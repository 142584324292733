import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from '@mui/material';
import { LinearProgress, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Modal from 'react-modal';

import {
    RedButton,
    Text,
    Flex,
    Column,
    JustifyContentEnd,
    PopUpHeader
} from '../../../components';

import closeIcon from '../../../assets/images/icon-close.svg';
import { DropDownEntityType, DropDownStakeholder } from './types';

import { CampaignEntityGroup, SelectedStakeholder } from '../types';
import { customModalStyles } from '../../../components/PopUps/constants';
import { ErrorText } from '../../../components/base/Text/ErrorText';

const CREATE_ENTITY_GROUP = gql`
    mutation createEntityGroup($data: CreateEntityGroupInput!) {
        createEntityGroup(data: $data) {
            id
            name
            archivedDate
            createdAt
            updatedAt
        }
    }
`;

const UPDATE_ENTITY_GROUP = gql`
    mutation updateEntityGroup($data: UpdateEntityGroupInput!, $id: ID!) {
        updateEntityGroup(data: $data, id: $id) {
            id
            name
        }
    }
`;

type Props = {
    // dropdownValues: DropdownValues;
    dropdownValues: any;
    entityData: any;
    setSelectedEntityGroups: any;
    selectedEntityGroups: CampaignEntityGroup[];
    setSelectedEntityGroup: any;
    entityGroupOpen: boolean;
    setEntityGroupOpen: any;
    clientId: number;
    setEntityGroupUpdated: any;
};

const EntityGroupSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    entityTypeId: Yup.number().moreThan(0,'Required').required('Required'),
    ownerId: Yup.string().required('Required'),
    stakeholderIds: Yup.array()
        .min(1, 'Select company team')
        .required('Required')
});

const CompanyEntityGroupForm: React.FC<Props> = ({
    dropdownValues,
    entityData,
    setSelectedEntityGroup,
    entityGroupOpen,
    setEntityGroupOpen,
    clientId,
    setEntityGroupUpdated
}) => {
    const handleClose = () => {
        setEntityGroupOpen(false);
        setSelectedEntityGroup(null);
    };
    const [createEntityGroup] = useMutation(CREATE_ENTITY_GROUP);
    const [updateEntityGroup] = useMutation(UPDATE_ENTITY_GROUP);
    const [isError, setIsError] = useState<boolean>(false);

    const initialValues = entityData
        ? {
              name: entityData.name,
              entityTypeId: entityData.type ? entityData.type.id : 0,
              ownerId: entityData.owner ? entityData.owner.id : '',
              stakeholderIds: entityData.stakeholders
                  ? entityData.stakeholders.map(
                        (stakeholder: any) => stakeholder.id
                    )
                  : []
          }
        : { name: '', entityTypeId: 0, ownerId: '', stakeholderIds: [] };
    return (
        <Modal
            isOpen={entityGroupOpen}
            contentLabel={entityData ? 'Edit parent modal' : 'Add parent modal'}
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={handleClose}
        >
            <PopUpHeader handleClose={handleClose} title={entityData ? 'Edit parent' : 'Add parent'} />
            <Formik
                initialValues={initialValues}
                validationSchema={EntityGroupSchema}
                onSubmit={(values, { setSubmitting }) => {
                    if (entityData) {
                        updateEntityGroup({
                            variables: {
                                data: values,
                                id: entityData && entityData.id
                            }
                        }).then(() => {
                                setSubmitting(false);
                                setEntityGroupUpdated(true);
                                handleClose();
                          }).catch(error => {
                                console.error(error);
                                alert('An error occurred, please see the console for details');
                            });
                        return;
                    }
                    createEntityGroup({
                        variables: {
                            data: { ...values, clientId, entities: [] }
                        }
                    }).then(() => {
                            setSubmitting(false);
                            setEntityGroupUpdated(true);
                            handleClose();
                        }).catch(error => {
                            console.error(error);
                            alert('An error occurred, please see the console for details');
                        });
                }}>
                {({
                    submitForm,
                    isSubmitting,
                    values,
                    setFieldValue,
                    errors,
                }) => (
                    <Form style={{paddingTop: '50px'}}>
                        <Flex>
                            <ColumnWrapper>
                                <Label>Parent title</Label>
                                {(isError && errors.name) &&
                                    <ErrorText>
                                       {errors.name.toString()}
                                    </ErrorText>
                                }
                                <Field
                                    name="name"
                                    error={isError && errors.name}
                                    variant="outlined"
                                    type="name"
                                    as={TextField}
                                    style={{ marginBottom: 20 }}
                                    inputProps={{
                                        'aria-label':'enter entity title'
                                    }}
                                />
                                <Label>Owner</Label>
                                {isError && errors.ownerId &&
                                    <ErrorText>
                                       {errors.ownerId.toString()}
                                    </ErrorText>
                                }
                                <Field
                                    variant="outlined"
                                    name="ownerId"
                                    type="text"
                                    error={isError && errors.ownerId}
                                    select
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    as={TextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    aria-label='select owner'
                                >
                                    <MenuItem value={0} disabled>
                                        Select owner
                                    </MenuItem>
                                    {dropdownValues.stakeholdersByClient.map((option: DropDownStakeholder) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {`${option.firstName} ${option.lastName}`}
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                                <Label>Stakeholders</Label>
                                {isError && errors.stakeholderIds &&
                                    <ErrorText>
                                       {errors.stakeholderIds.toString()}
                                    </ErrorText>
                                }
                                <Field
                                    variant="outlined"
                                    name="stakeholderIds"
                                    error={isError && errors.stakeholderIds}
                                    type="text"
                                    multiple
                                    style={{ marginBottom: 20, minHeight: 39 }}
                                    displayEmpty
                                    as={Select}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    renderValue={(selected: any) => {
                                        if ((selected as string[]).length === 0) {
                                            return 'Select stakeholders';
                                        }
                                        const selectedStakeholders = dropdownValues.stakeholdersByClient.filter((stakeholder: DropDownStakeholder) =>
                                                selected.includes(stakeholder.value));
                                        return selectedStakeholders.map((stakeholder: DropDownStakeholder) =>`${stakeholder.firstName} ${stakeholder.lastName}`);
                                    }}
                                >
                                    <MenuItem disabled>
                                        Select stakeholders
                                    </MenuItem>
                                    {dropdownValues.stakeholdersByClient.map((option: DropDownStakeholder) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {`${option.firstName} ${option.lastName}`}
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                                <Flex style={{ flexWrap: 'wrap' }}>
                                    {values.stakeholderIds.map((chip: string) => {
                                            const chipToDisplay = dropdownValues.stakeholdersByClient.filter(
                                                (el: SelectedStakeholder) => el.value === chip
                                            );
                                            return chipToDisplay.map((el: SelectedStakeholder) => (
                                                    <CustomChip key={el.value}>
                                                        <Flex
                                                            style={{
                                                                flexWrap: 'wrap',
                                                                boxSizing: 'border-box',
                                                                marginLeft: 12,
                                                                marginRight: 12,
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            {`${el.firstName} ${el.lastName}`}
                                                        </Flex>
                                                        <img
                                                            alt=""
                                                            src={closeIcon}
                                                            style={{
                                                                cursor:'pointer',
                                                                width: 14,
                                                                marginRight: 12,
                                                                verticalAlign: 'middle'
                                                            }}
                                                            onClick={() => {
                                                                const newTeam = values.stakeholderIds.filter(( e: string) => e !== el.value );
                                                                setFieldValue('stakeholderIds',newTeam);
                                                            }}
                                                        />
                                                    </CustomChip>
                                                )
                                            );
                                        }
                                    )}
                                </Flex>
                            </ColumnWrapper>
                            <ColumnWrapper>
                                <Label>Type</Label>
                                {isError && errors.entityTypeId &&
                                    <ErrorText>
                                       {errors.entityTypeId.toString()}
                                    </ErrorText>
                                }
                                <Field
                                    variant="outlined"
                                    name="entityTypeId"
                                    type="text"
                                    error={isError && errors.entityTypeId}
                                    select
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    as={TextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem value={0} disabled>
                                        Select type
                                    </MenuItem>
                                    {dropdownValues.entityTypes.map((option: DropDownEntityType) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                </Field>
                            </ColumnWrapper>
                        </Flex>
                        <SubmitWrapper>
                            {isSubmitting && <LinearProgress />}
                            <RedButton
                                style={{ padding: '12px 60px' }}
                                disabled={isSubmitting}
                                onClick={() => {
                                    if(errors.name || errors.entityTypeId || errors.ownerId || errors.stakeholderIds){
                                        setIsError(true);
                                    }else{
                                        submitForm();
                                    }
                                }}
                            >
                                Save
                            </RedButton>
                        </SubmitWrapper>
                    </Form>
                )}
                </Formik>
        </Modal>
    );
};

const ColumnWrapper = styled(Column)`
    width: 50%;
    padding: 0 50px;
`;

const SubmitWrapper = styled(JustifyContentEnd)`
    margin: 40px 24px 24px 0;
`;

const CustomChip = styled('span')`
    width: auto;
    padding: 12px;
    margin-right: 5px;
    margin-top: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: lightgrey;
    border: 1 solid black;
    border-radius: 50px;
`;

const Label = styled(Text)`
    color: #575757;
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
`;

export default CompanyEntityGroupForm;
