import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Column,
    SpaceBetween,
    JustifyContentCenter,
    Text
} from '../../../../components';
import Router from '../../../../routes/router';
import greyArrowRight from '../../../../assets/images/icon-grey-arrow-right.svg';
import { roundScore } from '../../../../utils/UtilsHelpers';
import { Objectives } from '../../MaturityScore/types';
import { MaturityOverviewMeetings } from '../MaturityOverview/types';

type Capability = {
    id: string;
    name: string;
    averageScore: number;
    inScope: boolean;
    status?: string;
    meetings: any[];
    moderatedScore: number;
    targetScore?: number;
    objectives: any[];
    feedback: {
        observations: {
            text: string;
        };
        recommendations: {
            text: string;
        }[];
    }[];
    workingNotes: {
        text: string;
    };
};

type RedirectParams = {
    campaignId: string;
    assessmentId: string;
    domainId: string | number;
    capabilityId: string | number;
};

type TooltipProps = {
    capability: Capability;
    redirectData: RedirectParams;
    meetings?: MaturityOverviewMeetings[];
    targetScoreEnabled?: boolean;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    pointOneDecimalEnable?: boolean;
    frameworkDomainAndCapabilityShortName?: string;
    isObjectiveComponentExist: boolean;
    compensatingControlsCount?: number | string;
    compensatingControlEnabled: boolean;
};

const getScoreValue = (value: number | null | string) => {
    if (value !== null && typeof value !== 'string') {
        if (value) {
            return Number(value).toFixed(1);
        }
        return value;
    }
    if (typeof value === 'string') {
        return value;
    }
    return 'Pending';
};

const handleArrowClick = (
    e: React.SyntheticEvent,
    inScope: boolean,
    redirectData: RedirectParams
): void => {
    if (inScope) {
        e.stopPropagation();
        navigateToMaturityScore(redirectData);
    }
};

const getCursorStyle = (inScope: boolean): string => {
    return inScope ? 'pointer' : 'default';
};

const navigateToMaturityScore = (data: RedirectParams) => {
    Router.goToMaturityScore(data);
};

const MaturityOverviewTooltip: React.FC<TooltipProps> = ({
    capability,
    redirectData,
    meetings,
    targetScoreEnabled = false,
    readOnlyEnabled,
    isReadOnly,
    pointOneDecimalEnable = false,
    frameworkDomainAndCapabilityShortName,
    isObjectiveComponentExist,
    compensatingControlsCount,
    compensatingControlEnabled
}) => {
    const [objCounter, setObjCounter] = useState(0);
    const [meetingsCounter, setMeetingsCounter] = useState(0);
    const countObjectives = (objectives: Objectives[]) => {
        let objectivesCounter = 0;
        objectives &&
        objectives.forEach((obj: Objectives) => {
            if (obj.inScope && obj.score) {
                objectivesCounter++;
            }
        });
        return objectivesCounter;
    };
    useEffect(() => {
        setObjCounter(countObjectives(capability.objectives));
    }, [capability.objectives]);
    useEffect(() => {
        let mtnCount=0;
       meetings&&meetings.map((mtn)=>{
         mtn.capabilities&&mtn.capabilities.map((cpb)=>{
             if(cpb.id===capability.id){
                 mtnCount++;
             }
           });
       });
       setMeetingsCounter(mtnCount);
    }, [meetings]);
    const capabilityNameCheck = (capabilityName: string): string => {
        if (capabilityName) {
            const frameworkShortName = frameworkDomainAndCapabilityShortName !== undefined ? frameworkDomainAndCapabilityShortName : '';
            return capabilityName.includes(frameworkShortName) ? capabilityName : `${capabilityName} (${frameworkShortName})`;
        }
        return '';
    };
    return (
        <Column style={{ margin: -8 }}>
            <TooltipHeader>
                <Heading>{capabilityNameCheck(capability && capability.name)}</Heading>
            </TooltipHeader>
            {(readOnlyEnabled && isReadOnly) && (capability.status !== 'COMPLETED') ?
            <><RowContainer>
            <TextContainer>
                <TooltipKey>Read Only: </TooltipKey>
                <TooltipValueReadOnly>
                    {capability.status === 'NOT_STARTED' ? 'Not started' : 'Pending approval'}
                </TooltipValueReadOnly>
            </TextContainer>
        </RowContainer>
        <RowContainer>
        <TextContainer>
            {'You do not have access to this information yet, contact your campaign manager for additional information'}
            </TextContainer>
        </RowContainer>
        </>
             :
            <>
            {!isObjectiveComponentExist && <RowContainer>
                    <TextContainer>
                        <TooltipKey>Moderated score:</TooltipKey>

                    <TooltipValue>
                        {getScoreValue(
                            capability && capability.inScope ? capability.moderatedScore
                            : !capability.inScope ? 'N/A' : null
                        )}
                    </TooltipValue>
                </TextContainer>
                <HoverImage
                    src={greyArrowRight}
                    alt="arrow"
                    style={{ cursor: getCursorStyle(capability.inScope) }}
                    onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                />
            </RowContainer>}
            {targetScoreEnabled && (
                <RowContainer>
                    <TextContainer>
                        <TooltipKey>Target score:</TooltipKey>

                        <TooltipValue>
                            {getScoreValue(
                                capability && capability.inScope ? typeof capability.targetScore==='number' ? capability.targetScore
                                : 'N/A' : 'N/A'
                            )}
                        </TooltipValue>
                    </TextContainer>
                    <HoverImage
                        src={greyArrowRight}
                        alt="arrow"
                        style={{ cursor: getCursorStyle(capability.inScope) }}
                        onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                    />
                </RowContainer>
            )}
            {compensatingControlEnabled && (
                <RowContainer>
                    <TextContainer>
                        <TooltipKey>Control Count:</TooltipKey>

                        <TooltipValue>
                            {compensatingControlsCount ?? '0'}
                        </TooltipValue>
                    </TextContainer>
                    <HoverImage
                        src={greyArrowRight}
                        alt="arrow"
                        style={{ cursor: getCursorStyle(capability.inScope) }}
                        onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                    />
                </RowContainer>
            )}
            <RowContainer>
                <TextContainer>
                    <TooltipKey>Objective score average:</TooltipKey>
                    <TooltipValue>
                        {getScoreValue(
                            capability && capability.inScope
                                ? roundScore(capability.averageScore, pointOneDecimalEnable)
                                : !capability.inScope ? 'N/A'
                                : null
                        )}
                    </TooltipValue>
                </TextContainer>
                <HoverImage
                    src={greyArrowRight}
                    alt="arrow"
                    style={{ cursor: getCursorStyle(capability.inScope) }}
                    onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                />
            </RowContainer>
            <RowContainer>
                <TextContainer>
                    <TooltipKey>Objective progress:</TooltipKey>

                        <TooltipValue>
                            {capability.inScope ? `${objCounter}/${capability.objectives.length}`: 'N/A'}
                        </TooltipValue>
                    </TextContainer>
                    <HoverImage
                        src={greyArrowRight}
                        alt="arrow"
                        style={{ cursor: getCursorStyle(capability.inScope) }}
                        onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                    />
                </RowContainer>
                <RowContainer>
                    <TextContainer>
                        <TooltipKey>Observations:</TooltipKey>

                        <TooltipValue>
                            {!capability.inScope ? 'N/A' : capability.feedback[0] &&
                            capability.feedback[0].observations
                                ? 'Yes'
                                : 'No'}
                        </TooltipValue>
                    </TextContainer>
                    <HoverImage
                        src={greyArrowRight}
                        alt="arrow"
                        style={{ cursor: getCursorStyle(capability.inScope) }}
                        onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                    />
                </RowContainer>
                <RowContainer>
                    <TextContainer>
                        <TooltipKey>Recommendations:</TooltipKey>

                        <TooltipValue>
                            {!capability.inScope ? 'N/A' : capability.feedback[0] &&
                            capability.feedback[0].recommendations &&
                            capability.feedback[0].recommendations.length
                                ? 'Yes'
                                : 'No'}
                        </TooltipValue>
                    </TextContainer>
                    <HoverImage
                        src={greyArrowRight}
                        alt="arrow"
                        style={{ cursor: getCursorStyle(capability.inScope) }}
                        onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                    />
                </RowContainer>
                {!(readOnlyEnabled && isReadOnly) &&
                        <>
                    <RowContainer>
                        <TextContainer>
                            <TooltipKey>Working notes:</TooltipKey>

                            <TooltipValue>
                                {!capability.inScope ? 'N/A' : capability.workingNotes ? 'Yes' : 'No'}
                            </TooltipValue>
                        </TextContainer>
                        <HoverImage
                            src={greyArrowRight}
                            alt="arrow"
                            style={{ cursor: getCursorStyle(capability.inScope) }}
                            onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                        />
                    </RowContainer>
                    <RowContainer style={{ paddingBottom: 20 }}>
                        <TextContainer>
                            <TooltipKey>Meetings:</TooltipKey>

                            <TooltipValue>
                            {!capability.inScope ? 'N/A' : meetingsCounter===0? 'No' : meetingsCounter}
                            </TooltipValue>
                        </TextContainer>
                        <HoverImage
                            src={greyArrowRight}
                            alt="arrow"
                            style={{ cursor: getCursorStyle(capability.inScope) }}
                            onClick={e => handleArrowClick(e, capability.inScope, redirectData)}
                        />
                    </RowContainer>
                  </>
                }
            </>
            }
            {/* <TooltipFooter>
                <TooltipFooterText>
                    <img
                        src={iconClock}
                        alt="clock"
                        style={{ marginRight: 5 }}
                    />
                </TooltipFooterText>
            </TooltipFooter> */}
        </Column>
    );
};

const TooltipKey = styled(Text)`
    font-size: 14px;
    color: #c4c4c4;
    width: 50%;
    text-align: right;
`;

const TooltipValue = styled(Text)`
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    width: 30%;
    text-align: left;
`;

const TooltipValueReadOnly = styled(Text)`
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    width: 50%;
    text-align: left;
`;

const Heading = styled(Text)`
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
`;

const TooltipHeader = styled(JustifyContentCenter)`
    background-color: #4f4f4f;
    flex-direction: column;
    padding: 10px 15%;
`;

const RowContainer = styled(SpaceBetween)`
    padding: 5px 10px 5px 10px;
    background-color: #2f2f2f;
`;

const TextContainer = styled(JustifyContentCenter)`
    width: 100%;
    text-align: center;
`;

const HoverImage = styled('img')`
    cursor: pointer;
`;

export default MaturityOverviewTooltip;
