import { styled } from '@mui/material/styles';
import { Text } from '../../../components';

const MainText = styled(Text)`
    letter-spacing: 0.5px;
    font-size: 16px;
    color: #575757;
    @media (max-width: 1366px) {
        font-size: 16px;
    }
    @media (max-width: 700px) {
        font-size: 16px;
    }
    @media (max-width: 450px) {
        font-size: 14px;
    }
`;

export default MainText;
