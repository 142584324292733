/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import Modal from 'react-modal';
import useEventListener from '@use-it/event-listener';

import { RedButton, PopUpHeader } from '../../../../components';
import { WorkingNotesModalProps } from './types';

import { styled } from '@mui/material/styles';
import { customModalStyles } from '../../../../components/PopUps/constants';

interface EditorType extends ReactQuillProps {
    ref: React.MutableRefObject<null>
}

export const WorkingNotesModal: React.FC<WorkingNotesModalProps> = ({
    isOpenWorkingNotes,
    saveWorkingNotes,
    setIsOpenWorkingNotes,
    workingNotes,
    workingNotesUpdating,
    canEdit,
}) => {
    const [markdownValue, setMarkdownValue] = useState<string>('');

    const handleProcedureContentChange = (content: any, delta: any, source: any, editor: any) => {
        setMarkdownValue(content);
    };

    const handleSaveWorkingNotes = () => {
        const notes = markdownValue;
        saveWorkingNotes(notes);
    };

    useEffect(() => {
        setMarkdownValue(workingNotes);
    }, [workingNotes, isOpenWorkingNotes]);

    const editorRef = useRef(null);
    const modalRef = useRef(null);
    const escapeKeyCodes = [13, 16, 9];


useEventListener('keydown', (event: any) => {
    // @ts-ignore
   if (!escapeKeyCodes.includes(event.keyCode) && modalRef !== null && editorRef !== null && (typeof editorRef.getEditor === 'function')) {
       // @ts-ignore
       editorRef.focus();
   }
});


    return (
        <Modal
            isOpen={isOpenWorkingNotes}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={() => setIsOpenWorkingNotes(false)}
            ref={modalRef}
        >
            <PopUpHeader
                handleClose={() => setIsOpenWorkingNotes(false)}
                title="Working notes"
            />
            <ReactQuillWrap
                ref={editorRef}
                value={markdownValue}
                onChange={handleProcedureContentChange}
                readOnly={!canEdit}
            />
            <SaveButton
                key="SaveButton"
                disabled={workingNotesUpdating || !canEdit}
                onClick={handleSaveWorkingNotes} >
                {workingNotesUpdating ? 'Loading...' : 'Save'}
            </SaveButton>
        </Modal>
    );
};

const SaveButton = styled(RedButton)`
    width: 100%;
    && {
        padding: 0 56px;
    }
`;

const ReactQuillWrap = styled((props: EditorType) =>
    <ReactQuill 
        theme="snow"
        {...props}
    />
)`
    display: flex;
    flex-direction: column-reverse;
    height: 350px;
    width: 100%;
    box-sizing: border-box;
    
    && {
        font-family: inherit;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        padding: 16px;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1em;
        color: #2d2d2d;
        overflow-y: hidden;
        min-height: 160px;
    }

    .ql-toolbar {
        flex-wrap: wrap;
        box-shadow: 0px -1px 9px 0px #cdcdcd;
        margin: 0;
        border: 0;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
    }
`;

export default WorkingNotesModal;
