import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import addIcon from '../../../../../../../assets/images/icon-add.svg';
import iconClock from '../../../../../../../assets/images/icon-clock.svg';
import {
    AlignItems,
    RedButton,
    TransparentButton,
    JustifyContentCenter,
    PopUpHeader
} from '../../../../../../../components';
import Text from '../../../../../../../components/base/Text';
import { AddRiskModalContentProps, Risk, Threat } from '../../../../types';
import RiskOption from './RiskOption';
import {
    renderModal,
    INITIAL_RISK_VALUES,
    renderDeleteModal
} from '../../../../ThreatAndRiskHelpers';
import { Button } from '@mui/material';

const RiskModalContent: React.FC<AddRiskModalContentProps> = ({
    risksRetrieved,
    setOpen,
    threatsRetrieved,
    selectedCampaign,
    riskAttributes,
    isAddModal,
    clientId,
    openRiskId,
    saveRisks,
    saveRisksLoading
}) => {
    const scrollRef = React.createRef<HTMLDivElement>();

    const [allRisks, setAllRisks] = useState<Risk[]>(
        isAddModal ? [{ ...INITIAL_RISK_VALUES }] : []
    );
    const [changedProps, setChangedProps] = useState<Risk[]>(
        isAddModal ? [{ ...INITIAL_RISK_VALUES }] : []
    );
    const [deleteItem, setDeleteItem] = useState({
        riskIndex: 0,
        objectName: '',
        isOpen: false,
        modalType: 'risk'
    });
    const [isValidation, setValidation] = useState<boolean>(false);
    const [isSave, setIsSave] = useState<boolean>(false);
    const [canClose, setCanClose] = useState<boolean>(true);
    const [isExpand, setIsExpand] = useState<boolean[]>(
        new Array(changedProps && changedProps.length).fill(false)
    );

    useEffect(() => {
        setIsExpand(
            new Array(
                ...changedProps.map((_el, index) => {
                    return isAddModal ? index === 0 : index === openRiskId;
                })
            )
        );
    }, [changedProps.length]);

    const expandAll = () => {
        setIsExpand(new Array(changedProps && changedProps.length).fill(true));
    };

    const collapseAll = () => {
        setIsExpand(new Array(changedProps && changedProps.length).fill(false));
    };

    const handleDisabling = () => {
        const filteredChangedProps: any[] = [];

        if (changedProps.length === allRisks.length) {
            allRisks.forEach((risk: Risk, index: number) => {
                Object.entries(risk).forEach(entriesValue => {
                    filteredChangedProps.push(
                        Object.entries(changedProps[index]).filter(
                            relatedEntriesValue => {
                                if (
                                    relatedEntriesValue[0] === entriesValue[0]
                                ) {
                                    if (
                                        relatedEntriesValue[0] === 'threats' &&
                                        relatedEntriesValue[1] &&
                                        entriesValue[1]
                                    ) {
                                        if (
                                            (relatedEntriesValue[1] as Array<
                                                Threat
                                            >).length !==
                                            (entriesValue[1] as Array<Threat>)
                                                .length
                                        ) {
                                            return true;
                                        }
                                        (relatedEntriesValue[1] as Array<
                                            Threat
                                        >).forEach(el => {
                                            if (
                                                !(entriesValue[1] as Array<
                                                    Threat
                                                >).find(
                                                    relatedEl =>
                                                        relatedEl.id === el.id
                                                )
                                            ) {
                                                return true;
                                            }
                                        });

                                        return false;
                                    }

                                    if (
                                        relatedEntriesValue[1] !==
                                        entriesValue[1]
                                    ) {
                                        return true;
                                    }
                                }
                                return false;
                            }
                        )
                    );
                });
            });
            if (filteredChangedProps.find(el => el.length > 0)) {
                setCanClose(false);
                return false;
            }
            setCanClose(true);
            return true;
        }
        setCanClose(false);
        return false;
    };

    const handleSaveRisks = () => {
        const filteredRisks = JSON.parse(JSON.stringify(changedProps));
        filteredRisks.forEach((changedProp: any) => {
            const threats = (changedProp.threats as Threat[]).map((el: any) => {
                return { id: el.id };
            });
            changedProp.threats = [...threats];
            delete changedProp.hasValidationErrors;
            delete changedProp.riskScore;
            delete changedProp.residualRate;
            delete changedProp.__typename;
        });

        saveRisks({
            variables: {
                clientId,
                campaignId: selectedCampaign.id,
                risks: filteredRisks
            }
        })
            .then(() => {})
            .catch(() => {});
    };
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const handleCloseModal = () => {
        canClose ? setOpen(false) : setConfirmationModal(true);
    };
    const saveRef = useRef(null);

    useEffect(() => {
        if (risksRetrieved) {
            if (isSave) {
                setAllRisks([
                    { ...INITIAL_RISK_VALUES },
                    ...JSON.parse(JSON.stringify(risksRetrieved))
                ]);
                setChangedProps([
                    { ...INITIAL_RISK_VALUES },
                    ...JSON.parse(JSON.stringify(risksRetrieved))
                ]);
                setIsSave(false);
            } else {
                setAllRisks([
                    ...allRisks,
                    ...JSON.parse(JSON.stringify(risksRetrieved))
                ]);
                setChangedProps([
                    ...changedProps,
                    ...JSON.parse(JSON.stringify(risksRetrieved))
                ]);
            }
        }
    }, [risksRetrieved]);

    return (
        <ContentWrapper>
            <PopUpHeader title="Risk" handleClose={handleCloseModal} />
            <Subheader>
                <AddButton
                    onClick={() => {
                        setAllRisks([{ ...INITIAL_RISK_VALUES }, ...allRisks]);
                        setChangedProps([
                            { ...INITIAL_RISK_VALUES },
                            ...changedProps
                        ]);
                        setValidation(false);
                        if (scrollRef && scrollRef.current) {
                            scrollRef.current.scrollIntoView();
                        }
                    }}
                >
                    <Icon src={addIcon} alt="Add risk" />
                    Add risk
                </AddButton>
            </Subheader>
            <Formik
                initialValues={[]}
                onSubmit={() => {}}
            >
                {({ setFieldValue }) => {
                    return (
                        <RiskForm>
                            <TAROptionsWrapper ref={scrollRef}>
                                {isExpand.some(el => el === false) &&
                                    !!changedProps.length && (
                                        <ExpandAll onClick={() => expandAll()}>
                                            Expand all
                                        </ExpandAll>
                                    )}
                                {isExpand.every(el => el === true) &&
                                    !!changedProps.length && (
                                        <ExpandAll
                                            onClick={() => collapseAll()}
                                        >
                                            Collapse all
                                        </ExpandAll>
                                    )}
                                {changedProps.map(
                                    (_risk: Risk, index: number) => {
                                        return (
                                            <RiskOption
                                                id={index}
                                                key={index}
                                                setChangedProps={
                                                    setChangedProps
                                                }
                                                isExpand={isExpand[index]}
                                                setFieldValue={setFieldValue}
                                                changedProps={changedProps}
                                                isSubmit={isValidation}
                                                threatsRetrieved={
                                                    threatsRetrieved
                                                }
                                                selectedCampaignName={
                                                    selectedCampaign.name
                                                }
                                                riskAttributes={riskAttributes}
                                                setDeleteItem={setDeleteItem}
                                            />
                                        );
                                    }
                                )}
                            </TAROptionsWrapper>
                            <RisksSaveWrapper>
                                {saveRisksLoading && (
                                    <JustifyContentCenter>
                                        <Icon
                                            src={iconClock}
                                            width="18px"
                                            alt="Loading..."
                                        />
                                        <LoadingText>Saving...</LoadingText>
                                    </JustifyContentCenter>
                                )}
                                <RisksSaveButton
                                    variant="contained"
                                    disabled={handleDisabling()}
                                    ref={saveRef}
                                    onClick={() => {
                                        setValidation(true);
                                        if (
                                            changedProps.find(
                                                el =>
                                                    el.hasValidationErrors ===
                                                    true
                                            )
                                        ) {
                                            return;
                                        }
                                        setIsSave(true);
                                        setValidation(false);
                                        handleSaveRisks();
                                    }}
                                >
                                    Save
                                </RisksSaveButton>
                            </RisksSaveWrapper>
                        </RiskForm>
                    );
                }}
            </Formik>
            {renderModal({
                handleDiscard: () => {
                    setConfirmationModal(false);
                    setOpen(false);
                },
                handleSave: () => {
                    setValidation(true);
                    setConfirmationModal(false);
                    if (
                        changedProps.find(
                            el => el.hasValidationErrors === true
                        )
                    ) {
                        return;
                    }
                    setOpen(false);
                    setIsSave(true);
                    setValidation(false);
                    handleSaveRisks();
                },
                isOpen: confirmationModal,
                title: 'Save risk(s) before closing?',
                text: 'The changes made will be lost if discarded.'
            })}
            {renderDeleteModal({
                handleClose: () => {
                    setDeleteItem({
                        riskIndex: 0,
                        objectName: '',
                        isOpen: false,
                        modalType: ''
                    });
                },
                handleSend: () => {
                    setDeleteItem({
                        riskIndex: 0,
                        objectName: '',
                        isOpen: false,
                        modalType: ''
                    });
                    changedProps.splice(deleteItem.riskIndex, 1);
                },
                isOpenDeleteConfirmation: deleteItem
            })}
        </ContentWrapper>
    );
};

const LoadingText = styled(Text)`
    font-size: 16px;
    color: #575757;
    margin-right: 16px;
    margin-left: -2px;
`;

const Subheader = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #575757;
    width: 100%;
    min-height: 80px;
    position: fixed;
    top: 70px;
    z-index: 999;
`;

const TAROptionsWrapper = styled('div')`
    margin-top: 13px;
    margin-left: 48px;
`;

const RisksSaveButton = styled(RedButton)<{ disabled: boolean }>`
    && {
        margin-left: 24px;
        padding: 12px 55px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 2px;
        line-height: 1;
        text-transform: none;
        width: 154px;
        height: 48px;
        border-radius: 2px;
        margin-right: 24px;
        opacity: ${props => (props.disabled ? '0.5' : '1')};
    }
`;

const AddButton = styled(TransparentButton)`
    && {
        margin-left: 48px;
        text-transform: none;
    }
`;

const ContentWrapper = styled(AlignItems)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const Icon = styled('img')`
    && {
        margin-right: 10px;
        height: 24px;
    }
`;

const RiskForm = styled(Form)`
    && {
        margin-top: 150px;
        margin-bottom: 80px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }
`;

const RisksSaveWrapper = styled('div')`
    width: 800px;
    min-height: 80px;
    background-color: #ffffff;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    border-top: 1px solid #f4f4f4;
    position: fixed;
    bottom: 0;
    z-index: 999;
`;

const ExpandAll = styled(Button)`
    font-size: 15px !important;
    color: #2d2d2d !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    margin-bottom: 12px !important;
    margin-right: 47px !important;
    float: right;
`;

export default RiskModalContent;
