export const getLocalStorageItem = (name: string) => {
    const result = localStorage.getItem(name);
    if (result === null) {
        return undefined;
    }
    return result;
};

export const setLocalStorageItem = (name: string, value?: string) => {
    if (value !== undefined) {
        localStorage.setItem(name, value);
    }
};
