import React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import { IIdleTimer } from 'react-idle-timer';
import { Select, MenuItem, IconButton, Checkbox,DialogContent } from '@mui/material';
import {
    DialogBox,
    BoldText,
    Text,
    Flex,
    JustifyContentCenter,
    CloseCampaignModal,
    DateTimePickerField,
    AlignItems,
    LinkExternal
} from '../../../components';
import { CampaignFilteredList } from '../types';
import infoIcon from '../../../assets/images/info_icon_blue.svg';
import crossIcon from '../../../assets/images/cross_icon.svg';
import PwC_full from '../../../assets/images/PwC_full.svg';
import PwC_light from '../../../assets/images/PwC_light.svg';
import client_sa from '../../../assets/images/client_sa.svg';
import iconClose from '../../../assets/images/Atmos_Icons_Close.svg';
import TermsOfUse from '../../TermsOfUse';
import CustomButton from '../../../components/base/Buttons/Button';

export const campaignStatusList = [
    {
        id: 'ACTIVE',
        label: 'Active campaigns'
    },
    {
        id: 'ALL',
        label: 'All campaigns'
    },
    {
        id: 'ARCHIVED',
        label: 'Archived campaigns'
    },
    {
        id: 'CLOSED_INCOMPLETE',
        label: 'Closed Incomplete'
    },
    {
        id: 'NOT_STARTED',
        label: 'Not started campaigns'
    },
    {
        id: 'COMPLETED',
        label: 'Closed complete'
    }
];

export const renderModal = ({
    modalId,
    handleClose,
    isOpen,
    closeCampaign,
    checkedCampaignsList,
    setSelectedRow,
    archiveCampaign,
    closeCampaignValue,
    setCloseCampaignValue,
    isSelectOpen,
    setSelectOpen,
    allCapabilitiesCompleted,
    benchmarkingEnabled,
    domainDescoped,
    pointOneDecimalEnable,
    isCheckboxEnable,
     setCheckBoxes,

}: {
    isOpen: boolean;
    modalId: string;
    handleClose: () => void;
    checkedCampaignsList: CampaignFilteredList[];
    setSelectedRow: (data: string[]) => void;
    closeCampaign: any;
    archiveCampaign: (data: object) => Promise<object>;
    closeCampaignValue: {
        radioValue: string;
        assessmentType: string | number;
        closeDate: Date;
        closeCurrentDate: boolean;
    };
    setCloseCampaignValue: any;
    isSelectOpen: boolean;
    setSelectOpen: any;
    allCapabilitiesCompleted: boolean;
    benchmarkingEnabled?: boolean;
    domainDescoped: boolean;
    pointOneDecimalEnable?: boolean;
    isCheckboxEnable: boolean[][];
    setCheckBoxes: any;
}) => {
    const store = new Map();
    const handleCloseTemplate = () => {
        setCloseCampaignValue({
            assessmentType: 0,
            radioValue: '',
            closeDate: new Date(0),
            closeCurrentDate: true
        });
        handleClose();
    };

    store.set(
        'close',
        <CloseCampaignModal
            title={
                <TitleWrapper>
                    <TitleTextWrapper>
                        <BoldText
                            color="#575757"
                            fontSize="22px"
                            style={{ textAlign: 'center' }}
                        >
                            Close
                            {checkedCampaignsList.length > 1
                                ? ' Campaigns'
                                : ' Campaign'}
                        </BoldText>
                    </TitleTextWrapper>
                    <IconButton onClick={() => handleCloseTemplate()} style={{margin: '0 24px'}}>
                        <img src={crossIcon} alt="close" />
                    </IconButton>
                </TitleWrapper>
            }
            buttonCancelText=""
            buttonSaveText={`Close ${
                checkedCampaignsList.length > 1 ? 'campaigns' : 'campaign'
            } `}
            content={
                <div>
                    <InfoTitleContainer>
                        <div>
                            <img src={infoIcon} alt="" />
                        </div>
                        <InfoTitleTextWrapper>
                            <Text color="#4588c3" size="17px">
                                This action will close the "
                                {checkedCampaignsList.length &&
                                    checkedCampaignsList[0].title}
                                " campaign. Closing a campaign will lock the
                                campaign and prevent any further editing - all
                                maturity scores, notes, observations and
                                recommendations will be view only.
                            </Text>
                        </InfoTitleTextWrapper>
                    </InfoTitleContainer>
                    {benchmarkingEnabled?(
                    !domainDescoped && allCapabilitiesCompleted ? (
                        <div style={{ padding: '32px 0' }}>
                            <BoldText fontSize="16px" color="#2d2d2d">
                                Would you like to send this campaign data to the
                                benchmarking database?
                            </BoldText>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    value={closeCampaignValue.radioValue}
                                    onChange={e =>
                                        setCloseCampaignValue({
                                            ...closeCampaignValue,
                                            radioValue: e.target.value
                                        })
                                    }
                                    row={false}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio color="default" />}
                                        color="default"
                                        label="Yes, send the campaign data to the benchmarking database."
                                        labelPlacement="end"
                                        onChange={(e: any) => {
                                            isCheckboxEnable[0][0] = true;
                                            isCheckboxEnable[1][0] = false;
                                            isCheckboxEnable[1][1] = false;
                                            setCheckBoxes({
                                                ...isCheckboxEnable,
                                            });
                                        }}
                                    />
                                     {isCheckboxEnable[0][0] && (
                                            <CheckModerationContainer>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    id="check1"
                                                    onChange={(e: any) => {
                                                        isCheckboxEnable[0][1] =
                                                            !isCheckboxEnable[0][1];
                                                        setCheckBoxes({
                                                            ...isCheckboxEnable,
                                                        });
                                                    }}
                                                />
                                                <Text>
                                                    I confirm the data is true
                                                    and accurate to the best of
                                                    my knowledge,<br/> and
                                                    <b>
                                                    {' '}does not contain test data. {' '}
                                                    </b>
                                                    <FooterLinkExternal
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] = true;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Terms of Use
                                                    </FooterLinkExternal>
                                                </Text>
                                            </CheckModerationContainer>
                                        ) }
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio color="default" />}
                                        color="default"
                                        label="No, don’t share campaign data."
                                        labelPlacement="end"
                                        onChange={(e: any) => {
                                            isCheckboxEnable[0][0] = false;
                                            isCheckboxEnable[1][0] = true;
                                            isCheckboxEnable[0][1] = false;
                                            setCheckBoxes({
                                                ...isCheckboxEnable,
                                            });
                                        }}
                                    />
                                     {isCheckboxEnable[1][0] && (
                                            <CheckModerationContainer>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    id="check2"
                                                    onChange={(e: any) => {
                                                        isCheckboxEnable[1][1] =
                                                            !isCheckboxEnable[1][1];
                                                        setCheckBoxes({
                                                            ...isCheckboxEnable,
                                                        });
                                                    }}
                                                />

                                                <Text>
                                                    I confirm the data is true
                                                    and accurate to the best of
                                                    my knowledge,<br/> and
                                                    <b>
                                                    {' '}does not contain test data. {' '}
                                                    </b>
                                                    <FooterLinkExternal
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                true;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Terms of Use
                                                    </FooterLinkExternal>
                                                </Text>
                                            </CheckModerationContainer>
                                        ) }
 {isCheckboxEnable[1][2] && (
                                            <Dialog
                                                open={true}
                                                maxWidth="md"
                                                scroll="body"
                                            >
                                                <DialogContent>
                                                    <Xmark
                                                        src={iconClose}
                                                        margin-right="99%"
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    />
                                                    <TermsOfUse />
                                                    <CloseButton
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Close
                                                    </CloseButton>
                                                </DialogContent>
                                            </Dialog>
                                        )}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : (
                        <div style={{ padding: '32px 0' }}>
                        {domainDescoped ? (
                            <BoldText fontSize="16px" color="#2d2d2d">
                                The data for this campaign will not be sent
                                to the benchmarking database because there
                                are domains with all capabilities descoped.
                            </BoldText>
                        ) : (
                            <BoldText fontSize="16px" color="#2d2d2d">
                                The data for this campaign will not be sent
                                to the benchmarking database because there
                                are capabilities that have not been
                                completed.
                            </BoldText>
                        )}
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    value={closeCampaignValue.radioValue}
                                    onChange={e =>
                                        setCloseCampaignValue({
                                            ...closeCampaignValue,
                                            radioValue: e.target.value
                                        })
                                    }
                                    row={false}
                                >
                                    <FormControlLabel
                                        value="wontBeSent"
                                        control={<Radio color="default" />}
                                        color="default"
                                        label="I acknowledge that the data for this campaign won’t be sent to the benchmarking database."
                                        labelPlacement="end"
                                        onChange={(e: any) => {
                                            isCheckboxEnable[1][0] = true;
                                            setCheckBoxes({
                                                ...isCheckboxEnable,
                                            });
                                        }}
                                    />
                                     {isCheckboxEnable[1][0] && (
                                            <CheckModerationContainer>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    id="check2"
                                                    onChange={(e: any) => {
                                                        isCheckboxEnable[1][1] =
                                                            !isCheckboxEnable[1][1];
                                                        setCheckBoxes({
                                                            ...isCheckboxEnable,
                                                        });
                                                    }}
                                                />
                                                <Text>
                                                    I confirm the data is true
                                                    and accurate to the best of
                                                    my knowledge,<br/>and
                                                    <b>
                                                    {' '}does not contain test data. {' '}
                                                    </b>
                                                    <FooterLinkExternal
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                true;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Terms of Use
                                                    </FooterLinkExternal>
                                                </Text>
                                            </CheckModerationContainer>
                                        )}
                                        {isCheckboxEnable[1][2] && (
                                            <Dialog
                                                open={true}
                                                maxWidth="md"
                                                scroll="body"
                                            >
                                                <DialogContent>
                                                    <Xmark
                                                        src={iconClose}
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    />
                                                    <TermsOfUse />
                                                    <CloseButton
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Close
                                                    </CloseButton>
                                                </DialogContent>
                                            </Dialog>
                                        )}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    )):null
                }
                    <div style={{ paddingBottom: '32px' }}>
                        <BoldText fontSize="16px" color="#2d2d2d">When was the campaign completed?</BoldText>
                        <FormControlLabel
                            control={<Checkbox
                                size="small"
                                color="primary"
                                id="checkx123"
                                checked={closeCampaignValue.closeCurrentDate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setCloseCampaignValue({...closeCampaignValue, closeCurrentDate: e.target.checked})
                                }
                            />}
                            label="Use current date"
                        />
                    {!closeCampaignValue.closeCurrentDate && ( <>
                        <Text style={{ display: 'block' }}>Defaults to last modified date</Text>
                        <DateTimePickerField
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            inputMargin="dense"
                            inputName="closeDate"
                            disableFuture
                            minDate={new Date('2020-04-01')}
                            onChange={(date: Date) => setCloseCampaignValue({...closeCampaignValue, closeDate: date})}
                            value={closeCampaignValue.closeDate}
                        /></>
                    )}
                    </div>
                    <div>
                        <BoldText fontSize="16px" color="#2d2d2d">
                            How was the assessment performed?
                        </BoldText>
                        <Text
                            size="14px"
                            color="#575757"
                            style={{ display: 'block', padding: '10px 0' }}
                        >
                            Assessment type
                        </Text>
                        <FormControl
                            variant="outlined"
                            style={{ minWidth: 300 }}
                        >
                            <CustomSelect
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    },
                                    transformOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    },
                                    anchorEl: null
                                }}
                                value={closeCampaignValue.assessmentType}
                                onChange={e =>
                                    setCloseCampaignValue({
                                        ...closeCampaignValue,
                                        assessmentType: e.target.value
                                    })
                                }
                                onOpen={() => setSelectOpen(true)}
                                onClose={() => setSelectOpen(false)}
                            >
                                <MenuItem value={0} disabled>
                                    <BoldText color="#6b6b6b" fontSize="16px">
                                        Select assessment type
                                    </BoldText>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <div>
                                        <BoldText fontSize="15px">
                                            <img
                                                src={PwC_full}
                                                alt="mdi_grade"
                                            />{' '}
                                            PwC full
                                        </BoldText>

                                        {isSelectOpen && (
                                            <Text color="#6b6b6b" size="14px">
                                                This applies to campaigns that
                                                have a full
                                                <br />
                                                set of scores, notes,
                                                observations and
                                                <br />
                                                recommendations.
                                            </Text>
                                        )}
                                    </div>
                                </MenuItem>
                                <MenuItem value={2}>
                                    <div>
                                        <BoldText fontSize="15px">
                                            <img
                                                src={PwC_light}
                                                alt="PwC_light"
                                            />{' '}
                                            PwC light
                                        </BoldText>
                                        {isSelectOpen && (
                                            <Text color="#6b6b6b" size="14px">
                                                This applies to campaigns that
                                                have limited
                                                <br />
                                                notes, observations or
                                                recommendations.
                                            </Text>
                                        )}
                                    </div>
                                </MenuItem>

                                <MenuItem value={3}>
                                    <div>
                                        <BoldText fontSize="15px">
                                            <img
                                                src={client_sa}
                                                alt="client_sa"
                                            />{' '}
                                            Client self-assessment
                                        </BoldText>
                                        {isSelectOpen && (
                                            <Text color="#6b6b6b" size="14px">
                                                This applies to campaigns that
                                                have been
                                                <br />
                                                completed by the client.
                                            </Text>
                                        )}
                                    </div>
                                </MenuItem>
                            </CustomSelect>
                        </FormControl>
                    </div>
                </div>
            }
            open={isOpen}
            handleClose={() => handleCloseTemplate()}
            disabledSave={benchmarkingEnabled?
                (!closeCampaignValue.assessmentType ||
                !closeCampaignValue.radioValue):(!closeCampaignValue.assessmentType)
            }
            handleSubmit={() => {
                closeCampaign({
                    campaignId: checkedCampaignsList[0].id,
                    assessmentType: closeCampaignValue.assessmentType,
                    submitForBenchmarking: closeCampaignValue.radioValue,
                    pointOneDecimalEnable: false
                });
            }}
        />
    );
    store.set(
        'archive',
        <DialogBox
            title={`Archive ${
                checkedCampaignsList.length ? 'Campaigns' : 'Campaign'
            }`}
            buttonCancelText="Cancel"
            buttonSaveText={`Archive ${
                checkedCampaignsList.length ? 'campaigns' : 'compaign'
            }`}
            content={`Are you sure you want to archive "${checkedCampaignsList
                .map((item: CampaignFilteredList) => item.title)
                .join(', ')}" ${
                checkedCampaignsList.length ? 'campaigns' : 'campaign'
            }?`}
            open={isOpen}
            handleClose={handleClose}
            handleSubmit={() => {
                checkedCampaignsList.length &&
                    archiveCampaign({
                        variables: {
                            id: checkedCampaignsList[0].id
                        }
                    })
                        .then(() => {
                            setSelectedRow([]);
                            handleClose();
                        })
                        .catch(err => {
                            console.log('Archive campaign Error', err);
                        });
            }}
        />
    );

    return store.get(modalId) || null;
};

const TitleTextWrapper = styled(JustifyContentCenter)`
    flex-grow: 1;
    padding-left: 72px;
`;

const TitleWrapper = styled(Flex)`
    border-bottom: 2px solid #e1e1e1;
    padding: 22px 0;
`;

const InfoTitleContainer = styled(Flex)`
    background-color: #edf4f8;
    padding: 12px;
`;

const InfoTitleTextWrapper = styled('div')`
    padding: 0 8px;
`;

const CustomSelect = styled(Select)`
    && .MuiFormLabel-root.Mui-focused {
        color: #575757;
    }
    &&.MuiFormLabel-root.Mui-focused {
        color: #575757;
    }
    &&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #575757;
    }
`;

const CheckModerationContainer = styled(AlignItems)`
    border-radius: 1px;
    margin-left: 30px;
    padding-right: 9px;
    height: 100%;
`;
const FooterLinkExternal = styled(LinkExternal)`
    color: #4169E1;
    font-size: 15px;
    text-decoration: underline;
`;
const Xmark = styled('img')`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    // margin-left: 98%;
`;
const CloseButton = styled(CustomButton)`
    && {
        background-color: #de3328;
        min-height: 48px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        box-sizing: border-box;
        border: none;
        color: white;
        &:hover {
            background-color: #f05046;
            color: white;
        }
        &:disabled {
            background-color: #f5928b;
            color: white;
        }
    }
`;
