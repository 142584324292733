import React, { useState, useEffect, CSSProperties } from 'react';
import Button from '@mui/material/Button';
import Router from '../../../routes/router';
import { removeJwtFromWhitelist } from '../../../services/ApolloClient/client';
import { EXTENDED_LOGOUT_TIME } from '../../../utils';
import { useAuth } from 'react-oidc-context';
import { useIdleTimer } from 'react-idle-timer';
import { styled } from '@mui/material/styles';


const IdleTimeoutFunctional: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false);
  const [timerText, setTimerText] = useState<string>(' soon.');
  const auth = useAuth();

    const onAction = () => {};

    const onActive = () => {};

    const _removeToken = async () => {
        auth.signoutSilent();
        auth.removeUser();
        await removeJwtFromWhitelist();
    };

    const onIdle =  async () => {
        if (isTimedOut === true && auth.isAuthenticated) {
            await _removeToken();
            Router.goToLogoutPage();
        } else if (auth.isAuthenticated) {
            reset();
            setShowModal(true);
            setIsTimedOut(true);
        }
    };

    const _extendTimeout = () => {
        setShowModal(false);
        window.location.reload();
    };

  const { getRemainingTime,reset } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * EXTENDED_LOGOUT_TIME,
    throttle: 500,
    events: ['click', 'wheel', 'scroll', 'keydown']
  });

  useEffect(() => {
  });

  useEffect(() => {

    const interval = setInterval(() => {
        let txt = '';
        if (getRemainingTime() === 0) txt = '0 minute and 0 second';
        const time = getRemainingTime();
        const minutes = Math.floor(time / 1000 / 60);
        const seconds = Math.floor((time / 1000) % 60);
        if (minutes === 0 && seconds === 0) txt = '0 minute and 0 second';
        else if (minutes === 0) txt = '0 minute and ' + seconds + ' seconds';
        else txt = minutes + ' minutes and ' + seconds + ' seconds';
        setTimerText(txt);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });



    if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification');
    } else {
        // tslint:disable-next-line: no-floating-promises
        Notification.requestPermission();
    }

    return (
        <>
            {auth.isAuthenticated  && showModal ?
            <CustomModal>
                <CustomModalContent>
                <img src={imgData} style={{ width: '64px' }} />
                     <h1 style={customBig}>CRE Session timeout alert!</h1>
                     <p style={customParagraph}>
                         <b>Warning: </b>{'Your CRE session is about to expire.  You will automatically be logged out in:'}
                     </p>
                     <p style={customTimeText}><b>{timerText}</b></p>
                     <p style={customParagraph}>
                         {'To avoid any data loss please extend your session using the button below and save your work.'}
                     </p>
                     <Button onClick={_extendTimeout} style={customButton}>Extend Time</Button>
                </CustomModalContent>
            </CustomModal> 
            : <></> }

        </>

    );
};

  const customTimeText: CSSProperties = {
    textAlign: 'center',
    lineHeight: '1.5rem',
    letterSpacing: '.01rem',
    color: '#de3328',
    margin: '0em'
  };

  const customParagraph: CSSProperties = {
    textAlign: 'center',
    lineHeight: '1.5rem',
    letterSpacing: '.01rem'
  };

  const customBig: CSSProperties = {
    marginBottom: '.8rem',
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: 'black'
  };

  const customButton: CSSProperties = {
    position: 'absolute',
    border: 'none',
    width: '100%',
    bottom: '-1px',
    left: '0px',
    borderRadius: '0px',
    padding: '1rem',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#de3328',
    display: 'block',
    verticalAlign: 'bottom',
    transition: 'background .3s ease-in-out',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px'
  };

  const imgData = 'data:image/svg+xml;charset=UTF-8,%3csvg version=\'1.1\' id=\'Layer_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 104 99\' style=\'enable-background:new 0 0 104 99;\' xml:space=\'preserve\'%3e%3cstyle type=\'text/css\'%3e .st0%7bfill:%23FFFFFF;%7d .st1%7bfill:%23DB536A;%7d .st2%7bfill:%237D7D7D;%7d .st3%7bfill:%23D04A02;%7d .st4%7bfill:%23E0301E;%7d .st5%7bfill:%23464646;%7d .st6%7bfill:%232D2D2D;%7d %3c/style%3e%3cpath class=\'st4\' d=\'M87.4,74.8L55.7,19.9c-1.7-2.9-5.8-2.9-7.4,0L16.6,74.8c-1.7,2.9,0.4,6.4,3.7,6.4h63.4 C87,81.2,89.1,77.7,87.4,74.8z M52,72.2c-2.4,0-4.3-1.9-4.3-4.3c0-2.4,1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3C56.3,70.3,54.4,72.2,52,72.2 z M56.5,54.7h-9V36.9h9V54.7z\'/%3e%3c/svg%3e';

  const CustomModal = styled('div')`
    position : fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  `;

const CustomModalContent = styled('div')`
    width: 400px;
    height: 400px;
    margin: auto;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 2rem 3.5rem 2rem;
    border: none
    overflow: hidden;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    position: relative;
`;

export default IdleTimeoutFunctional;
