import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/react-hooks';

import {
    LinearProgress,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';

import {
    Column,
    SpaceBetween,
    JustifyContentCenter,
    RedButton,
    Text,
    BoldText,
    CustomCircularProgress,
    ArrowTooltip,
    DialogBox,
} from '../../components';
import CustomMenu from '../../components/Menu';
import {
    getLastUpdatedDateDifference,
    isReadOnlyEnabled,
    isPointOneDecimalEnable,
} from '../../utils';
import mdi_lock_black from '../../assets/images/mdi_lock_black.svg';
import Router from '../../routes/router';
import { Campaign } from './types';
import { ClientParams } from './types';
import { gql } from 'apollo-boost';
import {client} from '../../services/ApolloClient/client';
import { CAMPAIGNS as CAMPAIGNS_LIST } from '../Evaluate/query';
import { UserStore } from '../../context/user-store';
import { dynamicSort, stringTruncator } from '../../utils/UtilsHelpers';

const ARCHIVE_CAMPAIGNS = gql`
    mutation archiveCampaigns1($ids: [ID!]!) {
        archiveCampaigns(ids: $ids) {
            id
        }
    }
`;

// const ARCHIVE_ASSESSMENTS = gql`
//     mutation archiveAssessments($ids: [ID!]!) {
//         archiveAssessments(ids: $ids) {
//             id
//             archivedDate
//             campaignEntity {
//                 id
//                 archivedDate
//             }
//         }
//     }
// `;

type Props = {
    cardData: Campaign;
    setRefreshState: any;
};

interface CampaignCardState {
    [key: string]: boolean;
}

const CampaignCard: React.FC<Props> = ({ cardData, setRefreshState }) => {
    const {
        state: { user, client, globalSettings },
    } = useContext(UserStore);

    const [archiveCampaigns] = useMutation(ARCHIVE_CAMPAIGNS, {
        refetchQueries: [
            {
                query: CAMPAIGNS_LIST,
                variables: {
                    clientId: client.id,
                    campaignId: cardData.id,
                },
            },
        ],
    });

    const [isModalArchiveOpen, setIsModalArchiveOpen] = useState(false);

    const handleModalArchiveClose = () => {
        setIsModalArchiveOpen(false);
    };

    const handleModalArchiveOpen = () => {
        setIsModalArchiveOpen(true);
    };

    const handleModalArchiveSubmit = () => {
        archiveCampaigns({
            variables: {
                ids: [cardData.id],
            },
        })
            .then(() => {
                // setSelectedRow([]);
                setIsModalArchiveOpen(false);
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const pointOneDecimalEnable = isPointOneDecimalEnable(globalSettings);

    return (
        <CampaignCardContainer
            onClick={() => {
                if (!document.activeElement?.classList.contains('MuiPaper-root')
                        && document.activeElement?.getAttribute('aria-label') !== 'more options'
                        && !document.activeElement?.classList.contains('MuiListItem-root'))
                    Router.goToMaturityOverview(cardData.id);
                setRefreshState(Math.random());
            }}
        >
            <DialogBox
                title="Archive Campaign"
                buttonSaveText="Archive Campaign"
                buttonCancelText="Cancel"
                content="Are you sure you want to archive the current campaign?"
                open={isModalArchiveOpen}
                handleClose={handleModalArchiveClose}
                handleSubmit={handleModalArchiveSubmit}
            />

            {(cardData.closedAt || (cardData.readOnly && readOnlyEnabled)) && (
                <ClosedCampaignBlock>
                    <img
                        style={{ padding: '0 5px' }}
                        src={mdi_lock_black}
                        alt="mdi_lock_black"
                    />
                    <Text size="14px" color="#575757">
                        {cardData.closedAt
                            ? cardData.readOnly && readOnlyEnabled
                                ? 'This campaign is closed - Read-only access'
                                : 'This campaign is closed - view only access'
                            : 'Read-only access'}
                    </Text>
                </ClosedCampaignBlock>
            )}

            <SpaceBetween>
                <div />
                <CampaignAssessmentHeading>
                    {cardData.name}
                </CampaignAssessmentHeading>
                <div>
                    {cardData && cardData.closedAt !== null || cardData.readOnly === true || (!cardData.manager.map(manager => manager.id).includes(user.id!) && user.id !== client.owner?.id)? ('') : (
                        <CustomMenu
                            button={
                                <Tooltip
                                    title={
                                        <Typography color="inherit">
                                            More Options
                                        </Typography>
                                    }
                                    placement="top">
                                    <DotContainer aria-hidden={true}>
                                        {[1, 2, 3].map((el: number) => (
                                            <Dot key={el} />
                                        ))}
                                    </DotContainer>
                                </Tooltip>
                            }
                            arrowColor="#fff"
                            ariaLabel='more options'>
                            <MenuItem onClick={(event) => {
                                Router.goToEvaluateCreateCampaign(
                                    cardData.id
                                );
                                event.stopPropagation();
                            }}>
                                <DropDownText>Edit</DropDownText>
                            </MenuItem>

                        </CustomMenu>
                    )}
                </div>
            </SpaceBetween>
            <DescriptionContainer>
                <CampaignAssessmentSubheading>
                    {stringTruncator(cardData.description, 120)}
                </CampaignAssessmentSubheading>
            </DescriptionContainer>
            <SpaceBetween>
                <CustomCircularProgress
                    strokeWidth={10}
                    sqSize={200}
                    percentage={cardData.progress}
                />
                <CampaignDetailsContainer>
                    <Column style={{ marginBottom: 16 }}>
                        <CampaignDetailsKey>CAMPAIGN LEAD(S)</CampaignDetailsKey>
                        <CampaignDetailsValue>
                            <ArrowTooltip
                                title={cardData.manager.map(manager => `${manager.firstName} ${manager.lastName}`)}

                                placement="bottom"
                                minWidth={'0px'}
                            >
                                <ManagerTruncatorWrapper>
                                    {
                                        cardData.manager.slice().sort(dynamicSort('firstName')).map(manager => `${manager.firstName} ${manager.lastName}`).join('\n')
                                    }
                                </ManagerTruncatorWrapper>
                            </ArrowTooltip>
                        </CampaignDetailsValue>
                    </Column>
                    <Column style={{ marginBottom: 16 }}>
                        <CampaignDetailsKey>FRAMEWORK</CampaignDetailsKey>
                        <CampaignDetailsValue>
                            <ArrowTooltip
                                title={cardData.framework.definition.name}
                                placement="bottom"
                                minWidth={'0px'}
                            >
                                <TruncatorWrapper>
                                    {cardData.framework.definition.name}
                                </TruncatorWrapper>
                            </ArrowTooltip>
                        </CampaignDetailsValue>
                    </Column>
                    {/*<Column style={{ marginBottom: 16 }}>*/}
                    {/*    <CampaignDetailsKey>MY ROLE</CampaignDetailsKey>*/}
                    {/*    <CampaignDetailsValue>team member</CampaignDetailsValue>*/}
                    {/*</Column>*/}
                    <Column style={{ marginBottom: 16 }}>
                        <CampaignDetailsKey>ASSESSMENTS</CampaignDetailsKey>
                        <CampaignDetailsValue>
                            {cardData.assessmentsCnt}
                        </CampaignDetailsValue>
                    </Column>
                    <Column style={{ marginBottom: 16 }}>
                        <CampaignDetailsKey>LAST UPDATE</CampaignDetailsKey>
                        <CampaignDetailsValue>
                            {getLastUpdatedDateDifference({
                                currentDate: cardData.updatedAt,
                            })}
                        </CampaignDetailsValue>
                    </Column>
                    {/* {
                        pointOneDecimalEnable ?
                            (
                                <Column>
                                    <CampaignDetailsKey>DECIMAL ONE CALCULATION</CampaignDetailsKey>
                                    <CampaignDetailsValue>
                                        {cardData.pointOneDecimalEnable && cardData.pointOneDecimalEnable === true ? 'Enabled' : 'Disabled'}
                                    </CampaignDetailsValue>
                                </Column>
                            ) : ''
                    } */}
                </CampaignDetailsContainer>
            </SpaceBetween>
            <RedButton
                style={{ backgroundColor: '#de3328' }}
                onClick={() => {
                    Router.goToMaturityOverview(cardData.id);
                }}
            >
                View campaign
            </RedButton>
        </CampaignCardContainer>
    );
};

const ManagerTruncatorWrapper = styled('div')`
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
`;

const TruncatorWrapper = styled('div')`
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const DotContainer = styled(JustifyContentCenter)`
    background-color: #ececec;
    width: 24px;
    height: 24px;
    flex-direction: column;
`;

const CampaignCardContainer = styled(Column)`
    cursor: pointer;
    width: calc(100% / 3 - 90px);
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    padding: 32px;
    margin-bottom: 32px;
    margin-right: 24px;
    justify-content: space-between;
    @media (max-width: 1438px) {
        width: calc(100% / 2 - 90px);
    }
    position: relative;
`;

const Dot = styled('div')`
    width: 4px;
    height: 4px;
    background-color: #b1b1b1;
    border-radius: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
`;

const CampaignAssessmentHeading = styled(Text)`
    font-size: 16px;
    color: #000000;
    text-align: center;
    margin-bottom: 8px;
`;

const CampaignAssessmentSubheading = styled(Text)`
    font-size: 12px;
    color: #6E6E6E;
    text-align: center;
    margin-bottom: 34px;
`;

const CampaignDetailsContainer = styled(Column)`
    border-radius: 2px;
    background-color: #f8f8f8;
    padding: 16px;
    margin-bottom: 34px;
    margin-left: 31px;
    margin-right: 14px;
`;

const CampaignDetailsKey = styled(Text)`
    font-size: 12px;
    color: #346FA2;
`;

const CampaignDetailsValue = styled(Text)`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #575757;
`;

const DropDownText = styled(BoldText)`
    color: #2f2f2f;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
`;

const DescriptionContainer = styled(JustifyContentCenter)`
    margin-right: 24px;
    word-break: break-all;
    overflow: hidden;
`;
const ClosedCampaignBlock = styled(JustifyContentCenter)`
    position: absolute;
    display: flex;
    top: -4%;
    left: 50%;
    background-color: white;
    width: 65%;
    transform: translateX(-50%);
    border: 1px solid #d9d9d9;
    padding: 8px 24px;
    border-radius: 20px;
`;
export default CampaignCard;
