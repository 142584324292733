import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import AutoSave from '../../../../../utils/AutoSave';
import { SpinnerWithOverlay } from '../../../../../components';

export type ObjectiveNotesContainerProps = {
    isActive: boolean;
    isMarkdownSaveButtonDisabled: boolean;
    markdownValue: string;
    setMarkdownValue: any;
    updateObjectiveNotesLoading: boolean;
    saveMarkdownChanges: any;
    canEdit: boolean;
    setDirty: any;
    isDirty: boolean;
};

const ObjectiveNotesContainer: React.FC<ObjectiveNotesContainerProps> = ({
    isActive,
    markdownValue,
    setMarkdownValue,
    isMarkdownSaveButtonDisabled,
    updateObjectiveNotesLoading,
    saveMarkdownChanges,
    canEdit,
    setDirty,
    isDirty
}) => {
    return (
        <MarkDownContainer>
            <ReactQuillWrap
                value={markdownValue}
                onChange={(content: any, delta: any, source: any, editor: any) => {
                    if (isActive || !canEdit) {
                        return;
                    }
                    setDirty(true);
                    setMarkdownValue(content);
                }}
            />
            <ActionsContainer>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={isMarkdownSaveButtonDisabled||updateObjectiveNotesLoading}
                    onClick={() => {
                        if (!canEdit) {
                            return;
                        }
                        saveMarkdownChanges(markdownValue);
                    }}
                >
                    {updateObjectiveNotesLoading? <SpinnerWithOverlay />:
                    'Save'}
                </Button>
                <AutoSaveContainer>
                <AutoSave data={markdownValue} saveData={saveMarkdownChanges} isDirty={isDirty} />
                </AutoSaveContainer>
            </ActionsContainer>
        </MarkDownContainer>
    );
};

const MarkDownContainer = styled('div')`
    padding-top: 20px;
    .public-DraftEditor-content {
        min-height: 164px;
    }
`;

const ReactQuillWrap = styled((props: ReactQuillProps) => 
    <ReactQuill 
        theme="snow"
        {...props}
    />
)`
    && {
        font-family: inherit;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        min-height: 160px;
    }
`;

const ActionsContainer = styled('div')`
    padding: 20px 0;
`;

const AutoSaveContainer = styled('div')`
    padding-left: 10px;
    display: inline-flex;
`;

export default ObjectiveNotesContainer;
