import { gql } from 'apollo-boost';

export const CAMPAIGN_FRAMEWORK = gql`
    query assessmentMaturityOverviewData($campaignId: ID!, $assessmentId: ID!) {
        assessment(id: $assessmentId) {
            id
            campaignEntity {
                id
                name
                archivedDate
            }
            state {
                status
                domains {
                    id
                    name
                    averageScore
                    moderatedAverageScore
                    moderatedCapabilitiesCnt
                    targetAverageScore
                    weighting
                    capabilities {
                        id
                        name
                        status
                        averageScore
                        inScope
                        meetings {
                            id
                            title
                        }
                        moderatedScore
                        targetScore
                        weighting
                        objectives {
                            inScope
                            score
                            compensatingControlEnable
                            notes
                        }
                        workingNotes {
                            text
                        }
                        feedback {
                            observations {
                                text
                            }
                            recommendations {
                                text
                            }
                        }
                    }
                    feedback {
                        id
                        title
                        observations {
                            text
                        }
                        recommendations {
                            text
                        }
                    }
                }
                capabilitiesCnt
                averageScore
                progress
                capabilitiesNotStartedCnt
                capabilitiesInProgressCnt
                capabilitiesCompletedCnt
            }
            permission
        }
        frameworkByCampaign(campaignId: $campaignId) {
            id
            definition {
                name
                scores
                domains {
                    name
                    capabilities {
                        name
                        objectives {
                            name
                        }
                    }
                }
                hasObjectiveComponents
            }
        }
    }
`;
