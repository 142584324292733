import React from 'react';
import { styled } from '@mui/material/styles';

import { Flex } from '../../../../../components';

import { Stakeholder, User, CapabilityDropdown } from '../types';

import closeIcon from '../../../../../assets/images/Atmos_Icons_Close.svg';
import { stringTruncator } from '../../../../../utils/UtilsHelpers';
export const renderStakeholdersChips = (
    selectedValues: number[],
    allValues: Stakeholder[],
    setFieldValue: any,
    outer: boolean
) => {
    return selectedValues.map((selected: number) => {
        const chipsToDisplay = allValues.filter(
            (item: Stakeholder) => item.id === selected
        );

        return chipsToDisplay.map((chip: Stakeholder) => {
            return (
                <CustomChip
                    key={chip.id}
                    style={outer ? {} : { marginBottom: 0 }}
                >
                    <CustomChipContent>
                        {outer && (
                            <ChipsAvatar>
                                {chip.firstName &&
                                    chip.firstName[0].toUpperCase()}
                            </ChipsAvatar>
                        )}

                        {chip.firstName &&
                            chip.lastName &&
                            `${chip.firstName} ${chip.lastName}`}
                    </CustomChipContent>
                    {outer && (
                        <CloseIcon
                            src={closeIcon}
                            alt="delete"
                            onClick={() => {
                                const newValues = selectedValues.filter(
                                    (id: number) => id !== chip.id
                                );
                                setFieldValue(newValues);
                            }}
                        />
                    )}
                </CustomChip>
            );
        });
    });
};

export const renderCapabilityChips = (
    selectedValues: string[],
    allValues: CapabilityDropdown[],
    setFieldValue: any,
    outer: boolean
) => {
    return selectedValues.map((chip: string) => {
        const chipToDisplays = allValues.filter(
            (capability: CapabilityDropdown) => capability.id === chip
        );
        return chipToDisplays.map(
            (chipToDisplay: CapabilityDropdown, index) => (
                <CustomChip
                    key={index}
                    style={
                        outer
                            ? { marginRight: '16px' }
                            : { marginRight: '16px', marginBottom: 0 }
                    }
                >
                    <CustomChipContent>
                        {stringTruncator(
                            `${chipToDisplay.domain}: ${chipToDisplay.name}`,
                            36
                        )}
                    </CustomChipContent>
                    {outer && (
                        <CloseIcon
                            src={closeIcon}
                            alt='delete'
                            onClick={() => {
                                const newCapability = selectedValues.filter(
                                    (id: string) => id !== chipToDisplay.id
                                );
                                setFieldValue(newCapability);
                            }}
                        />
                    )}
                </CustomChip>
            )
        );
    });
};

export const renderUsersChips = (
    selectedValues: number[],
    allValues: User[],
    setFieldValue: any,
    outer: boolean
) => {
    return selectedValues.map((selected: number) => {
        const chipsToDisplay = allValues.filter(
            (item: Stakeholder) => item.id === selected
        );

        return chipsToDisplay.map((chip: User) => {
            return (
                <CustomChip
                    key={chip.id}
                    style={outer ? {} : { marginBottom: 0 }}
                >
                    <CustomChipContent>
                        {outer && (
                            <ChipsAvatar>
                                {chip.firstName &&
                                    chip.firstName[0].toUpperCase()}
                            </ChipsAvatar>
                        )}

                        {chip.firstName &&
                            chip.lastName &&
                            `${chip.firstName} ${chip.lastName}`}
                    </CustomChipContent>
                    {outer && (
                        <CloseIcon
                            src={closeIcon}
                            alt="delete"
                            onClick={() => {
                                const newValues = selectedValues.filter(
                                    (id: number) => id !== chip.id
                                );
                                setFieldValue(newValues);
                            }}
                        />
                    )}
                </CustomChip>
            );
        });
    });
};

const ChipsAvatar = styled('span')`
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fdb52b;
    border-radius: 50px;
    padding: 4px;
    font-size: 16px;
    color: black;
    margin-right: 16px;
`;

const CustomChip = styled('span')`
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: #eeeeee;
    border: 1 solid black;
    border-radius: 50px;
    padding: 0 16px;
    margin-right: 16px;
    margin-bottom: 16px;

    &:hover {
        img {
            opacity: 1;
        }
    }
`;

const CustomChipContent = styled(Flex)`
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 8px 0;
    justify-content: space-between;
    align-items: center;
    color: #575757;
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 500;
`;

const CloseIcon = styled('img')`
    cursor: pointer;
    width: 16px;
    margin-left: 16px;
    opacity: 0;
    transition: 0.4s;
`;
