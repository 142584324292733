import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { createTheme } from '@mui/material/styles';
import App from './modules/App/App';
import {client} from './services/ApolloClient/client';
import CustomRouter from './routes/router';
import { UserStoreProvider } from './context/user-store';
import { ThemeProvider } from '@mui/material/styles';
import './index.scss';
import { BrowserRouter } from './routes/BrowserRouter';
import 'react-quill/dist/quill.snow.css';
import { AuthProvider } from 'react-oidc-context';
import AuthConfig from './modules/AuthPage/AuthConfig';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4588c3'
        },
        secondary: {
            main: '#575757'
        }
    },
    typography: {
        fontFamily: 'CustomHelveticaNeue'
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <AuthProvider {...AuthConfig }>
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <BrowserRouter history={CustomRouter.history}>

                        <UserStoreProvider>
                            <App/>
                        </UserStoreProvider>

                </BrowserRouter>
            </ThemeProvider>
        </ApolloProvider>
    </AuthProvider>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
