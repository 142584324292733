import React, { useCallback, useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Delete } from '@mui/icons-material';
import { Button, ClickAwayListener } from '@mui/material';
import { XYCoord } from 'dnd-core';
import {
    useDrag,
    useDrop,
    DropTargetMonitor,
    DragSourceMonitor
} from 'react-dnd';

import {
    Text,
    Column,
    AlignItems,
    TextArea,
    DialogBox
} from '../../../../../components';

import iconListBordo from '../../../../../assets/images/icon-list-bordo.svg';
import dragAndDrop from '../../../../../assets/images/dragAndDrop.svg';

import {
    Observation as ObservationType,
    CurrentCapability,
    Values
} from '../../types';

import ReactQuill, { ReactQuillProps } from 'react-quill';

type ObservationProps = {
    referenceName: string;
    observation: ObservationType;
    sectionIndex: number;
    observationIndex: number;
    currentCapability: CurrentCapability;
    setFieldValue: any;
    values: Values;
    observationKey: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    canEdit?: boolean;
    dataLoading?: boolean;
    level?: string;
};

const ItemTypes = {
    CARD: 'card'
};

const Observation: React.FC<ObservationProps> = ({
    referenceName,
    observation,
    currentCapability,
    sectionIndex,
    setFieldValue,
    values,
    observationKey,
    observationIndex,
    moveCard,
    canEdit = true,
    dataLoading,
    level
}) => {
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const [isInitialRender,setIsInitialRender]=useState<boolean>(true);
    const [editorFlag, setFlag]=useState(false);

    const handleDelete = useCallback(() => {
        const result = [...values.feedback[sectionIndex].observations];
        result.splice(observationIndex, 1);
        setFieldValue(`feedback[${sectionIndex}].observations`, result);
    }, [values, sectionIndex, observationIndex, setFieldValue]);

    const [previousCapability, setPreviousCapability] = useState<string>('');

    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item: any, monitor: DropTargetMonitor) {
            if (!canEdit) {
                return;
            }
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = observationKey;

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current!.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, observationIndex, observationKey },
        canDrag: canEdit,
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging()
        }),
        type: ItemTypes.CARD
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const [markdownValue, setMarkdownValue] = useState<string>('');

    const handleProcedureContentChange = (content: any, delta: any, source: any, editor: any) => {
        setMarkdownValue(content);
    };

    useEffect(() => {
        if(previousCapability != ''){
            setMarkdownValue('');
        }
        setPreviousCapability(currentCapability.name);
    }, [currentCapability.name, level]);

    useEffect(() => {
        if(!isInitialRender){
            return;
        }
        setMarkdownValue(observation.text);
    }, [observation.text]);

    useEffect(() => {
        if (editorFlag) {
            setFieldValue(
                `feedback[${sectionIndex}].observations[${observationIndex}]`,
                {
                    text: markdownValue,
                }
            );
            setFlag(false);
        }
    }, [editorFlag, sectionIndex, observationIndex]);

    return (
        <Container ref={ref} style={{ opacity }}>
            <Header>
                <AlignItems>
                    <ItemdragAndDrop src={dragAndDrop} alt="drag and drop"/>
                    <LeftIcon src={iconListBordo} alt="observation" aria-hidden={true}/>
                    <IdText>
                        {`Observation - ${referenceName}.${sectionIndex + 1}.O${observationIndex + 1}`}
                    </IdText>
                </AlignItems>

                <AlignItems>
                    <ClickAwayListener
                        onClickAway={() => setIsOpenDeleteDialog(false)}
                    >
                        <HeaderActionText>
                            <DialogBox
                                title="Delete observation?"
                                buttonCancelText="Cancel"
                                buttonSaveText="Delete"
                                content="Deleting an observation is forever. There is no undo."
                                open={isOpenDeleteDialog}
                                handleClose={() => setIsOpenDeleteDialog(false)}
                                handleSubmit={handleDelete}
                            />
                            <Button
                                disabled={!canEdit}
                                onClick={() => setIsOpenDeleteDialog(true)}
                            >
                                {' '}
                                <DeleteIcon /> Delete{' '}
                            </Button>
                        </HeaderActionText>
                    </ClickAwayListener>
                </AlignItems>
            </Header>
            <MarkDownContainer onMouseLeave={() => setFlag(true)}>
                <ReactQuillWrap
                    readOnly={!canEdit || dataLoading}
                    value={markdownValue}
                    onChange={handleProcedureContentChange}
                />
            </MarkDownContainer>
        </Container>
    );
};

const Container = styled(Column)`
    border-radius: 2px;
    border: solid 1px #dadada;
    width: 100%;
    min-height: 134px;
    margin-bottom: 32px;
    cursor: 'move';
`;

const ItemdragAndDrop = styled('img')`
    width: 25px;
    margin-right: 5px;
`;

const Header = styled(AlignItems)`
    justify-content: space-between;
    padding: 12px 24px;
    border-bottom: 1px solid #dadada;
`;

const IdText = styled(Text)`
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #6E6E6E;
`;

const HeaderActionText = styled(AlignItems)`
    font-size: 14px;
    color: #6E6E6E;
    text-decoration: underline;
    margin-left: 32px;
    cursor: pointer;
    & button {
        font-size: 14px;
        color: #6E6E6E;
        text-decoration: underline;
        margin-left: 32px;
        padding: 0;
        text-transform: capitalize;
    }
`;

const Content = styled(AlignItems)`
    padding: 20px;
    display: flex;
`;

const LeftIcon = styled('img')`
    width: 20px;
    height: 100%;
    margin-right: 10px;
`;

const DeleteIcon = styled(Delete)`
    && {
        width: 16px;
        height: 100%;
    }
`;

const MarkDownContainer = styled('div')`
    padding-top: 20px;
    .public-DraftEditor-content {
        min-height: 164px;
    }
`;
const ReactQuillWrap = styled((props: ReactQuillProps) =>
    <ReactQuill
        theme="snow"
        {...props}
    />
)`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    && {
        font-family: inherit;
        padding-top: 0px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        padding: 16px;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1em;
        color: #2d2d2d;
        overflow-y: hidden;
        min-height: 160px;
    }

    .ql-toolbar {
        flex-wrap: wrap;
        box-shadow: 0px -1px 9px 0px #cdcdcd;
        margin: 0;
        border: 0;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
    }
`;

export default Observation;
