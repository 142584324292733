import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

const CustomSelect = styled(Select)`
    .MuiOutlinedInput-input {
        padding: 10px;
        width: 150px;
    }
`;

export default CustomSelect;
