import React, { useState, useEffect, useContext } from 'react';
import gql from 'graphql-tag';
import {
    Stepper,
    Step,
    StepLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SecondStep from './CreateCampaignSteps/SecondStep';
import FirstStepEdit from './CreateCampaignSteps/FirstStepEdit';
import Text from '../../components/base/Text/Text';
import Flex from '../../components/base/Layout/Flex';
import JustifyContentCenter from '../../components/base/Layout/JustifyContentCenter';
import AlignItems from '../../components/base/Layout/AlignItems';
import Button from '../../components/base/Buttons/Button';

import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import Router from '../../routes/router';
import { Stakeholder, Campaign, CampaignEntityGroup, PermissionObject, EntityPermissionObject, FirstStepEditTypes } from './types';
import closeIcon from '../../assets/images/Atmos_Icons_Close.svg';
import redArrowIcon from '../../assets/images/icon-right-arrow-red.svg';
import greyArrowIcon from '../../assets/images/icon-grey-arrow-left.svg';
import { ASSESSMENT_LIST_PAGINATE } from './query';
import { AppData } from '../../modules/App/types';
import { ASSESSMENT_FETCH_LIMIT, NotificationCategoryEnum, NotificationTypeEnum } from '../../utils';
import { useParams } from 'react-router';
import _ from 'lodash';
import { UserStore } from '../../context/user-store';
import { SpinnerWithOverlay, Toast } from '../../components';

const USER_DATA = gql`
    query USER_DATA {
        me {
            id
            firstName
            lastName
            jobTitle
            email
            role
            tosAcceptedAt
            countryUsers {
                countryId
                campaignCreateEnabled
                isCountryAdmin
            }
            clients {
                id
                name
                countryId
                isSynced
                country
                benchmarkingEnabled
                owner {
                    id
                }
            }
            campaigns {
                id
                title
                description
                benchmarkingEnabled
            }
        }
    }
`;


const CAMPAIGN = gql`
    query campaign3($id: ID!) {
        campaign(id: $id) {
            id
            title
            description
            status
            progress
            targetScoreEnabled
            targetScoreDefault
            pointOneDecimalEnable
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCompletedCnt
            assessmentsCnt
            client {
                id
                name
                country
                createdAt
                updatedAt
            }
            readOnly
            stakeholder {
                id
                firstName
                lastName
                jobTitle
                email
                createdAt
                updatedAt
            }
            manager {
                id
                firstName
                lastName
                jobTitle
                role
                updatedAt
                createdAt
            }
            members {
                id
                firstName
                lastName
                jobTitle
                role
                updatedAt
                createdAt
            }
            readonlymembers {
                id
                firstName
                lastName
                jobTitle
                role
                updatedAt
                createdAt
            }
            campaignEntityGroups {
                id
                name
                archivedDate
                type {
                    id
                    name
                }
                owner {
                    id
                    firstName
                    lastName
                    email
                }
                campaignEntities {
                    id
                    name
                    archivedDate
                    type {
                        id
                        name
                    }
                    owner {
                        id
                        firstName
                        lastName
                        email
                    }
                    stakeholders {
                        id
                        firstName
                        lastName
                        email
                    }
                    createdAt
                    updatedAt
                }
                stakeholders {
                    id
                    firstName
                    lastName
                    email
                }
                createdAt
                updatedAt
            }
            framework {
                id
                version
                definition {
                    name
                    shortName
                    scores
                    domains {
                        name
                        shortName
                        capabilities {
                            name
                            shortName
                            category
                            description
                            objectives {
                                name
                                reference
                                results
                            }
                        }
                    }
                    metadata {
                        frameworkNature
                        owner
                        publishDate
                        website
                        aidUrl
                    }
                }
                updatedAt
                createdAt
            }
            updatedAt
            createdAt
        }
    }
`;

const UPDATED_CAMPAIGN_ENTITY_GROUPS = gql`
    mutation updateCampaignEntityGroups(
        $campaignId: ID!
        $data: [UpdateCampaignEntityGroupInput!]!
        $newStakeHolders:[BasicStakeholderInput!]!
    ) {
        updateCampaignEntityGroups(campaignId: $campaignId, data: $data, newStakeHolders: $newStakeHolders) {
            id
            name
            archivedDate
            type {
                id
                name
            }
            owner {
                id
                firstName
                lastName
                email
            }
            campaignEntities {
                id
                name
                archivedDate
                type {
                    id
                    name
                }
                owner {
                    id
                    firstName
                    lastName
                    email
                }
                stakeholders {
                    id
                    firstName
                    lastName
                    email
                }
                createdAt
                updatedAt
            }
            stakeholders {
                id
                firstName
                lastName
                email
            }
            createdAt
            updatedAt
        }
    }
`;
const EDIT_CAMPAIGN = gql`
    mutation updateCampaign1($id: ID!, $data: UpdateCampaignInput!) {
        updateCampaign(id: $id, data: $data) {
            id
            title
            description
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCompletedCnt
            assessmentsCnt
            progress
            client {
                id
                name
            }
            stakeholder {
                id
                firstName
                lastName
            }
            manager {
                id
                firstName
                lastName
            }
            members {
                id
                firstName
                lastName
            }
            readonlymembers {
                id
                firstName
                lastName
            }
            framework {
                id
                definition {
                    name
                }
            }
            readOnly
            status
            updatedAt
        }
    }
`;

const UPDATE_PERMISSIONS = gql`
    mutation createOrUpdatePermissions($permissions: [CreateOrUpdateUserEntityPermissionInput!]!, $campaignId: ID) {
        createOrUpdatePermissions(userEntityPermissionInputs: $permissions, campaignId: $campaignId) {
            id
            userId
            campaignEntityId
            permission
        }
    }
`;

const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
            userId
            notificationType
            notificationCategory
            referenceID
            notificationMessage
            wasRead
        }
    }
`;


const GET_PERMISSIONS_BY_CAMPAIGN = gql`
    query getPermissionsByCampaign($campaignId: ID!) {
        getPermissionsByCampaign(campaignId: $campaignId) {
            userId
            campaignEntityId
            permission
        }
    }
`;



const getSteps = () => {
    return ['Edit campaign details', 'Edit entities and members'];
};

const stepNameResolver = (step: number) => {
    if (step === 1) {
        return 'Save & Next';
    }
    return 'Next';
};

const EditCampaign: React.FC = () => {
    const match = useParams();

    const [activeStep, setActiveStep] = useState<number>(
        Number(match.step) || 0
    );

    const loggedUser = useQuery<AppData>(USER_DATA) ;

    const steps = getSteps();
    const { data, loading } = useQuery<{ campaign: Campaign }>(CAMPAIGN, {
        variables: {
            id: match.campaignId
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getPermissionsByCampaign, { data: permissionData, loading: permissionDataLoading }] = useLazyQuery<{ getPermissionsByCampaign: PermissionObject[] }>(GET_PERMISSIONS_BY_CAMPAIGN);

    useEffect(()=>{
        getPermissionsByCampaign({ variables: { campaignId: match.campaignId } });
    },[activeStep]);

    const {
        state: { user, client }
    } = useContext(UserStore);

    const [editData, setEditData] = useState<Campaign>();

    const [secondStep, setSecondStep] = useState<CampaignEntityGroup[]>([]);
    const [newStakeHolders, setNewStakeholders] = useState<string[]>([]);
    const [allStakeholdersInCampaign, setAllStakeholdersInCampaign] = useState<string[]>([]);
    const [firstStepValid, setFirstStepValid] = useState();
    const [firstStepEditValues, setFirstStepEditValues] = useState<FirstStepEditTypes | undefined>();
    const [firstStepEditManagerIDs, setFirstStepEditManagerIDs] = useState();
    const [members, setMembers] = useState<number[]>([]);
    const [readOnlyMembers, setReadOnlyMembers] = useState<number[]>([]);
    const [editCampaign] = useMutation(EDIT_CAMPAIGN);
    const [updatePermissions] = useMutation(UPDATE_PERMISSIONS);
    const [createNotification] = useMutation(CREATE_NOTIFICATION);
    const [permissionStructureData, setPermissionStructureData] = useState<PermissionObject[]>([]);
    const [entityPermission, setEntityPermission] = useState<EntityPermissionObject[]>([]);
    const [disableDoneButton, setDisableDoneButton] = useState(false);
    const [managerChanged, setManagerChanged] = useState<boolean>(false);
    const [newlyAddedManager, setNewlyAddedManager] = useState<number[]>([]);
    const [selectedManagers, setSelectedManager] =  useState<any>(editData &&  editData.manager.map(manager => manager.id));
    const mangerIds = editData &&  editData.manager.map(manager => manager.id);
    const [targetScore, setTargetScore] = useState<any>();
    const [pointDecimal, setPointDecimal] = useState<any>();
    const canEditCampaign = secondStep && secondStep.length > 0 && secondStep.every((item: any) => item.campaignEntities.length > 0);

    useEffect(() => {
        data && setEditData(data.campaign);
        setMembers(data ? data.campaign.members.map((m) => (m.id), []) : []);
        setReadOnlyMembers(data && data.campaign.readonlymembers ? data.campaign.readonlymembers?.map((rm) => (rm.id), []) : []);
    }, [data, loading]);

    useEffect(() => {
        if (permissionData && data) {
            const prePermissions: PermissionObject[] = [];
            if (data.campaign.members && data.campaign.members.length > 0) {
                data.campaign.members.forEach((member) => {
                    prePermissions.push({
                        level: 'Campaign',
                        campaignMember: member.id,
                        campaignReadOnlyMember: undefined,
                        campaignEntityId: undefined,
                        userId: undefined,
                        permission: undefined,
                    });
                });
            }

            if (data.campaign.readonlymembers && data.campaign.readonlymembers.length > 0) {
                data.campaign.readonlymembers.forEach((member) => {
                    prePermissions.push({
                        level: 'Campaign',
                        campaignMember: undefined,
                        campaignReadOnlyMember: member.id,
                        campaignEntityId: undefined,
                        userId: undefined,
                        permission: undefined,
                    });
                });
            }

            if (permissionData && permissionData.getPermissionsByCampaign.length > 0) {
                const entityPermissions = _.cloneDeep(permissionData.getPermissionsByCampaign);
                entityPermissions.forEach((permission) => {
                    prePermissions.push({
                        level: 'Entity',
                        campaignMember: undefined,
                        campaignReadOnlyMember: undefined,
                        campaignEntityId: permission.campaignEntityId,
                        userId: permission.userId,
                        permission: permission.permission,
                    });
                });
            }

            if (prePermissions.length > 0) {
                setPermissionStructureData(prePermissions);
            }
        }
    }, [permissionData, permissionDataLoading, data, loading]);

    useEffect(() => {
        if (permissionStructureData && permissionStructureData.length > 0) {
            const permissions = permissionStructureData.filter((permission) => {
                return permission.campaignEntityId !== undefined && permission.userId !== undefined && permission.permission !== undefined;
            }).map((permission: PermissionObject) => {
                return {
                    userId: permission.userId,
                    campaignEntityId: permission.campaignEntityId,
                    permission: permission.permission,
                };
            }, []);
            setEntityPermission(permissions);
        }
    }, [permissionStructureData]);

    const getSecondStepValues = (values: CampaignEntityGroup[]) => setSecondStep(values);

    const [updateEntities] = useMutation(UPDATED_CAMPAIGN_ENTITY_GROUPS, {
        refetchQueries: [
            {
                query: CAMPAIGN,
                variables: {
                    id: match.campaignId
                }
            },
            {
                query: ASSESSMENT_LIST_PAGINATE,
                variables: {
                    campaignId: match.campaignId,
                    id: match.campaignId,
                    pagination: {
                        skip: 0 * ASSESSMENT_FETCH_LIMIT,
                        take: ASSESSMENT_FETCH_LIMIT
                    }
                }
            }
        ]
    });

    const getEditData = () => {
        return editData ? editData.campaignEntityGroups.map((el: CampaignEntityGroup) => {
            return {
                id: el.id,
                name: el.name,
                editKey: el.id,
                campaignEntities: el.campaignEntities.map(
                    (entity: any) => ({
                        id: entity.id,
                        editKey: entity.id,
                        name: entity.name,
                        ownerId: entity.owner ? entity.owner.id : null,
                        entityTypeId: entity.type.id,
                        stakeholderIds: entity.stakeholders.map(
                            (
                                stakeholder: Stakeholder
                            ) => stakeholder.id
                        ),
                        archivedDate: entity.archivedDate
                    })
                ),
                ownerId: el.owner ? el.owner.id : null,
                entityTypeId: el.type.id,
                stakeholderIds: el.stakeholders.map(
                    (
                        stakeholder: Stakeholder
                    ) => stakeholder.id
                ),
                archivedDate: el.archivedDate,
            };
        }) : [];
    };

    const GetStepContent = (
        stepIndex: number,
        firstStepEditValues: FirstStepEditTypes | undefined,
    ) => {
        if (!loading && editData) {
            switch (stepIndex) {
                case 0:
                    return (<FirstStepEdit 
                            campaignData={editData} 
                            setFirstStepValid={setFirstStepValid} 
                            setFirstStepEditValues={setFirstStepEditValues}
                            firstStepEditValues={firstStepEditValues}
                            setFirstStepEditManager={setFirstStepEditManagerIDs}
                            setManagerChanged={setManagerChanged}
                            setNewlyAddedManager={setNewlyAddedManager}
                            setSelectedManager={setSelectedManager}
                            setTargetScore={setTargetScore}
                            setPointDecimal={setPointDecimal}
                            />);
                case 1:
                    return (
                        <SecondStep
                            getSecondStepValues={getSecondStepValues}
                            campaignTitle={editData?.title}
                            secondStep={getEditData()}
                            clientId={editData &&
                                editData.client &&
                                editData.client.id}
                            newStakeHolders={newStakeHolders}
                            setNewStakeholders={setNewStakeholders}
                            allStakeholdersInCampaign={allStakeholdersInCampaign}
                            setAllStakeholdersInCampaign={setAllStakeholdersInCampaign}
                            campaignId={editData && editData.id}
                            setMembers={setMembers}
                            setReadOnlyMembers={setReadOnlyMembers}
                            members={members}
                            managerId={selectedManagers ? selectedManagers : mangerIds }
                            readOnlyMembers={readOnlyMembers}
                            permissionStructureData={permissionStructureData}
                            setPermissionStructureData={setPermissionStructureData}/>
                    );
                default: return 'Uknown stepIndex';
            }
        }
    };

    const handleNext = async () => {
        setManagerChanged(false);
        if (activeStep === 1 && secondStep.length && secondStep.some(
            (el: CampaignEntityGroup) => el.campaignEntities.length
        )) {
            const entiryPermissionWithEntityId: EntityPermissionObject[] = [];
            const allUsedStakeholders = newStakeHolders.filter(
                (sh: string) => allStakeholdersInCampaign.includes(sh)
            );
            const withKeyData = _.cloneDeep(secondStep);
            const withoutKeys = secondStep.map(
                (el: any) => {
                    const attrToRemove: string[] = [
                        'editKey',
                        'children',
                        'archivedDate',
                        'expanded',
                        'type'
                    ];
                    attrToRemove.forEach(
                        e => delete el[e]
                    );

                    let parentOwnerIndex = null;
                    const parentStakeholderIndexes: Array<number> = [];

                    if(allUsedStakeholders.includes(el.ownerId)){
                        parentOwnerIndex = allUsedStakeholders.indexOf(JSON.parse(JSON.stringify(el.ownerId)));
                        el.ownerId = null;
                    }
                    el.stakeholderIds = el.stakeholderIds.filter((shId: string)=>{
                        if(allUsedStakeholders.includes(shId)){
                            parentStakeholderIndexes.push(allUsedStakeholders.indexOf(shId));
                        }
                        return !allUsedStakeholders.includes(shId);
                    });

                    el.ownerIndex = parentOwnerIndex;
                    el.stakeholderIndexes = parentStakeholderIndexes;
                    return {
                        ...el, campaignEntities: el && el.campaignEntities.map((entity: any) => {
                            attrToRemove.forEach(
                                e =>
                                    delete entity[
                                        e
                                    ]
                            );
                            let entityOwnerIndex = null;
                            const entityStakeholderIndexes: Array<number> = [];
                            if(allUsedStakeholders.includes(entity.ownerId)){
                                entityOwnerIndex = allUsedStakeholders.indexOf(JSON.parse(JSON.stringify(entity.ownerId)));
                                entity.ownerId = null;
                            }
                            entity.stakeholderIds = entity.stakeholderIds.filter((shId: string)=>{
                                if(allUsedStakeholders.includes(shId)){
                                    entityStakeholderIndexes.push(allUsedStakeholders.indexOf(shId));
                                }
                                return !allUsedStakeholders.includes(shId);
                            });
                            entity.ownerIndex = entityOwnerIndex;
                            entity.stakeholderIndexes = entityStakeholderIndexes;
                            return entity;
                        })
                    };
                }
            );
            const newFormat = allUsedStakeholders.map((sh: string) => {
                const firstName = sh.split(/ (.+)/)[0];
                const lastName = sh.split(/ (.+)/)[1];
                return {
                    firstName: firstName,
                    lastName: lastName
                };
            });
            setDisableDoneButton(true);
            await updateEntities({
                variables: {
                    campaignId: editData && editData.id,
                    newStakeHolders: newFormat,
                    data: withoutKeys
                }
            }).then((resp: any) => {
                if (resp && resp.data && resp.data.updateCampaignEntityGroups.length && entityPermission.length) {
                    const newEntitiesWithPermissions = withKeyData.map((entityGroup: any) => {
                        return {
                            editKey: entityGroup.editKey,
                            name: entityGroup.name,
                            campaignEntities: entityGroup.campaignEntities.map((campaignEntity: any) => {
                                return {
                                    name: campaignEntity.name,
                                    editKey: campaignEntity.editKey
                                };
                            }, []).filter((campaignEntity: any) => {
                                return entityPermission.map(p => p.campaignEntityId).includes(campaignEntity.editKey);
                            }, [])
                        };
                    }, []);

                    const existingEntityGroup = resp.data.updateCampaignEntityGroups.map((entityGroup: any) => {
                        return {
                            id: entityGroup.id,
                            name: entityGroup.name,
                            campaignEntities: entityGroup.campaignEntities.map((campaignEntity: any) => {
                                return {
                                    name: campaignEntity.name,
                                    id: campaignEntity.id
                                };
                            }, [])
                        };
                    }, []);

                    const permissionIdRefs: any[] = [];

                    existingEntityGroup.forEach((entity: any) => {
                        const treeEntity = newEntitiesWithPermissions.find(e => e.name === entity.name);
                        if (entity.campaignEntities.length && treeEntity?.campaignEntities.length) {
                            entity.campaignEntities.forEach((campaignEntity: any) => {
                                const treeCampaignEntity = treeEntity.campaignEntities.find((e: any) => e.name === campaignEntity.name);
                                if (treeCampaignEntity) {
                                    permissionIdRefs.push({
                                        ...treeCampaignEntity,
                                        refId: campaignEntity.id
                                    });
                                }
                            });
                        }
                    });

                    entityPermission.forEach((p) => {
                        const newId = permissionIdRefs.find(pr => pr.editKey === p.campaignEntityId).refId;
                        entiryPermissionWithEntityId.push({
                            campaignEntityId: newId,
                            permission: p.permission,
                            userId: p.userId
                        });
                    });
                }
            }).catch((err) => {
                console.error(err);
            });
            const relationshipManager = user?.clients?.find((userData: any) => userData.id === editData?.client.id );
            const {frameworkId , clientId, ...dataWithoutFrameworkdId} = firstStepEditValues || {};
            const editedFirstStepData = {...dataWithoutFrameworkdId, ...targetScore, ...pointDecimal };
            await editCampaign({
                variables: {
                    id: editData && editData.id,
                    data: {
                        ...editedFirstStepData,
                        memberIds: members.length>1 ? members.filter(member => member !== relationshipManager?.owner?.id) : members,
                        managerId: firstStepEditManagerIDs,
                        readOnlyMemberIds: readOnlyMembers
                    }
                },
            }).then(async (response) => {
                    await updatePermissions({
                        variables: {
                            permissions: entiryPermissionWithEntityId,
                            campaignId: editData && editData.id
                        }
                    });

                const memberIds = _.union(members,firstStepEditManagerIDs).concat(readOnlyMembers);
                const notificationList = response.data.updateCampaign.manager.find((managerInfo: any) => managerInfo.id === relationshipManager?.owner?.id) ? 
                                        memberIds : memberIds.filter(member => member !== relationshipManager?.owner?.id);
                if ( notificationList.length > 0 ) {
                    await notificationList.map(async (member)=>{
                        let message = '';
                        if(newlyAddedManager.length > 0 && newlyAddedManager.includes(member)){
                           
                            message = `You are now a Campaign Manager in ${response.data.updateCampaign.title} for ${response.data.updateCampaign.client.name}. \nPlease check if any actions need your attention.`;
                        }
                        message.length > 0 &&  await createNotification(
                            {variables:
                                {
                                    input: {
                                        userId: member,
                                        notificationType: NotificationTypeEnum.INFO,
                                        notificationCategory: NotificationCategoryEnum.CAMPAIGN,
                                        notificationMessage:  message,
                                        referenceID: JSON.stringify({
                                            companyId: editData &&
                                            editData.client &&
                                            editData.client.id,
                                            campaignId: editData && editData.id
                                        }) ,
                                        wasRead: false
                                    },
                                },
                            }
                        );
                    });
                }
                Router.goToMaturityOverview(response.data.updateCampaign.id);
            }).catch((err)=>{
                console.error(err);
            });
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    useEffect(()=>{
        if( data && !(data?.campaign.manager.map((manager) => manager.id).includes(user && user.id!)) && client.owner?.id !== user.id && managerChanged){
                 setTimeout(() => {
                    Router.goToDashboard();
                }, 3000);
        } 
        else if(data && !(data?.campaign.manager.map((manager) => manager.id).includes(user.id!)) &&  client.owner?.id !== user.id ){
            Router.goToDashboard();
        }
    }, [data]);


    const handleBack = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    return loading ? (
        <SpinnerWithOverlay />
    ) : (
        <EditCampaignContainer>
            <Flex style={{ justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        Router.goToDashboard();
                    }}
                    style={{
                        border: 'none',
                        backgroundColor: 'inherit',
                    }}
                >
                   <img src={closeIcon} alt='close' id="skip-to-content" tabIndex={-1}/>
                </Button>
            </Flex>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{
                    margin: '0 auto',
                    backgroundColor: '#f8f8f8',
                    color: '#575757'
                }}
            >
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <JustifyContentCenter
                style={{
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    border: 'solid 1px #d9d9d9'
                }}
            >
                {activeStep === steps.length ? (
                    <div>Campaign created</div>
                ) : (
                    <div style={{ width: '100%' }}>
                        <InstructionText>
                            {GetStepContent(
                                activeStep,
                                firstStepEditValues
                                )}
                        </InstructionText>
                        <AlignItems
                            style={{
                                justifyContent: 'flex-end',
                                marginRight: 44,
                                marginBottom: 12,
                                marginTop: 50
                            }}
                        >
                            {activeStep !== 0 && (
                                <BackButtonCust
                                    onClick={handleBack}>
                                    <AlignItems>
                                        <img src={greyArrowIcon} alt="back arrow"  aria-hidden={true}/>
                                        <ButtonText
                                            color="#6E6E6E"
                                            style={{ marginLeft: 23 }}
                                        >
                                            Back
                                        </ButtonText>
                                    </AlignItems>
                                </BackButtonCust>
                            )}
                            {activeStep !== steps.length - 1 ? (
                                <NextButton
                                    disabled={
                                        !!(
                                            activeStep === 0 && !firstStepValid)||
                                            !!(
                                                activeStep === 1 &&
                                                !secondStep.length ||
                                                secondStep.some(
                                                    (el: CampaignEntityGroup) =>
                                                        !el.campaignEntities
                                                            .length
                                                )
                                        )
                                    }
                                    onClick={handleNext}
                                >
                                    <AlignItems>
                                        <ButtonText
                                            color="#de3328"
                                            style={{ marginRight: 31 }}
                                        >
                                            {stepNameResolver(activeStep)}
                                        </ButtonText>
                                        <img
                                            src={redArrowIcon}
                                            style={{
                                                transform: 'rotate(180deg)'
                                            }}
                                            alt="forward arrow"
                                            aria-hidden={true}
                                        />
                                    </AlignItems>
                                </NextButton>
                            ) : (
                                <NextButton onClick={handleNext} disabled={ disableDoneButton || !canEditCampaign }>
                                    <ButtonText color="#de3328">
                                        Done
                                    </ButtonText>
                                </NextButton>
                            )}
                        </AlignItems>
                    </div>
                )}
            </JustifyContentCenter>
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={managerChanged}
                autoHideDuration={8000}
                icon="check"
                title={'Campaign manager updated successfully '}
                closeToast={setManagerChanged}
            />
        </EditCampaignContainer>
    );
};

const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
`;

const EditCampaignContainer = styled('div') `
    width: 100%,
    margin-Top: 50,
`;
const InstructionText = styled(Text)`
    margin-top: 1px;
    margin-bottom: 1px;
`;


const BackButton = styled(Button)`
    && {
        border: none;
        padding: 0 21px 0 19px;
        min-height: 48px;
        &:hover: {
            opacity: 1;
            background-color: inherit;
        }
    }
`;

const BackButtonCust = styled(BackButton)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const NextButton = styled(Button)`
    && {
        border: solid 1px #de3328;
        padding: 0 21px 0 19px;
        margin-left: 48px;
        min-height: 48px;
        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            opacity: 0.9;
        }
    }
`;

export default EditCampaign;
