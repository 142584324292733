import { styled } from '@mui/material/styles';
import {SpaceBetween} from '../Layout';

const ApplyAndClearWrapper = styled(SpaceBetween)`
    padding: 8px 16px 0;
    width: 100%;
    box-sizing: border-box;
    height: 48px;
`;

export default ApplyAndClearWrapper;