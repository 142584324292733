import React, { useState, useContext, useLayoutEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { ChatBot, Column } from '../../components';
import HeaderContainer from '../Layout/Header/HeaderContainer';
import { Footer } from '../Layout';
import Router from '../../routes/router';
import SubHeader from '../Layout/Header/SubHeader';
import TermOfUseSection from '../TermsOfUse/TermOfUseSection';
import { UserStore } from '../../context/user-store';
import { Routes as Routesenum } from '../../routes/routes.enum';
import { isChatBotEnabled } from '../../utils/global-settings';
import { useOutletContext } from 'react-router-dom';

const Main: React.FC = () => {
    const {
        state: { user, globalSettings }
    } = useContext(UserStore);
    const route = Router.getCurrentPath();
    const [isAccepted, setIsAccepted] = useState<boolean>(false);
    const [refreshState, setRefreshState] = useState<number>(Math.random());

    useLayoutEffect(() => {
        if (user.tosAcceptedAt) {
            setIsAccepted(!!user.tosAcceptedAt);
        }
    }, [user.tosAcceptedAt]);
    return (
        <MainContentWrapper>
            {isAccepted ? (
                <>
                    <div>
                        <HeaderContainer setRefreshState={setRefreshState} />
                        {(route === Routesenum.evaluateCampaign ||
                            route === Routesenum.evaluateAssessments) && (
                            <SubHeader route={route} />
                        )}
                        <SubroutesWrapper>
                           <Outlet context={{setRefreshState}}/>
                        </SubroutesWrapper>
                        {isChatBotEnabled(globalSettings) && (
                        <>
                        <ChatBot children={''}/>
                        </>
                        )}
                    </div>
                    <Footer />
                </>
            ) : (
                <TermOfUseSection setIsAccepted={setIsAccepted} />
            )}
        </MainContentWrapper>
    );
};


const MainContentWrapper = styled(Column)`
    justify-content: space-between;
    height: 100%;
`;

const SubroutesWrapper = styled('div')`
    width: 100%;
    min-height: 100vh;
    padding: 0 5% 0 5%;
    box-sizing: border-box;
`;

type ContextType = { setRefreshState : any};

export function useRefreshState() {
    return useOutletContext<ContextType>();
  }

export default Main;
