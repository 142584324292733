import { VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';


const DescopeIcon = styled(VisibilityOff)`
    color: #575757;
    padding: 8px;
`;

export default DescopeIcon;
