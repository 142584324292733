import React, { useState, useEffect, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { UserStore } from '../../../../../../context/user-store';

import {
    squireColorResolver,
    textColorResolver,
    roundToTwoDecimailPlaces
} from '../../../../../../utils';

import {
    ThreatAssessment,
    Threat,
    ThreatObjective,
    ThreatAssessmentCapability,
    ratingsType
} from '../../../types';

import iconStarBlack from '../../../../../../assets/images/icon-star-black.svg';
import { threatRating } from '../../../constants';
import { Divider, IconButton } from '@mui/material';
import { ArrowTooltip } from '../../../../../../components';
import { convertThreatResilienceScoreFromPerc } from '../../../constants';

type Props = {
    tableRef?: any;
    rowId: number;
    isOpen: boolean;
    assessment?: ThreatAssessment;
    assessments: any;
    threatDashboard: Threat[];
    isOnlyKeyVisible: boolean;
    isGuidance: boolean;
    listOfAssessmentIds?: string[];
};

const AssessmentRow: React.FC<Props> = ({
    tableRef,
    rowId,
    isOpen,
    assessment,
    assessments,
    threatDashboard,
    isOnlyKeyVisible,
    isGuidance,
    listOfAssessmentIds
}) => {
    const assessmentRef = useRef<HTMLTableDataCellElement>(null);
    const average = (array: any) =>
        array.reduce((a: any, b: any) => a + b) / array.length;
    const [isAssessmentOpen, setAssessmentOpen] = useState<boolean>(false);

    useEffect(() => {
        if (
            !!tableRef &&
            !!tableRef.current &&
            !!assessmentRef &&
            !!assessmentRef.current
        ) {
            assessmentRef.current.height = String(
                tableRef.current.rows[rowId].offsetHeight - 26
            );
        }
    });

    useEffect(() => {
        setAssessmentOpen(isOpen);
    }, [isOpen]);

    const {
        state: { currentTheme }
    } = useContext(UserStore);

    const displayAssessmentContent = (
        index: number,
        threat: Threat,
        currentAssessment?: ThreatAssessment,
        threatRates?: ratingsType,
        capabilities?: ThreatAssessmentCapability[]
    ) => {
        return (
            <ThreatTd key={index}>
                <ArrowTooltip
                    title={
                        <TooltipContainer>
                            <div style={{ alignSelf: 'center' }}>
                                {threat.title}
                            </div>
                            <Divider />
                            <div>
                                {' '}
                                {'Resilience score: '}
                                {currentAssessment
                                    ? convertThreatResilienceScoreFromPerc(
                                          currentAssessment.threatResilienceScore
                                      ) + '/1000'
                                    : convertThreatResilienceScoreFromPerc(
                                          threat.averageThreatResilienceScore
                                      ) + '/1000'}
                            </div>
                            <ObjectiveType>
                                <TypeStyle>P</TypeStyle>
                                <TypeSore>
                                    {' '}
                                    {currentAssessment
                                        ? roundToTwoDecimailPlaces(
                                              currentAssessment.pResilienceScore
                                          )
                                        : roundToTwoDecimailPlaces(
                                              threat.pResilienceAverageScore
                                          )}
                                </TypeSore>
                                <TypeStyle>D</TypeStyle>
                                <TypeSore>
                                    {' '}
                                    {currentAssessment
                                        ? roundToTwoDecimailPlaces(
                                              currentAssessment.dResilienceScore
                                          )
                                        : roundToTwoDecimailPlaces(
                                              threat.dResilienceAverageScore
                                          )}
                                </TypeSore>
                                <TypeStyle>C</TypeStyle>
                                <TypeSore>
                                    {' '}
                                    {currentAssessment
                                        ? roundToTwoDecimailPlaces(
                                              currentAssessment.cResilienceScore
                                          )
                                        : roundToTwoDecimailPlaces(
                                              threat.cResilienceAverageScore
                                          )}
                                </TypeSore>
                            </ObjectiveType>
                        </TooltipContainer>
                    }
                    placement="bottom"
                    key={index}
                    minWidth={'0px'}
                    background={'white'}
                    popperColor={'white'}
                >
                    <ThreatColor
                        aria-label="threat info tooltip"
                        background={
                            (threatRates && threatRates.background) || 'none'
                        }
                        color={ (threatRates && threatRates?.title === 'Moderate') ? '#000000' : '#ffffff' || 'none' }
                    >
                        {currentAssessment
                            ? currentAssessment.threatResilienceRating
                            : threat.averageThreatResilienceRating}
                    </ThreatColor>
                </ArrowTooltip>
                {isAssessmentOpen &&
                    capabilities &&
                    capabilities.map(
                        (capability: ThreatAssessmentCapability) => {
                            return (
                                !!capability.objectives.length && (
                                    <>
                                        {isGuidance && (
                                            <CapabilityTitle>
                                                {capability.name}
                                            </CapabilityTitle>
                                        )}
                                        {capability.objectives.map(
                                            (objective: ThreatObjective) => {
                                                return (
                                                    <>
                                                        <CapabilityWrapper>
                                                            <ObjectiveWrapper>
                                                                <ObjectivesHeader>
                                                                    <ObjectiveTitle>
                                                                        {
                                                                            objective.name
                                                                        }
                                                                    </ObjectiveTitle>
                                                                    <ObjectiveKey>
                                                                        {objective.isKey && (
                                                                            <StarIcon
                                                                                src={
                                                                                    iconStarBlack
                                                                                }
                                                                            />
                                                                        )}
                                                                    </ObjectiveKey>
                                                                    <ObjectiveType>
                                                                        <TypeStyle>
                                                                            {
                                                                                objective.type
                                                                            }
                                                                        </TypeStyle>
                                                                    </ObjectiveType>
                                                                    <ObjectiveScoreWrapper>
                                                                        <ObjectiveScore
                                                                            background={
                                                                                objective.inScope &&
                                                                                capability.inScope
                                                                                    ? squireColorResolver(
                                                                                          objective.score
                                                                                              ? objective.score
                                                                                              : 0,
                                                                                          capability.status,
                                                                                          currentTheme
                                                                                      )
                                                                                    : 'repeating-linear-gradient(-45deg, transparent, transparent 8px, grey 11px, grey 7px)'
                                                                            }
                                                                            color={textColorResolver(
                                                                                objective.score
                                                                                    ? objective.score
                                                                                    : 0,
                                                                                capability.status,
                                                                                currentTheme
                                                                            )}
                                                                        >
                                                                            {objective.score !==
                                                                                null &&
                                                                            objective.inScope &&
                                                                            capability.inScope
                                                                                ? currentAssessment
                                                                                    ? objective.score
                                                                                    : objective.score.toFixed(
                                                                                          1
                                                                                      )
                                                                                : ''}
                                                                        </ObjectiveScore>
                                                                    </ObjectiveScoreWrapper>
                                                                </ObjectivesHeader>
                                                                {isGuidance && (
                                                                    <ObjectiveDescription>
                                                                        {
                                                                            objective.description
                                                                        }
                                                                    </ObjectiveDescription>
                                                                )}
                                                            </ObjectiveWrapper>
                                                        </CapabilityWrapper>
                                                    </>
                                                );
                                            }
                                        )}
                                        {isGuidance && <CapabilityDivider />}
                                    </>
                                )
                            );
                        }
                    )}
            </ThreatTd>
        );
    };

    const displayOverallCampaignContent = (threat: Threat, index: number) => {
        const threatCopy: Threat = JSON.parse(JSON.stringify(threat));
        let capabilities: ThreatAssessmentCapability[] = [];

        if (threatCopy.assessments && threatCopy.assessments.length > 0) {
            const allObjectivesToAverage: any = [];

            threatCopy.assessments.forEach(
                (assessmentMap: ThreatAssessment) => {
                    if (
                        listOfAssessmentIds &&
                        listOfAssessmentIds.includes(assessmentMap.id)
                    ) {
                        assessmentMap.capabilities.forEach(
                            (cap: any, capIndex: number) => {
                                cap.objectives.forEach(
                                    (obj: any, objIndex: number) => {
                                        if (obj.inScope && cap.inScope) {
                                            if (
                                                allObjectivesToAverage.length >
                                                0
                                            ) {
                                                const averagedObj = allObjectivesToAverage.filter(
                                                    (
                                                        objToAvg: any,
                                                        index: number
                                                    ) => {
                                                        if (
                                                            objToAvg.capIndex ===
                                                                capIndex &&
                                                            objToAvg.objIndex ===
                                                                objIndex &&
                                                            obj.score !== null
                                                        ) {
                                                            allObjectivesToAverage[
                                                                index
                                                            ].objScore.push(
                                                                obj.score
                                                            );
                                                        }
                                                        return (
                                                            objToAvg.capIndex ===
                                                                capIndex &&
                                                            objToAvg.objIndex ===
                                                                objIndex
                                                        );
                                                    }
                                                );
                                                if (
                                                    averagedObj.length === 0 &&
                                                    obj.score !== null
                                                ) {
                                                    allObjectivesToAverage.push(
                                                        {
                                                            capIndex,
                                                            objIndex,
                                                            objScore: [
                                                                obj.score
                                                            ]
                                                        }
                                                    );
                                                }
                                            } else if (obj.score !== null) {
                                                allObjectivesToAverage.push({
                                                    capIndex,
                                                    objIndex,
                                                    objScore: [obj.score]
                                                });
                                            }
                                        }
                                    }
                                );
                            }
                        );
                    }
                }
            );

            allObjectivesToAverage.forEach((objectivetoAverage: any) => {
                if (threatCopy.assessments) {
                    const averagedScore = average(objectivetoAverage.objScore);

                    threatCopy.assessments[0].capabilities[
                        objectivetoAverage.capIndex
                    ].objectives[
                        objectivetoAverage.objIndex
                    ].score = averagedScore;
                    if (averagedScore !== 0) {
                        threatCopy.assessments[0].capabilities[
                            objectivetoAverage.capIndex
                        ].status = 'SCORED';
                    }
                }
            });

            capabilities = threatCopy.assessments[0].capabilities;
        }

        if (isOnlyKeyVisible) {
            capabilities.forEach(capability => {
                const filteredObjectives = capability.objectives.filter(
                    objective => {
                        return objective.isKey === true;
                    }
                );

                capability.objectives = [...filteredObjectives];
            });
        }

        const rating = threat.averageThreatResilienceRating;
        const threatRates = threatRating.find(tr => tr.title === rating);

        return displayAssessmentContent(
            index,
            threat,
            undefined,
            threatRates,
            capabilities
        );
    };

    return (
        <>
            <Assessment ref={assessmentRef}>
                    <IconButton onClick={() => setAssessmentOpen(!isAssessmentOpen)}
                        aria-label="collapse or expand button"
                    >
                        {isAssessmentOpen ? <KeyboardArrowUpIcon /> :<KeyboardArrowDownIcon /> }
                    </IconButton>
                {assessment ? assessment.name : 'Overall Campaign'}
            </Assessment>
            {assessment
                ? threatDashboard &&
                  threatDashboard.map((threat: Threat, index: number) => {
                      const currentAssessment =
                          threat.assessments &&
                          threat.assessments.find(
                              (ass: ThreatAssessment) =>
                                  ass.id === assessment.id
                          );

                      const currentAssessmentCopy: ThreatAssessment = JSON.parse(
                          JSON.stringify(currentAssessment)
                      );
                      const capabilities: ThreatAssessmentCapability[] = [];

                      if (currentAssessment) {
                          capabilities.push(
                              ...currentAssessmentCopy.capabilities
                          );

                          if (isOnlyKeyVisible) {
                              capabilities.forEach(capability => {
                                  const filteredObjectives = capability.objectives.filter(
                                      objective => {
                                          return objective.isKey === true;
                                      }
                                  );

                                  capability.objectives = [
                                      ...filteredObjectives
                                  ];
                              });
                          }
                      }
                      const rating =
                          currentAssessment &&
                          currentAssessment.threatResilienceRating;
                      const threatRates = threatRating.find(
                          tr => tr.title === rating
                      );

                      return displayAssessmentContent(
                          index,
                          threat,
                          currentAssessment,
                          threatRates,
                          capabilities
                      );
                  })
                : threatDashboard &&
                  threatDashboard.map((threat: Threat, index: number) => {
                      return displayOverallCampaignContent(threat, index);
                  })}
        </>
    );
};

const CapabilityDivider = styled(Divider)`
    && {
        width: 104%;
        margin-left: -2%;
        margin-top: 2px;
        background-color: #999;
    }
`;

const ThreatColor = styled('button')<{ background?: string; color?: string }>`
    width: 232px;
    height: 40px;
    border-radius: 2px;
    background-color: ${props =>
        props.background ? props.background : 'none'};
    color: ${props =>
        props.color ? props.color : 'none'};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
`;

const Assessment = styled('td')`
    background: yellow;
    position: absolute;
    left: calc(5% + 31px);
    width: 228px;
    border: solid 1px #d7d7d7;
    background-color: #fbfbfb;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #2d2d2d;
    padding-top: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const StarIcon = styled('img')`
    width: 32px;
    position: relative;
    color: grey;
    top: 3px;
`;

const TypeStyle = styled('div')`
    width: 25px;
    height: 25px;
    border-radius: 20px;
    background-color: #2d2d2d;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-left: 2px;
    padding-bottom: 2px;
    margin-top: 6px;
`;

const CapabilityWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #d7d7d7;
`;

const CapabilityTitle = styled('div')`
    display: flex;
    color: #575757;
    text-align: left;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 12px;
`;

const ObjectiveTitle = styled('div')`
    display: flex;
    color: #575757;
    text-align: left;
    flex-basis: 50%;
    font-size: 15px;
    font-weight: bold;
    &:before {
        content: '• ';
        margin-right: 8px;
    }
`;

const ObjectiveWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;
const ObjectivesHeader = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const ObjectiveKey = styled('div')`
    width: 32px;
    position: relative;
    color: grey;
    flex-basis: 14%;
    justify-content: center;
`;

const ObjectiveType = styled('div')`
    display: flex;
    flex-basis: 15%;
    justify-content: center;
`;

const ObjectiveScoreWrapper = styled('div')`
    display: flex;
    flex-basis: 15%;
    justify-content: center;
`;

const ObjectiveScore = styled('div')<{
    background?: string;
    color?: string;
}>`
    background: ${props => (props.background ? props.background : 'none')};
    color: ${props => (props.color ? props.color : 'none')};
    width: 32px;
    height: 32px;
    border-radius: 2px;
    border: solid 0.5px #d7d7d7;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const ObjectiveDescription = styled('div')`
    display: flex;
    color: #575757;
    font-size: 15px;
    text-align: left;
    margin-bottom: 8px;
`;

const ThreatTd = styled('td')`
    padding: 12px;
    font-size: 16px;
    min-height: 62px;
    flex-direction: column;
    color: #2d2d2d;
    text-align: center;
    border: solid 1px #d7d7d7;
    background-color: #fbfbfb;
    width: 276px;
`;

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)`
    color: #2d2d2d;
`;

const KeyboardArrowUpIcon = styled(KeyboardArrowUp)`
    color: #2d2d2d;
`;

const TypeSore = styled('div')`
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
`;

const TooltipContainer = styled('div')`
    color: #000000;
    display: flex;
    flex-direction: column;
    margin: 8px;
`;

export default AssessmentRow;
