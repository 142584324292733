import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import SortableTree, { getVisibleNodeCount } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { Column, Text, AlignItems } from '../../../components';
import iconDelete from '../../../assets/images/delete-bin-grey.svg';
import iconAdd from '../../../assets/images/icon-add-grey-small.svg';
import iconDnD from '../../../assets/images/icon-drag&drop.svg';
import iconEdit from '../../../assets/images/mdi_edit.svg';
import iconArchive from '../../../assets/images/mdi_archive.svg';
import { CampaignEntityGroup, CampaignEntity } from './types';
import { Box, Button, Grid } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CampaignPermissionsModal from './CampaignPermissionsModal';
import { EntityDropdownData, PermissionLevel, PermissionObject } from '../types';
import { left } from '@popperjs/core';

const ARCHIVE_ENTITY = gql`
    mutation archiveCampaignEntity1($id: ID!) {
        archiveCampaignEntity(id: $id) {
            id
            archivedDate
        }
    }
`;

const ARCHIVE_ENTITY_GROUP = gql`
    mutation archiveCampaignEntityGroup1($id: ID!) {
        archiveCampaignEntityGroup(id: $id) {
            id
            archivedDate
        }
    }
`;

const RESTORE_ENTITY = gql`
    mutation restoreCampaignEntity2($id: ID!) {
        restoreCampaignEntity(id: $id) {
            id
            archivedDate
        }
    }
`;

const RESTORE_ENTITY_GROUP = gql`
    mutation restoreCampaignEntityGroup1($id: ID!) {
        restoreCampaignEntityGroup(id: $id) {
            id
            archivedDate
        }
    }
`;

type EntityResponse = {
    id: string;
    archivedDate: number;
};

type Props = {
    selectedEntityGroups: CampaignEntityGroup[];
    setSelectedEntityGroups: any;
    setEntityGroupOpen: any;
    setEntityOpen: any;
    setSelectedEntityGroup: any;
    setSelectedEntity: any;
    searchString: any;
    stakeholdersByClient: any;
    setClonedEntityGroups: any;
    campaignTitle?: string;
    clientId: number;
    members: any;
    managerId?: number[];
    readOnlyMembers: any;
    campaignId?: string;
    permissionStructureData: PermissionObject[];
    setPermissionStructureData: any;
};

type CanDropProps = {
    node: any;
    nextParent: any;
    prevPath: any;
    nextPath: any;
};

const Tree: React.FC<Props> = ({
    selectedEntityGroups,
    setSelectedEntityGroups,
    setEntityGroupOpen,
    setEntityOpen,
    setSelectedEntityGroup,
    setSelectedEntity,
    searchString,
    stakeholdersByClient = [],
    setClonedEntityGroups,
    campaignTitle,
    clientId,
    members,
    managerId,
    readOnlyMembers,
    campaignId,
    permissionStructureData,
    setPermissionStructureData
}) => {
    const [treeData, setTreeData] = useState<any[]>([]);
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [permissionLevel, setPermissionLevel] = useState<PermissionLevel>();
    const [entityDropdownData, setEntityDropdownData] = useState<EntityDropdownData>();
    const targetRef = useRef(null);
    const [treeWrapperHeight, setTreeWrapperHeight] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [archiveEntity] = useMutation(ARCHIVE_ENTITY, {
        onCompleted: (data: { archiveCampaignEntity: EntityResponse }) =>
            setSelectedEntityGroups((prev: CampaignEntityGroup[]) => {
                return prev.map((group: CampaignEntityGroup) => {
                    return {
                        ...group,
                        campaignEntities: group.campaignEntities.map(
                            (entity: CampaignEntity) => {
                                if (
                                    entity.id === data.archiveCampaignEntity.id
                                ) {
                                    return {
                                        ...entity,
                                        archivedDate:
                                            data.archiveCampaignEntity
                                                .archivedDate
                                    };
                                }
                                return entity;
                            }
                        )
                    };
                });
            })
    });
    const [restoreEntity] = useMutation(RESTORE_ENTITY, {
        onCompleted: (data: { restoreCampaignEntity: EntityResponse }) =>
            setSelectedEntityGroups((prev: CampaignEntityGroup[]) => {
                return prev.map((group: CampaignEntityGroup) => {
                    return {
                        ...group,
                        campaignEntities: group.campaignEntities.map(
                            (entity: CampaignEntity) => {
                                if (
                                    entity.id === data.restoreCampaignEntity.id
                                ) {
                                    return {
                                        ...entity,
                                        archivedDate:
                                            data.restoreCampaignEntity
                                                .archivedDate
                                    };
                                }
                                return entity;
                            }
                        )
                    };
                });
            })
    });
    const [archiveEntityGroup] = useMutation(ARCHIVE_ENTITY_GROUP, {
        onCompleted: (data: { archiveCampaignEntityGroup: EntityResponse }) =>
            setSelectedEntityGroups((prev: CampaignEntityGroup[]) => {
                return prev.map((group: CampaignEntityGroup) => {
                    if (group.id === data.archiveCampaignEntityGroup.id) {
                        return {
                            ...group,
                            archivedDate:
                                data.archiveCampaignEntityGroup.archivedDate
                        };
                    }
                    return group;
                });
            })
    });
    const [restoreEntityGroup] = useMutation(RESTORE_ENTITY_GROUP, {
        onCompleted: (data: { restoreCampaignEntityGroup: EntityResponse }) =>
            setSelectedEntityGroups((prev: any) => {
                return prev.map((group: CampaignEntityGroup) => {
                    if (group.id === data.restoreCampaignEntityGroup.id) {
                        return {
                            ...group,
                            archivedDate:
                                data.restoreCampaignEntityGroup.archivedDate
                        };
                    }
                    return group;
                });
            })
    });
    useEffect(() => {
        const dataForTree =[{
            name: campaignTitle,
            type: 'parent',
            expanded: true,
            children: selectedEntityGroups.map((group: CampaignEntityGroup) => ({
                type: 'group',
                expanded: true,
                ...group,
                children:
                    group.campaignEntities &&
                    group.campaignEntities.map((entity: any) => ({
                        name: entity.name,
                        type: 'entity',
                        expanded: true,
                        ...entity
                    }))
        }))
        }];
        setTreeData(dataForTree);
    }, [selectedEntityGroups]);

    const canDrop = ({ node, nextParent }: CanDropProps) => {
        if (nextParent && nextParent.type === 'entity') {
            return false;
        }
        if (node.type === 'entity' && nextParent === null) {
            return false;
        }
        if (node.type === 'parent') {
            return false;
        }
        if (
            node.type === 'group' &&
            nextParent &&
            nextParent.type === 'group'
        ) {
            return false;
        }
        return true;
    };

    const canDrag = ({ node }: any) => node.type === 'parent' ? false : true ;

    const customSearchMethod = ({ node, searchQuery }: any) => searchQuery && node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    useEffect(() => {
        if (targetRef.current != null) {
            const calculatedHeight = ((getVisibleNodeCount({ treeData })) * 80);
            setTreeWrapperHeight(calculatedHeight);
        }
    }, [treeData, targetRef]);

    const externalNodeType = 'yourNodeType';

    return (
        <TreeWrapper ref={targetRef} sx={{height: treeWrapperHeight}}>
            <CustomSortableTree
                treeData={treeData}
                shouldCopyOnOutsideDrop={() => {
                    return false;
                }}
                dndType={externalNodeType}
                rowHeight={80}
                canDrop={canDrop}
                canDrag={canDrag}
                onMoveNode={(args: any) => {
                    const newData = args.treeData[0].children.slice().map((arg: any) => ({
                        ...arg,
                        campaignEntities: arg.children
                            ? arg.children.map((child: any) => ({
                                ...child
                            }))
                            : []
                    })) ;
                    setSelectedEntityGroups(newData);
                }}
                onChange={() => {}}
                searchMethod={customSearchMethod}
                searchFinishCallback={(matches: any) => {
                    setSearchFocusIndex(
                        matches.length > 0
                            ? searchFocusIndex % matches.length
                            : 0
                    );
                }}
                searchFocusOffset={searchFocusIndex}
                searchQuery={searchString}
                generateNodeProps={({ node, parentNode }: any) => {
                    return {
                        scaffoldBlockPxWidth: 500,
                        toggleChildrenVisibility: null,
                        className: node.type === 'parent'? 'top_node': '',
                        title: (
                            <Column>
                                <NameText>{node.name}</NameText>
                                {stakeholdersByClient.map(
                                    (option: any) =>
                                        option.value === node.ownerId && option.status!=='ARCHIVED' && (
                                            <Text
                                                color="#818181"
                                                size="14px"
                                                key={option.value}
                                            >
                                                {`${option.firstName} ${option.lastName}`}
                                            </Text>
                                        )
                                )}
                            </Column>
                        ),
                        style: {
                            width: node.type === ('group'|| 'parent') ? '75vw' : '75vw',
                            height: '100%',
                            opacity: node.archivedDate ? 0.5 : 1,
                        },
                        buttons:
                            node.type === 'group'
                                ? [
                                    <TreeButton
                                        onClick={() => {
                                            setSelectedEntityGroup(node);
                                            setEntityOpen(true);
                                        }}
                                        key={1}
                                    >
                                        <ImgWrapper src={iconAdd} alt="add" aria-hidden={true}  style={{  marginTop: 4}}/>
                                        <ButtonText
                                            style={{ marginRight: 40,  marginTop: 4}}
                                        >
                                            Add entity {node.children.length > 0?'': '- Required'}
                                        </ButtonText>
                                    </TreeButton>,
                                    <TreeButton
                                        onClick={() => {
                                            setSelectedEntityGroup(
                                                parentNode
                                            );
                                            setSelectedEntityGroup(node);
                                            setEntityGroupOpen(true);
                                        }}
                                        key={2}
                                    >
                                        <ImgWrapper src={iconEdit} alt="add" aria-hidden={true} />
                                        <ButtonText
                                            style={{ marginRight: 42,textAlign: left}}
                                        >
                                            Edit
                                        </ButtonText>
                                    </TreeButton>,
                                    <TreeButton
                                        key={3}
                                        style={{
                                            display: node.id
                                                ? 'none'
                                                : 'flex'
                                        }}
                                        onClick={() => {
                                            setSelectedEntityGroups(
                                                selectedEntityGroups.filter(
                                                    (group: CampaignEntityGroup) =>
                                                        group.editKey !==
                                                        node.editKey
                                                )
                                            );
                                            setClonedEntityGroups(
                                                (prev: CampaignEntityGroup[]) => {
                                                    const filteredEntityGroups = selectedEntityGroups.find(
                                                        (group: CampaignEntityGroup) =>
                                                        group.editKey ===
                                                            node.editKey
                                                    );
                                                    return [
                                                        ...prev,
                                                        filteredEntityGroups
                                                    ];
                                                }
                                            );
                                        }}
                                    >
                                        <ImgWrapper src={iconDelete} alt="add" aria-hidden={true} />
                                        <ButtonText
                                            style={{ marginRight: 20 }}
                                        >
                                            Delete
                                        </ButtonText>
                                    </TreeButton>,
                                    <TreeButton
                                        style={{
                                            display: node.id
                                                ? 'flex'
                                                : 'none'
                                        }}
                                        key={10}
                                        onClick={() => {
                                            node.archivedDate
                                                ? restoreEntityGroup({
                                                        variables: {
                                                            id: node.id
                                                        }
                                                    })
                                                : archiveEntityGroup({
                                                        variables: {
                                                            id: node.id
                                                        }
                                                    });
                                        }}
                                    >
                                        <ImgWrapper src={iconArchive} alt="add" aria-hidden={true} />
                                        <ButtonText
                                            style={{ marginRight: 24 }}
                                        >
                                            {node.archivedDate
                                                ? 'Restore'
                                                : 'Archive'}
                                        </ButtonText>
                                    </TreeButton>
                                ]
                                :  node.type != 'parent' ?[
                                    <TreeButton
                                        onClick={() => {
                                            setSelectedEntityGroup(
                                                parentNode
                                            );
                                            setSelectedEntity(node);
                                            setEntityOpen(true);
                                        }}
                                        key={4}
                                    >
                                        <ImgWrapper src={iconEdit} alt="add" aria-hidden={true} />
                                        <ButtonText
                                            style={{ marginRight: 40 }}
                                        >
                                            Edit
                                        </ButtonText>
                                    </TreeButton>,
                                    <TreeButton
                                        key={5}
                                        style={{
                                            display: node.id
                                                ? 'none'
                                                : 'flex'
                                        }}
                                        onClick={() =>
                                            setSelectedEntityGroups(
                                                selectedEntityGroups.map(
                                                    (group: any) => ({
                                                        ...group,
                                                        campaignEntities: group.campaignEntities.filter(
                                                            (entity: any) =>
                                                                entity.editKey !==
                                                                node.editKey
                                                        )
                                                    })
                                                )
                                            )
                                        }
                                    >
                                        <ImgWrapper src={iconDelete} alt="add" aria-hidden={true} style={{marginTop: 4}} />
                                        <ButtonText
                                            style={{ marginRight: 25 , width: '52px', marginTop: '4px'}}
                                        >
                                            Delete
                                        </ButtonText>
                                    </TreeButton>,
                                    <TreeButton
                                            key={11}
                                            style={{
                                                display: node.id
                                                    ? 'flex'
                                                    : 'none'
                                            }}
                                            onClick={() => {
                                                node.archivedDate
                                                    ? restoreEntity({
                                                        variables: {
                                                            id: node.id
                                                        }
                                                    })
                                                    : archiveEntity({
                                                        variables: {
                                                            id: node.id
                                                        }
                                                    });
                                            }}>
                                            <ImgWrapper src={iconArchive} alt="add" aria-hidden={true} />
                                            <ButtonText style={{ marginRight: 55 }}>
                                                {node.archivedDate
                                                    ? 'Restore'
                                                    : 'Archive'}
                                        </ButtonText>
                                    </TreeButton>,
                                    <TreeButton
                                            onClick={() => {
                                                setPermissionLevel({
                                                    level: 'Entity',
                                                    id: node.editKey,
                                                });
                                                setEntityDropdownData({
                                                    level: 'Entity',
                                                    data: treeData,
                                                    name: node.editKey,
                                                });
                                                node.archivedDate ? setIsModalOpen(false) : setIsModalOpen(true);}}
                                            key={4}
                                        >
                                    <PersonAddIcon sx={{ color: '#000000'}} />
                                    <ButtonText
                                        style={{ marginRight: 10 }}
                                    >
                                        Members
                                    </ButtonText>
                                </TreeButton>

                                ]
                                : [
                                    <TreeButton
                                        onClick={() => {
                                            setPermissionLevel({
                                                level: 'Campaign',
                                                id: campaignId,
                                            });
                                            setEntityDropdownData({
                                                level: 'Campaign',
                                                data: treeData,
                                                name: treeData[0].name
                                            });
                                            setIsModalOpen(true);
                                        }}
                                        key={4}>
                                        <PersonAddIcon sx={{ color: '#000000'}} />
                                        <ButtonText
                                            style={{ marginRight: 40 }}
                                        >
                                            Campaign members
                                        </ButtonText>
                                    </TreeButton>
                                ]
                                };
                }}
            />
            <CampaignPermissionsModal
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                clientId={clientId}
                campaignId={campaignId}
                managerId={managerId}
                members={members}
                readOnlyMembers={readOnlyMembers}
                entityGroup={entityDropdownData}
                permissionLevel={permissionLevel}
                setPermissionLevel={setPermissionLevel}
                permissionStructureData={permissionStructureData}
                setPermissionStructureData={setPermissionStructureData}
            />
        </TreeWrapper>
    );
};

const CustomSortableTree = styled(SortableTree)`
.rst__moveHandle {
    background: 50% url(${iconDnD});
}
.rst__node {
    width: 100%;
    display: flex;
}

.rst__node {
    & > :nth-child(1) {
        :after {
            height: 0;
        }
    }

.top_node .rst__lineBlock
 {
    display: none !important;
}
`;


const TreeButton = styled(Button)`
    display: flex;
    align-items: center;
`;

const ButtonText = styled(Text)`
    font-size: 14px;
    color: #000000;
    margin-left: 8px;
    border-bottom: 0.5px #393939;
    text-transform: none;
`;

const NameText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    color: #2d2d2d;
`;

const ImgWrapper = styled('img')`
    filter:brightness(0);
`;

const TreeWrapper = styled(Box)`
    position: relative;
    background-color: #fafafa;
    padding-bottom: 40px;
    padding-top: 40px;
`;

export default Tree;
