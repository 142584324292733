import { styled } from '@mui/material/styles';
import RedButton from './RedButton';

const ApplyButton = styled(RedButton)`
    && {
        width: 128px;
        min-height: 40px;
        font-size: 18px;
    }
`;

export default ApplyButton;