import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import addIcon from '../../../../../../../assets/images/icon-add.svg';
import iconClock from '../../../../../../../assets/images/icon-clock.svg';

import {
    AlignItems,
    RedButton,
    TransparentButton,
    JustifyContentCenter,
    PopUpHeader
} from '../../../../../../../components';
import {
    AddThreatModalContentProps,
    DomainsDTO,
    ObjectivesDropdownList,
    ThreatObject,
    ThreatToBeSaved,
    ObjectivesToBeSaved
} from '../../../../types';
import ThreatOption from './ThreatOption';
import {
    INITIAL_THREAT_VALUES,
    OBJECTIVES_FOR_CAMPAIGN,
    renderModal,
    renderDeleteModal
} from '../../../../ThreatAndRiskHelpers';
import Text from '../../../../../../../components/base/Text';
import { Button } from '@mui/material';

const ThreatModalContent: React.FC<AddThreatModalContentProps> = ({
    threatsRetrieved,
    setOpen,
    selectedCampaign,
    openThreatId,
    isAddModal,
    setGuidance,
    isGuidance,
    saveThreats,
    saveThreatsLoading
}) => {
    const scrollRef = React.createRef<HTMLDivElement>();
    const [initialValues, setInitialValues] = useState<ThreatToBeSaved[]>([]);
    const [newThreats, setNewThreats] = useState<ThreatObject[]>(
        isAddModal ? [INITIAL_THREAT_VALUES] : []
    );
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [isExpandAll, setExpandAll] = useState<boolean>(true);
    const [allIncomingThreats, setAllIncomingThreats] = useState<
        ThreatObject[]
    >([]);
    const [isThreatDeleted, setThreatDeleted] = useState<boolean>(false);
    const [deletedThreatIndex, setDeletedThreatIndex] = useState<number>(0);
    const [isInitialLoad, setInitialLoad] = useState<boolean>(true);
    const saveRef = useRef(null);

    const { data, loading } = useQuery(OBJECTIVES_FOR_CAMPAIGN, {
        variables: {
            campaignId: selectedCampaign && selectedCampaign.id
        },
        fetchPolicy: 'no-cache'
    });

    const [deleteItem, setDeleteItem] = useState({
        objectId: 0,
        objectName: '',
        isOpen: false,
        modalType: 'threat'
    });

    useEffect(() => {
        setAllIncomingThreats(threatsRetrieved);
        setInitialLoad(true);
    }, []);

    const [objectivesGroupedByCap, setObjectivesGroupedByCap] = useState<ObjectivesDropdownList[]>([]);

    useEffect(() => {
        if (data) {
            const allObjectives = data.frameworkByCampaign.definition.domains;

            allObjectives.forEach((domain: DomainsDTO) => {
                domain.capabilities.forEach((cap: ObjectivesDropdownList) => {
                    const newObj = {
                        name: cap.name,
                        objectives: cap.objectives
                    };
                    objectivesGroupedByCap.push(newObj);
                });
            });
            setObjectivesGroupedByCap([...objectivesGroupedByCap]);
        }
    }, [data]);

    const detectChange = (values: ThreatToBeSaved[]) => {
        if (isInitialLoad) {
            return true;
        }
        if (values.length !== initialValues.length) {
            return false;
        }
        for (let index = 0; index <= values.length; index++) {
            const threat = values[index];
            if (threat) {
                if (
                    threat.description !== initialValues[index].description ||
                    threat.title !== initialValues[index].title
                ) {
                    return false;
                }
                if (
                    threat.objectives.length !==
                    initialValues[index].objectives.length
                ) {
                    return false;
                }
                for (let i = 0; i <= threat.objectives.length; i++) {
                    const objective = threat.objectives[i];
                    if (objective) {
                        const matchingObjective = initialValues[
                            index
                        ].objectives.find(
                            (obj: ObjectivesToBeSaved) =>
                                obj.reference === objective.reference
                        );
                        if (!matchingObjective) {
                            return false;
                        }
                        if (matchingObjective.isKey !== objective.isKey) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };

    const handleSaveThreats = (values: ThreatToBeSaved[]) => {
        values.forEach(el => {
            delete el.hasValidationErrors;
            if (!el.id) {
                delete el.id;
            }
            el.__typename && delete el.__typename;
            el.objectives &&
                el.objectives.forEach((objective: ObjectivesToBeSaved) => {
                    objective.__typename && delete objective.__typename;
                });
        });

        setInitialValues(JSON.parse(JSON.stringify(values)));
        setCanClose(true);

        saveThreats({
            variables: {
                campaignId: selectedCampaign && selectedCampaign.id,
                threats: values
            }
        })
            .then(() => {})
            .catch(() => {});
    };

    const [canClose, setCanClose] = useState<boolean>(true);

    const handleCloseModal = () => {
        canClose ? setOpen(false) : setConfirmationModal(true);
    };

    const handleSave = (valaditaionPassed: boolean) => {
        setConfirmationModal(false);

        if (valaditaionPassed && confirmationModal) {
            setOpen(false);
        }
    };

    return (
        <ContentWrapper>
            <PopUpHeader title="Threat scenarios" handleClose={handleCloseModal} />
            <Subheader>
                <AddButton
                    onClick={() => {
                        setNewThreats([INITIAL_THREAT_VALUES, ...newThreats]);
                        setSubmit(false);
                        if (scrollRef && scrollRef.current) {
                            scrollRef.current.scrollIntoView();
                        }
                    }}
                >
                    <Icon src={addIcon} alt="Add threat" />
                    Add threat scenario
                </AddButton>
            </Subheader>
            <Formik
                initialValues={[]}
                onSubmit={() => {}}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ submitForm, setFieldValue, values }) => {
                    detectChange(values)
                        ? setCanClose(true)
                        : setCanClose(false);

                    if (isInitialLoad &&
                        (values.length !== initialValues.length ||
                        values.some((value: any, index: number) => {
                            return value.objectives.length !== initialValues[index].objectives.length;
                        }))){
                        setInitialValues(JSON.parse(JSON.stringify(values)));
                    }
                    if (isThreatDeleted) {
                        values.splice(deletedThreatIndex, 1);
                    }
                    return (
                        <ThreatForm>
                            <ThreatOptionsWrapper  ref={scrollRef}>
                                <ExpandAll
                                    onClick={() => setExpandAll(!isExpandAll)}
                                >
                                    { isExpandAll ? 'Collapse all' : 'Expand all' }
                                </ExpandAll>
                                {newThreats.map(
                                    (threat: ThreatObject, index: number) => {
                                        return (
                                            <ThreatOption
                                                key={
                                                    newThreats.length -
                                                    1 -
                                                    index
                                                }
                                                id={
                                                    newThreats.length -
                                                    1 -
                                                    index
                                                }
                                                isNewThreat
                                                placeholderText="Add threat name"
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                allObjectives={
                                                    objectivesGroupedByCap
                                                }
                                                isSubmit={isSubmit}
                                                setDeleteItem={setDeleteItem}
                                                isExpandAll={isExpandAll}
                                                threat={threat}
                                                setThreatDeleted={
                                                    setThreatDeleted
                                                }
                                                isThreatDeleted={
                                                    isThreatDeleted
                                                }
                                                isGuidance={isGuidance}
                                                setGuidance={setGuidance}
                                                setInitialLoad={setInitialLoad}
                                                isInitialLoad={isInitialLoad}
                                            />
                                        );
                                    }
                                )}
                                {allIncomingThreats.map(
                                    (threat: ThreatObject, index: number) => {
                                        return (
                                            <ThreatOption
                                                id={newThreats.length + index}
                                                key={newThreats.length + index}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                allObjectives={
                                                    objectivesGroupedByCap
                                                }
                                                isNewThreat={false}
                                                threat={threat}
                                                isSubmit={isSubmit}
                                                setDeleteItem={setDeleteItem}
                                                isExpandAll={isExpandAll}
                                                setThreatDeleted={
                                                    setThreatDeleted
                                                }
                                                isThreatDeleted={
                                                    isThreatDeleted
                                                }
                                                openThreatId={openThreatId}
                                                isAddModal={isAddModal}
                                                isGuidance={isGuidance}
                                                setGuidance={setGuidance}
                                                setInitialLoad={setInitialLoad}
                                                isInitialLoad={isInitialLoad}
                                            />
                                        );
                                    }
                                )}
                            </ThreatOptionsWrapper>
                            <ThreatSaveWrapper>
                                {saveThreatsLoading && (
                                    <JustifyContentCenter>
                                        <Icon
                                            src={iconClock}
                                            width="18px"
                                            alt="Loading..."
                                        />
                                        <LoadingText>Saving...</LoadingText>
                                    </JustifyContentCenter>
                                )}
                                <ThreatSaveButton
                                    type="submit"
                                    variant="contained"
                                    disabled={canClose}
                                    ref={saveRef}
                                    onClick={() => {
                                        submitForm();
                                        setSubmit(true);
                                        if (
                                            values.find(
                                                (el: ThreatObject) =>
                                                    el.hasValidationErrors ===
                                                    true
                                            )
                                        ) {
                                            handleSave(false);
                                        } else {
                                            setSubmit(false);
                                            handleSaveThreats(values);
                                            handleSave(true);
                                        }
                                    }}
                                >
                                    Save
                                </ThreatSaveButton>
                            </ThreatSaveWrapper>
                        </ThreatForm>
                    );
                }}
            </Formik>
            {renderModal({
                handleDiscard: () => {
                    setConfirmationModal(false);
                    setOpen(false);
                },
                handleSave: () => {
                    (saveRef as any).current.click();
                },
                isOpen: confirmationModal,
                title: 'Save threat(s) before closing?',
                text: 'The changes made will be lost if discarded.'
            })}
            {renderDeleteModal({
                handleClose: () => {
                    setDeleteItem({
                        objectId: 0,
                        objectName: '',
                        isOpen: false,
                        modalType: ''
                    });
                },
                handleSend: () => {
                    setDeleteItem({
                        objectId: 0,
                        objectName: '',
                        isOpen: false,
                        modalType: ''
                    });
                    if (typeof deleteItem.objectId === 'number') {
                        const newArray = newThreats.filter(
                            (_threat: ThreatObject, index: number) =>
                                index !== deleteItem.objectId
                        );
                        setDeletedThreatIndex(deleteItem.objectId);
                        setNewThreats(newArray);
                        setThreatDeleted(true);
                    } else if (typeof deleteItem.objectId === 'string') {
                        const newArray = allIncomingThreats.filter(
                            (threat: any) => threat.id !== deleteItem.objectId
                        );
                        const index = allIncomingThreats.findIndex(
                            (threat: any) => threat.id === deleteItem.objectId
                        );
                        setDeletedThreatIndex(newThreats.length + index);
                        setAllIncomingThreats(newArray);
                        setThreatDeleted(true);
                        if (isInitialLoad) {
                            setInitialLoad(false);
                        }
                    }

                },
                isOpenDeleteConfirmation: deleteItem
            })}
        </ContentWrapper>
    );
};

const ExpandAll = styled(Button)`
    font-size: 15px !important;
    color: #2d2d2d !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    margin-bottom: 12px !important;
    margin-right: 47px !important;
    float: right;
`;

const LoadingText = styled(Text)`
    font-size: 16px;
    color: #575757;
    margin-right: 16px;
    margin-left: -2px;
`;

const Subheader = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #575757;
    width: 100%;
    min-height: 80px;
    position: fixed;
    top: 70px;
    z-index: 999;
`;

const ThreatOptionsWrapper = styled('div')`
    margin-top: 47px;
    margin-left: 48px;
`;

const ThreatSaveButton = styled(RedButton)<{ disabled?: boolean }>`
    && {
        margin-left: 24px;
        padding: 12px 55px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 2px;
        line-height: 1;
        text-transform: none;
        width: 154px;
        height: 48px;
        border-radius: 2px;
        margin-top: 17px;
        margin-right: 24px;
        color: ${props =>
            props.disabled ? 'rgba(0, 0, 0, 0.26) !important' : 'white'}
        background-color: ${props =>
            props.disabled ? 'rgba(0, 0, 0, 0.12) !important' : '#de3328'}
    }
`;

const AddButton = styled(TransparentButton)`
    && {
        margin-left: 48px;
        text-transform: none;
    }
`;

const ContentWrapper = styled(AlignItems)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const Icon = styled('img')`
    && {
        margin-right: 10px;
        height: 24px;
    }
`;

const ThreatForm = styled(Form)`
    && {
        margin-top: 78px;
        margin-bottom: 80px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }
`;

const ThreatSaveWrapper = styled('div')`
    width: 800px;
    min-height: 80px;
    background-color: #ffffff;
    justify-content: flex-end;
    display: flex;
    border-top: 1px solid #f4f4f4;
    position: fixed;
    bottom: 0;
    z-index: 999;
`;

export default ThreatModalContent;
