import React, { useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { FieldArray } from 'formik';
import update from 'immutability-helper';
import {HTML5Backend as Backend} from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {
    AlignItems,
    Column,
    Text,
    SpaceBetween
} from '../../../../../components';
import Recommendation from './Recommendation';
import Observation from './Observation';


import {
    Recommendations,
    Observation as ObservationType,
    CurrentCapability as CurrentCapabilityType,
    Values
} from '../../types';

import { generateTitle } from '../ModerationHelpers';

import dragAndDrop from '../../../../../assets/images/dragAndDrop.svg';
import { Button } from '@mui/material';

type ObservationsAndRecommendationsSectionProps = {
    referenceName: any;
    currentCapability: CurrentCapabilityType;
    feedbackIndex: number;
    setFieldValue: any;
    values: Values;
    canEdit: boolean;
    dataLoading?: boolean;
    level?: string;
};

const ObservationsAndRecommendationsSection: React.FC<ObservationsAndRecommendationsSectionProps> = ({
    referenceName,
    currentCapability,
    feedbackIndex,
    setFieldValue,
    values,
    canEdit = true,
    dataLoading,
    level
}) => {
    const handleAddObservation = useCallback(() => {
        setFieldValue(
            `feedback[${feedbackIndex}].observations`,
            values.feedback[feedbackIndex] &&
                values.feedback[feedbackIndex].observations.concat({ text: '' })
        );
    }, [values]);

    const handleAddRecommendation = useCallback(() => {
        setFieldValue(
            `feedback[${feedbackIndex}].recommendations`,
            values.feedback[feedbackIndex] &&
                values.feedback[feedbackIndex].recommendations.concat({
                    text: ''
                })
        );
    }, [values]);

    const handleDelete = useCallback(() => {
        const result = [...values.feedback];
        result.splice(feedbackIndex, 1);
        setFieldValue('feedback', result);
    }, [values]);

    const moveObservationCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard =
                values.feedback[feedbackIndex].observations[dragIndex];
            if (dragCard === undefined) {
                return;
            }

            setFieldValue(
                `feedback[${feedbackIndex}].observations`,
                update(values.feedback[feedbackIndex].observations, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard]
                    ]
                })
            );
        },
        [values.feedback[feedbackIndex].observations]
    );

    const moveRecommendationsCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard =
                values.feedback[feedbackIndex].recommendations[dragIndex];
            if (dragCard === undefined) {
                return;
            }

            setFieldValue(
                `feedback[${feedbackIndex}].recommendations`,
                update(values.feedback[feedbackIndex].recommendations, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard]
                    ]
                })
            );
        },
        [values.feedback[feedbackIndex].recommendations]
    );
    const renderRecommendations = useMemo(() => {
        if (
            values.feedback[feedbackIndex] &&
            values.feedback[feedbackIndex].recommendations
        ) {
            return values.feedback[
                feedbackIndex
            ].recommendations.map(
                (recommendation: Recommendations, index: number) => (
                    <Recommendation
                        referenceName={referenceName}
                        key={`feedback[${feedbackIndex}].recommendations[${index}]`}
                        recommendation={recommendation}
                        setFieldValue={setFieldValue}
                        values={values}
                        sectionIndex={feedbackIndex}
                        recommendationIndex={index}
                        currentCapability={currentCapability}
                        recommendationKey={index}
                        moveCard={moveRecommendationsCard}
                        canEdit={canEdit}
                        dataLoading={dataLoading}
                        level={level}
                    />
                )
            );
        }
    }, [values, feedbackIndex, referenceName]);

    const renderObservation = useMemo(() => {
        if (
            values.feedback[feedbackIndex] &&
            values.feedback[feedbackIndex].observations
        ) {
            return values.feedback[
                feedbackIndex
            ].observations.map((observation: ObservationType, i: number) => (
                <Observation
                    referenceName={referenceName}
                    key={`feedback[${feedbackIndex}].observations[${i}]`}
                    observation={observation}
                    observationIndex={i}
                    sectionIndex={feedbackIndex}
                    currentCapability={currentCapability}
                    setFieldValue={setFieldValue}
                    values={values}
                    observationKey={i}
                    moveCard={moveObservationCard}
                    canEdit={canEdit}
                    dataLoading={dataLoading}
                    level={level}
                />
            ));
        }
    }, [values, feedbackIndex, referenceName]);
    return (
        <DndProvider backend={Backend}>
            <Container>
                <React.Fragment>
                    <Header>
                        <AlignItems>
                            <ItemdragAndDrop
                                src={dragAndDrop}
                                alt="drag and drop"
                            />
                            <IdText>{`${referenceName}.${feedbackIndex + 1}`}</IdText>
                            <TitleText>
                                <SectionInput
                                    type="text"
                                    name="title"
                                    disabled={!canEdit}
                                    value={values.feedback[feedbackIndex].title || ''}
                                    placeholder="Add section title"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            `feedback[${feedbackIndex}].title`,
                                            e.target.value
                                        );
                                    }}
                                />
                            </TitleText>
                        </AlignItems>
                        <AlignItems>
                            <HeaderActionText
                                onClick={() => {
                                    if (!canEdit) {
                                        return;
                                    }
                                    handleAddObservation();
                                }}
                            >
                                Add Observation
                            </HeaderActionText>

                            <HeaderActionText
                                onClick={() => {
                                    if (!canEdit) {
                                        return;
                                    }
                                    handleAddRecommendation();
                                }}
                            >
                                Add Recommendation
                            </HeaderActionText>
                            <HeaderActionText
                                onClick={() => {
                                    if (!canEdit) {
                                        return;
                                    }
                                    handleDelete();
                                }}
                            >
                                Delete
                            </HeaderActionText>
                        </AlignItems>
                    </Header>
                    <Content>
                        <FieldArray
                            name="observations"
                            render={() => (
                                <ContentColumn>
                                    {renderObservation}
                                </ContentColumn>
                            )}
                        />
                        <FieldArray
                            name="recommendations"
                            render={() => (
                                <ContentColumn>
                                    {renderRecommendations}
                                </ContentColumn>
                            )}
                        />
                    </Content>
                </React.Fragment>
            </Container>
        </DndProvider>
    );
};

const Container = styled(Column)`
    padding: 20px 0;
`;

const Header = styled(AlignItems)`
    justify-content: space-between;
`;

const Content = styled(SpaceBetween)`
    padding: 10px;
`;

const ContentColumn = styled(Column)`
    flex: 0 0 49%;
`;

const ItemdragAndDrop = styled('img')`
    width: 25px;
    margin-right: 5px;
`;

const HeaderActionText = styled(Button)`
    font-size: 16px !important;
    color: #6E6E6E !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    padding: unset !important;
    margin-left: 32px !important;
`;

const IdText = styled(Text)`
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #6E6E6E;
`;

const TitleText = styled(Text)`
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #575757;
    margin-left: 18px;

    & input {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #575757;
        margin-left: 18px;
        border: 0;
        cursor: pointer;
        margin-left: 0;
    }
`;

const SectionInput = styled('input')`
    outline: none;
`;

export default ObservationsAndRecommendationsSection;
