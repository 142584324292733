import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Modal from 'react-modal';
import {
    AlignItems,
    PopUpHeader,
    RedButton,
    Text
} from '../../../../../components';
import { Checkbox, TextField, Button } from '@mui/material';
import { ObjectiveWithScore, DescopedComponent, ObjectiveComponentWithDescriptions } from '../../types';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { renderDescopePopup } from '../ObjectivesHelpers';
import { Formik, Form, FieldArray } from 'formik';
import { weightBalanceSuccessMessages, oneDecimalNumber, weightingValidationMessage, weightBalanceFailureMessage} from '../../../../../utils';
import { DescopeIcon, RescopeIcon } from '../../../Common';
import { customModalStyles } from '../../../../../components/PopUps/constants';
import { ASSESSMENT_CAPABILITY_WITH_FRAMEWORK } from '../../MaturityScoreQueries';

export type WeightedProps = {
    handleOnSave: any;
    currentCampaignId: string;
    currentAssessmentId: string;
    currentDomainId: string;
    currentCapabilityId: string;
    isWeightedFormOpen: boolean;
    setIsWeightedFormOpen: any;
    objective: ObjectiveWithScore;
    isError?: boolean;
    setbalanceWeightSuccessMessage: any;
    balanceWeightSuccessMessage: boolean;
    balanceweightFailureMessage: boolean;
};

const UPDATE_ASSESSMENT_COMPONENTS_SCOPE = gql`
    mutation updateAssessmentComponentsScope(
        $data: UpdateAssessmentComponentScopeInput!
    ) {
        updateAssessmentComponentsScope(data: $data) {
            id
            averageScore
            inScope
            objectives {
                id
                updateScopeReason
                inScope
                notes
                score
                components {
                    inScope
                    updateScopeReason
                }
            }
        }
    }
`;

const ComponentWeightedModal: React.FC<WeightedProps> = ({
    handleOnSave,
    currentCampaignId,
    currentAssessmentId,
    currentDomainId,
    currentCapabilityId,
    isWeightedFormOpen,
    setIsWeightedFormOpen,
    objective,
    isError,
    setbalanceWeightSuccessMessage,
    balanceWeightSuccessMessage,
    balanceweightFailureMessage
}) => {
    const [selectedComponent, setSelectedComponent] = useState<ObjectiveComponentWithDescriptions[]>([]);
    const [isScopeModalOpen, setIsScopeModalOpen] = useState<boolean>(false);
    const [operationType, setOperationType] = useState<string>('');
    const [modalId, setModalStatus] = useState<string>('');
    const [descopedComponentsList, setDescopedComponentsList] = useState<DescopedComponent[]>([]);
    const [checkedState, setCheckedState] = useState(new Array(objective.components?.length).fill(false));
    const [isReasonExist, setIsReasonExist] = useState<boolean>(false);
    const [descopeComponentsScope] = useMutation(UPDATE_ASSESSMENT_COMPONENTS_SCOPE, {
        refetchQueries: [
            {
                query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                variables: {
                    campaignId: currentCampaignId,
                    data: {
                        assessmentId: currentAssessmentId,
                        domainId: currentDomainId,
                        capabilityId: currentCapabilityId
                    }
                }
            }
        ]
    });
    const handleOnChange = (position: number, component: ObjectiveComponentWithDescriptions) => {
        if (selectedComponent) {
            const idx = selectedComponent.findIndex((com) => (com.id === component.id));
            if (idx > -1) {
                selectedComponent.splice(idx, 1);
                setSelectedComponent([...selectedComponent]);
            } else {
                setSelectedComponent([...selectedComponent, component]);
            }
        }
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    };

    useEffect(() => {
        setSelectedComponent([]);
        setCheckedState(new Array(objective.components?.length).fill(false));
    }, [objective]);

    useEffect(() => {
        const isInScope = selectedComponent.length > 0 ? selectedComponent[0].inScope : undefined;
        if (selectedComponent.length > 0 && isInScope !== undefined) {
            setModalStatus(isInScope ? 'descope' : 'rescope');
            setOperationType(isInScope ? 'inScope' : 'descope');
            setDescopedComponentsList(selectedComponent.map((c) => ({ id: c.id, name: c.name })));
        } else {
            setModalStatus('');
            setOperationType('');
            setDescopedComponentsList([]);
        }
    }, [selectedComponent]);

    let submitAction: string | undefined;

    return (
        <>
            <Modal
                isOpen={isWeightedFormOpen}
                contentLabel="Weighted Average"
                style={customModalStyles}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
            >
                <Formik
                    enableReinitialize
                    initialValues={ {objective} }
                    onSubmit={(values, actions) => {
                        if(submitAction==='balance') {
                            const length=values.objective.components?.filter((com)=>com.inScope).length||1;
                            values.objective.components?.forEach((com)=>com.inScope?com.weighting=oneDecimalNumber(100/length):com.weighting=0);
                            handleOnSave(values.objective,true);
                            actions.setValues({...values});
                        }
                        else if(submitAction==='submit'){
                            handleOnSave(values.objective);
                            setbalanceWeightSuccessMessage(false);
                        }
                    }}>
                        {({ setFieldValue, values, handleSubmit }) => {
                        return (
                            <>
                                <PopUpHeader title='Manage Component Weights' handleClose={() => {
                                    submitAction='submit';
                                    handleSubmit();
                                }} />
                                <Form style={{ padding: '25px' }}>
                                    <FieldArray
                                        name="objective"
                                        render={() => (
                                            <>
                                                <ObjectiveHeader>
                                                    <TitleContainer>
                                                        <BoldText>{(values.objective && values.objective.name)} : </BoldText>
                                                        {(values.objective && values.objective.reference)}
                                                    </TitleContainer>
                                                    <HeaderTitle>Weight</HeaderTitle>
                                                </ObjectiveHeader>
                                                {values.objective.components && values.objective.components.map((component, componentIndex) => {
                                                    return (<>
                                                        <QuestionsContainer key={component.id} style={{
                                                            borderBottom: values.objective.components &&
                                                                (values.objective.components.length - 1) === componentIndex ? 'none' : '1px solid',
                                                            marginBottom: values.objective.components &&
                                                                (values.objective.components.length - 1) === componentIndex ? '0px' : '30px'
                                                        }}>
                                                            <Checkbox
                                                                style={{
                                                                    paddingLeft: 0,
                                                                    marginRight: 15,
                                                                    marginTop: 7,
                                                                    height: 'fit-content'
                                                                }}
                                                                color="secondary"
                                                                disabled={
                                                                    (modalId === 'descope' && component.inScope === false) ? true :
                                                                        // tslint:disable-next-line: no-redundant-boolean
                                                                        (modalId === 'rescope' && component.inScope === true) ? true : false
                                                                }
                                                                checked={checkedState[componentIndex]}
                                                                onChange={() => handleOnChange(componentIndex, component)}
                                                                inputProps={{
                                                                    'aria-labelledby': 'select'
                                                                }}
                                                            />
                                                            <AlignItems style={{
                                                                display: 'block',
                                                                flexGrow: 1,
                                                                marginBottom: 7,
                                                                color: component && component.inScope === false ? '#6E6E6E' : '#000'
                                                            }}>
                                                                <QuestionTitle>
                                                                    {component && component.inScope === false ? <BoldText>[DESCOPED] </BoldText> : null}
                                                                    <BoldText>{ component && component.reference }: { component && component.name }</BoldText>
                                                                </QuestionTitle>
                                                                <QuestionDescription>
                                                                    { component && component.question }
                                                                </QuestionDescription>
                                                            </AlignItems>
                                                            <TextField
                                                                defaultValue={
                                                                    component && component.weighting
                                                                }
                                                                value={oneDecimalNumber(component.weighting)}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        '%'
                                                                    )
                                                                }}
                                                                inputProps={{
                                                                    maxLength: 3,
                                                                    disabled: !component.inScope,
                                                                    min: 0,
                                                                    max: 100,
                                                                    step: '0.1',
                                                                    'aria-label':'set component weight'
                                                                }}
                                                                margin="dense"
                                                                name={`weighting${componentIndex}`}
                                                                style={{
                                                                    marginLeft: 15,
                                                                    minWidth: 100,
                                                                    flex: '0 0 100px'
                                                                }}
                                                                variant="outlined"
                                                                onChange={(e: any) => {
                                                                        setFieldValue(
                                                                            `objective.components[${componentIndex}].weighting`,
                                                                            parseFloat(e.target.value)
                                                                        );

                                                                }}
                                                                disabled={ !component.inScope }
                                                            />
                                                        </QuestionsContainer>
                                                    </>);
                                                })}
                                                {isError && <TitleContainer style={{
                                                    textAlign: 'right',
                                                    color: '#de3328'
                                                }}>
                                                    {weightingValidationMessage}
                                                </TitleContainer> }
                                                    {
                                                        balanceWeightSuccessMessage ?
                                                        <TitleContainer style={{textAlign: 'right',
                                                        color: '#4588c3'}}>{weightBalanceSuccessMessages}</TitleContainer> :''}

                                                {balanceweightFailureMessage ? <TitleContainer style={{
                                                    textAlign: 'right',
                                                    color: '#de3328'
                                                }}>
                                                    {weightBalanceFailureMessage}
                                                </TitleContainer> : ''}
                                                <ButtonContainer style={{
                                                    justifyContent: modalId === '' ? 'right' : 'space-between'
                                                }}>
                                                    {modalId === '' ? null :
                                                        modalId === 'descope' ? <Button
                                                            variant="outlined"
                                                            aria-label='descope button'
                                                            onClick={() => setIsScopeModalOpen(true)}>
                                                            <DescopeIcon />
                                                            Descope
                                                        </Button> : <Button
                                                            variant="outlined"
                                                            aria-label='rescope button'
                                                            onClick={() => setIsScopeModalOpen(true)}>
                                                            <RescopeIcon />
                                                            Rescope
                                                        </Button>
                                                    }
                                                    <AlignItems>
                                                    <RedButton
                                                        style={{ marginRight: 16 }}
                                                        onClick={() => {
                                                            submitAction='balance';
                                                            handleSubmit();
                                                        }}>
                                                            <TemplatesButtonText >
                                                                Balance weights
                                                            </TemplatesButtonText>
                                                    </RedButton>
                                                    <RedButton
                                                        style={{ marginRight: 16, paddingLeft: 16 }}
                                                        onClick={() => {
                                                            submitAction='submit';
                                                            handleSubmit();
                                                        }}
                                                    >
                                                            <TemplatesButtonText>
                                                                Save
                                                            </TemplatesButtonText>
                                                    </RedButton>
                                                    </AlignItems>
                                                </ButtonContainer>
                                            </>
                                        )}
                                    />
                                </Form>
                            </>
                        );
                }}
                </Formik>


            </Modal>

            <Modal
                isOpen={isScopeModalOpen}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
                style={customModalStyles}
                onRequestClose={() => setIsScopeModalOpen(false)}
            >
                {renderDescopePopup({
                    handleClose: () => setIsScopeModalOpen(false),
                    descopedComponentsList,
                    modalId,
                    currentAssessmentId,
                    currentDomainId,
                    currentCapabilityId,
                    currentObjectiveId: objective.id,
                    descopeComponentsScope,
                    selectedComponents: operationType,
                    isReasonExist,
                    setIsReasonExist,
                    setIsWeightedFormOpen
                })}
            </Modal>
        </>
    );
};

const ObjectiveHeader = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const QuestionsContainer = styled('div')`
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const BoldText = styled('span')`
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
`;

const TemplatesButtonText = styled(Text)`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    margin: 0 16px 0 8px;
`;

const ButtonContainer = styled('div')`
    display: flex;
`;

const TitleContainer = styled('p')``;
const HeaderTitle = styled('p')``;

const QuestionTitle = styled('p')``;
const QuestionDescription = styled('p')`

    max-width: 600px;
`;

export default ComponentWeightedModal;
