import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import info from '../../assets/images/toast_info_image.svg';
import error from '../../assets/images/toast_error_image.svg';
import check from '../../assets/images/toast_check_image.svg';

import Text from './../base/Text/Text';
import BoldText from './../base/Text/BoldText';
import JustifyContentEnd from './../base/Layout/JustifyContentEnd';
import Column from './../base/Layout/Column';
import Flex from './../base/Layout/Flex';
import JustifyContentCenter from './../base/Layout/JustifyContentCenter';


type ToastProps = {
    open: boolean;
    autoHideDuration?: number;
    title: string;
    width?: string;
    text?: string;
    icon: 'error' | 'info' | 'check';
    closeToast?: any;
    anchorOrigin: {
        vertical: 'top' | 'bottom';
        horizontal: 'left' | 'center' | 'right';
    };
    children?: React.ReactNode;
};

const Toast = ({
    open,
    autoHideDuration,
    title,
    width,
    text,
    icon,
    closeToast,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left'
    },
    children
}: ToastProps) => {
    const renderIcon = (currentIcon: string) => {
        switch (currentIcon) {
            case 'error':
                return (
                    <IconWrapper bgcolor="#de3328">
                        <img src={error} alt="" />
                    </IconWrapper>
                );
            case 'info':
                return (
                    <IconWrapper bgcolor="#4588c3">
                        <img src={info} alt="" />
                    </IconWrapper>
                );

            case 'check':
                return (
                    <IconWrapper bgcolor="#299d8f">
                        <img src={check} alt="" />
                    </IconWrapper>
                );
        }
    };


    let closeBtn = <></>;
    if (closeToast !== undefined) {
        closeBtn = <CloseButtonWrapper
        >
            <IconButton
                tabIndex={10}
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeToast !== undefined ? closeToast(false) : false}
            >
                <CloseIcon fontSize="medium" />
            </IconButton>
        </CloseButtonWrapper>;
    }

    return (
        <ToastWrapper
            anchorOrigin={anchorOrigin}
            open={open}
            onClose={(_, reason) => {
                if (reason === 'timeout' && closeToast !== undefined) {
                    closeToast(false);
                }
            }}
            autoHideDuration={autoHideDuration}
            action={
                <ToastContainer width={width ? width : '790px'}>
                    {renderIcon(icon)}
                    <ColumnCenter>
                        <BoldText color="white" fontSize="16px">
                            {title}
                        </BoldText>
                        <Text color="white">{text}</Text>
                    </ColumnCenter>
                    {children}
                    {closeBtn}

                </ToastContainer>
            }
        />
    );
};

const ToastContainer = styled(Flex)<{ width: string }>`
    width: ${props => props.width};
`;
const ColumnCenter = styled(Column)`
    justify-content: center;
    padding: 11px;
`;

const CloseButtonWrapper = styled(JustifyContentEnd)`
    flex-grow: 1;
    align-items: center;
    padding: 0 20px;
`;
const IconWrapper = styled(JustifyContentCenter)<{ bgcolor: string }>`
    background-color: ${props => props.bgcolor};
    min-width: 42px;
`;
const ToastWrapper = styled(Snackbar)`
    .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
        padding: 0;
    }
    .MuiSnackbarContent-action {
        padding: 0;
    }
`;

export default Toast;
