import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import MaturityOverview from '../MaturityOverview';
import MaturityAssessment from '../MaturityAssessment';
import {
    AlignItems,
    JustifyContentCenter,
    Column
} from '../../../../components/base/Layout';
import assessmentIcon from '../../../../assets/images/assessments-icon.svg';
import maturityOverviewIcon from '../../../../assets/images/icon-maturity-overview.svg';
import { SpinnerWithOverlay, Text } from '../../../../components';
import { EvaluateMatch, EvaluateDetailsData } from '../types';
import { ASSESSMENT_LIST_PAGINATE } from '../../query';
import { CampaignData } from '../MaturityOverview/types';
import { ASSESSMENT_FETCH_LIMIT } from '../../../../utils';
import campaignEventChannel from '../../../../eventChannels';
import { useParams } from 'react-router';
import { debounce } from 'lodash';

const EvaluateDetails: React.FC = () => {
    const match = useParams();

    const [value, setValue] = React.useState(0);
    const campaignId = match.campaignId;
    const defaultCampaign: CampaignData = {
        id: '',
        title: '',
        progress: 0,
        capabilitiesScoredCnt: 0,
        capabilitiesModeratedCnt: 0,
        capabilitiesCnt: 0,
        assessmentsCnt: 0,
        assessmentsNotStartedCnt: 0,
        assessmentsInProgressCnt: 0,
        assessmentsCompletedCnt: 0,
        manager:[],
        members: [],
        status: '',
        closedAt: null,
        targetScoreEnabled: false,
        pointOneDecimalEnable: false,
        readOnly: false,
        benchmarkingEnabled: false,
        updatedAt: 0,
        isCampaignEntityUser: false
    };

    const [assessmentsByCampaignWithGroups, setAssessmentsByCampaignWithGroups] = useState([]);
    const [assessmentsByCampaign, setAssessmentsByCampaign] = useState([]);
    const [campaign, setCampaign] = useState<CampaignData>(defaultCampaign);
    const [closedCampaign, setCloseCampaign] = useState<boolean>(false);

    const [assessmentsByCampaignWithGroupPageOffset, setAssessmentsByCampaignWithGroupPageOffset] = useState(0);
    const [assessmentsByCampaignWithGroupPageInfo, setAssessmentsByCampaignWithGroupPageInfo] = useState<{
        count: number;
        skip: number;
        take: number;
        total: number;
    }>({
        count: 0,
        skip: 0,
        take: 0,
        total: 0
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const [getAssessmentData] = useLazyQuery<EvaluateDetailsData>(ASSESSMENT_LIST_PAGINATE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res: any) => {
            setAssessmentsByCampaign(res.assessmentsByCampaign);
            setAssessmentsByCampaignWithGroups(res.assessmentsByCampaignWithGroupsWithPagination.items);
            setAssessmentsByCampaignWithGroupPageInfo({
                count: res.assessmentsByCampaignWithGroupsWithPagination.count,
                skip: res.assessmentsByCampaignWithGroupsWithPagination.skip,
                take: res.assessmentsByCampaignWithGroupsWithPagination.take,
                total: res.assessmentsByCampaignWithGroupsWithPagination.total
            });

            setLoading(false);
            if (search && search.length > 0) {
                setCampaign(res.campaign);
            }
            else
            {
                setCampaign(res.campaign);
            }

        },
        onError: (err: any) => {
            console.log('Error loading assessment list-', err);
        }
    });

    const delayedSearch = debounce(async(value) => {
        getAssessmentData({
            variables: {
                campaignId,
                id: campaignId,
                pagination: {
                    skip: assessmentsByCampaignWithGroupPageOffset * ASSESSMENT_FETCH_LIMIT,
                    take: ASSESSMENT_FETCH_LIMIT,
                    search: value
                }
            }
        });
    },100);

    useEffect(() => {
        setLoading(true);
            getAssessmentData({
                variables: {
                    campaignId,
                    id: campaignId,
                    pagination: {
                        skip: assessmentsByCampaignWithGroupPageOffset * ASSESSMENT_FETCH_LIMIT,
                        take: ASSESSMENT_FETCH_LIMIT,
                    }
                }
            });
    }, [closedCampaign]);


    useEffect(()=>
    {
        if(search && search.length > 0){
            delayedSearch(search);
    }
    else{
        getAssessmentData({
            variables: {
                campaignId,
                id: campaignId,
                pagination: {
                    skip: assessmentsByCampaignWithGroupPageOffset * ASSESSMENT_FETCH_LIMIT,
                    take: ASSESSMENT_FETCH_LIMIT,
                }
            }
        });
    }
    },[search,assessmentsByCampaignWithGroupPageOffset]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setSearch('');
    };

    campaignEventChannel.on('onCampaignClose', (isCampaignClosed) => {
        setCloseCampaign(isCampaignClosed);
    });

    return (
        <Column style={{ width: '100%' }}>
            <JustifyContentCenter
                style={{
                    backgroundColor: '#fff'
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    TabIndicatorProps={{
                        style: {
                            height: 4,
                            backgroundColor: '#de3328'
                        }
                    }}
                >
                    <Tab
                        label={
                            <TabContainer>
                                <img
                                    src={maturityOverviewIcon}
                                    alt="maturity overview"
                                    aria-hidden={true}
                                    style={{ marginRight: 8 }}
                                />
                                <TabText
                                id="skip-to-content"
                                tabIndex={-1}>Maturity Overview</TabText>
                            </TabContainer>
                        }
                    />
                    <Tab
                        label={
                            <TabContainer>
                                <img
                                    src={assessmentIcon}
                                    alt="assessment"
                                    aria-hidden={true}
                                    style={{ marginRight: 8 }}
                                />
                                <TabText>
                                    Assessments
                                </TabText>
                            </TabContainer>
                        }
                    />
                </Tabs>
            </JustifyContentCenter>
            {loading ? (
                <SpinnerWithOverlay />
            ) : value ? (
                <MaturityAssessment
                    maturityAssessmentData={assessmentsByCampaign}
                    campaign={campaign}
                    assessmentsByCampaignWithGroupPageOffset={assessmentsByCampaignWithGroupPageOffset}
                />
            ) : (
                <MaturityOverview
                    setSearch = {setSearch}
                    maturityOverviewData={assessmentsByCampaignWithGroups}
                    campaign={campaign}
                    assessmentsByCampaignWithGroupPageOffset={assessmentsByCampaignWithGroupPageOffset}
                    setAssessmentsByCampaignWithGroupPageOffset={setAssessmentsByCampaignWithGroupPageOffset}
                    assessmentsByCampaignWithGroupPageInfo={assessmentsByCampaignWithGroupPageInfo}
                />
            )}
        </Column>
    );
};

const TabText = styled(Text)`
    color: #575757;
    font-size: 16px;
    text-transform: none;
`;

const TabContainer = styled(AlignItems)`
    height: 60px;
    box-sizing: border-box;
`;

export default EvaluateDetails;
