import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Formik, Form, ErrorMessage, Field, FormikErrors } from 'formik';
import { TextField, Select, SelectChangeEvent, OutlinedInput } from '@mui/material';
import { MenuItem, Divider, Tooltip, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as Yup from 'yup';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { FirstStepUser, FirstStepFramework } from './types';

import {
    Flex,
    Column,
    ArrowTooltip,
    Text,
    CustomField,
    ToggleSwitch,
    TargetScoreSlider
} from '../../../components';
import { UserStore } from '../../../context/user-store';
import { Client } from '../../../context/reducer';
import { marks } from './CreateCampaignHelper';
import { isTargetScore, isPointOneDecimalEnable } from '../../../utils/global-settings';
import { dynamicSort, sortAlphaNum } from '../../../utils/UtilsHelpers';
import { isValid, secondsInDay } from 'date-fns';

const DROPDOWN_VALUES_BY_COMPANY = gql`
    query dropdownValuesByCompany($clientId: Int!) {
        users: usersByClient(clientId: $clientId) {
            value: id
            firstName
            lastName
            role
        }
        frameworksByCompany(clientId: $clientId) {
            value: id
            definition {
                name
            }
        }
    }
`;

const CHECK_CAMPAIGN_NAME = gql`
    query campaignByTitle($title: String!, $clientId: Int!) {
        campaignByTitle(title: $title, clientId: $clientId) {
            id
            title
        }
    }
`;

type FormValuesType = {
    title: string;
    description: string;
    clientId: number;
    frameworkId: number;
    managerId: number[];
    targetScoreEnabled?: boolean;
    targetScoreDefault?: number | null;
    pointOneDecimalEnable?: boolean;
};

type Props = {
    getFirstStepValues: any;
    submitting: number;
    firstStepValues?: FormValuesType;
    setMembers: any;
    setReadOnlyMembers: any;
    getSecondStepValues: any;
    setActiveStep: any;
    clientId: number;
    setFirstStepValid: any;
    setClientSelected: any;
};

const firstStepSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(35, 'Too Long!(Should be Maximum 35 Character)')
        .required('Required'),
    clientId: Yup.number()
        .moreThan(0, 'Required')
        .required('Required'),
    managerId: Yup.number()
        .moreThan(0, 'Required')
        .required('Required'),
    frameworkId: Yup.number()
        .moreThan(0, 'Required')
        .required('Required')
});

const FirstStep: React.FC<Props> = ({
    getFirstStepValues,
    submitting = 0,
    firstStepValues,
    setMembers,
    setReadOnlyMembers,
    getSecondStepValues,
    setActiveStep,
    clientId,
    setFirstStepValid,
    setClientSelected
}) => {
    const {
        state: { user, globalSettings }
    } = useContext(UserStore);

    const [localValues, setLocalValues] = useState<FormValuesType>();
    const [availableCompanies, setAvailableCompanies] = useState<Client[]>([]);
    const [descriptionCount, setDescriptionCount] = useState<number>(0);
    const [isTargetScoring, setTargetScoring] = useState(false);
    const [isPointOneDecimal, setPointOneDecimal] = useState(false);
    const [defaultTargetScore, setDefaultTargetScore] = useState(-1);
    const [selectedManagers, setSelectManagers] = useState<string[]>([]);

    const [checkName] = useLazyQuery(CHECK_CAMPAIGN_NAME, {
        onCompleted: () => {
            setErrorsFunction({
                title:
                    'This campaign name already exists. Please choose a different one.'
            });
        },
        onError: () => {
            formValues.targetScoreEnabled = isTargetScoring;
            formValues.pointOneDecimalEnable = isPointOneDecimal;
            formValues.targetScoreDefault = !formValues.targetScoreEnabled ? undefined : defaultTargetScore === -1 ? null : defaultTargetScore;
            getFirstStepValues(formValues);
            setActiveStep(1);
        }
    });
    const [dropDownLazy, { data: dataLazy }] = useLazyQuery(
        DROPDOWN_VALUES_BY_COMPANY
    );

    useEffect(() => {
        dropDownLazy({
            variables: {
                clientId: clientId,
            },
        });
    }, [dropDownLazy]);

    useEffect(() => {
        if (firstStepValues?.managerId) setLocalValues(firstStepValues);
        if (firstStepValues?.pointOneDecimalEnable) {
          setPointOneDecimal(firstStepValues.pointOneDecimalEnable);
        }
        if (firstStepValues?.targetScoreDefault && firstStepValues?.targetScoreEnabled) {
          setTargetScoring(firstStepValues.targetScoreEnabled);
          setDefaultTargetScore(firstStepValues.targetScoreDefault);
        }
      }, [firstStepValues]);

    useEffect(()=>{
        const countryUsers=user.countryUsers&&user.countryUsers.filter(cu=>cu.campaignCreateEnabled===true)||[];
        const companiesByCountry=user.clients&&
        user.clients.filter(client=>countryUsers.some(cu=>cu.countryId===client.countryId))||[];
            companiesByCountry.sort(dynamicSort('name'));
            setAvailableCompanies(companiesByCountry);
    },[user]);

    const initialValues = localValues || {
        title: '',
        description: '',
        clientId: 0,
        managerId: [],
        frameworkId: 0,
        targetScoreEnabled: false,
        targetScoreDefault: 0,
        pointOneDecimalEnable: false,
    };

    let validateFormFunction: any;
    let formValues: FormValuesType;
    let setErrorsFunction: any;
    let isFormValid: boolean;

    const callback = (
        func: any,
        values: FormValuesType,
        setErrors: any,
        isValid: boolean
    ) => {
        validateFormFunction = func;
        formValues = values;
        setErrorsFunction = setErrors;
        isFormValid = isValid;
    };

    useEffect(() => {
        if (submitting) {
            validateFormFunction();
        }
        if (isFormValid) {
            setFirstStepValid(isFormValid);
        }
    }, [submitting]);

    const resetFunction = (resetFieldFunction: any) => {
        resetFieldFunction('title', '');
        resetFieldFunction('description', '');
        resetFieldFunction('managerId', 0);
        resetFieldFunction('frameworkId', 0);
        setMembers([]);
        setReadOnlyMembers([]);
        getSecondStepValues([]);
    };

    const handleFirstStepSubmit = (values: { title: any; clientId: any; }, { setSubmitting }: any) => {
        checkName({
            variables: {
                title: values.title,
                clientId: values.clientId
            }
        });
        setSubmitting(false);
    };

    const setDropdowns = (option: Client, e: any, setFieldValue: any) => {
        setClientSelected(option);
        if (!(e.type == 'mousedown' || (e.type == 'keydown' && e.key == 'Enter')))
        resetFunction(setFieldValue);
        return dropDownLazy({
            variables: {
                clientId: option.id,
            },
            context: {
                headers: {
                    'pearl-client-uuid': option.id,
                },
            },
        });
    };

    return (
        <div>
            <Typography variant='h1' style={{
                    color: '#525252',
                    letterSpacing: '0.5px',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    margin: '40px 0 12px 0',
                    display: 'block',
                    float: 'none',
                    width: 'unset'
            }}>Campaign details</Typography>

            <Column
                style={{
                    marginBottom: 50,
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box'
                }}
            >
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnMount={firstStepSchema.isValidSync(initialValues)}
                    validationSchema={firstStepSchema}
                    onSubmit={handleFirstStepSubmit}>
                    {({
                        errors,
                        setFieldValue,
                        touched,
                        submitForm,
                        values,
                        setErrors,
                        isValid,
                        handleChange,
                        handleBlur,

                    }) => {
                        callback(submitForm, values, setErrors, isValid);
                        return (
                            <Form>
                                <Column>
                                    <Flex>
                                        <ColumnWrapper>
                                            <Label id='companyLabel'>Company</Label>
                                            <Field
                                                variant="outlined"
                                                align="left"
                                                name="clientId"
                                                error={
                                                    !!errors.clientId &&
                                                    touched.clientId
                                                }
                                                type="text"
                                                style={{
                                                    marginBottom: 20
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                as={Select}
                                                aria-labelledby='companyLabel'
                                            >
                                                <MenuItem disabled value={0}>
                                                    Company
                                                </MenuItem>
                                                {availableCompanies &&
                                                    availableCompanies.map((option, index) => (
                                                        <MenuItem
                                                            onKeyDown={(e) => setDropdowns(option, e, setFieldValue)}
                                                            onMouseDown={(e) => setDropdowns(option, e, setFieldValue)}
                                                            key={`company-${index}`}
                                                            value={option.id}
                                                        >
                                                            <ArrowTooltip
                                                                title={
                                                                    option.name!
                                                                }
                                                                placement="bottom"
                                                                minWidth={'0px'}
                                                                fontSize={16}
                                                                fontSmooth={'antialiased'}
                                                            >
                                                                <TruncatorWrapper>
                                                                    {
                                                                        option.name
                                                                    }
                                                                </TruncatorWrapper>
                                                            </ArrowTooltip>
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                            <ErrorMessage
                                                component={CustomErrorMessage}
                                                name="clientId"
                                            />
                                            <Label id='campaignManagerLabel'>
                                                Campaign manager(s)
                                                <Tooltip
                                                    title={'Campaign manager(s) are responsible for managing campaign details, reviewing/approving capabilities, and closing the campaign. There can be up to 2 campaign managers.'}
                                                    placement="top"
                                                >
                                                    <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                        <InfoIcon style={{fontSize: '12px'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Label>
                                            <Field
                                                variant="outlined"
                                                align="left"
                                                name="managerId"
                                                error={
                                                    !!errors.managerId &&
                                                    touched.managerId
                                                }
                                                type="text"
                                                fullWidth
                                                style={{
                                                    marginBottom: 20
                                                }}
                                                as={Select}
                                                multiple
                                                displayEmpty
                                                input={<OutlinedInput />}
                                                renderValue={(selected: any[]) => {
                                                    if (selected.length === 0) {
                                                        return <>Select manager </>;
                                                    }

                                                    const selectedUsers = dataLazy && dataLazy.users.filter((u: any) => selected.includes(u.value));
                                                    return selectedUsers.sort(dynamicSort('firstName')).map((su: any) => `${su.firstName} ${su.lastName}`).join(', ');
                                                }}
                                                onChange={(event: any) => {
                                                    const selectedValue = Array.from(event.target.value);

                                                    if(selectedValue.length <=2 ){
                                                        handleChange(event);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={!dataLazy}
                                                aria-labelledby='campaignManagerLabel'
                                            >
                                                <MenuItem disabled value="">
                                                    Select manager
                                                </MenuItem>
                                                { dataLazy &&
                                                    dataLazy.users.slice().sort(dynamicSort('firstName')).map((option: FirstStepUser, index: any) => (
                                                                <MenuItem
                                                                    key={
                                                                        `manager-${index}`
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    disabled={
                                                                        option.status ===
                                                                        'DISABLED'
                                                                    }
                                                                >
                                                                    <ArrowTooltip
                                                                        title={`${option.firstName} ${option.lastName}`}
                                                                        placement="bottom"
                                                                        minWidth={'0px'}
                                                                        fontSize={16}
                                                                        fontSmooth={'antialiased'}
                                                                    >
                                                                        <TruncatorWrapper>
                                                                        {`${option.firstName} ${option.lastName}`}
                                                                        </TruncatorWrapper>
                                                                    </ArrowTooltip>
                                                                </MenuItem>
                                                            )
                                                        )}
                                            </Field>
                                            <ErrorMessage
                                                component={CustomErrorMessage}
                                                name="managerId"
                                            />
                                            <Label id='campaignDescriptionLabel'>Campaign description (optional)</Label>
                                            <Field
                                                variant="outlined"
                                                align="left"
                                                placeholder="Add description"
                                                name="description"
                                                multiline
                                                rows={3}
                                                type="name"
                                                style={{ marginBottom: 20 }}
                                                as={TextField}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{
                                                    onChange: (event: any) => { setDescriptionCount(event.target.value.length); },
                                                    maxLength: '300',
                                                    'aria-labelledby': 'campaignDescriptionLabel'
                                                }}
                                            />
                                            <span style={{ textAlign:'left',marginTop:'-15px' }}><Label>{(300-descriptionCount)} characters remaining</Label></span>

                                        </ColumnWrapper>
                                        <ColumnWrapper>
                                            <Label id='campaignLabel'>Campaign name</Label>
                                            <Field
                                                variant="outlined"
                                                align="left"
                                                placeholder="Add campaign"
                                                name="title"
                                                type="name"
                                                as={TextField}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ marginBottom: 20 }}
                                                aria-labelledby='campaignLabel'
                                            />
                                              <ErrorMessage
                                                component={CustomErrorMessage}
                                                name="title"
                                            />

                                            <Label id='campaignFrameworkLabel'>Framework</Label>
                                            <Field
                                                variant="outlined"
                                                align="left"
                                                name="frameworkId"
                                                error={
                                                    !!errors.frameworkId &&
                                                    touched.frameworkId
                                                }
                                                type="text"
                                                style={{
                                                    marginBottom: 20
                                                }}
                                                as={Select}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={!dataLazy}
                                                aria-labelledby='campaignFrameworkLabel'
                                            >
                                                <MenuItem disabled value={0}>
                                                    Select framework
                                                </MenuItem>
                                                {dataLazy &&
                                                    dataLazy.frameworksByCompany.slice().sort((a: FirstStepFramework,b: FirstStepFramework) =>
                                                    sortAlphaNum(a.definition.name, b.definition.name))
                                                    .map((
                                                            option: FirstStepFramework,
                                                            index: any
                                                        ) => (
                                                            <MenuItem
                                                                key={
                                                                    `framework-${index}`
                                                                }
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                <ArrowTooltip
                                                                    title={
                                                                        option
                                                                            .definition
                                                                            .name
                                                                    }
                                                                    placement="bottom"
                                                                    minWidth={'0px'}
                                                                    fontSize={16}
                                                                    fontSmooth={'antialiased'}
                                                                >
                                                                    <TruncatorWrapper>
                                                                        {
                                                                            option
                                                                                .definition
                                                                                .name
                                                                        }
                                                                    </TruncatorWrapper>
                                                                </ArrowTooltip>
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Field>
                                            <ErrorMessage
                                                component={CustomErrorMessage}
                                                name="frameworkId"
                                            />
                                            {isTargetScore(globalSettings) && (
                                                <>
                                                    <Label id='targetScoringLabel'>
                                                        Enable target scoring
                                                        <Tooltip
                                                            title={'Target scoring enables campaign members to set target scores for capabilities, which can be visualised and compared with the actual scores.'}
                                                            placement="top"
                                                        >
                                                            <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                <InfoIcon style={{fontSize: '12px'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Label>
                                                    <CustomToggleSwitch
                                                        turnedOn={isTargetScoring}
                                                        setTurnOn={setTargetScoring}
                                                        ariaLabelledby='targetScoringLabel'
                                                    />
                                                    <Label style={{marginBottom:'2em'}} id='targetScoringSliderLabel'>
                                                        Default target score (optional) <b>{defaultTargetScore === -1 ? 'N/A' : defaultTargetScore.toFixed(1) }</b>
                                                        <Tooltip
                                                            title={'You can set a default target score that will be applied to all capabilities within the campaign. Target scores for individual capabilities can then be updated as required.'}
                                                            placement="top"
                                                        >
                                                            <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                <InfoIcon style={{fontSize: '12px'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Label>
                                                    <TargetScoreSlider
                                                        isEnabled={isTargetScoring}
                                                        defaultValue={-1}
                                                        value={defaultTargetScore}
                                                        setValue={setDefaultTargetScore}
                                                        marks={marks}
                                                        valueLabelDisplay={'auto'}
                                                        step={null}
                                                        valueLabelFormat={() => defaultTargetScore === -1 ? 'N/A' : defaultTargetScore.toFixed(1)}
                                                        min={-1}
                                                        max={5}
                                                        ariaLabelledby='targetScoringSliderLabel'
                                                    />
                                                </>
                                            )}
                                            {isPointOneDecimalEnable(globalSettings) && (
                                                <>
                                                    <Label id='point1Label'>
                                                        Enable .1 decimal
                                                        <Tooltip
                                                            title={'Enabling .1 decimal will display average scores to the nearest .1 decimal, disabling will display to the nearest .5. When closing the campaign, scores will be saved to the selected decimal.'}
                                                            placement="top"
                                                        >
                                                            <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                <InfoIcon style={{fontSize: '12px'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Label>
                                                    <CustomToggleSwitch
                                                        turnedOn={isPointOneDecimal}
                                                        setTurnOn={setPointOneDecimal}
                                                        ariaLabelledby='point1Label'
                                                    />
                                                </>
                                            )}
                                        </ColumnWrapper>
                                        <Divider />
                                    </Flex>
                                </Column>
                            </Form>
                        );
                    }}
                </Formik>
            </Column>
        </div>
    );
};

const TruncatorWrapper = styled('div')`
    display: inline-block;
    max-width: 258px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
`;

const ColumnWrapper = styled(Column)`
    padding: 0 50px;
    min-width: 300px;
`;

const Label = styled(Text)`
    color: #6E6E6E;
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 13px;
    text-align: left;
`;

const CustomErrorMessage = styled(Text)`
    font-size: 0.75em;
    color: #de3328;
    text-align: left;
`;

const CustomToggleSwitch =  styled(ToggleSwitch)`
    padding: 0.4em !important;
    width: 4.625em !important;
    height: 1.375em !important;
    margin-top: 1em;
    margin-bottom: 2.5rem;
    && > span > div {
        padding: 0.2em 0.4em;
        font-size: 12px;
    }
`;


export default FirstStep;
