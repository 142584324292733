import { TabType } from './types';
import iconCompareCampaignsActive from '../../../assets/images/icon-compare-campaigns-active.svg';
import iconCompareCampaigns from '../../../assets/images/icon-compare-campaigns.svg';
import { styled } from '@mui/material/styles';
import { Column, Text } from '../../../components';

export const comparisonTabsList: TabType[] = [
    {
        id: 1,
        label: 'Compare campaigns',
        iconActive: iconCompareCampaignsActive,
        iconDisable: iconCompareCampaigns
    }
    // {
    //     id: 2,
    //     label: 'Compare assessments within a campaign',
    //     iconActive: iconCompareCampaignsActive,
    //     iconDisable: iconCompareCampaigns
    // },
    // {
    //     id: 3,
    //     label: 'Compare assessments from different campaigns',
    //     iconActive: iconCompareCampaignsActive,
    //     iconDisable: iconCompareCampaigns
    // }
];

export const ColumnWithMargin = styled(Column)`
    margin: 0 5px;
`;

export const CompareText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #575757;
    margin: 19px 20px 0;
`;

export const HeaderFilterHeading = styled(Text)`
    letter-spacing: 0.5px;
    color: #575757;
    margin-bottom: 8px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
