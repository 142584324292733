import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Formik, Form, Field } from 'formik';
import { OutlinedInput, Select, TextField } from '@mui/material';
import { MenuItem, Divider, Tooltip, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as Yup from 'yup';

import {
    Flex,
    Column,
    SpinnerWithOverlay,
    CustomField,
    Text,
    ToggleSwitch,
    TargetScoreSlider
} from '../../../components';

import iconCheckmark from '../../../assets/images/icon-green-checkmark.svg';
import { UserStore } from '../../../context/user-store';
import {renderModal} from './CreateCampaignHelper';
import { marks } from './CreateCampaignHelper';
import { dynamicSort, useDidMountEffect } from '../../../utils/UtilsHelpers';
import { isTargetScore, isPointOneDecimalEnable } from '../../../utils/global-settings';
import {
    targetScoreDisableMessage,
    targetScoreDisableTitle,
    targetScoreEnableMessage,
    targetScoreEnableTitle
} from '../../../utils';

const EDIT_CAMPAIGN = gql`
    mutation updateCampaign2($id: ID!, $data: UpdateCampaignInput!) {
        updateCampaign(id: $id, data: $data) {
            id
            title
            description
            progress
            targetScoreEnabled
            pointOneDecimalEnable
            targetScoreDefault
            assessmentsCnt
            stakeholder {
                id
                firstName
                lastName
            }
            members {
                id
                firstName
                lastName
            }
            readonlymembers {
                id
                firstName
                lastName
            }
            framework {
                id
                definition {
                    name
                }
            }
            manager {
                id
                firstName
                lastName
                jobTitle
                role
                updatedAt
                createdAt
            }
            status
            updatedAt
        }
    }
`;

const DROPDOWN_VALUES = gql`
    query dropdownValues2($clientId: Int!) {
        users: usersByClient(clientId: $clientId) {
            value: id
            firstName
            lastName
            role
        }
        frameworks {
            value: id
            definition {
                name
            }
        }
    }
`;

type Props = {
    campaignData: any;
    setFirstStepValid: any;
    setManagerChanged: any;
    setFirstStepEditValues: any;
    setFirstStepEditManager: any;
    firstStepEditValues: any;
    setNewlyAddedManager: any;
    setSelectedManager: any;
    setTargetScore: any;
    setPointDecimal: any
};

type Fields = {
    title: boolean;
    description: boolean;
    // stakeholderId: boolean;
    managerId: boolean;
};

type Member = {
    id: number;
    firstName: string;
    lastName: string;
    jobTitle: string;
    role: string;
    status: string;
    updatedAt: number;
    createdAt: number;
};

const firstStepSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .max(35, 'Too Long!(Should be Maximum 35 Character)')
        .required('Required'),
    clientId: Yup.number()
        .moreThan(0, 'Required')
        .required('Required'),
    managerId: Yup.number()
        .moreThan(1, 'Required')
        .required('Required'),
    frameworkId: Yup.number()
        .moreThan(0, 'Required')
        .required('Required')
});

const FirstStepEdit: React.FC<Props> = ({ 
    campaignData, 
    setFirstStepValid,
    setFirstStepEditValues,
    setFirstStepEditManager, 
    firstStepEditValues, 
    setManagerChanged,
    setNewlyAddedManager,
    setSelectedManager,
    setTargetScore,
    setPointDecimal
}) => {
    const {
        state: { user, client, globalSettings }
    } = useContext(UserStore);
    const [editCampaign] = useMutation(EDIT_CAMPAIGN);
    const [isTargetScoring, setTargetScoring] = useState<boolean>(campaignData.targetScoreEnabled);
    const [defaultTargetScore, setDefaultTargetScore] = useState<number>(-1);
    const [isPointOneDecimal, setPointOneDecimal] = useState<boolean>(campaignData.pointOneDecimalEnable);
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [confirmationModalScoring, setConfirmationModalScoring] = useState<boolean>(false);
    const [isManagerEdit, setIsManagerEdit] = useState<boolean>(false);
    const [isManagerDropdownLoading, setIsManagerDropdownLoading] = useState(false);
    const [editApiCalled,setEditApiCalled] = useState(false);
    
    const [descriptionCount, setDescriptionCount] = useState<number>(0);
    const { loading, data } = useQuery(DROPDOWN_VALUES, {
        variables: {
            clientId:
                campaignData && campaignData.client && campaignData.client.id
        }
    });

    const relationshipManager = user?.clients?.find((userData: any) => userData.id === client.id );
    const relationshipManagerDetails = campaignData.members.find((memberlist: any) => memberlist.id === relationshipManager?.owner?.id);


    const initialValues = firstStepEditValues ? firstStepEditValues : campaignData && {
        title: campaignData.title,
        description: campaignData.description,
        clientId: campaignData.client && campaignData.client.id,
        frameworkId: campaignData.framework && campaignData.framework.id,
         managerId: campaignData.manager && campaignData.manager.map((user: any) => user.id), 

    };

    const [formData, setFormData] = useState<any>(initialValues);
    const userMayEdit = isManagerEdit ||
            (user && user.id) ===
            (client && client.owner && client.owner.id) ||
            (campaignData && campaignData.manager.some((manager: any) => user.id === manager.id));

    const canUserEdit =
             (user && (campaignData && campaignData.manager.some((manager: any) => user.id === manager.id))) ||
             (user && user.id) === (client && client.owner && client.owner.id);

    useEffect(() => {
        if (formData && formData.managerId && formData.managerId.length > 0 && campaignData.manager && campaignData.manager.length > 0 && userMayEdit ) {
            const campaignDataIds = campaignData.manager.map((manager: any) => manager.id );
            const formDataIDs = formData.managerId.map((id: any) => id);

            // Check if any managers were added or removed
            const addedManagers = formDataIDs.filter(
                (id: any) => !campaignDataIds.includes(id)
            );
            setNewlyAddedManager(addedManagers);
            
            const removedManagers = campaignDataIds.filter(
                (managerId: any) => !formDataIDs.includes(managerId)
            );
            const invokeEditApi = addedManagers.length > 0 || (removedManagers.length > 0 && !editApiCalled);

            // Only update the campaign if managers were added or removed
            if (invokeEditApi) {
                setIsManagerDropdownLoading(true);
                setFirstStepEditManager(formDataIDs);
                setSelectedManager(formDataIDs);
                setIsManagerEdit(true);
                setIsManagerDropdownLoading(false);
                setEditApiCalled(false);
            } else if (!invokeEditApi && editApiCalled) {
                setIsManagerDropdownLoading(false);
                setEditApiCalled(false);
            }
        }
        campaignData.targetScoreDefault ||
            campaignData.targetScoreDefault === 0
            ? setDefaultTargetScore(campaignData.targetScoreDefault)
            : setDefaultTargetScore(-1);
    }, [formData, campaignData, userMayEdit]);

    useDidMountEffect(() => {
        if(!isTargetScoring){
            setConfirmationModal(true);
        }
            setTargetScore({
                targetScoreEnabled: isTargetScoring, 
                targetScoreDefault: !isTargetScoring ? undefined : defaultTargetScore === -1 ? null : defaultTargetScore,
            });
    }, [isTargetScoring]);

    useEffect(() => {
            setTargetScore({
                targetScoreEnabled: isTargetScoring, 
                targetScoreDefault: !isTargetScoring ? undefined : defaultTargetScore === -1 ? null : defaultTargetScore,
            });
    }, [defaultTargetScore]);

    useEffect(() => {
        if (firstStepEditValues?.pointOneDecimalEnable) {
          setPointOneDecimal(firstStepEditValues.pointOneDecimalEnable);
        }
        if (firstStepEditValues?.targetScoreDefault && firstStepEditValues?.targetScoreEnabled) {
          setTargetScoring(firstStepEditValues.targetScoreEnabled);
          setDefaultTargetScore(firstStepEditValues.targetScoreDefault);
        }
      }, [firstStepEditValues]);

      useEffect(() => {
        setPointDecimal({
            pointOneDecimalEnable: isPointOneDecimal
        });
        
      }, [isPointOneDecimal]);

    return (
        data ?
            <div>
                <Typography variant='h1' style={{
                        color: '#525252',
                        letterSpacing: '0.5px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        margin: '40px 0 12px 0',
                        display: 'block',
                        float: 'none',
                        width: 'unset'
                }}>Campaign details</Typography>
                <Column
                    style={{
                        marginBottom: 50,
                        position: 'relative',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxSizing: 'border-box'
                    }}
                >
                    {loading && <SpinnerWithOverlay />}
                    {campaignData ? (
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validateOnMount={firstStepSchema.isValidSync(initialValues)}
                            validationSchema={firstStepSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                // editCampaign({
                                //     variables: {
                                //         id: campaignData.id,
                                //         data: values
                                //     }
                                // })
                                //     .then(() => setSubmitting(false))
                                //     .catch(error => console.error(error));
                            }}>
                            {({
                                values,
                                validateForm,
                                isValid,
                                handleChange,
                                handleBlur
                            }) => {
                                setFirstStepValid(isValid);
                                setFirstStepEditValues(values);
                                return (
                                    <Form>
                                        <Column>
                                            <Flex>
                                                <ColumnWrapper>
                                                    <Label id='companyLabel'>Company</Label>
                                                    <Field
                                                        variant="outlined"
                                                        align="left"
                                                        name="clientId"
                                                        disabled
                                                        type="text"
                                                        style={{
                                                            marginBottom: 20
                                                        }}
                                                        as={Select}
                                                        aria-labelledby='companyLabel'
                                                    >
                                                        <MenuItem disabled>
                                                            Select company
                                                        </MenuItem>
                                                        {!loading &&
                                                            client && client.name &&
                                                                    <MenuItem
                                                                        key={
                                                                            client.id
                                                                        }
                                                                        value={
                                                                            client.id
                                                                        }
                                                                    >
                                                                        {
                                                                            client.name
                                                                        }
                                                                    </MenuItem>
                                                            }
                                                    </Field>
                                                    <Label id='campaignManagerLabel'>
                                                    Campaign manager(s)
                                                        <Tooltip
                                                            title={'Campaign manager(s) are responsible for managing campaign details, reviewing/approving capabilities, and closing the campaign. There can be up to 2 campaign managers.'}
                                                            placement="top"
                                                        >
                                                            <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                    <InfoIcon style={{fontSize: '12px'}}/>
                                                                </IconButton>
                                                        </Tooltip>
                                                    </Label>
                                                    <Field
                                                        variant="outlined"
                                                        align="left"
                                                        name="managerId"
                                                        type="text"
                                                        fullWidth
                                                        style={{
                                                            marginBottom: 10
                                                        }}
                                                        as={Select}
                                                        multiple
                                                        displayEmpty
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected: any[]) => {
                                                            if (selected.length === 0 && data && data.users.length === 0) {
                                                              return <>Select manager </>;
                                                            }
                                                          
                                                            const selectedUsers = data && data.users.filter((u: any) => selected.includes(u.value));
                                                            return selectedUsers.sort(dynamicSort('firstName')).map((su: any) => `${su.firstName} ${su.lastName}`).join(', ');
                                                          }}
                                                        onChange={(event: any) => {
                                                            const selectedValue = Array.from(event.target.value);
                                                            if(selectedValue.length <= 2 ){
                                                                handleChange(event);
                                                            }
                                                        }}
                                                        onClose={() => {
                                                            setFormData(values);
                                                        }}
                                                        value={values.managerId && values.managerId.length ? values.managerId : []}
                                                        inputProps={{
                                                            'aria-labelledby': 'campaignManagerLabel',
                                                            disabled: isManagerDropdownLoading || (campaignData && campaignData.status === 'CLOSED') || !userMayEdit,
                                                        }}
                                                        aria-labelledby='campaignManagerLabel'
                                                    >
                                                        <MenuItem disabled value="">
                                                            Select manager
                                                        </MenuItem>
                                                        {!loading &&
                                                            data &&
                                                            data?.users.slice().sort(dynamicSort('firstName')).map(
                                                                (option: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            `managerId-${option.value}`
                                                                        }
                                                                        disabled={values.managerId && values.managerId.length === 2 && !values.managerId.includes(option.value)}

                                                                        value={
                                                                            option.value
                                                                        }
                                                                    >
                                                                        {`${option.firstName} ${option.lastName}`}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </Field>
                                                    <Label id='campaignDescriptionLabel'>
                                                        Campaign description (optional)
                                                    </Label>
                                                    <Field
                                                        variant="outlined"
                                                        align="left"
                                                        name="description"
                                                        multiline
                                                        rows={5}
                                                        type="name"
                                                        as={TextField}
                                                        inputProps={{
                                                            onChange: (event: any) => { setDescriptionCount(event.target.value.length);},
                                                            'aria-labelledby':'campaignDescriptionLabel',
                                                            maxLength: '300'

                                                        }}

                                                        disabled={
                                                            (campaignData &&
                                                                campaignData.status ===
                                                                    'CLOSED') ||
                                                            !userMayEdit
                                                        }
                                                    />
                                                    <span style={{ textAlign:'left',marginTop:'10px' }}><Label>{( descriptionCount === 0 ? 300-campaignData.description.length : 300-descriptionCount)} characters remaining</Label></span>

                                                </ColumnWrapper>
                                                <ColumnWrapper>
                                                    <Label id='campaignLabel'>Campaign</Label>
                                                    <Field
                                                        variant="outlined"
                                                        align="left"
                                                        name="title"
                                                        type="text"
                                                        as={TextField}
                                                        inputProps={{ 
                                                            'aria-labelledby':'campaignLabel'
                                                        }}
                                                        disabled={
                                                            (campaignData &&
                                                                campaignData.status ===
                                                                    'CLOSED') ||
                                                            !userMayEdit
                                                        }
                                                    />
                                                    <Label id='campaignFrameworkLabel'>Framework</Label>
                                                    <Field
                                                        variant="outlined"
                                                        align="left"
                                                        name="frameworkId"
                                                        // error={!!errors.frameworkId}
                                                        type="text"
                                                        style={{
                                                            marginBottom: 20
                                                        }}
                                                        disabled
                                                        as={Select}
                                                        aria-labelledby='campaignFrameworkLabel'
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            value={0}
                                                        >
                                                            Select framework
                                                        </MenuItem>
                                                        {!loading &&
                                                            data &&
                                                            data.frameworks.map(
                                                                (option: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            option.value
                                                                        }
                                                                        value={
                                                                            option.value
                                                                        }
                                                                    >
                                                                        {
                                                                            option
                                                                                .definition
                                                                                .name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </Field>
                                                    { isTargetScore(globalSettings) && (
                                                    <>
                                                        <Label id='targetScoringLabel'>
                                                            Enable target scoring
                                                            <Tooltip
                                                                title={'Target scoring enables campaign members to set target scores for capabilities, which can be visualised and compared with the actual scores.'}
                                                                placement="top"
                                                            >
                                                                <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                    <InfoIcon style={{fontSize: '12px'}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Label>
                                                        <CustomToggleSwitch
                                                            turnedOn={isTargetScoring}
                                                            setTurnOn={setTargetScoring}
                                                            ariaLabelledby='targetScoringLabel'
                                                        />
                                                        <Label style={{marginBottom:'2em'}} id='targetScoringSliderLabel'>
                                                            Default target score (optional): <b>{defaultTargetScore === -1 ? 'N/A' : defaultTargetScore.toFixed(1) }</b>
                                                            <Tooltip
                                                                title={'You can set a default target score that will be applied to all capabilities within the campaign. Target scores for individual capabilities can then be updated as required.'}
                                                                placement="top"
                                                            >
                                                                <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                    <InfoIcon style={{fontSize: '12px'}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Label>
                                                        <TargetScoreSlider
                                                            isEnabled={isTargetScoring}
                                                            defaultValue={-1}
                                                            value={defaultTargetScore}
                                                            setValue={setDefaultTargetScore}
                                                            onBlur={(event: object, value: number | number[]) => {
                                                                if(typeof value === 'number'){
                                                                    setConfirmationModalScoring(true);
                                                                }
                                                            }}
                                                            marks={marks}
                                                            valueLabelDisplay={'auto'}
                                                            step={null}
                                                            valueLabelFormat={() => defaultTargetScore === -1 ? 'N/A' : defaultTargetScore.toFixed(1)}
                                                            min={-1}
                                                            max={5}
                                                            ariaLabelledby='targetScoringSliderLabel'
                                                        />
                                                        {renderModal({
                                                            handleCancel: () => {
                                                                setConfirmationModal(false);
                                                                setTargetScoring(true);
                                                            },
                                                            handleAccept: () => {
                                                                setConfirmationModal(false);
                                                            },
                                                            isOpen: confirmationModal,
                                                            title: targetScoreDisableTitle,
                                                            text: targetScoreDisableMessage
                                                        })}
                                                        {renderModal({
                                                            handleCancel: () => {
                                                                setConfirmationModalScoring(false);
                                                                campaignData.targetScoreDefault || campaignData.targetScoreDefault === 0  ? setDefaultTargetScore(campaignData.targetScoreDefault) : setDefaultTargetScore(-1);
                                                            },
                                                            handleAccept: () => {
                                                                setConfirmationModalScoring(false);
                                                            },
                                                            isOpen: confirmationModalScoring,
                                                            title: targetScoreEnableTitle,
                                                            text: targetScoreEnableMessage
                                                        })}
                                                    </>
                                                    )}
                                                    {isPointOneDecimalEnable(globalSettings) && (
                                                    <>
                                                        <Label id='point1Label'>
                                                            Enable .1 decimal
                                                            <Tooltip
                                                                title={'Enabling .1 decimal will display average scores to the nearest .1 decimal, disabling will display to the nearest .5. When closing the campaign, scores will be saved to the selected decimal.'}
                                                                placement="top"
                                                            >
                                                                <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                                    <InfoIcon style={{fontSize: '12px'}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Label>
                                                        <CustomToggleSwitch
                                                            disabled={!canUserEdit}
                                                            turnedOn={isPointOneDecimal}
                                                            setTurnOn={setPointOneDecimal}
                                                            ariaLabelledby='point1Label'
                                                        />
                                                    </>
                                                    )}
                                                </ColumnWrapper>
                                                <Divider />
                                            </Flex>
                                        </Column>
                                    </Form>
                                );
                            }}
                    </Formik>
                    ) : (
                        <SpinnerWithOverlay />
                    )}
                </Column>

            </div> :
            <></>
    );
};

const ColumnWrapper = styled(Column)`
    padding: 0 50px;
    min-width: 300px;
`;

const Label = styled(Text)`
    color: #6E6E6E;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    text-align: left;
`;

const BluringField = styled(CustomField)`
    && {
        resize: none;
        font-size: 15px;
        margin-bottom: 10px;
        outline: none;
        padding: 10px;
        border-radius: 4px;
        border-width: thin;
        border-style:solid;
    }
    &:hover {
        border: 1px solid #000000;
    }
`;

const SuccessMessage = styled(Text)`
    color: #128286;
    font-weight: 300;
    line-height: 1.47;
    font-size: 15;
    text-align: left;
`;

const CustomToggleSwitch =  styled(ToggleSwitch)`
    padding: 0.4em !important;
    width: 4.625em !important;
    height: 1.375em !important;
    margin-top: 1em;
    margin-bottom: 2.5rem;
    && > span > div {
        padding: 0.2em 0.4em;
        font-size: 12px;
    }
`;

export default FirstStepEdit;
