import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { JustifyContentCenter } from '../../../../../components';
import { CampaignGroupAssessment } from '../../MaturityOverview/types';
import {
    capabilitiesAverage,
    dataFormatter
} from '../MaturityOverviewTabsHelpers';
import { colorsList } from '../../../../../utils';
import Bargraph from '../../../../../components/Charts/BarGraph/BarGraph';
import { MaturityGraphData, targetScoreData } from './MaturityOverviewBarChartHelpers';
import { Series } from '../../../../../components/Charts/BarGraph/types';

type Props = {
    assessmentsData: CampaignGroupAssessment[];
    companyName: string;
    showTargetScore: boolean;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    pointOneDecimalEnable: boolean;
    showCapabilityStatus?: boolean;
};
const MaturityOverviewBarChart: React.FC<Props> = ({
    assessmentsData,
    companyName,
    showTargetScore,
    readOnlyEnabled = false,
    isReadOnly = false,
    pointOneDecimalEnable= false
}) => {
    const capabilityListWithDomainName = capabilitiesAverage(assessmentsData, readOnlyEnabled, isReadOnly);
    const CapabilityByDomain = dataFormatter(capabilityListWithDomainName);
    const data = MaturityGraphData(CapabilityByDomain, pointOneDecimalEnable);
    const [colors, setColors] = useState<string[]>(colorsList);
    const [seriesData, setSeriesData] = useState<Series[]>(data.seriesData);

    const capabilityStatus = assessmentsData[0].assessments[0].state.status !== 'COMPLETED' ? true : false;
    useEffect(()=>{
        if(showTargetScore){
            const targetScoreSeriesData = targetScoreData(CapabilityByDomain);
            setSeriesData([targetScoreSeriesData, ...seriesData]);
            setColors(['grey', ...colorsList]);
        }
        else{
            setSeriesData(data.seriesData);
            setColors(colorsList);
        }
    },[showTargetScore]);
    return (
        <ChartContainer>
            <Bargraph
                title={
                    assessmentsData[0] &&
                    assessmentsData[0].assessments[0] &&
                    assessmentsData[0].assessments[0].campaign &&
                    assessmentsData[0].assessments[0].campaign.title +
                    ': ' +
                    companyName +
                    ' Capability Average'
                }
                objectivesLabelsList={
                    assessmentsData[0].assessments[0].framework.definition
                        .scores
                }
                seriesData={seriesData}
                legendData={data.legendData}
                xAxisData={data.xData}
                isCapabilityByDomain={false}
                colors={colors}
                barToLine={true}
                showCapabilityStatus={capabilityStatus}
            />
        </ChartContainer>
    );
};
const ChartContainer = styled(JustifyContentCenter)`
    padding: 50px;
    flex-direction: column;
`;

export default MaturityOverviewBarChart;
