import React from 'react';
import { styled } from '@mui/material/styles';
import { JustifyContentCenter, Text, RedButton } from '../../../../components';
import addIcon from '../../../../assets/images/icon-add.svg';
import { TAREmptyStateProps } from '../types';
import { AlignItems } from '../../../../components/base/Layout';

export const TAREmptyState: React.FC<TAREmptyStateProps> = ({
    headingText,
    explanationText,
    buttonText,
    isAddButtonDisabled,
    imageSrc,
    setAddTARModalOpen,
    setAddModal
}) => {
    return (
        <div>
            <EmptyState>
                <img src={imageSrc} alt="" style={{ maxWidth: '100%' }} />
                <EmptyStateHeading>{headingText}</EmptyStateHeading>
                <EmptyStateText>{explanationText}</EmptyStateText>
                <AddButton
                    disabled={isAddButtonDisabled}
                    onClick={() => {
                        setAddModal && setAddModal(true);
                        setAddTARModalOpen && setAddTARModalOpen(true);
                    }}
                >
                    <AlignItems>
                        <Icon src={addIcon} alt="Add risk" />
                        <Text size="18px" color="#fff">
                            {buttonText}
                        </Text>
                    </AlignItems>
                </AddButton>
            </EmptyState>
        </div>
    );
};

const EmptyState = styled(JustifyContentCenter)`
    flex-direction: column;
    padding: 20px 0;
`;

const EmptyStateText = styled(Text)`
    font-size: 16px;
    color: #666666;
    max-width: 400px;
    text-align: center;
    margin-bottom: 5px;
`;

const AddButton = styled(RedButton)<{ disabled: boolean }>`
    && {
        border-radius: 2px;
        margin: 35px 0 0;
        padding: 0.8rem 1.5rem;
        min-height: 40px;
        opacity: ${props => (props.disabled ? '0.5' : '1')};
    }
`;

const EmptyStateHeading = styled(Text)`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #666666;
    margin-top: 46px;
    margin-bottom: 16px;
`;

const Icon = styled('img')`
    && {
        margin-right: 10px;
        height: 24px;
    }
`;

export default TAREmptyState;
