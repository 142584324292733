import React from 'react';
import { styled } from '@mui/material/styles';

import Text from '../base/Text/Text';

type Props = {
    percentage: number;
    sqSize: number;
    strokeWidth: number;
};

const CustomCircularProgress: React.FC<Props> = ({
    percentage,
    sqSize,
    strokeWidth
}) => {
    // Size of the enclosing square
    const sqSize1 = sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * percentage) / 100;

    return (
        <div
            style={{
                position: 'relative',
                paddingBottom: 6,
                width: 200,
                height: 200,
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <svg width="100%" viewBox={viewBox}>
                <circle
                    className="circle-background"
                    cx={sqSize1 / 2}
                    cy={sqSize1 / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    stroke="#eeeeee"
                    fill="#fff"
                />
                <circle
                    className="circle-progress"
                    cx={sqSize1 / 2}
                    cy={sqSize1 / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    fill="transparent"
                    stroke={percentage === 100 ? '#4588c3' : '#fdb52b'}
                    strokeLinecap="round"
                    transform={`rotate(-90 ${sqSize1 / 2} ${sqSize1 / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }}
                />
            </svg>
            <PercentageText
                style={{
                    left: '50%',
                    transform: 'translate(-50%)'
                }}
            >{`${percentage}%`}</PercentageText>
            <CamapaignText
                style={{
                    top: '48%',
                    left: '50%',
                    transform: 'translate(-50%)'
                }}
            >
                Campaign
            </CamapaignText>
            <CamapaignText
                style={{
                    top: '60%',
                    left: '50%',
                    transform: 'translate(-50%)'
                }}
            >
                Progress
            </CamapaignText>
        </div>
    );
};

const PercentageText = styled(Text)`
    font-size: 36px;
    font-weight: bold;
    color: #6E6E6E;
    position: absolute;
    top: 25%;
`;

const CamapaignText = styled(Text)`
    font-size: 14px;
    font-weight: bold;
    color: #6E6E6E;
    position: absolute;
`;

export default CustomCircularProgress;
