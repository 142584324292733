import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { H1Text, PieChart, Flex } from '../../../../../components';
import {chartDraftWarning, colorsList} from '../../../../../utils';
import { AssessmentData } from '../../../AssessmentsMaturityOverview/types';
import { CampaignGroupAssessment } from '../../MaturityOverview/types';
import TooltipByClick from '../../../../../components/Tooltips/TooltipByClick';
import ChartWarningTag from '../../../../../components/base/ChartWarning/ChartWarningTag';

export type Score = {
    score: number | null;
    name: string;
};

type Props = {
    assessmentsData: CampaignGroupAssessment[];
    sectionsList: Score[];
    campaignTitle: string;
    companyName: string;
    showTargetScore?: boolean;
    pointOneDecimalEnable?: boolean;
};

const MaturityOverviewPieChart: React.FC<Props> = ({
    assessmentsData,
    sectionsList,
    campaignTitle,
    companyName,
    showTargetScore,
    pointOneDecimalEnable
}) => {
    const [bottomDomain, setBottomDomain] = useState(-1);
    const [sectionSize, setSectionSize] = useState(0);
    useEffect(() => {
        if (sectionsList) {
            setSectionSize(100 / sectionsList.length);
        }
    }, [sectionsList]);
    useEffect(() => {
        if (sectionsList && sectionsList.length % 2 > 0) {
            setBottomDomain(sectionsList.length / 2 - 0.5);
        }
    }, [sectionsList]);
    const capabilityStatus = assessmentsData[0].assessments[0].state.status !== 'COMPLETED' ? true : false;
     return (
        <ChartContainer>
            <H1Text color="#4f4f4f" style={{ textAlign: 'center',width:'100%' }}>
                {campaignTitle}: {companyName} Domain Average
            </H1Text>
            <ChartWarningTag> {chartDraftWarning}</ChartWarningTag>
            <PieChart
                saveAsImageText={'assessment_average_pie_chart'}
                scoresList={sectionsList}
                maxScoreRange={5}
                minScoreRange={0}
                sectorSize={sectionSize}
                bottomDomain={bottomDomain}
                colorsList={colorsList}
                showTargetScore={showTargetScore}
                showCapabilityStatus={capabilityStatus}
                pointOneDecimalEnable={pointOneDecimalEnable}
            />
        </ChartContainer>
    );
};

const ChartContainer = styled(Flex)`
    padding: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export default MaturityOverviewPieChart;
