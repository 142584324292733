import Dashboard from './../modules/Dashboard/Dashboard';
import CompanyAssessment from './../modules/Evaluate/Assessments/CompanyAssessment';
import AssessmentsMaturityOverview from './../modules/Evaluate/AssessmentsMaturityOverview/AssessmentsMaturityOverview';
import CompanyStructure from './../modules/CompanyStructure/CompanyStructure';

import Evaluate from '../modules/Evaluate';
import CreateCampaign from '../modules/Evaluate/CreateCampaign';
import Benchmarking from '../modules/Analyse/Benchmarking';
import Comparison from '../modules/Analyse/Comparison';
import ThreatAndRisk from '../modules/Analyse/ThreatAndRisk';
import StakeholderManagement from '../modules/StakeholderManagement/StakeholderManagement';
import EvaluateDetails from '../modules/Evaluate/MaturityOverview/EvaluateDetails/EvaluateDetails';
import { Routes as RoutesEnum } from './routes.enum';
import EditCampaign from '../modules/Evaluate/EditCampaign';
import TermsOfUse from '../modules/TermsOfUse';
import PrivateRoute from '../secure/PrivateRoute';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { User, Client, Campaign } from '../context/reducer';
import { UserStore } from '../context/user-store';
import { AppData, ClientType } from '../modules/App/types';
import { addJwtToWhitelist } from '../services/ApolloClient/client';
import { GlobalSettings } from '../utils';
import { getLocalStorageItem, setLocalStorageItem } from '../utils/local-storage';
import { USER_DATA, GLOBAL_SETTINGS } from './RouteAuthQueries';
import RouteType from './route.types';
import Main from '../modules/Main';

import { Route, Routes } from 'react-router-dom';
import { default as MaturityScore } from '../modules/Evaluate/MaturityScore/MaturityScore.container';
import LoginCallback from '../modules/AuthPage/LoginCallback';
import AuthPage from '../modules/AuthPage/AuthPage';
import { useAuth } from 'react-oidc-context';
import Router from './router';


const RouteConfig: React.FC = () => {

    const auth = useAuth();
    if(auth?.user?.expires_in && auth.user.expires_in < 0){
        auth.signoutSilent();
        auth.removeUser();
        sessionStorage.clear();
        localStorage.clear();
        Router.goToLogoutPage();
    }

    const {
        state: { client },
        dispatch,
    } = useContext(UserStore);
    const {
        state: { user },
    } = useContext(UserStore);

    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

    const setCurrentUser = (userData: User) =>
    dispatch({ type: 'SET_USER', value: userData });
    const setCurrentClient = (client: Client) =>
        dispatch({ type: 'SET_CLIENT', value: client });
    const setCurrentCampaign = (campaign: Campaign) =>
        dispatch({ type: 'SET_CAMPAIGN', value: campaign });
    const setCurrentGlobalSettings = (globalSettings: GlobalSettings[]) =>
        dispatch({ type: 'SET_GLOBALSETTINGS', value: globalSettings });

    const refreshTokenTime = window.__ENV__.MIN_TIME_REFRESH_TOKEN ? Number(window.__ENV__.MIN_TIME_REFRESH_TOKEN) : 600000;


    function useInterval(callback: () => void, delay: number) {
        const savedCallback = useRef(callback);

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                const id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    useInterval(() => {
        if(auth?.user?.expires_in && auth.user.expires_in < 60){
            auth.signoutSilent();
            auth.removeUser();
            sessionStorage.clear();
            localStorage.clear();
            Router.goToLogoutPage();
        }
    }, 60000);

    const whitelistJwt = async (jwtToken?: string, accessToken?: string) => {
        setLocalStorageItem('pid-token', jwtToken);
        setLocalStorageItem('pid-access-token', accessToken);
        await addJwtToWhitelist(jwtToken, accessToken)
        .then(() => {
            getGlobalSettings();
            getUserData();
            if (!client) {
                setCurrentClient({});
            }
        }).catch((error: Error) => {
            if(error.message == 'User not found'){
                alert(
                    'You\'re not authorised to view this page. Please contact an administrator for access'
                );
            }
            auth.signoutSilent();
            auth.removeUser();
            localStorage.clear();
            sessionStorage.clear();
            Router.goToLogoutPage();
        });
    };

    const [getGlobalSettings] = useLazyQuery<{
        globalSettings: GlobalSettings[];
    }>(GLOBAL_SETTINGS, {
        onCompleted: (data) => {
            if (data && data.globalSettings) {
                setCurrentGlobalSettings(data.globalSettings);
            }
        },
    });

    const [getUserData, { data }] = useLazyQuery<AppData>(USER_DATA, {
        onCompleted: (data) => {
            const { me } = data;
            const listFiltered = me.clients.filter((client)=>client.isSynced === true);
            const meUserData = { ...me, 'clients' : listFiltered};
            setCurrentUser(meUserData);

            if (meUserData.campaigns) {
                setCurrentCampaign(meUserData.campaigns[0]);
            }

            if (meUserData.clients) {
                const { clients } = meUserData;
                const clientMatch = clients.filter(
                    (el: ClientType) => el.id === meUserData.id
                );
                const localStorageClient = getLocalStorageItem('client');
                if (localStorageClient) {
                    const updatedClient = clients.find(client => client.id === JSON.parse(localStorageClient)?.id);
                    setCurrentClient(updatedClient? updatedClient: JSON.parse(localStorageClient));
                } else {
                    const clientForSet = clientMatch.length
                        ? clientMatch[0]
                        : clients[0];
                    setCurrentClient(clientForSet);
                }
            }
            setIsUserLoggedIn(true);
        },
        onError: (error: Error) => {
            if(error.message === 'User is disabled'){
                alert(
                    'You\'re not authorised to view this page. Please contact an administrator for access'
                );
                auth.signoutSilent();
                auth.removeUser();
                localStorage.clear();
                sessionStorage.clear();
                Router.goToLogoutPage();
            }
        }

    });

    useEffect(() => {
        const token = getLocalStorageItem('pid-token');
        if(
            (!auth.isLoading && auth.isAuthenticated && auth?.user?.id_token && auth?.user?.access_token && !user.role && !token)
        ){
            whitelistJwt(auth?.user?.id_token, auth?.user?.access_token);
        }
        if(!auth.isLoading && auth.isAuthenticated && auth?.user?.id_token && !user.role && token){
            if(token){
                getUserData();
                getGlobalSettings();
            }
            if (!client) {
                setCurrentClient({});
            }
        }
        setIsUserLoggedIn(true);
    }, [auth]);

    useEffect(() => {
        setLocalStorageItem('pid-token', auth.user?.id_token);
        whitelistJwt(auth?.user?.id_token, auth?.user?.access_token);
    }, [auth.user?.id_token]);


    useEffect(() => {
        const interval = setInterval(() => {
            if(auth.isAuthenticated){
                auth.signinSilent();
            }
        }, refreshTokenTime);

        return () => clearInterval(interval);
        }, [auth.isAuthenticated, refreshTokenTime]);

    const subroutes = {
        [RoutesEnum.main]: [
            {
                path: RoutesEnum.createCampaign,
                element: <PrivateRoute><CreateCampaign /></PrivateRoute>,
            },
            {
                path: RoutesEnum.evaluateCampaign,
                element: <PrivateRoute><Evaluate /></PrivateRoute>,
            },
            {
                path: RoutesEnum.benchmarking,
                element: <PrivateRoute><Benchmarking /></PrivateRoute>,
            },
            {
                path: RoutesEnum.threatAndRisk,
                element: <PrivateRoute><ThreatAndRisk /></PrivateRoute>,
            },
            {
                path: RoutesEnum.comparison,
                element: <PrivateRoute><Comparison /></PrivateRoute>,
            },
            {
                path: RoutesEnum.evaluateAssessments,
                element: <PrivateRoute><CompanyAssessment /></PrivateRoute>,
            },
            {
                path: RoutesEnum.stakeholderManagement,
                element: <PrivateRoute><StakeholderManagement /></PrivateRoute>,
            },
            {
                path: RoutesEnum.companyStructure,
                element: <PrivateRoute><CompanyStructure /></PrivateRoute>,
            },
            {
                path: RoutesEnum.termsOfUse,
                element: <PrivateRoute><TermsOfUse/></PrivateRoute>,
            },
            {
                path: RoutesEnum.assessmentMaturityScore,
                element: (
                    <PrivateRoute><MaturityScore /></ PrivateRoute>
                ),
            },
            {
                path: RoutesEnum.assessmentsMaturityOverview,
                element: <PrivateRoute><AssessmentsMaturityOverview /></PrivateRoute>,
            },
            {
                path: RoutesEnum.dashboard,
                element: <PrivateRoute><Dashboard /></PrivateRoute>,
            },
            {
                path: RoutesEnum.editCampaign,
                element: <PrivateRoute> <EditCampaign/></PrivateRoute>
            },
            {
                path: RoutesEnum.maturityOverview,
                element: <PrivateRoute><EvaluateDetails/></PrivateRoute>
            },
        ]
    };

    return (
        <>{
            (auth.isLoading && !isUserLoggedIn)  || (auth.isAuthenticated && !user.role) ? <></> :
            <Routes>
                <Route path={RoutesEnum.splash} element={<AuthPage />}/>
                <Route path={RoutesEnum.loginRedirect} element={<LoginCallback/>}/>
                <Route path={RoutesEnum.logoutRedirect} element={<AuthPage/>}/>

                <Route
                    path={RoutesEnum.main}
                    element={<PrivateRoute children={<Main />}></PrivateRoute>}
                >
                    {
                    subroutes[RoutesEnum.main].map((route: RouteType, index) => {
                        return (
                            <Route
                                path={route.path}
                                element={route.element}
                                key={index}
                            />
                        );
                    })}
                </Route>
            </Routes>
        }
        </>
    );


};


export default RouteConfig;
