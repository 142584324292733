import { styled } from '@mui/material/styles';

const ImgContainer = styled('img')<{
    padding?: string;
}>`
    padding: ${(props) => props.padding ? props.padding : '8px'};
`;

export default ImgContainer;

