import React, {useContext} from 'react';
import { UserStore } from '../../context/user-store';
import { Navigate } from 'react-router-dom';
import { Routes } from '../../routes/routes.enum';



const LoginCallback: React.FC = () => {

    const { state: { user } } = useContext(UserStore);

    if(user?.role){
        return <Navigate to={{ pathname: Routes.dashboard }} />;
    }

    return (
        <>
        </>
    );
};

export default LoginCallback;

