import React from 'react';
import 'date-fns';

import { createTheme, ThemeProvider } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const materialTheme = createTheme({
    palette: {
        primary: {
            main: '#de3328'
        }
    }
});

type Props = {
    value: Date;
    onChange: (value: any, context: any) => void;
    disabled?: boolean | null;
    format?: string;
    inputVariant?: 'standard' | 'outlined' | 'filled';
    inputMargin?: 'none' | 'dense' | 'normal';
    inputName: string;
    disableFuture?: boolean;
    minDate?: Date;
};

const DateTimePickerField: React.FC<Props> = ({
    value,
    onChange,
    disabled,
    format,
    inputVariant,
    inputMargin,
    inputName,
    disableFuture,
    minDate,
    ...props
}) => {
    return (
        <ThemeProvider theme={materialTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    value={value}
                    onChange={onChange}
                    disabled={disabled !== null ? disabled : false}
                    format={format}
                    slotProps={{
                        textField: {
                            inputProps: {
                                'aria-label':'enter date'
                            },
                            name: inputName,
                            variant: inputVariant,
                            margin: inputMargin
                        }
                    }}
                    disableFuture={disableFuture}
                    minDate={minDate}
                    {...props}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default DateTimePickerField;
