import React from 'react';
import { styled } from '@mui/material/styles';

type Props = {
    progress: number;
    containerWidth?: string;
};

const LinearProgress: React.FC<Props> = ({
    progress,
    containerWidth = '100%'
}) => {
    return (
        <Container style={{ width: containerWidth, backgroundColor: '#e7e7e7' }}>
            <Bar
                style={{
                    backgroundColor: progress === 100 ? '#4588c3' : '#fdb52b',
                    width: `${progress}%`
                }}
            />
        </Container>
    );
};

const Container = styled('div')`
    border-radius: 8px
    height: 8px
`;

const Bar = styled('div')`
    height: 8px;
    border-radius: 8px;
`;

export default LinearProgress;
