import React from 'react';
import { styled } from '@mui/material/styles';

import {
    JustifyContentCenter,
    BoldText
} from '../../../../../components';

type Props = {
    backgroundColor?: string;
    text?: string;
    legendIcon?: string;
    width?: string;
};

const Rating: React.FC<Props> = ({
    backgroundColor,
    text,
    legendIcon,
    width
}) => {
    return (
        <BlockRating
            style={{
                background: `${backgroundColor}`
            }}
            width={width}
        >
            {text &&
                <LegendLabel>
                    {text}
                </LegendLabel>
            }

            {legendIcon &&
                <LegendIcon dangerouslySetInnerHTML={{ __html: legendIcon }}/>
            }

        </BlockRating>
    );
};

const LegendLabel = styled(BoldText)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 0;
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    padding: 0;
    flexDirection: row-reverse;

    @media (max-width: 1366px) {
        font-size:  12px
    };
`;

const LegendIcon = styled(JustifyContentCenter)`
    right: 0.5rem;
    top: 0.5rem;
    height: calc(100% - 1rem);
`;

const BlockRating = styled(JustifyContentCenter)<{ width?: string }>`
    color: white;
    text-align: center;
    padding: 0px 0.5rem;
    display: flex;
    height: 42px;
    box-sizing: border-box;
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    border-radius: 2px;
`;

export default Rating;

