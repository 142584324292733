import React from 'react';
import {
    Column,
    Text,
    Flex,
    BoldText,
    JustifyContentCenter
} from '../../../../../components';
import { styled } from '@mui/material/styles';
import { ScoreTableType, ratingsType } from '../../types';

const ScoreTable: React.FC<ScoreTableType> = ({
    columnOneTitle,
    columnTwoTitle,
    columnThreeTitle,
    columnOneData,
    columnTwoData,
    columnThreeData
}) => {
    return (
        <ScoreTableContainer>
            <Columns>
                <ColumnsHeaders>
                    <BoldText fontSize="15px" letterSpacing="0.5px">
                        {columnOneTitle}
                    </BoldText>
                </ColumnsHeaders>
                {columnOneData.map(
                    (columnOneDataItem: ratingsType, index: number) => {
                        return (
                            <ColumnsContent
                                style={{
                                    backgroundColor: `${columnOneDataItem.background}`
                                }}
                                key={`${columnOneDataItem.title}${index}`}
                            >
                                <Text size="15px" color="#fff">
                                    {columnOneDataItem.title}
                                </Text>
                            </ColumnsContent>
                        );
                    }
                )}
            </Columns>

            <Columns>
                <ColumnsHeaders>
                    <BoldText fontSize="15px" letterSpacing="0.5px">
                        {columnTwoTitle}
                    </BoldText>
                </ColumnsHeaders>
                {columnTwoData.map((columnTwoDataItem: Array<any>) =>
                    columnTwoDataItem.map(
                        (
                            item: {
                                background: string;
                                containerHeight: string;
                                title: string;
                            },
                            index: number
                        ) => {
                            if (columnTwoDataItem.length - 1 !== index) {
                                return (
                                    <ColumnsContent
                                        style={{
                                            backgroundColor: `${item.background}`,
                                            height: `calc(140px * ${item.containerHeight})`
                                        }}
                                        key={`${item.title}${index}`}
                                    >
                                        <Text size="15px" color="#fff">
                                            {item.title} ({index + 1})
                                        </Text>
                                    </ColumnsContent>
                                );
                            }
                        }
                    )
                )}
            </Columns>

            <Columns>
                <ColumnsHeaders>
                    <BoldText fontSize="15px" letterSpacing="0.5px">
                        {columnThreeTitle}
                    </BoldText>
                </ColumnsHeaders>
                {columnThreeData.map((columnThreeDataItem: Array<any>) =>
                    columnThreeDataItem.map(
                        (
                            item: {
                                background: string;
                                containerHeight: string;
                                title: string;
                            },
                            index: number
                        ) => (
                            <ColumnsContent
                                style={{
                                    backgroundColor: `${item.background}`,
                                    height: `calc(140px * ${item.containerHeight})`
                                }}
                                key={`${item.title}${index}`}
                            >
                                <Text size="15px" color="#fff">
                                    {item.title}
                                </Text>
                            </ColumnsContent>
                        )
                    )
                )}
            </Columns>
        </ScoreTableContainer>
    );
};

const Columns = styled(Column)`
    width: 100%;
`;

const ColumnsContent = styled(JustifyContentCenter)`
    border: solid 0.5px #eaeae9;
    box-sizing: border-box;
    color: #fff;
    height: 140px;
`;

const ColumnsHeaders = styled(JustifyContentCenter)`
    padding: 8px 16px;
    text-align: center;
    border: solid 0.5px #eaeae9;
`;

const ScoreTableContainer = styled(Flex)`
    max-width: 490px;
    margin: 1rem auto;
    border: solid 0.5px #eaeae9;
`;

export default ScoreTable;
