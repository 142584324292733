import React, { useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import {
    AlignItems,
    Column,
    Text,
    SpaceBetween,
    ScoreSquare,
    Link,
    Flex
} from '../../../../../components';
import {
    textColorResolver,
    withoutThemeColorResolver
} from '../../../../../utils';
import { Mark } from '../../types';

type MarkType = {
    label?: string | null;
    value: number;
};

export type ObjectiveTitleProps = {
    averageScore: number | null;
    marks: Mark[];
    name: string;
    canEdit: boolean;
    scoreMarks: string[];
};

const ObjectiveTitle: React.FC<ObjectiveTitleProps> = ({
    averageScore,
    marks,
    name,
    canEdit,
    scoreMarks
}) => {
    const objectiveTitleRef = useRef<HTMLDivElement>(null);

    const handleTitlePosition = () => {
        const offsetTop =
            objectiveTitleRef &&
            objectiveTitleRef.current &&
            objectiveTitleRef.current.offsetTop;
        const headerGap = document.getElementById('header_gap');
        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY;
            if (
                offsetTop &&
                offsetTop <= scrollPosition &&
                objectiveTitleRef.current
            ) {
                objectiveTitleRef.current.style.position = 'fixed';
                objectiveTitleRef.current.style.width = '88%';
                if (headerGap) {
                    headerGap.style.height = '83px';
                }
            }
            if (
                offsetTop &&
                offsetTop >= scrollPosition &&
                objectiveTitleRef.current
            ) {
                objectiveTitleRef.current.style.position = 'static';
                objectiveTitleRef.current.style.width = '100%';
                if (headerGap) {
                    headerGap.style.height = '0px';
                }
            }
        });
    };

    useEffect(() => {
        handleTitlePosition();
    }, []);

    return (
        <>
            <ContainerWrap ref={objectiveTitleRef}>
                <ContainerWithMargin>
                    {/*{!isNotesSelectDisplay ? (*/}
                    {/*        <CustomSelect*/}
                    {/*            native*/}
                    {/*            variant="outlined"*/}
                    {/*            value={notesStatus}*/}
                    {/*            onChange={e => {*/}
                    {/*        >*/}
                    {/*            {notesList.map(*/}
                    {/*                ({*/}
                    {/*                    id,*/}
                    {/*                    label*/}
                    {/*                }: {*/}
                    {/*                    id: string;*/}
                    {/*                    label: string;*/}
                    {/*                }) => (*/}
                    {/*                    <option key={id} value={id}>*/}
                    {/*                    </option>*/}
                    {/*                )*/}
                    {/*            )}*/}
                    {/*        </CustomSelect>*/}
                    {/*) : null}*/}
                    <TitleContainer>
                        <Text size={'18px'} weight={'bold'} color="#575757">
                            {name}
                        </Text>

                        <Block>
                            <Text>Avg</Text>
                            <ScoreBlock>
                                <ScoreSquare
                                    background={withoutThemeColorResolver(
                                        averageScore
                                    )}
                                    color={textColorResolver(averageScore, '', '')}
                                >
                                    {averageScore !== null && averageScore}
                                </ScoreSquare>
                            </ScoreBlock>
                        </Block>
                    </TitleContainer>

                    <SliderContainer>
                        <LabelContainer>
                            {scoreMarks.map((item: string, index: number) => (
                                <Label key={index}>{item.toUpperCase()}</Label>
                            ))}
                        </LabelContainer>

                        <MarksContainer>
                            {marks.map((mark: MarkType, index: number) => {
                                return (
                                    <MarkLabel key={index}>{mark.value}</MarkLabel>
                                );
                            })}
                        </MarksContainer>
                    </SliderContainer>
                </ContainerWithMargin>
            </ContainerWrap>
            <div id="header_gap"></div>
        </>
    );
};

const ContainerWrap = styled(AlignItems)`
    top: 0;
    background-color: white;
    z-index: 100;
    right: 5.4%;
    left: 6%;
    padding: 0;
`;

const ContainerWithMargin = styled(AlignItems)`
    margin-bottom: 25px;
    width: 100%;
`;

const Block = styled(AlignItems)`
    margin-left: 20px;
`;

const ScoreBlock = styled(Column)`
    margin-left: 16px;
`;

const LinkWithMargin = styled(Link)`
    margin-top: 5px;
`;

const SliderContainer = styled(SpaceBetween)`
    width: 75%;
    flex-direction: column;
`;

const TitleContainer = styled(SpaceBetween)`
    width: 25%;
    align-items: center;
    margin-right: 20px;
`;

const MarksContainer = styled(SpaceBetween)`
    width: calc(100% + 6px);
    align-items: center;
    margin-top: 18px;
`;

const LabelContainer = styled(SpaceBetween)`
    border-bottom: 4px solid #dedede;
    border-right: 4px solid #dedede;
`;

const MarkLabel = styled('span')`
    color: #6E6E6E;
    font-size: 15px;
`;

const Label = styled(Flex)`
    font-size: 18px;
    font-weight: bold;
    color: #575757;
    padding: 9px 16px;
    flex-basis: 20%;
    border-left: 4px solid #dedede;
`;

export default ObjectiveTitle;
