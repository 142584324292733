export const colorForSeries = [
    '#D04A02',
    '#EB8C00',
    '#FFB600',
    '#DB536A',
    '#E0301E',
    '#464646',
    '#9013FE',
    '#0089EB',
    '#4EB523',
    '#FD6412',
    '#FFA929',
    '#FFC83D',
    '#E27588',
    '#E86153',
    '#7D7D7D',
    '#B15AFE',
    '#4DACF1',
    '#86DB4F'
];
export const colorForQuartiles=[
    ['#fd7b35','#d04a02','#b13f02','#7e2d01'],
    ['#ffa31a','#eb8c00','#cc7a00','#995c00'],
    ['#ffc533','#ffb600','#cc9200','#996e00'],
    ['#e06c7f','#db536a','#d22d48','#a8243a'],
    ['#e75b4b','#e0301e','#b42818','#871e12'],
    ['#595959','#464646','#333333','#1a1a1a'],
    ['#a034fe','#9013fe','#7a01e4','#5f01b2'],
    ['#1a9fff','#0089eb','#0077cc','#005999'],
    ['#4ed629','#43b523','#37961d','#276b14'],
    ['#fd7b35','#fd6412','#e35102','#b13f02'],
    ['#ffb84d','#ffa929','#ff9900','#cc7a00'],
    ['#ffd466','#ffc83d','#ffbe1a','#e6a400']
];
