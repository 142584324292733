import React, { useEffect } from 'react';

const OneTrustConsent:React.FC = () => {

    const cookieConsent = localStorage.getItem('cookie-consent');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        script.type = 'text/javascript';
        script.charset = 'UTF-8';
        script.setAttribute('data-domain-script', 'b14353cb-0730-4c77-9f33-61f088ee914e');

        if(!cookieConsent){
            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        }
    }, []);

    return <></>;
  };

  export default OneTrustConsent;
