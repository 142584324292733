import React, {
    forwardRef,
    useEffect,
    useState,
    useImperativeHandle
} from 'react';

import Bargraph from '../../../components/Charts/BarGraph/BarGraph';
import { barGraph } from '../../../components/Charts/BarGraph/BarGraphHelpers';
import { Series, Legend, PointLabel } from '../../../components/Charts/BarGraph/types';
import {
    colorForSeries,
    colorForQuartiles
} from '../../../components/Charts/BarGraph/data';
import {
    CalculateClusteredScores,
    Dataset,
    DomainsData,
    CapByDomModel,
    Report
} from './types';
import {
    MaturityOverviewFrameworkDomains,
    MaturityOverviewFrameworkCapabilities,
    MaturityTableData
} from '../../Evaluate/MaturityOverview/MaturityOverview/types';
import {
    capabilitiesAverage,
    dataFormatter,
    StateDomainsType,
    StateCapabilities,
    domainsAverage
} from '../../Evaluate/MaturityOverview/MaturityOverviewTabs/MaturityOverviewTabsHelpers';
import {
    createReport,
    Spinner,
    generateCapabilitiesByDomainGraph
} from './BenchmarkingHelpers';
import { roundGraphScore } from '../../../utils';
import { SpinnerWithOverlay } from '../../../components';
import _ from 'lodash';

type Props = {
    data: CalculateClusteredScores[];
    title: string;
    assessmentData: MaturityTableData;
    ref: any;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    pointOneDecimalEnable?: boolean;
};
const BenchmarkingGraph: React.FC<Props> = forwardRef(
    ({ data, title, assessmentData, readOnlyEnabled = false, isReadOnly = false, pointOneDecimalEnable = false }, ref) => {
        const dataset: Dataset[] = [];
        const datasetLine: Dataset[] = [];
        let series: Series[] = [];
        let legend: Legend[] = [];
        const [xCapabilityData, setxCapabilityData] = useState<string[]>([]);
        const [xDomainData, setxDomainData] = useState<string[]>([]);
        const [objectivesLabelsList, setObjectivesLabelsList] = useState<
            string[]
        >([]);
        const [seriesData, setSeriesData] = useState<Series[]>([]);
        const [domainNameForLegend, setDomainNameForLegend] = useState<
            Legend[]
        >([]);
        const subtitle = [
            'All Capabilities Overview',
            'Benchmarking results for all capabilities within the framework, irrespective of domain.'
        ];

        // ----------------------------------------------Domain graph section
        const [
            domainOverviewSeriesData,
            setDomainOverviewSeriesData
        ] = useState<Series[]>([]);
        const [
            domainOverviewNameForLegend,
            setDomainOverviewNameForLegend
        ] = useState<Legend[]>([]);
        const domainDatasetLine: Dataset[] = [];
        const domainOverviewLegend: Legend[] = [];
        let domainOverviewSeries: Series[] = [];
        const domainsAverageScores = domainsAverage(
            assessmentData && assessmentData.assessmentsByCampaignWithGroups
        );
        let domainCoorX = 0;
        domainsAverageScores.forEach((dmn: DomainsData) => {
            domainDatasetLine.push({
                value: [domainCoorX,roundGraphScore(dmn.Score, pointOneDecimalEnable),dmn.Domain],
                itemStyle: {
                    color: '#000000'
                }
            });
            domainCoorX++;
        });
        const domainOverviewSubtitle = [
            'Domain overview',
            'Overall benchmarking results for each domain.'
        ];
        const quartileNames = [
            'Bottom quartile',
            'Second quartile',
            'Third quartile',
            'Top quartile'
        ];
        const colorForDomainGraphs = [
            '#D04A02',
            '#EB8C00',
            '#FFB600',
            '#DB536A',
            '#000000'
        ];
        // --------------------------------------------------End of Domain graph section
        const [listOfCapByDomGraphs, setListOfCapByDomGraphs] = useState<
            CapByDomModel[]
        >([]);
        const [isExpandAll, setExpandAll] = useState<boolean>(false);
        const [isLoading, setLoading] = useState<boolean>(false);
        useImperativeHandle(ref, () => ({
            export(isDrive: boolean) {
                setLoading(true);

                const reportData: Report[] = [];
                const cont = document.createElement('div');
                cont.style.width = '1000px';
                cont.style.height = '500px';
                const capabilityGraph = barGraph(
                    cont,
                    domainNameForLegend,
                    false,
                    objectivesLabelsList,
                    seriesData,
                    false,
                    false,
                    xCapabilityData
                );
                const capabilitiesImage = new Image();
                capabilitiesImage.src = capabilityGraph.getDataURL();
                reportData.push({
                    title: 'All capabilities',
                    image: capabilitiesImage.src
                });
                const domainGraph = barGraph(
                    cont,
                    domainOverviewNameForLegend,
                    false,
                    objectivesLabelsList,
                    domainOverviewSeriesData,
                    false,
                    false,
                    xDomainData,
                    undefined,
                    colorForDomainGraphs
                );
                const domainImage = new Image();
                domainImage.src = domainGraph.getDataURL();
                reportData.push({
                    title: 'Domain overview',
                    image: domainImage.src
                });
                listOfCapByDomGraphs.forEach((capData: CapByDomModel) => {
                    const imageGraph = barGraph(
                        cont,
                        capData.legendData,
                        false,
                        capData.objectivesLabelsList,
                        capData.seriesData,
                        false,
                        false,
                        capData.xAxisData,
                        undefined,
                        colorForDomainGraphs
                    );
                    const image = new Image();
                    image.src = imageGraph.getDataURL();
                    reportData.push({
                        title: capData.domainTitle,
                        image: image.src
                    });
                });
                const campaignName = title.split('-')[1];
                createReport(campaignName, reportData, setLoading, isDrive);
            }
        }));
        useEffect(() => {
            stackGraph();
            const allDomains =
                assessmentData &&
                assessmentData.assessmentsByCampaignWithGroups &&
                assessmentData.assessmentsByCampaignWithGroups[0].assessments &&
                assessmentData.assessmentsByCampaignWithGroups[0]
                    .assessments[0] &&
                assessmentData.assessmentsByCampaignWithGroups[0].assessments[0]
                    .framework &&
                assessmentData.assessmentsByCampaignWithGroups[0].assessments[0]
                    .framework.definition &&
                assessmentData.assessmentsByCampaignWithGroups[0].assessments[0]
                    .framework.definition.domains;
            if (allDomains) {
                const capabilityByDomainArray: CapByDomModel[] = [];
                allDomains.forEach((dt,index) => {
                    const capabilityByDomain = generateCapabilitiesByDomainGraph(
                        assessmentData,
                        index,
                        data,
                        quartileNames,
                        readOnlyEnabled,
                        isReadOnly,
                        pointOneDecimalEnable
                    );
                    capabilityByDomainArray.push(capabilityByDomain);
                });
                setListOfCapByDomGraphs(capabilityByDomainArray);
            }
        }, [assessmentData]);

        useEffect(() => {
            series = [];
            legend = [];
            const ass = assessmentData && assessmentData.assessmentsByCampaignWithGroups && assessmentData.assessmentsByCampaignWithGroups[0].assessments;

            if ((ass && ass.length > 0) && (listOfCapByDomGraphs && listOfCapByDomGraphs.length > 0)) {
                const capabilitiesAverageScores = capabilitiesAverage(
                    assessmentData &&
                        assessmentData.assessmentsByCampaignWithGroups, readOnlyEnabled, isReadOnly
                );

                const FormattedData = dataFormatter(capabilitiesAverageScores);
                let coorX = 0;

                FormattedData.forEach((dmn: StateDomainsType) => {
                    dmn.capabilities.forEach((cp: StateCapabilities) => {
                        datasetLine.push({
                            value: [coorX, roundGraphScore(cp.moderatedScore, pointOneDecimalEnable), cp.name],
                            itemStyle: {
                                color: '#000000'
                            }
                        });
                        xCapabilityData.push(cp.name);
                        coorX++;
                    });
                    setxCapabilityData(xCapabilityData);
                });

                coorX = 0;
                listOfCapByDomGraphs.forEach((dmSeries, dInd: number) => {
                    legend.push({
                        name: dmSeries.domainTitle,
                        textStyle: { color: colorForSeries[dInd] }
                    });
                    if (dmSeries.seriesData && dmSeries.seriesData.length) {
                        dmSeries.seriesData.forEach((sd, qInd: number) => {
                            if (sd && quartileNames.includes(sd.name) && sd.data && sd.data.length) {
                                const newSeries = _.assign(_.omit(_.cloneDeep(sd), ['barWidth']), { barGap: '-98%' });

                                newSeries.data = sd.data.map((data: any, index: number) => {
                                    const tempData = {
                                        value: [
                                            sd.name === 'Top quartile' ? coorX : data.value[0] + coorX,
                                            data.value[1],
                                            data.value[2]
                                        ],
                                        itemStyle: {
                                            color: colorForQuartiles[dInd][qInd],
                                        }
                                    };

                                    if (sd.name === 'Top quartile') {
                                        coorX++;
                                    }

                                    return tempData;
                                }, []);

                                newSeries.name = dmSeries.domainTitle;
                                series.push(newSeries);
                            }
                        });
                    }
                });

                const titletoArray = title.split('-');

                legend.push({
                    name: titletoArray[1],
                    textStyle: { color: '#000000' }
                });

                colorForSeries[legend.length - 1] = '#000000';
                series.push({
                    name: titletoArray[1],
                    type: 'line',
                    data: datasetLine,
                    lineStyle: {
                        color: '#000000'
                    },
                    barGap: '-98%',
                    symbolSize: 10,
                    zlevel: 0
                });

                setDomainNameForLegend(legend);
                setSeriesData(series);
            }
        }, [listOfCapByDomGraphs]);

        const stackGraph = () => {
            series = [];
            legend = [];
            domainOverviewSeries = [];
            const ass =
                assessmentData &&
                assessmentData.assessmentsByCampaignWithGroups &&
                assessmentData.assessmentsByCampaignWithGroups[0].assessments;
            if (ass && ass.length > 0) {
                setObjectivesLabelsList(
                    ass[0].framework &&
                        ass[0].framework.definition &&
                        ass[0].framework.definition.scores
                );
                // -------------------------------------------------Domain graph section
                quartileNames.forEach((quartileName: string, index: number) => {
                    domainCoorX = 0;
                    let domainOverviewDataset: Dataset[] = [];
                    const nullPoints: PointLabel[] = [];
                    domainOverviewLegend.push({
                        name: quartileName,
                        textStyle: { color: colorForQuartiles[index][1] }
                    });
                    ass[0].framework &&
                        ass[0].framework.definition &&
                        ass[0].framework.definition.domains &&
                        ass[0].framework.definition.domains.forEach(
                            (dmn: MaturityOverviewFrameworkDomains) => {

                                const clsDomain = data[0].clusteredDomains.find(
                                    el => el.name === dmn.shortName
                                );
                                if (clsDomain && clsDomain.quartiles === null) {
                                    nullPoints.push({
                                        name: 'NullValue',
                                        value: 'N/A',
                                        xAxis: dmn.name,
                                        yAxis: 0.15,
                                        label: { color: colorForSeries[index] }
                                    });
                                }
                                clsDomain &&
                                    domainOverviewDataset.push({
                                        value: [
                                            domainCoorX,
                                            clsDomain.quartiles && clsDomain.quartiles[index],
                                            domainCoorX
                                        ],
                                        itemStyle: {
                                            color: colorForQuartiles[index][1]
                                        }
                                    });
                                    domainCoorX++;
                                if (index === 0) {
                                    xDomainData.push(dmn.name);
                                }
                            }
                        );
                    domainOverviewSeries.push({
                        name: quartileName,
                        type: 'bar',
                        stack: 'stack',
                        data: domainOverviewDataset,
                        barWidth: 60,
                        barCategoryGap: '5%',
                        symbolSize: 10,
                        zlevel: 0,
                        markPoint: {
                            symbol: 'rect',
                            symbolSize: 20,
                            data: nullPoints,
                            itemStyle: {
                                color: 'white',
                                opacity: 0.8
                            }
                        }
                    });
                    domainOverviewDataset = [];
                });

                setxDomainData(xDomainData);
                const titletoArrayDomain = title.split('-');
                domainOverviewLegend.push({
                    name: titletoArrayDomain[1],
                    textStyle: { color: '#000000' }
                });
                setDomainOverviewNameForLegend(domainOverviewLegend);
                domainOverviewSeries.push({
                    name: titletoArrayDomain[1],
                    type: 'line',
                    data: domainDatasetLine,
                    lineStyle: {
                        color: '#000000'
                    },
                    barGap: '-98%',
                    symbolSize: 10,
                    zlevel: 0
                });
                setDomainOverviewSeriesData(domainOverviewSeries);
                // --------------------------------------------------End of Domain graph section
            }
        };

        const renderAllDomains = () => {
            const renderGraph: JSX.Element[] = [];
            listOfCapByDomGraphs &&
                listOfCapByDomGraphs.length > 0 &&
                listOfCapByDomGraphs.forEach(
                    (_newGraph: any, index: number) => {
                        let title, subtitle;
                        if (index === 0) {
                            subtitle = [
                                'Capabilities by domain',
                                'Benchmarking results for the capabilities within each domain.'
                            ];
                            renderGraph.push(
                                <Bargraph
                                    key={index}
                                    seriesData={
                                        listOfCapByDomGraphs[index].seriesData
                                    }
                                    title={title}
                                    subtitle={subtitle}
                                    objectivesLabelsList={
                                        listOfCapByDomGraphs[index]
                                            .objectivesLabelsList
                                    }
                                    legendData={
                                        listOfCapByDomGraphs[index].legendData
                                    }
                                    xAxisData={
                                        listOfCapByDomGraphs[index].xAxisData
                                    }
                                    isCapabilityByDomain
                                    domainTitle={
                                        listOfCapByDomGraphs[index].domainTitle
                                    }
                                    isExpandAll={isExpandAll}
                                    setExpandAll={setExpandAll}
                                    colors={colorForDomainGraphs}
                                />
                            );
                        } else {
                            renderGraph.push(
                                <Bargraph
                                    key={index}
                                    seriesData={
                                        listOfCapByDomGraphs[index].seriesData
                                    }
                                    title={title}
                                    subtitle={subtitle}
                                    objectivesLabelsList={
                                        listOfCapByDomGraphs[index]
                                            .objectivesLabelsList
                                    }
                                    legendData={
                                        listOfCapByDomGraphs[index].legendData
                                    }
                                    xAxisData={
                                        listOfCapByDomGraphs[index].xAxisData
                                    }
                                    isCapabilityByDomain
                                    domainTitle={
                                        listOfCapByDomGraphs[index].domainTitle
                                    }
                                    isExpandAll={isExpandAll}
                                    colors={colorForDomainGraphs}
                                />
                            );
                        }
                    }
                );
            return renderGraph;
        };
        return isLoading ? (
             <SpinnerWithOverlay/>
        ) : (
            <>
                <Bargraph
                    seriesData={seriesData}
                    title={title}
                    subtitle={subtitle}
                    objectivesLabelsList={objectivesLabelsList}
                    legendData={domainNameForLegend}
                    xAxisData={xCapabilityData}
                    isCapabilityByDomain={false}
                />
                <Bargraph
                    seriesData={domainOverviewSeriesData}
                    title={''}
                    subtitle={domainOverviewSubtitle}
                    objectivesLabelsList={objectivesLabelsList}
                    legendData={domainOverviewNameForLegend}
                    xAxisData={xDomainData}
                    isCapabilityByDomain={false}
                    colors={colorForDomainGraphs}
                />
                {listOfCapByDomGraphs.length > 0 && renderAllDomains()}
            </>
        );
    }
);
export default BenchmarkingGraph;
