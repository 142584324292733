import React from 'react';
import { AlignItems } from '../../../../../../components';
import PopoverMenu from '../../PopoverMenu';
import ComparisonEmptyState from '../../ComparisonEmptyState';
import {
    HeaderFilterHeading,
    CompareText,
    ColumnWithMargin
} from '../../../constants';

const ComparisonAssessmentsDifferentCampaigns: React.FC = () => {
    return (
        <div>
            <AlignItems style={{ width: '100%' }}>
                <ColumnWithMargin style={{ marginRight: '115px' }}>
                    <HeaderFilterHeading>Campaign</HeaderFilterHeading>
                    <PopoverMenu placeholder={'Select campaign'} />
                </ColumnWithMargin>
                <ColumnWithMargin>
                    <HeaderFilterHeading>1st assessment</HeaderFilterHeading>
                    <PopoverMenu
                        width={'198px'}
                        placeholder={'Select 1st assessment'}
                    />
                </ColumnWithMargin>
                <CompareText>compare</CompareText>
                <ColumnWithMargin>
                    <HeaderFilterHeading>2nd assessment</HeaderFilterHeading>
                    <PopoverMenu
                        width={'198px'}
                        placeholder={'Select 2nd assessment'}
                    />
                </ColumnWithMargin>
            </AlignItems>
            <ComparisonEmptyState />
        </div>
    );
};

export default ComparisonAssessmentsDifferentCampaigns;
