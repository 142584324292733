export const months = [
    { value: 1, label: 'January', shortForm: 'Jan' },
    { value: 2, label: 'February', shortForm: 'Feb' },
    { value: 3, label: 'March', shortForm: 'Mar' },
    { value: 4, label: 'April', shortForm: 'Apr' },
    { value: 5, label: 'May', shortForm: 'May' },
    { value: 6, label: 'June', shortForm: 'Jun' },
    { value: 7, label: 'July', shortForm: 'Jul' },
    { value: 8, label: 'August', shortForm: 'Aug' },
    { value: 9, label: 'September', shortForm: 'Sep' },
    { value: 10, label: 'October', shortForm: 'Oct' },
    { value: 11, label: 'November', shortForm: 'Nov' },
    { value: 12, label: 'December', shortForm: 'Dec' }
];
