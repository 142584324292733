import React from 'react';
import Text from '../base/Text/Text';

type Props = {
    // updatedBy: string;
    updatedOn: string;
};

const LastUpdated: React.FC<Props> = ({
    // updatedBy,
    updatedOn
}) => {
    return (
        //        <Tooltip title={"Last updated by " + updatedBy} placement="bottom">
        <Text>
            {updatedOn}
            {/*<Link to='#'>{updatedOn}</Link>*/}
        </Text>
        //        </Tooltip>
    );
};

export default LastUpdated;
