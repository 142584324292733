import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import Modal from 'react-modal';

import { UserStore } from '../../../../../context/user-store';
import {
    Column,
    Text,
    ArrowTooltip,
    SpaceBetween,
    Flex,
    DescopePopup,
    RedButton,
    TruncateText,
    Button
} from '../../../../../components';
import MaturityOverviewTooltip from '../../../MaturityOverview/MaturityOverviewTooltip/MaturityOverviewTooltip';
import iconNotepad from '../../../../../assets/images/icon-notepad.svg';
import {
    AssessmentData,
    Domain,
    DomainCapability,
    ModalContent
} from '../../types';
import AssessmentHeadCell from './AssessmentHeadCell';
import { customModalStyles } from '../../../../../components/PopUps/constants';
import { FormData } from '../../../MaturityScore/types';
import Router from '../../../../../routes/router';
import { statusResolver } from '../../../EvaluateHelpers';
import {
    squireColorResolver,
    roundScore,
    textColorResolver,
} from '../../../../../utils/UtilsHelpers';
import AssessmentWeightingModal from './AssessmentWeightingModal';

type Props = {
    data?: AssessmentData;
    campaignId: string;
    assessmentId: string;
    handleCapabilityRescope: any;
    isTargetScoring: boolean;
    targetScoreEnabled: boolean;
    showCompensationControl: boolean;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    pointOneDecimalEnable: boolean;
    isReasonExist: boolean;
    isObjectiveComponentExist: boolean;
    handleOnSaveWeighting: any;
    isAssessmentWeightingError: boolean;
    isAssessmentWeightingSuccess: boolean;
    setbalanceWeightSuccessMessage: any;
    isbalanceWeightSuccessMessage: boolean;
    setIsAssessmentWeightingModal: any;
    isAssessmentWeightingModal: boolean;
    compensatingControlEnabled: boolean;
};

type RedirectParams = {
    campaignId: string;
    assessmentId: string;
    domainId: string | number;
    capabilityId: string | number;
};

const AssessmentsMaturityOverviewFirstTabHorizontal: React.FC<Props> = ({
    data,
    campaignId,
    assessmentId,
    handleCapabilityRescope,
    isTargetScoring,
    targetScoreEnabled,
    showCompensationControl,
    readOnlyEnabled = false,
    isReadOnly = false,
    pointOneDecimalEnable,
    isReasonExist,
    isObjectiveComponentExist,
    handleOnSaveWeighting,
    isAssessmentWeightingError,
    isAssessmentWeightingSuccess,
    setbalanceWeightSuccessMessage,
    isbalanceWeightSuccessMessage,
    setIsAssessmentWeightingModal,
    isAssessmentWeightingModal,
    compensatingControlEnabled
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ModalContent>({
        domainName: '',
        capabilityName: '',
        domainId: null,
        capabilityId: null
    });
    const domains =
        (data &&
            data.frameworkByCampaign &&
            data.frameworkByCampaign.definition &&
            data.frameworkByCampaign.definition.domains) ||
        [];
    // TODO use ids from capabilities to redirect to score
    const capabilities =
        (data &&
            data.assessment &&
            data.assessment.state &&
            data.assessment.state.domains) ||
        [];
    const handleModalOpen = ({
        domainName,
        capabilityName,
        domainIndex,
        capabilityIndex
    }: {
        domainName: string;
        capabilityName: string;
        domainIndex: number;
        capabilityIndex: number;
    }) => () => {
        if (
            data &&
            data.assessment &&
            data.assessment.state &&
            data.assessment.state.domains.length &&
            data.assessment.state.domains[domainIndex] &&
            data.assessment.state.domains[domainIndex].capabilities.length &&
            data.assessment.state.domains[domainIndex].capabilities[
                capabilityIndex
            ]
        ) {
            setIsModalOpen(true);
            setModalContent({
                domainName,
                capabilityName,
                domainId: data.assessment.state.domains[domainIndex].id,
                capabilityId:
                    data.assessment.state.domains[domainIndex].capabilities[
                        capabilityIndex
                    ].id
            });
        }
    };

    const {
        state: { currentTheme }
    } = useContext(UserStore);

    const RedirectToScore = (params: RedirectParams) => {
        Router.goToMaturityScore(params);
    };

    const [markdownValue, setMarkdownValue] = useState<string>('');

    const handleProcedureContentChange = (content: any, delta: any, source: any, editor: any) => {
        setMarkdownValue(content);
    };

    return (
        <>
            {isObjectiveComponentExist && !(readOnlyEnabled && isReadOnly)&&<Button
                style={{minHeight:'58px', marginLeft:'auto', float: 'right' }}
                onClick={()=>setIsAssessmentWeightingModal(true)}
                variant="outlined">
                <Text>Adjust Weights</Text>
            </Button>}
            <AssessmentWeightingModal
                isOpen={isAssessmentWeightingModal}
                setOpen={setIsAssessmentWeightingModal}
                handleOnSave={handleOnSaveWeighting}
                isError={isAssessmentWeightingError}
                isSuccess={isAssessmentWeightingSuccess}
                domains={capabilities}
                setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                isbalanceWeightSuccessMessage={isbalanceWeightSuccessMessage}
            />
        <Column>
            {domains &&
                domains.map((domain: Domain, key: number) => {
                    if (!capabilities[Number(key)]) {
                        return (<div key={key} />);
                    }
                    return (
                        <DomainsTableWrapper key={key}>
                            <Flex
                                style={{
                                    borderBottom: '1px solid #d9d9d9',
                                    marginTop: 24
                                }}
                            >
                                <AssessmentHeadCell
                                    domain={capabilities[key]}
                                    campaignId={campaignId}
                                    assessmentId={assessmentId}
                                    domainIndex={key}
                                    domainList={capabilities}
                                    assessmentData={data}
                                />

                                <Flex style={{ flexWrap: 'wrap', flex: 1 }}>
                                    {domain &&
                                        domain.capabilities &&
                                        domain.capabilities.map(
                                            (
                                                capability: DomainCapability,
                                                i: number
                                            ) => {
                                                if (!capabilities[Number(key)].capabilities[Number(i)]) {
                                                    return (<div key={i} />);
                                                }
                                                const redirectData = {
                                                    campaignId,
                                                    assessmentId,
                                                    domainId: capabilities[Number(key)].id,
                                                    capabilityId: capabilities[Number(key)].capabilities[Number(i)].id
                                                };
                                                const name = capabilities[key].capabilities[i].name;
                                                const status = capabilities[key].capabilities[i].status;
                                                let score = null;
                                                if(capabilities[key].capabilities[i].moderatedScore === null)
                                                {
                                                    score = capabilities[key].capabilities[i].moderatedScore ||
                                                    capabilities[key].capabilities[i].averageScore;
                                                }
                                                else
                                                {
                                                    score = capabilities[key].capabilities[i].moderatedScore;
                                                }
                                                const targetScore = capabilities[key].capabilities[i].targetScore;
                                                const compensatingControlsCount = capabilities[key].capabilities[i].objectives.filter((o: any) => capabilities[key].capabilities[i].inScope && o.inScope && o.compensatingControlEnable === true ).length;
                                                const isVisibleByReadOnly=(!(readOnlyEnabled && isReadOnly) || (readOnlyEnabled && isReadOnly && capabilities[key].capabilities[i].status === 'COMPLETED'));
                                                return (
                                                    <CapabilityWrapper key={i}>
                                                        <ArrowTooltip
                                                            top={-4}
                                                            // interactive
                                                            title={
                                                                <MaturityOverviewTooltip
                                                                    capability={capabilities[Number(key)].capabilities[Number(i)]}
                                                                    redirectData={redirectData}
                                                                    targetScoreEnabled={targetScoreEnabled}
                                                                    readOnlyEnabled={readOnlyEnabled}
                                                                    isReadOnly={isReadOnly}
                                                                    pointOneDecimalEnable={pointOneDecimalEnable}
                                                                    isObjectiveComponentExist={isObjectiveComponentExist}
                                                                    compensatingControlsCount={
                                                                        !capabilities[key].capabilities[i].inScope ||
                                                                        (capabilities[key].capabilities[i].objectives.length === capabilities[key].capabilities[i].objectives.filter((o: any) => o.inScope === false).length) ?
                                                                        'N/A' : compensatingControlsCount
                                                                    }
                                                                    compensatingControlEnabled={compensatingControlEnabled}
                                                                />
                                                            }
                                                            placement="bottom"
                                                        >
                                                            {capabilities &&
                                                                capabilities[Number(key)] &&
                                                                capabilities[Number(key)].capabilities &&
                                                                capabilities[Number(key)].capabilities[i] &&
                                                                capabilities[Number(key)].capabilities[i].inScope ? (
                                                                capabilities[Number(key)].capabilities[Number(i)].moderatedScore !==null ||
                                                                capabilities[Number(key)].capabilities[Number(i)].averageScore !== null ? (
                                                                    <Block
                                                                        isTargetScoring={isTargetScoring}
                                                                        isVisibleByReadOnly={isVisibleByReadOnly}
                                                                        showCompensationControl={showCompensationControl}
                                                                        onClick={() => {
                                                                            (readOnlyEnabled &&
                                                                                isReadOnly &&
                                                                                capabilities[key].capabilities[i].status !== 'COMPLETED') ?
                                                                                    console.log('Suppressed!!') :
                                                                                    RedirectToScore(redirectData);
                                                                        }}
                                                                        style={{
                                                                            backgroundColor: '#F9F9F9',
                                                                            justifyContent: 'space-between',
                                                                            padding: 0,
                                                                            border: '0.5px solid #D7D7D7'
                                                                        }}
                                                                    >
                                                                        {isVisibleByReadOnly &&
                                                                        <ProgressText>
                                                                            {statusResolver(
                                                                                status
                                                                            )}
                                                                        </ProgressText>}
                                                                        <Column
                                                                            style={{
                                                                                flex: 1,
                                                                                justifyContent: 'space-between',
                                                                                width: '100%'
                                                                            }}
                                                                        >
                                                                            <Column style={{margin:'8px 16px', height: '35px'}}>
                                                                                <SpaceBetween>
                                                                                    <Text style={{display: 'block', width: '100%'}}>
                                                                                        <TruncateText
                                                                                            lines={2}
                                                                                        >
                                                                                            {name}
                                                                                        </TruncateText>
                                                                                    </Text>

                                                                                </SpaceBetween>
                                                                            </Column>
                                                                            {isVisibleByReadOnly &&
                                                                            <Column
                                                                                style={{
                                                                                    padding:'8px 16px',
                                                                                    backgroundColor: squireColorResolver(
                                                                                        score || score === 0 ? roundScore(score, pointOneDecimalEnable) : null,
                                                                                        status,
                                                                                        currentTheme,
                                                                                        isTargetScoring,
                                                                                        targetScore
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <SpaceBetween>
                                                                                    <Text
                                                                                        color={textColorResolver(
                                                                                            roundScore(score, pointOneDecimalEnable),
                                                                                            status,
                                                                                            currentTheme,
                                                                                            isTargetScoring
                                                                                        )}
                                                                                    >
                                                                                        Actual:
                                                                                    </Text>
                                                                                    <Text
                                                                                        color={textColorResolver(
                                                                                            roundScore(score, pointOneDecimalEnable),
                                                                                            status,
                                                                                            currentTheme,
                                                                                            isTargetScoring
                                                                                        )}
                                                                                        weight="bold"
                                                                                    >
                                                                                        {roundScore(score, pointOneDecimalEnable)}
                                                                                    </Text>
                                                                                </SpaceBetween>
                                                                            </Column>}
                                                                            { isTargetScoring && isVisibleByReadOnly && (
                                                                                <Column
                                                                                    style={{padding:'8px 16px'}}
                                                                                >
                                                                                    <SpaceBetween>
                                                                                        <Text>
                                                                                            Target:
                                                                                        </Text>
                                                                                        <Text weight="bold">
                                                                                            {typeof targetScore==='number' ? roundScore(targetScore, pointOneDecimalEnable) : '-'}
                                                                                        </Text>
                                                                                    </SpaceBetween>
                                                                                </Column>
                                                                            )}
                                                                            { showCompensationControl && (
                                                                                <Column
                                                                                    style={{padding:'8px 16px'}}
                                                                                >
                                                                                    <SpaceBetween>
                                                                                        <Text style={{textAlign: 'left'}}>
                                                                                           Compensating Controls:
                                                                                        </Text>
                                                                                        <Text weight="bold" style={{textAlign: 'right'}}>
                                                                                         <br/>
                                                                                            {
                                                                                                !capabilities[key].capabilities[i].inScope ||
                                                                                                (capabilities[key].capabilities[i].objectives.length === capabilities[key].capabilities[i].objectives.filter((o: any) => o.inScope === false).length) ?
                                                                                                '-' : compensatingControlsCount
                                                                                            }
                                                                                        </Text>
                                                                                    </SpaceBetween>
                                                                                </Column>
                                                                            )}
                                                                         </Column>
                                                                    </Block>
                                                                ) : capabilities[key].capabilities[i].workingNotes ||
                                                                    capabilities[key].capabilities[i].objectives.find( el => el.notes && el.notes.length) ? (

                                                                        <Block style={{
                                                                            justifyContent: 'space-between',
                                                                            padding: 1,
                                                                            border: '0.5px solid #D7D7D7',
                                                                            backgroundColor: '#f9f9f9'
                                                                        }}
                                                                            onClick={() => {
                                                                                (readOnlyEnabled &&
                                                                                    isReadOnly &&
                                                                                    capabilities[key].capabilities[i].status !== 'COMPLETED') ?
                                                                                    console.log('Suppressed!!') :
                                                                                    RedirectToScore(redirectData);
                                                                            }}
                                                                        > <Column
                                                                        style={{
                                                                            flex: 1,
                                                                            justifyContent: 'space-between'

                                                                        }}
                                                                    >
                                                                             <Column style={{margin:'8px 16px', height: '35px'}}>
                                                                                <SpaceBetween>
                                                                                    <Text>
                                                                                        <TruncateText
                                                                                            lines={2}
                                                                                        >
                                                                                            {name}
                                                                                        </TruncateText>
                                                                                    </Text>

                                                                                </SpaceBetween>
                                                                            </Column>
                                                                            <Column style={{
                                                                                    padding:'8px 8px',
                                                                                    backgroundColor: '#ffffff'}}>
                                                                                  <img

                                                                                    src={iconNotepad}
                                                                                    alt="notes icon"
                                                                                    style={{ maxHeight: '25px',
                                                                                    width: '2.2vw', height: '2.2vw'}}

                                                                                 />
                                                                            </Column>
                                                                            </Column>


                                                                        </Block>
                                                                    ) : (
                                                                        <Block
                                                                            style={{
                                                                                background: squireColorResolver(
                                                                                    roundScore(score, pointOneDecimalEnable),
                                                                                    status,
                                                                                    currentTheme
                                                                                ),
                                                                                justifyContent: 'space-between',
                                                                            padding: 1,
                                                                            border: '0.5px solid #D7D7D7',
                                                                            backgroundColor: '#f9f9f9'
                                                                            }}
                                                                            onClick={() => {
                                                                                (readOnlyEnabled &&
                                                                                    isReadOnly &&
                                                                                    capabilities[key].capabilities[i].status !== 'COMPLETED') ?
                                                                                    console.log('Suppressed!!') :
                                                                                    RedirectToScore(redirectData);
                                                                            }}
                                                                        >
                                                                            <Column
                                                                        style={{
                                                                            flex: 1,
                                                                            justifyContent: 'space-between',
                                                                            display: 'block',width:'100%'

                                                                        }}
                                                                    >
                                                                             <Column style={{margin:'8px 16px', height: '35px'}}>
                                                                                <SpaceBetween
                                                                                style={{display: 'block',width:'100%'}}
                                                                                >

                                                                            <Text
                                                                                color={textColorResolver(
                                                                                    roundScore(score, pointOneDecimalEnable),
                                                                                    status,
                                                                                    currentTheme,
                                                                                    isTargetScoring
                                                                                )}
                                                                            >
                                                                                <TruncateText
                                                                                    lines={2}
                                                                                    style={{textAlign:'center'}}>
                                                                                    {name}
                                                                                </TruncateText>
                                                                            </Text>
                                                                                    </SpaceBetween>
                                                                                    </Column>
                                                                                    </Column>

                                                                        </Block>
                                                                    )
                                                                ) : (
                                                                    <Block
                                                                        style={{
                                                                            background: 'repeating-linear-gradient(-45deg, transparent, transparent 8px, grey 11px, grey 7px)'
                                                                        }}
                                                                        onClick={
                                                                            (readOnlyEnabled && isReadOnly)?undefined :
                                                                            handleModalOpen(
                                                                                {
                                                                                    domainIndex: key,
                                                                                    capabilityIndex: i,
                                                                                    domainName: domain.name,
                                                                                    capabilityName: capability.name
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        <Text color="000" style={{display: 'block',width:'100%'}}>
                                                                            {capabilities[key].capabilities[i] && name}
                                                                        </Text>
                                                                    </Block>
                                                                )}
                                                        </ArrowTooltip>
                                                    </CapabilityWrapper>
                                                );
                                            }
                                        )}
                                </Flex>
                            </Flex>
                        </DomainsTableWrapper>
                    );
                })}
            <Modal
                isOpen={isModalOpen}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
                style={customModalStyles}
                onRequestClose={() => setIsModalOpen(false)}
            >
                <>
                    <DescopePopup
                        handleClose={() => setIsModalOpen(false)}
                        title="Rescope Capability?"
                        mainText={`Are you sure you want to rescope the "${modalContent.domainName +
                            ' - ' +
                            modalContent.capabilityName}" capability?`}
                        descriptionText={'Please provide a reason.'}
                        initialValues={{
                            reason: '',
                            capabilityId: modalContent.capabilityId
                        }}
                        render={(formData: FormData) => {
                            return (
                                <>
                                    <ReactQuillWrap
                                        value={markdownValue}
                                        onChange={handleProcedureContentChange}
                                    />
                                    <ButtonContainer>
                                        {isReasonExist &&
                                            <Text color="red">
                                                Reason is required
                                            </Text>
                                        }
                                        <SaveButton
                                            key="SaveButton"
                                            onClick={() => {
                                                if (
                                                    modalContent.domainId !==
                                                        null &&
                                                    modalContent.capabilityId !==
                                                        null
                                                ) {
                                                    handleCapabilityRescope({
                                                        setIsModalOpen,
                                                        assessmentId,
                                                        domainId:
                                                            modalContent.domainId,
                                                        capabilityId:
                                                            modalContent.capabilityId,
                                                        updateScopeReason:
                                                            markdownValue
                                                    });
                                                }
                                            }}
                                        >Rescope
                                        </SaveButton>
                                    </ButtonContainer>
                                </>
                            );
                        }}
                    />
                </>
            </Modal>
        </Column>
            </>
    );
};

const Block = styled('button')<{ isTargetScoring?: boolean; isVisibleByReadOnly?: boolean; showCompensationControl?: boolean }>`
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    height: ${props => ((props.isTargetScoring && props.isVisibleByReadOnly) || (props.showCompensationControl) ? 'auto' : '98px')};
    padding: 16px;
    width: 140px;
    @media (max-width: 1366px) {
        width: 120px;
    }
    display: flex;
    flex-direction: column;
    border: none;
`;

const ProgressText = styled(Text)`
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    display: flex;
    width: 100%;
    justify-content: center;
`;

const DomainsTableWrapper = styled('div')`
    margin-right: 10%;
    @media (max-width: 1366px) {
        margin-right: 0;
    }
`;

const CapabilityWrapper = styled(Flex)`
    margin: 0 16px 16px;
    @media (max-width: 1366px) {
        margin: 0 7px 7px;
    }
`;

const SaveButton = styled(RedButton)`
    && {
        padding: 0 56px;
    }
`;

const ReactQuillWrap = styled((props: ReactQuillProps) =>
    <ReactQuill
        theme="snow"
        {...props}
    />
)`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    && {
        font-family: inherit;
        padding-top: 0px;
        height:250px;
        margin-top:10px;
        margin-bottom:10px;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        padding: 16px;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1em;
        color: #2d2d2d;
        overflow-y: hidden;
        min-height: 160px;
    }

    .ql-toolbar {
        flex-wrap: wrap;
        box-shadow: 0px -1px 9px 0px #cdcdcd;
        margin: 0;
        border: 0;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
    }
`;

const ButtonContainer = styled(Flex)`
    flex-direction: column;

    span {
        margin-bottom: 5px;
    }
`;

export default AssessmentsMaturityOverviewFirstTabHorizontal;
