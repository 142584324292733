import { colorsList, roundGraphScore } from '../../../../../utils';
import { capabilitiesAverage, dataFormatter} from '../MaturityOverviewTabsHelpers';
import { CampaignGroupAssessment } from '../../MaturityOverview/types';
import { Series } from '../../../../../components/Charts/BarGraph/types';

type Props = {
    stateDomains: StateDomainsType[];
    title: string;
    objectivesLabelsList: string[];
    pointOneDecimalEnable: boolean;
};
type StateDomainsType = {
    capabilities: StateCapabilities[];
    name: string;
};
type StateCapabilities = {
    moderatedScore: number | null;
    name: string;
    inScope: boolean;
    targetScore?: number;
    averageScore: number | null;
};
type graph = {
    domain: string;
    score: number | null;
    Capabilities: string;
    inScope: boolean;
};
type chart = {
    setOption: any;
    getDataURL: any;
};
type legend = {
    name: string;
    textStyle: textStyle;
};
type textStyle = {
    color: string;
};

type labelFormatter = {
    data: string | number[];
};
export const MaturityGraphData = (CapabilityByDomain: StateDomainsType[], pointOneDecimalEnable: boolean): { seriesData: Series[]; legendData: legend[]; xData: string[] } => {

    const legendData=prepareGraphLegend(CapabilityByDomain);
        const seriesData: Series[] = [];
        const xData: string[] = [];
        let coorX = 0;
   const nullPoints = CapabilityByDomain.map((sd: StateDomainsType,i: number) => {
       return sd.capabilities
            .filter((src: StateCapabilities) => src.inScope === false)
            .map((flt: StateCapabilities) => {
                const isExist = legendData.find(
                    x => x.name === sd.name
                );
                if (isExist) {
                    return {
                        name: 'NullValue',
                        value: 'N/A',
                        xAxis: flt.name,
                        yAxis: 0.15,
                        label: { color: 'red' }
                    };
                }
                return [];
            });
    }).flat();
            CapabilityByDomain.forEach((sd: StateDomainsType) => {
                const dataset: Array<string | number | null>[] = [];
                sd.capabilities.forEach((x: StateCapabilities) =>{
                    dataset.push([coorX, roundGraphScore(x.moderatedScore!==null?x.moderatedScore:x.averageScore, pointOneDecimalEnable), x.name]);
                    coorX++;
                    xData.push(x.name);
                });
                seriesData.push({
                    name: sd.name,
                    type: 'bar',
                    data: dataset,
                    label: {
                        show: true,
                        position: 'top',
                        // align:'middle',
                        // verticalAlign: 'top',
                        formatter: (value: labelFormatter) => {
                            return value.data[1] === 0 ? '        ' : value.data[1];
                        }
                    },
                    encode: {
                        x: 'Capabilities',
                        y: 'score'
                    },
                    symbolSize: 10,
                    barGap: '-100%',
                    barCategoryGap: '5%',
                    zlevel: 5,
                    markPoint: {
                        symbol: 'rect',
                        symbolSize: 20,
                        data: nullPoints,
                        itemStyle: {
                            color: 'white',
                            opacity: 0.8
                        }
                    },
                    markLine: {}
                });
            });
        return {
            seriesData,
            legendData,
            xData
        };

    };

export const targetScoreData=(CapabilityByDomain: StateDomainsType[])=>{
    let coorX=0;
    const targetScoreData: Array<string | number | null>[] = [];
    CapabilityByDomain.forEach((sd: StateDomainsType) => {
        sd.capabilities.forEach((x: StateCapabilities) => {
            /*TODO: add exact value from assessment*/
            targetScoreData.push([coorX, x.targetScore||null, x.name]);
            coorX++;
        });
    });
    return {
        name: 'target',
        type: 'bar',
        data: targetScoreData,
        label: {
            show: true,
            position: 'top',
            // align:'middle',
            // verticalAlign: 'top',
            formatter: (value: labelFormatter) => {
                return value.data[1] === 0 ? '        ' : value.data[1];
            }
        },
        encode: {
            x: 'Capabilities',
            y: 'score'
        },
        symbolSize: 10,
        barGap: '-100%',
        barCategoryGap: '5%',
        zlevel: 4,
        markPoint: {},
        markLine: {}
    };
};

const prepareGraphLegend=(CapabilityByDomain: StateDomainsType[])=>{
    const legendData: legend[] = [];
    CapabilityByDomain.forEach((x: StateDomainsType, i: number) => {
        legendData.push({
            name: x.name,
            textStyle: { color: colorsList[i] }
        });
    });
    return legendData;
};
