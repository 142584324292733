import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from '@mui/material';
import { LinearProgress, MenuItem } from '@mui/material';
import Modal from 'react-modal';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import {
    Flex,
    Column,
    JustifyContentEnd,
    Text,
    RedButton,
    CustomField,
    Button,
    PopUpHeader
} from '../../../components';
import closeIcon from '../../../assets/images/icon-close.svg';

import { DropDownStakeholder } from './types';
import { CampaignEntityGroup, TreeData } from '../types';
import { customModalStyles } from '../../../components/PopUps/constants';
import { v4 as uuidv4 } from 'uuid';

const CREATE_ENTITY = gql`
    mutation createEntity(
        $entityGroupId: ID!
        $entities: [CreateEntityInput!]!
    ) {
        createEntity(entityGroupId: $entityGroupId, entities: $entities) {
            id
            name
            archivedDate
            createdAt
            updatedAt
        }
    }
`;

const UPDATE_ENTITY = gql`
    mutation updateEntity($data: UpdateEntityInput!, $id: ID!) {
        updateEntity(data: $data, id: $id) {
            id
            name
        }
    }
`;

type Props = {
    entityData: any;
    selectedEntityGroups: CampaignEntityGroup[];
    setSelectedEntityGroups: any;
    selectedRow: TreeData | null;
    entityOpen: boolean;
    setEntityOpen: any;
    setSelectedEntityGroup: any;
    setSelectedEntity: any;
    dropdownValues?: any;
    setEntityGroupUpdated: any;
};

type SelectValues = {
    value: number;
    label: string;
};

const EntitySchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required('Required'),
    entityTypeId: Yup.number().required('Required'),
    ownerId: Yup.string().required('Required'),
    stakeholderIds: Yup.array()
        .min(1, 'Select company team')
        .required('Required')
});

const CompanyEntityForm: React.FC<Props> = ({
    entityData,
    selectedEntityGroups,
    setSelectedEntityGroups,
    selectedRow,
    entityOpen,
    setEntityOpen,
    dropdownValues,
    setSelectedEntity,
    setSelectedEntityGroup,
    setEntityGroupUpdated
}) => {
    const initialValues = {
        id: selectedRow ? selectedRow.editKey : uuidv4(),
        name: selectedRow ? selectedRow.name : '',
        entityTypeId: selectedRow ? selectedRow.type && selectedRow.type.id : 0,
        ownerId: selectedRow ? selectedRow.owner && selectedRow.owner.id : '',
        stakeholderIds: selectedRow
            ? selectedRow.stakeholders &&
              selectedRow.stakeholders.map((stakeholder: any) => stakeholder.id)
            : []
        // parentName: entityData ? entityData.name : ''
    };
    const [createEntity] = useMutation(CREATE_ENTITY);
    const [updateEntity] = useMutation(UPDATE_ENTITY);

    const handleClose = () => {
        setSelectedEntity(false);
        setSelectedEntityGroup(false);
        setEntityOpen(false);
        setEntityGroupUpdated(true);
    };
    
    return (
        <Modal
            isOpen={entityOpen}
            contentLabel={selectedRow ? 'Edit entity modal' : 'Add entity modal'}
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={handleClose}
        >
            <PopUpHeader handleClose={handleClose} title={selectedRow ? 'Edit entity' : 'Add entity'} />
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={EntitySchema}
                onSubmit={values => {
                    if (selectedRow) {
                        updateEntity({
                            variables: {
                                data: values,
                                id: selectedRow.id
                            }
                        })
                            .then(() => handleClose())
                            .catch(error => {
                                console.error(error);
                                alert('An error occurred, please see the console for details');
                            });
                        return;
                    }
                    createEntity({
                        variables: {
                            entityGroupId: entityData.id,
                            entities: [values]
                        }
                    })
                        .then(() => handleClose())
                        .catch(error => {
                            console.error(error);
                            alert('An error occurred, please see the console for details');
                        });
                }}
            >
                {({
                        submitForm,
                        isSubmitting,
                        values,
                        setFieldValue
                    }) => {
                    return (
                        <Form style={{paddingTop: '50px'}}>
                            <Flex>
                                <ColumnWrapper>
                                    <Label>Entity title</Label>
                                    <Field
                                        variant="outlined"
                                        name="name"
                                        type="name"
                                        as={TextField}
                                        style={{ marginBottom: 20 }}
                                        inputProps={{
                                            'aria-label':'enter entity title'
                                        }}
                                    />
                                    <Label>Owner</Label>
                                    <Field
                                        variant="outlined"
                                        name="ownerId"
                                        type="text"
                                        select
                                        style={{ marginBottom: 20 }}
                                        as={TextField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        aria-label='select owner'
                                    >
                                        <MenuItem value={0} disabled>
                                            Select owner
                                        </MenuItem>
                                        {dropdownValues &&
                                            dropdownValues.stakeholdersByClient.map(
                                                (
                                                    option: DropDownStakeholder
                                                ) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {`${option.firstName} ${option.lastName}`}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Field>
                                    <Flex>
                                        {dropdownValues &&
                                            dropdownValues.stakeholdersByClient.map(
                                                (option: DropDownStakeholder) =>
                                                    option.value ===
                                                        values.ownerId && (
                                                        <CustomChip
                                                            key={option.value}
                                                        >
                                                            <Flex
                                                                style={{
                                                                    flexWrap:
                                                                        'wrap',
                                                                    boxSizing:
                                                                        'border-box',
                                                                    paddingTop: 10,
                                                                    paddingLeft: 10,
                                                                    paddingBottom: 10,
                                                                    justifyContent:
                                                                        'center'
                                                                }}
                                                            >
                                                                {`${option.firstName} ${option.lastName}`}
                                                            </Flex>
                                                            <img
                                                                src={closeIcon}
                                                                alt=""
                                                                style={{
                                                                    cursor:
                                                                        'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        'ownerId',
                                                                        ''
                                                                    );
                                                                }}
                                                            />
                                                        </CustomChip>
                                                    )
                                            )}
                                    </Flex>
                                    <Label>Stakeholders</Label>
                                        <Field
                                            variant="outlined"
                                            name="stakeholderIds"
                                            type="text"
                                            multiple
                                            style={{
                                                marginBottom: 20,
                                                minHeight: 39
                                            }}
                                            as={Select}
                                            displayEmpty
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            renderValue={(selected: any) => {
                                                if (
                                                    selected &&
                                                    (selected as string[])
                                                        .length === 0
                                                ) {
                                                    return 'Select stakeholders';
                                                }
                                                const selectedStakeholders = dropdownValues
                                                    ? dropdownValues.stakeholdersByClient.filter(
                                                          (
                                                              stakeholder: DropDownStakeholder
                                                          ) =>
                                                              selected.includes(
                                                                  stakeholder.value
                                                              )
                                                      )
                                                    : [];
                                                return selectedStakeholders.map(
                                                    (
                                                        stakeholder: DropDownStakeholder
                                                    ) =>
                                                        `${stakeholder.firstName} ${stakeholder.lastName}`
                                                );
                                            }}
                                        >
                                            <MenuItem value={0} disabled>
                                                Select stakeholders
                                            </MenuItem>
                                            {dropdownValues &&
                                                dropdownValues.stakeholdersByClient.map(
                                                    (
                                                        option: DropDownStakeholder
                                                    ) => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {`${option.firstName} ${option.lastName}`}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Field>
                                        <Flex style={{ flexWrap: 'wrap' }}>
                                        {values.stakeholderIds &&
                                            values.stakeholderIds.map(
                                                (chip: string) => {
                                                    const chipToDisplay = dropdownValues
                                                        ? dropdownValues.stakeholdersByClient.filter(
                                                              (
                                                                  el: DropDownStakeholder
                                                              ) =>
                                                                  el.value ===
                                                                  chip
                                                          )
                                                        : [];
                                                    return chipToDisplay.map(
                                                        (
                                                            el: DropDownStakeholder
                                                        ) => (
                                                            <CustomChip
                                                                key={el.value}
                                                            >
                                                                <Flex
                                                                    style={{
                                                                        flexWrap:
                                                                            'wrap',
                                                                        boxSizing:
                                                                            'border-box',
                                                                        paddingTop: 10,
                                                                        paddingLeft: 10,
                                                                        paddingBottom: 10,
                                                                        justifyContent:
                                                                            'center'
                                                                    }}
                                                                >
                                                                    {`${el.firstName} ${el.lastName}`}
                                                                </Flex>
                                                                <img
                                                                    src={
                                                                        closeIcon
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                        cursor:
                                                                            'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        const newTeam = values.stakeholderIds.filter(
                                                                            (
                                                                                e: string
                                                                            ) =>
                                                                                e !==
                                                                                el.value
                                                                        );
                                                                        setFieldValue(
                                                                            'stakeholderIds',
                                                                            newTeam
                                                                        );
                                                                    }}
                                                                />
                                                            </CustomChip>
                                                        )
                                                    );
                                                }
                                            )}
                                    </Flex>
                                </ColumnWrapper>
                                <ColumnWrapper>
                                    <Label>Type</Label>
                                    <Field
                                        variant="outlined"
                                        name="entityTypeId"
                                        type="text"
                                        select
                                        fullWidth
                                        style={{ marginBottom: 20 }}
                                        as={TextField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    >
                                        <MenuItem value={0} disabled>
                                            Select type
                                        </MenuItem>
                                        {dropdownValues &&
                                            dropdownValues.entityTypes.map(
                                                (option: SelectValues) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Field>
                                </ColumnWrapper>
                            </Flex>
                            <SubmitWrapper>
                                {isSubmitting && <LinearProgress />}
                                <Button
                                    disabled={isSubmitting}
                                    style={{ padding: '12px 48px' }}
                                    onClick={() => {
                                        if (selectedRow) {
                                            selectedRow.editKey
                                                ? setSelectedEntityGroups(
                                                      selectedEntityGroups.map(
                                                          (group: any) => ({
                                                              ...group,
                                                              campaignEntities: group.campaignEntities.filter(
                                                                  (
                                                                      entity: any
                                                                  ) =>
                                                                      entity.name !==
                                                                      selectedRow.name
                                                              )
                                                          })
                                                      )
                                                  )
                                                : handleClose();
                                        }
                                        handleClose();
                                    }}
                                >
                                    Delete
                                </Button>
                                <RedButton
                                    style={{
                                        marginLeft: 24,
                                        padding: '12px 60px'
                                    }}
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Save
                                </RedButton>
                            </SubmitWrapper>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

const ColumnWrapper = styled(Column)`
    width: 100%;
    padding: 0 50px;
`;

const SubmitWrapper = styled(JustifyContentEnd)`
    margin: 40px 24px 24px 0;
`;

const CustomChip = styled('span')`
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: lightgrey;
    border: 1 solid black;
    border-radius: 50px;

    && img {
        width: 16px;
        padding-right: 10px;
        padding-left: 10px;
    }
`;

const Label = styled(Text)`
    color: #575757;
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
`;

export default CompanyEntityForm;
