import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';

import {
    AlignItems,
    RedButton,
    Button,
    Column,
    SpaceBetween
} from '../../../../components';

import addIcon from '../../../../assets/images/icon-add.svg';
import archiveIcon from '../../../../assets/images/Atmos_Icons_Archive.svg';

import {
    HeaderProps,
    Meeting
} from '../../MaturityScore/MeetingNotes/types';


const MeetingNotesHeader: React.FC<HeaderProps> = ({
    selectedValue,
    setSelectedValue,
    setMeetingsTableData,
    notFilteredData,
    selectedRow,
    setEditMeetingModalIsOpen,
    setIsDialogBoxOpen,
    canEdit = true,
    setDoArchiveData,
    doArchiveData,
    setSelectedRow
}) => {
    const handleFilterMeetings = (event: any) => {
        const value = event.target.value;

        setSelectedValue(value);
        let result: Meeting[] = [];
        setSelectedRow([]);
        switch (Number(value)) {
            case 1:
                result = notFilteredData.filter(
                    (item: Meeting) => item.archivedDate === null
                );
                setDoArchiveData(true);
                setMeetingsTableData(result);
                break;
            case 2:
                result = notFilteredData.filter(
                    (item: Meeting) => item.archivedDate !== null
                );
                setDoArchiveData(false);
                setMeetingsTableData(result);
                break;
            default:
                setMeetingsTableData(notFilteredData);
                break;
        }
    };

    return (
        <HeaderWrapper>
            <SpaceBetween>
                <AlignItems>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={selectedValue}
                        onChange={(e)=>handleFilterMeetings(e)}
                        inputProps={{
                            'aria-label':'select meeting status'
                        }}
                    >
                        <option value={1}>{`Active meetings (${
                            notFilteredData.filter(
                                (item: Meeting) => item.archivedDate === null
                            ).length
                        })`}</option>
                        <option value={2}>{`Archived meetings (${
                            notFilteredData.filter(
                                (item: Meeting) => item.archivedDate !== null
                            ).length
                        })`}</option>
                        <option
                            value={3}
                        >{`All meetings (${notFilteredData.length})`}</option>
                    </CustomSelect>

                    <AddButton
                        disabled={!canEdit}
                        onClick={() => {
                            setEditMeetingModalIsOpen(true);
                        }}
                    >
                        <Icon src={addIcon} alt="add meeting" />
                        Add meeting
                    </AddButton>

                    {selectedRow.length > 0 && (
                        <ArchiveButton
                            disabled={!canEdit}
                            onClick={() => setIsDialogBoxOpen(true)}
                        >
                            <Icon src={archiveIcon} alt={doArchiveData ? 'archive meeting' : 'unarchive meeting'} />
                            {doArchiveData ? 'Archive' : 'Unarchive'}
                        </ArchiveButton>
                    )}
                </AlignItems>
            </SpaceBetween>
        </HeaderWrapper>
    );
};


const HeaderWrapper = styled(Column)`
    width: 100%;
`;

const CustomSelect = styled(Select)`
    .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 30px;
    }
`;

const AddButton = styled(RedButton)`
    && {
        background-color: #de3328;
        border-radius: 2px;
        margin-left: 20px;
        padding: 6px 16px;
        min-height: 40px;

        &:hover {
            background-color: #d84241;
        }
    }
`;

const ArchiveButton = styled(Button)`
    && {
        border-radius: 2px;
        margin-left: 20px;
        padding: 6px 16px;
        min-height: 40px;
    }
`;

const Icon = styled('img')`
    && {
        margin-right: 10px;
        height: 24px;
    }
`;

export default MeetingNotesHeader;
