import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import closeIcon from '../../../assets/images/Atmos_Icons_Close.svg';
import { ThreatObject } from '../../../modules/Analyse/ThreatAndRisk/types';
import { IconButton, ThemeProvider, createTheme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {
    values: ThreatObject[];
    handleDelete: any;
};

const Chips: React.FC<Props> = ({ values, handleDelete }) => {
    const theme = createTheme({
        components: {
            MuiChip: {
                styleOverrides: {
                    root: {
                        margin: '9px 9px 0 0',
                        height: '44px',
                        borderRadius: '100px',
                        fontSize: '1rem'
                    },
                    
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <ChipsContainer>
                {values.map((data: ThreatObject) => {
                    return (
                        <ChipItem key={data.title}>
                            <Chip
                                deleteIcon={
                                    <IconButton
                                        style={{
                                            margin: '0 1rem 0 0'
                                        }}
                                    >
                                        <img
                                            src={closeIcon}
                                            alt="close"
                                            style={{
                                                height: '1rem',
                                                width: '1rem'
                                            }}
                                        />
                                    </IconButton>
                                }
                                label={
                                    data.title.length > 25
                                        ? data.title.slice(0, 25) + '...'
                                        : data.title
                                }
                                onDelete={handleDelete(data)}
                            />
                        </ChipItem>
                    );
                })}
            </ChipsContainer>
        </ThemeProvider>
    );
};

const ChipsContainer = styled('ul')`
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ChipItem = styled('li')`
    display: inline-block;
    list-style: none;
    padding: 0;
`;

export default Chips;
