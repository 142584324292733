import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import ImgContainer from '../../../../components/base/ImgContainer/ImgContainer';
import Text from '../../../../components/base/Text/Text';

import dropdownArrow from '../../../../assets/images/icon-drop-down-dark.svg';
import { List, ListItem, Popover, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import { Campaign, CampaignDetail, campaignByFramework } from '../types';

type CampaignMenuProps = {
    data: Campaign[];
    isOpen: boolean;
    setOpen: CallableFunction;
    placeholder: string;
    buttonDisabled?: boolean;
    setSelectedFramework: any;
    setCampaignById: any;
    lock?: boolean;
    campaignId1?: string;
    campaignId2?: string;
};
const CampaignPopover: React.FC<CampaignMenuProps> = ({
    data,
    isOpen,
    setOpen,
    placeholder,
    buttonDisabled,
    setSelectedFramework,
    setCampaignById,
    lock,
    campaignId1,
    campaignId2,
}) => {
    const [expanded, setExpanded] = useState<number[]>([0]);
    const handleListItemClick = (campaign: CampaignDetail, name: number) => {
        setSelectedFramework(name);
        setCampaignById(campaign.id);
        setOpen(false);
    };
    const preparingList = data.reduce(
        (
            acc: { [key in string]: CampaignDetail[] },
            { framework, name, id }
        ) => {
            if (acc[framework.definition.name]) {
                acc[framework.definition.name].push({
                    name,
                    id,
                    frameworkVersionId: framework.frameworkVersionId,
                });
            } else {
                acc[framework.definition.name] = [
                    {
                        name,
                        id,
                        frameworkVersionId: framework.frameworkVersionId,
                    },
                ];
            }
            return acc;
        },
        {}
    );
    const campaignList = Object.entries(preparingList).map(([key, name]) => {
        return {
            framework: key,
            campaigns: name,
        };
    });
    const sorting = (x: string, y: string) => {
        const a = x.toUpperCase();
        const b = y.toUpperCase();
        return a === b ? 0 : a > b ? 1 : -1;
    };
    const anchorId = isOpen ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (!isOpen) {
            handleClose();
        }
    }, [isOpen]);

    const handleExpandClick = (index: number) => {
        if (expanded.includes(index)) {
            const collapsed = [...expanded];
            collapsed.splice(collapsed.indexOf(index), 1);
            setExpanded(collapsed);
        } else {
            setExpanded([...expanded, index]);
        }
    };
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <MenuButtonContainer
                background={buttonDisabled ? 'none' : '#ececec'}
                disabled={buttonDisabled}
                aria-describedby={anchorId}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(e);
                    setOpen(true);
                }}
            >
                <TextContainer
                    id="skip-to-content"
                    tabIndex={-1}
                    color={buttonDisabled ? '#b6b6b6' : '#000'}
                >
                    {placeholder}
                </TextContainer>
                <ImgContainer
                    src={dropdownArrow}
                    alt="dropdown arrow"
                    aria-hidden
                />
            </MenuButtonContainer>

            <BenchPopover
                id={anchorId}
                open={isOpen}
                keepMounted={isOpen}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <CustomList>
                    {campaignList.sort((frs: campaignByFramework,scnd: campaignByFramework ) => sorting(frs.framework, scnd.framework) )
                        .map((item: campaignByFramework, index: number) => (
                            <li key={index} tabIndex={0}>
                                <ListSection>
                                    {item.framework}
                                    <CustomIconButton
                                        onClick={() => handleExpandClick(index)}
                                        aria-expanded={expanded.includes(index)}
                                        aria-label="show more">
                                        <ExpandMoreIcon />
                                    </CustomIconButton>
                                </ListSection>
                                <FormControlUppercaseLabelWrapper
                                    // tslint:disable-next-line: no-misleading-array-reverse
                                    children={
                                        item.campaigns.sort( (frs: CampaignDetail, scnd: CampaignDetail ) => sorting(frs.name, scnd.name) )
                                        .map((campaign: CampaignDetail,i: number ) => lock ? ( campaign.id === campaignId1 ? (
                                                        <ListText
                                                            disabled
                                                            key={i}>
                                                            {campaign.name}
                                                        </ListText>
                                                    ) : (
                                                        <ListText
                                                            key={i}
                                                            tabIndex={0}
                                                            onKeyDown={(event: any)=>{
                                                                if(event.key === 'Enter'){
                                                                    handleListItemClick(
                                                                        campaign,
                                                                        campaign.frameworkVersionId
                                                                    );
                                                                    }
                                                            }}
                                                            onClick={(event: any) =>{
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                handleListItemClick(
                                                                    campaign,
                                                                    campaign.frameworkVersionId
                                                                );}
                                                            }>
                                                            {campaign.name}
                                                            {campaignId2 === campaign.id ? ( <CheckSharpIcon /> ) : null}
                                                        </ListText>
                                                    )
                                                ) : (
                                                    <CustomListItem
                                                        key={i}
                                                        tabIndex={0}
                                                        onKeyDown={(event: any)=>{
                                                            if(event.key === 'Enter'){
                                                            handleListItemClick(
                                                                campaign,
                                                                campaign.frameworkVersionId
                                                            );
                                                            }
                                                        }}
                                                        onClick={(event: any) =>{
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleListItemClick(
                                                                campaign,
                                                                campaign.frameworkVersionId
                                                            );}
                                                        }>
                                                        {campaign.name}
                                                        {campaignId1 === campaign.id ? ( <CheckSharpIcon /> ) : null}
                                                    </CustomListItem>
                                                )
                                        )}
                                    in={expanded.includes(index)}
                             />
                            </li>
                        ))}
                </CustomList>
            </BenchPopover>
        </>
    );
};

const CustomList = styled(List)(({ theme }) => ({
    width: 462,
    margin: 0,
    padding: 0,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 350,
    display: 'flex',
    flexDirection: 'column',
    focus: {
        outline: 0,
    },
}));

const CustomListItem = styled(ListItem)(() => ({
    backgroundColor: 'inherit',
    paddingLeft: '16px',
    width: 240,
    justifyContent: 'space-between',
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    color: '#fff',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition:  theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    padding: 0,

    '&[aria-expanded="true"]': {
        transform: 'rotate(180deg)'
    }
}));

const ListSection = styled(ListItem)(() => ({
    backgroundColor: '#575757',
    color: '#fff',
    padding: 5,
    paddingLeft: 16,
    margin: 0,
    minHeight: '38px',
    borderBottom: '1px solid grey',
}));

const ListText = styled(ListItem)(() => ({
    width: 240,
    fontSize: '16px',
    color: '#575757',
}));

const BenchPopover = styled(Popover)`
    .makeStyles-root-110 {
        max-height: 450px;
    }
`;

const MenuButtonContainer = styled('button')<{ background?: string }>`
    display: flex;
    align-items: center;
    background: transparent;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    width: 462px;
    height: 38px;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        background: ${(props) =>
            props.background ? props.background : 'none'};
    }
    overflow: hidden;
`;
const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${(props) => (props.color ? props.color : '#575757')};
    font-size: 16px;
    max-height: 38px;
`;
const FormControlUppercaseLabelWrapper = styled(Collapse)`
    .MuiTypography-body1 {
        display: flex;
        text-transform: uppercase;
        justify-content: space-between;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: normal;
        color: #2d2d2d;
    }
`;

export default CampaignPopover;
