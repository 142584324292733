import React from 'react';
import * as echarts from 'echarts';
import { Score, PieChartSection } from './types';
import { domainLabel, getNestedChildren } from './PieChartHelpers';
import { Chart } from '../BarGraph/types';

type Props = {
    scoresList: Score[];
    maxScoreRange: number;
    minScoreRange: number;
    sectorSize: number;
    colorsList: string[];
    bottomDomain: number;
    saveAsImageText: string;
    cont: HTMLDivElement;
    showSaveImage: boolean;
    showTargetScore?: boolean;
    showCapabilityStatus?: boolean;
    pointOneDecimalEnable?: boolean;
};

const PieChart = ({
    scoresList,
    maxScoreRange,
    minScoreRange,
    sectorSize,
    bottomDomain,
    colorsList,
    saveAsImageText,
    cont,
    showSaveImage,
    showTargetScore,
    showCapabilityStatus,
    pointOneDecimalEnable
}: Props) => {
    const wholePie: PieChartSection[] = []; // make a local variables as buffers for a calculation values
    const overPie: PieChartSection[] = []; // make a local variables as buffers for a calculation values
    const borderPie: PieChartSection[] = []; // make a local variables as buffers for a calculation values

    let wholeNumberCount = 1; // Track the whole number pie.
    let halfNumberCount = 1; // Track the half number pie.
    let borderCount = 1; // Track the border pie.
    let showScores = true;
    const isShow = (index: number,pieScore: number) => {
       if(pointOneDecimalEnable) {
           return (Math.floor(pieScore) * 10 <= index && index <= pieScore * 10 - 1) ? 1 : 0;
       }
       return index === (pieScore * 2) - 1 ? 1 : 0;
    };
    scoresList.forEach((score: Score, sectionIndex: number) => {
        const pieScore = score.score || minScoreRange;
        const targetScore = score.targetScore || minScoreRange;
        for (
            let itemScore = minScoreRange;
            itemScore < Math.floor(pieScore);
            itemScore++
        ) {
            const showLabel = itemScore >= maxScoreRange - 1;
            const padding = bottomDomain === sectionIndex ? [110, 0, 0, -70] : 30;
            const position = 'outside';
            const customDomainLabel = {
                ...domainLabel,
                show: showLabel,
                position,
                padding
            };
            const parent =
                itemScore === minScoreRange ? 0 : wholeNumberCount - 1;
            const newArray = {
                value: sectorSize,
                itemStyle: { color: colorsList[sectionIndex] },
                parent,
                childId: wholeNumberCount,
                name: score.name,
                score: score.score,
                targetScore: score.targetScore,
                label: customDomainLabel,
                capabilities: score && score.capabilities && score.capabilities.length > 0 ? score.capabilities : []
            };
            wholePie.push(newArray);
            wholeNumberCount++;
        }
        for (let i = pieScore; i < maxScoreRange; i++) {
            const parent = i === minScoreRange ? 0 : wholeNumberCount - 1;
            const showLabel = i >= maxScoreRange - 1;
            const padding = bottomDomain === sectionIndex ? [110, 0, 0, -70] : 30;
            const position =  'outside';
            const customDomainLabel = {
                ...domainLabel,
                show: showLabel,
                position,
                padding
            };
            const newArray = {
                value: sectorSize,
                itemStyle: { color: '#E7E5E6' },
                parent,
                childId: wholeNumberCount,
                name: score.name,
                score: score.score,
                targetScore: score.targetScore,
                label: customDomainLabel,
                capabilities: score && score.capabilities && score.capabilities.length > 0 ? score.capabilities : []
            };
            wholePie.push(newArray);
            wholeNumberCount++;
        }

        // Coloured (scored) half sectors.
        const maxRange = pointOneDecimalEnable ? (maxScoreRange * 10) : (maxScoreRange * 2);
        for (let i = minScoreRange; i < maxRange; i++) {
            const parent = i === minScoreRange ? 0 : halfNumberCount - 1;
            const show = isShow(i,pieScore);
            const showT = (i <= targetScore * (pointOneDecimalEnable ? 10 : 2) - 1 && i > pieScore * (pointOneDecimalEnable ? 10 : 2) - 1) ? 1 : 0;
            const newArray = {
                value: sectorSize,
                itemStyle: {
                    color: colorsList[sectionIndex],
                    opacity: show
                },
                parent,
                childId: halfNumberCount,
                name: score.name,
                score: score.score,
                targetScore: score.targetScore,
                label: {
                    show: false
                },
                capabilities: score && score.capabilities && score.capabilities.length > 0 ? score.capabilities : []
            };
            const targetScoreSlice = {
                value: sectorSize,
                itemStyle: {
                    color: 'grey',
                    opacity: showT
                },
                parent,
                childId: halfNumberCount,
                name: score.name,
                score: score.score,
                targetScore: score.targetScore,
                label: {
                    show: false
                },
                capabilities: score && score.capabilities && score.capabilities.length > 0 ? score.capabilities : []
            };
            if(showT===1&&showTargetScore){
                overPie.push(targetScoreSlice);
            }
            else {
                overPie.push(newArray);
            }
            halfNumberCount++;
        }

        for (let i = minScoreRange; i < maxScoreRange; i++) {
            const parent = i === minScoreRange ? 0 : borderCount - 1;
            const newArray = {
                value: sectorSize,
                itemStyle: { color: '#00000000' },
                parent,
                childId: borderCount,
                name: `${i + 1}`,
                label: {
                    color: '#ffffff',
                    rotate: 0,
                    show: showScores,
                    fontSize: 14,
                    fontWeight: 'bold'
                }
            };
            borderPie.push(newArray);
            borderCount++;
        }
        showScores = false;
    });
    const wholeData = getNestedChildren(wholePie, 0);
    const halfData = getNestedChildren(overPie, 0);
    const borderData = getNestedChildren(borderPie, 0);
    const option = {

        graphic: showCapabilityStatus ? [
            {
                type: 'text',
                left: '300',
                top: '220',
                zlevel: 4,
                style: {
                    fill: '#000',
                    text: 'DRAFT',
                    font: ' 180px sans-serif',
                    opacity: 0.2
                }
            }
          ]: null,
        tooltip: {
            trigger: 'item',
            show: true,
            zlevel: 100,
            formatter: (params: any) => {
                const caps = params.data.capabilities ? params.data.capabilities : [];
                let html = `<u>Average Score: ${params.data.score}`;
                if (showTargetScore && params.data.targetScore) {
                    html += ` | Target Score: ${params.data.targetAverageScore ? params.data.targetAverageScore : params.data.targetScore}`;
                }
                html += '</u><br>';
                caps.forEach((c: any) => {
                    html += `${c.name}: ${c.inScope?c.score : 'N/A'}`;
                    if (showTargetScore && c.targetScore) {
                        html += ` | Target Score: ${c.targetScore}`;
                    }
                    html += '<br>';
                });
                return params.data.capabilities && params.data.capabilities.length > 0 ? html : '';
            }
        },
        toolbox: {
            orient: 'horizontal',
            right: '5%',
            feature: {
                saveAsImage: {
                    show: showSaveImage,
                    title: 'as Image',
                    name: saveAsImageText
                }
            }
        },
        // silent: true,
        animation: false,
        series: [
            {
                radius: ['15%', '85%'],
                type: 'sunburst',
                sort: null,
                data: wholeData,
                levels: [],
                nodeClick: false,
                itemStyle: {
                    color: '#ddd',
                    borderWidth: 2
                },
                zlevel: 2,
                emphasis: {
                    focus: 'none'
                }
            },
            {
                radius: ['15%', '85%'],
                type: 'sunburst',
                sort: null,
                data: borderData,
                levels: [],
                nodeClick: false,
                itemStyle: {
                    color: '#ddd',
                    borderWidth: 2
                },
                zlevel: 0,
                emphasis: {
                    focus: 'none'
                }
            },
            {
                radius: ['15%', '85%'],
                type: 'sunburst',
                sort: null,
                data: halfData,
                levels: [],
                nodeClick: false,
                itemStyle: {
                    color: '#ddd',
                    borderWidth: 0.5,
                    borderColor:'grey'
                },
                zlevel: 5,
                emphasis: {
                    focus: 'none'
                }
            }
        ]
    };

    const chart = echarts.init(cont);
    chart.setOption(option);
    return chart;
};

export default PieChart;
