import React, { useCallback, useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
    CustomTable,
    DialogBox,
    JustifyContentCenter,
    SpinnerWithOverlay
} from '../../../../components';

import EditMeetingModal from '../../Common/EditMeetingModal/EditMeetingModal';

import { UserStore } from '../../../../context/user-store';

import {
    AssessmentMeetings,
    CapabilityDropdown,
    Domain,
    Meeting,
    MeetingTableDataProps,
    Stakeholder,
    // User,
    AssessmentsCapabilities,
    MeetingUpdateProps,
    MeetingCreateProps
} from '../../MaturityScore/MeetingNotes/types';

import {
    fillAssessmentMeetings,
    headCells
} from '../../MaturityScore/MeetingNotes/MeetingNotesHelpers';
import { isReadOnlyEnabled } from '../../../../utils';
import { MeetingNotesHeader } from '../../Common';

const ASSESSMENT_MEETINGS = gql`
    query assessmentMeetings($assessmentId: ID!, $clientId: Int!) {
        assessmentMeetings(assessmentId: $assessmentId) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
        stakeholdersByClient(clientId: $clientId) {
            id
            firstName
            lastName
            status
        }
        usersByClient(clientId: $clientId) {
            id
            firstName
            lastName
        }
    }
`;

const ASSESSMENT_BY_ID = gql`
    query assessment3($id: ID!) {
        assessment(id: $id) {
            state {
                domains {
                    id
                    name
                    capabilities {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const ARCHIVE_MEETING = gql`
    mutation archiveAssessmentMeeting($assessmentId: ID!, $meetingIds: [ID!]!) {
        archiveAssessmentMeeting(
            assessmentId: $assessmentId
            meetingIds: $meetingIds
        ) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

const UNARCHIVE_MEETING = gql`
    mutation unarchiveAssessmentMeeting($assessmentId: ID!, $meetingIds: [ID!]!) {
        unarchiveAssessmentMeeting(
            assessmentId: $assessmentId
            meetingIds: $meetingIds
        ) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

const UPDATE_ASSESSMENT_MEETINGS = gql`
    mutation updateAssessmentMeeting($data: UpdateAssessmentMeetingInput!) {
        updateAssessmentMeeting(data: $data) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

const CREATE_ASSESSMENT_MEETINGS = gql`
    mutation createAssessmentMeeting($data: CreateAssessmentMeetingInput!) {
        createAssessmentMeeting(data: $data) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

type MeetingNotesTypes = {
    assessmentId: string;
    campaignIsClosed: boolean;
    isReadOnly?: boolean;
};

const MeetingNotes: React.FC<MeetingNotesTypes> = ({
    assessmentId,
    campaignIsClosed,
    isReadOnly
}) => {
    const [
        capabilitiesDropdownValues,
        setCapabilitiesDropdownValues
    ] = useState<CapabilityDropdown[]>([]);
    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [chosenField, setChosenField] = useState<Meeting | null>(null);
    const [meetingsTableData, setMeetingsTableData] = useState<
        MeetingTableDataProps[]
    >([]);
    const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
    const [meetings, setMeetings] = useState<Meeting[]>([]);
    const [doArchive, setDoArchive] = useState<boolean>(true);

    const [notFilteredData, setNotFilteredData] = useState<Meeting[]>([]);
    const [editMeetingModalIsOpen, setEditMeetingModalIsOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>('1');
    const [disableAllSelect, setDisableAllSelect] = useState<boolean>(false);
    const {
        state: { client, user, globalSettings }
    } = useContext(UserStore);


    let campaign_closed_message = 'This campaign is closed - view only access.';
    /**
     * We switch the flag on based on if the user has the right role, if closed we keep that way... if on the other hand not, then we flag closed but with the
     * message switched.
     */

     const readOnlyEnabled = isReadOnlyEnabled(globalSettings);


    if (readOnlyEnabled && !campaignIsClosed && isReadOnly) {
        //BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
        campaign_closed_message = 'You have read-only access to this campaign.';
    }

    const {
        loading,
        data = {
            assessmentMeetings: [],
            stakeholdersByClient: [],
            usersByClient: []
        },
        error
    } = useQuery<AssessmentMeetings>(ASSESSMENT_MEETINGS, {
        variables: {
            assessmentId,
            clientId: client && client.id
        }
    });
    const { data: capabilitiesData } = useQuery<AssessmentsCapabilities>(
        ASSESSMENT_BY_ID,
        {
            variables: {
                id: assessmentId
            }
        }
    );
    const [archiveAssessmentMeetingMutation] = useMutation(ARCHIVE_MEETING, {
            refetchQueries: [
                {
                    query: ASSESSMENT_MEETINGS,
                    variables: {
                        assessmentId,
                        clientId: client && client.id
                    }
                }
            ]

    });

    const [unarchiveAssessmentMeetingMutation] = useMutation(UNARCHIVE_MEETING, {
        // tslint:disable-next-line: no-shadowed-variable
        refetchQueries: [
            {
                query: ASSESSMENT_MEETINGS,
                variables: {
                    assessmentId,
                    clientId: client && client.id
                }
            }
        ]
    });

    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState<boolean>(false);
    const [updateAssessmentMeeting] = useMutation(UPDATE_ASSESSMENT_MEETINGS);
    const [createAssessmentMeeting] = useMutation(CREATE_ASSESSMENT_MEETINGS);

    const handleArchiveUnarchiveMeeting = () => {
        if (doArchive) {
            archiveAssessmentMeetingMutation({
                    variables: {
                        assessmentId,
                        meetingIds: selectedRow
                    }
                })
                .then(() => {
                    setSelectedRow([]);
                    setIsDialogBoxOpen(false);
                    setEditMeetingModalIsOpen(false);
                })
                .catch(err => {
                    console.log('Archive assessment Error', err);
                });
        } else {
            unarchiveAssessmentMeetingMutation({
                variables: {
                    assessmentId,
                    meetingIds: selectedRow
                }
            })
            // tslint:disable-next-line: no-identical-functions
            .then(() => {
                setSelectedRow([]);
                setIsDialogBoxOpen(false);
                setEditMeetingModalIsOpen(false);
            })
            .catch(err => {
                console.log('Archive assessment Error', err);
            });
        }
    };

    const handleUpdateAssessmentMeeting = useCallback(
        (meetingUpdateProps: MeetingUpdateProps) => {
            const {
                assessmentId,
                capabilities,
                id,
                meetingDate,
                notes,
                stakeholderIds,
                title,
                userIds
            } = meetingUpdateProps;
            updateAssessmentMeeting({
                variables: {
                    data: {
                        assessmentId,
                        capabilities,
                        id,
                        meetingDate,
                        notes,
                        stakeholderIds,
                        title,
                        userIds
                    }
                }
            })
                .then(() => {
                    setChosenField(null);
                    setEditMeetingModalIsOpen(false);
                })
                .catch(e => console.error(e));
        },
        [updateAssessmentMeeting]
    );

    const handleCreateAssessmentMeeting = useCallback(
        (meetingCreateProps: MeetingCreateProps) => {
            const {
                assessmentId,
                capabilities,
                meetingDate,
                notes,
                stakeholderIds,
                title,
                userIds
            } = meetingCreateProps;
            createAssessmentMeeting({
                variables: {
                    data: {
                        assessmentId,
                        capabilities,
                        meetingDate,
                        notes,
                        stakeholderIds,
                        title,
                        userIds
                    }
                },
                refetchQueries: [
                    {
                        query: ASSESSMENT_MEETINGS,
                        variables: {
                            assessmentId,
                            clientId: client && client.id
                        }
                    }
                ]
            })
                .then(dataThen => {
                    setChosenField(null);
                    setEditMeetingModalIsOpen(false);
                    return dataThen;
                })
                .catch(e => console.error(e));
        },
        [assessmentId, client, createAssessmentMeeting]
    );

    useEffect(() => {
        const values: CapabilityDropdown[] = [];
        capabilitiesData &&
            capabilitiesData.assessment &&
            capabilitiesData.assessment.state &&
            capabilitiesData.assessment.state.domains &&
            capabilitiesData.assessment.state.domains.map((domain: Domain) => {
                values.push({
                    id: 'title',
                    name: domain.name,
                    domain: ''
                });
                domain.capabilities.forEach((capability: CapabilityDropdown) =>
                    values.push({
                        id: capability.id,
                        name: capability.name,
                        domain: domain.name
                    })
                );
            });
        setCapabilitiesDropdownValues(values);
    }, [capabilitiesData, isDialogBoxOpen]);

    useEffect(() => {
        if (loading || error) {
            return undefined;
        }
        if(data.stakeholdersByClient.length){
            const stakeholderData = data.stakeholdersByClient.filter((stakeholder: any) => stakeholder.status === 'ACTIVE');
            setStakeholders(stakeholderData);
        }
    }, [data.stakeholdersByClient]);

    useEffect(() => {
        if (loading || error) {
            return undefined;
        }
        if (data.assessmentMeetings.length) {
            setNotFilteredData(data.assessmentMeetings);
            switch (Number(selectedValue)) {
                case 1:
                    setDoArchive(true);
                    setMeetings(data.assessmentMeetings.filter(
                        (item: Meeting) => item.archivedDate === null
                    ));
                    break;
                case 2:
                    setDoArchive(false);
                    setMeetings(data.assessmentMeetings.filter(
                        (item: Meeting) => item.archivedDate !== null
                    ));
                    break;
                default:
                    setMeetings(data.assessmentMeetings);
                    break;
            }
        }
    }, [data, loading]);

    useEffect(() => {
        if (loading || error) {
            return undefined;
        }
        if (selectedValue) {
            switch (Number(selectedValue)) {
                case 1:
                    setDisableAllSelect(false);
                    break;
                case 2:
                    setDisableAllSelect(false);
                    break;
                default:
                    setDisableAllSelect(true);
                    break;
            }
        }
    }, [selectedValue]);

    const [isShowAllCapabilities, setIsShowAllCapabilities] = useState<string[]>([]);

    useEffect(() => {
        if (selectedRow.length === 0) {
            setMeetings(meetings.map((meeting) => {
                if(meeting.disable){
                    meeting.disable = false;
                }
                return meeting;
            }));
        }
        if(meetings.length && selectedRow.length === 1 && selectedValue === '3') {
            const selectedMeeting = meetings.find((m) => m.id === selectedRow[selectedRow.length - 1]);
            // tslint:disable-next-line: no-redundant-boolean
            setDoArchive(selectedMeeting?.archivedDate ? false : true);
            setMeetings(meetings.map((meeting) => {
                // tslint:disable-next-line: no-redundant-boolean
                meeting.disable = selectedMeeting?.archivedDate ? meeting.archivedDate ? false : true : meeting.archivedDate ? true : false;
                return meeting;
            }));
        }
    }, [selectedRow]);

    useEffect(() => {
        if (meetings) {
            const meetingsList: MeetingTableDataProps[] = fillAssessmentMeetings(
                meetings,
                stakeholders,
                setEditMeetingModalIsOpen,
                setChosenField,
                setDoArchive,
                true,
                setSelectedRow,
                setIsShowAllCapabilities,
                isShowAllCapabilities,
                !campaignIsClosed
            );
            setMeetingsTableData(meetingsList);
        }
    }, [
        meetings,
        isDialogBoxOpen,
        editMeetingModalIsOpen,
        stakeholders,
        isShowAllCapabilities,
        campaignIsClosed
    ]);

    const selectedHandler = (selected: string[]) => {
        setSelectedRow(selected);
    };

    const getTitle = () => {
        if (selectedRow && selectedRow.length > 1) {
            return `${selectedRow.length} meetings`;
        }
        if (notFilteredData && selectedRow && selectedRow.length > 0) {
            const result = notFilteredData.find(
                (item: Meeting) => item.id === selectedRow[0]
            );
            return result ? result.title : '';
        }
        return '';
    };

    return loading ? (
        <SpinnerWithOverlay />
    ) : (
        <div>
            <CustomTable
                selectedHandler={selectedHandler}
                header={
                    <MeetingNotesHeader
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        setMeetingsTableData={setMeetings}
                        notFilteredData={notFilteredData}
                        selectedRow={selectedRow}
                        setEditMeetingModalIsOpen={setEditMeetingModalIsOpen}
                        setIsDialogBoxOpen={setIsDialogBoxOpen}
                        campaignIsClosed={campaignIsClosed}
                        setDoArchiveData={setDoArchive}
                        doArchiveData={doArchive}
                        setSelectedRow={setSelectedRow}
                    />
                }
                rows={meetingsTableData}
                headCells={headCells}
                selectedRows={selectedRow}
                selectAllDisabled={disableAllSelect}
            />

            <EditMeetingModal
                assessmentId={assessmentId}
                editMeetingModalIsOpen={editMeetingModalIsOpen}
                setEditMeetingModalIsOpen={setEditMeetingModalIsOpen}
                setChosenField={setChosenField}
                meetingData={chosenField}
                stakeholders={stakeholders}
                domainsList={capabilitiesDropdownValues}
                doArchiveData={doArchive}
                users={data && data.usersByClient}
                user={user && `${user.firstName} ${user.lastName}`}
                handleCreateAssessmentMeeting={handleCreateAssessmentMeeting}
                handleUpdateAssessmentMeeting={handleUpdateAssessmentMeeting}
                handleArchiveUnarchiveMeeting={handleArchiveUnarchiveMeeting}
            />

            <DialogBox
                title={`Are you sure you want to ${doArchive ? 'archive' : 'unarchive'} “${getTitle()}” meeting?`}
                buttonCancelText="Cancel"
                buttonSaveText={doArchive ? 'Archive' : 'Unarchive'}
                content={`${doArchive ?
                    'Archived meetings can be accessed using the “Archived meetings” filter. These will not be included in any campaign reporting.' :
                    'Unarchive meetings can be accessed using the “Active meetings” filter.'}`}
                open={isDialogBoxOpen}
                handleClose={() => setIsDialogBoxOpen(false)}
                handleSubmit={handleArchiveUnarchiveMeeting}
            />
        </div>
    );
};

export default MeetingNotes;
