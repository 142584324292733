import React, { useState, useEffect, useContext } from 'react';
import { CustomTable } from '../../../../components';

import MaturityOverviewAssessmentHeader from '../MaturityOverviewAssessmentHeader';
import { isReadOnlyEnabled, maturityHeaderRows } from '../../../../utils';
import { MaturityAssessmentsList } from '../types';
import { CampaignData, AssessmentTableType } from '../MaturityOverview/types';
import { User } from '../../../../context/reducer';
import {
    useAssessmentsList,
    useAssessmentsStatus
} from './MaturityAssessmentHelpers';
import { UserStore } from '../../../../context/user-store';

const MaturityAssessment: React.FC<{
    maturityAssessmentData: MaturityAssessmentsList[];
    campaign: CampaignData;
    user?: User;
    assessmentsByCampaignWithGroupPageOffset: number;
}> = ({ maturityAssessmentData, campaign, user, assessmentsByCampaignWithGroupPageOffset }) => {

    const {
        state: {  globalSettings }
    } = useContext(UserStore);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const[search,setSearch] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [filterProcessStatus, setFilterProcessStatus] = useState('ALL');
    const [filterActiveStatus, setFilterActiveStatus] = useState('ACTIVE');
    const [assessmentsFilteredList, setAssessmentsFilteredList] = useState<
        AssessmentTableType[]
    >([]);
    const [notFilteredData, setNotFilteredData] = useState<
        AssessmentTableType[]
    >([]);
    const [assessmentsList] = useAssessmentsList({
        data: maturityAssessmentData || [],
        filterProcessStatus,
        filterActiveStatus
    });
    const [checkedAssessmentsList] = useAssessmentsStatus({
        data: maturityAssessmentData || [],
        selectedRow
    });
    useEffect(() => {
        setAssessmentsFilteredList(assessmentsList);
        setNotFilteredData(assessmentsList);
    }, [assessmentsList]);
    const campaignIsClosed = ((campaign.closedAt !== null) || (readOnlyEnabled && campaign.readOnly));

    const selectedHandler = (selected: any) => {
        setSelectedRow(selected);
    };
    return (
        <>
        <CustomTable
            search={search}
            selectedHandler={selectedHandler}
            header={
                <MaturityOverviewAssessmentHeader
                    setSelectedRow={setSelectedRow}
                    checkedAssessmentsList={checkedAssessmentsList}
                    setAssessmentsFilteredList={setAssessmentsFilteredList}
                    notFilteredData={notFilteredData}
                    selectedRow={selectedRow}
                    filterProcessStatus={filterProcessStatus}
                    filterActiveStatus={filterActiveStatus}
                    setFilterProcessStatus={setFilterProcessStatus}
                    setFilterActiveStatus={setFilterActiveStatus}
                    assessmentsFilteredList={assessmentsFilteredList}
                    campaign={campaign}
                    title={
                        maturityAssessmentData && maturityAssessmentData.length
                            ? maturityAssessmentData[0].campaign.title
                            : ''
                    }
                    campaignIsClosed={campaignIsClosed}
                    assessmentsByCampaignWithGroupPageOffset={assessmentsByCampaignWithGroupPageOffset}
                    setSearch={setSearch}
                />
            }
            selectedRows={selectedRow}
            rows={assessmentsFilteredList}
            headCells={maturityHeaderRows}/>
        </>
    );
};

export default MaturityAssessment;
