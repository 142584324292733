import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { useQuery } from '@apollo/react-hooks';
import campaignIcon from '../../../assets/images/campaign-icon.svg';
import assessmentIcon from '../../../assets/images/assessments-icon.svg';
import Text from '../../../components/base/Text';
import { UserStore } from '../../../context/user-store';
import Router from '../../../routes/router';
import { HeaderButtonsTypes, AvailableDataByClient } from '../types';
import { SUBHEADER_DATA } from '../LayoutHelpers';
import { isReadOnlyEnabled } from '../../../utils';

const SubHeader: React.FC<{
    route: string;
}> = ({ route }) => {
    const {
        state: { client, globalSettings }
    } = useContext(UserStore);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    const [selected, setSelected] = useState(0);
    const {
        data = { availableCampaignsByClient: [], assessmentsByClient: [] }
    } = useQuery<AvailableDataByClient>(SUBHEADER_DATA, {
        variables: { clientId: (client && client.id) || 0 },
        fetchPolicy:'no-cache',
        onCompleted: res => {
            Router.goToEvaluateCampaign(res.availableCampaignsByClient);
        }
    });

    useEffect(() => {
        if (route === '/main/evaluate/campaigns') {
            setSelected(0);
        } else {
            setSelected(1);
        }
    }, [route]);

    const headerButtons = [
        {
            callback: () => {
                Router.goToEvaluateCampaign(data.availableCampaignsByClient);
            },
            imgSrc: campaignIcon,
            imgAlt: 'Campaign icon',
            title: `Campaigns (${data.availableCampaignsByClient.filter(cpm=>
                !(!readOnlyEnabled&&cpm.readOnly)
            ).length})`
        },
        {
            callback: () => {
                Router.goToEvaluateAssessments(data.assessmentsByClient);
            },
            imgSrc: assessmentIcon,
            imgAlt: 'Assessment icon',
            title: `Assessments (${data.assessmentsByClient.filter(ass=>
                !(!readOnlyEnabled&&ass.campaign.readOnly)
            )
                .length})`
        }
    ];

    return (
        <SubHeaderWrapper>
            <AppBar position="static">
                <ButtonContainer>
                    {headerButtons.map(
                        (button: HeaderButtonsTypes, i: number) => (
                            <TabButton
                                key={i}
                                style={{
                                    boxShadow:
                                        selected === i
                                            ? 'inset 0px -3px 0px 0px #de3328'
                                            : ''
                                }}
                                onClick={() => {
                                    setSelected(i);
                                    button.callback && button.callback();
                                }}
                            >
                                 <img src={button.imgSrc} alt={button.imgAlt}/>
                                <SubHeaderTitle id="skip-to-content" tabIndex={-1}>{button.title}</SubHeaderTitle>
                            </TabButton>
                        )
                    )}
                </ButtonContainer>
            </AppBar>
        </SubHeaderWrapper>
    );
};

const SubHeaderWrapper = styled('div')`
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;

    .MuiAppBar-colorPrimary {
        background-color: #fff;
        box-shadow: 0 0 0;
        color: #575757;
        z-index: 0;
    }
`;

const SubHeaderTitle = styled(Text)`
    margin-left: 10px;
    font-weight: medium;
    font-size: 16px;
    text-transform: none;
`;

const ButtonContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -2px;
`;

const TabButton = styled(Button)`
    && {
        box-sizing: border-box;
        padding: 17px 8px;
        height: 60px;
        border-radius: 0;
    }
`;

export default SubHeader;
