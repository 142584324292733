import { styled } from '@mui/material/styles';

const Text = styled('span')<{
    color?: string;
    weight?: string;
    size?: string;
    margin?: string;
    constantSize?: boolean;
}>`
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: ${props => (props.size ? props.size : '14px')};
    font-weight: ${props => (props.weight ? props.weight : 'normal')};
    color: ${props => (props.color ? props.color : '#2d2d2d')};
    margin: ${props => (props.margin ? props.margin : '0')};
    @media (max-width: 1366px) {
        font-size: ${props =>
            props.constantSize ? (props.size ? props.size : '14px') : '12px'};
    }
`;

export default Text;
