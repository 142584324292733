import React, { useState } from 'react';
import Modal from 'react-modal';
import { styled } from '@mui/material/styles';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Form, Field } from 'formik';
import { LinearProgress, Divider, TextField } from '@mui/material';
import * as Yup from 'yup';

import {
    Flex,
    Column,
    JustifyContentCenter,
    CustomField,
    RedButton,
    BoldText,
    Text,
    PopUpHeader
} from '../../components';
import { Stakeholder, StakeholderData, StakeholderModalValues } from './types';
import { STAKEHOLDERS } from './query';
import { customModalStyles } from '../../components/PopUps/constants';
import { ErrorText } from '../../components/base/Text/ErrorText';
import _ from 'lodash';

const EDIT_STAKEHOLDER = gql`
    mutation updateStakeholder($id: ID!, $data: UpdateStakeholderInput!) {
        updateStakeholder(id: $id, data: $data) {
            id
            firstName
            lastName
            jobTitle
            email
            createdAt
            updatedAt
        }
    }
`;

const CREATE_STAKEHOLDER = gql`
    mutation createStakeholder($data: CreateStakeholderInput!) {
        createStakeholder(data: $data) {
            id
            firstName
            lastName
            jobTitle
            email
            status
            ownedCampaignEntities {
                id
                name
            }
            updatedAt
            createdAt
        }
    }
`;

const createStakeholderSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    jobTitle: Yup.string(),
    email: Yup.string()
        .email('Invalid email format')
        .max(77, 'Must be less than 77 digits')
        .notRequired()
        
});

type Props = {
    clientsModalIsOpen: boolean;
    setClientsModalIsOpen: CallableFunction;
    clientData?: Stakeholder;
    clientId?: number;
    setIsEditedData: CallableFunction;
    stakeholderData?:any
};

const StakeholderModel: React.FC<Props> = ({
    clientsModalIsOpen,
    setClientsModalIsOpen,
    clientData,
    clientId,
    setIsEditedData,
    stakeholderData
}) => {
    const [editClient, { loading }] = useMutation(EDIT_STAKEHOLDER, {
        update(cache, { data: { updateStakeholder } }) {
            const cacheUsers = cache.readQuery<StakeholderData>({
                query: STAKEHOLDERS,
                variables: { clientId: clientId ? clientId : 0 }
            });

            if (cacheUsers && cacheUsers.stakeholdersByClient) {
                const stakeholderForUpdate = cacheUsers.stakeholdersByClient.findIndex(
                    el => el.id === updateStakeholder.id
                );

                const stakeholderCache = _.cloneDeep(cacheUsers.stakeholdersByClient[stakeholderForUpdate]);

                stakeholderCache.firstName = updateStakeholder.firstName;
                stakeholderCache.lastName = updateStakeholder.lastName;
                stakeholderCache.jobTitle = updateStakeholder.jobTitle;
                stakeholderCache.email = updateStakeholder.email;

                cache.writeQuery({
                    query: STAKEHOLDERS,
                    variables: { clientId: clientId ? clientId : 0 },
                    data: {
                        stakeholdersByClient: stakeholderCache
                    }
                });

            }

            cache.writeQuery({
                query: STAKEHOLDERS,
                variables: { clientId: clientId ? clientId : 0 },
                data: {
                    stakeholdersByClient:
                        cacheUsers && cacheUsers.stakeholdersByClient
                }
            });

            setIsEditedData(true);
        }
    });
    const [
        createStakeholder,
        { loading: createStakeholderLoading }
    ] = useMutation(CREATE_STAKEHOLDER, {
        // tslint:disable-next-line: no-shadowed-variable
        update(cache, { data: { createStakeholder } }) {
            const cacheUsers = cache.readQuery<StakeholderData>({
                query: STAKEHOLDERS,
                variables: { clientId: clientId ? clientId : 0 }
            });

            cache.writeQuery({
                query: STAKEHOLDERS,
                variables: { clientId: clientId ? clientId : 0 },
                data: {
                    stakeholdersByClient:
                        cacheUsers &&
                        cacheUsers.stakeholdersByClient &&
                        cacheUsers.stakeholdersByClient.concat([
                            createStakeholder
                        ])
                }
            });
        }
    });

    const initialValues = clientData
        ? {
              firstName: clientData.firstName,
              lastName: clientData.lastName,
              jobTitle: clientData.jobTitle,
              email: clientData.email
          }
        : {
              firstName: '',
              lastName: '',
              jobTitle: '',
              email: null
          };

    const submitStakeholder = (values: StakeholderModalValues) => {
        const tempValues = {
            firstName: values.firstName,
            lastName: values.lastName,
             email: values.email && values.email?.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)? values.email : null,
            jobTitle: values.jobTitle
        };
        values = tempValues;
        clientData
            ? editClient({
                  variables: {
                      id: clientData.id,
                      data: {
                          ...values
                      }
                  }
              }).then(_ => {
                 handleClose();
              }).catch(error => {
                console.error('error',error);
              })
            : createStakeholder({
                  variables: {
                      data: {
                          ...values,
                          clientId: clientId ? clientId : 0
                      }
                  }
              }).then(_ => {
                  handleClose();
              }).catch(error => {
                console.error(error);
            });
    };
    const [emailError, setEmailError] = useState<boolean>(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>();
    const [emailEntered, setEmailEntered] = useState<string | null>();

    const handleEmail = (email: string | null) => {
        setEmailError(false);
        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailError(true);
            setEmailErrorMessage('Invalid email');
          }
          else{
            const emailPresent =  stakeholderData.filter((stakeholder: any)=> stakeholder.email && stakeholder.email === email);
           if(emailPresent.length  > 0){
            setEmailError(true);
            setEmailErrorMessage('Email already exists');
           }
          }
          setEmailEntered(email);
    };

    const handleClose = () => {
        setEmailEntered(undefined);
        setEmailError(false);
        setEmailErrorMessage(undefined);
        setClientsModalIsOpen(false);
    };
    return (
        // eslint-disable-next-line react/no-unknown-property
        <div onLoad={() => {clientData &&  setEmailEntered(clientData.email);}} >
        <Modal
            isOpen={clientsModalIsOpen}
            shouldCloseOnOverlayClick
            contentLabel={clientData ? 'Edit stakeholder modal' : 'Create stakeholder modal'}
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={()=>handleClose()}
        >
            <PopUpHeader handleClose={() => setClientsModalIsOpen(false)} title={clientData ? 'Edit stakeholder' : 'Create stakeholder'} />
            <Formik
                initialValues={initialValues}
                validateOnMount={createStakeholderSchema.isValidSync(initialValues)}
                validationSchema={createStakeholderSchema}
                onSubmit={() => {}}
                >
                {({ values, isSubmitting,errors,touched,isValid,dirty,setFieldValue }) => {
                    return(
                        <Form style={{ width: '100%' }}>
                        <HeadingWrapper>
                            <HeadingText>
                                Let's start by adding the essentials
                            </HeadingText>
                        </HeadingWrapper>
                        <Flex>
                            <ColumnWrapper>
                                <Label>Name</Label>
                                <Field
                                    name="firstName"
                                    type="name"
                                    variant="outlined"
                                    error={
                                        !!errors.firstName &&
                                        touched.firstName
                                    }
                                    as={TextField}
                                    style={{ marginBottom: 20 }}
                                    inputProps={{
                                        'aria-label':'enter first name'
                                    }}
                                />
                                { errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
                            </ColumnWrapper>
                            <ColumnWrapper>
                                <Label>Last Name</Label>
                                <Field
                                    name="lastName"
                                    variant="outlined"
                                    type="name"
                                    error={
                                        !!errors.lastName &&
                                        touched.lastName
                                    }
                                    style={{ marginBottom: 20 }}
                                    as={TextField}
                                    inputProps={{
                                        'aria-label':'enter last name'
                                    }}
                                />
                                { errors.lastName &&  <ErrorText>{errors.lastName}</ErrorText>}
                            </ColumnWrapper>
                        </Flex>
                        <Flex>
                            <ColumnWrapper>
                                <Label>Job Title (optional)</Label>
                                <Field
                                    name="jobTitle"
                                    variant="outlined"
                                    type="text"
                                    style={{ marginBottom: 20 }}
                                    margin="normal"
                                    as={TextField}
                                    inputProps={{
                                        'aria-label':'enter job title'
                                    }}
                                />
                            </ColumnWrapper>
                            <ColumnWrapper>
                                <Label>Email Address (optional)</Label>
                                <Field
                                    name="email"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    margin="normal"
                                    as={TextField}
                                    error={emailError}
                                    inputProps={{
                                        'aria-label':'enter email address'
                                    }}
                                    value={values.email === '' ? values.email : emailEntered}
                                    onChange={(e: React.ChangeEvent<unknown>) => {
                                        const { value } = e.target as HTMLTextAreaElement;
                                        setFieldValue('email',value);
                                        handleEmail(value);
                                    }}
                                />
                                { emailError && <ErrorText>{emailErrorMessage}</ErrorText>  }
                                
                            </ColumnWrapper>
                            <Divider />
                        </Flex>
                        <SubmitWrapper>
                            {isSubmitting && <LinearProgress />}
                            <RedButton
                                disabled={(!(isValid && dirty) || emailError)}
                                onClick={() =>{
                                    submitStakeholder(values);
                                }}
                            >
                                Done
                            </RedButton>
                        </SubmitWrapper>
                    </Form>
                    );
                }}
                </Formik>
        </Modal>
        </div>
    );
};

const HeadingWrapper = styled(Flex)`
    justify-content: flex-start;
    padding: 50px 0px 0px 50px;
`;

const HeadingText = styled(BoldText)`
    font-size: 16px;
    margin-bottom: 10px;
`;

const ColumnWrapper = styled(Column)`
    width: 50%;
    padding: 0 50px;
`;

const SubmitWrapper = styled(JustifyContentCenter)`
    margin-top: 10%;
    margin-bottom: 24px;
`;

const Label = styled(Text)`
    color: #575757;
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
`;

export default StakeholderModel;
