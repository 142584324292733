import { styled } from '@mui/material/styles';

const TruncateText = styled('div')<{ lines?: number}>`
    line-height: 1.25;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lines ? props.lines : 3};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
`;

export default TruncateText;