import React from 'react';
import { styled } from '@mui/material/styles';
import { JustifyContentCenter, Text } from '../../../../components';
import emptyStateIcon from '../../../../assets/images/image-comparison-empty-state.svg';

export const ComparisonEmptyState: React.FC = () => {
    return (
        <div>
            <EmptyState>
                <img src={emptyStateIcon} alt="" />
                <EmptyStateHeading>Start comparing data</EmptyStateHeading>
                <EmptyStateText>
                    Compare campaigns and their underlying assessments to
                    identify further insights from the data.
                </EmptyStateText>
            </EmptyState>
        </div>
    );
};

const EmptyState = styled(JustifyContentCenter)`
    flex-direction: column;
    padding-top: 35px;
`;

const EmptyStateText = styled(Text)`
    font-size: 16px;
    color: #666666;
    max-width: 400px;
    text-align: center;
    margin-bottom: 16px;
`;

const EmptyStateHeading = styled(Text)`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #666666;
    margin-top: 46px;
    margin-bottom: 16px;
`;

export default ComparisonEmptyState;
