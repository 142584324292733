import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { debounce } from 'lodash';
import { ASSESSMENT_SEARCH_DELAY } from '../../../../utils/constants';

type Props = {
    placeholder: string;
    callback: any;
    style?: React.CSSProperties;
    setSearchValue?: any;
};
const EvaluateSearchFields: React.FC<Props> = ({ placeholder, callback,style, setSearchValue }) => {
    const debounceFn = debounce(handleDebounceFn, ASSESSMENT_SEARCH_DELAY);

    function handleDebounceFn(event: any) {
        callback(event.target.value);
    }

    const handleChange = (event:any ) => {
        setSearchValue(event.target.value);
        debounceFn(event);
    };

    return (
        <Wrapper style={style}>
            <Search
                type="search"
                placeholder={placeholder}
                inputProps={{ 'aria-label':placeholder }}
                onChange={e => {
                    handleChange(e);
                }}
            />
        </Wrapper>
    );
};

const Search = styled(InputBase)`
    && {
        border: none;
        width: 100%;
        outline: none;
        border-radius: 50px;
        padding: 1% 10px 5px 24px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        background-color: #fff;
        &::-webkit-input-placeholder {
            color: #818181;
        }
    }
`;

const Wrapper = styled('div')`
    height: 40px;
    min-width: 350px;
    border-radius: 30px;
    border: solid 1px #dcdcdc;
    position: relative;
    box-sizing: border-box;
`;

export default EvaluateSearchFields;
