import { styled } from '@mui/material/styles';
import { Field } from 'formik';

const CustomField = styled(Field)<{ align?: 'center' }>`
    text-align: ${props => props.align};
    .MuiInputBase-input {
        padding: 10px;
    }
    .MuiInputBase-multiline {
        padding-bottom: 0;
    }
    .MuiInput-underline:after {
        border-bottom: 2px solid #575757;
    }
    && .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #575757 !important;
    }
    .MuiOutlinedInput-multiline {
        padding: 10px;
    }
    && .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #575757;
    }
    &&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #575757;
    }
    && .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border: 2px solid #de3328;
    }
    &&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border: 2px solid #de3328;
    }
    && .MuiFormHelperText-root {
        color: #de3328
    }
`;

export default CustomField;
