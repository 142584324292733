import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { createBrowserHistory } from 'history';

import {
    SpaceBetween,
    Text,
    Column,
    AlignItems,
    ImgContainer,
    Tab,
    VerticalLine,
    Breadcrumb,
    DropdownSingleSelection,
    DropdownMultipleSelection
} from '../../../components';

import { threatAndRiskTabList } from './constants';
import {
    TabType,
    CampaignsData,
    CampaignsDataMenuProps,
    AssessmentData,
    AssessmentByCampaign,
    Risk,
    Threat,
    RiskList,
    ThreatList,
    RiskAttributes,
    ThreatObject,
    CampaignDropdownOption,
    AssessmentDropdownOption
} from './types';
import {
    renderContent,
    RISK_ATTRIBUTES,
    SAVE_THREATS,
    renderDeleteModal,
    SAVE_RISKS
} from './ThreatAndRiskHelpers';
import {
    CAMPAIGNS,
    ASSESSMENT_LIST,
    RISKS_LIST,
    THREATS_LIST,
    THREATS_DASHBOARD,
    DELETE_RISKS
} from './ThreatAndRiskHelpers';
import { UserStore } from '../../../context/user-store';
import { CalculationInfoModal, AddTARModal } from './components/modals';
import { TARLegend } from './components/legends';
import { Toolbar } from './components/toolbar';
import html2canvas from 'html2canvas';
import { Typography } from '@mui/material';
import { PermissionTypeEnum } from '../../../utils';

const ThreatAndRisk: React.FC = () => {
    const {
        state: { client, user }
    } = useContext(UserStore);
    const history = createBrowserHistory();
    const state = history.location.state as any;
    const [activeTab, setActiveTab] = useState<number>(1);
    const [isLegendVisible, setIsLegendVisible] = useState<boolean>(false);
    const [isGuidance, setGuidance] = useState<boolean>(false);
    const [isOnlyKeyVisible, setOnlyKeyVisible] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [value, setValue] = useState<number>(activeTab - 1);
    const [isCampaignMenuOpen, setCampaignMenuOpen] = useState<boolean>(false);
    const [selectedCampaign, setSelectedCampaign] = useState<
        CampaignsDataMenuProps
    >({
        id: '',
        name: '',
        readOnly: false,
        pointOneDecimalEnable: false,
        status: ''
    });
    const [selectedCampaignId, setSelectedCampaignId] = useState<string>('');
    const [listOfCampaignOptions, setListOfCampaignOptions] = useState<CampaignDropdownOption[]>([]);
    const [isAddModal, setAddModal] = useState<boolean>(true);
    const [openRiskId, setOpenRiskId] = useState<number>(0);
    const [openThreatId, setOpenThreatId] = useState<string>('');
    const [isAssessmentMenuOpen, setAssessmentMenuOpen] = useState<boolean>(
        false
    );
    const [assessments, setAssessments] = useState<AssessmentByCampaign[]>([]);
    const [selectedAssessments, setSelectedAssessments] = useState<AssessmentDropdownOption[]>(
        []
    );
    const [listOfAssessmentOptions, setListOfAssessmentOptions] = useState<AssessmentDropdownOption[]>([]);
    const [isAddButtonDisabled, setAddButtonDisabled] = useState<boolean>(true);
    const [threatAndRiskBreadcrumbs, setThreatAndRiskBreadcrumbs] = useState([
        {
            name: 'Evaluate',
            path: '/main/evaluate/campaigns'
        },
        {
            name: `${client ? client.name : ''} - ${state &&
                state.campaignTitle}`,
            path: `/main/evaluate/maturity/${state && state.campaignId}`
        },
        {
            name: 'Threat and risk analysis'
        }
    ]);
    const [isAddTARModalOpen, setAddTARModalOpen] = useState<boolean>(false);
    const [pointOneDecimalEnable, setPointOneDecimalEnable] = useState<boolean>(false);
    const [risksRetrieved, setRisksRetrieved] = useState<Risk[]>([]);
    const [threatsRetrieved, setThreatsRetrieved] = useState<ThreatObject[]>(
        []
    );
    const [isOpenDeleteConfirmation, setOpenDeleteConfirmation] = useState<{
        objectId: string | any[];
        objectName: string;
        isOpen: boolean;
        modalType: string;
        canDelete: boolean;
    }>({
        objectId: '',
        objectName: '',
        isOpen: false,
        modalType: '',
        canDelete: true
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { data } = useQuery<CampaignsData>(CAMPAIGNS, {
        variables: {
            clientId: (client && client.id) || 1,
            supportsThreatAndRisk: true
        }
    });

    const { data: riskAttributes } = useQuery<RiskAttributes>(RISK_ATTRIBUTES);

    const [getAssessmentList] = useLazyQuery<AssessmentData>(ASSESSMENT_LIST, {
        onCompleted: res => {
            setAssessments(
                res.assessmentsByCampaign.filter(
                    ass => ass.campaignEntity.archivedDate === null
                )
            );
        },
        onError: err => {
            console.log(err);
        }
    });
    const screenshot = () => {
        setLoading(true);
        window.scrollTo(0, 0);
        let containerWidth = 0;
        const container =
            document.getElementById('ThreatsWithAssessment') ||
            document.getElementById('risk') ||
            document.createElement('div');
        const threatTable = document.getElementById('ThreatObjectivesTable');
        const riskTable = document.getElementById('risk');
        if (threatTable) {
            containerWidth = threatTable.offsetWidth + 230;
        }
        if (riskTable) {
            containerWidth = riskTable.offsetWidth;
        }
        html2canvas(container, {
            onclone: documentClone => {
                const cloneMainElement = documentClone.getElementById(
                    'Threats'
                );
                const tableCloneElement = documentClone.getElementById(
                    'ThreatObjectivesTable'
                );
                if (cloneMainElement && cloneMainElement.style) {
                    cloneMainElement.style.minWidth = 'fit-content';
                    cloneMainElement.style.position = 'relative';
                    cloneMainElement.style.display = 'table-row-group';
                }
                if (tableCloneElement && tableCloneElement.style) {
                    tableCloneElement.style.marginLeft = '230px';
                }
            },
            width: containerWidth
        })
            .then(canvas => {
                setLoading(false);
                const image = canvas
                    .toDataURL('image/png')
                    .replace('image/png', 'image/octet-stream');

                // can be find better download method
                const a = document.createElement('a');
                a.href = image;
                a.download = 'Image.png';
                a.click();
            })
            .catch((error: string) => {
                setLoading(false);
                console.log(error);
                return null;
            });
    };

    const listOfAssessmentIds = selectedAssessments.map(
        (assessment: AssessmentDropdownOption) => {
            return assessment.id;
        }
    );

    const [deleteRisks] = useMutation(DELETE_RISKS, {
        onCompleted: () => {
            getRisksList({
                variables: {
                    campaignId:
                        selectedCampaign.id !== undefined &&
                        selectedCampaign.id,
                    assessmentIds: listOfAssessmentIds
                }
            });
        },
        onError: err => {
            console.log(err);
        }
    });

    const [getThreatsDashboard, { data: threatListDasboard }] = useLazyQuery(
        THREATS_DASHBOARD,
        {
            fetchPolicy: 'no-cache',
            onCompleted: res => {
                setThreatDashboard(res.threatsWithAssessments);
            },
            onError: err => {
                console.log(err);
            }
        }
    );

    const [saveThreats, { loading: saveThreatsLoading }] = useMutation(
        SAVE_THREATS,
        {
            onCompleted: () => {
                getThreatsDashboard({
                    variables: {
                        campaignId:
                            selectedCampaign.id !== undefined &&
                            selectedCampaign.id,
                        assessmentIds: listOfAssessmentIds
                    }
                });
                getThreatsList({
                    variables: {
                        campaignId:
                            selectedCampaign.id !== undefined &&
                            selectedCampaign.id
                    }
                });
                getRisksList({
                    variables: {
                        campaignId:
                            selectedCampaign.id !== undefined &&
                            selectedCampaign.id,
                        assessmentIds: listOfAssessmentIds
                    }
                });
            },
            onError: err => {
                console.log(err);
            }
        }
    );

    const [saveRisks, { loading: saveRisksLoading }] = useMutation(SAVE_RISKS, {
        onCompleted: () => {
            getRisksList({
                variables: {
                    campaignId:
                        selectedCampaign.id !== undefined &&
                        selectedCampaign.id,
                    assessmentIds: listOfAssessmentIds
                }
            });
        },
        onError: err => {
            console.log(err);
        }
    });

    useEffect(() => {
        if (threatListDasboard) {
            setThreatDashboard(
                JSON.parse(
                    JSON.stringify(threatListDasboard.threatsWithAssessments)
                )
            );
        }
    }, [threatListDasboard]);

    const changingReceivedRisks = (queryResult: RiskList) => {
        const reformatedThreatsWithAssessments: { [key: string]: Threat } = {};

        queryResult.threatsWithAssessments.forEach((el: Threat) => {
            reformatedThreatsWithAssessments[el.id] = el;
        });

        return queryResult.risks.map((el: Risk) => {
            if (el.threats) {
                return {
                    ...el,
                    threats: (el.threats as { id: string }[])
                        .map((ell: { id: string }) => {
                            return reformatedThreatsWithAssessments[ell.id];
                        })
                        .filter(ell => ell !== undefined)
                };
            }
            return el;
        });
    };

    const [getRisksList, { data: riskList }] = useLazyQuery<RiskList>(
        RISKS_LIST,
        {
            fetchPolicy: 'no-cache',
            onCompleted: () => {},
            onError: err => {
                console.log(err);
            }
        }
    );

    const [getThreatsList, { data: threatList }] = useLazyQuery<ThreatList>(
        THREATS_LIST,
        {
            onCompleted: res => {
                // res.threats.forEach(threat => {
                //     delete threat.createdAt;
                //     delete threat.updatedAt;
                // });
                if (res && res.threats) {
                    setThreatsRetrieved(res.threats);
                }
            },
            onError: err => {
                console.log(err);
            }
        }
    );

    useEffect(() => {
        if (threatList) {
            setThreatsRetrieved(threatList.threats);
        }
    }, [threatList]);

    const [threatDashboard, setThreatDashboard] = useState<Threat[]>();

    useEffect(() => {
        if (riskList) {
            setRisksRetrieved(changingReceivedRisks(riskList));
        }
    }, [riskList]);

    useEffect(() => {
        if (state && state.assessmentId) {
            threatAndRiskBreadcrumbs.splice(2, 0, {
                name: `${state.assessmentTitle}`,
                path: `/main/evaluate/maturity/${state.campaignId}/assessments/${state.assessmentId}`
            });
            setThreatAndRiskBreadcrumbs([...threatAndRiskBreadcrumbs]);
        }
    }, [state && state.assessmentId]);

    useEffect(() => {
        if ((state && state.campaignId) || (state && state.campaignTitle)) {
            setSelectedCampaign({
                id: state.campaignId,
                name: state.campaignTitle
            });
        }
    }, []);

    useEffect(() => {
        if (data && data.availableCampaignsByClient) {
            const currentListOfCampaignOptions = data.availableCampaignsByClient
                .map(el => {
                        return {
                                    id: el.id,
                                    label: el.name,
                                    isCampaignEntityUser: el.isCampaignEntityUser
                                };
                });

            setListOfCampaignOptions(currentListOfCampaignOptions);
        }
    }, [data && data.availableCampaignsByClient]);

    useEffect(() => {
        if (selectedCampaignId  && data && data.availableCampaignsByClient) {
            const currSelectedCampaign = data.availableCampaignsByClient.find(el => el.id===selectedCampaignId);
            currSelectedCampaign && setSelectedCampaign(currSelectedCampaign);
        }
    }, [selectedCampaignId]);

    useEffect(() => {
        // tslint:disable-next-line: no-redundant-boolean
        setPointOneDecimalEnable(selectedCampaign.pointOneDecimalEnable ? true : false);
    }, [selectedCampaign]);

    useEffect(() => {
        if (selectedAssessments.length) {
            if(user.id == client.owner?.id || selectedCampaign.members && selectedCampaign.members.filter((c) => c.id == user.id).length>0 || selectedCampaign.isCampaignEntityUser){
                setAddButtonDisabled(false);
            } else {
                setAddButtonDisabled(true);
            }

            getRisksList({
                variables: {
                    campaignId:
                        selectedCampaign.id !== undefined &&
                        selectedCampaign.id,
                    assessmentIds: listOfAssessmentIds
                }
            });
            getThreatsList({
                variables: {
                    campaignId:
                        selectedCampaign.id !== undefined && selectedCampaign.id
                }
            });
            getThreatsDashboard({
                variables: {
                    campaignId:
                        selectedCampaign.id !== undefined &&
                        selectedCampaign.id,
                    assessmentIds: listOfAssessmentIds
                }
            });
        } else {
            setAddButtonDisabled(true);
        }
        if(selectedCampaign.readOnly === true || selectedAssessments.map((s) => s.permission).includes(PermissionTypeEnum.READ_ONLY_ACCESS) ||
        selectedCampaign.status === 'COMPLETED' || selectedCampaign.status === 'CLOSED_INCOMPLETE') {
            setAddButtonDisabled(true);
        }
    }, [selectedAssessments, activeTab]);

    useEffect(() => {
        if (getAssessmentList && !!selectedCampaign.id) {
            getAssessmentList({
                variables: {
                    campaignId: selectedCampaign.id,
                    id: selectedCampaign.id
                }
            });
        }
        setSelectedAssessments([]);
    }, [selectedCampaign]);

    useEffect(() => {
        function compare_alphabetically(
            a: AssessmentByCampaign,
            b: AssessmentByCampaign
        ) {
            if (a.campaignEntity.name < b.campaignEntity.name) {
                return -1;
            }
            if (a.campaignEntity.name > b.campaignEntity.name) {
                return 1;
            }
            return 0;
        }
        assessments && assessments.sort(compare_alphabetically);
        const currentListOfAssessmentOptions = assessments.map(el => {
                return {
                            id: el.id,
                            name: el.campaignEntity.name,
                            permission: el.permission
                        };
        });
        setListOfAssessmentOptions(currentListOfAssessmentOptions);
    }, [assessments]);

    const selectPlaceholder = (label: string) => {
        if (label === 'campaign') {
            return selectedCampaign.name
                ? selectedCampaign.name
                : 'Select Campaign';
        }
        if (label === 'assessment') {
            return selectedAssessments.length
                ? selectedAssessments.length > 1
                    ? selectedAssessments.length + ' Selected'
                    : selectedAssessments[0].name
                : 'Select Assessment(s)';
        }
        return 'Select';
    };

    const riskScenario = activeTab === 2;
    const threatScenario = activeTab === 1;
    const riskScenarioWithData =
        riskScenario && riskList && riskList.risks.length > 0;
    const threatScenarioWithData =
        threatScenario && threatList && threatList.threats.length > 0;

    return (
        <div
            style={
                state && state.campaignId && state.campaignId.length
                    ? { marginTop: 16 }
                    : {}
            }
        >
            {state &&
            state.campaignId &&
            state.campaignId.length &&
            selectedCampaign.name === state.campaignTitle ? (
                <Breadcrumb crumbs={threatAndRiskBreadcrumbs} />
            ) : (
                ''
            )}

            { isAddTARModalOpen && (
                <AddTARModal
                    isRiskScenario={riskScenario}
                    isOpen
                    isAddModal={isAddModal}
                    setOpen={setAddTARModalOpen}
                    risksRetrieved={risksRetrieved}
                    threatsRetrieved={threatsRetrieved}
                    selectedCampaign={selectedCampaign}
                    riskAttributes={riskAttributes}
                    clientId={(client && client.id) || 1}
                    openRiskId={openRiskId}
                    openThreatId={openThreatId}
                    isGuidance={isGuidance}
                    setGuidance={setGuidance}
                    saveThreats={saveThreats}
                    saveThreatsLoading={saveThreatsLoading}
                    saveRisks={saveRisks}
                    saveRisksLoading={saveRisksLoading}
                />
            )}

            <Header>
                <Typography variant="h1" style={{
                    letterSpacing: 0.5,
                    color: '#525252',
                    fontSize: '24px',
                    fontWeight: 'bold'
                }}>
                    Threat and risk analysis
                </Typography>
                <AlignItems>
                    <Column>
                        <HeaderFilterWrapper>
                            <HeaderFilterHeading>
                                Campaign for analysis
                            </HeaderFilterHeading>
                            <DropdownSingleSelection
                                listOfOptions={listOfCampaignOptions}
                                isOpen={isCampaignMenuOpen}
                                setOpen={setCampaignMenuOpen}
                                placeholder={selectPlaceholder('campaign')}
                                buttonDisabled={false}
                                selectedOptionId={selectedCampaignId}
                                setSelectedOptionId={setSelectedCampaignId}
                                width="312px"
                            />
                        </HeaderFilterWrapper>
                    </Column>
                    <Column>
                        <HeaderFilterWrapper>
                            <HeaderFilterHeading>
                                Assessment(s) for analysis
                            </HeaderFilterHeading>
                            <DropdownMultipleSelection
                                listOfOptions={listOfAssessmentOptions}
                                isOpen={isAssessmentMenuOpen}
                                setOpen={setAssessmentMenuOpen}
                                placeholder={selectPlaceholder('assessment')}
                                buttonDisabled={!selectedCampaign.name}
                                selected={selectedAssessments}
                                setSelected={setSelectedAssessments}
                                width="312px"
                                multiselect
                            />
                        </HeaderFilterWrapper>
                    </Column>
                </AlignItems>
            </Header>
            <Column>
                <AlignItems>
                    {threatAndRiskTabList.map(
                        (item: TabType, index: number, array: TabType[]) => {
                            return (
                                <Tab
                                    index={index}
                                    active={activeTab === item.id}
                                    handleClick={() => {
                                        setActiveTab(item.id);
                                        setIsLegendVisible(false);
                                        setIsModalOpen(false);
                                        setValue(index);
                                    }}
                                    key={item.id}
                                    lastTab={
                                        threatAndRiskTabList.length - 1 ===
                                        index
                                    }
                                >
                                    <TabContentContainer>
                                        <ImgContainer
                                            src={
                                                activeTab === item.id
                                                    ? item.iconActive
                                                    : item.iconDisable
                                            }
                                            alt={'icon'}
                                        />
                                        <Text
                                            color={
                                                activeTab === item.id
                                                    ? '#4f4f4f'
                                                    : 'white'
                                            }
                                            size="18px"
                                            constantSize
                                            margin="0 0 0 2px"
                                        >
                                            {item.label}
                                        </Text>
                                    </TabContentContainer>
                                    {activeTab !== index + 1 &&
                                        index !== array.length - 1 &&
                                        index !== activeTab - 2 && (
                                            <VerticalLine margin="0" />
                                        )}
                                </Tab>
                            );
                        }
                    )}
                </AlignItems>
                <MainContentContainer>
                    {(riskScenarioWithData || threatScenarioWithData) && (
                        <Toolbar
                            setAddTARModalOpen={setAddTARModalOpen}
                            activeTab={activeTab}
                            setAddModal={setAddModal}
                            isLegendVisible={isLegendVisible}
                            setIsLegendVisible={setIsLegendVisible}
                            isAddButtonDisabled={isAddButtonDisabled}
                            setIsModalOpen={setIsModalOpen}
                            isGuidance={isGuidance}
                            setGuidance={setGuidance}
                            isOnlyKeyVisible={isOnlyKeyVisible}
                            setOnlyKeyVisible={setOnlyKeyVisible}
                            takeScreenshot={screenshot}
                            loading={loading}
                            campaignID={selectedCampaign.id}
                        />
                    )}

                    {renderContent({
                        activeTab,
                        setActiveTab,
                        isAddButtonDisabled,
                        threatAndRiskList: riskScenario
                            ? risksRetrieved
                            : threatList && threatList.threats,
                        assessments,
                        setAddTARModalOpen,
                        setAddModal,
                        isGuidance,
                        isOnlyKeyVisible,
                        setOpenRiskId,
                        setOpenThreatId,
                        setOpenDeleteConfirmation,
                        threatDashboard,
                        listOfAssessmentIds,
                        selectedCampaign,
                        pointOneDecimalEnable
                    })}
                    {renderDeleteModal({
                        handleClose: () =>
                            setOpenDeleteConfirmation({
                                objectId: '',
                                objectName: '',
                                isOpen: false,
                                modalType: '',
                                canDelete: true
                            }),
                        handleSend: () => {
                            setOpenDeleteConfirmation({
                                objectId: '',
                                objectName: '',
                                isOpen: false,
                                modalType: '',
                                canDelete: true
                            });
                            if (isOpenDeleteConfirmation.modalType === 'risk') {
                                deleteRisks({
                                    variables: {
                                        ids: [
                                            isOpenDeleteConfirmation.objectId
                                        ],
                                        campaignId: selectedCampaign.id
                                    }
                                })
                                    .then(() => {})
                                    .catch(() => {});
                            } else if (isOpenDeleteConfirmation.modalType === 'threat' &&
                                isOpenDeleteConfirmation.canDelete) {
                                const threatData = isOpenDeleteConfirmation && typeof isOpenDeleteConfirmation.objectId === 'object'
                                    && isOpenDeleteConfirmation.objectId.map(t => {
                                        return {
                                            description: t.description,
                                            id: t.id,
                                            objectives: t.objectives,
                                            title: t.title,
                                        };
                                }, []);
                                saveThreats({
                                    variables: {
                                        campaignId: selectedCampaign.id,
                                        threats: threatData
                                    }
                                })
                                    .then(() => {})
                                    .catch(() => {});
                            }
                        },
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        isOpenDeleteConfirmation
                    })}
                </MainContentContainer>

                {isLegendVisible && (
                    <TARLegend
                        setIsLegendVisible={setIsLegendVisible}
                        isRiskScenario={riskScenario}
                    />
                )}

                <CalculationInfoModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    value={value}
                    handleChange={(
                        _event: React.ChangeEvent<unknown>,
                        newValue: number
                    ) => {
                        setValue(newValue);
                    }}
                />
            </Column>
        </div>
    );
};

const MainContentContainer = styled('div')`
    padding: 31px 35px 14px 31px;
    border: solid 1px #d9d9d9;
    background: white;
    margin-bottom: 50px;
    border-radius: 2px;
    border-top-left-radius: 0;
`;

const TabContentContainer = styled('div')`
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    border-bottom: transparent solid 1px;
`;

const Header = styled(SpaceBetween)`
    padding-top: 32px;
    padding-bottom: 16px;
`;

const HeaderFilterHeading = styled(Text)`
    letter-spacing: 0.5px;
    color: #575757;
    margin-bottom: 8px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const HeaderFilterWrapper = styled('div')`
    margin-left: 30px;
`;

export default ThreatAndRisk;
