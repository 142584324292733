import { Routes, DynamicRoutes } from './routes.enum';
import { createBrowserHistory } from 'history';
import { History } from 'history';
import { Campaign, AssessmentsList } from '../modules/Layout/types';

class Router {
    public history: History;

    constructor() {
        this.history = createBrowserHistory();
    }

    public goToDashboard = () => {
        this.history.push(Routes.dashboard);
    };

    public goToSplash = () => {
        this.history.push(Routes.splash);
    };

    public goToEvaluateCampaign = (availableCampaignsByClient?: Campaign[]) => {
        this.history.push(Routes.evaluateCampaign, {
            availableCampaignsByClient
        });
    };

    public goToBenchmarking = (
        campaignId: string,
        campaignTitle?: string,
        assessmentId?: string,
        assessmentTitle?: string
    ) => {
        this.history.push(Routes.benchmarking, {
            campaignId,
            campaignTitle,
            assessmentId,
            assessmentTitle
        });
    };

    public goToComparison = (
        campaignId: string,
        campaignTitle?: string,
        assessmentId?: string,
        assessmentTitle?: string
    ) => {
        this.history.push(Routes.comparison, {
            campaignId,
            campaignTitle,
            assessmentId,
            assessmentTitle
        });
    };

    public goToThreatAndRisk = (
        campaignId?: string,
        campaignTitle?: string,
        assessmentId?: string,
        assessmentTitle?: string
    ) => {
        this.history.push(Routes.threatAndRisk, {
            campaignId,
            campaignTitle,
            assessmentId,
            assessmentTitle
        });
    };

    public goToEvaluateCreateCampaign = (id?: string, step?: number) => {
        if (id) {
            this.history.push(DynamicRoutes.goToCampaignCreation(id, step));
        } else {
            this.history.push(Routes.createCampaign);
        }
    };

    public goToEvaluateAssessments = (
        assessmentsByClient?: AssessmentsList[]
    ) => {
        this.history.push(Routes.evaluateAssessments, {
            assessmentsByClient
        });
    };

    public goToStakeholderManagement = () => {
        this.history.push(Routes.stakeholderManagement);
    };

    public goToLogoutPage = () => {
        this.history.push(Routes.logoutRedirect);
    };

    public goToMaturityOverview = (id: string) => {
        this.history.push(DynamicRoutes.goToMaturityOverview(id));
    };

    public goToMaturityScore = (props: {
        campaignId: string;
        assessmentId: string;
        domainId: string | number;
        capabilityId: string | number;
    }) => {
        /**
         * Split path name to determine if visit to MaturityScore is From Routes.maturityOverview
         * or Routes.assessmentMaturityScore so we can determine where to redirect as both pages acccess with the same
         * params PPN-1496
         */
        const pathname  = window.location.pathname;
        const paths     = pathname.split('/');
        const slug      = paths[paths.length-2];
        this.history.push(DynamicRoutes.goToMaturityScore(props), {slug: (slug === 'assessments' ? 'assessmentsMaturityOverview' : 'MaturityOverview')});    };

    public goToAssessmentsMaturityOverview = (
        campaignId: string,
        assessmentId: string
    ) => {
        this.history.push(
            DynamicRoutes.goToAssessmentsMaturityOverview(
                campaignId,
                assessmentId
            )
        );
    };

    public getCurrentPath = () => {
        return this.history.location.pathname;
    };

    public goToCompanyStructure = () => {
        this.history.push(Routes.companyStructure);
    };

    public goBack = () => {
        this.history.back();
    };

    public goToAdmin = () => {
        window.location.href = window.__ENV__.REACT_APP_ADMIN_URL;
    };

    public goToTermsOfUse = () => {
        this.history.push(Routes.termsOfUse);
    };

    public goToTimeout = () => {
        window.location.href = window.location.origin + '/timeout/index.html';
    };
}

export default new Router();
