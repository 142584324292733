import React from 'react';
import Modal from 'react-modal';

import { AddTARModalProps } from '../../types';
import RiskModalContent from './content/RiskModalContent/RiskModalContent';
import ThreatModalContent from './content/ThreatModalContent/ThreatModalContent';
import { addRiskModalWrapper } from '../../constants';

const AddTARModal: React.FC<AddTARModalProps> = ({
    isOpen,
    setOpen,
    risksRetrieved,
    threatsRetrieved,
    isRiskScenario,
    selectedCampaign,
    riskAttributes,
    clientId,
    isAddModal,
    openRiskId,
    openThreatId,
    isGuidance,
    setGuidance,
    saveThreats,
    saveThreatsLoading,
    saveRisks,
    saveRisksLoading
}) => {
    return (
        <Modal
            isOpen={isOpen}
            shouldCloseOnOverlayClick={false}
            style={addRiskModalWrapper}
            ariaHideApp={false}
        >
            {isRiskScenario ? (
                <RiskModalContent
                    risksRetrieved={risksRetrieved}
                    threatsRetrieved={threatsRetrieved}
                    setOpen={setOpen}
                    selectedCampaign={selectedCampaign}
                    riskAttributes={riskAttributes}
                    isAddModal={isAddModal}
                    clientId={clientId}
                    openRiskId={openRiskId}
                    saveRisks={saveRisks}
                    saveRisksLoading={saveRisksLoading}
                />
            ) : (
                <ThreatModalContent
                    threatsRetrieved={threatsRetrieved}
                    setOpen={setOpen}
                    selectedCampaign={selectedCampaign}
                    isAddModal={isAddModal}
                    openThreatId={openThreatId}
                    isGuidance={isGuidance}
                    setGuidance={setGuidance}
                    saveThreats={saveThreats}
                    saveThreatsLoading={saveThreatsLoading}
                />
            )}
        </Modal>
    );
};

export default AddTARModal;
