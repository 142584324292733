import { Fab, FabProps, Tooltip } from '@mui/material';
import ContactSupport from '@mui/icons-material/ChatBubble';
import CloseIcon from '@mui/icons-material/CloseRounded';
import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    createStore,
    createDirectLine,
    renderWebChat,
} from 'botframework-webchat';
import { Middleware } from 'redux';
import './ChatBot.scss';
// import BotIcon from '../../assets/images/bot.png';
import { UserStore } from '../../context/user-store';

const ChatIcon = styled('div')`
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 100px;
    left: auto;
    position: fixed;
`;
const ChatBox = styled('div')`
    margin: 0px;
    top: 20px;
    right: 20px;
    bottom: 100px;
    left: auto;
    position: fixed;
    background-color: white;
    height: auto;
    width: 400px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
`;

const ChatHeader = styled('div')`
    left: auto;
    right: auto;
    position: relative;
    padding: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #d04a02;
    display: flex;
    justify-content: space-between;
`;

const ChatBot: React.FC<FabProps> = () => {
    const {
        state: { user },
        dispatch,
    } = useContext(UserStore);

    const [chatStatus, setChatStatus] = useState(false);

    const BOT_ID = '63cf8cdf-92df-488b-8654-bab3181e581d';

    const theURL =
        'https://powerva.microsoft.com/api/botmanagement/v1/directline/directlinetoken?botId=' +
        BOT_ID;

    const styleOptions = {
        suggestedActionLayout: 'stacked',
        hideUploadButton: true,
        botAvatarInitials: 'BT',
        accent: '#00809d',
        botAvatarBackgroundColor: '#FFFFFF',
        botAvatarImage: CloseIcon,
    };


    const middleware: Middleware =
        ({ dispatch }) =>
        (next) =>
        (action) => {
            if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                dispatch({
                    meta: {
                        method: 'keyboard',
                    },
                    payload: {
                        activity: {
                            channelData: {
                                postBack: true,
                            },
                            name: 'startConversation',
                            value: {
                                'useremail': user.email,
                                'username': user.firstName,
                                'url': window.__ENV__.REACT_APP_APP_URL.replace('graphql', ''),
                            },
                            type: 'event',
                        },
                    },
                    type: 'DIRECT_LINE/POST_ACTIVITY',
                });
            }
            return next(action);
        };

    const store = createStore({}, middleware);
        fetch(theURL)
            .then((response) => response.json())
            .then((conversationInfo) => {
                renderWebChat(
                    {
                        directLine: createDirectLine({
                            token: conversationInfo.token,
                        }),
                        store: store,
                        styleOptions: styleOptions,
                    },
                    document.getElementById('webchat')
                );
            })
            .catch((err) => console.error('An error occurred: ' + err));

    if (chatStatus === false) {
        return (
            <Tooltip title="I'm a Chatbot, I'll be happy to help you.">
                <ChatIcon>
                    <Fab
                        color="secondary"
                        aria-label="Chatbot"
                        onClick={() => {
                            setChatStatus(true);
                        }}
                    >
                        <ContactSupport />
                    </Fab>
                </ChatIcon>
            </Tooltip>
        );
    } else {
        return (
            <ChatBox>
                <ChatHeader id='chatHeader'>
                    <h1>Connected Risk Engine - Chatbot</h1>
                    <Fab
                        style={{
                            marginRight: 10,
                        }}
                        size="small"
                        color="default"
                        aria-label="close chatbot"
                        onClick={() => {
                            setChatStatus(false);
                        }}
                    >
                        <CloseIcon />
                    </Fab>
                </ChatHeader>
                <div id="webchat" role="main" />
            </ChatBox>
        );
    }
};

export default ChatBot;
