import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

type AverageMarkProps = {
    left: string;
    right: string;
    children: ReactNode;
};

const AverageMark: React.FC<AverageMarkProps> = ({
    left,
    right,
    children
}) => {
    return (
        <AverageMarkContainer
            left={left}
            right={right}
        >
            {children}
            <LeftTopBorder />
            <LeftBottomBorder />
            <RightBottomBorder />
            <RightTopBorder />
        </AverageMarkContainer>
    );
};

const AverageMarkContainer = styled('div') <{ left: string; right: string }>`
    position: absolute;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    height: 140px;
    top: -56px;
    color: black;
    left: ${props => props.left};
    right: ${props => props.right};
`;


const LeftTopBorder = styled('div')`
    top: 0;
    left: 1px;
    width: 1px;
    height: 12px;
    background: #c4c4c4;
    position: absolute;
`;

const LeftBottomBorder = styled('div')`
    bottom: 0;
    left: 1px;
    width: 1px;
    height: 12px;
    background: #c4c4c4;
    position: absolute;
`;

const RightBottomBorder = styled('div')`
    bottom: 0;
    right: 1px;
    width: 1px;
    height: 12px;
    background: #c4c4c4;
    position: absolute;
`;

const RightTopBorder = styled('div')`
    top: 0;
    right: 1px;
    width: 1px;
    height: 12px;
    background: #c4c4c4;
    position: absolute;
`;

export default AverageMark;
