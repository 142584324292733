import { Risk, ratingsType } from '../../types';
import { residualRiskRating } from '../../constants';

export const getResidualRiskRate = (risk: Risk): ratingsType | undefined => {
    if (
        risk.threats &&
        risk.threats.length &&
        risk.threats.length !== 0
    ) {
        if (!!risk.residualRate && risk.residualRate!=='Scores pending') {
            return residualRiskRating.find(rating => rating.title === risk.residualRate);
        }

        return residualRiskRating.find(rating => rating.title === 'Pending');
    }

    return undefined;
};
