import React, { useEffect, useState, useContext } from 'react';
import { PermissionTypeEnum, isReadOnlyEnabled } from '../../../../../utils';
import { UserStore } from '../../../../../context/user-store';
import { styled } from '@mui/material/styles';
import {
    Text,
    AlignItems,
    Column,
    SpaceBetween,
    PopUpHeader
} from '../../../../../components';
import { ObsAndRecModalProps } from '../../../MaturityScore/WorkingNotesModal/types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import maturityOverviewIcon from '../../../../../assets/images/icon-maturity-overview.svg';
import assessmentIcon from '../../../../../assets/images/assessments-icon.svg';
import ObsAndRecContainer from '../../../MaturityScore/Moderation/components/ObsAndRecContainer';
import { gql } from 'apollo-boost';
import { AssessmentData, CampaignData } from '../../../MaturityScore/types';
import { useQuery } from '@apollo/react-hooks';
import {
    useAssessmentDomainsList,
    useCapability
} from '../../../MaturityScore/MaturityScoreHelpers';
import CapabilityMenu from '../../../../../components/Menu/CapabilityMenu';
import { obsAndConsStyles } from '../../../../../components/PopUps/constants';
import Modal from 'react-modal';
import { ASSESSMENT_BY_ID } from './AssessmentQueries';

const CAMPAIGN = gql`
    query campaign2($id: ID!) {
        campaign(id: $id) {
            id
            title
            manager {
                id
            }
            members {
                id
            }
            isCampaignEntityUser
            closedAt
            readOnly
        }
    }
`;

export const ObservationsAndRecommendations: React.FC<ObsAndRecModalProps> = ({
    isOpenObsAndRecModal,
    setIsOpenObsAndRecModal,
    currentIdsData,
    setCurrentIdsData,
    campaignId,
    assessmentId,
    currentDomain,
    currentDomainIndex,
    setCurrentDomain,
    setCurrentDomainIndex
}) => {
    const [value, setValue] = useState<number>(0);
    const [currentCapabilityIndex, setCurrentCapabilityIndex] = useState<
        number
    >(0);
    const [level, setLevel] = useState<string>('maturity');

    useEffect(() => {
        if (isOpenObsAndRecModal && !value) {
            setLevel('domains');
        } else if (isOpenObsAndRecModal && !!value) {
            setLevel('capabilities');
        } else {
            setLevel('maturity');
        }
    }, [value, isOpenObsAndRecModal]);

    const {
        loading: assessmentLoading,
        // error: assessmentError,
        data: assessmentData
    } = useQuery<AssessmentData>(ASSESSMENT_BY_ID, {
        variables: {
            id: assessmentId
        }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const frameworkCapabilities = assessmentData?.assessment?.framework.definition.domains.reduce(
        (newArray: any, domain: { capabilities: any; shortName: string}): any => {
            const updatedObject = domain.capabilities.map((c: any) => ({
                ...c,
                ['domainShortName']: domain.shortName

            }));
            return [...newArray, ...updatedObject];
        },
        []
    );

    const assessmentDomainsList = useAssessmentDomainsList(
        assessmentLoading,
        assessmentData
    );

    let capabilityData = null;

    if (
        assessmentDomainsList &&
        assessmentDomainsList[currentDomainIndex] &&
        assessmentDomainsList[currentDomainIndex].capabilities &&
        assessmentDomainsList[currentDomainIndex].capabilities[
            currentCapabilityIndex
        ]
    ) {
        capabilityData =
            assessmentDomainsList[currentDomainIndex].capabilities[
                currentCapabilityIndex
            ];
    }

    const currentCapability = useCapability({
        capabilityData,
        capabilityLoading: assessmentLoading,
        responseStatusData: null
    });

    const { data: campaignData } = useQuery<CampaignData>(CAMPAIGN, {
        variables: {
            id: campaignId
        }
    });

    const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
    };

    const currentDomainData = {
        setCurrentDomain,
        currentDomainIndex,
        setCurrentDomainIndex
    };

    const currentCapabilityData = {
        currentCapability,
        currentCapabilityIndex,
        setCurrentCapabilityIndex
    };

    // Check if the campaign is closed.
    let campaignIsClosed = campaignData
        ? campaignData.campaign.closedAt !== null
        : false;

    // Check if the user is read only and if it is enabled.
    const {
        state: { globalSettings }
    } = useContext(UserStore);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    const isReadOnly = (campaignData && campaignData.campaign.readOnly || assessmentData?.assessment.permission == PermissionTypeEnum.READ_ONLY_ACCESS || false);
    const assessmentReadOnly = assessmentData?.assessment.permission == PermissionTypeEnum.READ_ONLY_ACCESS;
    if (readOnlyEnabled && isReadOnly) {
        // Piggybacking on campaign closed mode for read only mode.
        campaignIsClosed = true;
    }

    return (
        <ObsAndRecModal
            isOpen={isOpenObsAndRecModal}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            style={obsAndConsStyles}
            onRequestClose={() => setIsOpenObsAndRecModal(false)}
        >
            <PopUpHeader title='Observations and Recommendations' handleClose={() => setIsOpenObsAndRecModal(false)}/>
            <ContentWrapper>
                <Column style={{ width: '100%' }}>
                    <SpaceBetween
                        style={{
                            alignItems: 'center',
                            height: '65px',
                            backgroundColor: '#f8f8f8'
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            style={{
                                width: '100%'
                            }}
                            TabIndicatorProps={{
                                style: {
                                    height: 4,
                                    backgroundColor: '#de3328'
                                }
                            }}
                            centered
                        >
                            <Tab
                                label={
                                    <TabContainer>
                                        <img
                                            src={maturityOverviewIcon}
                                            alt="Domains"
                                            style={{
                                                marginRight: 8,
                                                fill: '#2d2d2d'
                                            }}
                                        />
                                        <TabText>Domains</TabText>
                                    </TabContainer>
                                }
                            />
                            <Tab
                                label={
                                    <TabContainer>
                                        <img
                                            src={assessmentIcon}
                                            alt="Capabilities"
                                            style={{
                                                marginRight: 8
                                            }}
                                        />
                                        <TabText>Capabilities</TabText>
                                    </TabContainer>
                                }
                            />
                        </Tabs>
                    </SpaceBetween>

                    <ObsAndRecContainerWrapper>
                        <CapabilityMenuWrapper>
                            <CapabilityMenu
                                currentDomainData={currentDomainData}
                                currentCapabilityData={currentCapabilityData}
                                currentIdsData={currentIdsData}
                                readOnly={(readOnlyEnabled && isReadOnly)}
                                assessmentDomainsListOriginal={assessmentDomainsList}
                                setCurrentIdsData={setCurrentIdsData}
                                assessmentId={assessmentId}
                                assessmentReadOnly={assessmentReadOnly || campaignData?.campaign.readOnly}
                                level={level}
                            />
                        </CapabilityMenuWrapper>
                        {currentDomain.capabilities && (
                            <ObsAndRecContainer
                                frameworkCapabilities={frameworkCapabilities}
                                currentCapability={currentCapability}
                                currentDomain={currentDomain}
                                campaignData={campaignData}
                                currentAssessmentId={assessmentId}
                                currentIdsData={currentIdsData}
                                level={level}
                                canEdit={!campaignIsClosed}
                                isReadOnly={isReadOnly}
                            />
                        )}
                    </ObsAndRecContainerWrapper>
                </Column>
            </ContentWrapper>
        </ObsAndRecModal>
    );
};

const CapabilityMenuWrapper = styled('div')`
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
`;

const ObsAndRecContainerWrapper = styled('div')`
    padding: 20px 40px;
    background: white;
`;

const ContentWrapper = styled(Column)`
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
`;

const TabText = styled(Text)`
    color: #2d2d2d;
    font-size: 18px;
    text-transform: none;
    font-weight: 500;
`;

const TabContainer = styled(AlignItems)`
    height: 53px;
    box-sizing: border-box;
`;

const ObsAndRecModal = styled(Modal)`
    position: absolute;
    border: none;
    right: 0;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
`;

export default ObservationsAndRecommendations;
