import React from 'react';
import { styled } from '@mui/material/styles';
import Flex from '../base/Layout/Flex';
import Column from '../base/Layout/Column';
import JustifyContentCenter from '../base/Layout/JustifyContentCenter';
import BoldText from '../base/Text/BoldText';
import mdi_lock from '../../assets/images/mdi_lock.svg';


type Props = {
    message?: any;
};

const NotificationCloseCampaign: React.FC<Props> = ({message}) => {
    return (
        <Flex style={{
            marginBottom: 20
        }}>
            <IconWrapper backgroundColor="#4588c3">
                <img src={mdi_lock} alt="" />
            </IconWrapper>
            <ColumnCenter>
                <BoldText color="white" fontSize="16px">
                    { message }
                </BoldText>
            </ColumnCenter>
        </Flex>
    );
};

const ColumnCenter = styled(Column)`
    justify-content: center;
    flex-grow: 1;
    background-color: #40403e;
    padding: 11px;
`;
const IconWrapper = styled(JustifyContentCenter)<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    min-width: 42px;
`;

export default NotificationCloseCampaign;
