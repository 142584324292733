import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import InfoIcon from '@mui/icons-material/Info';
import { Checkbox, Tooltip, Button } from '@mui/material';
import {
    Explore,
    EventNote,
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@mui/icons-material';

import {
    ObjectivesSlider,
    ScoreSquare,
    Text,
    SpaceBetween,
    Flex,
    AlignItems,
    ArrowTooltip,
    ToggleSwitch
} from '../../../../../components';
import {
    textColorResolver,
    withoutThemeColorResolver
} from '../../../../../utils';
import { Mark, MaturityMatch,CampaignData } from '../../types';
import {
    objectiveScoreChange,
    objectiveNotesChange
} from '../ObjectivesHelpers';

import ObjectiveDescriptionsContainer from './ObjectiveDescriptionsContainer';
import ObjectiveNotesContainer from './ObjectiveNotesContainer';
import ObjectiveGuidanceTooltip from './ObjectiveGuidanceTooltip';
import PromptPopup from '../../../../../components/PopUps/PromptRouter/PromptPopup';
import * as ld from 'lodash';
import { UserStore } from '../../../../../context/user-store';
import { ASSESSMENT_CAPABILITY_WITH_FRAMEWORK } from '../../MaturityScoreQueries';

const EDIT_OBJECTIVE_SCORE = gql`
    mutation updateAssessmentObjectiveScore(
        $data: UpdateAssessmentObjectiveScoreInput!
    ) {
        updateAssessmentObjectiveScore(data: $data) {
            id
            status
            averageScore
            moderatedScore
            objectives {
                id
                inScope
                notes
                score
                compensatingControlEnable
                components{
                    id
                    score
                }
            }
        }
    }
`;

const EDIT_OBJECTIVE_NOTE = gql`
    mutation updateAssessmentObjectiveNotes(
        $data: UpdateAssessmentObjectiveNotesInput!
    ) {
        updateAssessmentObjectiveNotes(data: $data) {
            id
            objectives {
                id
                inScope
                notes
                score
            }
        }
    }
`;

export type ObjectiveDescriptionCardProps = {
    notesStatus: boolean;
    marksList: Mark[];
    isActive: boolean;
    score: number | null;
    compensatingControlValue?: boolean;
    checkboxName: string;
    handleChange: (status: boolean) => void;
    handleScoreChange: (e: number) => void;
    scoreMarks: string[];
    assessmentId: string;
    domainIndex: number;
    capabilityIndex: number;
    objectiveIndex: number;
    notes: string;
    guidance?: string;
    setLoadingStatus: (status: boolean) => void;
    idTitle: string;
    domainId: string;
    capabilityId: string;
    objectiveId: string;
    componentId?: string;
    description: string;
    descriptionsList: string[];
    canEdit: boolean;
    isDescopeModalOpen: boolean;
    setDirty: any;
    isDirty: boolean;
    capabilityStatus: string;
    match: MaturityMatch;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    isComponentCard?: boolean;
    isObjectiveComponentExist?: boolean;
    inScope: boolean;
    CampaignData?: CampaignData;
    compensatingControlEnabled: boolean;
};

const ObjectiveDescriptionCard: React.FC<ObjectiveDescriptionCardProps> = ({
    marksList,
    checkboxName,
    isActive,
    score,
    compensatingControlValue,
    scoreMarks,
    assessmentId,
    domainIndex,
    capabilityIndex,
    objectiveIndex,
    notes,
    guidance,
    idTitle,
    domainId,
    capabilityId,
    objectiveId,
    componentId,
    description,
    descriptionsList,
    notesStatus,
    handleScoreChange,
    handleChange,
    setLoadingStatus,
    canEdit,
    isDescopeModalOpen,
    readOnlyEnabled,
    isReadOnly,
    setDirty,
    isDirty,
    capabilityStatus,
    match,
    isComponentCard,
    isObjectiveComponentExist,
    inScope,
    CampaignData,
    compensatingControlEnabled
}) => {
    const { state: { user, client, globalSettings }} = useContext(UserStore);
    const [isNotesOpen, setIsnotesOpen] = useState<boolean>(false);
    const [isCompensatingControl, setIsCompensatingControl] = useState<boolean>(compensatingControlValue || false);
    const [isGuidanceOpen, setGuidanceOpen] = useState<boolean>(false);
    const [
        updateObjectiveScore,
        { loading: updateObjectiveScoreLoading }
    ] = useMutation(EDIT_OBJECTIVE_SCORE,
        {refetchQueries: [
        {
            query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
            variables: {
                campaignId: match.params.campaignId,
                data: {
                    assessmentId: match.params.assessmentId,
                    domainId: domainId,
                    capabilityId: capabilityId
                }
            }
        }
    ]}
    );

    const [
        updateObjectiveNotes,
        { loading: updateObjectiveNotesLoading }
    ] = useMutation(EDIT_OBJECTIVE_NOTE);

    useEffect(() => {
        setLoadingStatus(
            updateObjectiveScoreLoading || updateObjectiveNotesLoading
        );
    }, [updateObjectiveScoreLoading, updateObjectiveNotesLoading]);

    const [markdownValue, setMarkdownValue] = useState<string>('');
    const [previousMarkdownValue, setPreviousMarkdownValue] = useState<string>('');

    const [tooltipOpenIndex, setIsTooltipOpenIndex] = useState<null | number>(
        null
    );

    useEffect(() => {
        if(markdownValue!==notes){
            setMarkdownValue(notes);
            setPreviousMarkdownValue(notes);
        }
    }, [notes]);

    const isMarkdownSaveButtonDisabled = useMemo(() => {
        const isDisabled= (notes === markdownValue || isActive);
        setDirty(!isDisabled);
        return isDisabled;
    }, [notes, markdownValue, isActive]);

    const cancelMarkdownChanges = useCallback(() => {
        setMarkdownValue(previousMarkdownValue);
    }, [previousMarkdownValue]);

    const saveMarkdownChanges = useCallback((values: { toString: () => any; }) => {
            const callbackNotes = values.toString();
            return objectiveNotesChange({
                callbackNotes,
                updateObjectiveNotes,
                assessmentId,
                domainId,
                capabilityId,
                objectiveId,
            });
        },
        [assessmentId, domainIndex, capabilityIndex, objectiveIndex]
    );

    const handleResetObjectiveScore = useCallback(() => {
        if (isActive) {
            return;
        }
        objectiveScoreChange({
            score: null,
            updateObjectiveScore,
            assessmentId,
            domainId,
            capabilityId,
            objectiveId,
            componentId: componentId,
            compensatingControlEnable: compensatingControlEnabled ? false : undefined
        });
    }, [assessmentId, domainIndex, capabilityIndex, objectiveIndex, isActive]);

    const handleNotesOpen = () => {
        setIsnotesOpen(!isNotesOpen);
    };

    useEffect(() => {
        if (notesStatus) {
            setIsnotesOpen(false);
        }
        if (!notesStatus) {
            setIsnotesOpen(true);
        }
    }, [notesStatus]);

    const saveObjectiveScore = useCallback(ld.debounce(async (moderatedValue: number) => {
        await objectiveScoreChange({
            score: moderatedValue,
            updateObjectiveScore,
            assessmentId,
            domainId,
            capabilityId,
            objectiveId,
            componentId
        });
    }, 250), []);

    const handleCompensatingControl = async (val: boolean) => {
        setIsCompensatingControl(val);
        if (compensatingControlEnabled) {
            await objectiveScoreChange({
                score: score,
                updateObjectiveScore,
                assessmentId,
                domainId,
                capabilityId,
                objectiveId,
                componentId,
                compensatingControlEnable: compensatingControlEnabled ? val : undefined
            });
        }
    };

    return (
        <Container isActive={isActive} inScope={inScope}>
            <PromptPopup when={isDirty} setDirty={setDirty}/>
            <TitleContainer>
            <Tooltip
                    title={
                        'Select this objective for descoping or rescoping'}
                    placement="top-start">
                    <InfoIcon
                        style={{
                            marginRight: '4px',
                            marginBottom: '-2px',
                            fontSize: '16px',
                            position: 'relative',
                            top: '11px',
                            left: '-2px',
                        }}
                    />
                </Tooltip>

                <div>
                    {((!isObjectiveComponentExist)||(isObjectiveComponentExist&&!isComponentCard))&&
                    <Checkbox
                        checked={isActive}
                        color="primary"
                        name={checkboxName}
                        disabled={!canEdit}
                        onChange={() => {
                            handleChange(!isActive);
                        }}
                        value={isActive}
                        inputProps={{
                            'aria-label': 'primary checkbox'
                        }}
                        style={{
                            padding: '0',
                            top: '10px',
                            position: 'relative'
                        }}
                    />}
                </div>

                <TextContainerWithPadding>
                    <InfoContent>
                        <Text weight={'bold'}>{idTitle}: </Text>
                        <DescriptionText>{description}</DescriptionText>
                    </InfoContent>

                    {guidance && (
                        <ArrowTooltip
                            // interactive
                            title={
                                <ObjectiveGuidanceTooltip
                                    setGuidanceOpen={setGuidanceOpen}
                                    guidance={guidance}
                                />
                            }
                            placement="bottom"
                            open={isGuidanceOpen}
                            onClose={() => setGuidanceOpen(false)}
                            leaveDelay={1000}
                            background="#fff"
                            maxWidth="524px"
                            fontSize={16}
                            popperColor="#fff"
                            padding="0"
                            boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.15)"
                        >
                            <GuidanceLink
                                onClick={() => setGuidanceOpen(!isGuidanceOpen)}
                            >
                                <ExploreIcon />

                                <GuidanceText>Objective Guidance</GuidanceText>
                            </GuidanceLink>
                        </ArrowTooltip>
                    )}
                    {compensatingControlEnabled && <ContainerWithMargin>
                       <ToggleSwitch
                            turnedOn={isCompensatingControl}
                            setTurnOn={(e: boolean) => {
                                if (!canEdit) {
                                    return;
                                }
                                handleCompensatingControl(e);
                            }}
                           disabled={capabilityStatus==='COMPLETED' || capabilityStatus==='REVIEW_REQUESTED' ||isActive || !canEdit || !inScope}
                       />
                       <ButtonText>Compensating Control</ButtonText>
                    </ContainerWithMargin>}

                    {!(readOnlyEnabled && isReadOnly) && !isComponentCard &&
                        <NotesOpenLink
                            onClick={() => {
                                handleNotesOpen();
                            }}
                        >
                            <EventNoteIcon />

                            <LinkText>Notes</LinkText>

                            {isNotesOpen ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </NotesOpenLink>
                    }
                </TextContainerWithPadding>

                <ContainerWithPadding>
                    <ScoreSquare
                        background={inScope ? withoutThemeColorResolver(score) : 'repeating-linear-gradient(-45deg, transparent, transparent 4px, grey 7px, grey 7px)'}
                        color={textColorResolver(score, '', '')}
                    >
                        {score !== null && score}
                    </ScoreSquare>
                    {(capabilityStatus === 'REVIEW_REQUESTED' || capabilityStatus === 'COMPLETED') && (user && user.id && CampaignData?.campaign.manager.some(manager => manager.id!== user.id)) ? '' :
                                        <LinkWithMargin
                        onClick={() => {
                            if (!canEdit) {
                                return;
                            }
                            handleResetObjectiveScore();
                        }}
                    >
                        Reset
                    </LinkWithMargin>
                    }
                </ContainerWithPadding>
            </TitleContainer>

            <SliderContainer>
                {((isObjectiveComponentExist&&isComponentCard)||!isObjectiveComponentExist) &&
                    <ObjectivesSlider
                        handleMaturityScoreChange={(moderatedValue: number) => {
                            if (!canEdit) {
                                return;
                            }
                            saveObjectiveScore(moderatedValue);
                        }}
                        value={score || 0}
                        setValue={handleScoreChange}
                        step={0.5}
                        min={0}
                        max={5}
                        marks={marksList}
                        backgroundHeight={'190px'}
                        sliderMargin={'-28px 0 0 4px'}
                        opacity={'.6'}
                        isDisabled={capabilityStatus==='COMPLETED' || capabilityStatus==='REVIEW_REQUESTED' ||isActive || !canEdit || !inScope}
                        renderDescriptions={() => (
                            <ObjectiveDescriptionsContainer
                                descriptionsList={descriptionsList}
                                setIsTooltipOpenIndex={setIsTooltipOpenIndex}
                                tooltipOpenIndex={tooltipOpenIndex}
                                isActive={isActive}
                                scoreMarks={scoreMarks}
                                isDescopeModalOpen={isDescopeModalOpen}
                            />
                        )}
                    />
                }

                {isNotesOpen && (
                    <ObjectiveNotesContainer
                        isActive={isActive}
                        canEdit={canEdit}
                        markdownValue={markdownValue}
                        setMarkdownValue={setMarkdownValue}
                        isMarkdownSaveButtonDisabled={
                            isMarkdownSaveButtonDisabled
                        }
                        updateObjectiveNotesLoading={updateObjectiveNotesLoading}
                        saveMarkdownChanges={saveMarkdownChanges}
                        setDirty={setDirty}
                        isDirty={isDirty}
                    />
                )}
            </SliderContainer>
        </Container>
    );
};

ObjectiveDescriptionCard.defaultProps = {
    isActive: false
};

const SliderContainer = styled(SpaceBetween)`
    width: 75%;
    flex-direction: column;
`;

const TitleContainer = styled(SpaceBetween)`
    position: relative;
    width: 25%;
    padding: 0 20px 0 0;
`;

const NotesOpenLink = styled(Button)`
    margin: 12px 50px 0 0 !important;
    padding: 2px !important;
    text-transform: none !important;
`;

const Container = styled('div')<{ isActive?: boolean; inScope?: boolean }>`
    display: flex;
    border-radius: 2px;
    opacity: ${props => (props.isActive ? '.6' : '')};
    border: ${props => (props.isActive ? 'solid 2px #4588c3' : 'none')};
    flex-direction: row;
    justify-content: flex-start;
    opacity: ${props => (props.inScope ? '' : '.6')};
`;

const InfoContent = styled(Text)`
    display: block;
    padding: 0 0 16px;
`;

const LinkWithMargin = styled(Button)`
    margin-top: 5px !important;
    color: #346FA2 !important;
    font-size: 15px !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    padding: unset !important;
    min-width: unset !important;
`;

const ContainerWithPadding = styled('div')`
    padding-top: 10px;
    margin-left: 10px;
`;

const TextContainerWithPadding = styled('div')`
    flex-grow: 2;
    padding-top: 10px;
    margin-left: 10px;
`;

const EventNoteIcon = styled(EventNote)`
    margin-right: 10px;
    color: #2d2d2d;
`;

const ExploreIcon = styled(Explore)`
    margin-right: 10px;
    color: #2d2d2d;
`;

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)`
    margin-left: 10px;
    color: #2d2d2d;
`;

const KeyboardArrowUpIcon = styled(KeyboardArrowUp)`
    margin-left: 10px;
    color: #2d2d2d;
`;

const LinkText = styled(Flex)`
    font-size: 15px;
    color: #2d2d2d;
    text-decoration: underline;
`;

const GuidanceText = styled(LinkText)`
    :hover {
        color: #de3328;
    }
`;

const GuidanceLink = styled(NotesOpenLink)`
    margin: 0 !important;
`;

const DescriptionText = styled(Text)`
    white-space: pre-wrap;
`;
const ContainerWithMargin = styled(AlignItems)`
    margin-bottom: 5px;
`;
const ButtonText = styled(Text)`
    font-size: 14px;
    font-weight: 100;
`;

export default React.memo(
    ObjectiveDescriptionCard,
    (
        prevProps: ObjectiveDescriptionCardProps,
        nextProps: ObjectiveDescriptionCardProps
    ) => {
        if (
            prevProps.notesStatus === nextProps.notesStatus &&
            prevProps.marksList === nextProps.marksList &&
            prevProps.checkboxName === nextProps.checkboxName &&
            prevProps.isActive === nextProps.isActive &&
            prevProps.score === nextProps.score &&
            prevProps.scoreMarks === nextProps.scoreMarks &&
            prevProps.assessmentId === nextProps.assessmentId &&
            prevProps.domainIndex === nextProps.domainIndex &&
            prevProps.capabilityIndex === nextProps.capabilityIndex &&
            prevProps.objectiveIndex === nextProps.objectiveIndex &&
            prevProps.notes === nextProps.notes &&
            prevProps.idTitle === nextProps.idTitle &&
            prevProps.domainId === nextProps.domainId &&
            prevProps.capabilityId === nextProps.capabilityId &&
            prevProps.objectiveId === nextProps.objectiveId &&
            prevProps.description === nextProps.description &&
            prevProps.descriptionsList === nextProps.descriptionsList &&
            prevProps.canEdit === nextProps.canEdit &&
            prevProps.isDescopeModalOpen === nextProps.isDescopeModalOpen&&
            prevProps.isDirty === nextProps.isDirty
        ) {
            return true;
        }
        return false;
    }
);
