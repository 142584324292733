import React from 'react';
import Modal from 'react-modal';

import {  BenchmarkingInfoModalProps } from './types';
import { customModalStyles } from '../../../components/PopUps/constants';
import { PopUpHeader,ImgContainer } from '../../../components';

import  benchmarkingOne  from '../../../assets/images/graph.png';
import  benchmarkingTwo  from '../../../assets/images/graph2.png';
import  benchmarkingThree  from '../../../assets/images/graph3.png';
import  benchmarkingfour  from '../../../assets/images/graph4.png';
import  benchmarkingfive  from '../../../assets/images/graph5.png';

export const BenchmarkingInfoModal: React.FC<BenchmarkingInfoModalProps> = ({
    isOpenBenchmarkingInfo,
    setIsOpenBenchmarkingInfo
}) => {
    

    return (
        <Modal
            isOpen={isOpenBenchmarkingInfo}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={() => setIsOpenBenchmarkingInfo(false)}
        >
            <PopUpHeader handleClose={() => setIsOpenBenchmarkingInfo(false)} title='Benchmarking Info' />

           <div style={{padding: '35px',maxHeight: '400px'}}>

            <h3>Benchmarking graphs explained</h3>

            <p>Once the peer group has been defined, the system starts by putting the scores into ascending order (
                0 to 5) for each domain and capability.</p>

            <p> <b>Note: </b> The benchmarking database contains the average scores for each campaign. For example, if there are 4 assessments in a campaign,
                 the domain and capability scores will be averaged and sent to the benchmarking database.</p>
            
            <p>If the peer group can’t be split into four even quartiles, the system will increase the number of peers in the first 
                quartile by 1, then do the same for the second and third quartiles as required.</p>

             <p>For example, if the size of the peer group is 35, each quartile would contain the following number of peers:
                <ul style={{marginLeft: '15px',listStyleType:'circle'}}>
                    <li>First quartile (bottom): 9 peers</li>
                    <li>Second quartile: 9 peers</li>
                    <li>Third quartile: 9 peers</li>
                    <li>Fourth quartile (top): 8 peers</li>
                </ul>
                </p> 
                <p>The quartiles are then plotted on a bar graph.
                <ul style={{marginLeft: '15px',listStyleType:'circle'}}>
                    <li>The first quartile will always start at 0.</li>
                    <li>The highest score in each quartile will determine where the quartile ends and the next one starts.</li>
                    <li>See example below, which is based on 20 peers </li>

                    <ImgContainer style={{width:'550px'}} src={benchmarkingOne} alt="image 1" aria-hidden />

                </ul>
                There may be scenarios where the highest value of two quartiles is the same.
                </p>

                <p>For example, in the scenario below, the top value of the first and second quartiles is 1.5. Consequently,
                 the second quartile will be invisible when plotted onto a bar graph because it has a range of 1.5 to 1.5.

                 <ImgContainer  style={{width:'550px'}} src={benchmarkingTwo} alt="image 2" aria-hidden />

                 <p>
                 To ensure the benchmarking bar graphs always display 4 quartiles, the highest score of any ‘invisible’ 
                 quartile is increased by 0.1 to create a ‘tiny quartile’ for visualisation purposes - e.g. 1.5 to 1.5
                  would become 1.5 to 1.6. </p>

                  <ImgContainer style={{width:'550px', height:'450px'}} src={benchmarkingThree} alt="image 3" aria-hidden />

                  </p>
                  
                  <p> <b>Note:</b> The adjustment is for visualisation purposes only. The underlying data is not altered.</p>

                  <p>This is what a ‘tiny quartile’ looks like on the final graphs - 
                    <i>it visually represents a quartile that would otherwise be invisible to users.</i> 

                    <ImgContainer style={{width:'550px'}} src={benchmarkingfour} alt="image 4" aria-hidden />
                 </p>
                
                 <p>
                 Below is an example benchmarking graph from the Connected Risk Engine - 
                 <i>the quartiles for each area are plotted onto a bar graph with the clients score overlaid (the black line).</i> 
                    <ImgContainer style={{width:'550px'}}
                    src={benchmarkingfive} 
                    alt="image 5" aria-hidden />
                 </p>
                 <br/><br/>
           </div>
        </Modal>
    );
};