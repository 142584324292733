import { styled } from '@mui/material/styles';
import Button from './Button';

const ClearButton = styled(Button)`
    && {
        background-color: transparent;
        min-height: 40px;
        font-size: 15px;
        justify-content: flex-start;
        border: none;
        text-decoration: underline;
        margin: 0;
        :hover {
            color: #575757;
            text-decoration: underline;
        }
    }
`;

export default ClearButton;