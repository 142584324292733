import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CustomButton = styled(Button)`
    && {
        background-color: #fff;
        box-sizing: border-box;
        border: solid 1.5px #d9d9d9;
        border-radius: 2px;
        min-height: 48px;
        display: flex;
        align-items: center;
        outline: none;
        text-transform: none;
    }
`;

export default CustomButton;
