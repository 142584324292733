import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DialogBox, EditButton, Text } from '../../components';
import {
    Stakeholder,
    StakeholderData,
    OwnedCampaignEntities,
    StakeholderRow,
    StakeholdersFilteredList
} from './types';

export const useStakeholdersFiltered = ({
    data,
    setClientsModalIsOpen,
    setchosenField,
    filterStatus,
    userMayEdit,
    isEditedData,
    selectedRow
}: {
    data: StakeholderData;
    setClientsModalIsOpen: any;
    setchosenField: any;
    filterStatus: string;
    userMayEdit: any;
    isEditedData: boolean;
    selectedRow: any;
}) => {
    const [stakeholders, setStakeholders] = useState<StakeholderRow[]>([]);
    useEffect(() => {
        const selectedStakeholder = data.stakeholdersByClient.find((m) => m.id === selectedRow[selectedRow.length - 1]);
        if (data.stakeholdersByClient && data.stakeholdersByClient.length) {
            const stakehooldersList = data.stakeholdersByClient
                .filter((stakeholder: Stakeholder) => {
                    if (filterStatus === 'ALL') {
                        return stakeholder;
                    }
                    return filterStatus === stakeholder.status;
                })
                .map((stakeholder: Stakeholder) => {
                    return {
                        firstName: stakeholder.firstName,
                        lastName: stakeholder.lastName,
                        jobTitle: stakeholder.jobTitle,
                        email: stakeholder.email,
                        disable: (stakeholder.status === 'ARCHIVED') ? (selectedStakeholder?.status === 'ACTIVE') ? true : false : selectedStakeholder?.status === 'ARCHIVED' ? true: false ,
                        ownedCampaignEntities: (
                            <Tooltip
                                title={
                                    stakeholder.ownedCampaignEntities.length &&
                                    stakeholder.ownedCampaignEntities.map(
                                        (entity: OwnedCampaignEntities) => (
                                            <div key={entity.id}>
                                                {entity.name}
                                            </div>
                                        )
                                    )
                                }
                                placement="bottom"
                            >
                                <Text>
                                    {stakeholder.ownedCampaignEntities.length}
                                </Text>
                            </Tooltip>
                        ),
                        status: stakeholder.status,
                        edit: userMayEdit() ? (
                            <EditButton
                                callback={() => {
                                    setClientsModalIsOpen(true);
                                    setchosenField(stakeholder);
                                }}
                            />
                        ) : null,
                        id: stakeholder.id
                    };
                });
            setStakeholders(stakehooldersList);
        }
    }, [filterStatus, data, isEditedData,selectedRow]);
    return stakeholders;
};

export const useStakeholdersStatus = (
    data: StakeholderData,
    selectedRow: number[]
) => {
    const [stakeholdersList, setStakeholdersList] = useState<
        StakeholdersFilteredList[]
    >([]);

    useEffect(() => {
        if (data.stakeholdersByClient && data.stakeholdersByClient.length) {
            const filteredList = data.stakeholdersByClient
                .filter((item: Stakeholder) => {
                    return selectedRow.includes(item.id);
                })
                .map((item: Stakeholder) => {
                    return {
                        id: item.id,
                        title: item.firstName
                    };
                });
            setStakeholdersList(filteredList);
        }
    }, [data, selectedRow]);
    return stakeholdersList;
};

export const renderModal = ({
    modalId,
    handleClose,
    isOpen,
    stakeholdersCheckedList,
    setSelectedRow,
    updateStakeholdersStatus
}: {
    isOpen: boolean;
    modalId: string;
    handleClose: () => void;
    stakeholdersCheckedList: StakeholdersFilteredList[];
    setSelectedRow: (data: number[]) => void;
    updateStakeholdersStatus: (data: object) => Promise<object>;
}) => {
    const store = new Map();
    store.set(
        'archive',
        <DialogBox
            title={`Archive ${
                stakeholdersCheckedList.length > 1
                    ? 'Stakeholders'
                    : 'Stakeholder'
            }`}
            buttonCancelText="Cancel"
            buttonSaveText={`Archive ${
                stakeholdersCheckedList.length > 1
                    ? 'stakeholders'
                    : 'stakeholder'
            }`}
            content={`Are you sure you want to archive "${stakeholdersCheckedList
                .map((item: StakeholdersFilteredList) => item.title)
                .join(', ')}" ${
                stakeholdersCheckedList.length > 1
                    ? 'stakeholders'
                    : 'stakeholder'
            }? Note they will be removed as ${stakeholdersCheckedList.length > 1 ? 'stakeholders' : 'stakeholder'} from any campaign they are part of.`}
            open={isOpen}
            handleClose={handleClose}
            handleSubmit={() => {
                stakeholdersCheckedList.length &&
                    updateStakeholdersStatus({
                        variables: {
                            data: {
                                id: stakeholdersCheckedList.map(data=>data.id),
                                status: 'ARCHIVED'
                            }
                        }
                    })
                        .then(() => {
                            setSelectedRow([]);
                            handleClose();
                        })
                        .catch(err => {
                            console.log('Archive stakeholder Error', err);
                        });
            }}

        />
    );
    store.set(
        'active',
        <DialogBox
            title={`UnArchive ${
                stakeholdersCheckedList.length > 1
                    ? 'Stakeholders'
                    : 'Stakeholder'
            }`}
            buttonCancelText="Cancel"
            buttonSaveText={`Unarchive ${
                stakeholdersCheckedList.length > 1
                    ? 'stakeholders'
                    : 'stakeholder'
            }`}
            content={`Are you sure you want to Unarchive "${stakeholdersCheckedList
                .map((item: StakeholdersFilteredList) => item.title)
                .join(', ')}" ${
                stakeholdersCheckedList.length > 1
                    ? 'stakeholders'
                    : 'stakeholder'
            }?`}
            open={isOpen}
            handleClose={handleClose}
            handleSubmit={() => {
                stakeholdersCheckedList.length &&
                    updateStakeholdersStatus({
                        variables: {
                            data: {
                                id: stakeholdersCheckedList.map(data=>data.id),
                                status: 'ACTIVE'
                            }
                        }
                    })
                        .then(() => {
                            setSelectedRow([]);
                            handleClose();
                        })
                        .catch(err => {
                            console.log('Un-Archive stakeholder Error', err);
                        });
            }}

        />
    );
    return store.get(modalId) || null;
};

export const headCells = [
    {
        id: 'firstName',
        numeric: false,
        disablePadding: false,
        label: 'Name'
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: false,
        label: 'Surname'
    },
    {
        id: 'jobTitle',
        numeric: false,
        disablePadding: false,
        label: 'Job Title'
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'Email Address'
    },
    {
        id: 'entity',
        numeric: true,
        disablePadding: false,
        label: '# Entities'
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'status'
    },
    {
        id: 'edit',
        numeric: true,
        disablePadding: false,
        label: 'EDIT',
        style: { textAlign: 'center' },
        notSortable: true
    }
];

export const stakeholderStatus = [
    {
        id: 'ALL',
        label: 'All stakeholders'
    },
    {
        id: 'ARCHIVED',
        label: 'Archived stakeholders'
    },
    {
        id: 'ACTIVE',
        label: 'Active stakeholders'
    }
];
