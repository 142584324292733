import React from 'react';
import {
    DetailsTableHeaderProps,
    DetailsTableRows,
    DetailsFilterOption
} from './types';
import {
    SpaceBetween,
    Column,
    AlignItems,
    SearchField
} from '../../../../components';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { detailsFilterOptions } from './constants';

const DetailsTableHeader: React.FC<DetailsTableHeaderProps> = ({
    setFilteredData,
    notFilteredData,
    filterStatus,
    setFilterStatus,
    setSearch
}) => {
    const searchCallback = (params: string) => {
        if (!params) {
            setFilteredData(notFilteredData);
            return;
        }
        const filteredList = notFilteredData.filter(
            (capability: DetailsTableRows) => {
                return (
                    capability.name.props.children
                        .toLowerCase()
                        .indexOf(params.toLowerCase()) !== -1
                );
            }
        );
        setFilteredData(filteredList);
    };

    const handleSelectCapabilityStatus = (
        event: any
    ) => {
        const target = event.target as HTMLInputElement;
        setFilterStatus(target.value);
    };

    return (
        <HeaderWrapper>
            <SpaceBetween>
                <AlignItems>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={filterStatus}
                        onChange={(e: any)=>handleSelectCapabilityStatus(e)}
                        inputProps={{
                            'aria-label':'select capability status'
                        }}
                    >
                        {detailsFilterOptions.map(
                            (
                                detailsFilterOption: DetailsFilterOption,
                                i: number
                            ) => (
                                <option key={i} value={detailsFilterOption.id}>
                                    {`${detailsFilterOption.label} (${detailsFilterOption.rowCount})`}
                                </option>
                            )
                        )}
                    </CustomSelect>
                </AlignItems>
                <SearchField placeholder="Search" callback={searchCallback} setSearch={setSearch} />
            </SpaceBetween>
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled(Column)`
    width: 100%;
`;

const CustomSelect = styled(Select)`
    .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 30px;
    }
`;


export default DetailsTableHeader;
