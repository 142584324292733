import React from 'react';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import iconNotepad from '../../../../assets/images/icon-notepad.svg';


import {
    ScoreSquare,
    Text,
    AlignItems,
    Flex,
    ArrowTooltip,
    JustifyContentCenter
} from '../../../../components';
import {
    squireColorResolver,
    roundScore,
    textColorResolver,
    isHTML,
    htmlToString
} from '../../../../utils/UtilsHelpers';
import iconListBordo from '../../../../assets/images/icon-list-bordo.svg';
import iconStarBordo from '../../../../assets/images/icon-star-bordo.svg';
import {
    Feedback,
    Recommendation,
    Observation,
    Capability,
    CapabilityWithStatus
} from './types';
import {
    AssessmentMeeting,
    Objective,
    AssessmentData,
    AssessmentDomain
} from '../types';
import { detailsFilterOptions } from './constants';
import DetailsTableRowsTooltip from './DetailsTableRowsTooltip';

type DetailsCapability = {
    name: string;
    id: string;
    averageScore: any;
    status: string;
    inScope: boolean;
    meetings: AssessmentMeeting[];
    moderatedScore: number;
    targetScore: number | null;
    objectives: Objective[];
    feedback: {
        observations: {
            text: string;
        };
        recommendations: {
            text: string;
        }[];
    }[];
    workingNotes: {
        text: string;
    };
};

export const detailsTableRows = (
    capabilities: Capability[],
    openedFeedback: string[],
    feedbackToggler: any,
    detailsTabData: AssessmentData | undefined,
    filterStatus: string,
    currentTheme: string,
    showTargetScore: boolean,
    readOnlyEnabled: boolean,
    isReadOnly: boolean,
    pointOneDecimalEnable?: boolean,
    isObjectiveComponentExist?: boolean
) => {
    let capabilityID: Capability[] ;
    const textHeadId = (headIndex: number) => {
        return  `${headIndex + 1}`;
    };
    const textBodyId = (
        headIndex: number,
        bodyIndex: number,
        symbol: string
    ) => {
        return `${headIndex + 1}.${symbol}${bodyIndex + 1}`;
    };
    const notAdded = (
        <StatusContent>
            <StatusDot />
            <StatusText>Not added yet</StatusText>
        </StatusContent>
    );
    const pendingApproval = (
        <StatusContent>
            <StatusDot />
            <StatusText>Pending approval</StatusText>
        </StatusContent>
    );
    const available = (
        <StatusContent>
            <StatusDot backgroundColor="#2b977b" />
            <StatusText>Available</StatusText>
        </StatusContent>
    );
    const feedbackIsEmpty = (
        <Flex>
            <FeedbackBlock>{notAdded}</FeedbackBlock>
-           <FeedbackBlock paddingLeft="32px">{notAdded}</FeedbackBlock>
        </Flex>
    );

    const feedbackExist = (feedbacks: Feedback[], pending: boolean) => (
        <Flex>
            <FeedbackBlock>
                { pending ? pendingApproval : (feedbacks.some(
                    (feedback: Feedback) =>
                        feedback.observations.length &&
                        feedback.observations.some(obs => obs.text.length)
                )
                    ? available
                    : notAdded)}
            </FeedbackBlock>
            <FeedbackBlock paddingLeft="32px">
                { pending ? pendingApproval : (feedbacks.some(
                    (feedback: Feedback) =>
                        feedback.recommendations.length &&
                        feedback.recommendations.some(rec => rec.text.length)
                )
                    ? available
                    : notAdded)}
            </FeedbackBlock>
        </Flex>
    );
    const createFeedbackBlock = (
        feedback: Feedback,
        feedbackIndex: number,
        feedbackParam: 'observations' | 'recommendations',
        icon: number,
        textBodyParam: string
    ) => {

        const parseText = (text: string): string => {
            if (isHTML(text)) {
                return htmlToString(text, true);
            }
            return text;
        };

        return (
            <div key={feedback.id + feedbackIndex}>
                {feedback[feedbackParam].length
                    ? feedback[feedbackParam].map(
                          (
                              currentFeedback: Observation | Recommendation,
                              currentFeedbackIndex: number
                          ) =>
                              currentFeedback.text && (
                                  <div
                                      key={
                                          feedback.id +
                                          feedbackIndex +
                                          currentFeedbackIndex
                                      }
                                  >
                                      <AlignItems>
                                          <LeftIcon
                                              alt="icon"
                                              src={
                                                  icon === 1
                                                      ? iconListBordo
                                                      : iconStarBordo
                                              }
                                          />
                                          <IdTextBody>
                                              {capabilityID[0] && capabilityID[0].domainShortName + '-' + capabilityID[0].shortName + '.' +textBodyId(
                                                  feedbackIndex,
                                                  currentFeedbackIndex,
                                                  textBodyParam
                                              )}
                                          </IdTextBody>
                                      </AlignItems>

                                      <FeedbackText>
                                          {parseText(currentFeedback.text)}
                                      </FeedbackText>
                                  </div>
                              )
                      )
                    : notAdded}
            </div>
        );
    };

    const capabilitiesWithStatuses: CapabilityWithStatus[] = [];
    let iterationCount = 0;
    if (capabilities.length) {
        detailsFilterOptions.forEach(
            filterOption => (filterOption.rowCount = 0)
        );

        if (detailsTabData) {
            detailsTabData.assessment.state.domains.forEach(
                (domain: AssessmentDomain) => {
                    domain.capabilities.forEach(
                        (capability: DetailsCapability) => {
                           const modifiedCapability = {...capability};
                            if (!capability.inScope) {
                                modifiedCapability.status = 'OUT_OF_SCOPE';
                            } 
                            else if (
                                capability.status === 'NOT_STARTED' ||
                                capability.status === 'IN_PROGRESS'
                            ) {
                                capability.workingNotes === null
                                    ? (modifiedCapability.status = 'NOT_SCORED')
                                    : (modifiedCapability.status =
                                          'NOT_SCORED_WITH_NOTES');
                            }
                            capability= {...modifiedCapability};
                            for (const filterOption of detailsFilterOptions) {
                                if (filterOption.id === capability.status) {
                                    filterOption.rowCount++;
                                }
                            }

                            capabilitiesWithStatuses.push({
                                id: capability.id,
                                name: capability.name,
                                averageScore: (readOnlyEnabled && isReadOnly && capability.status !== 'COMPLETED') ? null : capability.averageScore,
                                moderatedScore: (readOnlyEnabled && isReadOnly && capability.status !== 'COMPLETED') ? null : capability.moderatedScore,
                                targetScore: capability.targetScore,
                                feedback: capabilities[iterationCount].feedback,
                                status: capability.status
                            });
                            iterationCount++;
                        }
                    );
                }
            );
        }
    }

    detailsFilterOptions[0].rowCount = iterationCount;
    return capabilitiesWithStatuses
        .filter((capability: CapabilityWithStatus) =>
            filterStatus === 'ALL'
                ? capability
                : capability.status === filterStatus
        )
        .map((capability: CapabilityWithStatus) => {
             capabilityID = capabilities.filter((c) => c.id === capability.id);
            const isVisibleByReadOnly=(!(readOnlyEnabled && isReadOnly) ||(readOnlyEnabled && isReadOnly && capability.status === 'COMPLETED'));
            const capabilityToReturn = {
                name: <CapabilityName>{capability.name}</CapabilityName>,
                averageScore:
                    capability.status === 'NOT_SCORED_WITH_NOTES' &&
                    !capability.averageScore ? (
                        <Block
                            style={{
                                backgroundColor: '#fff',
                                margin: '0px auto'
                            }}
                        >
                            <img
                                src={iconNotepad}
                                style={{ maxHeight: '30px' }}
                                alt="capability notes icon"
                            />
                        </Block>
                    ) : (
                        <ScoreSquare
                            style={{ margin: '0 auto' }}
                            background={
                                capability.status === 'OUT_OF_SCOPE'
                                    ? 'repeating-linear-gradient(-45deg, transparent, transparent 8px, grey 11px, grey 7px)'
                                    : squireColorResolver(
                                          capability.averageScore &&
                                              roundScore(
                                                  capability.averageScore,
                                                  pointOneDecimalEnable
                                              ),
                                          capability.status,
                                          currentTheme,
                                          showTargetScore,
                                          capability.targetScore,
                                          readOnlyEnabled,
                                          isReadOnly
                                      )
                            }
                            color={
                                readOnlyEnabled &&
                                isReadOnly &&
                                capability.status !== 'COMPLETED'
                                    ? '#fff'
                                    : textColorResolver(
                                          capability.averageScore &&
                                              roundScore(
                                                  capability.averageScore,
                                                  pointOneDecimalEnable
                                              ),
                                          capability.status,
                                          currentTheme,
                                          showTargetScore
                                      )
                            }
                        >
                            {capability.status === 'OUT_OF_SCOPE' ||
                            (readOnlyEnabled &&
                                isReadOnly &&
                                capability.status !== 'COMPLETED')
                                ? ''
                                : capability.averageScore &&
                                  roundScore(
                                      capability.averageScore,
                                      pointOneDecimalEnable
                                  )}
                        </ScoreSquare>
                    ),
                moderatedScore: !isObjectiveComponentExist ? (
                    <ArrowTooltip
                        top={-4}
                        minWidth="100px"
                        maxWidth="300px"
                        // interactive
                        title={
                            <DetailsTableRowsTooltip capability={capability} />
                        }
                        placement="bottom"
                    >
                        <HoverScoreSquare
                            tabIndex={0}
                            style={{ margin: '0 auto' }}
                            background={
                                capability.status === 'OUT_OF_SCOPE'
                                    ? 'repeating-linear-gradient(-45deg, transparent, transparent 8px, grey 11px, grey 7px)'
                                    : squireColorResolver(
                                          capability.moderatedScore,
                                          capability.status,
                                          currentTheme,
                                          showTargetScore,
                                          capability.targetScore,
                                          readOnlyEnabled,
                                          isReadOnly
                                      )
                            }
                            color={textColorResolver(
                                capability.moderatedScore,
                                capability.status,
                                currentTheme,
                                showTargetScore
                            )}
                        >
                            {(readOnlyEnabled &&
                            isReadOnly &&
                            capability.status !== 'COMPLETED' || capability.status === 'OUT_OF_SCOPE')
                                ? ''
                                : capability.moderatedScore}
                        </HoverScoreSquare>
                    </ArrowTooltip>
                ):undefined,
                targetScore: showTargetScore ? (
                    <ArrowTooltip
                        top={-4}
                        minWidth="100px"
                        maxWidth="300px"
                        // interactive
                        title={
                            <DetailsTableRowsTooltip capability={capability} />
                        }
                        placement="bottom"
                    >
                        <HoverScoreSquare
                            tabIndex={0}
                            style={{ margin: '0 auto' }}
                            background={
                                capability.status === 'OUT_OF_SCOPE'
                                    ? 'repeating-linear-gradient(-45deg, transparent, transparent 8px, grey 11px, grey 7px)'
                                    : 'white'
                            }
                        >
                            {!(capability.status === 'OUT_OF_SCOPE') &&
                                (capability.targetScore && isVisibleByReadOnly
                                    ? capability.targetScore
                                    : '-')}
                        </HoverScoreSquare>
                    </ArrowTooltip>
                ) : undefined,
                feedback: capability.feedback.length ? (
                    <div>
                        <Collapse
                            in={
                                !openedFeedback.some(
                                    (capabilityId) =>
                                        capabilityId === capability.id
                                )
                            }
                            timeout="auto"
                            unmountOnExit
                        >
                            {feedbackExist(
                                capability.feedback,
                                readOnlyEnabled &&
                                    isReadOnly &&
                                    capability.status !== 'COMPLETED'
                            )}
                        </Collapse>
                        <Collapse
                            in={openedFeedback.some(
                                (capabilityId) => capabilityId === capability.id
                            )}
                            timeout="auto"
                            unmountOnExit
                        >
                            {capability.feedback.map(
                                (
                                    feedbackObject: Feedback,
                                    feedbackIndex: number
                                ) =>
                                    (feedbackObject.observations.some(
                                        (obs) => obs.text.length
                                    ) ||
                                        feedbackObject.recommendations.some(
                                            (rec) => rec.text.length
                                        )) && (
                                        <FeedbackSection
                                            key={feedbackObject.id}
                                        >
                                            <div>
                                                <IdTitle>
                                                    <IdTextHead>
                                                        {capabilityID[0] && capabilityID[0].domainShortName + '-' + capabilityID[0].shortName + '.' + textHeadId(
                                                            feedbackIndex
                                                        )}
                                                    </IdTextHead>
                                                    <SectionTitle>
                                                        {feedbackObject.title}
                                                    </SectionTitle>
                                                </IdTitle>
                                            </div>
                                            <Flex>
                                                <FeedbackBlock>
                                                    {createFeedbackBlock(
                                                        feedbackObject,
                                                        feedbackIndex,
                                                        'observations',
                                                        1,
                                                        'O'
                                                    )}
                                                </FeedbackBlock>
                                                <FeedbackBlock paddingLeft="32px">
                                                    {createFeedbackBlock(
                                                        feedbackObject,
                                                        feedbackIndex,
                                                        'recommendations',
                                                        2,
                                                        'R'
                                                    )}
                                                </FeedbackBlock>
                                            </Flex>
                                        </FeedbackSection>
                                    )
                            )}
                        </Collapse>
                    </div>
                ) : (
                    <Flex>
                        <FeedbackBlock>
                            {readOnlyEnabled &&
                            isReadOnly &&
                            capability.status !== 'COMPLETED'
                                ? pendingApproval
                                : notAdded}
                        </FeedbackBlock>
                        <FeedbackBlock paddingLeft="32px">
                            {readOnlyEnabled &&
                            isReadOnly &&
                            capability.status !== 'COMPLETED'
                                ? pendingApproval
                                : notAdded}
                        </FeedbackBlock>
                    </Flex>
                ),

                expandAll: (
                    <div style={{ textAlign: 'center' }}>
                        <button
                            style={{
                                transition: 'transform .5s',
                                width: '32px',
                                height: '32px',
                                transform: openedFeedback.some(
                                    (capabilityId) =>
                                        capabilityId === capability.id
                                )
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                            disabled={
                                !capability.feedback.length ||
                                (readOnlyEnabled &&
                                    isReadOnly &&
                                    capability.status !== 'COMPLETED')
                            }
                            onClick={() => feedbackToggler(capability.id)}
                        >
                            {' '}
                            ▼{' '}
                        </button>{' '}
                    </div>
                )
            };
            if(!capabilityToReturn.targetScore) delete capabilityToReturn.targetScore;
            if(!capabilityToReturn.moderatedScore) delete capabilityToReturn.moderatedScore;
            return capabilityToReturn;
        });
};

const CapabilityName = styled(Text)`
    color: #575757;
    font-size: 17px;
`;

const HoverScoreSquare = styled(ScoreSquare)`
    :hover {
        border-radius: 2px;
        border: 2px solid #ffffff;
        box-shadow: 0 0 0 2pt #2d2d2d;
    }
`;

const IdTextHead = styled(Text)`
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #818181;
`;

const IdTitle = styled('div')`
    margin-bottom: 16px;
`;

const SectionTitle = styled(Text)`
    margin-left: 8px;
    font-size: 20px;
    color: #575757;
`;

const Block = styled(JustifyContentCenter)`
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 0.3vw;
    margin-left: 2.9vw;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
`;
const FeedbackSection = styled('div')`
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
        border-bottom: none;
    }
`;

const FeedbackBlock = styled('div')<{ paddingLeft?: string }>`
    width: 50%;
    padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 0)};
`;

const IdTextBody = styled(Text)`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #828282;
`;

const FeedbackText = styled(Text)`
    font-size: 16px;
    color: #4f4f4f;
`;

const LeftIcon = styled('img')`
    width: 20px;
    height: 100%;
    margin-right: 8px;
`;

const StatusDot = styled('div')<{ backgroundColor?: string }>`
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : '#dedede'};
`;

const StatusContent = styled('div')`
    display: flex;
    align-items: center;
`;

const StatusText = styled(Text)`
    margin-left: 12px;
    font-size: 16px;
    color: #575757;
`;
