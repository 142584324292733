import React, { useState,  useContext } from 'react';
import { UserStore } from '../../../../context/user-store';
import Router from '../../../../routes/router';
import AssessmentsMaturityOverviewFirstTabHorizontal from './Components/AssessmentsMaturityOverviewFirstTabHorizontal';
import AssessmentsMaturityOverviewFirstTabVertical from './Components/AssessmentsMaturityOverviewFirstTabVertical';
import Legend from '../../MaturityOverview/Legend';
import { AssessmentData } from '../types';

import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { isReadOnlyEnabled } from '../../../../utils';

type Props = {
    data?: AssessmentData;
    campaignId: string;
    assessmentId: string;
    showTargetScore: boolean;
    showCompensationControl: boolean;
    isLegendVisible: boolean;
    setIsLegendVisible: any;
    view: string;
    targetScoreEnabled: boolean;
    pointOneDecimalEnable: boolean;
    campaignIsClosed: boolean;
    isReadOnly?: boolean;
    isObjectiveComponentExist: boolean;
    handleOnSaveWeighting: any;
    isAssessmentWeightingError: boolean;
    isAssessmentWeightingSuccess: boolean;
    setbalanceWeightSuccessMessage: any;
    isbalanceWeightSuccessMessage: boolean;
    setIsAssessmentWeightingModal: any;
    isAssessmentWeightingModal: boolean;
    compensatingControlEnabled: boolean;
};

const DESCOPE_ASSESSMENT_CAPABILITY = gql`
    mutation updateAssessmentCapabilitiesScope2(
        $data: UpdateAssessmentCapabilitiesScopeInput!
    ) {
        updateAssessmentCapabilitiesScope(data: $data) {
            id
            state {
                domains {
                    id
                    capabilities {
                        id
                        inScope
                    }
                }
            }
        }
    }
`;

const AssessmentsMaturityOverviewFirstTab: React.FC<Props> = ({
    data,
    campaignId,
    assessmentId,
    showTargetScore,
    showCompensationControl,
    isLegendVisible,
    setIsLegendVisible,
    view,
    targetScoreEnabled,
    pointOneDecimalEnable,
    campaignIsClosed,
    isReadOnly,
    isObjectiveComponentExist,
    handleOnSaveWeighting,
    isAssessmentWeightingError,
    isAssessmentWeightingSuccess,
    setbalanceWeightSuccessMessage,
    isbalanceWeightSuccessMessage,
    setIsAssessmentWeightingModal,
    isAssessmentWeightingModal,
    compensatingControlEnabled
}) => {
    const [descopeAssessmentCapability] = useMutation(DESCOPE_ASSESSMENT_CAPABILITY);
    const [isReasonExist, setIsReasonExist] = useState<boolean>(false);

    const {
        state: { client, user, globalSettings }
    } = useContext(UserStore);

    let campaign_closed_message = 'This campaign is closed - view only access.';

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    if (readOnlyEnabled && !campaignIsClosed && isReadOnly) {
        //BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
        campaign_closed_message = 'You have read-only access to this campaign.';
    }



    const handleCapabilityRescope = ({
        setIsModalOpen,
        // tslint:disable-next-line: no-shadowed-variable
        assessmentId,
        domainId,
        capabilityId,
        updateScopeReason
    }: {
        assessmentId: string;
        domainId: string;
        capabilityId: string;
        updateScopeReason: string;
        setIsModalOpen: any;
    }) => {
        setIsReasonExist(false);
        if (!updateScopeReason) {
            setIsReasonExist(true);
            return;
        }

        descopeAssessmentCapability({
            variables: {
                data: {
                    assessmentId,
                    domainId,
                    capabilityIds: [capabilityId],
                    inScope: true,
                    updateScopeReason
                }
            }
        })
            .then(() => {
                setIsModalOpen(false);
                Router.goToMaturityScore({
                    campaignId,
                    assessmentId,
                    domainId,
                    capabilityId
                });
            })
            .catch(err => {
                console.log('err', err);
            });
    };

    return (
        <div>
            {view === 'vertical' ? (
                <AssessmentsMaturityOverviewFirstTabVertical
                    data={data}
                    campaignId={campaignId}
                    assessmentId={assessmentId}
                    handleCapabilityRescope={handleCapabilityRescope}
                    isTargetScoring={showTargetScore}
                    targetScoreEnabled={targetScoreEnabled}
                    showCompensationControl={showCompensationControl}
                    readOnlyEnabled={readOnlyEnabled}
                    isReadOnly={isReadOnly}
                    pointOneDecimalEnable={pointOneDecimalEnable}
                    isObjectiveComponentExist={isObjectiveComponentExist}
                    handleOnSaveWeighting={handleOnSaveWeighting}
                    isAssessmentWeightingError={isAssessmentWeightingError}
                    isAssessmentWeightingSuccess={isAssessmentWeightingSuccess}
                    isReasonExist={isReasonExist}
                    setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                    isbalanceWeightSuccessMessage={isbalanceWeightSuccessMessage}
                    setIsAssessmentWeightingModal={setIsAssessmentWeightingModal}
                    isAssessmentWeightingModal={isAssessmentWeightingModal}
                    compensatingControlEnabled={compensatingControlEnabled}
                />
            ) : (
                <AssessmentsMaturityOverviewFirstTabHorizontal
                    handleCapabilityRescope={handleCapabilityRescope}
                    data={data}
                    campaignId={campaignId}
                    assessmentId={assessmentId}
                    isTargetScoring={showTargetScore}
                    targetScoreEnabled={targetScoreEnabled}
                    showCompensationControl={showCompensationControl}
                    readOnlyEnabled={readOnlyEnabled}
                    isReadOnly={isReadOnly}
                    pointOneDecimalEnable={pointOneDecimalEnable}
                    isObjectiveComponentExist={isObjectiveComponentExist}
                    handleOnSaveWeighting={handleOnSaveWeighting}
                    isAssessmentWeightingError={isAssessmentWeightingError}
                    isAssessmentWeightingSuccess={isAssessmentWeightingSuccess}
                    isReasonExist={isReasonExist}
                    setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                    isbalanceWeightSuccessMessage={isbalanceWeightSuccessMessage}
                    setIsAssessmentWeightingModal={setIsAssessmentWeightingModal}
                    isAssessmentWeightingModal={isAssessmentWeightingModal}
                    compensatingControlEnabled={compensatingControlEnabled}
                />
            )}
            {isLegendVisible && (
                <Legend
                    setIsLegendVisible={setIsLegendVisible}
                    isTargetScoring={showTargetScore}
                    isReadOnly={isReadOnly}
                    isObjectiveComponentExist={isObjectiveComponentExist}
                />
            )}
        </div>
    );
};

export default AssessmentsMaturityOverviewFirstTab;
