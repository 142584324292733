import React, { useEffect, useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import CampaignStatus from './CampaignStatus';
import CampaignCard from './CampaignCard';
import Router from '../../routes/router';
import { Flex, Column } from '../../components/base/Layout';
import { DashboardProps } from './types';
import { Text, SpaceBetween, DropdownMultipleSelection, SpinnerWithOverlay } from '../../components';
import { UserStore } from '../../context/user-store';
import { getLocalStorageItem } from '../../utils/local-storage';
import { CampaignData, Campaign } from './types';
import {  Button } from '@mui/material';
import Toast from '../../components/Toast';
import { isReadOnlyEnabled, privacyBannerMessage, CampaignUserRoleEnum, CampaignStatusEnum, PermissionTypeEnum } from '../../utils';
import iconFilter from '../../assets/images/filter-icon.svg';
import { AssessmentDropdownOption } from '../Analyse/Benchmarking/types';
import { useRefreshState } from '../Main/Main';
import OneTrustConsent from '../Cookies/OneTrustConsent';

const CAMPAIGNS = gql`
    query availableCampaignsByClient1($clientId: Int!, $status : [String!], $roles : [String!], $frameworkIds : [Int!]) {
        availableCampaignsByClient(clientId: $clientId, status : $status, roles : $roles, frameworkIds : $frameworkIds) {
            id
            name: title
            description
            status
            progress
            readOnly
            assessmentsCnt
            updatedAt
            framework {
                id
                definition {
                    name
                }
            }
            members {
                id
                firstName
                lastName
            }
            readonlymembers {
                id
                firstName
                lastName
            }
            manager {
                id
                firstName
                lastName
            }
            closedAt
            pointOneDecimalEnable
            isCampaignEntityUser
        }
    }
`;

const DROPDOWN_VALUES_BY_COMPANY = gql`
    query dropdownValuesByCompany($clientId: Int!) {
        frameworksByCompany(clientId: $clientId) {
            value: id
            definition {
                name
            }
        }
    }
`;

type Option = {
    id: string;
    name: string;
    __typename?: string;
}

const Dashboard: React.FC = () => {

    const { setRefreshState } = useRefreshState();
    const {
        state: { user, client, globalSettings },
        dispatch
    } = useContext(UserStore);

    const [isStatusMenuOpen, setStatusMenuOpen] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<AssessmentDropdownOption[]>([]);

    const [isRolesMenuOpen, setRolesMenuOpen] = useState<boolean>(false);
    const [selectedRoles, setSelectedRoles] = useState<AssessmentDropdownOption[]>([]);

    const [isFrameworksMenuOpen, setFrameworksMenuOpen] = useState<boolean>(false);
    const [selectedFrameworks, setSelectedFrameworks] = useState<AssessmentDropdownOption[]>([]);

    const statusOptions: Option[] = [{
            id: CampaignStatusEnum.NOT_STARTED,
            name: 'Not started'
        }, {
            id: CampaignStatusEnum.ACTIVE,
            name: 'In progress'
        }, {
            id: CampaignStatusEnum.COMPLETED,
            name: 'Closed Complete'
        }, {
            id: CampaignStatusEnum.CLOSED_INCOMPLETE,
            name: 'Closed InComplete'
        }
    ];
    const roleOptions: Option[] = [{
            id: CampaignUserRoleEnum.MANAGER,
            name: 'Campaign manager'
        }, {
            id: CampaignUserRoleEnum.MEMBER,
            name: 'Campaign full member'
        }, {
            id: CampaignUserRoleEnum.READONLY,
            name: 'Campaign read-only member'
        },
        {
            id: PermissionTypeEnum.FULL_MEMBER_ACCESS,
            name: 'Assessment full member',
        },
        {
            id: PermissionTypeEnum.READ_ONLY_ACCESS,
            name: 'Assessment read-only member',
        },
    ];
    const [frameworkOptions, setFrameworkOptions] = useState<Option[]>([]);

    const [availableCampaigns, setAvailableCampaigns] = useState<Campaign[]>([]);
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [openFilterOption, setOpenFilterOption] = useState<boolean>(false);

    // Queries
    const [getCampaign, { data, loading }] = useLazyQuery<CampaignData>(CAMPAIGNS);
    const frameworkData = useQuery<any>(DROPDOWN_VALUES_BY_COMPANY, {
        variables: {
            clientId: (client && client.id)
        }
    });

    const getCampaignData = () => {
        getCampaign({
            variables: {
                clientId: (client && client.id),
                status: selectedStatus.map(m => (m.id)),
                roles: selectedRoles.map(m => (m.id)),
                frameworkIds: selectedFrameworks.map(m => (m.id))
            }
        });
    };
    useEffect(() => {
        if (
            data &&
            data.availableCampaignsByClient
        ) {
            const sortedCampaignList = data.availableCampaignsByClient.slice().sort(
                (a, b) => (a.updatedAt < b.updatedAt ? 1 : -1)
            );
            setAvailableCampaigns(sortedCampaignList);
        }
    }, [data]);

    useEffect(() => {
        const frameworks = frameworkData && frameworkData.data && frameworkData.data.frameworksByCompany;
        if (frameworks && frameworks.length > 0) {
            setFrameworkOptions(frameworks.map((f: any) => {
                return {
                    id: f.value,
                    name: f.definition.name
                };
            }));
            getCampaignData();
        }
    }, [frameworkData]);

    useEffect(() => {
        if (!user.isBannerDisplayedDashboard) {
            setOpenToast(true);
            user.isBannerDisplayedDashboard = true;
            dispatch({ type: 'SET_USER', value: user });
        }
    }, []);

    useEffect(() => {
        getCampaignData();
    }, [
        selectedStatus,
        selectedRoles,
        selectedFrameworks
    ]);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const selectPlaceholder = (
        option: string[],
        currentData: any,
        label: string,
        dropdownOption?: AssessmentDropdownOption[]
    ) => {
        const getName = (currentId: string) => {
            if (label !== 'region/country') {
                const currentLabelLocal = currentData.find(
                    (elem: any) =>
                        elem.name === currentId || elem.id === currentId
                );
                return currentLabelLocal ? currentLabelLocal.name : '';
            }
            const currentLabel = currentData.find(
                (elem: any) => elem.country === currentId
            );
            return currentLabel ? currentLabel.country : '';
        };
        if (dropdownOption) {
            return dropdownOption.length
                ? dropdownOption.length > 1
                    ? `${dropdownOption.length} selected`
                    : getName(dropdownOption[0].name)
                : `Select ${label}`;
        }
        return option.length
            ? option.length > 1
                ? `${option.length} selected`
                : getName(option[0])
            : `Select ${label}`;
    };

    return (
        <div>
            <OneTrustConsent/>
            <Toast
                open={openToast}
                autoHideDuration={10000}
                title={privacyBannerMessage}
                closeToast={()=>setOpenToast(false)}
                icon={'info'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            />
            {user && user.clients && user.clients.length !== 0 && (
                <>
                    <CampaignStatus
                        statusData={availableCampaigns}
                        user={user}
                    />
                    {loading ? (
                        <SpinnerWithOverlay />
                    ) : (
                        <>
                            {!!availableCampaigns.length && (
                                <>
                                    <CampaignHeader>
                                        <CampaignStatusHeading>
                                            My campaigns
                                        </CampaignStatusHeading>
                                            <FilterButton onClick={() => {
                                                setOpenFilterOption(!openFilterOption);
                                            }}>
                                                <IconWrapper src={iconFilter} alt="filter campaign icon" aria-hidden={true}/>
                                                Filter
                                            </FilterButton>
                                    </CampaignHeader>
                                </>
                            )}
                            <>
                                <SpaceBetween style={{
                                    width: '100%',
                                    display: openFilterOption ? 'flex' : 'none',
                                    justifyContent: 'start',
                                    marginTop: 15,
                                    marginBottom: 15
                                }}>
                                    <ColumnWithMargin>
                                        <HeaderFilterHeading>Status</HeaderFilterHeading>
                                        <DropdownMultipleSelection
                                            selected={selectedStatus}
                                            setSelected={setSelectedStatus}
                                            isOpen={isStatusMenuOpen}
                                            setOpen={setStatusMenuOpen}
                                            multiselect
                                            listOfOptions={statusOptions}
                                            placeholder={selectPlaceholder(
                                                [],
                                                statusOptions,
                                                'Status',
                                                selectedStatus
                                            )}
                                            selectAllText={'Select All'}
                                        />
                                    </ColumnWithMargin>
                                    <ColumnWithMargin>
                                        <HeaderFilterHeading>Roles</HeaderFilterHeading>
                                        <DropdownMultipleSelection
                                            selected={selectedRoles}
                                            setSelected={setSelectedRoles}
                                            isOpen={isRolesMenuOpen}
                                            setOpen={setRolesMenuOpen}
                                            multiselect
                                            listOfOptions={roleOptions}
                                            placeholder={selectPlaceholder(
                                                [],
                                                roleOptions,
                                                'Roles',
                                                selectedRoles
                                            )}
                                            selectAllText={'Select All'}
                                        />
                                    </ColumnWithMargin>
                                    <ColumnWithMargin>
                                        <HeaderFilterHeading>Frameworks</HeaderFilterHeading>
                                        <DropdownMultipleSelection
                                            selected={selectedFrameworks}
                                            setSelected={setSelectedFrameworks}
                                            isOpen={isFrameworksMenuOpen}
                                            setOpen={setFrameworksMenuOpen}
                                            multiselect
                                            listOfOptions={frameworkOptions}
                                            placeholder={selectPlaceholder(
                                                [],
                                                frameworkOptions,
                                                'Frameworks',
                                                selectedFrameworks
                                            )}
                                            selectAllText={'Select All'}
                                        />
                                    </ColumnWithMargin>
                                </SpaceBetween>
                            </>
                            <CampaignCardsContainer>
                                {availableCampaigns.map(
                                    (el: Campaign, i: number) =>
                                        (!readOnlyEnabled&&el.readOnly)?null:
                                     <CampaignCard setRefreshState={setRefreshState} cardData={el} key={i}/>
                                )}
                            </CampaignCardsContainer>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const CampaignStatusHeading = styled(Text)`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #575757;
`;

const CampaignCardsContainer = styled(Flex)`
    margin-top: 24px;
    flex-flow: row wrap;
    margin-right: -24px;
`;

const CampaignHeader = styled('div')`
    justify-content: space-between;
`;

const IconWrapper = styled('img')`
    padding: 0 12px 0 8px;
    cursor: pointer;
`;

const CampaignFilterContainer = styled(Flex)`
    margin-top: 15px;
`;

const ColumnWithMargin = styled(Column)`
    margin: 0 5px;
`;

const HeaderFilterHeading = styled(Text)`
    letter-spacing: 0.5px;
    color: #575757;
    margin-bottom: 8px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FilterButton = styled(Button)`
    color: #575757;
`;

export default Dashboard;
