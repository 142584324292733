import { styled } from '@mui/material/styles';

const LinkExternal = styled('a')`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    width: fit-content;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: none;
    :hover {
        cursor: pointer;
        border-bottom: 2px solid #999999;
        margin-bottom: 20px;
    }
`;

export default LinkExternal;

