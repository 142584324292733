import React, { useState } from 'react';

type Props = {
    color: string;
    backgroundColor: string;
    borderColor: string;
    selected: boolean;
};

const IconMaturity4: React.FC<Props> = ({
    color,
    backgroundColor,
    borderColor,
    selected
}) => {
    const [hovered, isHovered] = useState(false);
    return (
        <svg
            onMouseEnter={() => isHovered(true)}
            onMouseLeave={() => isHovered(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <g clipPath="url(#a)">
                <path
                    fill={selected ? backgroundColor : '#fff'}
                    stroke={selected ? borderColor : '#D7D8DA'}
                    strokeWidth="0.5"
                    d="M39.75 38A1.75 1.75 0 0138 39.75H.25V.25H38c.967 0 1.75.784 1.75 1.75v36z"
                />
                <circle
                    cx="20"
                    cy="20"
                    r="9.5"
                    fill="#fff"
                    stroke={hovered || selected ? color : '#D9D9D9'}
                />
                <path
                    fill={hovered || selected ? color : '#D9D9D9'}
                    d="M23 14h-6V8h6zm0 18h-6v-6h6zm9-9h-6v-6h6zm-18 0H8v-6h6z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill="#fff"
                        d="M0 0h40v40H0z"
                        transform="rotate(-180 20 20)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconMaturity4;
