import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import {
    AlignItems,
    Column,
    EditButton,
    EditButtonRed,
    Link,
    Text
} from '../../../../components';
import { Capability, Stakeholder, Meeting } from './types';

export const headCells = [
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Title',
        sortLabel: true
    },
    {
        id: 'capabilities',
        numeric: false,
        disablePadding: false,
        label: 'Capabilities covered',
        sortLabel: true
    },
    {
        id: 'attendees',
        numeric: false,
        disablePadding: false,
        label: 'Attendees'
    },
    {
        id: 'meetingDate',
        numeric: true,
        disablePadding: false,
        label: 'Date',
        sortLabel: true
    },
    {
        id: 'notes',
        numeric: false,
        disablePadding: false,
        label: 'Notes',
        style: {
            maxWidth: '100px',
            width: '22%'
        }
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'edit',
        numeric: true,
        disablePadding: false,
        label: 'Edit'
    }
];

export const INITIAL_VALUES = {
    archivedDate: null,
    creator: '',
    capabilities: [],
    meetingDate: new Date(),
    stakeholders: [],
    users: [],
    notes: '',
    title: ''
};

const parseMeetingText = (text: string) => {
    const textWithSpaces = text.replace(/&nbsp;/g, ' ');
    return textWithSpaces.length > 100
        ? textWithSpaces
              .replace(/<\/?[^>]+>/g, '')
              .slice(0, 100)
              .concat('...')
        : textWithSpaces.replace(/<\/?[^>]+>/g, '');
};

const fillNamesOfCapabilities = (array: Capability[]) => {
    return array.reduce((capabilities, capability) => {
        return capabilities.concat(`${capability.name}, `);
    }, '');
};

export const fillAssessmentMeetings = (
    data: Meeting[],
    stakeholders: Stakeholder[],
    setEditMeetingModalIsOpen: any,
    setChosenField: any,
    setDoArchive: any,
    isRed: boolean,
    setSelectedRow: any,
    setIsShowAllCapabilities: any,
    isShowAllCapabilities: string[],
    canEdit = true
) => {
    const callbackTemplate = (index: number, meeting: Meeting) => {
        if (!canEdit) {
            return;
        }
        setEditMeetingModalIsOpen(true);
        setChosenField(data[index]);
        // tslint:disable-next-line: no-redundant-boolean
        setDoArchive(meeting.archivedDate ? false : true);
    };

    const handleShowMore = (id: string) => {
        setIsShowAllCapabilities(isShowAllCapabilities.concat(id));
    };

    const handleShowLess = (id: string) => {
        const index = isShowAllCapabilities.findIndex(
            (item: string) => id === item
        );
        if (index >= 0) {
            const result = [...isShowAllCapabilities];
            result.splice(index, 1).concat();
            setIsShowAllCapabilities(result);
        }
    };

    return data.map((meeting: Meeting, index: number) => {
        return {
            id: meeting.id,
            disable: meeting.disable,
            title: (
                <TitleTextStakeholders onClick={() => callbackTemplate(index, meeting)}>
                    {meeting.title}
                </TitleTextStakeholders>
            ),
            capabilities: (
                <CapabilitiesWrap>
                    <Text>
                        {meeting.capabilities.length < 4 ? fillNamesOfCapabilities(meeting.capabilities)
                            : fillNamesOfCapabilities(meeting.capabilities.slice(0, 4))}
                    </Text>
                    {meeting.capabilities.length > 4 &&
                        (isShowAllCapabilities.includes(meeting.id) ? (
                            <>
                                <Text>
                                    {fillNamesOfCapabilities(meeting.capabilities.slice(4,meeting.capabilities.length))} 
                                </Text>
                                <TextSeeMore
                                    onClick={() => {handleShowLess(meeting.id); }}
                                >
                                    See less
                                </TextSeeMore>
                            </>
                        ) : (
                            <TextSeeMore
                                onClick={() => {handleShowMore(meeting.id);}}
                            >
                                See more
                            </TextSeeMore>
                        ))}
                </CapabilitiesWrap>
            ),
            attendees: (
                <AlignItems>
                    {meeting.users.length < 4
                        ? meeting.users.map(
                              (
                                  stakeholder: Stakeholder,
                                  globalIndex: number
                              ) => (
                                  <Tooltip
                                      title={`${stakeholder.firstName}
                                    ${stakeholder.lastName}`}
                                      placement="bottom"
                                      key={globalIndex}
                                  >
                                      <ChipsAvatar>
                                          {stakeholder.firstName &&
                                              stakeholder.firstName[0].toUpperCase()}
                                      </ChipsAvatar>
                                  </Tooltip>
                              )
                          )
                        : stakeholders
                              .slice(0, 4)
                              .map(
                                  (
                                      stakeholder: Stakeholder,
                                      mapIndex: number
                                  ) => (
                                      <Tooltip
                                          title={`${stakeholder.firstName}
                                    ${stakeholder.lastName}`}
                                          placement="bottom"
                                          key={mapIndex}
                                      >
                                          <ChipsAvatar
                                              style={{
                                                  backgroundColor: `${'#' +
                                                      Math.random()
                                                          .toString(16)
                                                          .substr(-6)}`
                                              }}
                                          >
                                              {stakeholder.firstName &&
                                                  stakeholder.firstName[0].toUpperCase()}
                                          </ChipsAvatar>
                                      </Tooltip>
                                  )
                              )}
                    {meeting.stakeholders.length > 4 && (
                        <Tooltip
                            title={
                                <>
                                    {meeting.stakeholders
                                        .slice(4)
                                        .map(
                                            (
                                                item: Stakeholder,
                                                stakeholdersIndex: number
                                            ) => (
                                                <div
                                                    key={stakeholdersIndex}
                                                >{`${item.firstName} ${item.lastName}`}</div>
                                            )
                                        )}
                                </>
                            }
                            placement="top"
                        >
                            <TextStakeholders>{`+${meeting.stakeholders.length -
                                4}`}</TextStakeholders>
                        </Tooltip>
                    )}
                </AlignItems>
            ),
            meetingDate: new Date(meeting.meetingDate).toDateString(),
            notes: meeting.notes.length
                ? parseMeetingText(meeting.notes[0].text)
                : '',
            status: (
                <TextStatus>
                    <span>{meeting.archivedDate ? 'Archived' : 'Active'}</span>
                </TextStatus>
            ),
            edit: isRed ? (
                <EditButtonRed
                    callback={() => callbackTemplate(index, meeting)}
                />
            ) : (
                <EditButton callback={() => callbackTemplate(index, meeting)} />
            )
        };
    });
};

const ChipsAvatar = styled('span')`
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fdb52b;
    border-radius: 50px;
    padding: 4px;
    font-size: 16px;
    color: black;
    margin-right: -10px;
`;

const TitleTextStakeholders = styled(Link)`
    cursor: pointer;
`;

const TextStakeholders = styled(Text)`
    font-size: 19.9px;
    font-weight: bold;
    color: #b4b4b4;
    margin-left: 16px;
`;

const TextSeeMore = styled(Text)`
    margin-top: 10px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #de3328;
    text-decoration: underline;
    cursor: pointer;
`;

const TextStatus = styled(Column)`
    background: #346FA2;
    text-align: center;
    padding: 5px 10px;
    width: 74px;
    border-radius: 30px;
    color: white;
`;

const CapabilitiesWrap = styled(Column)`
    max-width: 300px;
`;
