import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { AlignItems } from '../../../../components';

type Props = {
    placeholder: string;
    callback: any;
    setSearchValue: any;
    searchValue: string;
};
    const SearchCompaniesField: React.FC<Props> = ({ placeholder, callback,setSearchValue,searchValue }) => {
    return (
        <Wrapper >
            <Search
                type="search"
                value={searchValue}
                placeholder={placeholder}
                onChange={e => {
                    setSearchValue(e.target.value);
                    callback(e.target.value);
                }}
            />
        </Wrapper>
    );
};

const Search = styled(InputBase)`
    && {
        width: 100%;
        border: none;
        height: 20px;
        outline: none;
        padding: 1% 2% 1% 2%;
        border-radius: 50px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        font-color: #080808;
        &::-webkit-input-placeholder {
            color: #0000;
        }
    }
`;

const Wrapper = styled('div')`
    height: 25px;
    width: 90%;
    border: solid 1px #696969;
    padding: 1% 2% 5% 2%;
    position: relative;
    box-sizing: border-box;
    align-self: flex-start;
    margin: 10px auto;
`;


export default SearchCompaniesField;
