import React from 'react';
import { styled } from '@mui/material/styles';
import { Slider, SliderProps } from '@mui/material';
import { withoutThemeColorResolver } from '../../utils';

const CustomSliderBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const Custom = styled(Slider)<SliderProps>(({ theme }) => ({
    color: 'transparent',
    height: 10,
    padding: '15px 0',
    zIndex: 100,
    
    thumb: {
        position: 'absolute',
        top: '7px',
        color: 'black',
        width: '25px',
        height: '25px',
        display: 'flex',
        marginLeft: '-8px',
        transform: 'rotate(-45deg)',
        alignItems: 'center',
        borderRadius: '50% 50% 50% 0',
        justifyContent: 'center',
        backgroundColor: '#2e7bf8',
        '&:focus,&:hover,&$active': {
            boxShadow: 'none',
            '@media (hover: none)': {
                boxShadow: CustomSliderBoxShadow
            }
        }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 11px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#c4c4c4'
        }
    },
    track: {
        height: 6
    },
    rail: {
        height: 6,
        opacity: 0.5,
        background: 'rgba(128,128,128,.5)'
    },
    mark: {
        backgroundColor: 'rgba(128,128,128,.5)',
        width: 4,
        height: 17,
        marginTop: -6
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'rgba(128,128,128,.5)'
    },
    markLabel: {
        top: '30px',
        color: '#888888',
        fontSize: '14px',
        fontWeight: 'bold'
    }
}));

type MarkType = {
    label?: string | null;
    value: number;
};

const CustomSlider: React.FC<{
    marks: MarkType[];
    ValueLabelComponent: any;
    SliderThumbComponent: any;
    setValue: any;
    value: number;
    step: number;
    min: number;
    max: number;
    backgroundHeight?: string;
    sliderMargin?: string;
    renderDescriptions?: any;
    opacity?: string;
    isDisabled?: boolean;
}> = ({
    marks,
    ValueLabelComponent,
    SliderThumbComponent,
    value,
    setValue,
    step,
    min,
    max,
    backgroundHeight,
    sliderMargin,
    renderDescriptions,
    opacity,
    isDisabled
}) => {
    return (
        <SliderContainer>
            <MarksContainer>
                {marks.map((mark: MarkType, index: number) => {
                    return (
                        <Mark
                            key={index}
                            isVisible={
                                mark.value !== value &&
                                Number.isInteger(mark.value)
                            }
                        >
                            {mark.value}
                        </Mark>
                    );
                })}
            </MarksContainer>
            <Custom
                valueLabelFormat={ValueLabelComponent}
                components={SliderThumbComponent}
                aria-labelledby="discrete-slider-small-steps"
                value={value}
                onChangeCommitted={(_e: object, sliderValue: any) => {
                    if (isDisabled) {
                        return;
                    }
                    setValue(Number(sliderValue));
                }}
                onChange={(_e: object, sliderValue: any) => {
                    if (isDisabled) {
                        return;
                    }
                    setValue(Number(sliderValue));
                }}
                step={step}
                min={min}
                max={max}
                marks={marks}
                valueLabelDisplay="on"
            />
            <SliderBackground
                width={value * 2 * 10}
                background={`linear-gradient(90deg, rgba(255,255,255,1) 0%, ${withoutThemeColorResolver(
                    value
                )} 100%)`}
                opacity={opacity}
                height={backgroundHeight}
                margin={sliderMargin}
            />
            {renderDescriptions ? renderDescriptions() : null}
        </SliderContainer>
    );
};

CustomSlider.defaultProps = {
    isDisabled: false
};

const Mark = styled('span')<{ isVisible: boolean }>`
    color: #c4c4c4;
    font-size: 15px;
    opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const MarksContainer = styled('div')`
    width: calc(100% + 6px);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SliderContainer = styled('div')`
    position: relative;
    min-width: 440px;
    width: 100%;
    align-items: center;
    flex-direction: column;
`;

const SliderBackground = styled('div')<{
    width: number;
    background?: string;
    height?: string;
    margin?: string;
    opacity?: string;
}>`
    width: ${props => props.width && props.width + '%'};
    background: ${props =>
        props.background
            ? props.background
            : 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(148,173,196,1) 100%)'};
    height: ${props => (props.height ? props.height : '5px')};
    margin: ${props => (props.margin ? props.margin : '-45px 0 0 4px')};
    opacity: ${props => (props.opacity ? props.opacity : '1')};
`;

export default CustomSlider;
