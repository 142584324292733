import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { JustifyContentCenter } from '../../../../../components';
import { AssessmentData } from '../../types';
import Bargraph from '../../../../../components/Charts/BarGraph/BarGraph';
import {
    MaturityGraphData,
    targetScoreData
} from '../../../MaturityOverview/MaturityOverviewTabs/MaturityOverviewBarChart/MaturityOverviewBarChartHelpers';
import { colorsList } from '../../../../../utils';
import { Legend, Series } from '../../../../../components/Charts/BarGraph/types';

type Props = {
    assessmentsData?: AssessmentData;
    campaignTitle: string;
    showTargetScore?: boolean;
    pointOneDecimalEnable: boolean;
    showCapabilityStatus?: boolean;
};
const BarGraph: React.FC<Props> = ({ assessmentsData, campaignTitle ,showTargetScore, pointOneDecimalEnable}) => {
    const data=assessmentsData && MaturityGraphData(assessmentsData.assessment.state.domains, pointOneDecimalEnable);
    const [seriesData,setSeriesData]=useState<Series[]>(data&&data.seriesData||[]);
    const [colors,setColors]=useState<string[]>(colorsList);
    const fullTitle =
        (campaignTitle || '') +
        ': ' +
        ((assessmentsData &&
            assessmentsData.assessment &&
            assessmentsData.assessment.campaignEntity &&
            assessmentsData.assessment.campaignEntity.name) ||
            '');
    let objectivesLabelsList: string[] = [];

    if (assessmentsData) {
        objectivesLabelsList =
            assessmentsData.frameworkByCampaign.definition.scores;
    }
    const capabilityStatus = assessmentsData?.assessment.state.status !== 'COMPLETED' ? true : false;
    useEffect(()=>{
        if(showTargetScore){
            const targetScoreSeriesData=assessmentsData &&targetScoreData (assessmentsData.assessment.state.domains);
            if(targetScoreSeriesData){
                setSeriesData([targetScoreSeriesData,...seriesData]);
                setColors(['grey',...colorsList]);
            }
        }
        else{
            setSeriesData(data&&data.seriesData||[]);
            setColors(colorsList);
        }
    },[showTargetScore]);
    return (
        <ChartContainer>
            <Bargraph
                title={fullTitle + ' Capability'}
                objectivesLabelsList={
                    objectivesLabelsList
                }
                seriesData={seriesData}
                legendData={data&&data.legendData||[]}
                xAxisData={data&&data.xData||[]}
                isCapabilityByDomain={false}
                colors={colors}
                barToLine={true}
                showCapabilityStatus={capabilityStatus}
            />
        </ChartContainer>
    );
};

const ChartContainer = styled(JustifyContentCenter)`
    padding: 50px;
    flex-direction: column;
`;

export default BarGraph;
