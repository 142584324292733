import React, { useCallback, useEffect, useState } from 'react';
import { Value as EditorValue } from 'react-quill';
import * as ld from 'lodash';
import { AutosaveProps, SavingState } from './types';
import { autoSaveFailedMessage } from './constants';
import { ErrorText } from '../components/base/Text/ErrorText';

const DELAY_MS = 3000;


export default function AutoSave({ data, saveData, isDirty }: AutosaveProps) {
  const [savingStatus,setSavingStatus]=useState<SavingState>();
    const debouncedSave = useCallback(
        ld.debounce(async (newData: EditorValue) => {
          const rsp=await saveData(newData);
          if(rsp===SavingState.Failed){
              setSavingStatus(SavingState.Failed);
          }
        }, DELAY_MS),
        []
    );
    useEffect(() => {
        if (data&&isDirty) {
            debouncedSave(data);
        }
    }, [data, debouncedSave,isDirty]);

    useEffect(() => {
        if (!isDirty) {
            debouncedSave.cancel();
        }
    }, [isDirty]);

    return savingStatus===SavingState.Failed ?
        (<ErrorText>{autoSaveFailedMessage}</ErrorText>):null;
}

