
import { gql } from 'apollo-boost';

export const REQUEST_ASSESSMENT_CAPABILITY_REVIEW = gql`
    mutation requestAssessmentCapabilityReview(
        $data: UpdateAssessmentCapabilityStatusInput!
    ) {
        requestAssessmentCapabilityReview(data: $data) {
            id
            name
            status
        }
    }
`;

export const REQUEST_ASSESSMENT_CAPABILITY_AMENDS = gql`
    mutation requestAssessmentCapabilityAmends(
        $data: UpdateAssessmentCapabilityStatusInput!
    ) {
        requestAssessmentCapabilityAmends(data: $data) {
            id
            name
            status
        }
    }
`;

export const APPROVE_ASSESSMENT_CAPABILITY = gql`
    mutation approveAssessmentCapability(
        $data: UpdateAssessmentCapabilityStatusInput!
    ) {
        approveAssessmentCapability(data: $data) {
            id
            name
            status
        }
    }
`;

export const UPDATE_WORKING_NOTES = gql`
    mutation updateAssessmentCapabilityWorkingNotes(
        $data: UpdateAssessmentCapabilityWorkingNotesInput!
    ) {
        updateAssessmentCapabilityWorkingNotes(data: $data) {
            id
            workingNotes {
                text
            }
        }
    }
`;

export const UPDATE_ASSESSMENT_CAPABILITY_SCORE = gql`
    mutation updateAssessmentCapabilitiesScope3(
        $data: UpdateAssessmentCapabilitiesScopeInput!
    ) {
        updateAssessmentCapabilitiesScope(data: $data) {
            id
            state {
                averageScore
                domains {
                    id
                    capabilities {
                        id
                        inScope
                    }
                }
            }
        }
    }
`;

export const ASSESSMENT_BY_ID = gql`
    query assessment5($id: ID!) {
        assessment(id: $id) {
            id
            campaignEntity {
                name
                archivedDate
            }
            permission
            state {
                domains {
                    id
                    name
                    capabilities {
                        id
                        name
                        status
                        inScope
                    }
                }
            }
            framework {
                id
                version
                definition {
                    name
                    shortName
                    scores
                    domains {
                        name
                        shortName
                        capabilities {
                            name
                            shortName
                        }
                    }
                }
            }
        }

    }
`;

export const ASSESSMENT_CAPABILITY_WITH_FRAMEWORK = gql`
    query assessmentCapability1(
        $data: AssessmentCapabilityInput!
        $campaignId: ID!
    ) {
        frameworkByCampaign(campaignId: $campaignId) {
            definition {
                name
                scores
                domains {
                    name
                    capabilities {
                        name
                        objectives {
                            name
                            reference
                            displayRef
                            results
                            guidance
                            components{
                                name
                                results
                                reference
                                question
                            }
                        }
                    }
                }
                hasObjectiveComponents
            }
        }
        assessmentCapability(data: $data) {
            id
            name
            averageScore
            targetScore
            inScope
            status
            meetings {
                archivedDate
                stakeholderIds
                capabilities {
                    id
                    name
                }
                meetingDate
                notes {
                    text
                }
            }
            moderatedScore
            objectives {
                id
                inScope
                score
                compensatingControlEnable
                notes
                weighting
                components{
                    id
                    score
                    weighting
                    inScope
                }
            }
            workingNotes {
                text
            }
            feedback {
                id
                title
                observations {
                    text
                }
                recommendations {
                    text
                }
            }
            objectivesScoredCnt
        }
    }
`;

export const CAMPAIGN_FRAMEWORK_SETTINGS = gql`
    query frameworkSettingsByCampaign($campaignId: ID!) {
        frameworkSettingsByCampaign(campaignId: $campaignId) {
            id
            name
            status
        }
    }
`;

export const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
            userId
            notificationType
            notificationCategory
            referenceID
            notificationMessage
            wasRead
        }
    }
`;
