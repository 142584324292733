import React from 'react';
import AlignItems from '../Layout/AlignItems';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

type SwitchProps = {
    turnedOn: boolean;
    setTurnOn: any;
    onColor?: string;
    offColor?: string;
    className?: string;
    disabled?: boolean;
    ariaLabelledby?: string;
};

const ToggleSwitch: React.FC<SwitchProps> = ({ turnedOn, setTurnOn,onColor='#de3328', offColor='#6E6E6E', className, disabled=false, ariaLabelledby }) => {
    return (
        <ToggleButton
            onClick={() => {
                if (disabled) return;
                setTurnOn(!turnedOn);
            }}
            className={className}
            aria-labelledby={ariaLabelledby}
            aria-pressed={turnedOn ? 'true': 'false'}
        >
            <AlignItems
                style={{
                    backgroundColor: disabled ? (turnedOn ? '#c7c7c7' : 'white')  : (turnedOn ? onColor : 'white'),
                    borderRadius: 48,
                    width: '50%',
                    height: '100%',
                    justifyContent: 'center',
                    color: disabled ? 'grey' : (turnedOn ? '#fff' : '#6E6E6E')
                }}
            >
                ON
            </AlignItems>
            <AlignItems
                style={{
                    backgroundColor: disabled ? (turnedOn ? 'white' : '#c7c7c7') : (turnedOn ? 'white' : offColor),
                    borderRadius: 48,
                    width: '50%',
                    height: '100%',
                    justifyContent: 'center',
                    color: disabled ? 'grey' : (turnedOn ? '#6E6E6E' : '#fff')
                }}
            >
                OFF
            </AlignItems>
        </ToggleButton>
    );
};

export default ToggleSwitch;

const ToggleButton = styled(Button)`
    &&& {
        width: 96px;
        height: 32px;
        border-radius: 48px;
        border: 1px solid rgb(196, 196, 196);
        background-color: rgb(255, 255, 255);
        justify-content: space-between;
        padding: 2px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        box-sizing: unset;
    }

    .MuiButton-label {
        height: 100%
        width: 100%
    }
`;
