import React, { useState, useEffect } from 'react';
import {
    Column,
    Flex,
    SearchField,
    RedButton,
    Text,
    AlignItems,
    JustifyContentCenter,
    SpinnerWithOverlay
} from '../../components';
import { styled } from '@mui/material/styles';

import Select from '@mui/material/Select';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {Typography } from '@mui/material';
import { CompanyEntityForm, CompanyEntityGroupForm } from './CompanyStructureForms';
import CompanyLevelTree, { ENTITIES_GROUPS } from './CompanyLevelTree';

import iconGreyPlus from '../../assets/images/icon-grey-plus-circle.svg';
import {
    EntityStructureData,
    CampaignEntity,
    CampaignEntityGroup,
    TreeData,
    EntityStructureProps
} from './types';

type StatusType = {
    id: string;
    label: string;
};

const entityStatusList = [
    {
        id: 'ACTIVE',
        label: 'Active'
    },
    {
        id: 'ARCHIVED',
        label: 'Archived'
    }
];

const EntityStructure = ({ clientId, userMayEdit }: EntityStructureProps) => {
    const [selectedEntityGroups, setSelectedEntityGroups] = useState<
        CampaignEntityGroup[]
    >([]);
    const [entityStatus, setEntityStatus] = useState<string | unknown>(
        'ACTIVE'
    );
    const { data, loading, refetch } = useQuery<EntityStructureData>(ENTITIES_GROUPS, {
        variables: { clientId }
    });
    const [selectedEntity, setSelectedEntity] = useState<TreeData | null>(null);
    const [entityGroupOpen, setEntityGroupOpen] = useState(false);
    const [entityGroupUpdated, setEntityGroupUpdated] = useState(false);
    const [entityOpen, setEntityOpen] = useState(false);
    const [
        selectedEntityGroup,
        setSelectedEntityGroup
    ] = useState<CampaignEntityGroup | null>(null);
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        const fetchData = async () =>{
            if(entityGroupUpdated){
                await refetch();
            }
        };
        fetchData();
        setEntityGroupUpdated(false);
    },[entityGroupUpdated]);

    useEffect(() => {
        data && setSelectedEntityGroups(data.entityGroups);
    }, [data]);

    const handleSelectCampaignStatus = (e: any) =>
        setEntityStatus(e && e.target && e.target.value);
    return (
        <Column>
            <SectionWrapper>
                <Column style={{ alignItems: 'flex-start', marginBottom: 32 }}>
                    <Typography
                        variant='h1'
                        style={{
                            letterSpacing: 0.5,
                            marginBottom: 8,
                            color:'#525252',
                            fontWeight:'bold',
                            fontSize:'24px'
                        }}
                    >
                        Company structure
                    </Typography>
                    <Text color="#525252">
                        Create an entity structure by selecting a parent first
                        and then add
                    </Text>
                    <Text color="#525252">
                        entities to it. You can add multiple entities to a
                        parent.
                    </Text>
                </Column>
                <Column style={{ marginBottom: 40 }}>
                    <AlignItems style={{ justifyContent: 'space-between' }}>
                        <Flex>
                            <CustomSelect
                                native
                                variant="outlined"
                                value={entityStatus}
                                onChange={handleSelectCampaignStatus}
                                inputProps={{'aria-label':'select campaign status'}}
                            >
                                {entityStatusList.map(
                                    (campaignStatus: StatusType, i: number) => (
                                        <option
                                            key={i}
                                            value={campaignStatus.id}
                                        >
                                            {campaignStatus.label}
                                        </option>
                                    )
                                )}
                            </CustomSelect>
                            {userMayEdit && (
                                <RedButton
                                    style={{ marginRight: 16, paddingLeft: 16 }}
                                    onClick={() => {
                                        setSelectedEntityGroup(null);
                                        setEntityGroupOpen(true);
                                    }}
                                >
                                    <AlignItems>
                                        <img src={iconGreyPlus} alt="plus" aria-hidden={true}/>
                                        <TemplatesButtonText>
                                            Add parent
                                        </TemplatesButtonText>
                                    </AlignItems>
                                </RedButton>
                            )}
                        </Flex>
                        <SearchField
                            placeholder="Search assessment"
                            callback={(event: string) => setSearchString(event)}
                        />
                    </AlignItems>
                </Column>
                {loading ? (
                    <SpinnerWithOverlay />
                ) : (
                    selectedEntityGroups.map(
                        (el: CampaignEntityGroup, i: number) => (
                            <CompanyLevelTree
                                selectedEntityGroup={{
                                    ...el,
                                    editKey: i + 1,
                                    campaignEntities: el.campaignEntities
                                        ? el.campaignEntities.map(
                                              (entity: CampaignEntity) => ({
                                                  ...entity
                                              })
                                          )
                                        : []
                                }}
                                key={i}
                                searchString={searchString}
                                stakeholdersByClient={
                                    data && data.stakeholdersByClient
                                }
                                entityStatus={entityStatus}
                                setEntityGroupOpen={setEntityGroupOpen}
                                setEntityOpen={setEntityOpen}
                                setSelectedEntityGroup={setSelectedEntityGroup}
                                setSelectedEntity={setSelectedEntity}
                                userMayEdit={userMayEdit}
                            />
                        )
                    )
                )}
            </SectionWrapper>
            <CompanyEntityForm
                dropdownValues={{
                    entityTypes: data && data.entityTypes,
                    stakeholdersByClient: data && data.stakeholdersByClient.filter((sc: any) =>
                    sc.status !== 'ARCHIVED')
                }}
                entityOpen={entityOpen}
                entityData={selectedEntityGroup}
                setSelectedEntityGroups={setSelectedEntityGroups}
                selectedEntityGroups={selectedEntityGroups}
                setSelectedEntityGroup={setSelectedEntityGroup}
                selectedRow={selectedEntity}
                setEntityOpen={setEntityOpen}
                setSelectedEntity={setSelectedEntity}
                setEntityGroupUpdated={setEntityGroupUpdated}
            />
            <CompanyEntityGroupForm
                dropdownValues={{
                    entityTypes: data && data.entityTypes,
                    stakeholdersByClient: data && data.stakeholdersByClient.filter((sc: any) =>
                    sc.status !== 'ARCHIVED')
                }}
                entityData={selectedEntityGroup}
                setSelectedEntityGroups={setSelectedEntityGroups}
                entityGroupOpen={entityGroupOpen}
                selectedEntityGroups={selectedEntityGroups}
                setSelectedEntityGroup={setSelectedEntityGroup}
                setEntityGroupOpen={setEntityGroupOpen}
                clientId={clientId}
                setEntityGroupUpdated={setEntityGroupUpdated}
            />
        </Column>
    );
};

const SectionWrapper = styled(Column)`
    padding: 20px;
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
`;

const CustomSelect = styled(Select)`
    min-height: 48px;
    margin-right: 16px;
    .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 84px !important;
    }
`;

const TemplatesButtonText = styled(Text)`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    margin: 0 16px 0 8px;
`;

export default EntityStructure;
