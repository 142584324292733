import { PieChartSection } from './types';

export const getNestedChildren = (array: PieChartSection[], parent: number) => {
    const newArray: PieChartSection[] = [];
    array.forEach((_item: PieChartSection, index: number) => {
        if (array[index].parent === parent) {
            const children = getNestedChildren(array, array[index].childId);

            if (children.length) {
                array[index].children = children;
            }
            newArray.push(array[index]);
        }
    });
    return newArray;
};

export const domainLabel = {
    color: '#777777',
    rotate: 0,
    show: false,
    fontSize: 14,
    fontWeight: 'bold',
    position: 'test',
    padding: 900
};
