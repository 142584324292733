import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, Tooltip } from '@mui/material';
import { arrowGenerator } from '../TooltipsHelpers';
import { ArrowTooltipProps } from '../types';

const Delayed = (props: any) => {
    useEffect(() => {
        const delayTimer = setTimeout(() => {
            props.setHidden(false);
        }, 1000);

        return () => {
            props.setHidden(true);
            clearTimeout(delayTimer);
        };
    }, []);

    return props.hidden ? '' : props.children;
};

const ArrowTooltip = (props: ArrowTooltipProps) => {
    const [hidden, setHidden] = useState(true);

    const theme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        minWidth: props.minWidth || '359px',
                        position: 'relative',
                        fontSize: props.fontSize || 12,
                        fontSmooth: props.fontSmooth || 'auto',
                        textDecoration: props.textDecoration || 'inherit',
                        maxWidth: props.maxWidth || '400px',
                        boxShadow: props.boxShadow || 'unset',
                        padding: props.padding || (hidden ? '0px 8px' : '4px 8px'),
                        backgroundColor: props.background
                            ? props.background
                            : 'rgba(97, 97, 97, 0.9)'
                    },
                    arrow: {
                        position: 'absolute',
                        fontSize: 6,
                        '&::before': {
                            content: '""',
                            margin: 'auto',
                            display: 'block',
                            width: 0,
                            height: 0,
                            borderStyle: 'solid'
                        }
                    },
                    popper: arrowGenerator(
                        props.popperColor ? props.popperColor : '#2f2f2f',
                        props.top
                    ),
                },
            },
        },
    });
    
    const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(
        null
    );

    return (
        <ThemeProvider theme={theme}>
            <Tooltip
                PopperProps={{
                    popperOptions: {
                        modifiers: [
                            {
                              name: 'arrow',
                              options: {
                                enabled: Boolean(arrowRef),
                                element: arrowRef,
                              },
                            },
                          ],
                    }
                }}
                {...props}
                title={
                    <Delayed hidden={hidden} setHidden={setHidden}>
                        <React.Fragment>
                            {props.title}
                            <span ref={setArrowRef} />
                        </React.Fragment>
                    </Delayed>
                }
            />
        </ThemeProvider>
    );
};

export default ArrowTooltip;
