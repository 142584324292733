import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import SortableTree, { getVisibleNodeCount } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { Column, Text, AlignItems } from '../../components';
import { UserStore } from '../../context/user-store';
import {
    EntityStakeholder,
    TreeData,
    CampaignEntity,
    SelectedStakeholder
} from './types';

import iconAdd from '../../assets/images/icon-add-grey-small.svg';
import iconDnD from '../../assets/images/icon-drag&drop.svg';
import iconEdit from '../../assets/images/mdi_edit.svg';
import iconArchive from '../../assets/images/mdi_archive.svg';
import { Button } from '@mui/material';


export const ENTITIES_GROUPS = gql`
    query entityGroups($clientId: Int!) {
        entityGroups {
            id
            name
            type {
                id
                name
            }
            client {
                id
                name
            }
            owner {
                id
                firstName
                lastName
                email
            }
            stakeholders {
                id
                firstName
                lastName
                email
            }
            campaignEntities: entities {
                id
                name
                type {
                    id
                    name
                }
                owner {
                    id
                    firstName
                    lastName
                }
                stakeholders {
                    id
                    firstName
                    lastName
                }
                archivedDate
            }
            archivedDate
        }
        entityTypes {
            value: id
            label: name
        }
        stakeholdersByClient(clientId: $clientId) {
            value: id
            firstName
            lastName
            status
        }
    }
`;
const ARCHIVE_ENTITY = gql`
    mutation archiveCampaignEntity2($id: ID!){
        archiveEntity(id: $id) {
            id
            name
        }
    }
`;

const ARCHIVE_ENTITY_GROUP = gql`
    mutation archiveCampaignEntityGroup2($id: ID!) {
        archiveEntityGroup(id: $id) {
            id
            name
        }
    }
`;

const RESTORE_ENTITY = gql`
    mutation restoreCampaignEntity1($id: ID!) {
        restoreEntity(id: $id) {
            id
            name
        }
    }
`;

const RESTORE_ENTITY_GROUP = gql`
    mutation restoreCampaignEntityGroup2($id: ID!) {
        restoreEntityGroup(id: $id) {
            id
            name
        }
    }
`;

const UPDATE_ENTITY = gql`
    mutation updateEntity($data: UpdateEntityInput!, $id: ID!) {
        updateEntity(data: $data, id: $id) {
            id
            name
        }
    }
`;

type Props = {
    selectedEntityGroup: any;
    setEntityGroupOpen: any;
    setEntityOpen: any;
    setSelectedEntityGroup: any;
    setSelectedEntity: any;
    searchString: string;
    entityStatus: string | unknown;
    userMayEdit: boolean;
    stakeholdersByClient?: EntityStakeholder[];
};

type CanDropProps = {
    node: any;
    nextParent: any;
};

const statusFilterResolver = (
    choosenStatus: string | unknown,
    nodeArchiveDate: number | null
) => {
    if (choosenStatus === 'ACTIVE') {
        return nodeArchiveDate ? 0.5 : 1;
    }
    if (choosenStatus === 'ARCHIVED') {
        return nodeArchiveDate ? 1 : 0.5;
    }
};

const CompanyLevelTree: React.FC<Props> = ({
    selectedEntityGroup,
    setEntityGroupOpen,
    setEntityOpen,
    setSelectedEntityGroup,
    setSelectedEntity,
    searchString,
    stakeholdersByClient = [],
    entityStatus,
    userMayEdit
}) => {
    const {
        state: { client }
    } = useContext(UserStore);
    const [treeData, setTreeData] = useState<TreeData[]>([]);
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [archiveEntity] = useMutation(ARCHIVE_ENTITY,
       {
        refetchQueries: [
            {
                query: ENTITIES_GROUPS,
                variables: {
                    clientId: client.id
                }
            }
        ]
       });
    const [restoreEntity] = useMutation(RESTORE_ENTITY,
        {
            refetchQueries: [
                {
                    query: ENTITIES_GROUPS,
                    variables: {
                        clientId: client.id
                    }
                }
            ]
           });
    const [archiveEntityGroup] = useMutation(ARCHIVE_ENTITY_GROUP,
        {
            refetchQueries: [
                {
                    query: ENTITIES_GROUPS,
                    variables: {
                        clientId: client.id
                    }
                }
            ]
           });
    const [restoreEntityGroup] = useMutation(RESTORE_ENTITY_GROUP,
        {
            refetchQueries: [
                {
                    query: ENTITIES_GROUPS,
                    variables: {
                        clientId: client.id
                    }
                }
            ]
           });
    const [updateEntity] = useMutation(UPDATE_ENTITY);
    useEffect(() => {
        const dataForTree = [
            {
                ...selectedEntityGroup,
                name: selectedEntityGroup.name,
                treeType: 'group',
                expanded: true,
                children:
                    selectedEntityGroup.campaignEntities &&
                    selectedEntityGroup.campaignEntities.map(
                        (entity: CampaignEntity) => ({
                            // name: entity.name,
                            treeType: 'entity',
                            expanded: true,
                            ...entity
                        })
                    )
            }
        ];
        setTreeData(dataForTree);
    }, [selectedEntityGroup]);

    const {
        state: { user }
    } = useContext(UserStore);

    const canDrop = ({ node, nextParent }: CanDropProps) => {
        if (!userMayEdit) {
            return false;
        }
        if (node.treeType === 'group') {
            return false;
        }
        if (nextParent && nextParent.treeType === 'entity') {
            return false;
        }

        return true;
    };

    const customSearchMethod = ({ node, searchQuery }: any) =>
        searchQuery &&
        node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
    const count = getVisibleNodeCount({ treeData });
    const externalNodeType = 'yourNodeType';
    return (
        <div
            style={{
                height: (count + 1) * 80,
                position: 'relative',
                backgroundColor: '#fafafa',
                marginTop: 16
            }}
        >
            <CustomSortableTree
                treeData={treeData}
                shouldCopyOnOutsideDrop={() => {
                    return false;
                }}
                dndType={externalNodeType}
                rowHeight={80}
                canDrag={() => {
                    return userMayEdit;
                }}
                canDrop={canDrop}
                onChange={(newData: any) => {
                    setTreeData(newData);
                }}
                onMoveNode={(e: any) => {
                    if (e && e.nextParentNode) {
                        updateEntity({
                            variables: {
                                data: {
                                    name: e && e.node && e.node.name,

                                    entityGroupId:
                                        e &&
                                        e.nextParentNode &&
                                        e &&
                                        e.nextParentNode.id
                                },
                                id: e && e.node && e.node.id
                            }
                        }).catch(error => console.error(error));
                    }
                }}
                searchMethod={customSearchMethod}
                searchFinishCallback={(matches: any) => {
                    setSearchFocusIndex(
                        matches.length > 0
                            ? searchFocusIndex % matches.length
                            : 0
                    );
                }}
                searchFocusOffset={searchFocusIndex}
                searchQuery={searchString}
                generateNodeProps={({ node, parentNode }: any) => {
                    return {
                        scaffoldBlockPxWidth: 500,
                        toggleChildrenVisibility: null,
                        title: (
                            <Column>
                                <NameText>{node.name}</NameText>
                                {stakeholdersByClient.map(
                                    (option: SelectedStakeholder) =>
                                        option.value === node.ownerId && (
                                            <Text
                                                color="#818181"
                                                size="14px"
                                                key={option.value}
                                            >
                                                {`${option.firstName} ${option.lastName}`}
                                            </Text>
                                        )
                                )}
                            </Column>
                        ),
                        // ),
                        style: {
                            width:
                                node.treeType === 'group' ? '79vw' : '76.7vw',
                            height: '100%',
                            opacity: statusFilterResolver(
                                entityStatus,
                                node.archivedDate
                            )
                        },
                        buttons:
                            user && userMayEdit
                                ? node.treeType === 'group'
                                    ? [
                                          <TreeButton
                                              onClick={() => {
                                                  setSelectedEntityGroup(
                                                      parentNode
                                                  );
                                                  setSelectedEntityGroup(node);
                                                  setEntityOpen(true);
                                              }}
                                              key={1}>
                                              <img src={iconAdd} alt="add" aria-hidden={true}/>
                                              <ButtonText style={{ marginRight: 40 }}>
                                                  Add entity
                                              </ButtonText>
                                          </TreeButton>,
                                          <TreeButton
                                              onClick={() => {
                                                  setSelectedEntityGroup(
                                                      parentNode
                                                  );
                                                  setSelectedEntityGroup(node);
                                                  setEntityGroupOpen(true);
                                              }}
                                              key={2}>
                                              <img src={iconEdit} alt="edit" aria-hidden={true}/>
                                              <ButtonText style={{ marginRight: 40 }}>
                                                Edit
                                              </ButtonText>
                                          </TreeButton>,
                                          <TreeButton
                                              style={{
                                                  display: node.id
                                                      ? 'flex'
                                                      : 'none'
                                              }}
                                              key={10}
                                              onClick={() => {
                                                  node.archivedDate
                                                      ? restoreEntityGroup({
                                                            variables: {
                                                                id: node.id
                                                            }
                                                        })
                                                      : archiveEntityGroup({
                                                            variables: {
                                                                id: node.id
                                                            }
                                                        });
                                              }}
                                          >
                                              <img
                                                  src={iconArchive}
                                                  alt="archive"
                                                  aria-hidden={true}
                                              />
                                              <ButtonText
                                                  style={{ marginRight: 24 }}
                                              >
                                                  {node.archivedDate
                                                      ? 'Restore'
                                                      : 'Archive'}
                                              </ButtonText>
                                          </TreeButton>
                                      ]
                                    : [
                                          <TreeButton
                                              onClick={() => {
                                                  setSelectedEntityGroup(
                                                      parentNode
                                                  );
                                                  setSelectedEntity(node);
                                                  setEntityOpen(true);
                                                  console.log(
                                                      'selected node',
                                                      node
                                                  );
                                              }}
                                              key={4}
                                          >
                                              <img src={iconEdit} alt="edit" aria-hidden={true}/>
                                              <ButtonText
                                                  style={{ marginRight: 40 }}
                                              >
                                                  Edit
                                              </ButtonText>
                                          </TreeButton>,
                                          <TreeButton
                                              key={11}
                                              style={{
                                                  display: node.id
                                                      ? 'flex'
                                                      : 'none'
                                              }}
                                              onClick={() => {
                                                  node.archivedDate
                                                      ? restoreEntity({
                                                            variables: {
                                                                id: node.id
                                                            }
                                                        })
                                                      : archiveEntity({
                                                            variables: {
                                                                id: node.id
                                                            }
                                                        });
                                              }}
                                          >
                                              <img
                                                  src={iconArchive}
                                                  alt="archive"
                                                  aria-hidden={true}
                                              />
                                              <ButtonText
                                                  style={{ marginRight: 24 }}
                                              >
                                                  {node.archivedDate
                                                      ? 'Restore'
                                                      : 'Archive'}
                                              </ButtonText>
                                          </TreeButton>
                                      ]
                                : []
                    };
                }}
            />
        </div>
    );
};

const CustomSortableTree = styled(SortableTree)`
    .rst__moveHandle {
        background: 50% url(${iconDnD});
    }
    .rst__node {
        width: 100%;
        display: flex;
    }
    .rst__rowWrapper {
        padding: 10px 0 5px 0;
    }
    .rst__node .rst__nodeContent {
        position: static;
    }
    .rst__rowToolbar {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
    }
`;

const TreeButton = styled(Button)`
    color: "#393939";
`;

const ButtonText = styled(Text)`
    font-size: 14px;
    color: #393939;
    margin-left: 8px;
    border-bottom: 0.5px #393939;
`;

const NameText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    color: #2d2d2d;
`;

export default CompanyLevelTree;
