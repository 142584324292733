import { Value as EditorValue } from 'react-quill';

export enum SavingState{
    Saved='Saved',
    Failed='Failed'
}
export interface AutosaveProps {
    data: EditorValue;
    saveData: any;
    isDirty?: boolean;
}
