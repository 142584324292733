import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, List, ListItem, Checkbox, Popover } from '@mui/material';
import dropdownArrow from '../../../assets/images/icon-drop-down-dark.svg';
import Text from '../../../components/base/Text/Text';
import {
    ApplyAndClearWrapper,
    ClearButton,
    ApplyButton
} from '../Buttons';
import ImgContainer from '../ImgContainer/ImgContainer';
import ArrowTooltip from '../../Tooltips/ArrowTooltip';

type Option = {
    id: string;
    name: string;
    __typename?: string;
}

type Props = {
    listOfOptions?: Option[];
    isOpen: boolean;
    setOpen: CallableFunction;
    placeholder: string;
    buttonDisabled?: boolean;
    selected: Option[];
    setSelected: any;
    width?: string;
    multiselect?: boolean;
    selectAllText?: string;
};

const DropdownMultipleSelection: React.FC<Props> = ({
    listOfOptions,
    isOpen,
    setOpen,
    placeholder,
    buttonDisabled = false,
    selected,
    setSelected,
    width,
    multiselect,
    selectAllText = 'All'
}) => {
    const [selectedData, setSelectedData] = useState<Option[]>(selected);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const anchorId = isOpen ? 'simple-popover' : undefined;
    const filterCheckbox = (checkboxData: Option) => {
        if (selectedData.some(option => option.id === checkboxData.id)) {
            const newData = selectedData.filter(
                existData => existData.id !== checkboxData.id
            );
            setSelectedData(newData);
        } else {
            multiselect
                ? setSelectedData([...selectedData, checkboxData])
                : setSelectedData([checkboxData]);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (!isOpen) {
            handleClose();
        }
        if (isOpen) {
            setSelectedData(selected);
        }
    }, [isOpen]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const checkAll = () => {
        if (listOfOptions) {
            selectedData.length === listOfOptions.length
                ? setSelectedData([])
                : setSelectedData(listOfOptions);
        }
    };

    return (
        <div style={{ marginTop: 8 }}>
            <MenuButtonContainer
                background={buttonDisabled ? 'none' : '#ececec'}
                disabled={buttonDisabled}
                aria-describedby={anchorId}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(e);
                    setOpen(true);
                }}
                width={width ? width : '100%'}>
                <TextContainer
                    color={placeholder.includes('Select') ? '#575757' : '#000'}>
                    {placeholder}
                </TextContainer>
                <ImgContainer src={dropdownArrow} alt="dropdown arrow" aria-hidden />
            </MenuButtonContainer>

            <Popover
                id={anchorId}
                open={isOpen}
                keepMounted={isOpen}
                onClose={() => setOpen(false)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                style={{ marginTop: '8px' }}
            >
                <div style={{ paddingBottom: '8px', width: width }}>
                    <CustomList>
                        {multiselect && (
                            <>
                                <ListOption>
                                    <Checkbox
                                        color="secondary"
                                        checked={
                                            listOfOptions &&
                                            selectedData.length === listOfOptions.length
                                        }
                                        inputProps={{
                                            'aria-label': 'select all'
                                        }}
                                        style={{
                                            padding: '0 9px'
                                        }}
                                        onChange={() => checkAll()}
                                    />
                                    <ListOptionText>{selectAllText}</ListOptionText>
                                </ListOption>
                                <Divider />
                            </>
                        )}
                        {listOfOptions ? (
                            listOfOptions.map(
                                (item: Option, index: number) => (
                                    <ListOption
                                        key={index}
                                        style={
                                            selected.find(
                                                el =>
                                                    el.id ===
                                                    item.id
                                            )
                                                ? {
                                                      backgroundColor:
                                                          '#f2f2f2',
                                                      padding:
                                                          '14px 0 14px 5px',
                                                      margin: '0',
                                                      height: '52px'
                                                  }
                                                : {
                                                      padding:
                                                          '14px 0 14px 5px',
                                                      margin: '0',
                                                      height: '52px'
                                                  }
                                        }
                                    >
                                        <ListCheckbox
                                            color="secondary"
                                            checked={selectedData.some(
                                                selectedOption =>
                                                    selectedOption.id ===
                                                    item.id
                                            )}
                                            inputProps={{
                                                'aria-label': 'select item'
                                            }}
                                            onChange={() => filterCheckbox(item)}
                                        />
                                        <ArrowTooltip
                                            title={item.name}
                                            placement="bottom"
                                            key={item.id}
                                            minWidth={'0px'}
                                        >
                                            <MenuOptionText>
                                                {item.name}
                                            </MenuOptionText>
                                        </ArrowTooltip>
                                    </ListOption>
                                )
                            )
                        ) : (
                            <div>Loading...</div>
                        )}
                    </CustomList>
                    <Divider />
                    <ButtonWrapper>
                        <ClearAllButton
                            disabled={!selectedData.length}
                            onClick={() => {
                                setSelected([]);
                                setOpen(false);
                            }}
                        >
                            Clear
                        </ClearAllButton>
                        <ApplyButton
                            disabled={!selectedData.length}
                            onClick={() => {
                                setSelected(selectedData);
                                setOpen(false);
                            }}
                        >
                            Apply
                        </ApplyButton>
                    </ButtonWrapper>
                </div>
            </Popover>
        </div>
    );
};

const CustomList = styled(List)({
    maxWidth: 300,
    minWidth: 150,
    margin: 0,
    padding: 16,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 450,
    display: 'flex',
    flexDirection: 'column',
    focus: {
        outline: 0
    }
});

const ListOption = styled(ListItem)({
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
    height: '42px',
    paddingTop: 12,
    paddingBottom: 12
});

const ListOptionText = styled(Text)({
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 'normal',
    color: '#2d2d2d'
});

const ListCheckbox = styled(Checkbox)({
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 9,
    paddingLeft: 9,
});

const MenuOptionText = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const MenuButtonContainer = styled('button')<{
    background?: string;
    width?: string;
}>`
    display: flex;
    width: ${props => props.width};
    align-items: center;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        background: ${props => (props.background ? props.background : 'none')};
    }
`;

const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${props => (props.color ? props.color : '#575757')};
`;

const ButtonWrapper = styled(ApplyAndClearWrapper)`
    padding-left: 8px;
`;

const ClearAllButton = styled(ClearButton)`
    width: 50%;
`;

export default DropdownMultipleSelection;
