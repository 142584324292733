import React, { ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/material/styles';
import { Select, MenuItem, InputBase, InputBaseProps, MenuItemProps, SelectChangeEvent } from '@mui/material';
import Text from '../../base/Text/Text';
import { useTheme } from '@mui/material/styles';

type Props = {
    value: string;
    handleChange: (event: any, child?: any) => void;
    options: any;
    placeholder?: string;
    validation?: boolean;
};

const Dropdown: React.FC<Props> = ({
    value,
    handleChange,
    options,
    placeholder,
    validation
}) => {

    const CustomInput = styled(InputBase)<InputBaseProps>(({theme}) => ({
        'label + &': {
            marginTop: useTheme().spacing(3)
        },
        input: {
            borderRadius: 4,
            border: validation ? '1px solid #de3328' : '1px solid #cdcdcd',
            padding: '9px',
            width: '312px'
        }
    }));

    const CustomMenuItem = styled(MenuItem)<MenuItemProps>(({theme}) => ({
        width: '320px',
        whiteSpace: 'normal',
        overflow: 'hidden'
    }));

    return (
        <>
            <DropDownContainer
                value={value ? value : placeholder}
                onChange={handleChange}
                input={<CustomInput />}>
                <CustomMenuItem value={placeholder} disabled>
                    <Text color="#575757">{placeholder}</Text>
                </CustomMenuItem>
                {options &&
                    Object.entries(options)
                        .filter((option: any) => option[0] !== '__typename')
                        .map((option: any) => {
                            return (
                                <CustomMenuItem
                                    key={option[0]}
                                    value={option[0]}
                                >
                                    <Text>{option[1]}</Text>
                                </CustomMenuItem>
                            );
                        })}
            </DropDownContainer>
        </>
    );
};

const DropDownContainer = styled(Select)`
    width: 312px;
    border-radius: 4px;
    border: 1px solid #cdcdcd;
    padding-left: 10px;
`;

export default Dropdown;
