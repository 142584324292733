import React, { useState, useEffect, useContext } from 'react';
import { AlignItems, SpinnerWithOverlay } from '../../../../../../components';
import CampaignPopover from '../../CampaignPopover';
import ComparisonEmptyState from '../../ComparisonEmptyState';
import {
    HeaderFilterHeading,
    CompareText,
    ColumnWithMargin
} from '../../../constants';
import { CampaignByClient, ComparisonGraph, Campaign } from '../../../types';
import { createBrowserHistory } from 'history';
import { useLazyQuery } from '@apollo/react-hooks';
import ComparisonGrapgh from './ComparisonGraph';
import { styled } from '@mui/material/styles';
import { COMPARE_CAMPAIGNS } from '../../../ComparisonQueries';
import { isPointOneDecimalEnable } from '../../../../../../utils';
import { isReadOnlyEnabled } from '../../../../../../utils';
import { UserStore } from '../../../../../../context/user-store';
const ComparisonCampaigns: React.FC<{ data?: CampaignByClient }> = ({
    data
}) => {
    const {
        state: { globalSettings }
    } = useContext(UserStore);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    const history = createBrowserHistory();
    const state = history.location.state as any;
    const [isCampaignOpen, setCampaignOpen] = useState<boolean>(false);
    const [isCampaign2Open, setCampaign2Open] = useState<boolean>(false);
    const [firstCampaignId, setFirstCampaignId] = useState<string>();
    const [secondCampaignId, setSecondCampaignId] = useState<string>();
    const [selectedFramework, setSelectedFramework] = useState<string|number>();
    const [graphData, setGraphData] = useState<ComparisonGraph>();
    const fullData = (data && data.availableCampaignsByClient.filter(campaign=>
        !(!readOnlyEnabled&&campaign.readOnly)
    )) || [];
    const filteredData = fullData.filter(
        x => x.framework.frameworkVersionId === selectedFramework
    );
    const firstCampaignName = fullData.find(x => x.id === firstCampaignId);
    const [showGraphSpin, setShowGraphSpin] = useState(false);
    const [showGraph, setShowGraph] = useState<boolean>(false);
    const [pointOneDecimalEnable, setPointOneDecimalEnable] = useState<boolean>(false);
    let secondCampaignName: Campaign | undefined;
    if (firstCampaignId !== secondCampaignId) {
        secondCampaignName = filteredData.find(x => x.id === secondCampaignId);
    }
    const [getComparisonData] = useLazyQuery(COMPARE_CAMPAIGNS, {
        onCompleted: res => {
            const { item1, item2 } = res;
            setGraphData({ campaign1: item1, campaign2: item2 });
            setShowGraph(true);
            setShowGraphSpin(false);
        },
        onError: err => {
            setShowGraph(false);
            setShowGraphSpin(false);
            alert('Something Went Wrong');
            console.log(err);
        }
    });
    useEffect(() => {
        const frm1 =
            firstCampaignName && firstCampaignName.framework.frameworkVersionId;
        const frm2 =
            secondCampaignName && secondCampaignName.framework.frameworkVersionId;
        if (
            !data ||
            !firstCampaignId ||
            !secondCampaignId ||
            firstCampaignId === secondCampaignId ||
            frm1 !== frm2
        ) {
            setShowGraph(false);
            return undefined;
        }
        setShowGraphSpin(true);
        getComparisonData({
            variables: {
                campaignId1: firstCampaignId,
                campaignId2: secondCampaignId
            }
        });
    }, [firstCampaignId, secondCampaignId, data]);
    useEffect(() => {
        if (state) {
            const currentCampaignId = state.campaignId;
            const currentCampaign = fullData.find(
                x => x.id === currentCampaignId
            );
            const currentFramework =
                currentCampaign && currentCampaign.framework.frameworkVersionId;
            setFirstCampaignId(currentCampaignId);
            setSelectedFramework(currentFramework);
            // tslint:disable-next-line: no-redundant-boolean
            setPointOneDecimalEnable(!isPointOneDecimalEnable(globalSettings) ? false : currentCampaign && currentCampaign.pointOneDecimalEnable || false);
        }
    }, [state && state.campaignId, data]);
    return (
        <div>
            <AlignItems style={{ width: '100%' }}>
                <ColumnWithMargin>
                    <HeaderFilterHeading>1st campaign</HeaderFilterHeading>
                    <CampaignPopover
                        placeholder={
                            (firstCampaignName && firstCampaignName.name) ||
                            'Select 1st campaign'
                        }
                        data={fullData}
                        isOpen={isCampaignOpen}
                        setOpen={setCampaignOpen}
                        setSelectedFramework={setSelectedFramework}
                        setCampaignById={setFirstCampaignId}
                        campaignId1={firstCampaignId}
                        lock={false}
                        buttonDisabled={false}
                    />
                </ColumnWithMargin>
                <CompareText>compare</CompareText>
                <ColumnWithMargin>
                    <HeaderFilterHeading>2nd campaign</HeaderFilterHeading>
                    <CampaignPopover
                        placeholder={
                            (secondCampaignName && secondCampaignName.name) ||
                            'Select 2nd campaign'
                        }
                        data={filteredData}
                        isOpen={isCampaign2Open}
                        setOpen={setCampaign2Open}
                        setSelectedFramework={setSelectedFramework}
                        setCampaignById={setSecondCampaignId}
                        campaignId1={firstCampaignId}
                        campaignId2={secondCampaignId}
                        lock
                        buttonDisabled={!firstCampaignId}
                    />
                </ColumnWithMargin>
            </AlignItems>
            {showGraphSpin ? ( <SpinnerWithOverlay />
            
             ) : !showGraph ? (
                <ComparisonEmptyState />
            ) : (
                <ComparisonGrapgh
                    pointOneDecimalEnable={pointOneDecimalEnable}
                    data={graphData || { campaign1: [], campaign2: [] }}
                    campaignsName={[
                        (firstCampaignName && firstCampaignName.name) || '',
                        (secondCampaignName && secondCampaignName.name) || ''
                    ]}
                    userfilteredData={filteredData}
                />
            )}  
        </div>
    );
};

export default ComparisonCampaigns;
const Spinner = styled('div')`
display: 'flex',
justify-content: 'center',
align-items: 'center',
height: '100%',
background-color: '#fff'
`;
