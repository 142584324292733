import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BoldText from '../../../../components/base/Text/BoldText';
import { Cell, EnhancedTableProps, DetailsTableProps } from './types';
import { Button } from '@mui/material';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { headCells, expandAll, collapseAll, canCollapse } = props;

    return (
        <TableHead
            style={{ borderTop: '2px solid', borderBottom: '2px solid' }}>
            <TableRow>
                {headCells.map((headCell: Cell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding="none"
                        style={headCell.style ? headCell.style : {}}
                    >
                        <TableHeadTitle>{headCell.label}</TableHeadTitle>
                    </TableCell>
                ))}
                <TableCell padding="checkbox">
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '50%' }}>
                            <TableHeadTitle>Observations</TableHeadTitle>
                        </div>
                        <div style={{ width: '50%' }}>
                            <TableHeadTitle>Recommendations</TableHeadTitle>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="center" padding="checkbox">
                    {canCollapse ? (
                        <ExpandText onClick={() => collapseAll()}>
                            Collapse all
                        </ExpandText>
                    ) : (
                        <ExpandText onClick={() => expandAll()}>
                            Expand all
                        </ExpandText>
                    )}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}


const DetailsTable: React.FC<DetailsTableProps> = ({
    header,
    rows,
    headCells,
    expandAll,
    collapseAll,
    canCollapse,
    isTargetScore,
    readOnlyEnabled,
    isReadOnly,
    campaignComplete,
    search
}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    function handleChangePage(newPage: number) {
        setPage(newPage);
    }

    useEffect(()=>{
        setPage(0);
    },[search]);

    function handleChangeRowsPerPage(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const colsQuantity = () => {
        if (headCells) {
            return headCells.length + 1;
        }
        return rows.length ? Object.entries(rows[0]).length + 1 : 0;
    };

    return (
        <CustomDetailContainer>
            <CustomPaper>
                {header}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}

                    onPageChange={(_, nextPage: number) =>
                        handleChangePage(nextPage)
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div style={{
                    overflowX: 'auto'
                }}>
                    <Table
                        style={{
                            minWidth: 750
                        }}
                        aria-label="capability table">
                        { isTargetScore ? (
                            <colgroup>
                                <col width="15%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="43%" />
                                <col width="16%" />
                            </colgroup>
                        ) : (
                            <colgroup>
                                <col width="15%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="55%" />
                                <col width="16%" />
                            </colgroup>
                        )}
                        <EnhancedTableHead
                            rowCount={rows.length}
                            headCells={headCells}
                            expandAll={expandAll}
                            collapseAll={collapseAll}
                            canCollapse={canCollapse}
                        />
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row: any, index: number) => {
                                    const filteredRow = Object.entries(
                                        row
                                    ).reduce((acc: any, curr: any) => {
                                        if (curr[0] !== 'id') {
                                            acc[curr[0]] = curr[1];
                                        }
                                        return acc;
                                    }, {});
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {Object.values(filteredRow).map(
                                                (cell: any, i: number) => {
                                                    return (
                                                        <TableCell
                                                            style={{
                                                                verticalAlign: 'top',
                                                                height: 44
                                                            }}
                                                            key={i}
                                                        >
                                                            {cell}
                                                        </TableCell>
                                                    );
                                                }
                                            )}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={colsQuantity()} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </CustomPaper>
        </CustomDetailContainer>
    );
};

const CustomDetailContainer = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(3)
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    boxShadow: 'none',
    marginBottom: theme.spacing(2)
}));

const TableHeadTitle = styled(BoldText)`
    font-size: 18px;
    color: #575757;
`;

const ExpandText = styled(Button)`
    color: #2d2d2d !important;
    font-size: 15px !important;
    text-decoration: underline !important;
    text-decoration-color:  #d9d9d9 !important;
    text-transform: none !important;
    line-height: normal !important;

`;

export default DetailsTable;
