import React, { useContext, useState } from 'react';
import CampaignList from '../SidebarCampaign/CampaignList';
import Router from '../../../../routes/router';
import { AlignItems, BoldText, Column, Flex, Menu, SpinnerWithOverlay } from '../../../../components';
import { Client as ClientType } from '../../../../context/reducer';
import { UserStore } from '../../../../context/user-store';
import { styled } from '@mui/material/styles';
import {  Divider, IconButton, MenuItem } from '@mui/material';
import blackCogIcon from '../../../../assets/images/icon-black-cog.svg';
import greyArrowRight from '../../../../assets/images/icon-grey-arrow-right.svg';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

type ClientProps = {
    showLoading: any;
    companiesAreOpen: boolean;
    setCompaniesAreOpen: CallableFunction;
    clientData: ClientType;
    isoCode: string;
};

export const Client: React.FC<ClientProps> = ({
    showLoading,
    clientData,
    companiesAreOpen,
    setCompaniesAreOpen,
    isoCode
}) => {
    const { id, name } = clientData;
    const {
        state: { user, client },
        dispatch
    } = useContext(UserStore);

    const setCurrentClient = (currentClient: ClientType) =>
        dispatch({ type: 'SET_CLIENT', value: currentClient });

    const [isCampaignOpen, setIsCampaignOpen] = useState<boolean>(false);
    const [isIconOpen, setIsIconOpen] = useState<boolean>(true);

const handleCloseCampaign = (data: any) => {
    switch (data) {
        case 'show':
            setIsIconOpen(false);
            break;
        case 'hide':
            setIsIconOpen(true);
            break;
        default:
            setIsIconOpen(false);
            break;
    }
};

const handleChangeCompany = () => {
    const choosenClient =
        (user &&
            user.clients &&
            user.clients.find(
                item => item.id === id
            )) ||
        client;
    setCurrentClient(choosenClient);
    setCompaniesAreOpen(!companiesAreOpen);
};

    return (
        <ListItem>
            {showLoading ? (
                <SpinnerWithOverlay />
            ) : (
                <Column style={{ width: '100%' }}>
                    <CompanyWrapper>
                        <AlignItems>
                            <CompanyLogoText>{name && name[0]}</CompanyLogoText>
                            <CompanyName>
                                {name}
                                {' - ' + isoCode}
                            </CompanyName>
                        </AlignItems>
                        <AlignItems>
                            <Menu
                                button={
                                    <CompanyImage
                                        src={blackCogIcon}
                                        alt="black cog icon"
                                        aria-hidden={true}
                                    />
                                }
                                arrowColor="#fff"
                                ariaLabel="company actions"
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleChangeCompany();
                                        Router.goToStakeholderManagement();
                                    }}
                                >
                                    <DropDownText>
                                        Manage stakeholders
                                    </DropDownText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleChangeCompany();
                                        Router.goToCompanyStructure();
                                    }}
                                >
                                    <DropDownText>
                                        Company Structure
                                    </DropDownText>
                                </MenuItem>
                            </Menu>
                            <IconButton
                                aria-label="go to selected company dashboard"
                                onClick={() => {
                                    handleChangeCompany();
                                    Router.goToDashboard();
                                }}
                            >
                                <CompanyImage
                                    src={greyArrowRight}
                                    alt="grey arrow right"
                                    aria-hidden={true}/>


                            </IconButton>
                        </AlignItems>
                    </CompanyWrapper>
                    {isIconOpen ? (
                        <LinkText>
                            <IconButton
                                aria-label="show campaigns"
                                style={{
                                    padding: '0px',
                                }}
                                onClick={() => {
                                    setIsCampaignOpen(true);
                                    handleCloseCampaign('show');
                                }}
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                            Show Campaigns{' '}
                        </LinkText>
                    ) : (
                        <LinkText>
                            <IconButton
                                aria-label="hide campaigns"
                                style={{
                                    padding: '0px',
                                }}
                                onClick={() => {
                                    setIsCampaignOpen(false);
                                    handleCloseCampaign('hide');
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                            Hide Campaigns{' '}
                        </LinkText>
                    )}
                    {id && isCampaignOpen && (
                        <CampaignList
                            clientId={id}
                            companiesAreOpen={companiesAreOpen}
                            setCompaniesAreOpen={setCompaniesAreOpen}
                        />
                    )}

                    <Divider />
                </Column>
            )}
        </ListItem>
    );
};

export default Client;

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)`
    color: #2d2d2d;
`;

const KeyboardArrowUpIcon = styled(KeyboardArrowUp)`
    color: #2d2d2d;
`;

const LinkText = styled(Flex)`
    font-size: 12px;
    color: #2d2d2d;
    padding: 5px 25px;
    display: block;

`;

const DropDownText = styled(BoldText)`
    font-size: 13px;
    color: #2d2d2d;
    margin-bottom: 10px;
`;

const CompanyName = styled(BoldText)`
    font-size: 13px;
    color: #2f2f2f;
`;

const ListItem = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`;

const CompanyImage = styled('img')``;

const CompanyWrapper = styled(AlignItems)`
    flex: 1;
    padding: 8px 12px 8px 25px;
    justify-content: space-between;
    &:hover {
        background-color: #d6d6d6;
    }
`;

const CompanyLogoText = styled(AlignItems)`
    background-color: #737373;
    padding: 5px 10px;
    margin-right: 8px;
    color: white;
    margin-right: 8px;
    border-radius: 2px;
`;
