import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Menu, MenuProps } from '@mui/material';

type Props = {
    button: React.ReactNode;
    children: React.ReactNode;
    arrowColor?: string;
    top?: string;
    isMenuOpen?: boolean;
    isBoxShadowHide?: boolean;
    menuStyles?: object;
    paperStyles?: object;
    setMenuStatus?: any;
    buttonWrapperWidth?: string;
    buttonDisabled?: boolean;
    keepMounted?: boolean;
    ariaLabel?: string;
};

const CustomMenu: React.FC<Props> = ({
    button,
    children,
    arrowColor = '#ffffff',
    top = '-5%',
    isMenuOpen,
    isBoxShadowHide,
    menuStyles,
    paperStyles,
    setMenuStatus,
    buttonWrapperWidth,
    buttonDisabled = false,
    keepMounted,
    ariaLabel
}) => {
    const StyledMenu = styled((props: MenuProps) => 
        <Menu
            elevation={0}
            anchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            {...props}
        />
    )({
        paper: {
            boxShadow: isBoxShadowHide
                ? 'none'
                : '0 0 13px 0 rgba(0, 0, 0, 0.15)',
            marginTop: 10,
            maxWidth: 350,
            '&::before': {
                content: '""',
                position: 'absolute',
                right: '45%',
                top: `${top}`,
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '0 10px 10px 10px',
                borderColor: `transparent transparent ${arrowColor} transparent`,
                zIndex: 99981
            }
        }
    });


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        if (!isMenuOpen) {
            handleClose();
        }
    }, [isMenuOpen]);
    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
        setMenuStatus && setMenuStatus(true);
    }

    function handleClose() {
        setMenuStatus && setMenuStatus(false);
        !keepMounted && setAnchorEl(null);
    }
    return (
        <ButtonWrapper width={buttonWrapperWidth}>
            <Button
                onClick={handleClick}
                disabled={buttonDisabled}
                style={{
                    textTransform: 'none',
                    minWidth: 0,
                    padding: 0,
                    ...menuStyles
                }}
                data-testid="styled-button"
                aria-label={ariaLabel}
            >
                {button}
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClick={() => !keepMounted && handleClose()}
                onClose={() => !keepMounted && handleClose()}
                data-testid="styled-menu"
                style={{... paperStyles}}
            >
                {children}
            </StyledMenu>
        </ButtonWrapper>
    );
};

CustomMenu.defaultProps = {
    menuStyles: {},
    paperStyles: {},
    buttonWrapperWidth: ''
};

const ButtonWrapper = styled('div')<{ width?: string }>`
    width: ${props => (props.width ? props.width : 'auto')};
`;

export default CustomMenu;
