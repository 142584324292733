import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { Popover, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import BoldText from '../base/Text/BoldText';
import Text from '../base/Text/Text';
import {RedButton, ClearButton} from '../base/Buttons';
import Flex from '../base/Layout/Flex';
import Column from '../base/Layout/Column';
import AlignItems from '../base/Layout/AlignItems';
import { months } from './DateRangeSelectHelpers';
import mdi_timer_blue from '../../assets/images/mdi_timer_blue.svg';
import ImgContainer from '../base/ImgContainer';
import dropdownArrow from '../../assets/images/icon-drop-down-dark.svg';

type Props = {
    open: boolean;
    setOpen: any;
    loading: boolean;
    yearsData: Year[];
    buttonDisabled: boolean;
    selected: {
        from: {
            month: number;
            year: number;
        };
        to: {
            month: number;
            year: number;
        };
    } | null;
    setSelected: any;
};

type Month = {
    label: string;
    value: number;
};

type Year = {
    label: number;
    value: number;
};

const DateRangeSelect = ({
    open,
    setOpen,
    loading,
    yearsData,
    buttonDisabled,
    selected,
    setSelected
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [monthFrom, setMonthFrom] = useState(0);
    const [monthTo, setMonthTo] = useState(0);
    const [yearFrom, setYearFrom] = useState(0);
    const [yearTo, setYearTo] = useState(0);

    useEffect(() => {
            if (selected) {
                setMonthFrom(selected.from.month);
                setMonthTo(selected.to.month);
                setYearFrom(selected.from.year);
                setYearTo(selected.to.year);
            } else {
                setMonthFrom(0);
                setMonthTo(0);
                setYearFrom(0);
                setYearTo(0);
        }
    }, [selected]);

    const monthsDiff = () => {
        if (
            monthFrom === 0 ||
            monthTo === 0 ||
            yearFrom === 0 ||
            yearTo === 0
        ) {
            return null;
        }
        const yearsDiff = (d1: Date, d2: Date) => {
            return d2.getFullYear() - d1.getFullYear();
        };
        const labelFrom = months.find(
            (month: Month) => month.value === monthFrom
        );
        const labelTo = months.find((month: Month) => month.value === monthTo);
        const date1 = new Date(`${labelFrom && labelFrom.label}, ${yearFrom}`);
        const date2 = new Date(`${labelTo && labelTo.label}, ${yearTo}`);
        const years = yearsDiff(date1, date2);

        return years * 12 + (date2.getMonth() - date1.getMonth());
    };

    const dateLabel = () => {
        if (
            monthFrom === 0 ||
            monthTo === 0 ||
            yearFrom === 0 ||
            yearTo === 0
        ) {
            return '';
        }

        return `${months[monthFrom - 1].shortForm} ${yearFrom} - ${
            months[monthTo - 1].shortForm
        } ${yearTo}`;
    };
    const currentMonthsDiff = monthsDiff();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <MenuButtonContainer
                disabled={buttonDisabled}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(e);
                    setOpen(true);
                }}
            >
                <TextContainer
                    color={currentMonthsDiff !== null ? '#000' : '#575757'}
                >
                    {currentMonthsDiff !== null
                        ? dateLabel()
                        : 'Select date range'}
                </TextContainer>
                <ImgContainer src={dropdownArrow} alt="dropdown arrow" aria-hidden />
            </MenuButtonContainer>

            <Popover
                open={open}
                keepMounted={open}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <ModalWrapper>
                    <Flex>
                        <DateBlock>
                            <BoldText
                                style={{
                                    paddingBottom: '8px',
                                    borderBottom: '1px solid #efefef'
                                }}
                            >
                                FROM
                            </BoldText>
                            <DateContent>
                                <DateSelect>
                                    <HeaderFilterHeading>
                                        Month
                                    </HeaderFilterHeading>
                                    <FormControl variant="outlined">
                                        <CustomSelect
                                            value={monthFrom}
                                            onChange={(
                                                e: any
                                            ) => {
                                                e.persist = () => {};
                                                const {
                                                    value
                                                } = e.target as HTMLTextAreaElement;
                                                setMonthFrom(
                                                    parseInt(value, 10)
                                                );
                                            }}
                                        >
                                            <MenuItem disabled value={0}>
                                                <span
                                                    style={{ color: '#575757' }}
                                                >
                                                    Select month
                                                </span>
                                            </MenuItem>
                                            {months.map(
                                                (month: {
                                                    label: string;
                                                    value: number;
                                                }) => (
                                                    <MenuItem
                                                        key={month.value}
                                                        value={month.value}
                                                    >
                                                        {month.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </CustomSelect>
                                    </FormControl>
                                </DateSelect>
                                <YearSelect>
                                    <HeaderFilterHeading>
                                        Year
                                    </HeaderFilterHeading>
                                    <FormControl variant="outlined">
                                        <CustomSelect
                                            value={yearFrom}
                                            onChange={(
                                                e: any
                                            ) => {
                                                e.persist = () => {};
                                                const {
                                                    value
                                                } = e.target as HTMLTextAreaElement;
                                                setYearFrom(
                                                    parseInt(value, 10)
                                                );
                                            }}
                                        >
                                            <MenuItem disabled value={0}>
                                                <span
                                                    style={{ color: '#575757' }}
                                                >
                                                    Select
                                                </span>
                                            </MenuItem>
                                            {!loading &&
                                                yearsData &&
                                                yearsData.map(year => (
                                                    <MenuItem
                                                        key={year.value}
                                                        value={year.value}
                                                    >
                                                        {year.label}
                                                    </MenuItem>
                                                ))}
                                        </CustomSelect>
                                    </FormControl>
                                </YearSelect>
                            </DateContent>
                        </DateBlock>
                        <DateBlock style={{ marginLeft: '16px' }}>
                            <BoldText
                                style={{
                                    paddingBottom: '8px',
                                    borderBottom: '1px solid #efefef'
                                }}
                            >
                                TO
                            </BoldText>
                            <DateContent>
                                <DateSelect>
                                    <HeaderFilterHeading>
                                        Month
                                    </HeaderFilterHeading>
                                    <FormControl variant="outlined">
                                        <CustomSelect
                                            value={monthTo}
                                            onChange={(
                                                e: any
                                            ) => {
                                                e.persist = () => {};
                                                const {
                                                    value
                                                } = e.target as HTMLTextAreaElement;
                                                setMonthTo(parseInt(value, 10));
                                            }}
                                        >
                                            <MenuItem disabled value={0}>
                                                <span
                                                    style={{ color: '#575757' }}
                                                >
                                                    Select month
                                                </span>
                                            </MenuItem>
                                            {months.map(
                                                (month: {
                                                    label: string;
                                                    value: number;
                                                }) => (
                                                    <MenuItem
                                                        key={month.value}
                                                        value={month.value}
                                                    >
                                                        {month.label}
                                                    </MenuItem>
                                                )
                                            )}
                                        </CustomSelect>
                                    </FormControl>
                                </DateSelect>
                                <YearSelect>
                                    <HeaderFilterHeading>
                                        Year
                                    </HeaderFilterHeading>
                                    <FormControl variant="outlined">
                                        <CustomSelect
                                            value={yearTo}
                                            onChange={(
                                                e: any
                                            ) => {
                                                e.persist = () => {};
                                                const {
                                                    value
                                                } = e.target as HTMLTextAreaElement;
                                                setYearTo(parseInt(value, 10));
                                            }}
                                        >
                                            <MenuItem disabled value={0}>
                                                <span
                                                    style={{ color: '#575757' }}
                                                >
                                                    Select
                                                </span>
                                            </MenuItem>
                                            {!loading &&
                                                yearsData &&
                                                yearsData.map(year => (
                                                    <MenuItem
                                                        key={year.value}
                                                        value={year.value}
                                                    >
                                                        {year.label}
                                                    </MenuItem>
                                                ))}
                                        </CustomSelect>
                                    </FormControl>
                                </YearSelect>
                            </DateContent>
                        </DateBlock>
                    </Flex>
                    {currentMonthsDiff !== null && (
                        <Flex>
                            <img src={mdi_timer_blue} alt="timer" />
                            <Text color="#346FA2">
                                Time range selected:{' '}
                                {currentMonthsDiff !== null &&
                                currentMonthsDiff > 1
                                    ? `${currentMonthsDiff} months`
                                    : `${currentMonthsDiff} month`}
                            </Text>
                        </Flex>
                    )}

                    <ButtonsContent>
                        <ClearButton
                            onClick={() => {
                                setMonthFrom(0);
                                setMonthTo(0);
                                setYearFrom(0);
                                setYearTo(0);
                            }}
                        >
                            Clear
                        </ClearButton>
                        <RedButton
                            value="button"
                            style={{
                                padding: '0 32px',
                                opacity:
                                    currentMonthsDiff === null ||
                                    currentMonthsDiff < 0
                                        ? 0.5
                                        : 1
                            }}
                            disabled={
                                currentMonthsDiff === null ||
                                currentMonthsDiff < 0
                            }
                            onClick={() => {
                                setOpen(false);
                                setSelected({
                                    from: {
                                        month: monthFrom,
                                        year: yearFrom
                                    },
                                    to: {
                                        month: monthTo,
                                        year: yearTo
                                    }
                                });
                            }}
                        >
                            Apply
                        </RedButton>
                    </ButtonsContent>
                </ModalWrapper>
            </Popover>
        </>
    );
};

const CustomSelect = styled(Select)`
    height: 47px;
    && .MuiFormLabel-root.Mui-focused {
        color: #575757;
    }
    &&.MuiFormLabel-root.Mui-focused {
        color: #575757;
    }
    &&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #575757;
    }
`;
const HeaderFilterHeading = styled(Text)`
    letter-spacing: 0.5px;
    color: #575757;
    margin-bottom: 8px;
    padding-left: 8px;
`;

const ModalWrapper = styled(Column)`
    padding: 16px;
    outline: none;
`;
const DateBlock = styled(Column)`
    width: 50%;
    min-width: 320px;
    padding: 16px;
    background: #f6f6f6;
`;
const DateContent = styled(Flex)`
    margin-top: 20px;
`;
const DateSelect = styled(Column)`
    width: 100%;
    max-width: 160px;
`;
const YearSelect = styled(Column)`
    margin: 0 24px;
    width: 100%;
`;
const ButtonsContent = styled(AlignItems)`
    justify-content: space-between;
    margin-top: 33px;
`;

const MenuButtonContainer = styled('button')`
    display: flex;
    align-items: center;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
`;

const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${props => (props.color ? props.color : '#575757')};
`;

export default DateRangeSelect;
