import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import LazyLoad from 'react-lazy-load';
import { Divider, List, Popover } from '@mui/material';

import dropdownArrow from '../../../../../../../../assets/images/icon-drop-down-dark.svg';
import {
    ApplyButton,
    ApplyAndClearWrapper
} from '../../../../../../../../components/base/Buttons';
import { ClearAllButton } from '../../../../../ThreatAndRiskHelpers';
import ObjectiveItem from './ObjectiveItem';
import {
    ImgContainer,
    Text,
    SearchInline
} from '../../../../../../../../components';
import { ObjectivesDropdownList } from '../../../../../types';

type Props = {
    isOpen: boolean;
    setOpen: any;
    anchorEl?: HTMLButtonElement | null;
    setAnchorEl: any;
    allObjectives?: ObjectivesDropdownList[];
    setFinalObjectives: any;
    finalObjectives: string[];
    setFieldValue: any;
    id?: number | string;
    isGuidance: boolean;
    setInitialLoad: any;
    isInitialLoad: boolean;
};

const PopoverObjectivesMenu: React.FC<Props> = ({
    isOpen,
    setOpen,
    anchorEl,
    setAnchorEl,
    allObjectives,
    setFinalObjectives,
    finalObjectives,
    setFieldValue,
    id,
    isGuidance,
    setInitialLoad,
    isInitialLoad
}) => {
    const buttonDisabled = false;
    const anchorId = isOpen ? 'simple-popover' : undefined;
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [selectedObjectives, setSelectedObjectives] = useState<string[]>(
        finalObjectives ? finalObjectives : []
    );

    useEffect(() => {
        if (finalObjectives) {
            setSelectedObjectives(finalObjectives);
        }
    }, [finalObjectives, isOpen]);

    const addKeyFieldToObj = () => {
        return selectedObjectives.map((objective: string) => {
            return {
                reference: objective,
                isKey: false
            };
        });
    };

    return (
        <div style={{  width: '100%' }}>
            <MenuButtonContainer
                background={buttonDisabled ? 'none' : '#ececec'}
                disabled={buttonDisabled}
                aria-describedby={anchorId}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(e);
                    setOpen(true);
                }}>
                <TextContainer>Select objectives</TextContainer>
                <ImgContainer src={dropdownArrow} alt="dropdown arrow" aria-hidden />
            </MenuButtonContainer>
            <ObjPopover
                id={anchorId}
                open={isOpen}
                keepMounted={isOpen}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <SearchInline
                    placeholder="Search"
                    callback={() => {}}
                    style={{ margin: '8px' }}
                />
                <SearchDivider />
                <ListWrapper>
                    <List
                        style={{
                            width: '462px',
                            padding: 0
                        }}>
                        {allObjectives &&
                            allObjectives.map(
                                (
                                    capability: ObjectivesDropdownList,
                                    index: number
                                ) => {
                                    return (
                                        <LazyLoad offset={100} key={index}>
                                            <ObjectiveItem
                                                capability={capability}
                                                setSelectedObjectives={
                                                    setSelectedObjectives
                                                }
                                                selectedObjectives={
                                                    selectedObjectives
                                                }
                                                isGuidance={isGuidance}
                                            />
                                        </LazyLoad>
                                    );
                                }
                            )}
                    </List>
                </ListWrapper>
                <Divider />
                <ButtonWrapperFixed>
                    <ClearAllButton
                        disabled={!selectedObjectives.length}
                        onClick={() => setSelectedObjectives([])}
                    >
                        Clear
                    </ClearAllButton>
                    <ApplyRedButton
                        onClick={(_e: React.ChangeEvent<any>) => {
                            setFieldValue(
                                `[${id}].objectives`,
                                addKeyFieldToObj()
                            );
                            setFinalObjectives(selectedObjectives);
                            setOpen(false);
                            if (isInitialLoad) {
                                setInitialLoad(false);
                            }
                        }}
                    >
                        Apply
                    </ApplyRedButton>
                </ButtonWrapperFixed>
            </ObjPopover>
        </div>
    );
};

const ListWrapper = styled('div')`
    max-height: 300px;
    overflow-y: scroll;
`;

const MenuButtonContainer = styled('button')<{ background?: string }>`
    display: flex;
    align-items: center;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    width: 462px;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        background: ${props => (props.background ? props.background : 'none')};
    }
`;

const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${props => (props.color ? props.color : '#575757')};
    font-size: 16px;
`;

const ObjPopover = styled(Popover)`
    border-radius: 0;
    max-height: 689px;
    width: 462px;
    padding: 8px;
    border-radius: 2px;

    & .MuiPopover-paper {
        max-height: 412px;
        max-width: unset;
    }
`;

const SearchDivider = styled(Divider)`
    margin: 0 8px !important;
`;

const ButtonWrapperFixed = styled(ApplyAndClearWrapper)`
    height: 56px;
    background: white;
    padding-left: 8px;
`;

const ApplyRedButton = styled(ApplyButton)`
    && {
        margin-bottom: 8px;
    }
`;

export default PopoverObjectivesMenu;
