import { styled } from '@mui/material/styles';

const Link = styled('a')`
    // font-family: HelveticaNeue;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 15px;
    color: #346FA2;
    text-decoration: underline;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    :visited {
        color: #5518AB;
    }
    :hover {
        cursor: pointer;
        color:  #346FA2;
    }
`;

export default Link;
