import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { BoldText, RedButton, Text } from '../../../components';


export const marks = [
    {value: -1, label:'N/A'},
    {value: 0, label:'0'},
    {value: 0.5},
    {value: 1, label:'1'},
    {value: 1.5},
    {value: 2, label:'2'},
    {value: 2.5},
    {value: 3, label:'3'},
    {value: 3.5},
    {value: 4, label:'4'},
    {value: 4.5},
    {value: 5, label:'5'}
];

export const renderModal = ({
    handleCancel,
    handleAccept,
    isOpen,
    title,
    text
}: {
    isOpen: boolean;
    handleCancel: () => void;
    handleAccept: () => void;
    title: string;
    text: string;
}) => {
    return (
        <div>
            <CustomDialog
                open={isOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // disableBackdropClick
            >
                <CustomDialogTitle>
                    <CustomBoldText>{title}</CustomBoldText>
                    <IconButton aria-label="close" onClick={handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </CustomDialogTitle>
                <MuiDialogContent>
                    <Text style={{fontWeight: 'bold'}}>{text}</Text>
                </MuiDialogContent>

                <DialogActions>
                    <ButtonCancel onClick={handleCancel} variant="outlined">
                        Cancel
                    </ButtonCancel>

                    <ButtonWithMarginLeft
                        onClick={handleAccept}
                        variant="contained"
                    >
                        Accept
                    </ButtonWithMarginLeft>
                </DialogActions>
            </CustomDialog>
        </div>
    );
};

const CustomDialog = styled(Dialog)`
    .MuiDialog-paperWidthSm {
        max-width: none;
        padding-top: 8px;
        padding-bottom: 9px;
    }
    .MuiDialogActions-root {
        padding: 16px 24px 16px 16px;
    }
    .MuiDialogTitle-root{
        padding-bottom: 1em !important;
        border-bottom: 1px solid #E0E0E0;
    }
    .MuiDialogContent-root{
        font-weight: bold;
    }
    .MuiButtonBase-root.MuiIconButton-root{
        scale:1.5;
    }
`;

const CustomBoldText = styled(BoldText)`
    color: #575757;
    font-size: 22px;
    margin-left: 8px;
    margin: auto;
`;

const ButtonWithMarginLeft = styled(RedButton)`
    && {
        text-transform: capitalize;
        margin-left: 20px;
        min-height: auto;
        padding: 0.5em;
        width: 2em;
        font-size: 12px;
    }
`;

const ButtonCancel = styled(Button)`
    && {
        text-transform: capitalize;
        border: 1.5px solid #D9D9D9;
        color: #575757;
        padding: 0.5em;
        width: 2em;
        font-size: 12px;
    }
`;

const CustomDialogTitle = styled(DialogTitle)`
    width: '588px';
    paddingBottom: 0;
    h2{
        display: flex;
    }
`;
