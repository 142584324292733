import React, { useContext, useEffect, useState } from 'react';
import {IdleTimerProvider} from 'react-idle-timer';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Router from '../../../routes/router';
import { Toast } from '../../../components';
import MaturityScore from './MaturityScore';
import { MaturityMatch, CurrentIdsData, CampaignData, MaturityParams } from './types';
import { isReadOnlyEnabled } from '../../../utils';
import { UserStore } from '../../../context/user-store';
import { useParams } from 'react-router';

const LOCK_ASSESSMENT_CAPABILITY = gql`
    mutation lockAssessmentCapability($data: AssessmentCapabilityInput!) {
        lockAssessmentCapability(data: $data) {
            lock {
                assessmentId
                lockId
                createdAt
                user {
                    id
                    email
                    firstName
                    lastName
                }
            }
            lockedBySomeoneElse
        }
    }
`;
const UNLOCK_ASSESSMENT_CAPABILITY = gql`
    mutation unlockAssessmentCapability($data: AssessmentCapabilityInput!) {
        unlockAssessmentCapability(data: $data) {
            message
        }
    }
`;
const CAMPAIGN = gql`
    query campaign5($id: ID!) {
        campaign(id: $id) {
            id
            title,
            readOnly
            manager {
                id
            }
            members {
                id
            }
            status
            closedAt
            targetScoreEnabled
            pointOneDecimalEnable
            isCampaignEntityUser
        }
    }
`;

const MaturityScoreContainer: React.FC = () => {
    const params = useParams<MaturityParams>();
    const match: MaturityMatch = {
        isExact : false,
        path: '',
        url: '',
        params: {
            campaignId: params.campaignId!,
            assessmentId: params.assessmentId!,
            domainId: params.domainId!,
            capabilityId: params.capabilityId!
        }
    };

    const [canEdit, setEditMode] = useState<boolean>(false);
    const [blockSnackbar, setBlockSnackbar] = useState(false);
    const [warningSnackbar, setWarningSnackbar] = useState(false);

    const [currentIdsData, setCurrentIdsData] = useState<CurrentIdsData>({
        domainId: match.params.domainId,
        capabilityId: match.params.capabilityId
    });
    const { data: campaignData, loading: campaignDataLoading } = useQuery<
        CampaignData
    >(CAMPAIGN, {
        variables: {
            id: match.params.campaignId
        }
    });
    const [lockAssessmentCapability] = useMutation(LOCK_ASSESSMENT_CAPABILITY);
    const [unlockAssessmentCapability] = useMutation(
        UNLOCK_ASSESSMENT_CAPABILITY
    );
    const { capabilityId, domainId, assessmentId } = match.params;
    const unlockCapability = (values: {
        capabilityId: string;
        domainId: string;
        assessmentId: string;
    }) => {
        unlockAssessmentCapability({
            variables: {
                data: {
                    campaignId: match.params.campaignId,
                    capabilityId: values.capabilityId,
                    domainId: values.domainId,
                    assessmentId: values.assessmentId
                }
            }
        }).catch(() => {});
    };
    const lockCapability = (
        values: {
            capabilityId: string;
            domainId: string;
            assessmentId: string;
        },
        showToast: boolean
    ) => {
        lockAssessmentCapability({
            variables: {
                data: {
                    campaignId: match.params.campaignId,
                    capabilityId: values.capabilityId,
                    domainId: values.domainId,
                    assessmentId: values.assessmentId
                }
            }
        })
            .then(data => {
                const locked =
                    data.data.lockAssessmentCapability.lockedBySomeoneElse;
                setEditMode(!locked);
                if (showToast) {
                    setBlockSnackbar(locked);
                }
            })
            .catch(() => setEditMode(false));
    };

    useEffect(() => {
        if (
            campaignDataLoading ||
            !campaignData ||
            (campaignData && campaignData.campaign.closedAt !== null)
        ) {
            return;
        }

        lockCapability({ capabilityId, domainId, assessmentId }, true);

        const lockInterval = setInterval(() => {
            lockCapability({ capabilityId, domainId, assessmentId }, false);
        }, 1000 * 60);

        return () => {
            clearInterval(lockInterval);
            unlockCapability({ capabilityId, domainId, assessmentId });
        };
    }, [campaignDataLoading, campaignData]);
    const setNewData = (values: { capabilityId: string; domainId: string }) => {
        return setCurrentIdsData(prev => {
            if (
                (values.capabilityId !== prev.capabilityId ||
                    values.domainId !== prev.domainId) &&
                campaignData &&
                campaignData.campaign.closedAt === null
            ) {
                unlockCapability({ ...prev, assessmentId });
                lockCapability({ ...values, assessmentId }, false);
            }
            return values;
        });
    };
    let campaignIsClosed = campaignData
        ? campaignData.campaign.closedAt !== null
        : false;

    const {
        state: { client, user, globalSettings }
    } = useContext(UserStore);

    const isReadOnly = (campaignData && campaignData.campaign.readOnly || false);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    /**
     * We switch the flag on based on if the user has the right role, if closed we keep that way... if on the other hand not, then we flag closed but with the
     * message switched.
     */
     if (readOnlyEnabled && !campaignIsClosed && isReadOnly) {
        //BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
    }

    return (
        <>
            <IdleTimerProvider
                onIdle={() => {
                    if (!canEdit) {
                        return;
                    }
                    setWarningSnackbar(true);
                }}
                timeout={1000 * 60 * 5}
            />
            <IdleTimerProvider
                onIdle={() => {
                    Router.goToDashboard();
                }}
                timeout={1000 * 60 * 15}
            />
            <MaturityScore
                canEdit={campaignIsClosed ? false : canEdit}
                match={match}
                setCurrentIdsData={setNewData}
                currentIdsData={currentIdsData}
                campaignData={campaignData}
                campaignDataLoading={campaignDataLoading}
                campaignIsClosed={campaignIsClosed}
            />
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={blockSnackbar}
                autoHideDuration={1000 * 60 * 5}
                icon="info"
                title="View only"
                text={`This capability is currently view only as it is
                being edited.`}
                closeToast={setBlockSnackbar}
            />
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={warningSnackbar}
                autoHideDuration={1000 * 60 * 10}
                icon="info"
                title="Warning"
                text={'Your lock is going to expire in 10 minutes.'}
                closeToast={setWarningSnackbar}
            />
        </>
    );
};

export default MaturityScoreContainer;
