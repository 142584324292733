import React, { useContext, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import ImgSrc from '../../assets/images/AuthPage.png';
import { Navigate } from 'react-router-dom';
import PwcLogoBLack from '../../assets/images/PwC_Logo_Black.svg';
import { Button } from '../../components';
import { styled } from '@mui/material/styles';
import { Routes } from '../../routes/routes.enum';
import { UserStore } from '../../context/user-store';
import Router from '../../routes/router';
import { removeJwtFromWhitelist } from '../../services/ApolloClient/client';


const AuthPage = () => {
    const { state: { user } } = useContext(UserStore);
    const auth = useAuth();
    const route = Router.getCurrentPath();
    let subText = '';
    if(route === '/'){
        subText = 'Navigate the risk landscape with confidence, using Connected Risk Engine!';
    } else {
        subText = 'You were logged out from the application, click on the button below to log-in again.';
    }

    useEffect(() => {
        if(route.includes('logout/redirect')){
            auth.signoutSilent();
            auth.removeUser();
            removeJwtFromWhitelist();
        }
    }, []);

    if (auth.isAuthenticated && user.role) {
        return <Navigate to={{ pathname: Routes.dashboard }} />;
    }

    return (

        <Body>
            <LeftDIV>
                <LogoDiv><PwCLogo src={PwcLogoBLack as string} alt={'PwC Logo'} /></LogoDiv>
                <Content>
                    <WelcomeText>{'Welcome to'}</WelcomeText>
                    <Title>{'Connected Risk Engine'}</Title>
                    {!auth.isAuthenticated && (
                                <CustomButton
                                    onClick={async() => {await auth.signinRedirect();}}
                                >
                                    {'Login'}
                                </CustomButton>
                    )}
                </Content>
            </LeftDIV>
        </Body>
    );
};

const LogoDiv = styled('div')`
    display:block;
    position: absolute;
    left: 0%;
`;

const Title = styled('h1')`
    font-size:3rem;
    margin-top: 10px;
`;

const WelcomeText = styled('h3')`
    font-size:1.25rem;
    color: #696969;

`;


const LeftDIV = styled('div')`
    width:60vw;
    height:100%;
    background-color: #F3F3F3;
    display:flex;
    justify-content: center;
`;

const Body = styled('div')`
    width:100%;
    height:100%;
    background-image: url(${ImgSrc});
    background-color: rgb(46,46,46);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    color: black;
`;
const PwCLogo = styled('img')`
    min-width: 62px;
    max-width: 15rem;
    object-fit: contain;
	margin-left: 50px;
    margin-top: 20px;
`;

const Content = styled('div')`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const CustomButton = styled(Button)`
    && {
        font-family: 'CustomHelveticaNeue';
        font-weight: bold;
        font-size: 1rem;
        background-color: #415385;
        display: flex;
        align-items: center;
        outline: none;
        text-transform: none;
        min-width: 25%;
        width: 100%;
        padding-left: 125px;
        padding-right: 125px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white !important;
        border-radius: 0.5rem;
        border-color: #415385;

    }
    .MuiOutlinedInput-input {
        font-family: 'CustomHelveticaNeue';
    }
`;

export default AuthPage;
