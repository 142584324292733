import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { styled } from '@mui/material/styles';
import {
    SpaceBetween,
    Text,
    Column,
    AlignItems,
    Tab,
    VerticalLine,
    Breadcrumb
} from '../../../components';
import { comparisonTabsList } from './constants';
import { TabType, CampaignByClient } from './types';
import { renderContent } from './ComparisonHelpers';
import { AVAILABLE_CAMPAIGNS } from './ComparisonQueries';
import { createBrowserHistory } from 'history';
import { UserStore } from '../../../context/user-store';
import { Typography } from '@mui/material';

const Comparison: React.FC = () => {
    const history = createBrowserHistory();
    const state = history.location.state as any;
    const {
        state: { client = {} }
    } = useContext(UserStore);
    const [activeTab, setActiveTab] = useState<number>(1);
    const { data } = useQuery<CampaignByClient>(AVAILABLE_CAMPAIGNS, {
        variables: {
            clientId: (client && client.id) || 1
        }
    });
    const [comparisonBreadcrumbs, setComparisonBreadcrumbs] = useState([
        {
            name: 'Evaluate',
            path: '/main/evaluate/campaigns'
        },
        {
            name: `${client ? client.name : ''} - ${state &&
                state.campaignTitle}`,
            path: `/main/evaluate/maturity/${state && state.campaignId}`
        },
        {
            name: 'Comparison'
        }
    ]);

    useEffect(() => {
        if (state && state.assessmentId) {
            comparisonBreadcrumbs.splice(2, 0, {
                name: `${state.assessmentTitle}`,
                path: `/main/evaluate/maturity/${state.campaignId}/assessments/${state.assessmentId}`
            });
            setComparisonBreadcrumbs([...comparisonBreadcrumbs]);
        }
    }, [state && state.assessmentId]);

    return (
        <div style={state && state.campaignId.length ? { marginTop: 16 } : {}}>
            {state && state.campaignId.length ? (
                <Breadcrumb crumbs={comparisonBreadcrumbs} />
            ) : (
                ''
            )}
            <Header
                style={
                    state && state.campaignId.length ? { paddingTop: 19 } : {}
                }
            >
                <Typography variant="h1" style={{
                    letterSpacing: 0.5,
                    color: '#525252',
                    fontSize: '24px',
                    fontWeight: 'bold'
                }}>
                    Comparison
                </Typography>
            </Header>
            <Column>
                <AlignItems>
                    {comparisonTabsList.map(
                        (item: TabType, index: number, array: TabType[]) => {
                            return (
                                <Tab
                                    active={activeTab === item.id}
                                    handleClick={() => setActiveTab(item.id)}
                                    key={item.id}
                                    index={index}
                                    lastTab={
                                        comparisonTabsList.length - 1 === index
                                    }
                                >
                                    <TabContentContainer
                                        isActive={activeTab === item.id}
                                    >
                                        <img
                                            src={
                                                activeTab === item.id
                                                    ? item.iconActive
                                                    : item.iconDisable
                                            }
                                            alt={'icon'}
                                        />
                                        <Text
                                            color={
                                                activeTab === item.id
                                                    ? 'black'
                                                    : 'white'
                                            }
                                            margin="0 0 0 2px"
                                        >
                                            {item.label}
                                        </Text>
                                    </TabContentContainer>
                                    {activeTab !== index + 1 &&
                                        index !== array.length - 1 &&
                                        index !== activeTab - 2 && (
                                            <VerticalLine margin="0" />
                                        )}
                                </Tab>
                            );
                        }
                    )}
                </AlignItems>

                <MainContentContainer>
                    {renderContent({
                        activeTab,
                        data
                    })}
                </MainContentContainer>
            </Column>
            <div style={{ height: '100px' }} />
        </div>
    );
};

const Header = styled(SpaceBetween)`
    padding-top: 54px;
    padding-bottom: 38px;
`;

const TabContentContainer = styled('div')<{ isActive: boolean }>`
    padding: 3px 25px 3px 12px;
    display: flex;
    align-items: center;
    height: 47px;
`;

const MainContentContainer = styled('div')`
    padding: 20px;
    border: solid 1px #d9d9d9;
    background: white;
`;

export default Comparison;
