import React, { useCallback, useEffect, useState } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Router from '../../../routes/router';
import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';
import {
    AlignItems,
    Column,
    Flex,
    SpaceBetween,
    RedButton,
    H1Text,
    Toast
} from '../../../components';
import addIcon from '../../../assets/images/icon-add.svg';
import { User, Client } from '../../../context/reducer';
import {
    CampaignStatus,
    CampaignTableRows,
    CampaignFilteredList,
    Campaign
} from '../types';
import { campaignStatusList, renderModal } from './EvaluateHeaderHelpers';
import { renderCloseButton, renderArchiveButton } from '../../../utils';
import { CAMPAIGNS, CLOSE_CAMPAIGN } from '../query';
import campaignEventChannel from '../../../eventChannels';
import SearchAssessmentField from '../Assessments/CompanyAssessmentHeader/EvaluateSearchFields';

const ARCHIVE_CAMPAIGN = gql`
    mutation archiveCampaigns2($ids: [ID!]!) {
        archiveCampaigns(ids: $ids) {
            id
            status
        }
    }
`;

type Props = {
    setFilteredData: (data: any) => void;
    notFilteredData: CampaignTableRows[];
    selectedRow: string[];
    setFilterStatus: (data: string) => void;
    filterStatus: string;
    setSelectedRow: (data: string[]) => void;
    checkedCampaignsList: CampaignFilteredList[];
    user: User;
    selectedActiveCampaigns: Campaign[];
    client: Client;
    benchmarkingEnabled?: boolean;
    domainDescoped: boolean;
    pointOneDecimalEnable: boolean;
    setRefetchNeeded: any;
    setSearch?: any;
};

const EvaluateHeader: React.FC<Props> = ({
    setFilteredData,
    notFilteredData,
    selectedRow,
    setFilterStatus,
    filterStatus,
    setSelectedRow,
    checkedCampaignsList,
    user,
    selectedActiveCampaigns,
    client,
    benchmarkingEnabled,
    domainDescoped,
    pointOneDecimalEnable,
    setRefetchNeeded,
    setSearch
}) => {
    const [closeCampaign] = useMutation(CLOSE_CAMPAIGN, {
        refetchQueries: [
            {
                query: CAMPAIGNS,
                variables: {
                    clientId: client.id
                }
            }
        ],
        onCompleted: () => {
            setRefetchNeeded(true);
        }
    });
    const [archiveCampaign] = useMutation(ARCHIVE_CAMPAIGN);
    const [isOpen, setOpen] = useState(false);
    const [modalId, setModalId] = useState('');
    const [closeCampaignValue, setCloseCampaignValue] = useState({
        radioValue: '',
        assessmentType: 0,
        closeDate: new Date(0),
        closeCurrentDate: true
    });
    const [isSelectOpen, setSelectOpen] = useState(false);
    const [closeCampaignSnackbar, setCloseCampaignSnackbar] = useState(false);

    useEffect(() => {
        setCloseCampaignValue({...closeCampaignValue, closeDate: new Date(selectedActiveCampaigns[0] ? selectedActiveCampaigns[0].updatedAt: 0)});
    }, [selectedActiveCampaigns]);

    const handleToggleModal = useCallback(
        ({ open, renderModalId }: { open: boolean; renderModalId: string }) => {
            setOpen(open);
            setModalId(renderModalId);
        },
        []
    );

    const [isCheckboxEnable, setCheckBoxes] = useState([
        [false, false],
        [false, false],
    ]);

    const handleCloseCampaign = (data: {
        campaignId: string;
        assessmentType: number;
        submitForBenchmarking: string;
        pointOneDecimalEnable: boolean;
    }) => {
        const newAssessmentType = (type: number) => {
            switch (type) {
                case 1:
                    return 'PWC_FULL';
                case 2:
                    return 'PWC_LIGHT';
                case 3:
                    return 'SELF_ASSESSMENT';
                default:
                    return 'PWC_FULL';
            }
        };
        const submitForBenchmarking = data.submitForBenchmarking === 'yes';

        const pod = data.pointOneDecimalEnable;

        const newData = {
            ...data,
            submitForBenchmarking,
            assessmentType: newAssessmentType(data.assessmentType),
            pointOneDecimalEnable: pod,
            closedAt: closeCampaignValue.closeCurrentDate ? new Date() : closeCampaignValue.closeDate
        };
        closeCampaign({
            variables: {
                data: newData
            }
        }).then(() => {
            setOpen(false);
            setCloseCampaignValue({ radioValue: '', assessmentType: 0, closeDate: new Date(0), closeCurrentDate: true });
            setCloseCampaignSnackbar(true);
            setSelectedRow([]);
            campaignEventChannel.emit('onCampaignClose', true);
        }).catch(e => {
            console.error(e);
            alert(e);
        });
    };
    const searchCallback = (params: string) => {
        if (!params) {
            setFilteredData(notFilteredData);
            setSearch(false);
            return;
        }
        setSearch(true);
        const filteredList = notFilteredData.filter((campaign: any) => {
            return (
                campaign.name.props.children
                    .toLowerCase()
                    .indexOf(params.toLowerCase()) !== -1
            );
        });
        setFilteredData(filteredList);
    };

    const handleSelectCampaignStatus = (
        event: any
    ) => {
        const target = event.target as HTMLInputElement;
        setFilterStatus(target.value);
        setSelectedRow([]);
    };

    const tempClientCheck=(client.name === 'Global Implementation' && client.id === 251);

    return (
        <HeaderWrapper>
            <HeaderField style={{ width: '100%' }}>
                <H1Text>Campaigns</H1Text>
            </HeaderField>
            <HeaderField>
                <AlignItems>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={filterStatus}
                        onChange={(e)=>handleSelectCampaignStatus(e)}
                        inputProps={{
                            'aria-label':'select campaign status'
                        }}
                    >
                        {campaignStatusList.map(
                            (campaignStatus: CampaignStatus, i: number) => (
                                <option key={i} value={campaignStatus.id}>
                                    {campaignStatus.label}
                                </option>
                            )
                        )}
                    </CustomSelect>
                    {user && (user.role && user.countryUsers&&user.countryUsers.some(cu=>cu.campaignCreateEnabled)) && (
                        <RedButton
                            onClick={() => {
                                Router.goToEvaluateCreateCampaign();
                            }}
                        >
                            <img
                                src={addIcon}
                                alt="add icon"
                                style={{ marginRight: 10 }}
                            />
                            Create campaign
                        </RedButton>
                    )}
                    {selectedRow.length ? (
                        <Flex>
                             {(selectedRow.length === 1 && !tempClientCheck) && renderCloseButton({
                                filterStatus: selectedActiveCampaigns.length
                                    ? ''
                                    : 'CLOSED',

                                openModal: () =>
                                    handleToggleModal({
                                        open: true,
                                        renderModalId: 'close'
                                    }),
                                disabled: selectedRow.length > 1
                            })}
                            {renderArchiveButton({
                                filterStatus,
                                openModal: () =>
                                    handleToggleModal({
                                        open: true,
                                        renderModalId: 'archive'
                                    }),
                                disabled: Object.values(notFilteredData).find(s => selectedRow.includes(s.id) && s.isReadOnly === true) == undefined ? false : true
                            })}
                        </Flex>
                    ) : null}
                </AlignItems>
                <SearchAssessmentField placeholder="Search" callback={searchCallback}
                 setSearchValue={setSearch}/>
            </HeaderField>
            {renderModal({
                modalId,
                handleClose: () => setOpen(false),
                isOpen,
                closeCampaign: handleCloseCampaign,
                checkedCampaignsList,
                setSelectedRow,
                archiveCampaign,
                closeCampaignValue,
                setCloseCampaignValue,
                isSelectOpen,
                setSelectOpen,
                allCapabilitiesCompleted: selectedActiveCampaigns.every(campaign => campaign.progress === 100 && campaign.status === 'ACTIVE'),
                benchmarkingEnabled,
                domainDescoped,
                pointOneDecimalEnable,
                isCheckboxEnable,
                setCheckBoxes,
            })}
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={closeCampaignSnackbar}
                autoHideDuration={4000}
                icon="check"
                title="Campaign successfully closed"
                closeToast={setCloseCampaignSnackbar}
            />
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled(Column)`
    width: 100%;
    padding-bottom: 32px;
`;

const CustomSelect = styled(Select)`
    min-height: 48px;
    margin-right: 16px;
    .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 84px;
    }
`;

const HeaderField = styled(SpaceBetween)`
    margin: 0 20px;
`;

export default EvaluateHeader;
