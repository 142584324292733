import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { styled } from '@mui/material/styles';
import BoldText from '../base/Text/BoldText';
import Text from '../base/Text/Text';
import { Cell } from '../../modules/Evaluate/AssessmentsMaturityOverview/AssessmentsMaturityOverviewDetailsTab/types';
import moment, { Moment } from 'moment';
import { Theme,useTheme } from '@mui/material/styles';

function covertToTypes(value: any): any {
    if (value === undefined) return value;
    if (value.split('/').length === 2) {
        return parseInt(value.split('/')[0], 10) / parseInt(value.split('/')[1], 10);
    }
    if (value.includes('day') || value.includes('month') || value.includes('year') || value.includes('recent')||value.includes('hour')||value.includes('minute')) {
        const timeNow: Moment = moment();
        if (value.includes('recent')) return timeNow;
        if (value.includes('minute')) return timeNow.subtract(parseInt(value, 0), 'minutes');
        if (value.includes('hour')) return timeNow.subtract(parseInt(value, 0), 'hours');
        if (value.includes('day')) return timeNow.subtract(parseInt(value, 0), 'days');
        if (value.includes('month')) return timeNow.subtract(parseInt(value, 0), 'months');
        if (value.includes('year')) return timeNow.subtract(parseInt(value, 0), 'years');
    }
    if (moment(value).isValid()) {
        return moment(value);
    }
    return value;
}

function getStringFromObject(obj: any, str = ''): string {
    if (Array.isArray(obj) && obj.length > 0) {
        obj = obj.find((v) => (typeof v === 'object' || Array.isArray(v)));
        if (Array.isArray(obj) && obj.length > 0) {
            return str += getStringFromObject(obj, str) + ' ';
        }
    }
    if (typeof obj === 'object' && !Array.isArray(obj) && obj !== undefined) {
        if (Array.isArray(obj.props.children) || typeof obj.props.children === 'object') return str += getStringFromObject(obj.props.children, str);
        if (obj.props.children !== undefined) {
            str += obj.props.children  + ' ';
        }
        str += Object.values(obj.props).join(' ') + ' ';
    }
    return str;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
    const a_temp = (typeof a[orderBy] === 'object' && !Array.isArray(a[orderBy]) && a[orderBy] !== null) ? getStringFromObject(a[orderBy]) : a[orderBy];
    const b_temp = (typeof b[orderBy] === 'object' && !Array.isArray(b[orderBy]) && b[orderBy] !== null) ? getStringFromObject(b[orderBy]) : b[orderBy];
    const a_final = covertToTypes(typeof a_temp === 'string' ? a_temp.toLowerCase().trim().split(' ').join('-') : a_temp);
    const b_final = covertToTypes(typeof b_temp === 'string' ? b_temp.toLowerCase().trim().split(' ').join('-') : b_temp);
    if(typeof b_temp === 'string'&&typeof a_temp === 'string'&&orderBy==='progress'){
        const parsedValue_a=parseInt(a_temp,10);
        const parsedValue_b=parseInt(b_temp,10);
        if(isNaN(parsedValue_a)||isNaN(parsedValue_b)){
            return 0;
        }
        if (parsedValue_a > parsedValue_b) {
            return 1;
        }
        if (parsedValue_a < parsedValue_b) {
            return -1;
        }
    }
    // Checking when the values are date object
    if ((a_final !== undefined && b_final !== undefined) &&
        (a_final._isAMomentObject && b_final._isAMomentObject) &&
        a_final.isAfter(b_final)) {
        return 1;
    }
    if ((a_final !== undefined && b_final !== undefined) &&
        (a_final._isAMomentObject && b_final._isAMomentObject) &&
        a_final.isBefore(b_final)) {
        return -1;
    }

    // Checking when the values are string or number
    if (a_final > b_final) {
        return 1;
    }
    if (a_final < b_final) {
        return -1;
    }

    // Default returns 0
    return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(order: Order, orderBy: K):
    (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => desc(a, b, orderBy)
            : (a, b) => -desc(a, b, orderBy);
}

type EnhancedTableProps = {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    onSelectAllClick: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells?: Cell[];
    disable?: boolean;
    selectAllDisabled?: boolean;
};

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    headCells,
    disable,
    selectAllDisabled
}) => {
    const createSortHandler = (property: any) => (
        event: React.MouseEvent<unknown>
    ) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="secondary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        disabled={disable || selectAllDisabled}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells &&
                    headCells.map((headCell: any) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align || 'left'}
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                            sortDirection={
                                orderBy === headCell.id ? order : false
                            }
                            style={headCell.style ? headCell.style : {}}
                        >
                            {headCell.notSortable ? (
                                <TableHeadTitle>
                                    {headCell.label}
                                </TableHeadTitle>
                            ) : (
                                <CustomTableSortLabel
                                    active={
                                        orderBy === headCell.id ||
                                        headCell.sortLabel
                                    }
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                    IconComponent={ArrowDropUp}
                                >
                                    <TableHeadTitle>
                                        {headCell.label}
                                    </TableHeadTitle>
                                    {orderBy === headCell.id ? (
                                        <VisuallyHidden>
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </VisuallyHidden>
                                    ) : null}
                                </CustomTableSortLabel>
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

type Props = {
    header?: React.ReactNode;
    selectedHandler: any;
    rows: any[];
    headCells?: any[];
    selectedRows?: (number | string)[];
    selectAllDisabled?: boolean;
    search?: any;
};

const CustomTable: React.FC<Props> = ({
    header,
    rows,
    headCells,
    selectedHandler,
    selectedRows,
    selectAllDisabled,
    search
}) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState<any[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        setSelected(selectedRows || []);
    }, [selectedRows]);
    function handleRequestSort(_event: any, property: any) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id || n.name);
            selectedHandler(newSelecteds);
            setSelected(newSelecteds);
            return;
        }
        selectedHandler([]);
        setSelected([]);
    }

    function handleClick(name: any, disable: boolean) {
        if (disable) {
            return;
        }
        const selectedIndex = selected.indexOf(name);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        selectedHandler(newSelected);
    }

    function handleChangePage(_event: unknown, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    useEffect(()=>{
        setPage(0);
    },[search]);

    const isSelected = (name: any) => selected.indexOf(name) !== -1;
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const colsQuantity = () => {
        if (headCells) {
            return headCells.length + 1;
        }
        return rows.length ? Object.entries(rows[0]).length + 1 : 0;
    };

    return (
        <TableContainer>
            <CustomPaper>
                {header}
                {!!rows.length && (
                    <>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <TableWrapper>
                            <Table
                                style={{
                                    minWidth: 750
                                }}>
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    // tslint:disable-next-line: no-redundant-boolean
                                    disable={rows.filter((r) => (r.disable === true)).length > 0 ? true : false}
                                    selectAllDisabled={selectAllDisabled}
                                />
                                <TableBody>
                                    {stableSort(
                                        rows,
                                        getSorting(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(
                                                row.id || row.name
                                            );
                                            const labelId = 'select table row';
                                            const filteredRow = Object.entries(
                                                row
                                            ).reduce((acc: any, curr: any) => {
                                                if (curr[0] !== 'id' && curr[0] !== 'disable') {
                                                    acc[curr[0]] = curr[1];
                                                }
                                                return acc;
                                            }, {});
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={() => {
                                                        // tslint:disable-next-line: no-redundant-boolean
                                                        handleClick(row.id || row.name, row.disable ? true : false);
                                                    }}
                                                    key={index}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            // tslint:disable-next-line: no-redundant-boolean
                                                            disabled={row.disable ? true : false}
                                                            color="secondary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-label': labelId
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {Object.values(
                                                        filteredRow
                                                    ).map(
                                                        (
                                                            cell: any,
                                                            filteredRowIndex: number
                                                        ) => {
                                                            return (
                                                                <TableCell
                                                                    align={
                                                                        cell &&
                                                                        cell.type &&
                                                                        cell
                                                                            .type
                                                                            .name &&
                                                                        cell
                                                                            .type
                                                                            .name ===
                                                                            'EditButton'
                                                                            ? 'center'
                                                                            : 'left'
                                                                    }
                                                                    key={
                                                                        filteredRowIndex
                                                                    }
                                                                >
                                                                    {Array.isArray(
                                                                        cell
                                                                    )
                                                                        ? cell.map(
                                                                              (
                                                                                  el: any,
                                                                                  cellIndex: number
                                                                              ) => (
                                                                                  <HeaderSubTitle
                                                                                      style={{
                                                                                          marginRight: 5,
                                                                                          marginLeft: 5
                                                                                      }}
                                                                                      key={
                                                                                          cellIndex
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          el
                                                                                      }
                                                                                  </HeaderSubTitle>
                                                                              )
                                                                          )
                                                                        : cell}
                                                                </TableCell>
                                                            );
                                                        }
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 49 * emptyRows }}
                                        >
                                            <TableCell
                                                colSpan={colsQuantity()}
                                            />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableWrapper>
                    </>
                )}
            </CustomPaper>
        </TableContainer>
    );
};

const TableContainer = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(3),
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    boxShadow: 'none',
    marginBottom: theme.spacing(2)
}));

const TableWrapper = styled('div')`
    overflow-x: auto;
`;

const VisuallyHidden = styled('span')`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20px;
    width: 1px;
`;

const TableHeadTitle = styled(BoldText)`
    font-size: 14px;
    color: #6E6E6E;
`;

const HeaderSubTitle = styled(Text)`
    padding: 10px;
    &:hover {
        cursor: pointer;
        background-color: #d2d2d2;
    }
`;

const CustomTableSortLabel = styled(TableSortLabel)`
    &:focus-visible {
        padding: 2px;
        border: 2px solid black;
    }
`;
export default CustomTable;
