import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ClientList from './SidebarClient/ClientList';
import Router from '../../../routes/router';
import {
    Text,
    BoldText,
    Menu,
    Button,
    RedButton,
    Column,
    SpaceBetween,
    AlignItems,
    SpinnerWithOverlay,
} from '../../../components';
import { styled } from '@mui/material/styles';
import { Avatar, IconButton, ListItemText, MenuItem } from '@mui/material';
import { UserStore } from '../../../context/user-store';
import { capitalizeFirstLetter } from '../../../utils/UtilsHelpers';
import closeIcon from '../../../assets/images/icon-close.svg';
import dropdownArrow from '../../../assets/images/icon-drop-down.svg';
import { removeJwtFromWhitelist } from '../../../services/ApolloClient/client';
import { setLocalStorageItem } from '../../../utils/local-storage';
import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Country } from '../types';
import { Client as ClientType, User } from '../../../context/reducer';
import SearchCompaniesField from './SidebarClient/SearchCompaniesField';
import { CLIENT_FETCH_LIMIT } from '../../../utils';
import { useAuth } from 'react-oidc-context';
export const COUNTRIES = gql`
    query countries {
        countries {
            id
            isoCode
        }
    }
`;

const clientsWithPagination = gql`
    query ClientsWithPagination($pagination: PaginationInput!) {
        getClientsPagination(pagination: $pagination) {
            total
            skip
            take
            count
            items {
                id
                name
                countryId
                isSynced
                country
                benchmarkingEnabled
                owner {
                    id
                }
            }
        }
    }
`;

type Props = {
    companiesAreOpen: boolean;
    setCompaniesAreOpen: CallableFunction;
};

const CompanyModal: React.FC<Props> = ({
    companiesAreOpen,
    setCompaniesAreOpen
}) => {
    const auth = useAuth();
    const {
        state: { user, client },
    } = useContext(UserStore);
    const { data } = useQuery(COUNTRIES);
    const countries =
        data &&
        data.countries &&
        data.countries.filter(
            (value: Country) =>
                user.clients &&
                user.clients.findIndex((x) => x.countryId === value.id) !== -1
        );
    useEffect(() => {
        setLocalStorageItem('client', JSON.stringify(client));
    }, [client]);
    const shouldClose =
        !!client || (user && user.clients && user.clients.length === 1);

    const [clientsList, setClientsList] = useState<ClientType[]>([]);
    const [clientsPageOffset, setClientsPagePageOffset] =
        useState<number>(0);

    const [clientsPageInfo, setClientsPageInfo] = useState<{
        count: number;
        skip: number;
        take: number;
        total: number;
    }>({
        count: 0,
        skip: 0,
        take: 0,
        total: 0,
    });
    const [searchValue, setSearchValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [showButton, setShowButton] = useState<boolean>(false);
    const [getClients] = useLazyQuery<any>(clientsWithPagination, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (res) => {
            setClientsPageInfo({
                count: res.getClientsPagination.count,
                skip: res.getClientsPagination.skip,
                take: res.getClientsPagination.take,
                total: res.getClientsPagination.total,
            });
            setLoading(false);
            if (search && search.length > 0) {
                setClientsList(res.getClientsPagination.items);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setClientsList((prevResult: any) => {
                    const newData = [
                        ...prevResult,
                        ...res.getClientsPagination.items,
                    ];
                    return Array.from(new Set(newData));
                });
            }
        },
        onError: (err) => console.error(err.stack),
    });

    useEffect(() => {
        setLoading(true);
        if (search) {
            getClients({
                variables: {
                    pagination: {
                        search: search,
                    },
                },
            });
        } else {
            getClients({
                variables: {
                    pagination: {
                        skip: clientsPageOffset * CLIENT_FETCH_LIMIT,
                        take: CLIENT_FETCH_LIMIT,
                    },
                },
            });
        }
    }, [clientsPageOffset, search, showButton]);

    useEffect(() => {
        (clientsList &&
            clientsList.length > 0 &&
            clientsList.length === clientsPageInfo.total) ||
        clientsPageInfo.total === 0
            ? setShowButton(true)
            : setShowButton(false);
    }, [clientsList]);

    const searchCallback = (params: string) => {
        if (!params) {
            setClientsPagePageOffset(0);
            setClientsList([]);
            setShowButton(false);
            setSearch('');
        } else {
            setSearch(params);
        }
    };
    return !user ? (
        <SpinnerWithOverlay />
    ) : (
        <Modal
            isOpen={companiesAreOpen}
            shouldCloseOnOverlayClick={shouldClose}
            contentLabel="Company Modal"
            ariaHideApp={false}
            style={customStyles}
            onRequestClose={() => shouldClose && setCompaniesAreOpen(false)}>
            <ContentWrapper>
                <Header>
                    <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        {(!!client ||
                            (user &&
                                user.clients &&
                                user.clients.length === 1)) && (
                            <IconButton
                                aria-label="close company modal"
                                style={{
                                    marginRight: 10,
                                    width: 20,
                                    height: 20,
                                }}
                                onClick={() =>
                                    setCompaniesAreOpen(!companiesAreOpen)
                                }
                            >
                                <img
                                    src={closeIcon}
                                    alt="close icon"
                                    aria-hidden={true}
                                    style={{
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            </IconButton>
                        )}
                    </div>
                    <HeaderContent>
                        <AlignItems>
                            <CustomAvatar>
                                {user.firstName && user.firstName[0]}
                                {user.lastName && user.lastName[0]}
                            </CustomAvatar>
                            <Column>
                                <AlignItems>
                                    <NameText>Hello, {user.firstName}</NameText>
                                    <Menu
                                        button={
                                            <img
                                                src={dropdownArrow}
                                                alt="dropdown icon"
                                                aria-hidden={true}
                                            />
                                        }
                                        ariaLabel={'user actions'}
                                    >
                                        <MenuItem
                                            onClick={async () => {
                                                auth.signoutSilent();
                                                auth.removeUser();
                                                await removeJwtFromWhitelist();
                                                Router.goToLogoutPage();
                                            }}
                                        >
                                            <ListItemText primary="Logout" />
                                        </MenuItem>
                                    </Menu>
                                </AlignItems>
                                <UserRoleText>
                                    {user &&
                                        user.jobTitle &&
                                        capitalizeFirstLetter(user.jobTitle)}
                                </UserRoleText>
                            </Column>
                        </AlignItems>
                        {user.role === 'ADMIN' && (
                            <AdminButton onClick={Router.goToAdmin}>
                                Admin panel
                            </AdminButton>
                        )}
                    </HeaderContent>
                </Header>
                {user && user.clients && user.clients.length ? (
                    <>
                        <SearchCompaniesField
                            placeholder="Search for a company..."
                            callback={searchCallback}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                        />
                        <Heading>
                            <SubheadingText>
                                Your Companies and Campaigns
                            </SubheadingText>
                            <SubheadingText style={{ width: '40.5%' }}>
                                Campaigns’ progress
                            </SubheadingText>
                        </Heading>
                        <ClientList
                            showLoading={loading}
                            clientsPageOffset={clientsPageOffset}
                            setClientsPagePageOffset={setClientsPagePageOffset}
                            showButton={showButton}
                            clients={clientsList}
                            countries={countries}
                            companiesAreOpen={companiesAreOpen}
                            setCompaniesAreOpen={setCompaniesAreOpen}
                        />
                    </>
                ) : (
                    <BoldText>
                        You don't have any companies, please contact
                        administrator.
                    </BoldText>
                )}
            </ContentWrapper>
            <FooterWrapper>
                {user.role &&
                    user.countryUsers &&
                    user.countryUsers.some(
                        (cu) => cu.campaignCreateEnabled
                    ) && (
                        <FooterBlueButton
                            onClick={() => {
                                Router.goToEvaluateCreateCampaign();
                                setCompaniesAreOpen(false);
                            }}
                        >
                            Create campaign
                        </FooterBlueButton>
                    )}
            </FooterWrapper>
        </Modal>
    );
};

const customStyles = {
    content: {
        top: '50%',
        left: '20%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        overflow: 'hidden',
        height: '100%',
        border: 'none',
        backgroundColor: '#fff',
        padding: 0,
        '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            background: 'black',
            left: 0,
            right: 0,
            top: 0,
            height: '100%',
            opacity: 0.5,
        },
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 2,
    },
};

const ContentWrapper = styled(Column)`
    overflow: auto;
    align-items: center;
    width: 100%;
    height: 90%;
`;

const Header = styled('div')`
    width: 100%;
    background-color: #2d2d2d;
    padding-top: 10px;
`;

const HeaderContent = styled(SpaceBetween)`
    align-items: center;
    margin: 5px 32px 24px 32px;
`;

const NameText = styled(BoldText)`
    font-size: 22px;
    color: #fff;
    margin-right: 8px;
`;

const CustomAvatar = styled(Avatar)`
    && {
        background-color: #de3328;
        margin-right: 16px;
        padding: 10px;
        font-weight: bold;
    }
`;

const UserRoleText = styled(Text)`
    font-size: 15px;
    color: #dddddd;
`;

const AdminButton = styled(Button)`
    && {
        background-color: transparent;
        color: #ffffff;
        font-size: 16px;
        border: solid 1.5px #ffffff;
    }
`;

const Heading = styled('div')`
    background-color: #ebebeb;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 11px 11px 11px 27px;
`;

const SubheadingText = styled(Text)`
    font-size: 13px;
    font-weight: 500;
    color: #575757;
`;

const FooterWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
`;

const FooterBlueButton = styled(RedButton)`
    width: 90%;
`;

export default CompanyModal;
