import React, { useCallback, useEffect, useState } from 'react';
import AlignItems from '../base/Layout/AlignItems';
import ImgContainer from '../base/ImgContainer';
import JustifyContentCenter from '../base/Layout/JustifyContentCenter';
import Menu from '../Menu/index';
import Text from '../base/Text/Text';
import dropdownArrow from '../../assets/images/icon-drop-down-dark.svg';
import { IconButton, ListSubheader, MenuItem } from '@mui/material';
import {
    AssessmentCapability,
    AssessmentData,
    AssessmentDomain,
    CapabilityMenuProps, NavigateStateProps
} from '../../modules/Evaluate/MaturityScore/types';
import { styled } from '@mui/material/styles';
import buttonDisabledLeft from '../../assets/images/button-disabled-left.svg';
import buttonActiveLeft from '../../assets/images/button-active-left.svg';
import buttonDisabledRight from '../../assets/images/button-disabled-right.svg';
import buttonActiveRight from '../../assets/images/button-active-right.svg';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import NestedMenuItem from './NestedMenuItem';
import { Toast } from '../../components';
import DialogBox from '../base/DialogBox';
import { autoSaveMessage, PermissionTypeEnum } from '../../utils';
import iconleftarrow from '../../assets/images/icon-grey-arrow-left.svg';
import iconrightarrow from '../../assets/images/icon-grey-arrow-right.svg';
import _ from 'lodash';

const ASSESSMENT_BY_ID = gql`
    query assessment1($id: ID!) {
        assessment(id: $id) {
            id
            permission
            state {
                domains {
                    id
                    name
                    feedback {
                        id
                        title
                        observations {
                            text
                        }
                        recommendations {
                            text
                        }
                    }
                    capabilities {
                        id
                        name
                        averageScore
                        inScope
                        status
                        updateScopeReason
                        moderatedScore
                        objectives {
                            score
                            inScope
                        }
                        workingNotes {
                            text
                        }
                        feedback {
                            id
                            title
                            observations {
                                text
                            }
                            recommendations {
                                text
                            }
                        }
                    }
                }
            }
        }
    }
`;

const CapabilityMenu = ({
    currentDomainData,
    currentCapabilityData,
    currentIdsData,
    assessmentDomainsListOriginal,
    setCurrentIdsData,
    assessmentId,
    level,
    setResponseStatusData,
    readOnly,
    isDirty,
    setDirty,
    assessmentReadOnly
}: CapabilityMenuProps) => {
    const { currentDomainIndex, setCurrentDomainIndex } = currentDomainData;
    const [successToastOpen, setSuccessToastOpen] = useState(false);

    const {
        currentCapability,
        currentCapabilityIndex,
        setCurrentCapabilityIndex
    } = currentCapabilityData;

    const [isMenuOpen, setIsMenuOpenStatus] = useState<boolean>(false);
    const [isLeavePopUpOpen, setIsLeavePopUpOpen] = useState<boolean>(false);
    const [navigateInformation, setNavigateInformation] = useState<NavigateStateProps>({});
    const  [leftArrowIcon, setleftArrowIcon ] = useState<boolean>(false);
    const  [rightArrowIcon, setrightArrowIcon ] = useState<boolean>(false);
    const  [lastDomainIndex, setLastDomainIndex ] = useState<number>(0);
    const  [lastCapabilityIndex, setLastCapabilityIndex ] = useState<number>(0);
    const assessmentDomainsList = _.cloneDeep(assessmentDomainsListOriginal);
    const { loading: assessmentLoading, data: assessmentData } = useQuery<
            AssessmentData
        >(ASSESSMENT_BY_ID, {
            variables: {
                id: assessmentId
            }
        });

    const readOnlyAssessment = ( assessmentData?.assessment.permission && assessmentData?.assessment.permission === PermissionTypeEnum.READ_ONLY_ACCESS );

    if (readOnly || readOnlyAssessment) {
        // The user has read only access to this campaign and should not be able to access incomplete capabilities.
        for (let i = 0; i < assessmentDomainsList.length; i++) {
            const domain = assessmentDomainsList[i];
            for (let x = 0; x < domain.capabilities.length; x++) {
                if (domain.capabilities[x].status !== 'COMPLETED') {
                    assessmentDomainsList[i].capabilities[x].disabled = true;
                }
            }
            if (assessmentDomainsList[i].capabilities.filter(c => c.disabled !== true).length < 1) {
                // Mark this domain as disabled as it is devoid of accessible capabilities.
                assessmentDomainsList[i].disabled = true;
            }
        }
    }

    useEffect(() => {
        if (assessmentLoading || !assessmentData) {
            return undefined;
        }

        const domainIndex = assessmentData.assessment.state.domains.findIndex(
            (item: AssessmentDomain) => {
                return item.id === currentIdsData.domainId;
            }
        );

        if (domainIndex === -1) {
            return undefined;
        }
        
        setLastDomainIndex(assessmentData.assessment.state.domains.length);
        setLastCapabilityIndex(assessmentData.assessment.state.domains[
            domainIndex
        ].capabilities.length);

        const capabilityIndex = assessmentData.assessment.state.domains[
            domainIndex
        ].capabilities.findIndex((item: AssessmentCapability) => {
            return item.id === currentIdsData.capabilityId;
        });

        setCurrentDomainIndex(domainIndex);
        setCurrentCapabilityIndex(capabilityIndex);

        if(readOnly || readOnlyAssessment)
        {
            handleDescopeCapabilities_readonly(domainIndex, capabilityIndex);
        }

    }, [assessmentData, assessmentLoading, currentIdsData]);

    const handleDescopeCapabilities = (
        status: string,
        domIndex: number,
        capIndex: number
    ) => {
        if (status === 'next') {
            for (
                let dIndex = domIndex;
                dIndex < assessmentDomainsList.length;
                dIndex++
            ) {
                for (
                    let cIndex = 0;
                    cIndex < assessmentDomainsList[dIndex].capabilities.length;
                    cIndex++
                ) {
                    if (((dIndex === domIndex) && (cIndex <= capIndex)) || assessmentDomainsList[dIndex].capabilities[cIndex].inScope === false) {
                        continue;
                    }

                    return {
                        dIndex,
                        cIndex
                    };
                }
            }
        }

        if (status === 'prev') {
            for (
                let dIndex = domIndex;
                dIndex > -1;
                dIndex--
            ) {
                for (
                    let cIndex = assessmentDomainsList[dIndex].capabilities.length - 1;
                    cIndex > -1;
                    cIndex--
                ) {
                    if ((dIndex === domIndex && cIndex >= capIndex) || assessmentDomainsList[dIndex].capabilities[cIndex].inScope === false) {
                        continue;
                    }

                    return {
                        dIndex,
                        cIndex
                    };
                }
            }
        }

        return {
            dIndex: -1,
            cIndex: -1
        };
    };

    const handleDescopeCapabilities_readonly =(
        domIndex: number,
        capIndex: number,
        status?: string,
    ) => {
        const marksList: { domain: string; capability: string }[] = [];
            for (
                let dIndex = 0;
                dIndex < assessmentDomainsList.length;
                dIndex++
            ) {
                for (
                    let cIndex = 0;
                    cIndex < assessmentDomainsList[dIndex].capabilities.length;
                    cIndex++
                ) {
                    if (assessmentDomainsList[dIndex].capabilities[cIndex].status === 'COMPLETED') {
                        marksList.push({domain: assessmentDomainsList[dIndex].name, capability: assessmentDomainsList[dIndex].capabilities[cIndex].name});
                 }
                     else
                     {
                        continue;
                     }
                }
            }
            const marksLength= marksList.length;

            if(assessmentDomainsList.length > 0 && marksList.length > 0)
            {
                if(assessmentDomainsList[domIndex].capabilities[capIndex].name === marksList[0].capability && assessmentDomainsList[domIndex].capabilities[capIndex].name === marksList[marksLength-1].capability   )
                    {
                        setleftArrowIcon(true);
                        setrightArrowIcon(true);
                    }
                    else if(assessmentDomainsList[domIndex].capabilities[capIndex].name === marksList[0].capability)
                    {
                        setleftArrowIcon(true);
                    }
                    else if(assessmentDomainsList[domIndex].capabilities[capIndex].name === marksList[marksLength-1].capability  )
                    {
                        setrightArrowIcon(true);
                    }
             }


           if (status === 'next') {
            for (
                let dIndex = domIndex;
                dIndex < assessmentDomainsList.length;
                dIndex++
            ) {
                for (
                    let cIndex = 0;
                    cIndex < assessmentDomainsList[dIndex].capabilities.length;
                    cIndex++
                ) {
                    if (((dIndex === domIndex) && (cIndex <= capIndex)) || assessmentDomainsList[dIndex].capabilities[cIndex].status !== 'COMPLETED') {
                        continue;
                    }
                    else

                        if(assessmentDomainsList[dIndex].name === marksList[marksLength-1].domain && assessmentDomainsList[dIndex].capabilities[cIndex].name === marksList[marksLength-1].capability)
                        {
                            setrightArrowIcon(true);
                        }

                            setleftArrowIcon(false);
                    return {
                        dIndex,
                        cIndex
                    };
                }
            }


        }
        if (status === 'prev') {

            for (
                let dIndex = domIndex;
                dIndex > -1;
                dIndex--
            ) {
                for (
                    let cIndex = assessmentDomainsList[dIndex].capabilities.length - 1;
                    cIndex > -1;
                    cIndex--
                ) {
                    if ((readOnly || readOnlyAssessment) && (dIndex === domIndex && cIndex >= capIndex) || assessmentDomainsList[dIndex].capabilities[cIndex].status !== 'COMPLETED') {
                        continue;
                    }
                    else

                        if(assessmentDomainsList[dIndex].name === marksList[0].domain && assessmentDomainsList[dIndex].capabilities[cIndex].name === marksList[0].capability)
                        {
                            setleftArrowIcon(true);
                        }
                        setrightArrowIcon(false);
                    return {
                        dIndex,
                        cIndex
                    };
                }
            }
        }
        return {
            dIndex: -1,
            cIndex: -1
        };
    };

    const handleChangeCurrentCapabilityIndex = useCallback(
        (status: string, domIndex: number, capIndex: number) => {
            if (!assessmentDomainsList.length) {
                return;
            }
            if(readOnly || readOnlyAssessment)
            {
                const handleDescopeCapability_readonly: {
                    dIndex: number;
                    cIndex: number;
                } = handleDescopeCapabilities_readonly(domIndex, capIndex,status);
                const { dIndex, cIndex } = handleDescopeCapability_readonly;
                if (dIndex !== -1) {
                    const domain = assessmentDomainsList[dIndex];
                    const domainId = domain.id;
                    const capabilityId = domain.capabilities[cIndex].id;

                    setCurrentIdsData({
                        domainId,
                        capabilityId
                    });
                    setResponseStatusData && setResponseStatusData(null);
                    setCurrentCapabilityIndex(cIndex);
                    setCurrentDomainIndex(dIndex);

                    return;
            }}

            const handleDescopeCapability: {
                dIndex: number;
                cIndex: number;
            } = handleDescopeCapabilities(status, domIndex, capIndex);
            const { dIndex, cIndex } = handleDescopeCapability;

            if (dIndex !== -1) {
                const domain = assessmentDomainsList[dIndex];
                const domainId = domain.id;
                const capabilityId = domain.capabilities[cIndex].id;

                setCurrentIdsData({
                    domainId,
                    capabilityId
                });
                setResponseStatusData && setResponseStatusData(null);
                setCurrentCapabilityIndex(cIndex);
                setCurrentDomainIndex(dIndex);

                return;
            }
        },
        [assessmentDomainsList]
    );

    const handleChangeCurrentDomainIndex = useCallback(
        (status: string, index: number) => {
            let i = index;
            if (status === 'next' && assessmentDomainsList) {
                while (i < assessmentDomainsList.length && (assessmentDomainsList[i] && assessmentDomainsList[i + 1] !== undefined )) {
                  
                        const domainId = assessmentDomainsList[i + 1].id;
                        const capabilityId = assessmentDomainsList[i + 1].capabilities[0].id;
                        if(readOnly || readOnlyAssessment){
                            if (assessmentDomainsList[i + 1].capabilities.every(c => c.status === 'COMPLETED')) {
                                setCurrentIdsData({domainId,capabilityId });
                                setResponseStatusData && setResponseStatusData(null);
                                setCurrentDomainIndex(i + 1);
                               
                            } else{
                                i++;
                            }

                        } else{
                            console.log(readOnly , readOnlyAssessment);
                            setCurrentIdsData({domainId,capabilityId });
                            setResponseStatusData && setResponseStatusData(null);
                            setCurrentDomainIndex(i + 1);
                        }
                        return;
                }
            }

            if (status === 'prev' && assessmentDomainsList) {
                while (i > 0 && (assessmentDomainsList[i] && assessmentDomainsList[i - 1] !== undefined)) {
                        const domainId = assessmentDomainsList[i - 1].id;
                        const capabilityId = assessmentDomainsList[i - 1].capabilities[0].id;

                        if(readOnly || readOnlyAssessment){
                            if (assessmentDomainsList[i - 1].capabilities.every(c => c.status === 'COMPLETED')) {
                                setCurrentIdsData({ domainId, capabilityId});
                                setResponseStatusData && setResponseStatusData(null);
                                setCurrentDomainIndex(i - 1);
                            } else{
                                 i--;
                            }

                        }else{
                            setCurrentIdsData({ domainId, capabilityId});
                            setResponseStatusData && setResponseStatusData(null);
                            setCurrentDomainIndex(i - 1);
                        }
                        return;
                }
                
            }
        },
        [assessmentDomainsList]
    );

    const renderContent = () => {
        const contentList = {
            capabilities: (
                <AlignItems>
                    <NoWrapText>
                        {`${currentCapabilityIndex + 1} /
                                ${assessmentDomainsList[currentDomainIndex] &&
                                assessmentDomainsList[currentDomainIndex]
                                    .capabilities
                                ? assessmentDomainsList[
                                    currentDomainIndex
                                ].capabilities.length
                                : ''
                            }`}
                        &nbsp;
                    </NoWrapText>
                    <NoWrapText
                        style={{
                            fontWeight: level === 'maturity' ? 'bold' : 'normal'
                        }}
                    >
                        {currentCapability ? currentCapability.name : ''}
                    </NoWrapText>
                </AlignItems>
            ),
            domains: (
                <AlignItems>
                    {level === 'domains' && (
                        <NoWrapText>
                            {`${currentDomainIndex +
                                1} / ${assessmentDomainsList.length || ''}`}
                            &nbsp;
                        </NoWrapText>
                    )}
                    <NoWrapText
                        style={{
                            fontWeight:
                                level === 'capabilities' ? 'bold' : 'normal'
                        }}
                    >
                        {assessmentDomainsList[currentDomainIndex]
                            ? assessmentDomainsList[currentDomainIndex].name
                            : ''}
                    </NoWrapText>
                </AlignItems>
            ),
            domainsAndCapabilities: (
                <AlignItems>
                    <NoWrapText
                        style={{
                            fontWeight: level === 'maturity' ? 'bold' : 'normal'
                        }}
                    >
                        {`${assessmentDomainsList[currentDomainIndex]
                            ? assessmentDomainsList[currentDomainIndex].name
                            : ''} | ${currentCapability ? currentCapability.name : ''}`}
                    </NoWrapText>
                </AlignItems>
            )
        };

        if (level === 'domains') {
            return contentList.domains;
        }
        if (level === 'capabilities') {
            return (
                <>
                    {contentList.capabilities}
                    {contentList.domains}
                </>
            );
        }
        if (level === 'domains-and-capabilities') {
            return contentList.domainsAndCapabilities;
        }

        return contentList.capabilities;
    };

    const domainDropdown = (domain: AssessmentDomain, index: number) => {
        if(!isDirty) {
            setCurrentDomainIndex(index);
            setCurrentCapabilityIndex(0);
            setCurrentIdsData({ domainId: domain.id, capabilityId: domain.capabilities[0].id });

            setIsMenuOpenStatus(false);
        }
        else{
            setDirty&& setDirty(false);
            setIsLeavePopUpOpen(true);
            setNavigateInformation({domainIndex:index,capabilityIndex:0,domain:domain});
        }
    };

    const domainAndCapsDropdown = (
        domain: AssessmentDomain,
        index: number,
        i: number | null
    ) => {
        setResponseStatusData && setResponseStatusData(null);
        if (i === null) {
            setSuccessToastOpen(true);
            return;
        }
        if (!isDirty) {
            setCurrentDomainIndex(index);
            setCurrentCapabilityIndex(i);
            setCurrentIdsData({
                domainId: domain.id,
                capabilityId: domain.capabilities[i].id
            });
            setIsMenuOpenStatus(false);
        }
        else{
            setDirty&& setDirty(false);
            setIsLeavePopUpOpen(true);
            setNavigateInformation({domainIndex:index,capabilityIndex:i,domain:domain});
        }
    };

    const renderDropdown = () => {
        const contentList = {
            domainsOnly: (
                assessmentDomainsList &&
                    assessmentDomainsList.map(
                        (domain: AssessmentDomain, index: number) => (
                            <MenuItem
                                disabled={domain.disabled}
                                onClick={() =>
                                    domainDropdown(domain, index)
                                }
                                key={domain.id}
                            >
                                {domain.name || 'No name provided'}
                            </MenuItem>
                        )
                    )
            ),
            domainsAndCaps: (
                assessmentDomainsList &&
                    assessmentDomainsList.map(
                        (domain: AssessmentDomain, index: number) => (
                            [
                                <DarkListSubheader key={domain.id}>
                                    {domain.name || 'No name provided'}
                                </DarkListSubheader>,
                                domain.capabilities.map((cap, i) => (
                                    <MenuItem
                                        disabled={domain.disabled}
                                        onClick={() =>
                                            domainAndCapsDropdown(
                                                domain,
                                                index,
                                                i
                                            )
                                        }
                                        key={cap.id}
                                    >
                                        {cap.name || 'No name provided'}
                                    </MenuItem>
                                ))
                            ]
                        )
                    )
            ),
            domainsAndCapabilities: (
                assessmentDomainsList &&
                    assessmentDomainsList.map(
                        (domain: AssessmentDomain, index: number) => (
                            <NestedMenuItem
                                    key={domain.id}
                                    disabled={domain.disabled}
                                    label={domain.name || 'No name provided'}
                                    mainMenuOpen={isMenuOpen}
                                >
                                    {domain.capabilities.map((cap, i) => (
                                        <MenuItem
                                            key={cap.id}
                                            disabled={cap.disabled || !(cap.inScope)}
                                            onClick={() => {
                                                setIsMenuOpenStatus(false);
                                                domainAndCapsDropdown(
                                                    domain,
                                                    index,
                                                    i
                                                );
                                        }}>{cap.name || 'No name provided'}</MenuItem>
                                    ))}
                            </NestedMenuItem>
                ))
            )
        };

        if (level === 'domains') {
            return contentList.domainsOnly;
        }
        if (level === 'capabilities') {
            return contentList.domainsAndCaps;
        }
        if (level === 'domains-and-capabilities') {
            return contentList.domainsAndCapabilities;
        }

        return contentList.domainsAndCaps;
    };

    const renderCurrentIconRight = useCallback(
        (domainIndex: number, capabilityIndex: number) => {

            if (readOnly || readOnlyAssessment) {
                let canProceed = false;
                // Read only alters the behavior, because the next domain might be inaccessible.
                for (let i = (domainIndex + 1); i < assessmentDomainsList.length; i++) {
                    // Loop through remaining domains and check if there are any more which the user can access.
                    if (!assessmentDomainsList[i].disabled) {
                        // At least one upcoming domain is accessible
                        canProceed = true;
                    }
                }

                if (!canProceed && assessmentDomainsList[domainIndex] &&
                    capabilityIndex ===
                    assessmentDomainsList[domainIndex].capabilities.length - 1) {
                    return buttonDisabledRight;
                }

            }

            if (
                assessmentDomainsList[domainIndex] &&
                capabilityIndex ===
                assessmentDomainsList[domainIndex].capabilities.length - 1 &&
                domainIndex === assessmentDomainsList.length - 1
            ) {
                return buttonDisabledRight;
            }
            return buttonActiveRight;
        },
        [assessmentDomainsList]
    );

    const renderCurrentIconLeft = useCallback(
        (domainIndex: number, capabilityIndex: number) => {
            if (domainIndex === 0 && capabilityIndex === 0) {
                return buttonDisabledLeft;
            }
            return buttonActiveLeft;
        },
        [assessmentDomainsList]
    );

    const handleChange = (
        status: string,
        domainIndex: number,
        capabilityIndex: number
    ) => {
        if(!isDirty) {
            if (level === 'domains') {
                handleChangeCurrentDomainIndex(status, domainIndex);
                return;
            }
            handleChangeCurrentCapabilityIndex(
                status,
                domainIndex,
                capabilityIndex
            );
        } else {
            setDirty&& setDirty(false);
            setIsLeavePopUpOpen(true);
            setNavigateInformation({
                domainIndex: domainIndex,
                capabilityIndex: capabilityIndex,
                status: status
            });
        }
        return;
    };

    const updateDomainsAndCap = () => {
        setDirty && setDirty(false);
        setIsLeavePopUpOpen(false);

        const { domain , domainIndex, capabilityIndex, status } = navigateInformation;

        if(status && domainIndex !== undefined && capabilityIndex !== undefined){
            if (level === 'domains') {
                handleChangeCurrentDomainIndex(status, domainIndex);

                return;
            }

            handleChangeCurrentCapabilityIndex(
                status,
                domainIndex,
                capabilityIndex
            );
        }

        if ((level === 'domains'||level===undefined)&&domain!==undefined&&domainIndex!==undefined) {
            setCurrentDomainIndex(domainIndex);
            setCurrentCapabilityIndex(0);
            setCurrentIdsData({ domainId: domain.id, capabilityId: domain.capabilities[0].id });
        }

        if (level === 'capabilities' && domain !== undefined && domainIndex !== undefined && capabilityIndex !== undefined) {
            setCurrentDomainIndex(domainIndex);
            setCurrentCapabilityIndex(capabilityIndex);
            setCurrentIdsData({
                domainId:domain.id,
                capabilityId:domain.capabilities[capabilityIndex].id
            });
        }

        if (level === 'domains-and-capabilities' && domain !== undefined && domainIndex !== undefined && capabilityIndex !== undefined) {
            setCurrentDomainIndex(domainIndex);
            setCurrentCapabilityIndex(capabilityIndex);
            setCurrentIdsData({
                domainId:domain.id,
                capabilityId:domain.capabilities[capabilityIndex].id
            });
        }

    };
    return (
        <>
            <MenuContainer>
                {level !== 'domains-and-capabilities' ? 
                    (<IconButton onClick={() =>
                        handleChange(
                            'prev',
                            currentDomainIndex,
                            currentCapabilityIndex
                        )} aria-label='left'>
                        <img src={renderCurrentIconLeft(currentDomainIndex, currentCapabilityIndex)} alt="btn-active-left" aria-hidden={true} />
                    </IconButton>) : 
                    ((currentDomainIndex === 0 && currentCapabilityIndex === 0) || leftArrowIcon)? '' :
                    <ArrowImage
                        src={iconleftarrow}
                        alt="grey arrow left"
                        onClick={() =>
                            handleChange(
                                'prev',
                                currentDomainIndex,
                                currentCapabilityIndex
                            )
                        }
                        style={{
                            height: '16px',
                            width: '16px',
                        }}>
                    </ArrowImage>
                }
                <Menu
                    top={'-2%'}
                    isMenuOpen={isMenuOpen}
                    buttonWrapperWidth="100%"
                    menuStyles={{
                        width: '100%'
                    }}
                    setMenuStatus={setIsMenuOpenStatus}
                    button={
                        <MenuButtonContainer id="skip-to-content" tabIndex={-1}>
                            <TextContainer>{renderContent()}</TextContainer>
                            <ImgContainer
                                src={dropdownArrow}
                                alt="dropdown arrow"
                                aria-hidden
                            />
                        </MenuButtonContainer>
                    }>
                    {isMenuOpen ? renderDropdown() : ''}
                </Menu>
                {level !== 'domains-and-capabilities' ? 
                    (<IconButton
                        onClick={() =>
                            handleChange(
                                'next',
                                currentDomainIndex,
                                currentCapabilityIndex
                            )} aria-label="right">
                        <img src={renderCurrentIconRight(currentDomainIndex, currentCapabilityIndex)} alt="btn-active-right" aria-hidden={true} />
                    </IconButton>) : 
                    ((lastDomainIndex === currentDomainIndex + 1 && lastCapabilityIndex === currentCapabilityIndex + 1) || (rightArrowIcon)) ? '' : 
                    <ArrowImage
                        src={iconrightarrow}
                        alt="grey arrow right"
                        onClick={() =>
                            handleChange(
                                'next',
                                currentDomainIndex,
                                currentCapabilityIndex
                            )
                        }>
                    </ArrowImage>
                }
                <Toast
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={successToastOpen}
                    autoHideDuration={3000}
                    icon="error"
                    width="379px"
                    title="Selected Domain dont have any Capabilities."
                    closeToast={() => setSuccessToastOpen(false)}
                />
                <DialogBox
                    title={''}
                    content={autoSaveMessage}
                    open={isLeavePopUpOpen}
                    handleClose={() => {
                        setIsLeavePopUpOpen(false);
                        setDirty && setDirty(true);
                    }}
                    handleSubmit={() => updateDomainsAndCap()}
                    buttonCancelText={'No'}
                    buttonSaveText={'Yes'}
                />
            </MenuContainer>
        </>
    );
};


const ArrowImage = styled('img')`
cursor: pointer;
`;

const MenuContainer = styled(JustifyContentCenter)`
    min-width: 400px;
`;

const MenuButtonContainer = styled(AlignItems)`
    min-width: 250px;
    background: transparent;
    padding: 10px;
    justify-content: space-between;
`;

const TextContainer = styled(AlignItems)`
    flex-direction: column;
`;

const NoWrapText = styled(Text)`
    white-space: nowrap;
`;

const DarkListSubheader = styled(ListSubheader)`
    && {
        color: white;
        background-color: #575757;
        height: 37px;
        line-height: 37px;
    }
`;
export default CapabilityMenu;
