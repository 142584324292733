import * as echarts from 'echarts';
import { Series, Legend, Chart } from './types';
import { colorForSeries } from './data';

export const barGraph = (
    container: any,
    legendData: Legend[],
    barToLine: boolean,
    objectivesLabelsList: string[],
    seriesData: Series[],
    slider: boolean,
    showAnimation: boolean,
    xAxisData: string[],
    scroolValue?: number,
    colors?: string[],
    showCapabilityStatus?: boolean
) => {
    let option: object | null = null;
    let myChart: Chart;

    // eslint-disable-next-line prefer-const
    myChart = echarts.init(container);
    option = {
        graphic: showCapabilityStatus?
        [{
            type: 'text',
            left: '180',
            top: '100',
            zlevel: 7,
            style: {
                fill: '#000',
                text: 'DRAFT',
                font: ' 280px sans-serif',
                opacity: 0.2,
                position: 'front'
            }
        }] : null,
        tooltip: {
            trigger: 'item'
        },
        color:colors?colors:colorForSeries,
        animation: showAnimation,
        legend: {
            data: legendData,
            itemWidth: 30,
            top: '5%'
        },
        grid: {
            bottom: 200,
            left: '15%',
            right: '10%',
            top: 120
        },
        toolbox: {
            show: true,
            orient: 'horizontal',
            right: '0%',
            itemSize: 20,
            itemGap: 10,
            top: 'top',
            feature: {
                mark: { show: true },
                magicType: {
                    title: {bar: 'bar',line: 'line',},
                    show: barToLine,
                    type: ['bar','line']
                },
                restore: { title: 'Refresh', show: barToLine },
                saveAsImage: { title: 'as Image', show: barToLine }
            }
        },
        xAxis: [
            {
                name: 'Capabilities',
                type: 'category',
                axisTick: {
                    alignWithLabel: true,
                    length: 5
                },
                data: xAxisData,
                axisLabel: {
                    margin: 8,
                    rotate: 45,
                    fontSize: 11,
                    formatter: (xaxis: string) => {
                        return xaxis.slice(0, 25);
                    }
                }
            }
        ],
        yAxis: [
            {
                name: 'Score',
                interval: 0.5,
                type: 'value',
                min: 0,
                max: 5,
                axisLabel: {
                    formatter: (value: number) => {
                        let updatedObjectivesLabelsListArray = objectivesLabelsList;
                        if (objectivesLabelsList.length < 6) {
                            updatedObjectivesLabelsListArray = ['', ...objectivesLabelsList];
                        }
                        return Number.isInteger(value)
                            ? `${updatedObjectivesLabelsListArray[value]} ${value}`
                            : value;
                    }
                }
            }
        ],
        dataZoom: [
            {
                type: 'slider',
                start: 0,
                end: scroolValue,
                bottom: 40,
                show: slider
            }
        ],
        series: seriesData
    };
    myChart.setOption(option, { notMerge: true, silent: true });
    return myChart;
};
