import React, { useEffect, useState } from 'react';
import { AlignItems, Flex } from '../../../../components/base/Layout';
import {
    ImgContainer,
    LastUpdated,
    LinearProgress,
    NavLink
} from '../../../../components';
import iconTime from '../../../../assets/images/icon_time.svg';
import { countProgress, getLastUpdatedDateDifference } from '../../../../utils';
import { MaturityAssessmentsList, AssessmentsFilteredList } from '../types';
import { AssessmentTableType } from '../MaturityOverview/types';

export const useAssessmentsList = ({
    data,
    filterActiveStatus,
    filterProcessStatus
}: {
    data: MaturityAssessmentsList[];
    filterActiveStatus: string;
    filterProcessStatus: string;
}) => {
    const [assessmentsList, setAssessmentsList] = useState<
        AssessmentTableType[]
    >([]);
    useEffect(() => {
        if (data.length) {
            const filteredAssessmentsList = data
                .filter((assessment: MaturityAssessmentsList) => {
                    switch (filterProcessStatus) {
                        case 'ALL':
                            if (filterActiveStatus === 'ALL') {
                                return assessment;
                            }
                            if (filterActiveStatus === 'ARCHIVED') {
                                return assessment.campaignEntity.archivedDate;
                            }
                            if (filterActiveStatus === 'ACTIVE') {
                                return !assessment.campaignEntity.archivedDate;
                            }
                            return (
                                assessment.state.status === filterActiveStatus
                            );
                        case 'COMPLETED':
                            if (filterActiveStatus === 'ALL') {
                                return assessment.state.status === 'COMPLETED';
                            }
                            if (filterActiveStatus === 'ARCHIVED') {
                                return assessment.campaignEntity.archivedDate;
                            }
                            if (filterActiveStatus === 'ACTIVE') {
                                return !assessment.campaignEntity.archivedDate;
                            }
                            return (
                                assessment.state.status === filterActiveStatus
                            );
                        case 'NOT_STARTED':
                            if (filterActiveStatus === 'ALL') {
                                return (
                                    assessment.state.status === 'NOT_STARTED'
                                );
                            }
                            if (filterActiveStatus === 'ARCHIVED') {
                                return assessment.campaignEntity.archivedDate;
                            }
                            if (filterActiveStatus === 'ACTIVE') {
                                return !assessment.campaignEntity.archivedDate;
                            }
                            return (
                                assessment.state.status === filterActiveStatus
                            );
                        case 'IN_PROGRESS':
                            if (filterActiveStatus === 'ALL') {
                                return (
                                    assessment.state.status === 'IN_PROGRESS'
                                );
                            }
                            if (filterActiveStatus === 'ARCHIVED') {
                                return assessment.campaignEntity.archivedDate;
                            }
                            if (filterActiveStatus === 'ACTIVE') {
                                return !assessment.campaignEntity.archivedDate;
                            }
                            return (
                                assessment.state.status === filterActiveStatus
                            );
                        default:
                            return assessment;
                    }
                })
                .map((assessment: MaturityAssessmentsList) => {
                    return {
                        name: (
                            <NavLink
                                to={`/main/evaluate/maturity/${assessment.campaign.id}/assessments/${assessment.id}`}
                            >
                                {assessment.campaignEntity.name}
                            </NavLink>
                        ),
                        capCovered: `${Number(
                            assessment.state.capabilitiesScoredCnt
                        )}/${Number(assessment.state.capabilitiesCnt)}`,
                        capModerated: `${Number(
                            assessment.state.capabilitiesModeratedCnt
                        )}/${Number(assessment.state.capabilitiesCnt)}`,
                        lastUpdated: (
                            <Flex>
                                <AlignItems>
                                    <ImgContainer
                                        src={iconTime}
                                        alt="iconTime"
                                    />
                                    <LastUpdated
                                        // updatedBy="Kam Rai"
                                        updatedOn={getLastUpdatedDateDifference(
                                            {
                                                currentDate:
                                                    assessment.updatedAt
                                            }
                                        )}
                                    />
                                </AlignItems>
                            </Flex>
                        ),
                        progress: (
                            <LinearProgress
                                progress={countProgress({
                                    progressLength: assessment.state.capabilitiesCnt,
                                    value: assessment.state.capabilitiesModeratedCnt
                                })}
                            />
                        ),
                        id: assessment.id || ''
                    };
                });
            setAssessmentsList(filteredAssessmentsList);
        }
    }, [data, filterActiveStatus, filterProcessStatus]);
    return [assessmentsList];
};

export const useAssessmentsStatus = ({
    data,
    selectedRow
}: {
    data: MaturityAssessmentsList[];
    selectedRow: string[];
}) => {
    const [assessmentsListByStatus, setAssessmentsListByStatus] = useState<
        AssessmentsFilteredList[]
    >([]);
    useEffect(() => {
        if (data.length) {
            const filteredList = data
                .filter((item: MaturityAssessmentsList) =>
                    selectedRow.includes(item.id)
                )
                .map((item: MaturityAssessmentsList) => {
                    return {
                        id: item.id,
                        title: item.campaignEntity.name
                    };
                });
            setAssessmentsListByStatus(filteredList);
        }
    }, [data, selectedRow]);
    return [assessmentsListByStatus];
};
