import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    ImgContainer,
    Text,
    JustifyContentCenter
} from '../../../../../components';
import dropdownArrow from '../../../../../assets/images/icon-drop-down-dark.svg';
import {
    ClearAllButton,
    regionMap
} from '../../BenchmarkingHelpers';
import {
    ApplyAndClearWrapper,
    ApplyButton
} from '../../../../../components/base/Buttons';
import SearchInline from '../../../../../components/base/SearchInline';
import {
    Divider,
    List,
    ListItem,
    Checkbox,
    Popover,
    FormControlLabel,
    Button
} from '@mui/material';
import { RegionMenuProps, Region } from '../../types';

const PopoverRegionMenu: React.FC<RegionMenuProps> = ({
    data,
    isOpen,
    setOpen,
    placeholder,
    buttonDisabled = false,
    selected,
    setSelected
}) => {
    const [showAllInRegion, setRegion] = useState<string[]>([]);
    const [selectedWithoutApply, setSelectedWithoutApply] = useState<string[]>(
        []
    );
    const [viewSelected, setViewSelected] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const regionList = regionMap(
        data,
        searchValue,
        viewSelected,
        selectedWithoutApply
    );
    const searchCallback = (value: string) => {
        setSearchValue(value);
    };
    const anchorId = isOpen ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (selected) {
            setSelectedWithoutApply(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (!isOpen) {
            handleClose();
            setSelectedWithoutApply(selected);
        }
    }, [isOpen]);

    const selectedCount = (countries: string[]) => {
        const selectedValues = countries.filter(country =>
            selectedWithoutApply.includes(country)
        );
        if (selectedValues.length) {
            return selectedValues.length === countries.length
                ? 'selected'
                : `${selectedValues.length} selected`;
        }
        return undefined;
    };

    const checkAll = (e: React.ChangeEvent<unknown>) => {
        const { checked } = e.target as HTMLInputElement;
        if (checked && data) {
            setSelectedWithoutApply(data.map(acc => acc.country));
        } else {
            setSelectedWithoutApply([]);
        }
    };

    const checkRegion = (
        e: React.FormEvent<HTMLInputElement>,
        item: { region: string; countries: string[] }
    ) => {
        const currentRegion = regionList.find(
            region => region.region === item.region
        );
        const { checked } = e.target as HTMLInputElement;

        if (checked && data) {
            const newArray = currentRegion
                ? Array.from(
                      new Set([
                          ...currentRegion.countries,
                          ...selectedWithoutApply
                      ])
                  )
                : selectedWithoutApply;
            setSelectedWithoutApply(newArray);
        } else {
            const newData =
                currentRegion &&
                selectedWithoutApply.filter(
                    dataFilter =>
                        !currentRegion.countries.some(
                            country => country === dataFilter
                        )
                );
            newData
                ? setSelectedWithoutApply(newData)
                : setSelectedWithoutApply([]);
        }
    };

    const checkCountry = (
        e: React.FormEvent<HTMLInputElement>,
        country: string
    ) => {
        const { checked } = e.target as HTMLInputElement;

        if (checked && data) {
            setSelectedWithoutApply([...selectedWithoutApply, country]);
        } else {
            const newData = selectedWithoutApply.filter(
                selectedCountry => selectedCountry !== country
            );
            setSelectedWithoutApply(newData);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <MenuButtonContainer
                background={buttonDisabled ? 'none' : '#ececec'}
                disabled={buttonDisabled}
                aria-describedby={anchorId}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(e);
                    setOpen(true);
                }}
            >
                <TextContainer
                    color={placeholder.includes('Select') ? '#575757' : '#000'}
                >
                    {placeholder}
                </TextContainer>
                <ImgContainer src={dropdownArrow} alt="dropdown arrow" aria-hidden />
            </MenuButtonContainer>

            <BenchPopover
                id={anchorId}
                open={isOpen}
                keepMounted={isOpen}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                selecteddata={selectedWithoutApply.length}
            >
                <div style={{ padding: '16px' }}>
                    <SearchInline
                        placeholder="Search"
                        callback={(value: string) => searchCallback(value)}
                    />
                    {selectedWithoutApply.length ? (
                        <ViewButtonWrapper>
                            {viewSelected ? (
                                <ViewButton
                                    onClick={() => setViewSelected(false)}
                                >
                                    View all
                                </ViewButton>
                            ) : (
                                <ViewButton
                                    onClick={() => setViewSelected(true)}
                                >
                                    View selected
                                </ViewButton>
                            )}
                        </ViewButtonWrapper>
                    ) : (
                        <div />
                    )}

                    <Divider />
                    <CustomList>
                        <ListSection>
                            <FormControlUppercaseLabelWrapper
                                style={{width:'100%', margin:0}}
                                control={
                                    <Checkbox
                                        style={{paddingLeft: 0}}
                                        color="secondary"
                                        checked={
                                            data &&
                                            selectedWithoutApply.length ===
                                                data.length
                                        }
                                        onChange={(
                                            e: React.FormEvent<HTMLInputElement>
                                        ) => checkAll(e)}
                                        inputProps={{
                                            'aria-label': 'select all countries'
                                        }}
                                    />
                                }
                                label="Global"
                            />
                        </ListSection>
                        <Divider variant="middle" />
                        {regionList.map((item: Region, index: number) => (
                            <div key={index}>
                                <ListSection>
                                    <FormControlUppercaseLabelWrapper
                                        style={{width:'100%', margin:0}}
                                        control={
                                            <Checkbox
                                                style={{paddingLeft: 0}}
                                                color="secondary"
                                                checked={
                                                    (data &&
                                                        selectedWithoutApply.length ===
                                                            data.length) ||
                                                    item.countries.every(
                                                        country =>
                                                            selectedWithoutApply.includes(
                                                                country
                                                            )
                                                    )
                                                }
                                                onChange={(
                                                    e: React.FormEvent<
                                                        HTMLInputElement
                                                    >
                                                ) => checkRegion(e, item)}
                                                inputProps={{
                                                    'aria-label': 'select ' + item.region + ' region'
                                                }}
                                            />
                                        }
                                        label={item.region}
                                    />
                                    <SelectedCountText>
                                        {selectedCount(item.countries)}
                                    </SelectedCountText>
                                </ListSection>
                                {item.countries
                                    .slice(
                                        0,
                                        showAllInRegion.includes(item.region)
                                            ? item.countries.length
                                            : 4
                                    )
                                    .map((country: string, i: number) => (
                                        <CustomListItem
                                            key={i}
                                            style={{paddingRight:0}}>
                                            <FormControlCountryLabelWrapper
                                                style={{width:'100%', margin:0}}
                                                control={
                                                    <Checkbox
                                                        style={{paddingLeft: 0}}
                                                        color="secondary"
                                                        checked={
                                                            (data &&
                                                                selectedWithoutApply.length ===
                                                                    data.length) ||
                                                            selectedWithoutApply.includes(
                                                                country
                                                            )
                                                        }
                                                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                                            checkCountry(
                                                                e,
                                                                country
                                                            )
                                                        }
                                                        inputProps={{
                                                            'aria-label':
                                                                'select ' + country
                                                        }}
                                                    />
                                                }
                                                label={country}
                                            />
                                        </CustomListItem>
                                    ))}
                                {item.countries.length > 4 &&
                                !showAllInRegion.includes(item.region) ? (
                                    <div style={{ margin: '16px 0' }}>
                                        <ShowAllButton
                                            onClick={() =>
                                                setRegion([
                                                    ...showAllInRegion,
                                                    item.region
                                                ])
                                            }
                                        >
                                            Show all (
                                            {item.countries.length - 4})
                                        </ShowAllButton>
                                    </div>
                                ) : null}

                                <Divider variant="middle" />
                            </div>
                        ))}
                    </CustomList>
                    <Divider />
                    <ApplyAndClearWrapper>
                        <ClearAllButton
                            disabled={!selectedWithoutApply.length}
                            onClick={() => setSelected([])}
                        >
                            Clear
                        </ClearAllButton>
                        <ApplyButton
                            onClick={() => {
                                setSelected(selectedWithoutApply);
                                setRegion([]);
                                setSearchValue('');
                                setOpen(false);
                            }}
                        >
                            Apply
                        </ApplyButton>
                    </ApplyAndClearWrapper>
                </div>
            </BenchPopover>
        </>
    );
};

const CustomList = styled(List)(() => ({
    maxWidth: 300,
    minWidth: 150,
    margin: 0,
    padding: 16,
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 450,
    display: 'flex',
    flexDirection: 'column',
    focus: {
        outline: 0
    }
}));

const ListSection = styled(ListItem)({
    backgroundColor: 'inherit',
    padding: 0,
    margin: 0,
    height: '42px'
});

const CustomListItem = styled(ListItem)({
    backgroundColor: 'inherit',
    paddingLeft: '16px',
    height: '42px'
});

const BenchPopover = styled(Popover)<{ selecteddata?: number }>`
    .makeStyles-root-110 {
        max-height: ${props => (props.selecteddata !== 0 ? '422px' : '450px')};
    }
`;

const MenuButtonContainer = styled('button')<{ background?: string }>`
    display: flex;
    align-items: center;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        background: ${props => (props.background ? props.background : 'none')};
    }
`;
const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${props => (props.color ? props.color : '#575757')};
`;

const ViewButton = styled(Button)`
    margin: 2px !important;
    color: #346FA2 !important;
    font-size: 14px !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    padding: unset !important;
    min-width: unset !important;
`;

const ViewButtonWrapper = styled(JustifyContentCenter)`
    margin: 5px 0;
`;
const SelectedCountText = styled('span')`
    margin-left: auto;
    margin-right: 8px;
    color: #6E6E6E;
    font-size: 13px;
`;
const ShowAllButton = styled(Button)`
    font-size: 14px !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    color: #575757 !important;
`;

const FormControlCountryLabelWrapper = styled(FormControlLabel)`
    .MuiTypography-body1 {
        letter-spacing: 0.5px;
        font-size: 16px;
        color: #575757;
        @media (max-width: 1366px) {
            font-size: 16px;
        }
        @media (max-width: 450px) {
            font-size: 14px;
        }
    }
`;
const FormControlUppercaseLabelWrapper = styled(FormControlLabel)`
    .MuiTypography-body1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: normal;
        color: #2d2d2d;
    }
`;

export default PopoverRegionMenu;
