import React from 'react';
import { styled } from '@mui/material/styles';
import dropdownArrow from '../../../../assets/images/icon-drop-down-dark.svg';
import { Popover } from '@mui/material';
import { MainMenuProps } from '../types';
import { ImgContainer, Text } from '../../../../components';

const PopoverMenu: React.FC<MainMenuProps> = ({ placeholder, width }) => {
    return (
        <div>
            <MenuButtonContainer
                disabled
                onClick={(_e: React.MouseEvent<HTMLButtonElement>) => {}}
                width={width ? width : '250px'}
            >
                <TextContainer
                    color={placeholder.includes('Select') ? '#575757' : '#000'}
                >
                    {placeholder}
                </TextContainer>
                <ImgContainer src={dropdownArrow} alt="dropdown arrow" aria-hidden/>
            </MenuButtonContainer>

            <Popover
                open={false}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            />
        </div>
    );
};

const MenuButtonContainer = styled('button')<{ width?: string }>`
    display: flex;
    width: ${props => (props.width ? props.width : '250px')};
    align-items: center;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        background: 'none';
    }
`;

const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${props => (props.color ? props.color : '#575757')};
`;

export default PopoverMenu;
