import { styled } from '@mui/material/styles';
import {NavLink as NavRouterLink} from 'react-router-dom';

const NavLink = styled(NavRouterLink)`
    font-size: 15px;
    text-decoration: underline;
    display: flex;
    align-items: center;
    height: 100%;
    color: #346FA2;
    :visited {
        color: #5518AB;
    }
    :hover {
        cursor: pointer;
        color:  #346FA2;
    }
`;

export default NavLink;