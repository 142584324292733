import React from 'react';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { roundToTwoDecimailPlaces } from '../../../../../utils';
import { convertThreatResilienceScoreFromPerc } from '../../constants';

type TARTooltip = {
    title: string;
    threatResilienceScore?: number;
    pResilienceScore?: number;
    dResilienceScore?: number;
    cResilienceScore?: number;
};

const TARTooltip: React.FC<TARTooltip> = ({
    title,
    threatResilienceScore,
    pResilienceScore,
    dResilienceScore,
    cResilienceScore
}) => {
    return (
        <TooltipContainer>
            <div style={{ alignSelf: 'center' }}>{title}</div>
            <Divider />
            <div>
                {' '}
                {'Resilience score: '}
                {convertThreatResilienceScoreFromPerc(threatResilienceScore) + '/1000'
                   }
            </div>
            <ObjectiveType>
                <TypeStyle>P</TypeStyle>
                <TypeScore>
                    {' '}
                    {roundToTwoDecimailPlaces(pResilienceScore)}
                </TypeScore>
                <TypeStyle>D</TypeStyle>
                <TypeScore>
                    {roundToTwoDecimailPlaces(dResilienceScore)}
                </TypeScore>
                <TypeStyle>C</TypeStyle>
                <TypeScore>
                    {roundToTwoDecimailPlaces(cResilienceScore)}
                </TypeScore>
            </ObjectiveType>
        </TooltipContainer>
    );
};

const ObjectiveType = styled('div')`
    display: flex;
    flex-basis: 15%;
    justify-content: center;
`;
const TypeScore = styled('div')`
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
`;

const TooltipContainer = styled('div')`
    color: #000000;
    display: flex;
    flex-direction: column;
    margin: 8px;
`;

const TypeStyle = styled('div')`
    width: 25px;
    height: 25px;
    border-radius: 20px;
    background-color: #2d2d2d;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-left: 2px;
    padding-bottom: 2px;
    margin-top: 6px;
`;

export default TARTooltip;
