import { styled } from '@mui/material/styles';

const ScoreSquare = styled('div')<{
    background?: string;
    color?: string;
    border?: string;
}>`
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #94adc4;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${props => (props.border ? props.border : 'solid 0.5px #d7d7d7')};
    background: ${props => (props.background ? props.background : '#94adc4')};
    color: ${props => (props.color ? props.color : '#000000')};
`;

export default ScoreSquare;
