import React from 'react';

import { BoldText, Column, HorizontalLine } from '../../components';
import { styled } from '@mui/material/styles';
import TermsOfUseText from './TermsOfUseText';

const TermsOfUse = () => {
    const customStyles = {
        fontSize: '18px',
        lineHeight: '1.31',
        letterSpacing: 'normal'
    };
    return (
        <Wrapper>
            <HeadingText
                margin="32px auto 5px"
                color="#2d2d2d"
                fontSize="25px"
            >
                Connected Risk Engine Platform
            </HeadingText>
            <HeadingText
                margin="5px auto 24px"
                color="#2d2d2d"
                fontSize="30px"
            >
                Terms of Use
            </HeadingText>
            <HorizontalLine />
            <TermsOfUseText
                customStyles={customStyles}
            />
        </Wrapper>
    );
};

const HeadingText = styled(BoldText)`
    @media (max-width: 1366px) {
        font-size: 22px;
    }
    @media (max-width: 700px) {
        font-size: 18px;
    }
`;

const Wrapper = styled(Column)`
    max-width: 800px;
    margin: 0 auto 53px;
`;

export default TermsOfUse;
