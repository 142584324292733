import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
    BoldText,
    Breadcrumb,
    H1Text,
    // ImgContainer,
    LinearProgress,
    Text,
    AlignItems,
    JustifyContentCenter,
    Column,
    Flex,
    SpaceBetween,
    NotificationCloseCampaign
} from '../../../../components';
import iconAssessment from '../../../../assets/images/icon-assessment.svg';

import { CampaignProgresData } from '../types';
import { countProgress, isReadOnlyEnabled } from '../../../../utils';

import { UserStore } from '../../../../context/user-store';


type Props = {
    assessmentName: string;
    campaignProgresData: CampaignProgresData;
    campaignId: string;
    campaignTitle: string;
    campaignIsClosed: boolean;
    isReadOnly?: boolean;
    readOnlyAssessment?: boolean;
};

const AssessmentMaturityOverviewHeader: React.FC<Props> = ({
    assessmentName,
    campaignProgresData,
    campaignId,
    campaignTitle,
    campaignIsClosed,
    isReadOnly,
    readOnlyAssessment
}) => {
    const {
        state: { client = {}, user, globalSettings }
    } = useContext(UserStore);


    let campaign_closed_message = 'This campaign is closed - view only access.';

    /**
     * We switch the flag on based on if the user has the right role, if closed we keep that way... if on the other hand not, then we flag closed but with the
     * message switched.
     */

     const readOnlyEnabled = isReadOnlyEnabled(globalSettings);


    if (readOnlyEnabled && !campaignIsClosed && isReadOnly) {
        //BRUTE HACK TURN ON CAMPAIGN IS CLOSED MODE
        campaignIsClosed = true;
        campaign_closed_message = 'You have read-only access to this campaign.';
    }

    if (readOnlyEnabled && readOnlyAssessment) {
        campaignIsClosed = true;
        campaign_closed_message = 'You have read-only access to this assessment.';
    }


    const assessmentsMaturityCrumbs = [
        {
            name: 'Evaluate (Campaigns)',
            path: '/main/evaluate/campaigns'
        },
        {
            name: `${client ? client.name : ''} | ${campaignTitle}`,
            path: `/main/evaluate/maturity/${campaignId}`
        },
        {
            name: assessmentName
        }
    ];

    return (
        <HeaderWrapper>
            <HeaderContainer>
                <AlignItems>
                    <img
                        src={iconAssessment}
                        alt="icon"
                        style={{ marginRight: 8 }}
                    />
                    <H1Text style={{ fontSize: 18 }} color={'#4f4f4f'}>
                        Assessments
                    </H1Text>
                </AlignItems>
            </HeaderContainer>
            {campaignIsClosed && <NotificationCloseCampaign message={ campaign_closed_message } />}
            <SpaceBetween>
                <Breadcrumb crumbs={assessmentsMaturityCrumbs} />
                <SpaceBetween style={{ marginBottom: 20 }}>
                    <Column
                        style={{
                            paddingRight: 24,
                            borderRight: '1px solid #c5c5c5'
                        }}
                    >
                        <SpaceBetween style={{ marginBottom: 4 }}>
                            <BoldText style={{ fontSize: '14px' }}>Campaign</BoldText>
                            <Flex>
                                <BoldText style={{ marginRight: 5, fontSize: '14px' }}>
                                    {campaignProgresData ? campaignProgresData.progress : 0}
                                    %
                                </BoldText>
                                <Text>Progress</Text>
                            </Flex>
                        </SpaceBetween>
                        <LinearProgress
                            progress={ campaignProgresData ? campaignProgresData.progress : 0 }
                            containerWidth="200px"
                        />
                    </Column>
                    <Column
                        style={{paddingLeft: 24}}
                    >
                        <SpaceBetween style={{ marginBottom: 4 }}>
                            <BoldText style={{ fontSize: '14px' }}>Assessment</BoldText>
                            <Flex>
                                <BoldText style={{ marginRight: 5, fontSize: '14px' }}>
                                    {campaignProgresData ? campaignProgresData.assessmentsCompletedCnt : 0}
                                    /
                                    {campaignProgresData ? campaignProgresData.assessmentsCnt : 0}
                                </BoldText>
                                <Text>Completed</Text>
                            </Flex>
                        </SpaceBetween>
                        <LinearProgress
                            progress={campaignProgresData ? countProgress({
                                            progressLength: campaignProgresData && campaignProgresData.assessmentsCnt,
                                            value: campaignProgresData && campaignProgresData.assessmentsCompletedCnt
                                        })
                                        : 0
                                    }
                            containerWidth="200px"
                        />
                    </Column>
                </SpaceBetween>
            </SpaceBetween>
        </HeaderWrapper>
    );
};

const HeaderContainer = styled(JustifyContentCenter)`
    background-color: #fff;
    margin: 0 -5% 24px -8.8%;
    padding: 0 8.6%;
    min-height: 72px;
`;

const HeaderWrapper = styled(Column)`
    width: 100%;
`;



export default AssessmentMaturityOverviewHeader;
