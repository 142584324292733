export const detailsFilterOptions = [
    {
        id: 'ALL',
        label: 'All',
        rowCount: 0
    },
    {
        id: 'OUT_OF_SCOPE',
        label: 'Out of scope',
        rowCount: 0,
        linearProgress: 0
    },
    {
        id: 'NOT_SCORED',
        label: 'Not scored',
        rowCount: 0,
        linearProgress: 0
    },
    {
        id: 'NOT_SCORED_WITH_NOTES',
        label: 'Not scored with notes',
        rowCount: 0,
        linearProgress: 1
    },
    {
        id: 'SCORED',
        label: 'Scored',
        rowCount: 0,
        linearProgress: 2
    },
    {
        id: 'MODERATED',
        label: 'Moderated',
        rowCount: 0,
        linearProgress: 3
    },
    {
        id: 'REVIEW_REQUESTED',
        label: 'Review',
        rowCount: 0,
        linearProgress: 4
    },
    {
        id: 'AMENDS_REQUESTED',
        label: 'Amendments requested',
        rowCount: 0,
        linearProgress: 5
    },
    {
        id: 'COMPLETED',
        label: 'Completed',
        rowCount: 0,
        linearProgress: 5
    }
];
