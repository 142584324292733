
import { gql } from 'apollo-boost';

export const AVAILABLE_CAMPAIGNS = gql`
    query availableCampaignsByClient3($clientId: Int!) {
        availableCampaignsByClient(clientId: $clientId) {
            id
            name: title
            readOnly
            isCampaignEntityUser
            framework {
                id
                frameworkVersionId
                definition {
                    name
                }
            }
        }
    }
`;

export const COMPARE_CAMPAIGNS = gql`
    query compareCampaigns($campaignId1: ID!, $campaignId2: ID!) {
        item1: assessmentsByCampaignWithGroups(campaignId: $campaignId1) {
            groupName
            assessments {
                id
                campaign {
                    id
                    title
                    status
                }
                campaignEntity {
                    id
                    name
                    archivedDate
                }
                state {
                    status
                    inScope
                    averageScore
                    domains {
                        id
                        name
                        averageScore
                        moderatedAverageScore
                        capabilities {
                            status
                            id
                            name
                            averageScore
                            inScope
                            moderatedScore
                        }
                    }
                }
                permission
                framework {
                    id
                    version
                    definition {
                        name
                        shortName
                        scores
                        domains {
                            name
                            shortName
                            capabilities {
                                name
                                shortName
                            }
                        }
                    }
                }
            }
        }
        item2: assessmentsByCampaignWithGroups(campaignId: $campaignId2) {
            groupName
            assessments {
                id
                campaign {
                    id
                    title
                    status
                }
                campaignEntity {
                    id
                    name
                    archivedDate
                }
                permission
                state {
                    status
                    inScope
                    averageScore
                    domains {
                        id
                        name
                        averageScore
                        moderatedAverageScore
                        capabilities {
                            status
                            id
                            name
                            averageScore
                            inScope
                            moderatedScore
                        }
                    }
                }
                framework {
                    id
                    version
                    definition {
                        name
                        shortName
                        scores
                        domains {
                            name
                            shortName
                            capabilities {
                                name
                                shortName
                            }
                        }
                    }
                }
            }
        }
    }
`;
