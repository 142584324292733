

import { gql } from 'apollo-boost';

export const ASSESSMENT_BY_ID = gql`
    query assessment4($id: ID!) {
        assessment(id: $id) {
            permission
            state {
                domains {
                    id
                    name
                    capabilities {
                        id
                        name
                        status
                        feedback {
                            id
                            title
                            observations {
                                text
                            }
                            recommendations {
                                text
                            }
                        }
                    }
                    feedback {
                        id
                        title
                        observations {
                            text
                        }
                        recommendations {
                            text
                        }
                    }
                }
            }
            framework {
                id
                version
                definition {
                    name
                    shortName
                    scores
                    domains {
                        name
                        shortName
                        capabilities {
                            name
                            shortName
                        }
                    }
                }
            }
        }
    }
`;
