import React from 'react';
import Rating from './Rating';
type Props = {
    ratings?: any;
    numberOfColumns?: number;
};

type RatingsType = {
    background?: string;
    title?: string;
    numberOfColumns?: number;
    legendIcon?: string;
    width?: string;
};

const Ratings: React.FC<Props> = ({ratings, numberOfColumns}) => {
    return (
        <>
            {ratings.map(( rating: RatingsType, index: number ) => {
                return (
                    <div key={index} style={{ width: `calc(100% / ${numberOfColumns ? numberOfColumns : ratings.length})`, display: 'flex' }}>
                        <Rating  backgroundColor={rating.background} text={rating.title} legendIcon={rating.legendIcon} width="calc(100% - 1rem)" />
                    </div>
                );
            })}
        </>
    );
};


export default Ratings;

