import { IconButton } from '@mui/material';
import React from 'react';

import editIcon from '../../../assets/images/button_edit_red.svg';

type Props = {
    callback: any;
};

const EditButton: React.FC<Props> = ({ callback }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.stopPropagation();
                callback(e);
            }}
            aria-label={'edit button'}
    >
        <img src={editIcon} alt="edit icon" aria-hidden={true} />
    </IconButton>
    );
};

export default EditButton;
