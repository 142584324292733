import React, { Fragment, useEffect, useState } from 'react';
import Client from './Client';
import {  Client as ClientType,User } from '../../../../context/reducer';
import { Country } from '../../types';
import { dynamicSort } from '../../../../utils';
import { styled } from '@mui/material/styles';
import { gql } from 'apollo-boost';
import { CircularProgress } from '@mui/material';

type ClientListProps = {
    showLoading: boolean;
    showButton: boolean;
    clients: ClientType[];
    companiesAreOpen: boolean;
    setCompaniesAreOpen: CallableFunction;
    countries: Country[];
    clientsPageOffset: any;
    setClientsPagePageOffset: any;
};

const ClientList: React.FunctionComponent<ClientListProps> = ({
    showLoading,
    showButton,
    clients = [],
    companiesAreOpen,
    setCompaniesAreOpen,
    countries,
    clientsPageOffset,
    setClientsPagePageOffset

}) => {
    return (
        <Fragment>
            {clients &&
                clients.map((client: ClientType, index: number) => {
                    const country = countries.find(
                        (cn: Country) => cn.id === client.countryId
                    );
                    const isoCode = country && country.isoCode;
                    return (
                        <Client
                            key={index}
                            showLoading={showLoading}
                            clientData={client}
                            companiesAreOpen={companiesAreOpen}
                            setCompaniesAreOpen={setCompaniesAreOpen}
                            isoCode={isoCode || ''}
                        />
                    );
                })
            }
            {!showButton &&
                <LoadMoreBtn
                    onClick={() => {
                        setClientsPagePageOffset(clientsPageOffset + 1);
                    }}>
                    Load more
                </LoadMoreBtn>
            }
        </Fragment>
    );
};

const LoadMoreBtn = styled('button')`
    background-color: #ebebeb;
    width: 100%;
    border: none;
    justify-content: space-between;
    padding: 11px 11px 11px 27px;
`;
export default ClientList;
