import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form } from 'formik';
import { TextField, Select } from '@mui/material';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { Divider, LinearProgress, MenuItem } from '@mui/material';
import './editMeetingStyle.css';
import * as Yup from 'yup';

import {
    AlignItems,
    Button,
    Column,
    DateTimePickerField,
    Flex,
    PopUpHeader,
    RedButton,
    Text
} from '../../../../components';

import { styled } from '@mui/material/styles';

import checkedIcon from '../../../../assets/images/check.svg';

import {
    Capability,
    EditMeetingModalProps,
    MeetingCreateProps,
    MeetingTableValues,
    MeetingUpdateProps,
    Stakeholder,
    User,
    CapabilityDropdown
} from '../../MaturityScore/MeetingNotes/types';

import { INITIAL_VALUES } from '../../MaturityScore/MeetingNotes/MeetingNotesHelpers';

import {
    renderStakeholdersChips,
    renderCapabilityChips,
    renderUsersChips
} from '../../MaturityScore/MeetingNotes/components';

import {
    getLocalStorageItem,
    setLocalStorageItem
} from '../../../../utils/local-storage';
import { ContentBlock } from 'draft-js';
import { dynamicSort } from '../../../../utils/UtilsHelpers';
import { customModalStyles } from '../../../../components/PopUps/constants';

const editMeetingSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    capabilities: Yup.array()
        .min(1, 'select a capability')
        .required('Required')
});

const EditMeetingModal: React.FC<EditMeetingModalProps> = ({
    assessmentId,
    editMeetingModalIsOpen,
    setEditMeetingModalIsOpen,
    meetingData,
    stakeholders,
    domainsList,
    users,
    setChosenField,
    user,
    handleUpdateAssessmentMeeting,
    handleCreateAssessmentMeeting,
    doArchiveData,
    handleArchiveUnarchiveMeeting,
    currentCapabilityId
}) => {
    const [meetingsOtherAttendees, setMeetingsOtherAttendees] = useState<
        string[]
    >([]);
    const [isErrors, setIsErrors] = useState<boolean>(false);
    const [visibleTab, setVisibleTab] = useState<string>('info');
    const [initialValues, setInitialValues] = useState<MeetingTableValues>(
        INITIAL_VALUES
    );

    const [markdownValue, setMarkdownValue] = useState<string>('');

    useEffect(() => {
        const meetingsOtherAttendeesFromLocalStorage = getLocalStorageItem(
            `meetingsOtherAttendees-${assessmentId}`
        );
        const parsedMeetingsOtherAttendees = meetingsOtherAttendeesFromLocalStorage
            ? JSON.parse(meetingsOtherAttendeesFromLocalStorage)
            : [];
        setMeetingsOtherAttendees(parsedMeetingsOtherAttendees);
    }, [assessmentId, editMeetingModalIsOpen]);

    useEffect(() => {
        if (meetingData && meetingData.notes.length) {
            setMarkdownValue(meetingData.notes[0].text);
        }

        if (meetingData) {
            const values: MeetingTableValues = {
                archivedDate: meetingData.archivedDate || null,
                creator: meetingData.creator || user,
                capabilities:
                    (meetingData.capabilities &&
                        meetingData.capabilities.map(
                            (capability: Capability) => capability.id
                        )) ||
                    [],
                meetingDate: new Date(meetingData.meetingDate),
                stakeholders:
                    (meetingData.stakeholders &&
                        meetingData.stakeholders.map(
                            (stakeholder: Stakeholder) => stakeholder.id
                        )) ||
                    [],
                users: (meetingData.users &&
                        meetingData.users.map(
                            (tempUser: User) => tempUser.id
                        )) ||
                    [],
                notes:
                    (meetingData.notes &&
                        meetingData.notes.length &&
                        meetingData.notes[0].text) ||
                    '',
                title: meetingData.title || ''
            };
            setInitialValues(values);
        } else {
            setInitialValues({
                ...INITIAL_VALUES,
                creator: user,
                capabilities: currentCapabilityId ? [currentCapabilityId] : []
            });
        }
    }, [
        meetingData,
        meetingsOtherAttendees,
        currentCapabilityId,
        user,
        domainsList,
        assessmentId,
        setEditMeetingModalIsOpen
    ]);

    const handleSubmitMeetings = (values: MeetingTableValues) => {
        setLocalStorageItem(
            `meetingsOtherAttendees-${assessmentId}`
        );

        const defaultData: MeetingCreateProps = {
            assessmentId,
            title: values.title,
            creator: values.creator,
            meetingDate: values.meetingDate.toString(),
            capabilities: domainsList
                .filter((domain: CapabilityDropdown) => {
                    return values.capabilities.includes(domain.id);
                })
                .map((domain: CapabilityDropdown) => {
                    return {
                        id: domain.id,
                        name: domain.name
                    };
                }),
            notes: [
                {
                    text: markdownValue
                }
            ],
            stakeholderIds: values.stakeholders,
            userIds: values.users
        };

        if (meetingData) {
            const data: MeetingUpdateProps = {
                ...defaultData,
                id: meetingData.id
            };

            handleUpdateAssessmentMeeting(data);
        } else {
            handleCreateAssessmentMeeting(defaultData);
        }

        setMarkdownValue('');
    };
    
    const myBlockStyleFn = (contentBlock: ContentBlock) => {
        const type = contentBlock.getType();
        const depth = contentBlock.getDepth();
        if (type === 'ordered-list-item' && depth === 0) {
            return 'parentList';
        }
        if (type === 'ordered-list-item' && depth === 1) {
            return 'subList';
        }
    };

    const handleCloseModal = () => {
        setChosenField(null);
        setMeetingsOtherAttendees([]);
        setEditMeetingModalIsOpen(false);
    };
    return (
        <Modal
            isOpen={editMeetingModalIsOpen}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
            style={customModalStyles}
            onRequestClose={handleCloseModal}
        >
            <PopUpHeader handleClose={handleCloseModal} title={meetingData ? 'Edit meeting' : 'Add meeting'}/>
            <TabsWrap>
                <SubHeader>
                    <TabLink
                        className={`${
                            visibleTab === 'info' ? 'activeLink' : ''
                        } ${isErrors ? 'errorLink' : ''}`}
                        onClick={() => setVisibleTab('info')}
                    >
                        Info
                    </TabLink>

                    <TabLink
                        className={
                            visibleTab === 'notes' ? 'activeLink' : ''
                        }
                        onClick={() => setVisibleTab('notes')}
                    >
                        Notes
                    </TabLink>
                </SubHeader>
                <TabsErrorText>
                    {isErrors
                        ? 'Please complete Info tab before saving a note'
                        : ''}
                </TabsErrorText>
            </TabsWrap>

            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={editMeetingSchema}
                onSubmit={values => {
                    setIsErrors(false);
                    handleSubmitMeetings(values);
                }}>
                    {({
                    submitForm,
                    isSubmitting,
                    setFieldValue,
                    values,
                    errors
                }) => (
                    <MeetingForm>
                        <Tab
                            className={
                                visibleTab === 'info' ? 'activeTab' : ''
                            }
                        >
                            <Column>
                                <Flex>
                                    <ColumnWrapper
                                        style={{ paddingRight: 0 }}
                                    >
                                        <Label>Title</Label>
                                        <Field
                                            as={TextField}
                                            disabled={
                                                meetingData &&
                                                meetingData.archivedDate !==
                                                    null
                                            }
                                            margin="dense"
                                            name="title"
                                            style={{ marginBottom: 16 }}
                                            type="name"
                                            variant="outlined"
                                            errors={errors.title}
                                            inputProps={{
                                                'aria-label':'enter meeting title'
                                            }}
                                        />

                                        <Label>Creator</Label>
                                        <Field
                                            as={TextField}
                                            disabled
                                            margin="dense"
                                            name="creator"
                                            style={{ marginBottom: 16 }}
                                            type="name"
                                            variant="outlined"
                                            inputProps={{
                                                'aria-label':'enter creator name'
                                            }}
                                        />

                                        <Label style={{ marginBottom: 10 }}>
                                            Capability
                                        </Label>
                                        <Field
                                            as={Select}
                                            disabled={
                                                meetingData &&
                                                meetingData.archivedDate !==
                                                    null
                                            }
                                            margin="dense"
                                            name="capabilities"
                                            style={{ marginBottom: 16 }}
                                            type="name"
                                            variant="outlined"
                                            multiple
                                            renderValue={(
                                                selected: string[]
                                            ) =>
                                                renderCapabilityChips(
                                                    selected,
                                                    domainsList,
                                                    (newValues: number[]) =>
                                                        setFieldValue(
                                                            'capabilities',
                                                            newValues
                                                        ),
                                                    false
                                                )
                                            }
                                        >
                                            {domainsList &&
                                                domainsList.map(
                                                    (
                                                        option: CapabilityDropdown,
                                                        index: number
                                                    ) => {
                                                        return option.id ===
                                                            'title' ? (
                                                            <TitleMenuItem
                                                                disabled
                                                                key={`${option.id}-${index}`}
                                                                value={
                                                                    option.id
                                                                }
                                                            >
                                                                {
                                                                    option.name
                                                                }
                                                            </TitleMenuItem>
                                                        ) : (
                                                            <CustomMenuItem
                                                                key={`${option.id}-${index}`}
                                                                value={
                                                                    option.id
                                                                }
                                                            >
                                                                {
                                                                    option.name
                                                                }
                                                            </CustomMenuItem>
                                                        );
                                                    }
                                                )}
                                        </Field>
                                    </ColumnWrapper>

                                    <ColumnWrapper>
                                        <Label>Date</Label>
                                        <DateTimePickerField
                                            disabled={
                                                meetingData &&
                                                meetingData.archivedDate !==
                                                    null
                                            }
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            inputMargin="dense"
                                            inputName="meetingDate"
                                            onChange={(date: Date) =>
                                                setFieldValue(
                                                    'meetingDate',
                                                    date
                                                )
                                            }
                                            value={values.meetingDate}
                                        />
                                    </ColumnWrapper>
                                </Flex>

                                <ChipsWrap>
                                    {values.capabilities &&
                                        renderCapabilityChips(
                                            values.capabilities,
                                            domainsList,
                                            (newValues: Capability[]) =>
                                                setFieldValue(
                                                    'capabilities',
                                                    newValues
                                                ),
                                            true
                                        )}
                                </ChipsWrap>

                                <Divider style={{ margin: '0 30px' }} />

                                <Flex>
                                    <ColumnWrapper
                                        style={{
                                            width: 'calc(50% - 45px)',
                                            paddingBottom: 0
                                        }}
                                    >
                                        <Label style={{ marginBottom: 10 }}>
                                            Select team members
                                        </Label>
                                        <Field
                                            multiple
                                            as={Select}
                                            name="users"
                                            margin="dense"
                                            variant="outlined"
                                            renderValue={(
                                                selected: number[]
                                            ) =>
                                                renderUsersChips(
                                                    selected,
                                                    users,
                                                    (newValues: number[]) =>
                                                        setFieldValue(
                                                            'users',
                                                            newValues
                                                        ),
                                                    false
                                                )
                                            }
                                        >
                                            {users &&
                                                users.slice().sort(dynamicSort('firstName')).map(
                                                    (
                                                        option: User
                                                    ) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option.id
                                                                }
                                                                value={
                                                                    option.id
                                                                }
                                                            >
                                                                {`${option.firstName} ${option.lastName}`}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                        </Field>
                                    </ColumnWrapper>
                                </Flex>
                                <ChipsWrap>
                                        {values.users &&
                                            renderUsersChips(
                                                values.users,
                                                users,
                                                (newValues: number[]) =>
                                                    setFieldValue(
                                                        'users',
                                                        newValues
                                                    ),
                                                true
                                            )}
                                </ChipsWrap>



                                <Flex>
                                    <ColumnWrapper
                                        style={{
                                            width: 'calc(50% - 45px)',
                                            paddingBottom: 0
                                        }}
                                    >
                                            <Label
                                                style={{ marginBottom: 10 }}
                                            >
                                                Select stakeholders (if any)
                                            </Label>
                                        <Field
                                            multiple
                                            as={Select}
                                            name="stakeholders"
                                            margin="dense"
                                            variant="outlined"
                                            renderValue={(
                                                selected: number[]
                                            ) =>
                                                renderStakeholdersChips(
                                                    selected,
                                                    stakeholders,
                                                    (newValues: number[]) =>
                                                        setFieldValue(
                                                            'stakeholders',
                                                            newValues
                                                        ),
                                                    false
                                                )
                                            }
                                        >
                                            {stakeholders &&
                                                stakeholders.slice().sort(dynamicSort('firstName')).map(
                                                    (
                                                        option: Stakeholder
                                                    ) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    option.id
                                                                }
                                                                value={
                                                                    option.id
                                                                }
                                                            >
                                                                {`${option.firstName} ${option.lastName}`}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                        </Field>
                                    </ColumnWrapper>
                                </Flex>
                                <ChipsWrap>
                                    {values.stakeholders &&
                                        renderStakeholdersChips(
                                            values.stakeholders,
                                            stakeholders,
                                            (newValues: number[]) =>
                                                setFieldValue(
                                                    'stakeholders',
                                                    newValues
                                                ),
                                            true
                                        )}
                                </ChipsWrap>
                            </Column>
                        </Tab>

                        <Tab
                            className={
                                visibleTab === 'notes' ? 'activeTab' : ''
                            }
                        >
                            <NotesWrap>
                                <MarkDownContainer>
                                    <ReactQuillWrap
                                        value={markdownValue}
                                        onChange={(content: any, delta: any, source: any, editor: any) => {
                                            setMarkdownValue(content);
                                            setFieldValue(
                                                'notes',
                                                markdownValue
                                            );
                                        }}
                                    />
                                </MarkDownContainer>
                            </NotesWrap>
                        </Tab>

                        <SubmitWrapper>
                            {isSubmitting && <LinearProgress />}
                            <NotesArchiveButton
                                disabled={isSubmitting || !meetingData}
                                onClick={handleArchiveUnarchiveMeeting}>
                                    {doArchiveData ? 'Archive' : 'Unarchive'}
                            </NotesArchiveButton>

                            <NotesSaveButton
                                disabled={
                                    isSubmitting ||
                                    !values.title ||
                                    !(
                                        values.capabilities &&
                                        values.capabilities.length > 0
                                    )
                                }
                                onClick={() => {
                                    errors
                                        ? setIsErrors(true)
                                        : setIsErrors(false);
                                    submitForm();
                                }}
                            >
                                Save
                            </NotesSaveButton>
                        </SubmitWrapper>
                    </MeetingForm>
                )}
            </Formik>
        </Modal>
    );
};

const TabLink = styled('button')`
    font-size: 18px;
    font-weight: 500;
    color: #575757;
    width: 180px;
    cursor: pointer;
    text-align: center;
    padding: 16px 0;
    height: 100%;
    border: none;
    background none;

    &&.activeLink {
        display: block;
        color: #346FA2;
        box-shadow: 0 2px 0 0 #346FA2;
    }

    &&.errorLink {
        display: block;
        color: #de3328;
    }
`;

const TabsWrap = styled(Column)`
    width: 100%;
    align-items: center;
`;

const TabsErrorText = styled(Text)`
    color: #de3328;
    font-size: 0.8rem;
    margin: 0.5rem;
`;

const Tab = styled('div')`
    display: none;

    &&.activeTab {
        display: block;
    }
`;

const TitleMenuItem = styled(MenuItem)`
    && {
        background-color: #575757;
        color: #fff;
        && {
            opacity: 1;
        }
    }
`;

const CustomMenuItem = styled(MenuItem)`
    && {
        &&.MuiListItem-root.Mui-selected {
            background: url(${checkedIcon}) no-repeat no-repeat 90% 50%;
        }
    }
`;

const ReactQuillWrap = styled((props: ReactQuillProps) => 
    <ReactQuill 
        theme="snow"
        {...props}
    />
)`
    && {
        font-family: inherit;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        min-height: 240px;
    }
`;

const SubHeader = styled(AlignItems)`
    background-color: #f8f8f8;
    justify-content: center;
    width: 100%;
    padding: 2px 0;
`;

const NotesWrap = styled(Column)`
    padding: 15px 32px;
`;

const NotesSaveButton = styled(RedButton)`
    && {
        margin-left: 24px;
        padding: 12px 55px;
    }
`;

const NotesArchiveButton = styled(Button)`
    && {
        padding: 12px 55px;
    }
`;

const ChipsWrap = styled(Flex)`
    flex-wrap: wrap;
    padding: 18px 32px 0;
`;

const ColumnWrapper = styled(Column)`
    padding: 32px 16px 0 32px;
    width: 50%;
`;

const SubmitWrapper = styled(Flex)`
    padding: 30px;
    justify-content: flex-end;
`;

const Label = styled(Text)`
    color: #6E6E6E;
    font-size: 12px;
`;

const MarkDownContainer = styled('div')`
    padding-top: 20px;
    .public-DraftEditor-content {
        min-height: 164px;
    }
`;

const MeetingForm = styled(Form)`
    && {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export default EditMeetingModal;
