import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {Tooltip, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TablePagination, Theme} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Modal from 'react-modal';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { UserStore } from '../../../../../context/user-store';
import {
    AlignItems,
    Column,
    JustifyContentCenter,
    RedButton,
    ArrowTooltip,
    DescopePopup,
    Text,
    renderCapabilityInScopeBlock,
    renderCapabilityOutOfScope,
    renderTargetScoreCapabilityBlock,
    Flex,
    renderCompensatingControlBlock,
} from '../../../../../components';
import { customModalStyles } from '../../../../../components/PopUps/constants';
import MaturityOverviewTooltip from '../../MaturityOverviewTooltip/MaturityOverviewTooltip';
import Router from '../../../../../routes/router';
import {
    MaturityOverviewCapability,
    MaturityOverviewDomain,
    CampaignAssessment,
    MaturityOverviewFrameworkDomains,
    MaturityOverviewFrameworkCapabilities,
    CampaignGroupAssessment
} from '../../MaturityOverview/types';
import { textColor } from '../../../EvaluateHelpers';
import { htmlToText, stringTruncator } from '../../../../../utils/UtilsHelpers';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { isReadOnlyEnabled, PermissionTypeEnum } from '../../../../../utils';
import { useTheme } from '@mui/material/styles';
import mdi_lock from '../../../../../assets/images/mdi_lock.svg';

const DESCOPE_ASSESSMENT_CAPABILITY = gql`
    mutation updateAssessmentCapabilitiesScope1(
        $data: UpdateAssessmentCapabilitiesScopeInput!
    ) {
        updateAssessmentCapabilitiesScope(data: $data) {
            id
            state {
                domains {
                    id
                    capabilities {
                        id
                        inScope
                    }
                }
            }
        }
    }
`;

type Props = {
    selectedHandler: any;
    rows: CampaignGroupAssessment[];
    customHeadCells?: MaturityOverviewFrameworkDomains[];
    campaignId: string;
    maturityContainer: React.MutableRefObject<HTMLDivElement>;
    selectedRow: string[];
    showTargetScore: boolean;
    showCompensationControl: boolean;
    compensatingControlEnabled: boolean;
    targetScoreEnabled: boolean;
    pointOneDecimalEnable: boolean;
    isReadOnly?: boolean;
    readOnlyEnabled?: boolean;
    isObjectiveComponentExist: boolean;
    assessmentsByCampaignWithGroupPageOffset: number;
    assessmentsByCampaignWithGroupPageInfo: {
        count: number;
        skip: number;
        take: number;
        total: number;
    };
    setAssessmentsByCampaignWithGroupPageOffset: any;
};

export type ModalContent = {
    domainName: string;
    capabilityName: string;
    domainId: string | null;
    capabilityId: string | null;
    assessmentId: string;
    isReadOnly?: boolean;
    readOnlyEnabled?: boolean;
};

const MaturityTable: React.FC<Props> = ({
    rows = [],
    selectedHandler,
    customHeadCells,
    campaignId,
    maturityContainer,
    selectedRow,
    showTargetScore,
    showCompensationControl,
    compensatingControlEnabled,
    targetScoreEnabled,
    pointOneDecimalEnable,
    isReadOnly = false,
    isObjectiveComponentExist,
    assessmentsByCampaignWithGroupPageOffset,
    assessmentsByCampaignWithGroupPageInfo,
    setAssessmentsByCampaignWithGroupPageOffset
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [descopeAssessmentCapability] = useMutation(
        DESCOPE_ASSESSMENT_CAPABILITY
    );
    const [cellsCount, setCellsCount] = React.useState<
        MaturityOverviewDomain[]
    >([]);

    const {
        state: { currentTheme, globalSettings }
    } = useContext(UserStore);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const [modalContent, setModalContent] = useState<ModalContent>({
        domainName: '',
        capabilityName: '',
        domainId: null,
        capabilityId: null,
        assessmentId: ''
    });

    const [isReasonExist, setIsReasonExist] = useState<boolean>(false);

    useEffect(() => {
        rows.length &&
            rows[0].assessments.length &&
            rows[0].assessments[0] &&
            rows[0].assessments[0].state &&
            setCellsCount(rows[0].assessments[0].state.domains);
    }, [rows]);
    function handleClick(name: any) {
        const selectedIndex = selectedRow.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRow, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRow.slice(1));
        } else if (selectedIndex === selectedRow.length - 1) {
            newSelected = newSelected.concat(selectedRow.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRow.slice(0, selectedIndex),
                selectedRow.slice(selectedIndex + 1)
            );
        }

        selectedHandler(newSelected);
    }

    const handleCapabilityRescope = ({
        // tslint:disable-next-line: no-shadowed-variable
        setIsModalOpen,
        assessmentId,
        domainId,
        capabilityId,
        updateScopeReason
    }: {
        assessmentId: string;
        domainId: string;
        capabilityId: string;
        updateScopeReason: string;
        setIsModalOpen: any;
    }) => {
        setIsReasonExist(false);
        if (!updateScopeReason) {
            setIsReasonExist(true);
            return;
        }

        descopeAssessmentCapability({
            variables: {
                data: {
                    assessmentId,
                    domainId,
                    capabilityIds: [capabilityId],
                    inScope: true,
                    updateScopeReason
                }
            }
        })
            .then(() => {
                setIsModalOpen(false);
                Router.goToMaturityScore({
                    campaignId,
                    assessmentId,
                    domainId,
                    capabilityId
                });
            })
            .catch(err => {
                console.log('err', err);
            });
    };

    const isSelected = (name: any) => selectedRow.indexOf(name) !== -1;

    const [markdownValue, setMarkdownValue] = useState<string>('');

    const handleProcedureContentChange = (content: any, delta: any, source: any, editor: any) => {
        setMarkdownValue(content);
    };

    useEffect(() => {
        setMarkdownValue('');
    }, []);

    const checkBoxDisabled=isReadOnly&&readOnlyEnabled;

    return (
        <CustomMaturityTable id="MaturityCont" ref={maturityContainer}>
            { assessmentsByCampaignWithGroupPageInfo.count != 0  && <TablePagination
                component="div"
                count={assessmentsByCampaignWithGroupPageInfo.total}
                rowsPerPage={assessmentsByCampaignWithGroupPageInfo.take}
                page={assessmentsByCampaignWithGroupPageOffset}
                rowsPerPageOptions={[assessmentsByCampaignWithGroupPageInfo.take]}
                backIconButtonProps={{
                    'aria-label': 'previous page'
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page'
                }}
                onPageChange={(e: any, page: number) => {
                    setAssessmentsByCampaignWithGroupPageOffset(page);
                }}
            />}
            {assessmentsByCampaignWithGroupPageInfo.count != 0  && <CustomPaper>
                <TableWrapper>
                    <Table
                        style={{
                            minWidth: 750,
                            overflowX: 'auto',
                        }}>
                        <TableHead id="table">
                            <TableRow
                                style={{
                                    verticalAlign: 'bottom'
                                }}
                            >
                                <StickyTableCell
                                    aria-hidden={true}
                                />
                                {customHeadCells &&
                                    customHeadCells.map(
                                        (
                                            cell: MaturityOverviewFrameworkDomains,
                                            i: number
                                        ) => (
                                            <CustomTableCell key={i}>
                                                <HeadCell>
                                                    <DomainText>
                                                        {cell.name ? cell.name : 'No name provided'}
                                                    </DomainText>
                                                    <div
                                                        style={{
                                                            width: '90%',
                                                            borderRadius: 50,
                                                            border: '1px solid #e3e3e3'
                                                        }}
                                                    />
                                                    <AlignItems>
                                                        {cell.capabilities.map(
                                                            (
                                                                element: MaturityOverviewFrameworkCapabilities,
                                                                index: number
                                                            ) => (
                                                                <ArrowTooltip
                                                                    title={element.name}
                                                                    placement="bottom"
                                                                    key={index}
                                                                    minWidth={'0px'}
                                                                >
                                                                    <HeaderSubTitleWrapper
                                                                        style={{
                                                                            minWidth: element.shortName.length > 4 ? 50 : 40
                                                                        }}
                                                                    >
                                                                        <Text>
                                                                            {element.shortName}
                                                                        </Text>
                                                                    </HeaderSubTitleWrapper>
                                                                </ArrowTooltip>
                                                            )
                                                        )}
                                                    </AlignItems>
                                                </HeadCell>
                                            </CustomTableCell>
                                        )
                                    )}
                            </TableRow>
                        </TableHead>
                        <TableBody
                            style={{
                                border: '1px solid #d9d9d9',
                                borderTop: 'none',
                                boxSizing: 'border-box'
                            }}
                        >
                            {rows.map((row, index) => {
                                const labelId = `table-checkbox-${index}`;
                                return (
                                    <React.Fragment key={labelId}>
                                        <TableRow
                                            tabIndex={-1}
                                            style={{ height: 37 }}
                                        >
                                            <TableCell
                                                style={{
                                                    border: 'none',
                                                    position: 'absolute',
                                                    paddingTop: 8
                                                }}
                                            >
                                                <GroupName>
                                                    {row.groupName}
                                                </GroupName>
                                            </TableCell>
                                            <TableCell
                                                style={{ border: 'none' }}
                                            />
                                            {cellsCount.map((_, i: number) => {
                                                if (i < cellsCount.length - 1) {
                                                    return (
                                                        <TableCell
                                                            style={{
                                                                border: 'none'
                                                            }}
                                                            key={i}
                                                        />
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                        {row &&
                                            row.assessments &&
                                            row.assessments.map(
                                                (
                                                    assessment: CampaignAssessment,
                                                    key: number
                                                ) => {
                                                    const isItemSelected = isSelected(
                                                        assessment.id
                                                    );
                                                    let domainCompensatingControlCount = 0;
                                                    assessment.state.domains.forEach(d => {
                                                        let capabilityCount = 0;
                                                        d.capabilities.forEach(c => {
                                                            capabilityCount += c.objectives.filter((o: any) => o.compensatingControlEnable === true && o.inScope ).length;
                                                        });
                                                        domainCompensatingControlCount += capabilityCount;
                                                    });
                                                    const readOnlyAssessment = readOnlyEnabled && (assessment.permission && assessment.permission === PermissionTypeEnum.READ_ONLY_ACCESS);
                                                    return (
                                                        <CustomTableRow
                                                            key={key}
                                                            tabIndex={-1}
                                                        >
                                                            {/* <TableCell /> */}
                                                            <TableCell
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    zIndex: 2,
                                                                    padding: 16,
                                                                    border:
                                                                        'none',
                                                                    boxSizing:
                                                                        'border-box',
                                                                    height: (showCompensationControl && showTargetScore) ? 212 : (showCompensationControl && !showTargetScore )? 142: (!showCompensationControl && showTargetScore ) ? 142 : 72,
                                                                    backgroundColor: isItemSelected
                                                                        ? '#d9e5f0'
                                                                        : ''
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        !checkBoxDisabled && isItemSelected
                                                                    }
                                                                    disabled={checkBoxDisabled}
                                                                    inputProps={{
                                                                        'aria-label': labelId
                                                                    }}
                                                                    onClick={() =>
                                                                        !checkBoxDisabled && handleClick(
                                                                            assessment.id
                                                                        )
                                                                    }
                                                                />
                                                            </TableCell>

                                                            <>
                                                                <TableCell
                                                                    className="name"
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        paddingLeft: 80,
                                                                        backgroundColor: isItemSelected
                                                                            ? '#d9e5f0'
                                                                            : '',
                                                                        boxSizing:
                                                                            'border-box',
                                                                        width: (showTargetScore || showCompensationControl) ? 200 : 280,
                                                                        height: (showTargetScore || showCompensationControl) ? 142 : 72,
                                                                        border: 'none',
                                                                        zIndex: 1
                                                                    }}
                                                                >
                                                                    <AssessmentNameText
                                                                        to={`/main/evaluate/maturity/${campaignId}/assessments/${assessment.id}`}
                                                                    >
                                                                        <Tooltip
                                                                            title={`${assessment.campaignEntity.name} ${readOnlyAssessment ? '| ReadOnly' : ''}`}
                                                                            placement="top"
                                                                            key={assessment.id}
                                                                        >
                                                                            <Flex style={{
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {
                                                                                stringTruncator(assessment
                                                                                    .campaignEntity
                                                                                    .name, 22)
                                                                                }
                                                                                {readOnlyAssessment &&
                                                                                    <IconWrapper backgroundColor="#4588c3">
                                                                                        <img src={mdi_lock} alt="read only icon" />
                                                                                    </IconWrapper>
                                                                                }
                                                                            </Flex>
                                                                        </Tooltip>
                                                                    </AssessmentNameText>
                                                                </TableCell>
                                                                { (showCompensationControl || showTargetScore) &&
                                                                    <TableCell
                                                                        style={{
                                                                            position:'relative',
                                                                            paddingLeft: 215,
                                                                            backgroundColor: isItemSelected
                                                                                ? '#d9e5f0'
                                                                                : '',
                                                                            boxSizing:'border-box',
                                                                            height: (showCompensationControl && showTargetScore) ? 212 : (showCompensationControl || showTargetScore)? 142 : 72,
                                                                            border:'none',
                                                                            paddingRight: 7,
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            justifyContent: 'space-evenly',
                                                                            paddingTop:(showTargetScore && showCompensationControl )? 28 : 20,
                                                                            textAlign: 'right',
                                                                            whiteSpace: 'nowrap'
                                                                        }}
                                                                    >
                                                                        <p>Actual</p>

                                                                        {showTargetScore?  <p>Target</p>: null}
                                                                        { showCompensationControl?<p>
                                                                            Compensating Controls <br/> <span style={{backgroundColor: '#D3D3D3',padding: 3}}>Count: {domainCompensatingControlCount}</span>
                                                                          </p>: null}
                                                                    </TableCell>
                                                                }

                                                                {assessment.state.domains.map(
                                                                    (
                                                                        domain: MaturityOverviewDomain
                                                                    ) => {
                                                                        const frameworkDomain = assessment.framework.definition.domains.find((fd) => {
                                                                            return fd.name === domain.name;
                                                                        });
                                                                        return (
                                                                            <TableCell
                                                                                key={
                                                                                    domain.id
                                                                                }
                                                                                style={{
                                                                                    textAlign:
                                                                                        'center',
                                                                                    padding:
                                                                                        '16px 4px',
                                                                                    margin: 20,
                                                                                    height: (showCompensationControl && showTargetScore) ? 212 : (showCompensationControl && !showTargetScore )? 142: (!showCompensationControl && showTargetScore ) ? 142 : 72,
                                                                                    boxSizing:
                                                                                        'border-box',

                                                                                    border:
                                                                                        'none',
                                                                                    backgroundColor: isItemSelected
                                                                                        ? '#d9e5f0'
                                                                                        : ''
                                                                                }}
                                                                            >
                                                                                <AlignItems
                                                                                    style={{
                                                                                        justifyContent:
                                                                                            'center'
                                                                                    }}
                                                                                >
                                                                                    {domain.capabilities.map(
                                                                                        (
                                                                                            capability: MaturityOverviewCapability,
                                                                                            index: number
                                                                                        ) => {
                                                                                            const compensationControlCount = capability.objectives.filter((o: any) => capability.inScope && o.inScope && o.compensatingControlEnable === true ).length;
                                                                                            const frameworkCapability = frameworkDomain?.capabilities.find((fc) => {
                                                                                                return fc.name === capability.name;
                                                                                            });
                                                                                            const frameworkDomainAndCapabilityShortName = frameworkDomain?.shortName !== undefined && frameworkCapability?.shortName !== undefined ?
                                                                                                `${frameworkDomain?.shortName.toUpperCase()}${frameworkDomain?.shortName !== undefined && frameworkCapability?.shortName !== undefined ? '.' : ''}${frameworkCapability?.shortName.toUpperCase()}` : '';
                                                                                            return (
                                                                                                <div
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        flexDirection: 'column'
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        key={
                                                                                                            capability.id
                                                                                                        }
                                                                                                        style={{
                                                                                                            display:
                                                                                                                'inline-flex',
                                                                                                            justifyContent:
                                                                                                                'center',
                                                                                                            textAlign:
                                                                                                                'center',
                                                                                                            width: 40,
                                                                                                            height: 40,
                                                                                                            margin:
                                                                                                                '0 3px',
                                                                                                            color: textColor(
                                                                                                                currentTheme,
                                                                                                                capability.averageScore,
                                                                                                                capability.status
                                                                                                            ),
                                                                                                            paddingBottom: (showTargetScore || showCompensationControl) ? '10px' : 0
                                                                                                        }}
                                                                                                    >
                                                                                                        <ArrowTooltip
                                                                                                            top={
                                                                                                                -4
                                                                                                            }
                                                                                                            // interactive
                                                                                                            title={
                                                                                                                <MaturityOverviewTooltip
                                                                                                                    meetings={assessment.state.meetings}
                                                                                                                    capability={
                                                                                                                        capability
                                                                                                                    }
                                                                                                                    redirectData={{
                                                                                                                        campaignId,
                                                                                                                        assessmentId: assessment.id,
                                                                                                                        domainId: domain.id,
                                                                                                                        capabilityId: capability.id
                                                                                                                    }}
                                                                                                                    frameworkDomainAndCapabilityShortName={frameworkDomainAndCapabilityShortName}
                                                                                                                    targetScoreEnabled={targetScoreEnabled}
                                                                                                                    readOnlyEnabled={readOnlyEnabled}
                                                                                                                    isReadOnly={isReadOnly || readOnlyAssessment}
                                                                                                                    pointOneDecimalEnable={pointOneDecimalEnable}
                                                                                                                    isObjectiveComponentExist={isObjectiveComponentExist}
                                                                                                                    compensatingControlsCount={ !capability.inScope || (capability.objectives.length === capability.objectives.filter((o: any) => o.inScope === false).length) ? 'N/A' : compensationControlCount }
                                                                                                                    compensatingControlEnabled={compensatingControlEnabled}
                                                                                                                />
                                                                                                            }
                                                                                                            placement="bottom"
                                                                                                        >
                                                                                                            {capability.inScope
                                                                                                                ? renderCapabilityInScopeBlock(
                                                                                                                    {
                                                                                                                        averageScore:
                                                                                                                            capability.averageScore,
                                                                                                                        assessmentId:
                                                                                                                            assessment.id,
                                                                                                                        domainId:
                                                                                                                            domain.id,
                                                                                                                        capabilityId:
                                                                                                                            capability.id,
                                                                                                                        capabilityStatus:
                                                                                                                            capability.status,
                                                                                                                        moderatedScore:
                                                                                                                            capability.moderatedScore,
                                                                                                                        workingNotes:
                                                                                                                            capability.workingNotes,
                                                                                                                        isObjectiveNotes: !!capability.objectives.find(el => el.notes && el.notes.length),
                                                                                                                        currentTheme,
                                                                                                                        campaignId,
                                                                                                                        showTargetScore,
                                                                                                                        targetScore: capability.targetScore,
                                                                                                                        isReadOnly: (isReadOnly || readOnlyAssessment),
                                                                                                                        readOnlyEnabled,
                                                                                                                        pointOneDecimalEnable
                                                                                                                    }
                                                                                                                )
                                                                                                                : renderCapabilityOutOfScope(
                                                                                                                    () => {
                                                                                                                        if ((readOnlyEnabled && isReadOnly) || readOnlyAssessment) {
                                                                                                                            setIsModalOpen(
                                                                                                                                false
                                                                                                                            );
                                                                                                                        } else {
                                                                                                                            setModalContent(
                                                                                                                                {
                                                                                                                                    domainName:
                                                                                                                                        domain.name,
                                                                                                                                    capabilityName:
                                                                                                                                        capability.name,
                                                                                                                                    domainId:
                                                                                                                                        domain.id,
                                                                                                                                    capabilityId:
                                                                                                                                        capability.id,
                                                                                                                                    assessmentId:
                                                                                                                                        assessment.id
                                                                                                                                }
                                                                                                                            );
                                                                                                                            setIsModalOpen(
                                                                                                                                true
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }
                                                                                                                )}
                                                                                                        </ArrowTooltip>
                                                                                                    </div>
                                                                                                    {showTargetScore && (
                                                                                                        <div
                                                                                                            key={
                                                                                                                `${capability.id}-targetScore`
                                                                                                            }
                                                                                                            style={{
                                                                                                                display: 'inline-flex',
                                                                                                                justifyContent: 'center',
                                                                                                                textAlign: 'center',
                                                                                                                width: 40,
                                                                                                                height: 40,
                                                                                                                margin: '0 3px',
                                                                                                                paddingBottom: (showCompensationControl) ? '10px' : 0
                                                                                                            }}>
                                                                                                            {renderTargetScoreCapabilityBlock(
                                                                                                                campaignId,
                                                                                                                assessment.id,
                                                                                                                domain.id,
                                                                                                                capability.id,
                                                                                                                capability.status,
                                                                                                                isReadOnly || readOnlyAssessment,
                                                                                                                readOnlyEnabled,
                                                                                                                capability.targetScore,
                                                                                                                capability.inScope
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                     {showCompensationControl && (
                                                                                                        <div
                                                                                                            key={
                                                                                                                `${capability.id}-CompensationControl`
                                                                                                            }
                                                                                                            style={{
                                                                                                                display: 'inline-flex',
                                                                                                                justifyContent: 'center',
                                                                                                                textAlign: 'center',
                                                                                                                width: 40,
                                                                                                                height: 40,
                                                                                                                margin: '0 3px'
                                                                                                            }}
                                                                                                             >
                                                                                                                {renderCompensatingControlBlock(
                                                                                                                    campaignId,
                                                                                                                    assessment.id,
                                                                                                                    domain.id,
                                                                                                                    capability.id,
                                                                                                                    capability.status,
                                                                                                                    isReadOnly || readOnlyAssessment,
                                                                                                                    readOnlyEnabled,
                                                                                                                    !capability.inScope || (capability.objectives.length === capability.objectives.filter((o: any) => o.inScope === false).length) ? '-' : compensationControlCount,
                                                                                                                    capability.inScope
                                                                                                                )}

                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>

                                                                                            );}
                                                                                    )}
                                                                                </AlignItems>
                                                                            </TableCell>
                                                                        );
                                                                    }
                                                                )}
                                                            </>
                                                        </CustomTableRow>
                                                    );
                                                }
                                            )}
                                    </React.Fragment>
                                );
                            })}
                            <Modal
                                isOpen={isModalOpen}
                                shouldCloseOnOverlayClick
                                ariaHideApp={false}
                                style={customModalStyles}
                                onRequestClose={() => setIsModalOpen(false)}
                            >
                                <>
                                    <DescopePopup
                                        handleClose={() =>
                                            setIsModalOpen(false)
                                        }
                                        title="Rescope Capability?"
                                        mainText={`Are you sure you want to rescope the "${modalContent.domainName +
                                            ' - ' +
                                            modalContent.capabilityName}" capability?`}
                                        descriptionText={
                                            'Please provide a reason.'
                                        }
                                        initialValues={{
                                            reason: '',
                                            capabilityId:
                                                modalContent.capabilityId
                                        }}
                                        render={(formData: any) => {
                                            return (
                                                <>
                                                    <ReactQuillWrap
                                                        value={markdownValue}
                                                        onChange={handleProcedureContentChange}
                                                    />
                                                    <ButtonContainer>
                                                        {isReasonExist &&
                                                            <Text color="red">
                                                                Reason is required
                                                            </Text>
                                                        }
                                                        <SaveButton
                                                            key="SaveButton"
                                                            onClick={() => {
                                                                if (
                                                                    modalContent.domainId !==
                                                                        null &&
                                                                    modalContent.capabilityId !==
                                                                        null
                                                                ) {
                                                                    handleCapabilityRescope(
                                                                        {
                                                                            setIsModalOpen,
                                                                            assessmentId:
                                                                                modalContent.assessmentId,
                                                                            domainId:
                                                                                modalContent.domainId,
                                                                            capabilityId:
                                                                                modalContent.capabilityId,
                                                                            updateScopeReason:
                                                                                htmlToText(markdownValue)
                                                                        }
                                                                    );
                                                                }
                                                            }}>Rescope
                                                        </SaveButton>
                                                    </ButtonContainer>
                                                </>
                                            );
                                        }}
                                    />
                                </>
                            </Modal>
                        </TableBody>
                    </Table>
                </TableWrapper>
            </CustomPaper>
            }
        </CustomMaturityTable>
    );
};

const CustomMaturityTable = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(3),
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    boxShadow: 'none',
    marginBottom: useTheme().spacing(2)
}));

const TableWrapper = styled('div')`
    position: relative;
    border: 1px solid #d9d9d9;
    overflow-x: auto;
`;

const HeaderSubTitleWrapper = styled(JustifyContentCenter)`
    height: 40px;
    margin-right: 5px;
    margin: 0px 2px;
    &:hover {
        cursor: pointer;
        background-color: #d2d2d2;
    }
`;

const HeadCell = styled(Column)`
    background-color: #f2f2f2;
    align-items: center;
`;
const CustomTableCell = styled(TableCell)`
    && {
        padding: 0;
        min-height: 73px;
        border: 5px solid #fff;
        background-color: #f2f2f2;
    }
`;

const GroupName = styled(Text)`
    font-size: 15px;
    font-weight: bold;
    color: #5d5d5d;
    white-space: nowrap;
`;

const CustomTableRow = styled(TableRow)`
    &:nth-of-type(even) {
        td {
            background-color: #f8f8f8;
        }
    }
    &:nth-of-type(odd) {
        td {
            background-color: #ffffff;
        }
    }
    &:hover {
        td {
            cursor: pointer;
            background-color: #d9e5f0;
        }
    }
    > .name {
        background-color: #fff;
    }
`;

const AssessmentNameText = styled(NavLink)`
    font-size: 15px;
    text-decoration: underline;
    padding-top: 12px;
    display: flex;
    align-items: flex-start;
    height: 100%;
    color: #346FA2;
    :visited {
        color: #5518ab;
    }
    :hover {
        cursor: pointer;
        color: #346FA2;
    }
`;

const DomainText = styled(Text)`
    margin: 6px auto;
    max-width: 200px;
    overflow-wrap: anywhere;
    text-align: center;
    white-space: normal;
    padding-left: 10px;
    padding-right: 10px;
    color: #525252;
    font-weight: bold;
    font-size: 16px;
    padding: 8px;
`;

const StickyTableCell = styled(TableCell)`
    && {
        padding: 0 10px 0 84px;
        background-color: #f8f8f8;
        min-width: 184px;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        border-bottom-right-radius: 2px;
        border: 6px solid white;
        border-left: none;
    }
`;

const SaveButton = styled(RedButton)`
    && {
        padding: 0 56px;
    }
`;

const MarkDownContainer = styled('div')`
    padding-top: 20px;
    .public-DraftEditor-content {
        min-height: 164px;
    }
`;
const ReactQuillWrap = styled((props: ReactQuillProps) =>
    <ReactQuill
        theme="snow"
        {...props}
    />
)`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    && {
        font-family: inherit;
        padding-top: 0px;
        height:250px;
        margin-top:10px;
        margin-bottom:10px;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc;
        border-top: 1px solid #ccc !important;
    }

    .ql-editor {
        height: 100%;
        padding: 16px;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1em;
        color: #2d2d2d;
        overflow-y: hidden;
        min-height: 160px;
    }

    .ql-toolbar {
        flex-wrap: wrap;
        box-shadow: 0px -1px 9px 0px #cdcdcd;
        margin: 0;
        border: 0;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
    }
`;

const ButtonContainer = styled(Flex)`
    flex-direction: column;

    span {
        margin-bottom: 5px;
    }
`;

const IconWrapper = styled(JustifyContentCenter)<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    width: 15px;
    height: 15px;
    border-radius: 4px;
    margin-left: 12px;
    padding: 8px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export default MaturityTable;
