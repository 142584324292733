import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { CustomTable, SpinnerWithOverlay } from '../../components';
import StakeholderManagementHeader from './StakeholderManagementHeader';
import { UserStore } from '../../context/user-store';
import StakeholderModal from './StakeholderModal';
import { Stakeholder, StakeholderData, StakeholderRow } from './types';

import {
    headCells,
    useStakeholdersFiltered,
    useStakeholdersStatus
} from './StakeholderManagementHelpers';
import { STAKEHOLDERS } from './query';

const StakeholderManagement: React.FC = () => {
    const {
        state: { client, user }
    } = useContext(UserStore);
    const { loading, data = { stakeholdersByClient: [] } } = useQuery<
        StakeholderData
    >(STAKEHOLDERS, {
        variables: { clientId: (client && client.id) || 0 }
    });
    const [clientsModalIsOpen, setClientsModalIsOpen] = useState(false);
    const [chosenField, setchosenField] = useState<Stakeholder>();
    const [selectedRow, setSelectedRow] = useState<number[]>([]);
    const [filterStatus, setFilterStatus] = useState<string>('ALL');
    const [doArchive, setDoArchive] = useState<string>();
    const [clientsTableData, setClientsTableData] = useState<StakeholderRow[]>(
        []
    );
    const [notFilteredData, setNotFilteredData] = useState<StakeholderRow[]>(
        []
    );
    const [disableAllSelect, setDisableAllSelect] = useState<boolean>(true);

    const userMayEdit = useCallback(() => {
        const isManagers =
            user &&
            user.clients &&
            user.clients.some(item => item.id === client.id);
        return (
            (user && user.id) === (client && client.owner && client.owner.id) ||
            isManagers
        );
    }, [user, client]);

    const [isEditedData, setIsEditedData] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const stakeholdersList = useStakeholdersFiltered({
        data,
        setClientsModalIsOpen,
        setchosenField,
        filterStatus,
        userMayEdit,
        isEditedData,
        selectedRow
    });
    const stakeholdersCheckedList = useStakeholdersStatus(data, selectedRow);
    useEffect(() => {
        setClientsTableData(stakeholdersList);
        setNotFilteredData(stakeholdersList);
        setIsEditedData(false);
    }, [stakeholdersList]);

    const selectedHandler = (selected: number[]) => {
        setSelectedRow(selected);
    };
    useEffect(() => {
        if (filterStatus) {

            switch (filterStatus) {
                case 'ARCHIVED':
                    setDisableAllSelect(false);
                    break;
                case 'ACTIVE':
                    setDisableAllSelect(false);
                    break;
                default:
                    setDisableAllSelect(true);
                    break;
            }
        }
    }, [filterStatus]);

    return loading ? (<SpinnerWithOverlay />
    ) : (
        <div>
            <CustomTable
            search={search}
                selectedHandler={selectedHandler}
                header={
                    <StakeholderManagementHeader
                        search={search}
                        stakeholderdata={data}
                        stakeholdersCheckedList={stakeholdersCheckedList}
                        setClientsModalIsOpen={setClientsModalIsOpen}
                        setchosenField={setchosenField}
                        setClientsTableData={setClientsTableData}
                        notFilteredData={notFilteredData}
                        selectedRow={selectedRow}
                        filterStatus={filterStatus}
                        doArchive= {doArchive}
                        setdoArchive= {setDoArchive}
                        setFilterStatus={setFilterStatus}
                        setSelectedRow={setSelectedRow}
                        userMayEdit={userMayEdit}
                        setSearch={setSearch}
                    />
                }
                selectedRows={selectedRow}
                rows={clientsTableData || []}
                headCells={userMayEdit() ? headCells : headCells.slice(0, 5)}
                selectAllDisabled={disableAllSelect}
            />
            <StakeholderModal
                clientsModalIsOpen={clientsModalIsOpen}
                setClientsModalIsOpen={setClientsModalIsOpen}
                clientData={chosenField}
                clientId={client && client.id}
                setIsEditedData={setIsEditedData}
                stakeholderData={data.stakeholdersByClient}
            />
        </div>
    );
};

export default StakeholderManagement;
