import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Column, Text, Link, ArrowTooltip } from '../../../../../components';
import { AssessmentData, AssessmentDomain } from '../../types';
import { ObservationsAndRecommendations } from './ObservationsAndRecommendations';
import {
    CurrentIdsData,
    CampaignData,
    AssessmentDomain as CurrentDomain
} from '../../../MaturityScore/types';
import { initDomain, roundScore, isPointOneDecimalEnable, PermissionTypeEnum } from '../../../../../utils';
import { isReadOnlyEnabled } from '../../../../../utils';
import { UserStore } from '../../../../../context/user-store';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

type Props = {
    domain: AssessmentDomain;
    campaignId: string;
    assessmentId: string;
    domainIndex: number;
    domainList: AssessmentDomain[];
    assessmentData?: AssessmentData;
};

const CAMPAIGN = gql`
    query readOnlyCheck($id: ID!) {
        campaign(id: $id) {
            id
            readOnly
            pointOneDecimalEnable
        }
    }
`;

const AssessmentHeadCell: React.FC<Props> = ({
    domain,
    campaignId,
    assessmentId,
    domainIndex,
    domainList,
    assessmentData
}) => {
    const [isOpenObsAndRecModal, setIsOpenObsAndRecModal] = useState<boolean>(
        false
    );
    const [currentDomain, setCurrentDomain] = useState<CurrentDomain>(
        initDomain
    );
    const [currentDomainIndex, setCurrentDomainIndex] = useState<number>(0);
    const [currentIdsData, setCurrentIdsData] = useState<CurrentIdsData>({
        domainId: '',
        capabilityId: ''
    });
    const activateModal = () => {
        setIsOpenObsAndRecModal(true);
        setCurrentDomain(domainList[domainIndex]);
        setCurrentDomainIndex(domainIndex);
        setCurrentIdsData({
            domainId: domainList[domainIndex].id,
            capabilityId: domainList[domainIndex].capabilities[0].id
        });
    };

    const { data: campaignData } = useQuery<CampaignData>(CAMPAIGN, {
        variables: {
            id: campaignId
        }
    });

    // Check Read Only is enabled and if the user is a read only user.
    const {
        state: { globalSettings }
    } = useContext(UserStore);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    const isReadOnly = (readOnlyEnabled && campaignData && campaignData.campaign.readOnly || assessmentData?.assessment.permission == PermissionTypeEnum.READ_ONLY_ACCESS || false);
    const pointOneDecimalEnable = isPointOneDecimalEnable(globalSettings) && (campaignData && campaignData.campaign.pointOneDecimalEnable) || false;

    // Determine if this domain is completed.
    let approved = true;
    for (let i = 0; i < domainList[domainIndex].capabilities.length; i++) {
        if (domainList[domainIndex].capabilities[i].status !== 'COMPLETED') {
            approved = false;
        }
    }

    // Can this user view this domain?
    const canView = isReadOnly ? approved : true;

    // Create the tooltip.
    let tooltip;

    return (
        <AssessmentHeadCellWrapper>
                <Text color="#575757" weight="bold" size="16px">
                    {domain.name || 'No name provided'}
                </Text>
            <Text color="#525252" size="13px">
                Caps moderated:{' '}
                {domain && Number(domain.moderatedCapabilitiesCnt)}
            </Text>
            <Text color="#525252" size="13px">
                Mod average:{' '}
                {domain && Number(roundScore(domain.moderatedAverageScore, pointOneDecimalEnable))}
            </Text>
            <Text color="#525252" size="13px">
                Obj average:{' '}
                {domain &&
                    domain.averageScore &&
                    Number(roundScore(domain.averageScore, pointOneDecimalEnable))}
            </Text>
            {currentDomain && currentDomain.id && (
                <ObservationsAndRecommendations
                    isOpenObsAndRecModal={isOpenObsAndRecModal}
                    setIsOpenObsAndRecModal={setIsOpenObsAndRecModal}
                    currentIdsData={currentIdsData}
                    setCurrentIdsData={setCurrentIdsData}
                    campaignId={campaignId}
                    assessmentId={assessmentId}
                    currentDomain={domainList[currentDomainIndex]}
                    currentDomainIndex={currentDomainIndex}
                    setCurrentDomain={setCurrentDomain}
                    setCurrentDomainIndex={setCurrentDomainIndex}
                />
            )}
            {!canView
                ? (tooltip = (
                      <TooltipNoLink
                          style={{
                              background: '#2d2d2d',
                              marginTop: '10px',
                              borderRadius: '2px',
                          }}
                      >
                          <TooltipText>
                              Observations and Recommendations for this domain
                              are Pending
                          </TooltipText>
                      </TooltipNoLink>
                  ))
                : (tooltip = (
                      <TooltipLink
                          href="#"
                          style={{
                              background: '#2d2d2d',
                              marginTop: '10px',
                              borderRadius: '2px',
                          }}
                      >
                          <TooltipText onClick={() => activateModal()}>
                              View observations and recommendations
                          </TooltipText>
                      </TooltipLink>
                  ))}
        </AssessmentHeadCellWrapper>
    );
};

const AssessmentHeadCellWrapper = styled(Column)`
    flex: 0.15;
    min-height: 120px;
    padding-left: 20px;
    margin-bottom: 20px;
`;

const TooltipLink = styled(Link)`
    min-width: '120px'
    color: #ffffff;
    max-width: 160px;
    flex-wrap: wrap;
    :visited {
        color: #ffffff;
    }
    :hover {
        color: #ffffff;
    }
`;

const TooltipNoLink = styled('div')`
    color: #ffffff;
    max-width: 160px;
    flex-wrap: wrap;
    :visited {
        color: #ffffff;
    }
    :hover {
        color: #ffffff;
    }
    text-align:center;
`;

const TooltipText = styled(Text)`
    color: #ffffff;
    text-align: center;
`;

export default AssessmentHeadCell;
