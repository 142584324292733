import React, { useState, useEffect, useContext } from 'react';
import TAREmptyState from '../../TAREmptyState';
import {
    RiskDashboardComponentType,
    ratingsType,
    Risk,
    Threat as ThreatType,
    ThreatAssessment,
    ThreatAssessmentCapability,
    ThreatObjective,
    AllObjectivesToAverage,
} from '../../../types';
import { UserStore } from '../../../../../../context/user-store';
import { styled } from '@mui/material/styles';
import Flex from '../../../../../../components/base/Layout/Flex';
import ArrowTooltip from '../../../../../../components/Tooltips/ArrowTooltip/ArrowTooltip';
import JustifyContentCenter from '../../../../../../components/base/Layout/JustifyContentCenter';
import {
    squireColorResolver,
    textColorResolver,
    roundScore,
} from '../../../../../../utils';
import {
    Column,
    SpaceBetween,
    AlignItems,
} from '../../../../../../../src/components/base/Layout';
import iconStarBlack from '../../../../../../assets/images/icon-star-black.svg';
import verticalDots from '../../../../../../assets/images/icon-3-vertical-dots.svg';
import Text from '../../../../../../../src/components/base/Text';
import { riskRating, threatRating } from '../../../constants';
import { Rating } from '../../ratings';
import addIconGrey from '../../../../../../assets/images/icon-add-grey.svg';
import riskIcon from '../../../../../../assets/images/icon-threat-and-risk-background.svg';
import iconRiskActive from '../../../../../../assets/images/icon-tab-risk-active.svg';
import {
    Button,
    Divider,
    MenuItem,
    Tooltip,
    Typography,
    IconButton,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import TARTooltip from '../TARTooltip';
import { getResidualRiskRate } from '../TabsHelpers';
import CustomMenu from '../../../../../../components/Menu';
import info from '../../../../../../assets/images/toast_info_image.svg';

export const renderThreatNotification = (setActiveTab: any) => {
    return (
        <Flex style={{ marginBottom: '24px' }}>
            <IconWrapper>
                <img src={info} alt="info" />
            </IconWrapper>
            <Content>
                <Column>
                    <NotificationTitle>
                        Threat scenarios have not been added yet
                    </NotificationTitle>
                    <NotificationText>
                        Add threat scenarios to get a more complete risk
                        analysis.
                    </NotificationText>
                </Column>
                <NotificationButton
                    onClick={() => {
                        setActiveTab(1);
                    }}
                >
                    Go to threat scenarios
                </NotificationButton>
            </Content>
        </Flex>
    );
};

const RiskDashboardComponent: React.FC<RiskDashboardComponentType> = ({
    isAddButtonDisabled,
    setActiveTab,
    riskList,
    setAddTARModalOpen,
    isGuidance,
    isOnlyKeyVisible,
    setAddModal,
    setOpenRiskId,
    setOpenDeleteConfirmation,
    listOfAssessmentIds,
    pointOneDecimalEnable,
}) => {
    const [isExpand, setIsExpand] = useState<(boolean | undefined)[]>([]);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const isScored = (objScores: Array<number | null>): boolean => {
        return objScores.filter((score) => score !== null).length > 0;
    };

    useEffect(() => {
        setIsExpand(new Array(riskList && riskList.length).fill(false));
    }, [riskList]);

    const {
        state: { currentTheme },
    } = useContext(UserStore);

    const average = (array: number[]) =>
        array.reduce((a: number, b: number) => a + b) / array.length;

    const ObjectiveTemplateJsx = (
        objective: ThreatObjective,
        capabilityStatus: string,
        inScope: boolean
    ) => {
        return (
            <Objective>
                <AlignItems
                    style={{
                        marginBottom: '8px',
                        justifyContent: 'space-between',
                    }}
                >
                    <ObjectiveTitle>&bull; {objective.name}</ObjectiveTitle>
                    <Flex>
                        {objective.isKey && (
                            <ObjectiveKey>
                                <StarIcon src={iconStarBlack} />
                            </ObjectiveKey>
                        )}
                        <ObjectiveType>
                            {objective.type
                                ? objective.type[0].toUpperCase()
                                : ''}
                        </ObjectiveType>
                        <ObjectiveScore
                            background={
                                objective.inScope && inScope
                                    ? squireColorResolver(
                                          objective.score ? objective.score : 0,
                                          capabilityStatus,
                                          currentTheme
                                      )
                                    : 'repeating-linear-gradient(-45deg, transparent, transparent 8px, grey 11px, grey 7px)'
                            }
                            color={textColorResolver(
                                objective.score ? objective.score : 0,
                                capabilityStatus,
                                currentTheme
                            )}
                        >
                            {objective.score !== null &&
                                objective.inScope &&
                                inScope &&
                                roundScore(
                                    objective.score,
                                    pointOneDecimalEnable
                                )}
                        </ObjectiveScore>
                    </Flex>
                </AlignItems>
                <ObjectiveDescription>
                    {isGuidance && objective.description}
                </ObjectiveDescription>
            </Objective>
        );
    };

    const expandAll = () => {
        setIsExpand(
            isExpand.map((el) => {
                if (el === false) {
                    return true;
                }
                return el;
            })
        );
    };

    const collapseAll = () => {
        setIsExpand(
            isExpand.map((el) => {
                if (el === true) {
                    return false;
                }
                return el;
            })
        );
    };

    useEffect(() => {
        riskList &&
            riskList.forEach((risk: Risk, index: number) => {
                if (risk && risk.threats && risk.threats.length === 0) {
                    isExpand[index] = undefined;
                }
            });
    }, [isExpand]);

    const canExpand = () => {
        return (
            riskList &&
            riskList.some((risk: Risk) => {
                if (risk && risk.threats) {
                    return risk.threats.length > 0;
                }
            })
        );
    };

    return (
        <div>
            {riskList && riskList.length ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div>
                        {isExpand.some((el) => el === false) && canExpand() && (
                            <ExpandAll onClick={() => expandAll()}>
                                Expand all
                            </ExpandAll>
                        )}
                        {isExpand.every(
                            (el) => el === true || el === undefined
                        ) &&
                            canExpand() && (
                                <ExpandAll onClick={() => collapseAll()}>
                                    Collapse all
                                </ExpandAll>
                            )}
                    </div>
                    {!canExpand() && renderThreatNotification(setActiveTab)}
                    <div id="risk">
                        {riskList.map((risk: Risk, indexRisk: number) => {
                            const inherentRisk = riskRating.filter(
                                (riskMapItem: ratingsType) => {
                                    return (
                                        riskMapItem.title &&
                                        riskMapItem.title === risk.riskScore
                                    );
                                }
                            );

                            const residualRiskRate = getResidualRiskRate(risk);

                            const threats = risk.threats
                                ? [...(risk.threats as ThreatType[])]
                                : [];

                            return (
                                <div key={'RiskId' + risk.id}>
                                    <RiskWrapper
                                        isExpand={isExpand[indexRisk]}
                                        hasThreats={
                                            risk.threats && risk.threats.length
                                        }
                                    >
                                        <RiskExpandContainer>
                                            {!isAddButtonDisabled && (
                                                <CustomMenu
                                                    setMenuStatus={
                                                        setIsMenuOpen
                                                    }
                                                    isMenuOpen={isMenuOpen}
                                                    button={
                                                        <Tooltip
                                                            title={
                                                                <Typography>
                                                                    Risk Menu
                                                                </Typography>
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    verticalDots
                                                                }
                                                                alt="Risk Menu"
                                                                style={{
                                                                    padding:
                                                                        '3px',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    menuStyles={{
                                                        backgroundColor:
                                                            '#c4c4c4',
                                                        position: 'absolute',
                                                        top: '8px',
                                                        right: '52px',
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => {
                                                            setIsMenuOpen(
                                                                false
                                                            );
                                                            setAddModal(false);
                                                            setOpenRiskId(
                                                                indexRisk
                                                            );
                                                            setAddTARModalOpen &&
                                                                setAddTARModalOpen(
                                                                    true
                                                                );
                                                        }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setIsMenuOpen(
                                                                false
                                                            );
                                                            setOpenDeleteConfirmation(
                                                                {
                                                                    objectId:
                                                                        risk.id,
                                                                    objectName:
                                                                        risk.title,
                                                                    isOpen: true,
                                                                    modalType:
                                                                        'risk',
                                                                    canDelete:
                                                                        true,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </CustomMenu>
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    isExpand[indexRisk] =
                                                        !isExpand[indexRisk];
                                                    setIsExpand([...isExpand]);
                                                }}
                                                aria-label="expand or collapse button"
                                            >
                                                {isExpand[indexRisk] &&
                                                !!threats.length ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </IconButton>
                                        </RiskExpandContainer>
                                        <RiskContainer>
                                            <div>
                                                <Flex>
                                                    <img
                                                        src={iconRiskActive}
                                                        alt="+"
                                                        style={{
                                                            alignSelf: 'center',
                                                        }}
                                                    />
                                                    <RiskName>
                                                        {risk.title}
                                                    </RiskName>
                                                </Flex>
                                                {isGuidance && (
                                                    <RiskDescriptionWrapper>
                                                        <RiskDescription>
                                                            {risk.description}
                                                        </RiskDescription>
                                                    </RiskDescriptionWrapper>
                                                )}
                                            </div>

                                            {inherentRisk.length ? (
                                                <RiskRating>
                                                    <RiskText>
                                                        Inherent risk
                                                    </RiskText>
                                                    <Rating
                                                        backgroundColor={
                                                            inherentRisk[0]
                                                                .background
                                                        }
                                                        text={
                                                            inherentRisk[0]
                                                                .title
                                                        }
                                                    />
                                                </RiskRating>
                                            ) : (
                                                ''
                                            )}

                                            {residualRiskRate ? (
                                                <RiskRating
                                                    style={{
                                                        marginRight: '55px',
                                                    }}
                                                >
                                                    <RiskText>
                                                        Residual risk
                                                    </RiskText>
                                                    <Rating
                                                        backgroundColor={
                                                            residualRiskRate.background
                                                        }
                                                        text={
                                                            residualRiskRate.title
                                                        }
                                                    />
                                                </RiskRating>
                                            ) : null}

                                            {threats.length ? (
                                                <AssociatedThreatContainer>
                                                    <RiskText
                                                        style={{
                                                            marginLeft: '16px',
                                                        }}
                                                    >
                                                        Associated threat
                                                        scenarios
                                                    </RiskText>
                                                    <ThreatWrapper>
                                                        <ThreatContainer
                                                            oneThreat={
                                                                threats.length ===
                                                                1
                                                            }
                                                        >
                                                            {threats.map(
                                                                (
                                                                    threat: ThreatType,
                                                                    index: number
                                                                ) => {
                                                                    let ratingsTypeColor =
                                                                        threat.averageThreatResilienceRating
                                                                            ? threatRating.find(
                                                                                  (
                                                                                      ratingsTypeElement: ratingsType
                                                                                  ) =>
                                                                                      ratingsTypeElement.title ===
                                                                                      threat.averageThreatResilienceRating
                                                                              )
                                                                            : {};
                                                                    if (
                                                                        !ratingsTypeColor
                                                                    ) {
                                                                        ratingsTypeColor =
                                                                            threatRating[5];
                                                                    }

                                                                    return (
                                                                        index <
                                                                            threats.length /
                                                                                2 && (
                                                                            <div
                                                                                key={
                                                                                    'ThreatIdFirstColumn' +
                                                                                    threat.id +
                                                                                    risk.id
                                                                                }
                                                                            >
                                                                                <Threat
                                                                                    border={
                                                                                        index <
                                                                                        threats.length /
                                                                                            2 -
                                                                                            1
                                                                                    }
                                                                                >
                                                                                    <Flex>
                                                                                        <ArrowTooltip
                                                                                            title={
                                                                                                threat.title
                                                                                            }
                                                                                            placement="bottom"
                                                                                            key={
                                                                                                threat.id
                                                                                            }
                                                                                            minWidth={
                                                                                                '0px'
                                                                                            }
                                                                                        >
                                                                                            <ThreatTitle>
                                                                                                <Text
                                                                                                    color="#2d2d2d"
                                                                                                    size="16px"
                                                                                                    style={{
                                                                                                        width: '91%',
                                                                                                        marginBottom:
                                                                                                            '4px',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        threat.title
                                                                                                    }
                                                                                                </Text>
                                                                                            </ThreatTitle>
                                                                                        </ArrowTooltip>
                                                                                        <ArrowTooltip
                                                                                            title={
                                                                                                <TARTooltip
                                                                                                    title={
                                                                                                        threat.title
                                                                                                    }
                                                                                                    pResilienceScore={
                                                                                                        threat.pResilienceAverageScore
                                                                                                    }
                                                                                                    dResilienceScore={
                                                                                                        threat.dResilienceAverageScore
                                                                                                    }
                                                                                                    cResilienceScore={
                                                                                                        threat.cResilienceAverageScore
                                                                                                    }
                                                                                                    threatResilienceScore={
                                                                                                        threat.averageThreatResilienceScore
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                            placement="bottom"
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            minWidth={
                                                                                                '0px'
                                                                                            }
                                                                                            background={
                                                                                                'white'
                                                                                            }
                                                                                            popperColor={
                                                                                                'white'
                                                                                            }
                                                                                        >
                                                                                            <ThreatColor
                                                                                                aria-label="threat info tooltip"
                                                                                                data-testid="threatColor"
                                                                                                background={
                                                                                                    ratingsTypeColor
                                                                                                        ? ratingsTypeColor.background
                                                                                                        : 'none'
                                                                                                }
                                                                                            />
                                                                                        </ArrowTooltip>
                                                                                    </Flex>
                                                                                    <div>
                                                                                        {isGuidance && (
                                                                                            <ArrowTooltip
                                                                                                title={
                                                                                                    threat.description!
                                                                                                }
                                                                                                placement="bottom"
                                                                                                key={
                                                                                                    risk.id
                                                                                                }
                                                                                                minWidth={
                                                                                                    '0px'
                                                                                                }
                                                                                            >
                                                                                                <ThreatTitle>
                                                                                                    <Text
                                                                                                        color="#575757"
                                                                                                        size="15px"
                                                                                                    >
                                                                                                        {
                                                                                                            threat.description
                                                                                                        }
                                                                                                    </Text>
                                                                                                </ThreatTitle>
                                                                                            </ArrowTooltip>
                                                                                        )}
                                                                                    </div>
                                                                                </Threat>
                                                                                {index !==
                                                                                    0 &&
                                                                                    index !==
                                                                                        threats.length && (
                                                                                        <Divider />
                                                                                    )}
                                                                            </div>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                        </ThreatContainer>
                                                        <ThreatContainer
                                                            noBorder
                                                        >
                                                            {threats.map(
                                                                (
                                                                    threat: ThreatType,
                                                                    index: number
                                                                ) => {
                                                                    let ratingsTypeColor =
                                                                        threat.averageThreatResilienceRating
                                                                            ? threatRating.find(
                                                                                  (
                                                                                      ratingsTypeElement: ratingsType
                                                                                  ) =>
                                                                                      ratingsTypeElement.title ===
                                                                                      threat.averageThreatResilienceRating
                                                                              )
                                                                            : {};
                                                                    if (
                                                                        !ratingsTypeColor
                                                                    ) {
                                                                        ratingsTypeColor =
                                                                            threatRating[5];
                                                                    }

                                                                    return (
                                                                        index >=
                                                                            threats.length /
                                                                                2 && (
                                                                            <Threat
                                                                                key={
                                                                                    'ThreatIdSecondColumn' +
                                                                                    threat.id +
                                                                                    risk.id
                                                                                }
                                                                                border={
                                                                                    index >=
                                                                                        threats.length /
                                                                                            2 -
                                                                                            1 &&
                                                                                    index !==
                                                                                        threats.length -
                                                                                            1
                                                                                }
                                                                            >
                                                                                <Flex>
                                                                                    <ArrowTooltip
                                                                                        title={
                                                                                            threat.title
                                                                                        }
                                                                                        placement="bottom"
                                                                                        key={
                                                                                            threat.id
                                                                                        }
                                                                                        minWidth={
                                                                                            '0px'
                                                                                        }
                                                                                    >
                                                                                        <ThreatTitle>
                                                                                            <Text
                                                                                                color="#2d2d2d"
                                                                                                size="16px"
                                                                                                style={{
                                                                                                    width: '91%',
                                                                                                    marginBottom:
                                                                                                        '4px',
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    threat.title
                                                                                                }
                                                                                            </Text>
                                                                                        </ThreatTitle>
                                                                                    </ArrowTooltip>
                                                                                    <ArrowTooltip
                                                                                        title={
                                                                                            <TARTooltip
                                                                                                title={
                                                                                                    threat.title
                                                                                                }
                                                                                                pResilienceScore={
                                                                                                    threat.pResilienceAverageScore
                                                                                                }
                                                                                                dResilienceScore={
                                                                                                    threat.dResilienceAverageScore
                                                                                                }
                                                                                                cResilienceScore={
                                                                                                    threat.cResilienceAverageScore
                                                                                                }
                                                                                                threatResilienceScore={
                                                                                                    threat.averageThreatResilienceScore
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                        placement="bottom"
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        minWidth={
                                                                                            '0px'
                                                                                        }
                                                                                        background={
                                                                                            'white'
                                                                                        }
                                                                                        popperColor={
                                                                                            'white'
                                                                                        }
                                                                                    >
                                                                                        <ThreatColor
                                                                                            aria-label="threat info tooltip"
                                                                                            background={
                                                                                                ratingsTypeColor
                                                                                                    ? ratingsTypeColor.background
                                                                                                    : 'none'
                                                                                            }
                                                                                        />
                                                                                    </ArrowTooltip>
                                                                                </Flex>
                                                                                <div>
                                                                                    {isGuidance && (
                                                                                        <ArrowTooltip
                                                                                            title={
                                                                                                threat.description!
                                                                                            }
                                                                                            placement="bottom"
                                                                                            key={
                                                                                                risk.id
                                                                                            }
                                                                                            minWidth={
                                                                                                '0px'
                                                                                            }
                                                                                        >
                                                                                            <ThreatTitle>
                                                                                                <Text
                                                                                                    color="#575757"
                                                                                                    size="15px"
                                                                                                >
                                                                                                    {
                                                                                                        threat.description
                                                                                                    }
                                                                                                </Text>
                                                                                            </ThreatTitle>
                                                                                        </ArrowTooltip>
                                                                                    )}
                                                                                </div>
                                                                            </Threat>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                        </ThreatContainer>
                                                    </ThreatWrapper>
                                                </AssociatedThreatContainer>
                                            ) : canExpand() ? (
                                                <Flex
                                                    onClick={() => {
                                                        setAddModal(false);
                                                        setOpenRiskId(
                                                            indexRisk
                                                        );
                                                        setAddTARModalOpen &&
                                                            setAddTARModalOpen(
                                                                true
                                                            );
                                                    }}
                                                >
                                                    <img
                                                        src={addIconGrey}
                                                        alt="+"
                                                    />
                                                    <AddThreat>
                                                        Add threat scenarios
                                                    </AddThreat>
                                                </Flex>
                                            ) : (
                                                ''
                                            )}
                                        </RiskContainer>
                                    </RiskWrapper>
                                    {isExpand[indexRisk] &&
                                        threats.map(
                                            (
                                                threat: ThreatType,
                                                indexThreat: number
                                            ) => {
                                                const threatCopy: ThreatType =
                                                    JSON.parse(
                                                        JSON.stringify(threat)
                                                    );
                                                let capabilities: ThreatAssessmentCapability[] =
                                                    [];

                                                if (
                                                    threatCopy.assessments &&
                                                    threatCopy.assessments
                                                        .length > 0
                                                ) {
                                                    const allObjectivesToAverage: AllObjectivesToAverage[] =
                                                        [];
                                                    threatCopy.assessments.forEach(
                                                        (
                                                            assessment: ThreatAssessment
                                                        ) => {
                                                            if (
                                                                listOfAssessmentIds.includes(
                                                                    assessment.id
                                                                )
                                                            ) {
                                                                assessment.capabilities.forEach(
                                                                    (
                                                                        cap: ThreatAssessmentCapability,
                                                                        capIndex: number
                                                                    ) => {
                                                                        cap.objectives.forEach(
                                                                            (
                                                                                obj: ThreatObjective,
                                                                                objIndex: number
                                                                            ) => {
                                                                                if (
                                                                                    allObjectivesToAverage.length >
                                                                                    0
                                                                                ) {
                                                                                    const averagedObj =
                                                                                        allObjectivesToAverage.filter(
                                                                                            (
                                                                                                objToAvg: AllObjectivesToAverage,

                                                                                                index: number
                                                                                            ) => {
                                                                                                if (
                                                                                                    objToAvg.capIndex ===
                                                                                                        capIndex &&
                                                                                                    objToAvg.objIndex ===
                                                                                                        objIndex
                                                                                                ) {
                                                                                                    allObjectivesToAverage[
                                                                                                        index
                                                                                                    ].objScore.push(
                                                                                                        obj.score
                                                                                                            ? obj.score
                                                                                                            : 0
                                                                                                    );
                                                                                                }
                                                                                                return (
                                                                                                    objToAvg.capIndex ===
                                                                                                        capIndex &&
                                                                                                    objToAvg.objIndex ===
                                                                                                        objIndex
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                    if (
                                                                                        averagedObj.length ===
                                                                                        0
                                                                                    ) {
                                                                                        allObjectivesToAverage.push(
                                                                                            {
                                                                                                capIndex,
                                                                                                objIndex,
                                                                                                objScore:
                                                                                                    [
                                                                                                        obj.score
                                                                                                            ? obj.score
                                                                                                            : 0,
                                                                                                    ],
                                                                                            }
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    // Objective is not scored, so it should not be added to the array. Fix for bug 374
                                                                                    /*
                                                                                    allObjectivesToAverage.push(
                                                                                        {
                                                                                            capIndex,
                                                                                            objIndex,
                                                                                            objScore: [
                                                                                                (obj.score !== null)
                                                                                                    ? obj.score
                                                                                                    : 0
                                                                                            ]
                                                                                        }
                                                                                    );*/
                                                                                }
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    );

                                                    allObjectivesToAverage.forEach(
                                                        (
                                                            objectivetoAverage: AllObjectivesToAverage
                                                        ) => {
                                                            if (
                                                                threatCopy.assessments
                                                            ) {
                                                                const averagedScore =
                                                                    isScored(
                                                                        objectivetoAverage.objScore
                                                                    )
                                                                        ? average(
                                                                              objectivetoAverage.objScore
                                                                          )
                                                                        : null;

                                                                threatCopy.assessments[0].capabilities[
                                                                    objectivetoAverage.capIndex
                                                                ].objectives[
                                                                    objectivetoAverage.objIndex
                                                                ].score =
                                                                    averagedScore;

                                                                if (
                                                                    averagedScore !==
                                                                    0
                                                                ) {
                                                                    threatCopy.assessments[0].capabilities[
                                                                        objectivetoAverage.capIndex
                                                                    ].status =
                                                                        'SCORED';
                                                                }
                                                            }
                                                        }
                                                    );

                                                    capabilities =
                                                        threatCopy
                                                            .assessments[0]
                                                            .capabilities;
                                                }

                                                if (isOnlyKeyVisible) {
                                                    capabilities.forEach(
                                                        (capability) => {
                                                            const filteredObjectives =
                                                                capability.objectives.filter(
                                                                    (
                                                                        objective
                                                                    ) => {
                                                                        return (
                                                                            objective.isKey ===
                                                                            true
                                                                        );
                                                                    }
                                                                );

                                                            capability.objectives =
                                                                [
                                                                    ...filteredObjectives,
                                                                ];
                                                        }
                                                    );
                                                }

                                                let ratingsTypeColor =
                                                    threat.averageThreatResilienceRating
                                                        ? threatRating.find(
                                                              (
                                                                  ratingsTypeElement: ratingsType
                                                              ) =>
                                                                  ratingsTypeElement.title ===
                                                                  threat.averageThreatResilienceRating
                                                          )
                                                        : {};
                                                if (!ratingsTypeColor) {
                                                    ratingsTypeColor =
                                                        threatRating[5];
                                                }

                                                return (
                                                    <ThreatWrapperExpand
                                                        isLatestThreatExpanded={
                                                            indexThreat ===
                                                            threats.length - 1
                                                        }
                                                        isFirstThreatExpanded={
                                                            !!indexThreat
                                                        }
                                                        key={
                                                            'ThreatIdExpand' +
                                                            threat.id +
                                                            risk.id
                                                        }
                                                    >
                                                        <SpaceBetween
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <ThreatExpandTitleWrapper>
                                                                <ThreatExpandTitleText>
                                                                    Threat
                                                                    scenario
                                                                </ThreatExpandTitleText>
                                                                {threat.title}
                                                            </ThreatExpandTitleWrapper>
                                                            {threat.description &&
                                                                isGuidance && (
                                                                    <div
                                                                        style={{
                                                                            width: '27vw',
                                                                        }}
                                                                    >
                                                                        <ThreatExpandTitleText>
                                                                            Threat
                                                                            description
                                                                        </ThreatExpandTitleText>
                                                                        <ThreatExpandTitleText
                                                                            style={{
                                                                                fontSize:
                                                                                    '15px',
                                                                                marginTop:
                                                                                    '1px',
                                                                                letterSpacing: 1,
                                                                            }}
                                                                        >
                                                                            {
                                                                                threat.description
                                                                            }
                                                                        </ThreatExpandTitleText>
                                                                    </div>
                                                                )}
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                <ThreatExpandTitleText>
                                                                    Threat
                                                                    resilience
                                                                </ThreatExpandTitleText>
                                                                <ArrowTooltip
                                                                    title={
                                                                        <TARTooltip
                                                                            title={
                                                                                threat.title
                                                                            }
                                                                            pResilienceScore={
                                                                                threat.pResilienceAverageScore
                                                                            }
                                                                            dResilienceScore={
                                                                                threat.dResilienceAverageScore
                                                                            }
                                                                            cResilienceScore={
                                                                                threat.cResilienceAverageScore
                                                                            }
                                                                            threatResilienceScore={
                                                                                threat.averageThreatResilienceScore
                                                                            }
                                                                        />
                                                                    }
                                                                    placement="bottom"
                                                                    key={
                                                                        'ThreatIdExpand' +
                                                                        threat.id +
                                                                        risk.id
                                                                    }
                                                                    minWidth={
                                                                        '0px'
                                                                    }
                                                                    background={
                                                                        'white'
                                                                    }
                                                                    popperColor={
                                                                        'white'
                                                                    }
                                                                >
                                                                    <ThreatExpandResilience
                                                                        background={
                                                                            ratingsTypeColor
                                                                                ? ratingsTypeColor.background
                                                                                : 'none'
                                                                        }
                                                                    >
                                                                        {ratingsTypeColor
                                                                            ? ratingsTypeColor.title
                                                                            : 'None'}
                                                                    </ThreatExpandResilience>
                                                                </ArrowTooltip>
                                                            </div>
                                                        </SpaceBetween>
                                                        {capabilities.map(
                                                            (
                                                                capability: ThreatAssessmentCapability,
                                                                indexCapability: number
                                                            ) => {
                                                                let divider = 0;

                                                                return (
                                                                    <CapabilityWrapper
                                                                        key={
                                                                            'CapabilityId' +
                                                                            capability.id +
                                                                            threat.id +
                                                                            risk.id
                                                                        }
                                                                        isPadding={
                                                                            !isGuidance &&
                                                                            !capability
                                                                                .objectives
                                                                                .length
                                                                        }
                                                                        isFirstCapabilityExpanded={
                                                                            !indexCapability ||
                                                                            (!!indexCapability &&
                                                                                !capability
                                                                                    .objectives
                                                                                    .length &&
                                                                                !isGuidance)
                                                                        }
                                                                    >
                                                                        {isGuidance && (
                                                                            <CapabilityName>
                                                                                {
                                                                                    capability.name
                                                                                }
                                                                            </CapabilityName>
                                                                        )}
                                                                        {capability
                                                                            .objectives
                                                                            .length ? (
                                                                            <ObjectiveWrapper>
                                                                                <ObjectiveContainer>
                                                                                    {capability.objectives.map(
                                                                                        (
                                                                                            objective: ThreatObjective,
                                                                                            indexObjective: number
                                                                                        ) => {
                                                                                            indexObjective <
                                                                                            capability
                                                                                                .objectives
                                                                                                .length /
                                                                                                3
                                                                                                ? divider++
                                                                                                : (divider = 0);

                                                                                            return (
                                                                                                divider >
                                                                                                    0 &&
                                                                                                objective.name && (
                                                                                                    <div
                                                                                                        key={
                                                                                                            'ObjectiveIdFirstColumn' +
                                                                                                            indexObjective +
                                                                                                            capability.id +
                                                                                                            threat.id +
                                                                                                            risk.id
                                                                                                        }
                                                                                                    >
                                                                                                        {divider >
                                                                                                            1 && (
                                                                                                            <Divider />
                                                                                                        )}
                                                                                                        {ObjectiveTemplateJsx(
                                                                                                            objective,
                                                                                                            capability.status,
                                                                                                            capability.inScope
                                                                                                        )}
                                                                                                    </div>
                                                                                                )
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </ObjectiveContainer>
                                                                                <ObjectiveContainer>
                                                                                    {capability.objectives.map(
                                                                                        (
                                                                                            objective: ThreatObjective,
                                                                                            indexObjective: number
                                                                                        ) => {
                                                                                            indexObjective +
                                                                                                1 <=
                                                                                                Math.ceil(
                                                                                                    capability
                                                                                                        .objectives
                                                                                                        .length -
                                                                                                        capability
                                                                                                            .objectives
                                                                                                            .length /
                                                                                                            3
                                                                                                ) &&
                                                                                            indexObjective >=
                                                                                                capability
                                                                                                    .objectives
                                                                                                    .length /
                                                                                                    3
                                                                                                ? divider++
                                                                                                : (divider = 0);

                                                                                            return (
                                                                                                divider >
                                                                                                    0 &&
                                                                                                objective.name && (
                                                                                                    <div
                                                                                                        key={
                                                                                                            'ObjectiveIdSecondColumn' +
                                                                                                            indexObjective +
                                                                                                            capability.id +
                                                                                                            threat.id +
                                                                                                            risk.id
                                                                                                        }
                                                                                                    >
                                                                                                        {divider >
                                                                                                            1 && (
                                                                                                            <Divider />
                                                                                                        )}
                                                                                                        {ObjectiveTemplateJsx(
                                                                                                            objective,
                                                                                                            capability.status,
                                                                                                            capability.inScope
                                                                                                        )}
                                                                                                    </div>
                                                                                                )
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </ObjectiveContainer>
                                                                                <ObjectiveContainer>
                                                                                    {capability.objectives.map(
                                                                                        (
                                                                                            objective: ThreatObjective,
                                                                                            indexObjective: number
                                                                                        ) => {
                                                                                            indexObjective >
                                                                                            Math.ceil(
                                                                                                capability
                                                                                                    .objectives
                                                                                                    .length -
                                                                                                    capability
                                                                                                        .objectives
                                                                                                        .length /
                                                                                                        3
                                                                                            ) -
                                                                                                1
                                                                                                ? divider++
                                                                                                : (divider = 0);

                                                                                            return (
                                                                                                divider >
                                                                                                    0 &&
                                                                                                objective.name && (
                                                                                                    <div
                                                                                                        key={
                                                                                                            'ObjectiveIdThirdColumn' +
                                                                                                            indexObjective +
                                                                                                            capability.id +
                                                                                                            threat.id +
                                                                                                            risk.id
                                                                                                        }
                                                                                                    >
                                                                                                        {divider >
                                                                                                            1 && (
                                                                                                            <Divider />
                                                                                                        )}
                                                                                                        {ObjectiveTemplateJsx(
                                                                                                            objective,
                                                                                                            capability.status,
                                                                                                            capability.inScope
                                                                                                        )}
                                                                                                    </div>
                                                                                                )
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </ObjectiveContainer>
                                                                            </ObjectiveWrapper>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </CapabilityWrapper>
                                                                );
                                                            }
                                                        )}
                                                    </ThreatWrapperExpand>
                                                );
                                            }
                                        )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <TAREmptyState
                    headingText="Analyse your business risks"
                    explanationText={
                        'Gain insight into your risk landscape by leveraging your' +
                        ' maturity levels and threat scenarios to your risks'
                    }
                    buttonText="Add risk"
                    isAddButtonDisabled={isAddButtonDisabled}
                    imageSrc={riskIcon}
                    setAddTARModalOpen={setAddTARModalOpen}
                    setAddModal={setAddModal}
                />
            )}
        </div>
    );
};
const ThreatTitle = styled('div')`
    max-width: 300px;
    width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const StarIcon = styled('img')`
    width: 37px;
    position: relative;
    background-color: #ffffff;
    top: -3px;
    left: -5px;
`;

const ObjectiveIconTemplate = styled('div')`
    color: #ffffff;
    width: 32px;
    height: 32px;
`;

const ObjectiveKey = styled(ObjectiveIconTemplate)`
    border-radius: 50%;
    background-color: #2d2d2d;
`;

const ObjectiveType = styled(ObjectiveIconTemplate)`
    border-radius: 50%;
    background-color: #2d2d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
`;

const ObjectiveScore = styled(ObjectiveIconTemplate)<{
    background?: string;
    color?: string;
}>`
    background: ${(props) => (props.background ? props.background : 'none')};
    color: ${(props) => (props.color ? props.color : 'none')};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    border: solid 0.5px #d7d7d7;
`;

const Objective = styled('div')`
    padding-top: 24px;
    padding-bottom: 8px;
    color: #575757;
    font-size: 15px;
`;

const ObjectiveDescription = styled(Text)`
    color: #575757;
    font-size: 15px;
`;

const ObjectiveTitle = styled(ObjectiveDescription)`
    font-weight: bold;
`;

const CapabilityWrapper = styled('div')<{
    isFirstCapabilityExpanded: boolean;
    isPadding: boolean;
}>`
    padding: ${(props) => (props.isPadding ? '0' : '31px 32px 23px 15px')};
    border-top: ${(props) =>
        props.isFirstCapabilityExpanded ? 'none' : 'solid 1px #bbbbbb'};
`;

const ThreatExpandResilience = styled('div')<{ background?: string }>`
    width: 160px;
    height: 40px;
    border-radius: 2px;
    background-color: ${(props) =>
        props.background ? props.background : 'none'};
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const CapabilityName = styled('p')`
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #2d2d2d;
    margin: 0;
    margin-bottom: 8px;
`;

const ThreatExpandTitleWrapper = styled('div')`
    width: 27vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ThreatExpandTitleText = styled('p')`
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #2d2d2d;
    margin: 0;
    margin-bottom: 8px;
`;

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)`
    color: #2d2d2d;
`;

const KeyboardArrowUpIcon = styled(KeyboardArrowUp)`
    color: #2d2d2d;
`;

const ExpandAll = styled(Button)`
    font-size: 15px !important;
    color: #2d2d2d !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    margin-bottom: 12px !important;
    float: right;
`;

const RiskDescription = styled(Text)`
    position: relative;
`;

const RiskDescriptionWrapper = styled('div')`
    width: 300px;
    height: 200px;
    overflow: overlay;
    margin-left: 30px;
`;

const RiskRating = styled(Column)`
    width: 15%;
    max-width: 150px;
    text-align: center;
    padding: 16px;
`;

const AssociatedThreatContainer = styled('div')`
    padding: 0.5rem;
    width: 50%;
`;

const ThreatWrapper = styled(Flex)`
    margin-top: 5px;
`;

const ObjectiveWrapper = styled(Flex)`
    justify-content: space-between;
`;

const ObjectiveContainer = styled('div')`
    padding: 0 16px;
    width: 28%;
`;

export const ThreatColor = styled('button')<{ background?: string }>`
    border: none;
    width: 20px;
    height: 20px;
    background-color: ${(props) =>
        props.background ? props.background : 'none'};
    margin-left: auto;
`;

const ThreatContainer = styled('div')<{ oneThreat?: boolean; noBorder?: boolean }>`
    padding: 0 16px;
    border-right: ${(props) =>
        props.oneThreat || props.noBorder ? 'none' : '1px solid #666'};
    width: 50%;
`;

const Threat = styled('div')<{ border: boolean }>`
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #2d2d2d;
    padding: 8px 0px;
    border-bottom: ${(props) => (props.border ? '2px solid #dddddd' : 'none')};
`;

const AddThreat = styled('p')`
    margin-left: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #575757;
    text-decoration: underline;
    cursor: pointer;
`;

const RiskText = styled('p')`
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #2d2d2d;
    margin: 0;
    display: block;
    padding: 0.5rem 0;
`;

const RiskName = styled(RiskText)`
    margin-left: 9px;
    width: 17vw;
`;

const RiskContainer = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
`;

const RiskExpandContainer = styled('div')`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: 0px;
    width: 100%;
    position: relative;
`;

const ThreatWrapperExpand = styled('div')<{
    isLatestThreatExpanded: boolean;
    isFirstThreatExpanded: boolean;
}>`
    margin-bottom: ${(props) => (props.isLatestThreatExpanded ? '16px' : '0')};
    border-top: ${(props) =>
        props.isFirstThreatExpanded ? 'solid 1px #333333' : 'solid 1px #ececec'}
    padding: 24px 49px 5px 56px;
    flex-direction: column;
    border: solid 1px #ececec;
    background-color: #ffffff;
    display: flex;
`;

const RiskWrapper = styled('div')<{ isExpand?: boolean; hasThreats?: number }>`
    margin-bottom: ${(props) => (props.isExpand ? '0' : '16px')};
    background-color: ${(props) => (props.hasThreats ? '#f4f4f4' : '#ffffff')};
    border: ${(props) =>
        props.hasThreats ? 'solid 1px #ececec' : 'solid 1px #d9d9d9'};
    display: flex;
    flex-direction: column;
    padding: 24px 17px 24px 24px;
    border-radius: 4px;
`;

const Content = styled(Flex)`
    background-color: #40403e;
    width: -webkit-fill-available;
`;

const IconWrapper = styled(JustifyContentCenter)`
    background-color: #4588c3;
    padding: 19px 9px;
`;

const NotificationTitle = styled(Text)`
    padding: 11px 56px 4px 9px
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #ffffff;
`;

const NotificationText = styled(Text)`
    padding: 4px 56px 9px 9px
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #ffffff;
`;

const NotificationButton = styled(Button)`
    && {
        background-color: transparent;
        color: #ffffff;
        padding: 8px 16px;
        border-radius: 2px;
        border: solid 1px #ffffff;
        text-transform: none;
        font-size: 18px;
        font-weight: 500;
        height: 37px;
        margin-top: 12px;
    }
`;

export default RiskDashboardComponent;
