import React from 'react';
import { styled } from '@mui/material/styles';
import {
    AlignItems,
    JustifyContentCenter,
    Column,
    Text,
    BoldText
} from '../../../../../components';
import legendClose from '../../../../../assets/images/icon-legend-close.svg';
import { riskRating, threatRating } from '../../constants';
import { Ratings } from '../ratings';

type Props = {
    setIsLegendVisible: any;
    isRiskScenario?: boolean;
};

const TARLegend: React.FC<Props> = ({ setIsLegendVisible, isRiskScenario }) => {
    return (
        <LegendWrapper>
            <Column
                style={{
                    borderRight: 'solid 1px #d9d9d9',
                    alignSelf: 'flex-start'
                }}
            >
                <LegendContainer>
                    <LegendHeader>
                        <LegendHeaderText>
                            OBJECTIVE(S) TYPE
                        </LegendHeaderText>
                    </LegendHeader>
                    <LegendContent>
                        <CircleBlock>C</CircleBlock>
                        <DescriptionText>Corrective</DescriptionText>
                        <CircleBlock>D</CircleBlock>
                        <DescriptionText>Detective</DescriptionText>
                        <CircleBlock>P</CircleBlock>
                        <DescriptionText>Preventative</DescriptionText>
                        <CircleBlock>★</CircleBlock>
                        <DescriptionText>Key</DescriptionText>
                    </LegendContent>
                </LegendContainer>
                <LegendContainer>
                    <LegendHeader>
                        <LegendHeaderText>
                            OBJECTIVE(S) SCORE
                        </LegendHeaderText>
                    </LegendHeader>
                    <AlignItems
                        style={{
                            padding: '0.5rem 0 1rem 0'
                        }}
                    >
                        <SquareBlock
                            style={{
                                background: `repeating-linear-gradient(
                                    -45deg,
                                    transparent,
                                    transparent 4px,
                                    #d7d7d7 7px,
                                    #d7d7d7 7px
                                )`
                            }}
                        />
                        <DescriptionText>Out of scope</DescriptionText>
                        <SquareBlock />
                        <DescriptionText>Not scored</DescriptionText>
                    </AlignItems>
                </LegendContainer>
            </Column>

            <Column
                style={{
                    width: '100%',
                    alignSelf: 'flex-start',
                    paddingLeft: '1rem'
                }}
            >
                <LegendContainer>
                    <LegendHeader>
                        <LegendHeaderText>
                            THREAT RESILIENCE RATING
                        </LegendHeaderText>
                    </LegendHeader>
                    <LegendContent>
                        <Ratings ratings={threatRating} />
                    </LegendContent>
                </LegendContainer>

                {isRiskScenario && (
                    <LegendContainer>
                        <LegendHeader>
                            <LegendHeaderText>
                                RISK RATING (INHERENT/RESIDUAL)
                            </LegendHeaderText>
                        </LegendHeader>
                        <LegendContent>
                            <Ratings ratings={riskRating} numberOfColumns={6} />
                        </LegendContent>
                    </LegendContainer>
                )}
            </Column>
            <CloseItems onClick={() => setIsLegendVisible(false)}>
                <img
                    style={{ width: '1.2vw', height: '1.2vw' }}
                    src={legendClose}
                    alt="close"
                />
                <Text size="0.8vw" weight="bold" color="#525252">
                    CLOSE
                </Text>
            </CloseItems>
        </LegendWrapper>
    );
};

const LegendHeaderText = styled(Text)`
    font-weight: bold;
    color: #575757;
    font-size: 12px;
`;

const LegendWrapper = styled(AlignItems)`
    width: 100%;
    max-width: 100vw;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0 0 0 5%;

    @media (max-width: 1366px) {
        padding: 0 0 0 1%;
    }
`;

const CircleBlock = styled(BoldText)`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    align-items: flex-start;
    background: #2d2d2d;
    border-radius: 50%;
    color: white;
    text-align: center;
    padding: 0;
    display: inline-block;
`;

const SquareBlock = styled(JustifyContentCenter)`
    box-sizing: border-box;
    margin: 0 0.5rem 0 0;
    width: 42px;
    height: 42px;
    line-height: 40px;
    align-items: flex-start;
    color: white;
    text-align: center;
    display: inline-block;
    padding: 10;
    border-radius: 0;
    border: solid 0.5px #d7d7d7;
    background: none;
`;

const DescriptionText = styled(Text)`
    font-size: 15px;
    padding-right: 1rem;

    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;

const CloseItems = styled(AlignItems)`
    align-self: flex-start;
    margin: 0px;
    right: 0px;
    padding-top: calc(1.5rem + 15px);
    padding-right: 1rem;
    cursor: pointer;
`;

const LegendContent = styled(AlignItems)`
    position: relative;
    padding: 0.5rem 0 1rem 0;
`;

const LegendHeader = styled(AlignItems)`
    position: relative;
    padding: 0.5rem 0;
`;

const LegendContainer = styled(AlignItems)`
    position: relative;
    display: block;
`;

export default TARLegend;
