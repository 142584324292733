import React from 'react';
import { styled } from '@mui/material/styles';
import tickImage from '../../assets/images/mdi-check-circle.svg';

type Props = {
    content: string;
    show: boolean;
};

const Notification: React.FC<Props> = ({ content, show }) => {
    if (show === true) {
        return (
            <NotificationWrapper>
                <IconSection>
                    <img src={tickImage} alt="Tick Image" />
                </IconSection>
                {content}
            </NotificationWrapper>
        );
    }

    return null;
};

export default Notification;

const NotificationWrapper = styled('div')`
    background: #40403e;
    color: #fff;
    display: flex;
    flex-direction: row;
    width: 30%;
    align-items: center;
`;

const IconSection = styled('div')`
    background: #299d8f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 10%;
    margin-right: 15px;
`;
