import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Campaign } from './Campaign';
import { Text } from '../../../../components';

import { styled } from '@mui/material/styles';
import { CAMPAIGN_BY_CLIENT } from '../../LayoutHelpers';
import { dynamicSort,isReadOnlyEnabled } from '../../../../utils';
import { UserStore } from '../../../../context/user-store';

type CampaignListProps = {
    clientId: number;
    companiesAreOpen: boolean;
    setCompaniesAreOpen: CallableFunction;
};

type Campaign = {
    title: string;
    progress: number;
    id: string;
    client: {
        id: number;
        name: string;
    };
    readOnly?: boolean;
};

const CampaignList: React.FunctionComponent<CampaignListProps> = ({
    clientId,
    companiesAreOpen,
    setCompaniesAreOpen
}) => {
    const {
        state: {globalSettings },
    } = useContext(UserStore);
    const { loading, error, data } = useQuery(CAMPAIGN_BY_CLIENT,  {
        variables: { clientId: clientId},
        fetchPolicy:'no-cache',
    });
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    if (loading) {
        return <p>Loading...</p>;
    }
    if (error) {
        return <p>Please refresh the page and wait for 5 minutes between refreshes. <br/>
            If the page doesn't load after 2 attempts,<br/>
            please log a support issue ticket in ServiceNow</p>;
    }

    const campaignsByClient =
        data &&
        data.availableCampaignsByClient &&
        data.availableCampaignsByClient.filter(
            (campaign: Campaign) => campaign.client.id === clientId
        );
    return (
        <CampaignsWrapper>
            {campaignsByClient ? (
                campaignsByClient.sort(dynamicSort('title'))
                    .map((item: Campaign, index: number) => (
                    !(!readOnlyEnabled && item.readOnly)&&
                    <Campaign
                        key={index}
                        campaignId={item.id}
                        clientId={clientId}
                        campaignData={item}
                        companiesAreOpen={companiesAreOpen}
                        setCompaniesAreOpen={setCompaniesAreOpen}
                    />
                ))
            ) : (
                <Text>No campaigns available</Text>
            )}
        </CampaignsWrapper>
    );
};

export default CampaignList;

const CampaignsWrapper = styled('div')`
    margin-bottom: 20px;
`;
