import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DialogBox from '../../base/DialogBox';
import { autoSaveMessage } from '../../../utils';
import { useNavigate } from 'react-router';


type FormProps = {
    values: any;
    handleSubmit: (e: React.FormEvent<any>) => void;
    handleChange: (e: React.ChangeEvent) => void;
};

type Props = {
    when: boolean;
    setDirty?: any;
};
function Prompt(props: any) {
    const [blocked, setBlocked] = useState(false);

    if (blocked) {
      return <div>{props.message}</div>;
    }

    return (
        <div key={props.when}/>
      );
}

const PromptPopup: React.FC<Props> = ({ when = false,setDirty }) => {
    const location = useLocation();
    const history = useNavigate();
    const [lastLocation, setLastLocation] = useState(location);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [isOpen, setOpen] = useState<boolean>(false);


    const handleBlockedRoute = (nextLocation: any) => {
        if (!confirmedNavigation && when) {
            setOpen(true);
            setDirty&& setDirty(false);
            setLastLocation(nextLocation);
            return false;
        }

        return true;
    };
    const handleConfirmNavigationClick = () => {
        setOpen(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {

            history(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, history]);


    return (
        <div>
            <Prompt
                when={true}
                message={handleBlockedRoute}
            />
            <DialogBox
                title={''}
                content={autoSaveMessage}
                open={isOpen}
                handleClose={() => {
                    setOpen(false);
                    setDirty && setDirty(true);
                }}
                handleSubmit={() => handleConfirmNavigationClick()}
                buttonCancelText={'No'}
                buttonSaveText={'Yes'}
            />
        </div>
    );
};


export default PromptPopup;
