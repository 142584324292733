import React, { useEffect, useState, useContext } from 'react';
import gql from 'graphql-tag';
import { Stepper, Step, StepLabel, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Text from '../../components/base/Text/Text';
import { useMutation } from '@apollo/react-hooks';
import Router from '../../routes/router';
import { CampaignEntityGroup } from './CreateCampaignSteps/types';
import { UserStore } from '../../context/user-store';
import { Client as ClientType } from '../../context/reducer';
import redArrowIcon from '../../assets/images/icon-right-arrow-red.svg';
import greyArrowIcon from '../../assets/images/icon-grey-arrow-left.svg';
import closeIcon from '../../assets/images/Atmos_Icons_Close.svg';
import { Navigate } from 'react-router-dom';
import { SUBHEADER_DATA } from '../Layout/LayoutHelpers';
import { NotificationCategoryEnum, NotificationTypeEnum, PermissionTypeEnum, isNotificationsEnabled } from '../../utils';
import Flex from '../../components/base/Layout/Flex';
import { Button } from '../../components/base/Buttons';
import JustifyContentCenter from '../../components/base/Layout/JustifyContentCenter';
import { AlignItems } from '../../components/base/Layout';
import SecondStep from './CreateCampaignSteps/SecondStep';
import FirstStep from './CreateCampaignSteps/FirstStep';
import { EntityPermissionObject, FirstStepTypes, PermissionObject } from './types';
import { useTheme } from '@mui/material/styles';

const CREATE_CAMPAIGN = gql`
    mutation createCampaignWithPermission($data: CreateCampaignInput!, $permissions: [CreateOrUpdateUserEntityPermissionInput!]!) {
        createCampaign(data: $data) {
            id
            title
            description
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCompletedCnt
            assessmentsCnt
            progress
            client {
                id
                name
            }
            stakeholder {
                id
                firstName
                lastName
            }
            manager {
                id
                firstName
                lastName
            }
            members {
                id
                firstName
                lastName
            }
            readonlymembers {
                id
                firstName
                lastName
            }
            framework {
                definition {
                    name
                }
            }
            status
            updatedAt
        }
        createOrUpdatePermissions(userEntityPermissionInputs: $permissions) {
            id
            userId
            campaignEntityId
            permission
        }
    }
`;

const CREATE_NOTIFICATION = gql`
    mutation createNotification($input: CreateNotificationInput!) {
        createNotification(input: $input) {
            userId
            notificationType
            notificationCategory
            referenceID
            notificationMessage
            wasRead
        }
    }
`;

const getSteps = () => {
    return ['Enter campaign details', 'Create entities and members'];
};
const GetStepContent = (
    stepIndex: number,
    submitting: number,
    getFirstStepValues: any,
    firstStepValues: FirstStepTypes | undefined,
    setSecondStep: any,
    secondStep: CampaignEntityGroup[],
    members: number[],
    setMembers: any,
    readOnlyMembers: number[],
    setReadOnlyMembers: any,
    setActiveStep: any,
    clientId: number,
    setFirstStepValid: any,
    newStakeHolders: string[],
    setNewStakeholders: any,
    allStakeholdersInCampaign: string[],
    setAllStakeholdersInCampaign: any,
    permissionStructureData: PermissionObject[],
    setPermissionStructureData: any,
    setClientSelected: any
) => {
    const getSecondStepValues = (values: CampaignEntityGroup[]) => setSecondStep(values);
    switch (stepIndex) {
        case 0:
            return (
                <FirstStep
                    getFirstStepValues={getFirstStepValues}
                    submitting={submitting}
                    firstStepValues={firstStepValues}
                    setMembers={setMembers}
                    setReadOnlyMembers={setReadOnlyMembers}
                    getSecondStepValues={getSecondStepValues}
                    setActiveStep={setActiveStep}
                    clientId={clientId}
                    setFirstStepValid={setFirstStepValid}
                    setClientSelected={setClientSelected}
                />
            );
        case 1:
            return (
                <SecondStep
                    secondStep={secondStep}
                    getSecondStepValues={getSecondStepValues}
                    campaignTitle={(firstStepValues && firstStepValues.title) }
                    clientId={
                        (firstStepValues && firstStepValues.clientId) || 0
                    }
                    newStakeHolders={newStakeHolders}
                    setNewStakeholders={setNewStakeholders}
                    allStakeholdersInCampaign={allStakeholdersInCampaign}
                    setAllStakeholdersInCampaign={setAllStakeholdersInCampaign}
                    setMembers={setMembers}
                    setReadOnlyMembers={setReadOnlyMembers}
                    readOnlyMembers={readOnlyMembers}
                    members={members}
                    managerId={
                        (firstStepValues && firstStepValues.managerId) || []
                    }
                    permissionStructureData={permissionStructureData}
                    setPermissionStructureData={setPermissionStructureData}
                />
            );
        default:
            return 'Uknown stepIndex';
    }
};
const CreateCampaign: React.FC = () => {

    const {
        state: { client, user, globalSettings },
        dispatch,
    } = useContext(UserStore);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [firstStepValues, setFirstStepValues] = useState<FirstStepTypes | undefined>();
    const [firstStepValid, setFirstStepValid] = useState();
    const [secondStep, setSecondStep] = useState<CampaignEntityGroup[]>([]);
    const [members, setMembers] = useState<number[]>([]);
    const [readOnlyMembers, setReadOnlyMembers] = useState<number[]>([]);
    const [submitting, setSubmitting] = useState<number>(0);
    const [clientSelected, setClientSelected] = useState<ClientType>(client);
    const [newStakeHolders, setNewStakeholders] = useState<string[]>([]);
    const [allStakeholdersInCampaign, setAllStakeholdersInCampaign] = useState<string[]>([]);
    const [clientId, setClientId] = useState<number>(0);
    const [permissionStructureData, setPermissionStructureData] = useState<PermissionObject[]>([]);
    const [entiryPermission, setEntityPermission] = useState<EntityPermissionObject[]>([]);
    const canCreateCampaign = secondStep && secondStep.length > 0 && secondStep.every((item: any) => item.campaignEntities.length > 0);


    useEffect(() => {
        if (client && client.id) {
            setClientId(client.id);
        }
    }, [client]);

    useEffect(() => {
        if (firstStepValues && firstStepValues.managerId) {
            const found = members.find(
                (element) => firstStepValues.managerId.includes(element));

            if (found) {
                return undefined;
            }
            const memberList: number[] = [];
            if(clientSelected.owner && !firstStepValues.managerId.map(manager => manager).includes(clientSelected.owner.id)){
                firstStepValues.managerId.forEach((id) => {
                    memberList.push(id);
                });
                memberList.push(clientSelected.owner.id);
            } else {
                firstStepValues.managerId.forEach((id) => {
                    memberList.push(id);
                });
            }
            if(user.id && !memberList.includes(user.id)){
                memberList.push(user.id);
            }
            setMembers(memberList);
        }
    }, [firstStepValues]);

    useEffect(() => {
        if (firstStepValues && firstStepValues.managerId) {
            const memberList: number[] = [];
            if(clientSelected.owner && !firstStepValues.managerId.map(manager => manager).includes(clientSelected.owner.id)){
                firstStepValues.managerId.forEach((id) => {
                    memberList.push(id);
                });
                memberList.push(clientSelected.owner.id);
            } else {
                firstStepValues.managerId.forEach((id) => {
                    memberList.push(id);
                });
            }
            if(user.id && !memberList.includes(user.id)){
                memberList.push(user.id);
            }
            setMembers(memberList);
        }
    }, [clientSelected]);

    useEffect(() => {
        if (permissionStructureData && permissionStructureData.length > 0) {
            const permissions = permissionStructureData.filter((permission) => {
                return permission.campaignEntityId !== undefined && permission.userId !== undefined && permission.permission !== undefined;
            }).map((permission: PermissionObject) => {
                return {
                    userId: permission.userId,
                    campaignEntityId: permission.campaignEntityId,
                    permission: permission.permission,
                };
            }, []);
            setEntityPermission(permissions);
        }
    }, [permissionStructureData]);

    const setCurrentClient = (currentClient: ClientType) => {
        dispatch({ type: 'SET_CLIENT', value: currentClient });
    };

    const steps = getSteps();
    const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
        refetchQueries: [
            {
                query: SUBHEADER_DATA,
                variables: { clientId: (client && client.id) || 0 },
            },
        ],
    });
    const [createNotification] = useMutation(CREATE_NOTIFICATION);

    const getFirstStepValues = (values: FirstStepTypes) => setFirstStepValues(values);


    const handleNext = () => {
        if (activeStep === 0) {
            setSubmitting(submitting + 1);
        } else if (activeStep === 1 && secondStep.length) {
            if (members.length) {
                const allUsedStakeholders = newStakeHolders.filter((sh: string) =>
                    allStakeholdersInCampaign.includes(sh)
                );


                const withoutKeys = secondStep.map((el: any) => {
                    const attrToRemove: string[] = [
                        'editKey',
                        'children',
                        'archivedDate',
                        'expanded',
                        'type',
                    ];


                    attrToRemove.forEach((e) => delete el[e]);


                    let parentOwnerIndex = null;
                    const parentStakeholderIndexes: Array<number> = [];


                    if (allUsedStakeholders.includes(el.ownerId)) {
                        parentOwnerIndex = allUsedStakeholders.indexOf(JSON.parse(JSON.stringify(el.ownerId)));
                        el.ownerId = null;
                    }
                    el.stakeholderIds = el.stakeholderIds.filter((shId: string) => {
                        if (allUsedStakeholders.includes(shId)) {
                            parentStakeholderIndexes.push(
                                allUsedStakeholders.indexOf(
                                    shId
                                )
                            );
                        }
                        return !allUsedStakeholders.includes(
                            shId
                        );
                    });


                    el.ownerIndex = parentOwnerIndex;
                    el.stakeholderIndexes = parentStakeholderIndexes;


                    return {
                        ...el,
                        campaignEntities: el && el.campaignEntities.map((entity: any) => {
                            entity.id = entity.editKey;
                            attrToRemove.forEach((e) =>
                                delete entity[e]
                            );
                            let entityOwnerIndex = null;
                            const entityStakeholderIndexes: Array<number> = [];


                            if (allUsedStakeholders.includes(entity.ownerId)) {
                                entityOwnerIndex = allUsedStakeholders.indexOf(JSON.parse(JSON.stringify(entity.ownerId)));
                                entity.ownerId = null;
                            }


                            entity.stakeholderIds = entity.stakeholderIds.filter((shId: string) => {
                                if (allUsedStakeholders.includes(shId)) {
                                    entityStakeholderIndexes.push(
                                        allUsedStakeholders.indexOf(
                                            shId
                                        )
                                    );
                                }
                                return !allUsedStakeholders.includes(shId);
                            });


                            entity.ownerIndex = entityOwnerIndex;
                            entity.stakeholderIndexes = entityStakeholderIndexes;
                            return entity;
                        }),
                    };
                });


                const newFormat = allUsedStakeholders.map((sh: string) => {
                        const firstName = sh.split(/ (.+)/)[0];
                        const lastName = sh.split(/ (.+)/)[1];
                        return {
                            firstName: firstName,
                            lastName: lastName,
                        };
                    }
                );


                createCampaign({
                    variables: {
                        data: {
                            ...firstStepValues,
                            newStakeHolders: newFormat,
                            campaignEntityGroups: withoutKeys,
                            memberIds: members.length>1 ? members.filter(member => member !== clientSelected.owner?.id) : members,
                            readOnlyMemberIds: readOnlyMembers,
                        },
                        permissions: entiryPermission
                    },
                }).then(async (response) => {
                    if (response.data.createCampaign) {
                        const choosenClient = (user && user.clients && user.clients.find((item) =>
                            item.id === (firstStepValues && firstStepValues.clientId)
                        )) || client;


                        setCurrentClient(choosenClient);


                        if (isNotificationsEnabled(globalSettings)) {
                            const notificationList = response.data.createCampaign.manager.find((managerInfo: any) => managerInfo.id === clientSelected.owner?.id) ?
                                members : members.filter(member => member !== clientSelected.owner?.id);
                            const managerID = response.data.createCampaign.manager.map((manager: any)=> manager.id);
                            if ( notificationList.length > 0 ) {
                                    await notificationList.map(async (member) => {
                                        let message = '';
                                        if(managerID.includes(member) && member !== user.id){
                                            message = `You are now a Campaign Manager in ${response.data.createCampaign.title} for ${client.name}.\nPlease check if any actions need your attention.`;
                                        } else if(!managerID.includes(member)){
                                            message = `Campaign ${response.data.createCampaign.title} has been assigned to you.`;
                                        }
                                        message.length > 0 &&  await createNotification({
                                            variables: {
                                                input: {
                                                    userId: member,
                                                    notificationType: NotificationTypeEnum.INFO,
                                                    notificationCategory: NotificationCategoryEnum.CAMPAIGN,
                                                    notificationMessage: message,
                                                    referenceID:JSON.stringify({
                                                        companyId: client.id,
                                                        campaignId: response.data.createCampaign.id
                                                    }) ,
                                                    wasRead: false,
                                                },
                                            },
                                        });
                                    }
                                );
                            }

                        }

                        Router.goToMaturityOverview(response.data.createCampaign.id);
                    }
                }).catch((error: any) => {
                    console.error(error);
                });
            }
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    return user.role ? (
        <CreateCampaignContainer>
            <Flex style={{ justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        Router.goToDashboard();
                    }}
                    style={{
                        border: 'none',
                        backgroundColor: 'inherit',
                    }}
                >
                    <img src={closeIcon} alt='close' id="skip-to-content" tabIndex={-1}/>
                </Button>
            </Flex>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                style={{
                    margin: '0 auto',
                    backgroundColor: '#f8f8f8',
                    color: '#575757',
                }}
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <JustifyContentCenter
                style={{
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    border: 'solid 1px #d9d9d9',
                }}
            >
                {activeStep === steps.length ? (
                    <div>Campaign created</div>
                ) : (
                    <div style={{ width: '100%' }}>
                        <InstructionText>
                            {GetStepContent(
                                activeStep,
                                submitting,
                                getFirstStepValues,
                                firstStepValues,
                                setSecondStep,
                                secondStep,
                                members,
                                setMembers,
                                readOnlyMembers,
                                setReadOnlyMembers,
                                setActiveStep,
                                clientId,
                                setFirstStepValid,
                                newStakeHolders,
                                setNewStakeholders,
                                allStakeholdersInCampaign,
                                setAllStakeholdersInCampaign,
                                permissionStructureData,
                                setPermissionStructureData,
                                setClientSelected
                            )}
                        </InstructionText>
                        <AlignItems
                            style={{
                                justifyContent: 'flex-end',
                                marginRight: 44,
                                marginBottom: 12,
                                marginTop: 50,
                            }}
                        >
                            {activeStep !== 0 && (
                                <BackButtonCust
                                    onClick={handleBack}>
                                    <AlignItems>
                                        <img
                                            src={greyArrowIcon}
                                            alt="back arrow"
                                            aria-hidden={true}
                                        />
                                        <ButtonText
                                            color="#6E6E6E"
                                            style={{ marginLeft: 23 }}
                                        >
                                            Back
                                        </ButtonText>
                                    </AlignItems>
                                </BackButtonCust>
                            )}
                            {activeStep !== steps.length - 1 ? (
                                <NextButton
                                    disabled={
                                        !!(
                                            activeStep === 0 && !firstStepValid
                                        ) ||
                                        !!(
                                            activeStep === 1 &&
                                            (!secondStep.length ||
                                                secondStep.some(
                                                    (el: CampaignEntityGroup) =>
                                                        !el.campaignEntities
                                                            .length
                                                ))
                                        )
                                    }
                                    onClick={handleNext}>
                                    <AlignItems>
                                        <ButtonText
                                            color="#de3328"
                                            style={{ marginRight: 31 }}
                                        >
                                            {activeStep === steps.length - 1
                                                ? 'Create Campaign'
                                                : 'Next'}
                                        </ButtonText>
                                        <img
                                            src={redArrowIcon}
                                            style={{
                                                transform: 'rotate(180deg)',
                                            }}
                                            alt="forward arrow"
                                            aria-hidden={true}
                                        />
                                    </AlignItems>
                                </NextButton>
                            ) : (
                                <NextButton onClick={handleNext} disabled={ !canCreateCampaign }>
                                    <ButtonText color="#de3328">
                                        Create Campaign
                                    </ButtonText>
                                </NextButton>
                            )}
                        </AlignItems>
                    </div>
                )}
            </JustifyContentCenter>
        </CreateCampaignContainer>
    ) : (
        <Navigate
            to={{
                pathname: '/',
            }}
        />
    );
};

const CreateCampaignContainer = styled('div')`
        width: 100%;
        margin-top: 50;
`;

const InstructionText = styled(Text)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const BackButton = styled(Button)`
    && {
        border: none;
        padding: 0 21px 0 19px;
        &:hover {
            opacity: 1;
            background-color: inherit;
        }
    }
`;

const BackButtonCust = styled(BackButton)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const NextButton = styled(Button)`
    && {
        border: solid 1px #de3328;
        padding: 0 21px 0 19px;
        margin-left: 48px;
        &:disabled {
            opacity: 0.5;
        }
    }
`;

const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
`;

export default CreateCampaign;
