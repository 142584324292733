import React, { useContext, useEffect, useState } from 'react';
import { AlignItems, Flex } from '../../../components/base/Layout';
import {
    DialogBox,
    ImgContainer,
    LastUpdated,
    LinearProgress,
    NavLink
} from '../../../components';
import iconTime from '../../../assets/images/icon_time.svg';
import { countProgress, getLastUpdatedDateDifference, isReadOnlyEnabled } from '../../../utils';
import {
    AssessmentsList,
    AssessmentsTableRow,
    AssessmentsFilteredList
} from './types';
import { UserStore } from '../../../context/user-store';
import { DomainWeight } from '../AssessmentsMaturityOverview/types';

export const useAssessmentsList = ({
    data,
    filterActiveStatus,
    filterProcessStatus
}: {
    data: AssessmentsList[];
    filterActiveStatus: string;
    filterProcessStatus: string;
}) => {
    const {
        state: {globalSettings },
    } = useContext(UserStore);
    const [assessmentsList, setAssessmentsList] = useState<
        AssessmentsTableRow[]
    >([]);
    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    useEffect(() => {
        if (data.length) {
            const assessmentsFilteredList = data
                .filter((assessment: AssessmentsList) => {
                    switch (filterProcessStatus) {
                        case 'ALL_ASSESSMENTS':
                            return assessment;
                        case 'COMPLETED':
                            return assessment.state.status === 'COMPLETED';
                        case 'NOT_STARTED':
                            return assessment.state.status === 'NOT_STARTED';
                        case 'IN_PROGRESS':
                            return assessment.state.status === 'IN_PROGRESS';
                        default:
                            return assessment;
                    }
                }).filter((assessment: AssessmentsList) => {
                    switch (filterActiveStatus) {
                        case 'ALL':
                            return assessment;
                        case 'ARCHIVED':
                            return assessment.campaignEntity.archivedDate;
                        case 'ACTIVE':
                            return !assessment.campaignEntity.archivedDate;
                        default:
                            return assessment;
                    }
                }).filter((ass: AssessmentsList)=>
                        !(!readOnlyEnabled && ass.campaign.readOnly)
                )
                .map((assessment: AssessmentsList) => {
                    return {
                        name: (
                            <NavLink
                                to={`/main/evaluate/maturity/${assessment.campaign.id}/assessments/${assessment.id}`}
                                reloadDocument={true}
                            >
                                {assessment.campaignEntity.name}
                            </NavLink>
                        ),
                        campaignTitle: assessment.campaign.title,
                        framework: `${assessment.framework.definition.name}`,
                        capCovered: `${Number(
                            assessment.state.capabilitiesScoredCnt
                        )}/${Number(assessment.state.capabilitiesCnt)}`,
                        capModerated: `${Number(
                            assessment.state.capabilitiesModeratedCnt
                        )}/${Number(assessment.state.capabilitiesCnt)}`,
                        lastUpdated: (
                            <Flex>
                                <AlignItems>
                                    <ImgContainer
                                        src={iconTime}
                                        alt="iconTime"
                                    />
                                    <LastUpdated
                                        updatedOn={getLastUpdatedDateDifference(
                                            {
                                                currentDate:
                                                    assessment.updatedAt
                                            }
                                        )}
                                    />
                                </AlignItems>
                            </Flex>
                        ),
                        progress: (
                            <LinearProgress
                            progress={countProgress({
                                progressLength: assessment.state.capabilitiesCnt,
                                value: assessment.state.capabilitiesModeratedCnt
                            })}
                            />
                        ),
                        id: assessment.id || ''
                    };
                });
            setAssessmentsList(assessmentsFilteredList);
        }
    }, [data, filterActiveStatus, filterProcessStatus]);
    return [assessmentsList];
};

export const renderModal = ({
    modalId,
    handleClose,
    isOpen,
    checkedAssessmentsList,
    setSelectedRow,
    archiveAssessments
}: {
    isOpen: boolean;
    modalId: string;
    handleClose: () => void;
    checkedAssessmentsList: AssessmentsFilteredList[];
    setSelectedRow: (data: string[]) => void;
    archiveAssessments: (data: object) => Promise<object>;
}) => {
    const store = new Map();

    store.set(
        'archive',
        <DialogBox
            title={`Archive ${
                checkedAssessmentsList.length ? 'Assessments' : 'Assessment'
            }`}
            buttonCancelText="Cancel"
            buttonSaveText={`Archive ${
                checkedAssessmentsList.length ? 'assessments' : 'assessment'
            }`}
            content={`Are you sure you want to archive "${checkedAssessmentsList
                .map((item: AssessmentsFilteredList) => item.title)
                .join(', ')}" ${
                checkedAssessmentsList.length ? 'assessments' : 'assessment'
            }?`}
            open={isOpen}
            handleClose={handleClose}
            handleSubmit={() => {
                checkedAssessmentsList.length &&
                    archiveAssessments({
                        variables: {
                            ids: checkedAssessmentsList.map(
                                (item: AssessmentsFilteredList) => {
                                    return item.id;
                                }
                            )
                        }
                    })
                        .then(() => {
                            setSelectedRow([]);
                            handleClose();
                        })
                        .catch(err => {
                            console.log('Archive assessment Error', err);
                        });
            }}
        />
    );

    return store.get(modalId) || null;
};

export const useAssessmentsStatus = (
    data: AssessmentsList[],
    selectedRow: string[]
) => {
    const [assessmentsList, setAssessmentsList] = useState<
        AssessmentsFilteredList[]
    >([]);
    useEffect(() => {
        if (data.length) {
            const filteredList = data
                .filter((item: AssessmentsList) =>
                    selectedRow.includes(item.id)
                )
                .map((item: AssessmentsList) => {
                    return {
                        id: item.id,
                        title: item.campaignEntity.name
                    };
                });
            setAssessmentsList(filteredList);
        }
    }, [data, selectedRow]);
    return [assessmentsList];
};

export const isDomainCapWeightValid = (domainCapWeight: DomainWeight[]) => {
    if (domainCapWeight.length > 0) {

        const isCapWeightingValid = domainCapWeight.every(domain => {
            const capWeightings = domain.capability.map(cap => (cap.value));
            return isWeightingValid(capWeightings);
        });

        if (!isCapWeightingValid) {
            return false;
        }

        const weightings = domainCapWeight.map(domain => (domain.value));
        return isWeightingValid(weightings);
    }
    return false;
};

export const isWeightingValid = (weightings: number[]) => {
    const sumOfWeights = weightings.reduce((sum, value) => (sum || 0) + (value || 0));
    const summary = sumOfWeights ? (Math.round((sumOfWeights + Number.EPSILON) * 100) / 100) : 0;

    if (!(summary && (summary > 99 && summary <= 100))) {
        return false;
    }
    return true;
};
