import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Score } from './types';
import Pie from './PieChart';
type Props = {
    scoresList: Score[];
    maxScoreRange: number;
    minScoreRange: number;
    sectorSize: number;
    colorsList: string[];
    bottomDomain: number;
    saveAsImageText: string;
    showTargetScore?: boolean;
    showCapabilityStatus?: boolean;
    pointOneDecimalEnable?: boolean;
};

const PieChart: React.FC<Props> = ({
    scoresList,
    maxScoreRange,
    minScoreRange,
    sectorSize,
    bottomDomain,
    colorsList,
    saveAsImageText,
    showTargetScore,
    showCapabilityStatus,
    pointOneDecimalEnable
}) => {
    const container = useRef<HTMLDivElement>(document.createElement('div'));
    Pie({scoresList,
        maxScoreRange,
        minScoreRange,
        sectorSize,
        bottomDomain,
        colorsList,
        saveAsImageText,
        cont: container.current,
        showSaveImage:true,
        showTargetScore,
        showCapabilityStatus,
        pointOneDecimalEnable
    });
    return (
        <ChartContainer
            ref={container}
        />
    );
};
const ChartContainer = styled('div')`
    height: 600px;
    width: 1200px;
    @media (max-width: 1250px) {
        transform: scale(0.8);
    }
    @media (max-width: 990px) {
        transform: scale(0.6);
    }
    @media (max-width: 768px) {
        transform: scale(0.5);
    }
`;

export default PieChart;
