import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Text from '../../../components/base/Text/Text';
import dropdownArrow from '../../../assets/images/icon-drop-down-dark.svg';
import { Divider, List, ListItem, Checkbox, Popover, createTheme, ThemeProvider } from '@mui/material';
import { ApplyAndClearWrapper, ApplyButton, ClearButton } from '../Buttons';
import { Chips } from '../Chips';
import { ThreatObject } from '../../../modules/Analyse/ThreatAndRisk/types';
import ImgContainer from '../ImgContainer';
import ArrowTooltip from '../../Tooltips/ArrowTooltip';

type Props = {
    options?: ThreatObject[];
    placeholder: string;
    buttonDisabled?: boolean;
    disableClear?: boolean;
    values: ThreatObject[];
    handleDeleteChips: any;
    dropdownsSelected: ThreatObject[];
    onDropdownClick: any;
    onClear: any;
    onApply: any;
    onCheckAll: () => void;
    anchorEl: HTMLButtonElement | null;
    onButtonClick: any;
    allCheckboxed?: boolean;
    disableApply: boolean;
};

const DropdownMulti: React.FC<Props> = ({
    options,
    placeholder,
    buttonDisabled = false,
    disableClear,
    values,
    handleDeleteChips,
    dropdownsSelected,
    onDropdownClick,
    onClear,
    onApply,
    onCheckAll,
    anchorEl,
    onButtonClick,
    allCheckboxed,
    disableApply
}) => {
    const theme = createTheme({
        components: {
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        marginTop: '1rem',
                        boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.25)',
                        paddingBottom: '8px',
                        width: '400px'
                    },
                },
            },
        },
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const anchorId = isOpen ? 'simple-popover' : '';

    return (
        <ThemeProvider theme={theme}>
            <div>
                <MenuButtonContainer
                    background={buttonDisabled ? 'none' : '#ececec'}
                    disabled={buttonDisabled}
                    aria-describedby={anchorId}
                    onClick={(e) => {
                        setIsOpen(true);
                        onButtonClick(e);
                    }}
                    width={'400px'}>
                    <Text color="#575757">{placeholder}</Text>
                    <ImgContainer
                        src={dropdownArrow}
                        alt="dropdown arrow"
                        aria-hidden
                    />
                </MenuButtonContainer>

                {values && values.length > 0 && (
                    <Chips values={values} handleDelete={handleDeleteChips} />
                )}

                <Popover
                    id={anchorId}
                    open={isOpen}
                    keepMounted={isOpen}
                    onClose={() => setIsOpen(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}>
                    <CustomList>
                        <ListSelectionAsessment
                            style={{
                                padding: '14px 0',
                                margin: '0',
                                height: '52px'
                            }}>
                            <Checkbox
                                color="default"
                                checked={allCheckboxed}
                                onChange={onCheckAll}
                                inputProps={{
                                    'aria-label': 'select all'
                                }}
                                style={{
                                    padding: '0 9px'
                                }}
                            />
                            <Text>All</Text>
                        </ListSelectionAsessment>

                        <Divider />

                        {options ? (
                            options.map((item: ThreatObject, index: number) => (
                                <ListSelectionAsessment
                                    key={`dropdown-multi-menu-item-${index}`}>
                                    <Checkbox
                                        color="default"
                                        checked={
                                            dropdownsSelected &&
                                            dropdownsSelected.some(
                                                (selected: ThreatObject) =>
                                                    selected.title === item.title
                                            )
                                        }
                                        onChange={e => onDropdownClick(e, item.title)}
                                        inputProps={{
                                            'aria-label': 'select item'
                                        }}
                                        style={{
                                            padding: '0 9px'
                                        }}
                                    />
                                    <ArrowTooltip
                                        title={item.title}
                                        placement="bottom"
                                        minWidth={'0px'}>
                                        <TextWrapper>
                                            <Text>{item.title}</Text>
                                        </TextWrapper>
                                    </ArrowTooltip>
                                </ListSelectionAsessment>
                            ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </CustomList>
                    <Divider />
                    <ButtonWrapper>
                        <ClearAllButton
                            disabled={disableClear}
                            onClick={() => {
                                onClear();
                                setIsOpen(false);
                            }}>
                            Clear
                        </ClearAllButton>
                        <ApplyButton
                            disabled={disableApply}
                            onClick={() => {
                                onApply();
                                setIsOpen(false);
                            }}>
                            Apply
                        </ApplyButton>
                    </ButtonWrapper>
                </Popover>
            </div>
        </ThemeProvider>
    );
};

export const ButtonWrapper = styled(ApplyAndClearWrapper)`
    padding-left: 8px;
`;

export const ClearAllButton = styled(ClearButton)`
    width: 50%;
`;

const CustomList = styled(List)`
    width: 400px;
    margin: 0;
    padding: 0;
    background-color: white;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    
    &:focus {
        outline: 0;
    }
`;

const ListSelectionAsessment = styled(ListItem)`
    display: flex;
    justify-content: start;
    background-color: inherit;
    padding: 0px;
    margin: 0px 5px;
    height: 42p;
`;

const TextWrapper = styled('div')`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 98%;
`;

const MenuButtonContainer = styled('button')<{
    background?: string;
    width?: string;
    disabled?: boolean;
}>`
    font-family: 'CustomHelveticaNeue';
    padding: 9px;
    box-sizing: border-box;
    display: flex;
    width: ${props => props.width};
    align-items: center;
    background: transparent;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    opacity: ${props => (props.disabled ? '0.4' : '1')};
    &:hover {
        background: ${props => (props.background ? props.background : 'none')};
    }
`;

export default DropdownMulti;
