import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { JustifyContentCenter } from '../../../../../components';
import { CardHeader, Card } from '@mui/material';
import * as echarts from 'echarts';
import {
    CampaignGroupAssessment,
    CampaignAssessment,
    MaturityOverviewDomain,
    MaturityOverviewCapability
} from '../../MaturityOverview/types';
import { capabilitiesAverage } from '../MaturityOverviewTabsHelpers';
import {PermissionTypeEnum, chartDraftWarning, colorsList, roundGraphScore, roundScore} from '../../../../../utils';
import TooltipByClick from '../../../../../components/Tooltips/TooltipByClick';
import ChartWarningTag from '../../../../../components/base/ChartWarning/ChartWarningTag';
// tslint:disable-next-line: no-var-requires
const dataTool = require('echarts/extension/dataTool');

type Props = {
    assessmentsData: CampaignGroupAssessment[];
    companyName: string;
    campaignTitle: string;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    campaignComplete?: boolean;
    pointOneDecimalEnable: boolean;
    showCapabilityStatus?: boolean;
};
type chart = {
    setOption: any;
    getDataURL: any;
};
type boxPlot = {
    boxData: number[][];
};
type graph = {
    Score: number | null;
    Entity: string;
    inScope: boolean;
};
type domainwithAverageData = {
    ScoreArray: number[];
    Capabilities: string;
    Score: number | null;
    Domain: string;
    inScope: boolean;
};
type seriesDataType = {
    name: string;
    type: string;
    data: (number | null)[] | (number | null)[][];
    zlevel: number;
    markPoint: object;
};
type legend = {
    name: string;
    textStyle: textStyle;
};
type textStyle = {
    color: string;
};
const MaturityOverviewBoxChart: React.FC<Props> = ({
    assessmentsData,
    companyName,
    campaignTitle,
    readOnlyEnabled=false,
    isReadOnly=false,
    campaignComplete = true,
    pointOneDecimalEnable= false
}) => {
    const container = useRef<HTMLDivElement>(document.createElement('div'));
    let option: object | null = null;
    const dataAxis: string[] = [];
    const entityNameForCategories: legend[] = [];
    let formatterData: boxPlot;
    const source: Array<graph> = [];
    const seriesData: seriesDataType[] = [];
    const objectivesLabelsList =
        assessmentsData &&
        assessmentsData[0].assessments[0].framework.definition.scores;

    useEffect(() => {
        assessmentsData.forEach((cga: CampaignGroupAssessment) => {
            cga.assessments.forEach((ca: CampaignAssessment, i: number) => {
                if(readOnlyEnabled && (isReadOnly || ca.permission === PermissionTypeEnum.READ_ONLY_ACCESS) && ca.campaign.status !== 'COMPLETED') {
                    return;
                }
                ca.state.domains.forEach((mod: MaturityOverviewDomain) => {
                    mod.capabilities.forEach(
                        (moc: MaturityOverviewCapability) => {
                            source.push({
                                Entity: ca.campaignEntity.name,
                                Score: moc.inScope
                                    ? moc.moderatedScore !== null
                                        ? moc.moderatedScore
                                        : moc.averageScore
                                    : null,
                                inScope: moc.inScope
                            });
                        }
                    );
                });
                entityNameForCategories.push({
                    name: ca.campaignEntity.name,
                    textStyle: { color: colorsList[i] }
                });
            });
        });
        const DomainsWithAverageArray = capabilitiesAverage(assessmentsData, readOnlyEnabled, isReadOnly);
        const nullPoints = DomainsWithAverageArray.filter(
            (src: domainwithAverageData) => src.inScope === false
        ).map((flt: domainwithAverageData) => {
            return {
                name: 'NullValue',
                value: 'N/A',
                xAxis: flt.Capabilities,
                yAxis: 0.15,
                label: { color: 'black' }
            };
        });
        const prepareBoxplot = [];
        for (const el of DomainsWithAverageArray) {
            prepareBoxplot.push(el.ScoreArray.filter(x => x !== null));
            dataAxis.push(el.Capabilities);
        }
        formatterData = dataTool.prepareBoxplotData(prepareBoxplot);
        formatterData.boxData = formatterData.boxData.map(scores => {
            return scores.map(score => {
                return (roundScore(score, pointOneDecimalEnable)||0);
            });
        });
        for (const entityNameForCategory of entityNameForCategories) {
            const filteredByEntity = source.filter(
                x => x.Entity === entityNameForCategory.name
            );
            const scatterData: (number | null)[] = [];
            filteredByEntity.forEach(x =>
                scatterData.push(roundGraphScore(x.Score, pointOneDecimalEnable))
            );
            seriesData.push({
                name: entityNameForCategory.name,
                type: 'scatter',
                data: scatterData,
                zlevel: 1,
                markPoint: {}
            });
        }
        seriesData.push({
            name: 'box',
            type: 'boxplot',
            data: formatterData.boxData,
            zlevel: 0,
            markPoint: {
                symbol: 'rect',
                symbolSize: 20,
                data: nullPoints,
                itemStyle: {
                    color: 'white',
                    opacity: 0.8
                }
            }
        });
        boxChart();
    }, [assessmentsData]);
    const boxChart = () => {
        chartforBox = echarts.init(container && container.current);
        option = {
            graphic: assessmentsData[0].assessments[0].state.status !== 'COMPLETED' ?[
                {
                  type: 'text',
                  left: '180',
                  top: '100',
                 style: {
                        fill: '#000',
                        text: 'DRAFT',
                        font: ' 280px sans-serif',
                        opacity:0.2
                      }
                }
              ] : undefined,
            color: colorsList,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            toolbox: {
                show: (!(readOnlyEnabled && isReadOnly) ||  (readOnlyEnabled && isReadOnly && !campaignComplete)) ? false : true,
                orient: 'horizontal',
                right: '0%',
                itemSize: 20,
                itemGap: 10,
                top: 'top',
                feature: {
                    mark: { show: true },
                    saveAsImage: { title: 'as Image', show: true }
                }
            },
            grid: {
                bottom: 200,
                left: '15%',
                right: '10%',
                top: 120
            },
            legend: {
                data: entityNameForCategories,
                pageIconColor: colorsList,
                top: '14%'
            },
            xAxis: {
                name: 'Capabilities',
                type: 'category',
                data: dataAxis,
                axisTick: {
                    alignWithLabel: true,
                    length: 5
                },
                axisLabel: {
                    margin: 8,
                    rotate: 45,
                    fontSize: 11,
                    formatter: (xaxis: string) => {
                        return xaxis.slice(0, 25);
                    }
                }
            },
            yAxis: {
                name: 'Score',
                type: 'value',
                splitArea: {
                    show: true
                },
                interval: 0.5,
                min: 0,
                max: 5,
                axisLabel: {
                    formatter: (value: number) => {
                      let updatedObjectivesLabelsListArray = objectivesLabelsList;
                        if (objectivesLabelsList.length < 6) {
                            updatedObjectivesLabelsListArray = ['', ...objectivesLabelsList];
                        }
                        return Number.isInteger(value)
                            ? `${updatedObjectivesLabelsListArray[value]} ${value}`
                            : value;
                    }
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    start: 0,
                    end: 100,
                    bottom: 10
                }
            ],
            series: seriesData
        };
        chartforBox.setOption(option);
    };
    return (
        <ChartContainer>
            <GraphContainer>
                <Card>
                        <CardHeader
                            style={{ display: 'grid', justifyItems: 'center' }}
                            title={`${campaignTitle}: ${companyName} Maturity Box Chart`}
                        />
                        <ChartWarningTag> {chartDraftWarning}</ChartWarningTag>
                    <GraphDiv ref={container} />
                </Card>
            </GraphContainer>
        </ChartContainer>
    );
};
const ChartContainer = styled(JustifyContentCenter)`
    padding: 50px;
    flex-direction: column;
`;
const GraphContainer = styled('div')`
    width: 1200px;
    margin-top: 50;
    left: '0%';
    @media (max-width: 1250px) {
        transform: scale(0.8);
    }
    @media (max-width: 990px) {
        transform: scale(0.6);
    }
    @media (max-width: 768px) {
        transform: scale(0.5);
    }
`;
const GraphDiv = styled('div')`
    height: 600px;
    width: 100%;
    margin-top: 50;
    left: '0%';
`;
export let chartforBox: chart;
export default MaturityOverviewBoxChart;
