import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
// eslint-disable-next-line react/prop-types
export default function PrivateRoute({ children}) {
    const tokensExist = !!localStorage.getItem('pid-token');
    const auth = useAuth();
  return (
      auth.isLoading && tokensExist && !auth.isAuthenticated ?
        <></> :
        auth.isAuthenticated && tokensExist ? (
            children
            ) : (
            <Navigate
                to={{
                pathname: '/',
                state: { from: location }
                }}
            />
            )


  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ])
};
