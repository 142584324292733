import Router from '../../../../routes/router';

export const assessmentsActionsHandlers = (
    name: string,
    step: number,
    campaignId?: string
) => {
    switch (name) {
        case 'Edit campaign':
            Router.goToEvaluateCreateCampaign(campaignId, step);
            break;
        case 'Add assessment':
            Router.goToEvaluateCreateCampaign(campaignId, step);
            break;
    }
};
