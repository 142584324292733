import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Select } from '@mui/material';
import {
    AlignItems,
    Column,
    SpaceBetween,
    H1Text,
    RedButton,
    SearchField,
    Flex
} from '../../../components';
import addIcon from '../../../assets/images/icon-add.svg';
import { renderArchiveButton, renderUnArchiveButton } from '../../../utils';
import { renderModal, stakeholderStatus } from '../StakeholderManagementHelpers';
import {
    Stakeholder,
    StakeholderData,
    StakeholdersFilteredList,
    StakeholderStatusDropDownProps
} from '../types';

type StakeholderRow = {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email?: string | null;
    edit: React.ReactNode;
};

type Props = {
    stakeholderdata: StakeholderData;
    doArchive: any;
    setdoArchive: any;
    stakeholdersCheckedList: StakeholdersFilteredList[];
    setClientsModalIsOpen: any;
    setchosenField: any;
    setClientsTableData: any;
    notFilteredData: StakeholderRow[];
    selectedRow: number[];
    filterStatus: string;
    setFilterStatus: any;
    setSelectedRow: (data: number[]) => void;
    userMayEdit: any;
    setSearch: any;
    search: any;
};

const UPDATE_STAKEHOLDER_STATUS = gql`
    mutation updateStakeholderStatus($data: UpdateStakeholderStatusInput!) {
        updateStakeholderStatus(data: $data) {
            id
            firstName
            status
        }
    }
`;

const StakeholderManagementHeader: React.FC<Props> = ({
    stakeholderdata,
    doArchive,
    setdoArchive,
    setClientsModalIsOpen,
    setchosenField,
    setClientsTableData,
    notFilteredData,
    selectedRow,
    filterStatus,
    setFilterStatus,
    stakeholdersCheckedList,
    setSelectedRow,
    userMayEdit,
    setSearch,
    search
}) => {
    const [updateStakeholdersStatus] = useMutation(UPDATE_STAKEHOLDER_STATUS);
    const [isOpen, setOpen] = React.useState(false);
    const [isStakeholderDropdownChanged, setIsStakeholderDropdownChanged] = useState(false);
    const [modalId, setModalId] = useState('');

    const handleSelectStakeholderStatus = (
        event: any
    ) => {
        if (search) {
            setIsStakeholderDropdownChanged(true);
        }
        const target = event.target as HTMLInputElement;
        setFilterStatus(target.value);
        setSelectedRow([]);
    };

    const searchCallback = (params: string) => {
        setIsStakeholderDropdownChanged(false);
        if (!params) {
            setClientsTableData(notFilteredData);
            setSearch('');
            return;
        }
         setSearch(params);
        const filteredList = notFilteredData.filter(
            (stakeholder: StakeholderRow) =>
                stakeholder.jobTitle
                    .toLowerCase()
                    .indexOf(params.toLowerCase()) !== -1 ||
                stakeholder.firstName
                    .toLowerCase()
                    .indexOf(params.toLowerCase()) !== -1 ||
                stakeholder.lastName
                    .toLowerCase()
                    .indexOf(params.toLowerCase()) !== -1 ||
                stakeholder.email!
                    .toLowerCase()
                    .indexOf(params.toLowerCase()) !== -1
        );

        setClientsTableData(filteredList);
    };

    const handleToggleModal = useCallback(
        ({ open, renderModalId }: { open: boolean; renderModalId: string }) => {
            setOpen(open);
            setModalId(renderModalId);
        },
        []
    );

    useEffect(() => {
        if (stakeholderStatus.length) {
            stakeholderdata.stakeholdersByClient.filter((stakeholder: Stakeholder) => {
                if ( selectedRow.includes(stakeholder.id)) {
                    setdoArchive( stakeholder.status);
                }
            });
      }
    }, [selectedRow]);

    return (
        <HeaderWrapper>
            <SpaceBetween style={{ width: '100%' }}>
                <H1Text>Stakeholder Management</H1Text>
            </SpaceBetween>

            <SpaceBetween>
                <AlignItems>
                    <CustomSelect
                    id="skip-to-content" tabIndex={-1}
                        native
                        variant="outlined"
                        value={filterStatus}
                        onChange={(e)=>handleSelectStakeholderStatus(e)}
                        inputProps={{
                            'aria-label':'select stakeholder status'
                        }}
                    >
                        {stakeholderStatus.map(
                            (
                                status: StakeholderStatusDropDownProps,
                                index: number
                            ) => (
                                <option key={index} value={status.id}>
                                    {status.label}
                                </option>
                            )
                        )}
                    </CustomSelect>
                    {userMayEdit() && (
                        <RedButton
                            onClick={() => {
                                setClientsModalIsOpen(true);
                                setchosenField(null);
                            }}
                        >
                            <img
                                src={addIcon}
                                alt="add icon"
                                style={{ marginRight: 10 }}
                                aria-hidden={true}
                            />
                            Add stakeholders
                        </RedButton>
                    )}
                    {selectedRow.length ? (
                        <Flex>
                            {renderArchiveButton({
                                filterStatus,
                                doArchive,
                                openModal: () =>
                                    handleToggleModal({
                                        open: true,
                                        renderModalId: 'archive'
                                    })
                            })}

                            {renderUnArchiveButton({
                                filterStatus,
                                doArchive,
                                openModal: () =>
                                    handleToggleModal({
                                        open: true,
                                        renderModalId: 'active'
                                    })
                            })}
                        </Flex>
                    ) : null}
                </AlignItems>
                <SearchField
                    placeholder="Search stakeholders"
                    callback={searchCallback}
                    setSearch={setSearch}
                    isStakeholderDropdownChanged={isStakeholderDropdownChanged}
                />
            </SpaceBetween>
            {renderModal({
                modalId,
                handleClose: () => setOpen(false),
                isOpen,
                stakeholdersCheckedList,
                setSelectedRow,
                updateStakeholdersStatus
            })}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled(Column)`
    width: 100%;
    background-color: #f8f8f8;
    padding-bottom: 32px;
`;

const CustomSelect = styled(Select)`
    min-height: 48px;
    margin-right: 16px;
    .MuiOutlinedInput-input {
        padding: 10px 32px 10px 10px;
    }
`;

export default StakeholderManagementHeader;
