import { styled } from '@mui/material/styles';

const VerticalLine = styled('span')<{
    width?: string;
    background?: string;
    height?: string;
    margin?: string;
}>`
    width: ${props => (props.width ? props.width : '2px')};
    background: ${props => (props.background ? props.background : '#666')};
    height: ${props => (props.height ? props.height : '28px')};
    margin: ${props => (props.margin ? props.margin : '7px')};
`;

export default VerticalLine;
