import React, { useState } from 'react';

type Props = {
    color: string;
    backgroundColor: string;
    borderColor: string;
    selected: boolean;
};

const IconMaturity2: React.FC<Props> = ({
    color,
    backgroundColor,
    borderColor,
    selected
}) => {
    const [hovered, isHovered] = useState(false);
    return (
        <svg
            onMouseEnter={() => isHovered(true)}
            onMouseLeave={() => isHovered(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill={selected ? backgroundColor : '#fff'}
                stroke={selected ? borderColor : '#D7D8DA'}
                strokeWidth="0.5"
                d="M.25.25h39.5v39.5H.25V.25z"
            />
            <path
                fill={hovered || selected ? color : '#D9D9D9'}
                d="M20.833 20H30v9.167h-9.167zM10 9h9v20h-9zM7 31h26v1H7z"
            />
        </svg>
    );
};

export default IconMaturity2;
