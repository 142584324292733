import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import RedButton from '../base/Buttons/RedButton';

type Props = {
    title: any;
    content: any;
    open: boolean;
    handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabledSave: boolean;
    buttonCancelText?: string;
    buttonSaveText?: string;
};

const CloseCampaignModal: React.FC<Props> = ({
    title,
    content,
    open,
    handleClose,
    handleSubmit,
    buttonCancelText,
    buttonSaveText,
    disabledSave = false
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogContent
                style={{ padding: 0, overflow: 'initial' }}
                id="alert-dialog-title"
            >
                {title}
            </DialogContent>
            <DialogContent>{content}</DialogContent>
            <DialogActions style={{ padding: 26 }}>
                {buttonCancelText && (
                    <ButtonCancel onClick={handleClose} variant="outlined">
                        {buttonCancelText || 'Cancel'}
                    </ButtonCancel>
                )}
                <ButtonWithMarginLeft
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={disabledSave}
                >
                    {buttonSaveText || 'Okay'}
                </ButtonWithMarginLeft>
            </DialogActions>
        </Dialog>
    );
};

const ButtonWithMarginLeft = styled(RedButton)`
    && {
        text-transform: capitalize;
        margin-left: 20px;
        min-height: 34px;
    }
`;

const ButtonCancel = styled(Button)`
    && {
        text-transform: capitalize;
    }
`;

export default CloseCampaignModal;
