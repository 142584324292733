import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
    BoldText,
    SearchInline,
    SpaceBetween,
    Text,
    Toast
} from '../../../components';
import { styled } from '@mui/material/styles';
import {
    Checkbox,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormGroup,
    IconButton,
    Grid,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/react-hooks';
import { customModalStylesMangeMembers } from '../../../components/PopUps/constants';
import Button from '../../../components/base/Buttons/Button';
import closeIcon from '../../../assets/images/icons8-close-25.svg';
import { DUPLICATE_PERMISSION_ERROR_TOAST_MSG, dynamicSort, MANAGE_MEMBERS_MODAL_MESSAGE1, MANAGE_MEMBERS_MODAL_MESSAGE2, NO_PERMISSION_TO_DELETE_ERROR_TOAST_MSG, NO_USER_SELECTED_ERROR_TOAST_MSG, PermissionTypeEnum } from '../../../utils';
import { EntityDropdownData, PermissionLevel, PermissionObject } from '../types';
import _ from 'lodash';
import CampaignPermissionModalDialog from './CampaignPermissionModalDialog';
import { UserStore } from '../../../context/user-store';

const GET_USERS = gql`
    query campaignMembers($clientId: Int!) {
        availableCampaignMembers: usersByClient(clientId: $clientId) {
            value: id
            firstName
            lastName
            role
        }
        me {
            id
        }
    }
`;

const GET_CLIENT = gql`
    query client($clientId: Int!) {
        client: client(id: $clientId) {
            value: id
            owner {
                id
                firstName
                lastName
            }
        }
    }
`;

type Props = {
    members: number[];
    readOnlyMembers: number[];
    clientId: number;
    campaignId?: string;
    managerId?: number[];
    setIsModalOpen: CallableFunction;
    isModalOpen: boolean;
    entityGroup?: EntityDropdownData;
    permissionLevel?: PermissionLevel;
    setPermissionLevel: any;
    permissionStructureData: PermissionObject[];
    setPermissionStructureData: any
};

const CampaignPermissionsModal: React.FC<Props> = ({
    isModalOpen,
    setIsModalOpen,
    clientId,
    campaignId,
    managerId,
    members,
    readOnlyMembers,
    entityGroup,
    permissionLevel,
    setPermissionLevel,
    permissionStructureData,
    setPermissionStructureData
}) => {

    const { data: userData, loading: userDataLoading } = useQuery<any>(GET_USERS, {
        variables: {
            clientId,
        },
    });

    const { data: clientData, loading: clientDataLoading } = useQuery<any>(GET_CLIENT, {
        variables: {
            clientId,
        },
    });
    const {
        state: { client: clientOwner }
    } = useContext(UserStore);
    const clientOwnerId = clientOwner && clientOwner.owner?.id;
    const [data, setData] = useState<any>();
    const [readOnlyMembersList, setReadOnlyMembersList] = useState<any[]>([]);
    const [fullMemberList, setFullMemberList] = useState<any[]>([]);
    const [companyMemberItems, setCompanyMemberItems] = useState<any[]>([]);
    const [selectedRadioValue, setSelectedRadioValue] = useState<'Full' | 'Read-only' | ''>('Full');
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [modifiedSelectData, setModifiedSelectData] = useState<any[]>([]);
    const [isAllFullMemberSelected, setAllFullMemberSelected] = useState<boolean>(false);
    const [isReadOnlyMemberSelected, setReadOnlyMemberSelected] = useState<boolean>(false);
    const [toastMessageShow, setToastMessageShow] = useState<boolean>(false);
    const [removePermissionType, setRemovePermissionType] = useState<'Full' | 'Read-only'>('Full');
    const [actionType, setActionType] = useState<'Remove' | 'Add'>('Add');
    const [userList, setUserList] = useState<any[]>([]);
    const [toastMessage, setToastMessage] = useState<string>(DUPLICATE_PERMISSION_ERROR_TOAST_MSG);
    const [fakeCompanyList, setFakeCompanyList] = useState<any[]>([]);
    const [fakeFullList, setFakeFullList] = useState<any[]>([]);
    const [fakeReadOnlyList, setFakeReadOnlyList] = useState<any[]>([]);
    const [selectAllFullMemberChecked, setSelectAllFullMemberChecked] = useState<boolean>(false);
    const [selectAllReadOnlyMemberChecked, setSelectAllReadOnlyMemberChecked] = useState<boolean>(false);


    useEffect(() => {
        if (companyMemberItems && companyMemberItems.length) {
            setFakeCompanyList(_.cloneDeep(companyMemberItems));
        }

        if (fullMemberList && fullMemberList.length) {
            setFakeFullList(_.cloneDeep(fullMemberList));
        }

        if (readOnlyMembersList && readOnlyMembersList.length >= 0) {
            setFakeReadOnlyList(_.cloneDeep(readOnlyMembersList));
        }
    }, [companyMemberItems, fullMemberList, readOnlyMembersList]);

    useEffect(() => {
        const fullPermissionDataList : PermissionObject[] = [];
        if (members && members.length > 0) {
            members.forEach((member) => {
                if (!permissionStructureData.map(p => p.campaignMember, []).includes(member)) {
                    const permissionData: PermissionObject = {
                        level: 'Campaign',
                        campaignMember: member,
                        campaignReadOnlyMember: undefined,
                        campaignEntityId: undefined,
                        userId: undefined,
                        permission: undefined
                    };
                    fullPermissionDataList.push(permissionData);
                }
            });
        }

        if (fullPermissionDataList.length > 0){
            setPermissionStructureData(permissionStructureData.concat(fullPermissionDataList));
        }

        if (readOnlyMembers && readOnlyMembers.length > 0) {
            readOnlyMembers.forEach((member) => {
                if (!permissionStructureData.map(p => p.campaignReadOnlyMember, []).includes(member)) {
                    const permissionData: PermissionObject = {
                        level: 'Campaign',
                        campaignMember: undefined,
                        campaignReadOnlyMember: member,
                        campaignEntityId: undefined,
                        userId: undefined,
                        permission: undefined
                    };
                    setPermissionStructureData([...permissionStructureData, permissionData]);
                }
            });
        }
    }, [members, readOnlyMembers]);

    useEffect(() => {
        if (entityGroup && entityGroup.data) {
            const menuItems = entityGroup.data.flatMap((entity: any) => [
                { ...entity, value: entity.name, menuType: 'Campaign' },
                ...entity.children.flatMap((parent: any) => [
                    { ...parent, value: parent.name},
                    ...parent.campaignEntities.map((child: any) => ({
                        ...child,
                        name: child.name,
                        value: child.editKey,
                        menuType: 'Entity'
                    })),
                ]),
            ], []);

            setModifiedSelectData(menuItems);

            if (entityGroup.name) {
                setSelectedOption(entityGroup.name);
            }

            if (entityGroup.level === 'Campaign') {
                setPermissionLevel({
                    level: 'Campaign',
                    id: campaignId,
                });
            } else {
                setPermissionLevel({
                    level: 'Entity',
                    id: menuItems.find((m) => m.value === entityGroup.name),
                });
            }
        }
    }, [entityGroup]);

    useEffect(() => {
        setData({...userData, ...clientData});
    }, [userDataLoading, clientDataLoading]);

    useEffect(() => {
        if (data && data.availableCampaignMembers && data.availableCampaignMembers.length) {
            setCustomFullMemberList(members && members.map((memberId: number) => {
                const filterMember = data.availableCampaignMembers.find((member: any) => member.value === memberId);
                return {
                    ...filterMember || {},
                    checked: false,
                    disable: filterMember ? ( managerId?.map((id) => id).includes(filterMember.value) || filterMember.value === clientOwnerId) : false
                };
            }, []));

            setCustomReadOnlyMemberList(readOnlyMembers && readOnlyMembers.map((memberId: number) => {
                    const filterMember = data.availableCampaignMembers.find((member: any) => member.value === memberId);
                    return {
                        ...filterMember || {},
                        checked: false,
                        disable: filterMember ? ( managerId?.map((id) => id).includes(filterMember.value) || filterMember.value === clientOwnerId) : false
                    };
            }, []));

            setCompanyMemberItems(data.availableCampaignMembers && data.availableCampaignMembers.map((member: any) => {
                return {
                    ...member || {},
                    checked: false,
                    disable: member ? ( managerId?.map((id) => id).includes(member.value) || member.value === clientOwnerId) : false
                };
            }, []));
        }
    }, [data]);

    useEffect(() => {
        if (selectedRadioValue !== '') {
            findIMemberAndDisable();
        }
    }, [fullMemberList, readOnlyMembersList]);

    useEffect(() => {
        if (permissionLevel && permissionLevel.level && permissionStructureData && permissionStructureData.length) {
            findIMemberAndDisable();
            findMemberAndRerenderList();
        }
    }, [permissionStructureData, permissionLevel, entityGroup]);

    const addSelectedMembersPermission = () => {
        setToastMessageShow(false);
        setToastMessage(DUPLICATE_PERMISSION_ERROR_TOAST_MSG);
        const filteredItems = companyMemberItems.filter((item: any) => {
            if (item.checked) {
                return { ...item, checked: !item.checked };
            }
        });

        if (permissionLevel && permissionLevel.level.toLowerCase() === 'campaign') {
            const isExistMembers = permissionStructureData.filter((m) => {
                return filteredItems.map((m) => (m.value)).includes(m.userId);
            });

            _.remove(permissionStructureData, (p) => {
                return isExistMembers.includes(p);
            });
        }

        if (filteredItems && filteredItems.length > 0 && permissionLevel) {
            const permissionData: PermissionObject[] = [];
            filteredItems.forEach((filteredData) => {
                const permissionObj: PermissionObject = {
                    level: permissionLevel.level,
                    campaignMember: permissionLevel.level.toLowerCase() === 'campaign' && selectedRadioValue === 'Full' ? filteredData.value : undefined,
                    campaignReadOnlyMember: permissionLevel.level.toLowerCase() === 'campaign' && selectedRadioValue === 'Read-only' ? filteredData.value : undefined,
                    campaignEntityId: permissionLevel.level.toLowerCase() === 'entity' ? selectedOption : undefined,
                    userId: permissionLevel.level.toLowerCase() === 'entity' ? filteredData.value : undefined,
                    permission: permissionLevel.level.toLowerCase() === 'entity' ? ( selectedRadioValue === 'Full' ? PermissionTypeEnum.FULL_MEMBER_ACCESS : PermissionTypeEnum.READ_ONLY_ACCESS) : undefined
                };
                if (permissionStructureData.find(p => p === permissionObj)) {
                    setToastMessageShow(true);
                    return;
                } else {
                    permissionData.push(permissionObj);
                }
            });
            permissionStructureData.find(permission => {

            });
            setPermissionStructureData([...permissionStructureData, ...permissionData]);
        }
    };

    const removeSelectedMembersPermission = (removeSelectedMemberType: 'Full' | 'Read-only') => {
        setToastMessageShow(false);
        if (removeSelectedMemberType === 'Full') {
            const filteredMembers = fullMemberList.filter((item: any) => {
                if (item.checked) {
                    return { ...item, checked: !item.checked };
                }
            });

            if (filteredMembers.map(m => m.value).includes(clientOwnerId) || managerId?.some(id => filteredMembers.map(m => m.value).includes(id))){
                setToastMessage(NO_PERMISSION_TO_DELETE_ERROR_TOAST_MSG);
                setToastMessageShow(true);
            }


            if (filteredMembers && filteredMembers.length > 0 && permissionLevel && permissionLevel.level === 'Campaign') {
                const membersToBeDeleted = permissionStructureData.filter((permission) => {
                    return filteredMembers.map((member) => member.value, []).includes(permission.campaignMember) && permission.level === 'Campaign';
                });
                permissionsDelete(membersToBeDeleted);
            }

            if (filteredMembers && filteredMembers.length > 0 && permissionLevel && permissionLevel.level === 'Entity') {
                const membersToBeDeleted = permissionStructureData.filter((permission) => {
                    return filteredMembers.map((member) => member.value, []).includes(permission.userId) &&
                            permission.level === 'Entity' &&
                            permission.campaignEntityId === selectedOption &&
                            permission.permission === PermissionTypeEnum.FULL_MEMBER_ACCESS;
                });
                permissionsDelete(membersToBeDeleted);
            }

        } else if (removeSelectedMemberType === 'Read-only') {
            const filteredMembers = readOnlyMembersList.filter((item: any) => {
                if (item.checked) {
                    return { ...item, checked: !item.checked };
                }
            });

            if (filteredMembers.map(m => m.value).includes(clientOwnerId) || managerId?.some(id => filteredMembers.map(m => m.value).includes(id))) {
                setToastMessage(NO_PERMISSION_TO_DELETE_ERROR_TOAST_MSG);
                setToastMessageShow(true);
            }

            if (filteredMembers && filteredMembers.length > 0 && permissionLevel && permissionLevel.level === 'Campaign') {
                const membersToBeDeleted = permissionStructureData.filter((permission) => {
                    return filteredMembers.map((member) => member.value, []).includes(permission.campaignReadOnlyMember) && permission.level === 'Campaign';
                });
                permissionsDelete(membersToBeDeleted);
            }

            if (filteredMembers && filteredMembers.length > 0 && permissionLevel && permissionLevel.level === 'Entity') {
                const membersToBeDeleted = permissionStructureData.filter((permission) => {
                    return filteredMembers.map((member) => member.value, []).includes(permission.userId) &&
                            permission.level === 'Entity' &&
                            permission.campaignEntityId === selectedOption &&
                            permission.permission === PermissionTypeEnum.READ_ONLY_ACCESS;
                });
                permissionsDelete(membersToBeDeleted);
            }
        }
    };

    const permissionsDelete = (membersToBeDeleted: PermissionObject[]) => {
        const newPermissions = _.clone(permissionStructureData);
        membersToBeDeleted.forEach(member => {
            if(newPermissions.includes(member)) {
                const indexOfPermission = newPermissions.findIndex(oldMember => oldMember === member);
                newPermissions.splice(indexOfPermission, 1);
            }
        });
        setPermissionStructureData(newPermissions);
    };

    const findIMemberAndDisable = () => {
        let permissions: PermissionObject[] = [];

        if (permissionLevel && permissionLevel.level === 'Entity') {
            permissions = permissionStructureData.filter((permission) => {
                return permission.level === 'Entity' && permission.campaignEntityId === selectedOption;
            });
        } else if (permissionLevel && permissionLevel.level === 'Campaign') {
            permissions = permissionStructureData.filter((permission) => {
                return permission.level === 'Campaign';
            });
        }

        const dataTemp = companyMemberItems.map((member) => {
            member.checked = false;
            member.disable = managerId?.map(id => id).includes(member.value) ||  member.value === clientOwnerId || readOnlyMembers.includes(member.value) || members.includes(member.value);
            if ((permissions && permissions.length > 0) &&
                (permissionLevel && permissionLevel.level === 'Campaign') &&
                (permissions.map(p => p.campaignMember, []).includes(member.value) ||
                    permissions.map(p => p.campaignReadOnlyMember, []).includes(member.value))) {
                    member.disable = true;
            }
            if ((permissions && permissions.length > 0) &&
                (permissionLevel && permissionLevel.level === 'Entity') &&
                (permissions.map(p => p.campaignEntityId, []).includes(selectedOption)) &&
                (permissions.map(p => p.userId, []).includes(member.value))) {
                    member.disable = true;
            }

            return member;
        });
        setCompanyMemberItems(dataTemp);
    };

    const findMemberAndRerenderList = () => {
        const fullMemberPermissions = permissionStructureData.filter((permission) => {
            if (permissionLevel && permissionLevel.level === 'Campaign') {
                return permission.level === 'Campaign' &&
                        companyMemberItems.map((m) => m.value).includes(permission.campaignMember);
            } else if (permissionLevel && permissionLevel.level === 'Entity') {
                return permission.level === 'Entity' &&
                        permission.campaignEntityId === selectedOption &&
                        permission.permission === PermissionTypeEnum.FULL_MEMBER_ACCESS &&
                        companyMemberItems.map((m) => m.value).includes(permission.userId);
            }
        }, []);

        const filteredFullMumber = _.cloneDeep(companyMemberItems).filter((member) => {
            if (permissionLevel && permissionLevel.level === 'Campaign') {
                return fullMemberPermissions.map(p => p.campaignMember).includes(member.value);
            } else if (permissionLevel && permissionLevel.level === 'Entity') {
                return fullMemberPermissions.map(p => p.userId).includes(member.value);
            }
        }, []).map((member) => {
            member.checked = false;
            member.disable = managerId?.map(id => id).includes(member.value) || member.value === clientOwnerId;
            return member;
        });
        setCustomFullMemberList(filteredFullMumber);

        const readOnlyMemberPermissions = permissionStructureData.filter((permission) => {
            if (permissionLevel && permissionLevel.level === 'Campaign') {
                return permission.level === 'Campaign' &&
                        companyMemberItems.map((m) => m.value).includes(permission.campaignReadOnlyMember);
            } else if (permissionLevel && permissionLevel.level === 'Entity') {
                return permission.level === 'Entity' &&
                        permission.campaignEntityId === selectedOption &&
                        permission.permission === PermissionTypeEnum.READ_ONLY_ACCESS &&
                        companyMemberItems.map((m) => m.value).includes(permission.userId);
            }
        }, []);

        const filteredReadOnlyMember = _.cloneDeep(companyMemberItems).filter((member) => {
            if (permissionLevel && permissionLevel.level === 'Campaign') {
                return readOnlyMemberPermissions.map(p => p.campaignReadOnlyMember).includes(member.value);
            } else if (permissionLevel && permissionLevel.level === 'Entity') {
                return readOnlyMemberPermissions.map(p => p.userId).includes(member.value);
            }
        }, []).map((member) => {
            member.checked = false;
            member.disable = managerId?.map(id => id).includes(member.value) || member.value === clientOwnerId;
            return member;
        });
        setCustomReadOnlyMemberList(filteredReadOnlyMember);
    };

    const handleChangeDropdown = (event: any) => {
        setSelectedOption(event.target.value);
        const getMenueItem = modifiedSelectData.find(m => (m.value === event.target.value));
        if (getMenueItem.menuType === 'Campaign') {
            setPermissionLevel({
                level: 'Campaign',
                id: campaignId,
            });
        } else {
            setPermissionLevel({
                level: 'Entity',
                id: event.target.value,
            });
        }
    };

    const searchCallback = (params: string) => {
        const filterData = _.filter(companyMemberItems, (m) => {
            return `${m.firstName} ${m.lastName}`.toLowerCase().indexOf(params.toLowerCase()) > -1;
        });

        setFakeCompanyList(params && params.length ? filterData : companyMemberItems);
    };

    const searchCallbackMembers = (params: string) => {
        const filterData = _.filter(fullMemberList, (m) => {
            return `${m.firstName} ${m.lastName}`.toLowerCase().indexOf(params.toLowerCase()) > -1;
        });

        setFakeFullList(params && params.length ? filterData : fullMemberList);
    };

    const searchCallbackReadOnlyMembers = (params: string) => {
        const filterData = _.filter(readOnlyMembersList, (m) => {
            return `${m.firstName} ${m.lastName}`.toLowerCase().indexOf(params.toLowerCase()) > -1;
        });

        setFakeReadOnlyList(params && params.length ? filterData : readOnlyMembersList);
    };

    const setCustomFullMemberList = (data: any[]) => {
        if (permissionLevel && permissionLevel.level === 'Entity') {
            const campaignMembersFromPermissionList = permissionStructureData.filter((p) => {
                return p.level === 'Campaign' && p.campaignMember !== undefined;
            }, []).map(p => p.campaignMember);

            const campaignFullMember = companyMemberItems.filter(member => {
                return campaignMembersFromPermissionList.includes(member.value);
            }, []).map(member => ({
                ...member,
                disable: true
            }), []);

            const updatetdList = companyMemberItems.filter(member => {
                return managerId?.includes(member.value);
            }, []).map(member => ({
                ...member,
                disable: true
            }), []);

            const tempList = [...campaignFullMember, ...data, ...updatetdList].filter((memberList1,index,self)=>
                 index === self.findIndex((memberList2)=> memberList2.value === memberList1.value));

            setFullMemberList(tempList);
        } else {
            const updatetdList = companyMemberItems.filter(member => {
                return managerId?.includes(member.value);
            }, []).map(member => ({
                ...member,
                disable: true
            }), []);

            const tempList = [...data, ...updatetdList].filter((memberList1,index,self)=>
                index === self.findIndex((memberList2)=> memberList2.value === memberList1.value));
            setFullMemberList(tempList);
        }

    };

    const setCustomReadOnlyMemberList = (data: any[]) => {
        if (permissionLevel && permissionLevel.level === 'Entity') {
            const campaignMembersFromPermissionList = permissionStructureData.filter((p) => {
                return p.level === 'Campaign' && p.campaignReadOnlyMember !== undefined;
            }, []).map(p => p.campaignReadOnlyMember);

            const campaignReadOnlyMember = companyMemberItems.filter(member => {
                return campaignMembersFromPermissionList.includes(member.value);
            }, []).map(member => ({
                ...member,
                disable: true
            }), []);

            const tempList = [...campaignReadOnlyMember, ...data].filter((memberList1,index,self)=>
            index === self.findIndex((memberList2)=> memberList2.value === memberList1.value));

            setReadOnlyMembersList(tempList);
        } else {
            setReadOnlyMembersList(data);
        }
    };

    const handleDone = () => {
        setSelectedRadioValue('');
        setSelectedOption('');
        setIsModalOpen(false);
    };

    const openActionDialog = (actionType: 'Remove' | 'Add', isOpenDialog: boolean, userList: any[], removePermissionType?: 'Full' | 'Read-only') => {
        setToastMessageShow(false);
        const filteredMembersForAction = userList.filter((member) => {
            if (member.checked) {
                return { ...member, checked: !member.checked };
            }
        }, []);
        if (filteredMembersForAction.length) {
            setActionType(actionType);
            setUserList(filteredMembersForAction);
            setIsOpenDialog(isOpenDialog);
            if (removePermissionType) {
                setRemovePermissionType(removePermissionType);
            }
        } else {
            setToastMessage(NO_USER_SELECTED_ERROR_TOAST_MSG);
            setToastMessageShow(true);
        }
    };
    function renderMemberName(member: any){
        const{firstName, lastName, value} = member;
        const prefix = (managerId?.map(id => id).includes(value) && value === clientOwnerId)
            ? '| Campaign Manager'
            : managerId?.map(id => id).includes(value)
                ? '| Campaign Manager'
                : value === clientOwnerId
                    ? '| Relationship Manager'
                    : '';
        return `${firstName} ${lastName} ${prefix}`;
     }

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
                style={customModalStylesMangeMembers}
                onRequestClose={handleDone}>
                <Header>
                    <BoldText color="white">Manage members</BoldText>
                    <IconButton
                        onClick={handleDone}
                        aria-label={'close button'}
                        style={{
                            right: '24px',
                            position: 'absolute',
                            color: 'white',
                        }}
                    >
                        <img src={closeIcon}  alt="close" aria-hidden={true} />
                    </IconButton>
                </Header>

                <Constainer style={{ flexGrow: '1', display: 'flex', flexDirection: 'column'}}>
                    <ModalInfoText>{MANAGE_MEMBERS_MODAL_MESSAGE1}</ModalInfoText>
                    <ModalInfoText>{MANAGE_MEMBERS_MODAL_MESSAGE2}</ModalInfoText>

                    <Grid container spacing={2} style={{ backgroundColor: 'white', marginTop: '24px', flexGrow: '1' }}>
                        <Grid xs={8} item={true} style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
                            <FormControl fullWidth>
                                <Select
                                    id="select"
                                    value={selectedOption}
                                    onChange={ (event: any) => handleChangeDropdown(event)}>
                                    { modifiedSelectData && modifiedSelectData.map(
                                        (entity: any, key: number) => (
                                            <MenuItem
                                                key={`${entity.id}-campaign-member-modal-menu-${key}`}
                                                value={entity.value}
                                                disabled={ entity.type && entity.type === 'group' || entity.archivedDate }
                                                style={ entity.type === 'group' ? {
                                                        paddingLeft: '18px',
                                                    } : entity.type === 'parent' ? {
                                                        paddingLeft:  '4px',
                                                    } : {
                                                        paddingLeft: '38px',
                                                    }
                                                }>
                                                {entity.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>

                            <Grid container spacing={2} style={{ flexGrow: '1' }}>
                                <Grid xs={6} item={true}>
                                    <TableColumnInner style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <div style={{ padding: '10px' }}>
                                            <Label>Full members</Label>
                                        </div>
                                        <List
                                            style={{
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                flexGrow: '1'
                                            }}>
                                            <SearchInline
                                                placeholder="Search"
                                                callback={(value: string) =>
                                                    searchCallbackMembers(
                                                        value
                                                    )
                                                }
                                            />
                                            <MemberListBox>
                                                <ListTag key="select-all">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={fullMemberList.length> 0 ?  selectAllFullMemberChecked : false}
                                                                onChange={() => {
                                                                    setCustomFullMemberList(fullMemberList.map((memberData: any) => {
                                                                        if(!memberData.disable && memberData.value != clientOwnerId)
                                                                          memberData.checked = !isAllFullMemberSelected;
                                                                        return memberData;
                                                                    }));
                                                                    setAllFullMemberSelected(!isAllFullMemberSelected);
                                                                    setSelectAllFullMemberChecked(!selectAllFullMemberChecked);
                                                                }}
                                                            />
                                                        }
                                                        label="Select All"
                                                    />
                                                </ListTag>
                                                {fakeFullList && fakeFullList.sort(dynamicSort('firstName')).map((member: any, key: number) =>
                                                    <ListTag key={`${member.id ? member.id : member.value}-${key}-full-member-list`}>
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={member.checked}
                                                            onChange={ () => {

                                                                setCustomFullMemberList(fullMemberList.map((memberData: any) => {
                                                                    if (memberData.value === member.value) {
                                                                        memberData.checked = !memberData.checked;
                                                                    }
                                                                    return memberData;
                                                                }));
                                                            }}
                                                            disabled={ member && member.disable}
                                                        />
                                                        <ListItemText
                                                            style={(member && member.disable) || (managerId?.includes(member.value)) ? { color: '#878787' } : {}}
                                                            primary={renderMemberName(member)}
                                                        />
                                                    </ListTag>
                                                )}
                                            </MemberListBox>
                                        </List>
                                        <CustomButton
                                            style={{
                                                margin: '12px 0px'
                                            }}
                                            onClick={() => {
                                                openActionDialog('Remove', true, fullMemberList, 'Full');
                                            }}>
                                            <ButtonText
                                                color="#de3328">
                                                Remove Selected
                                            </ButtonText>
                                        </CustomButton>
                                    </TableColumnInner>
                                </Grid>
                                <Grid xs={6} item={true}>
                                    <TableColumnInner style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <div style={{ padding: '10px' }}>
                                            <Label>Read-only members</Label>
                                        </div>
                                        <List
                                            style={{
                                                border: '1px solid black',
                                                borderRadius: '4px',
                                                flexGrow: '1'
                                            }}>
                                            <SearchInline
                                                placeholder="Search"
                                                callback={(value: string) =>
                                                    searchCallbackReadOnlyMembers(
                                                        value
                                                    )
                                                }
                                            />
                                            <MemberListBox>
                                                <ListTag key="select-all">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={readOnlyMembersList.length > 0 ? selectAllReadOnlyMemberChecked
                                                                    : false}
                                                                onChange={()=>{
                                                                    setCustomReadOnlyMemberList(readOnlyMembersList.map((memberData: any) => {
                                                                        if(!memberData.disable)
                                                                           memberData.checked = !isReadOnlyMemberSelected;
                                                                        return memberData;
                                                                    }));
                                                                    setReadOnlyMemberSelected(!isReadOnlyMemberSelected);
                                                                    setSelectAllReadOnlyMemberChecked(!selectAllReadOnlyMemberChecked);
                                                                }}
                                                            />
                                                        }
                                                        label="Select All"
                                                    />
                                                </ListTag>
                                                {fakeReadOnlyList && fakeReadOnlyList.sort(dynamicSort('firstName')).map((member: any, key: number) =>
                                                    <ListTag key={`${member.value}-${key}-read-only-member-list`}>
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={member.checked}
                                                            onChange={()=> {
                                                                setCustomReadOnlyMemberList(readOnlyMembersList.map((memberData: any) => {
                                                                    if (memberData.value === member.value) {
                                                                        memberData.checked = !memberData.checked;
                                                                    }
                                                                    return memberData;
                                                                }));
                                                            }}
                                                            disabled={ member && member.disable ? true : false }
                                                        />
                                                        <ListItemText
                                                            style={member && member.disable ? { color: '#878787' } : {}}
                                                            primary={`${member.firstName} ${member.lastName}`}
                                                        />
                                                    </ListTag>
                                                )}
                                            </MemberListBox>
                                        </List>
                                        <CustomButton
                                            style={{
                                                margin: '12px 0px'
                                            }}
                                            onClick={() => {
                                                openActionDialog('Remove', true, readOnlyMembersList, 'Read-only');
                                            }}>
                                            <ButtonText
                                                color="#de3328">
                                                Remove Selected
                                            </ButtonText>
                                        </CustomButton>
                                    </TableColumnInner>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={4} item={true} style={{ padding: '20px' }}>
                            <TableColumnInner style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', padding: '0px' }}>
                                <List
                                    style={{
                                        border: '1px solid black',
                                        borderRadius: '4px',
                                        fontSize: '10px',
                                        flexGrow: '1',
                                    }}>
                                    <div style={{ padding: '10px' }}>
                                        <Label>Company members</Label>
                                    </div>
                                    <SearchInline
                                        placeholder="Search"
                                        callback={(value: string) =>
                                            searchCallback(value)
                                        }
                                    />
                                    <MemberListBox style={{fontSize: '14px' }}>
                                        {fakeCompanyList &&
                                            fakeCompanyList.sort(dynamicSort('firstName')).map(
                                                (member: any, key: number) => (
                                                    <ListTag key={`${member.value}-${key}-company-member-list`}>
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={
                                                                member.checked
                                                            }
                                                            onChange={() => {
                                                                setCompanyMemberItems((prevs: any) =>
                                                                    prevs.map((prevsItem: any) =>
                                                                    prevsItem.value === member.value ? { ...prevsItem, checked: !prevsItem.checked } : prevsItem
                                                                ));
                                                            }}
                                                            disabled={ member && member.disable ? true : false }
                                                        />
                                                        <ListItemText
                                                            style={member && member.disable ? { color: '#878787' } : {}}
                                                            primary={renderMemberName(member)}
                                                        />
                                                    </ListTag>
                                                )
                                            )}
                                    </MemberListBox>
                                </List>
                                <RadioGroup
                                    aria-label="options"
                                    name="options"
                                    value={selectedRadioValue}
                                    onChange={(event: any)=> setSelectedRadioValue(event.target.value)}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            value="Full"
                                            control={<Radio />}
                                            label="Full"
                                        />
                                        <FormControlLabel
                                            value="Read-only"
                                            control={<Radio />}
                                            label="Read-only"
                                        />
                                    </FormGroup>
                                </RadioGroup>
                                <CustomButton
                                    style={{
                                        margin: '12px 0px'
                                    }}
                                    disabled={!selectedRadioValue}
                                    onClick={()=>{
                                        openActionDialog('Add', true, companyMemberItems);
                                    }}>
                                    <ButtonText
                                        color="#de3328">
                                        Add Selected
                                    </ButtonText>
                                </CustomButton>
                            </TableColumnInner>
                        </Grid>
                    </Grid>
                </Constainer>
            </Modal>

            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={toastMessageShow}
                autoHideDuration={4000}
                icon='error'
                title={toastMessage}
                closeToast={setToastMessageShow}
            />

            <CampaignPermissionModalDialog
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
                addSelectedMembersPermission={addSelectedMembersPermission}
                removeSelectedMembersPermission={removeSelectedMembersPermission}
                actionType={actionType}
                removePermissionType={removePermissionType}
                userList={userList}
                modifiedSelectData={modifiedSelectData}
                permissionStructureData={permissionStructureData}
                selectedRadioValue={selectedRadioValue}
                setCompanyMemberItems={setCompanyMemberItems}
            />
        </>
    );
};

const ModalInfoText = styled('p')`
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 14px;
    font-weight: bold;
    color: #2d2d2d;
    margin: 8px;
`;

const Header = styled(SpaceBetween)`
    align-items: center;
    background-color: #454545;
    border-bottom: 1px solid #cdcdcd;
    padding: 24px;
    color: white;
`;

const Constainer = styled('div')`
    background-color: #f3f3f3;
    padding: 14px 24px;
`;

const CustomButton = styled(Button)`
    && {
        border: solid 1px #de3328;
        padding: 0 21px 0 38px;
        margin-left: 10px;
        min-height: 48px;
        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            opacity: 0.9;
        }
    }
`;

const ListTag = styled(ListItem)`
    .MuiTypography-root {
        font-size: 14px;
        line-height: 1rem;
    }
    .MuiListItem-root {
        padding: 0px 0px 0px 5px;
    }
    padding: 0px 0px 0px 5px;
`;

const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
`;

const Label = styled(Text)`
    text-align: left;
    margin-bottom: 10px;
    font-size: 16px;
`;

const TableColumnInner = styled('div')`
    font-size: 14px;
`;

const MemberListBox = styled('div')`
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: auto;
    max-height: 330px;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
      }
`;

export default CampaignPermissionsModal;
