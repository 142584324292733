import React from 'react';
import ComparisonCampaigns from './components/tabs/ComparisonCampaigns/ComparisonCampaigns';
import ComparisonAssessmentsOneCampaign from './components/tabs/ComparisonAssessmentsOneCampaign/ComparisonAssessmentsOneCampaign';
import ComparisonAssessmentsDifferentCampaigns from './components/tabs/ComparisonAssessmentsDifferentCampaigns/ComparisonAssessmentsDifferentCampaigns';
import { CampaignByClient } from './types';


export const renderContent = ({
    activeTab,
    data
}: {
    activeTab: number;
    data?: CampaignByClient;
}) => {
    const contentList = {
        [`${1}`]: <ComparisonCampaigns data={data} />,
        [`${2}`]: <ComparisonAssessmentsOneCampaign />,
        [`${3}`]: <ComparisonAssessmentsDifferentCampaigns />
    };
    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return contentList[activeTab] || contentList[0];
};
