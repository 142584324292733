import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Campaign, CampaignTableRows, CampaignFilteredList } from './types';

import Text from './../../components/base/Text/Text';
import EditButton from './../../components/base/Buttons/EditButton';
import LastUpdated from './../../components/LastUpdated/LastUpdated';
import LinearProgressWithText from './../../components/LinearProgress/LinearProgressWithText';
import NavLink from './../../components/base/NavLink/NavLink';
import ArrowTooltip from './../../components/Tooltips/ArrowTooltip/ArrowTooltip';
import JustifyContentCenter from './../../components/base/Layout/JustifyContentCenter';

import Router from '../../routes/router';
import mdi_lock_black from '../../assets/images/mdi_lock_black.svg';
import mdi_lock_red from '../../assets/images/mdi_lock_red.svg';
import { getLastUpdatedDateDifference, isReadOnlyEnabled, roundFloor } from '../../utils';
import { AssessmentDomain, Capability } from './AssessmentsMaturityOverview/types';
import { UserStore } from '../../context/user-store';

export const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Campaign Title'
    },
    {
        id: 'framework',
        numeric: false,
        disablePadding: false,
        label: 'Framework'
    },
    {
        id: 'scored',
        numeric: true,
        disablePadding: false,
        label: 'Scored'
    },
    {
        id: 'capCovered',
        numeric: true,
        disablePadding: false,
        label: 'Moderated'
    },
    {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'Last Updated'
    },
    {
        id: 'progress',
        numeric: true,
        disablePadding: false,
        label: 'Status'
    },
    {
        id: 'edit',
        numeric: true,
        disablePadding: false,
        label: 'Edit'
    }
];

export const useFilteredCampaigns = (
    data: Campaign[],
    filterStatus: string
) => {
    const {
        state: {user, client,globalSettings }
    } = useContext(UserStore);
    const [filteredCampaigns, setFilteredCampaigns] = useState<
        CampaignTableRows[]
    >([]);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    useEffect(() => {
        if (data.length) {
            const campaignFilteredList = data
                .filter((campaign: Campaign) =>
                    !(!readOnlyEnabled&&campaign.readOnly)&&
                        (filterStatus === 'ALL'
                        ? campaign
                        : campaign.status === filterStatus)
                )
                .map((filteredCampaign: Campaign) => {
                    const assessmentMemberAccess = data.some((userData: any) => {
                        const matchingCampaign = filteredCampaign.id === userData.id;
                        return matchingCampaign && userData.manager.id !== user.id && user.id !== client.owner?.id ;
                      });
                    return {
                        name: (
                            <NavLink
                                to={`/main/evaluate/maturity/${filteredCampaign.id}`}
                                reloadDocument={true}
                            >
                                {filteredCampaign.title}
                            </NavLink>
                        ),
                        framework:
                            filteredCampaign.framework &&
                            filteredCampaign.framework.definition &&
                            filteredCampaign.framework.definition.name,
                        scored: `${filteredCampaign.capabilitiesScoredCnt}/${filteredCampaign.capabilitiesCnt}`,
                        capCovered: `${filteredCampaign.capabilitiesModeratedCnt}/${filteredCampaign.capabilitiesCnt}`,
                        lastUpdated: (
                            <LastUpdated
                                updatedOn={getLastUpdatedDateDifference({
                                    currentDate: filteredCampaign.updatedAt,
                                })}
                            />
                        ),
                        progress:
                            filteredCampaign.status === 'CLOSED' ||
                            filteredCampaign.status === 'ARCHIVED' ? (
                                <TextCapitalize>
                                    {filteredCampaign.status.toLowerCase()}
                                </TextCapitalize>
                            ) : (
                                <LinearProgressWithText
                                    progress={filteredCampaign.progress}
                                />
                            ),
                        edit:
                            filteredCampaign.closedAt !== null ||
                            filteredCampaign.readOnly ||
                            assessmentMemberAccess ? (
                                <JustifyContentCenter>
                                    <ArrowTooltip
                                        top={-4}
                                        // interactive
                                        minWidth="280px"
                                        maxWidth="300px"
                                        background="#2f2f2f"
                                        title={
                                            <div
                                                style={{
                                                    backgroundColor: '#2f2f2f',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Text size="14px" color="#fff">
                                                    {assessmentMemberAccess && !filteredCampaign.readOnly
                                                        ? 'You have only assessment-access to this campaign'
                                                        : filteredCampaign.readOnly
                                                        ? 'You have read-only access to this campaign.'
                                                        : 'This campaign is closed - view only access'}
                                                </Text>
                                            </div>
                                        }
                                        placement="bottom"
                                    >
                                        <CampaignCloseImageWrapper>
                                            <CampaignCloseImage />
                                        </CampaignCloseImageWrapper>
                                    </ArrowTooltip>
                                </JustifyContentCenter>
                            ) : (
                                <EditButton
                                    callback={() => {
                                        Router.goToEvaluateCreateCampaign(
                                            filteredCampaign.id
                                        );
                                    }}
                                />
                            ),
                        id: filteredCampaign.id,
                        isReadOnly: filteredCampaign.readOnly,
                    };
                });
            setFilteredCampaigns(campaignFilteredList);
        }
    }, [data, filterStatus]);
    return [filteredCampaigns];
};

const TextCapitalize = styled(Text)`
    text-transform: capitalize;
`;

const CampaignCloseImageWrapper = styled(JustifyContentCenter)`
    width: 32px;
    height: 32px;
`;

const CampaignCloseImage = styled('div')`
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url('${mdi_lock_black}') 100% 100% no-repeat;
    display: inline-block;
    &:hover {
        background-image: url(${mdi_lock_red});
    }
`;

export const useCampaignStatus = (data: Campaign[], selectedRow: string[]) => {
    const [campaignsList, setCampaignsList] = useState<CampaignFilteredList[]>(
        []
    );
    useEffect(() => {
        if (data.length) {
            const filteredList = data
                .filter((item: Campaign) => selectedRow.includes(item.id))
                .map((item: Campaign) => {
                    return {
                        id: item.id,
                        status: item.status,
                        title: item.title
                    };
                });
            setCampaignsList(filteredList);
        }
    }, [data, selectedRow]);
    return [campaignsList];
};

export const statusResolver = (value: string) => {
    if (!value) {
        return '';
    }
    if (value === 'SCORED') {
        return 'S';
    }
    if (value === 'MODERATED') {
        return 'M';
    }
    if (value === 'REVIEW_REQUESTED') {
        return 'R';
    }
    if (value === 'AMENDS_REQUESTED') {
        return 'AR';
    }
    return null;
};

export const textColor = (
    theme: string,
    score: number | null,
    status: string
) => {
    if (
        status === 'NOT_STARTED' ||
        status === 'IN_PROGRESS' ||
        status === 'NOT_SCORED' ||
        !status
    ) {
        return '#575757';
    }
    return `${
        theme !== 'blue' ? (score !== null ? '#fff' : '#575757') : '#575757'
    }`;
};

export const getAverageScoreByDomain=(domain: AssessmentDomain, pointOneDecimalEnable = false): number=>{
    if (domain.moderatedAverageScore) {
        return  getScoreFloorByDomainWithMinimum(domain.moderatedAverageScore, pointOneDecimalEnable);
    }
    if (domain.averageScore){
        return getScoreFloorByDomainWithMinimum(domain.averageScore, pointOneDecimalEnable);
    }
    return 0;
};

export const getAverageScoreByCapability=(capability: Capability, pointOneDecimalEnable = false): number=>{
    if (capability.moderatedScore != null)     {
        return  getScoreFloorByDomainWithMinimum(capability.moderatedScore, pointOneDecimalEnable);
    }
    if (capability.averageScore){
        return getScoreFloorByDomainWithMinimum(capability.averageScore, pointOneDecimalEnable);
    }
    return 0;
};

export const getScoreFloorByDomainWithMinimum=(score: number, pointOneDecimalEnable = false): number=> {
    return score !== 0 ? roundFloor(score, pointOneDecimalEnable): 0;
};

// Returns true if there is a domain within which all the capabilities are descoped.
export const isAnyDescopedDomain = (data: any) =>
    data.some((group: any) =>
        group.assessments.some((assessment: any) =>
            assessment.state.domains.some((domain: any) =>
                (domain.capabilities.reduce((prev: any, current: any) =>
                    (current.inScope)? prev : prev-=1, domain.capabilities.length
                ) == 0) ? true : false
)));
