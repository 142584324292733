import { TabType, ratingsType } from './types';
import iconThreatTabPassive from '../../../assets/images/icon-tab-threat-passive.svg';
import iconThreatTabActive from '../../../assets/images/icon-tab-threat-active.svg';
import iconRiskActive from '../../../assets/images/icon-tab-risk-active.svg';
import iconRiskPassive from '../../../assets/images/icon-tab-risk-passive.svg';

export const addRiskModalWrapper = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        height: '90%',
        left: '50%',
        padding: 0,
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
};

export const threatAndRiskTabList: TabType[] = [
    {
        id: 1,
        label: 'Threat scenarios',
        iconActive: iconThreatTabActive,
        iconDisable: iconThreatTabPassive
    },
    {
        id: 2,
        label: 'Risk dashboard',
        iconActive: iconRiskActive,
        iconDisable: iconRiskPassive
    }
];

export const squareOutlineIcon = '<div style="border: solid 1px #ffffff;height: 24px;width: 24px;"/>';

export const riskRating: ratingsType[] = [
    {
        title: 'Very High',
        background: '#000000'
    },
    {
        title: 'High',
        background: '#b73349'
    },
    {
        title: 'Medium',
        background: '#ea8b24'
    },
    {
        title: 'Low',
        background: '#76b539'
    },
    {
        title: 'Very low',
        background: '#0b6552'
    }
];

export const residualRiskRating: ratingsType[] = [
    {
        title: 'Very High',
        background: '#000000'
    },
    {
        title: 'High',
        background: '#b73349'
    },
    {
        title: 'Medium',
        background: '#ea8b24'
    },
    {
        title: 'Low',
        background: '#76b539'
    },
    {
        title: 'Pending',
        background: '#909b9e'
    }
];

export const riskRatingTwo: ratingsType[] = [
    {
        title: 'Catastrophic',
        background: '#000000'
    },
    {
        title: 'Critical',
        background: '#b73349'
    },
    {
        title: 'Moderate',
        background: '#ea8b24'
    },
    {
        title: 'Minimal',
        background: '#76b539'
    },
    {
        title: 'Insignificant',
        background: '#0b6552'
    }
];

export const threatRating: ratingsType[] = [
    {
        title: 'Critical',
        background: '#000000',
        containerHeight: '0.2',
        legendIcon: squareOutlineIcon
    },
    {
        title: 'Weak',
        background: '#b73349',
        containerHeight: '0.2',
        legendIcon: squareOutlineIcon
    },
    {
        title: 'Moderate',
        background: '#ea8b24',
        containerHeight: '0.2',
        legendIcon: squareOutlineIcon
    },
    {
        title: 'Strong',
        background: '#175d2d',
        containerHeight: '0.2',
        legendIcon: squareOutlineIcon
    },
    {
        title: 'Very Strong',
        background: '#0b6552',
        containerHeight: '0.2',
        legendIcon: squareOutlineIcon
    },
    {
        title: 'Scores pending',
        background: '#7d7d7d',
        containerHeight: '0.2',
        legendIcon: squareOutlineIcon
    }
];

export const likelihoodScoreVeryHigh: ratingsType[] = [
    {
        title: 'Critical',
        background: '#000000',
        containerHeight: '0.4'
    },
    {
        title: 'High',
        background: '#b73349',
        containerHeight: '0.2'
    },
    {
        title: 'Medium',
        background: '#ea8b24',
        containerHeight: '0.2'
    },
    {
        title: 'Low',
        background: '#76b539',
        containerHeight: '0.2'
    }
];

export const likelihoodScoreHigh: ratingsType[] = [
    {
        title: 'High',
        background: '#b73349',
        containerHeight: '0.4'
    },
    {
        title: 'Medium',
        background: '#ea8b24',
        containerHeight: '0.2'
    },
    {
        title: 'Low',
        background: '#76b539',
        containerHeight: '0.4'
    }
];

export const likelihoodScoreMedium: ratingsType[] = [
    {
        title: 'Medium',
        background: '#ea8b24',
        containerHeight: '0.4'
    },
    {
        title: 'Low',
        background: '#76b539',
        containerHeight: '0.2'
    },
    {
        title: 'Very low',
        background: '#0b6552',
        containerHeight: '0.4'
    }
];

export const likelihoodScoreLow: ratingsType[] = [
    {
        title: 'Low',
        background: '#76b539',
        containerHeight: '0.4'
    },
    {
        title: 'Very low',
        background: '#0b6552',
        containerHeight: '0.6'
    }
];

export const likelihoodScoreVeryLow: ratingsType[] = [
    {
        title: 'Very low',
        background: '#0b6552',
        containerHeight: '1'
    }
];

export const likelihoodScoreCatastrophic: ratingsType[] = [
    {
        title: 'Catastrophic',
        background: '#000000',
        containerHeight: '0.4'
    },
    {
        title: 'Critical',
        background: '#b73349',
        containerHeight: '0.2'
    },
    {
        title: 'Moderate',
        background: '#ea8b24',
        containerHeight: '0.2'
    },
    {
        title: 'Minimal',
        background: '#76b539',
        containerHeight: '0.2'
    }
];

export const likelihoodScoreCritical: ratingsType[] = [
    {
        title: 'Critical',
        background: '#b73349',
        containerHeight: '0.4'
    },
    {
        title: 'Moderate',
        background: '#ea8b24',
        containerHeight: '0.2'
    },
    {
        title: 'Minimal',
        background: '#76b539',
        containerHeight: '0.4'
    }
];

export const likelihoodScoreModerate: ratingsType[] = [
    {
        title: 'Moderate',
        background: '#ea8b24',
        containerHeight: '0.4'
    },
    {
        title: 'Minimal',
        background: '#76b539',
        containerHeight: '0.2'
    },
    {
        title: 'Insignificant',
        background: '#0b6552',
        containerHeight: '0.4'
    }
];

export const likelihoodScoreMinimal: ratingsType[] = [
    {
        title: 'Minimal',
        background: '#76b539',
        containerHeight: '0.4'
    },
    {
        title: 'Insignificant',
        background: '#0b6552',
        containerHeight: '0.6'
    }
];

export const likelihoodScoreInsignificant: ratingsType[] = [
    {
        title: 'Insignificant',
        background: '#0b6552',
        containerHeight: '1'
    }
];

export const convertThreatResilienceScoreFromPerc = (
    score?: number | string
) => {
    if (typeof score === 'number') {
        return (score * 10).toFixed(0);
    }
    return 'Scores pending';
};
