import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { RiskOptionType, ThreatObject, Risk, Threat } from '../../../../types';
import verticalDots from '../../../../../../../assets/images/icon-3-vertical-dots-grey.svg';
import riskDescription from '../../../../../../../assets/images/icon-risk-description.svg';
import riskInherentAttributes from '../../../../../../../assets/images/icon-note-risk-attributes.svg';
import iconTrash from '../../../../../../../assets/images/icon-trash.svg';
import iconThreats from '../../../../../../../assets/images/icon-tab-threat-active.svg';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Field } from 'formik';
import { IconButton, TextField, createTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
    BoldText,
    Column,
    Text,
    Row,
    CustomField,
    Flex
} from '../../../../../../../components';
import {
    Dropdown,
    DropdownMulti
} from '../../../../../../../components/base/Dropdowns';

const RiskOption: React.FC<RiskOptionType> = ({
    isSubmit,
    threatsRetrieved,
    selectedCampaignName,
    setFieldValue,
    riskAttributes,
    setChangedProps,
    changedProps,
    id,
    isExpand,
    setDeleteItem
}) => {
    const [isRiskOpen, setRiskOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [threats, setThreats] = useState<ThreatObject[]>(
        changedProps[id].threats
            ? (changedProps[id].threats as ThreatObject[])
            : []
    );

    const [validationErrorText, setValidationErrorText] = useState<string>('');

    useEffect(() => {
        setRiskOpen(isExpand);
    }, [isExpand]);

    useEffect(() => {
        const threatsToSave = changedProps[id].threats
            ? (changedProps[id].threats as ThreatObject[])
            : [];
        setThreats(threatsToSave);
    }, [changedProps.length]);

    useEffect(() => {
        if (
            changedProps[id].title.length &&
            changedProps[id].inherentImpactRating.length &&
            changedProps[id].threatProximity.length &&
            changedProps[id].threatSophistication.length &&
            changedProps[id].threatPrevalence.length
        ) {
            changedProps[id].hasValidationErrors = false;
            setChangedProps(changedProps);
            setValidationErrorText('');
            setFieldValue('changed', true);
        } else {
            changedProps[id].hasValidationErrors = true;
            setChangedProps(changedProps);
            setValidationErrorText('Complete required information');
            setFieldValue('changed', true);
        }
        if (
            changedProps[id].title.length &&
            changedProps.filter(
                (changedProp: Risk) =>
                    changedProp.title === changedProps[id].title
            ).length > 1
        ) {
            changedProps[id].hasValidationErrors = true;
            setValidationErrorText(
                'There is already a risk with this name in this campaign.'
            );
        }
    }, [
        changedProps[id].title,
        changedProps[id].inherentImpactRating,
        changedProps[id].threatProximity,
        changedProps[id].threatSophistication,
        changedProps[id].threatPrevalence
    ]);

    const handleDelete = (chipToDelete: Threat) => () => {
        setThreats(threats.filter(chip => chip.id !== chipToDelete.id));
        changedProps[id].threats = threats.filter(el => {
            if (el.id !== chipToDelete.id) {
                return { id: el.id };
            }
            return false;
        });
        setChangedProps(changedProps);
        setFieldValue('changed', true);
    };

    const filterCheckbox = (checkboxData: string) => {
        const selectedThreat = threats.find(
            (threat: ThreatObject) => threat.title === checkboxData
        );

        if (selectedThreat) {
            setThreats(threats.filter(threat => threat.title !== checkboxData));
        } else {
            const selectedThreatInRetrieved =
                threatsRetrieved &&
                threatsRetrieved.find(
                    (threat: ThreatObject) => threat.title === checkboxData
                );

            selectedThreatInRetrieved
                ? setThreats([...threats, selectedThreatInRetrieved])
                : setThreats([...threats]);
        }
    };

    return (
        <OptionWrapper>
            <Header
                validation={isSubmit && changedProps[id].hasValidationErrors}>
                <IconsWrapper
                    style={{ marginRight: '4px', paddingTop: '22px' }}>
                    <Icon src={verticalDots} alt="Add risk" />
                    <Icon src={verticalDots} alt="Add risk" />
                </IconsWrapper>
                <Field
                    disabled={false}
                    margin="dense"
                    style={{
                        flexBasis: '87%',
                        top: isSubmit
                            && changedProps[id].hasValidationErrors
                            && '-12px'
                    }}
                    label="Risk name"
                    type="name"
                    name="title"
                    variant="outlined"
                    as={TitleTextField}
                    rows="1"
                    fullWidth
                    InputProps={{
                        style: {
                            notchedOutline: {
                                border: 'none'
                            }
                        }
                    }}
                    onChange={(e: React.ChangeEvent<any>) => {
                        changedProps[id].title = e.target.value;
                        setChangedProps(changedProps);
                        setFieldValue('changed', true);
                    }}
                    value={changedProps[id].title}
                    placeholder={
                        changedProps[id].title.length
                            ? changedProps[id].title
                            : 'Add risk name'
                    }
                    multiline
                    isvalidation={
                        isSubmit && changedProps[id].hasValidationErrors
                    }
                />
                <IconButton
                    onClick={() => {
                        setDeleteItem({
                            riskIndex: id,
                            objectName: changedProps[id].title,
                            isOpen: true,
                            modalType: 'risk'
                        });
                        setFieldValue('changed', true);
                    }}
                >
                    <DeleteIcon src={iconTrash} alt="Delete risk" aria-hidden />
                    <DeleteText>Delete</DeleteText>
                </IconButton>
                <KeyboardArrowButton onClick={() => setRiskOpen(!isRiskOpen)}
                    aria-label="collapse or expand button"
                >
                    { isRiskOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown /> }
                </KeyboardArrowButton>
            </Header>
            {isSubmit && changedProps[id].hasValidationErrors && (
                <ErrorText>{validationErrorText}</ErrorText>
            )}
            {isRiskOpen && (
                <Column style={{ marginBottom: '15px' }}>
                    <Column>
                        <SubTitleWrapper>
                            <img
                                src={riskDescription}
                                style={{ marginRight: 5 }}
                                alt="DESCRIPTION"
                            />
                            <SubTitle>Description</SubTitle>
                        </SubTitleWrapper>
                        <Field
                            margin="dense"
                            name="description"
                            type="name"
                            variant="outlined"
                            align="left"
                            placeholder="Add description"
                            value={changedProps[id].description}
                            multiline
                            rows={3}
                            as={TextField}
                            style={{
                                marginLeft: 45,
                                marginTop: 8,
                                marginBottom: 5
                            }}
                            onChange={(e: React.ChangeEvent<any>) => {
                                changedProps[id].description =
                                    e.target.value;
                                setChangedProps(changedProps);
                                setFieldValue('changed', true);
                            }}
                            />
                    </Column>
                    <SubTitleWrapper>
                        <img
                            src={riskInherentAttributes}
                            style={{ marginRight: 5 }}
                            alt="INHERENT RISK ATTRIBUTES"
                        />
                        <SubTitle>inherent risk attributes</SubTitle>
                    </SubTitleWrapper>
                    <Column>
                        <Row>
                            <RiskModalFieldsIndent>
                                <RiskModalLabelsIndent>
                                    Inherent impact rating
                                </RiskModalLabelsIndent>
                                <Dropdown
                                    placeholder="Select inherent impact rating"
                                    value={
                                        changedProps[id].inherentImpactRating
                                    }
                                    handleChange={(
                                        e: React.ChangeEvent<any>
                                    ) => {
                                        changedProps[id].inherentImpactRating =
                                            e.target.value;
                                        setChangedProps(changedProps);
                                        setFieldValue('changed', true);
                                    }}
                                    options={
                                        riskAttributes &&
                                        riskAttributes.inherentRiskAttributesMap
                                            .inherentImpactRating
                                    }
                                    validation={
                                        isSubmit &&
                                        !changedProps[id].inherentImpactRating
                                            .length
                                    }
                                />
                                {isSubmit &&
                                    !changedProps[id].inherentImpactRating
                                        .length && (
                                        <ErrorLabel>
                                            This field is required
                                        </ErrorLabel>
                                    )}
                            </RiskModalFieldsIndent>
                            <RiskModalFieldsIndent>
                                <RiskModalLabelsIndent>
                                    Threat proximity
                                </RiskModalLabelsIndent>
                                <Dropdown
                                    placeholder="Select threat proximity"
                                    value={changedProps[id].threatProximity}
                                    handleChange={(
                                        e: React.ChangeEvent<any>
                                    ) => {
                                        changedProps[id].threatProximity =
                                            e.target.value;
                                        setChangedProps(changedProps);
                                        setFieldValue('changed', true);
                                    }}
                                    options={
                                        riskAttributes &&
                                        riskAttributes.inherentRiskAttributesMap
                                            .threatProximity
                                    }
                                    validation={
                                        isSubmit &&
                                        !changedProps[id].threatProximity.length
                                    }
                                />
                                {isSubmit &&
                                    !changedProps[id].threatProximity
                                        .length && (
                                        <ErrorLabel>
                                            This field is required
                                        </ErrorLabel>
                                    )}
                            </RiskModalFieldsIndent>
                        </Row>
                        <Row>
                            <RiskModalFieldsIndent>
                                <RiskModalLabelsIndent>
                                    Threat sophistication
                                </RiskModalLabelsIndent>
                                <Dropdown
                                    placeholder="Select threat sofistication"
                                    value={
                                        changedProps[id].threatSophistication
                                    }
                                    handleChange={(
                                        e: React.ChangeEvent<any>
                                    ) => {
                                        changedProps[id].threatSophistication =
                                            e.target.value;
                                        setChangedProps(changedProps);
                                        setFieldValue('changed', true);
                                    }}
                                    options={
                                        riskAttributes &&
                                        riskAttributes.inherentRiskAttributesMap
                                            .threatSophistication
                                    }
                                    validation={
                                        isSubmit &&
                                        !changedProps[id].threatSophistication
                                            .length
                                    }
                                />
                                {isSubmit &&
                                    !changedProps[id].threatSophistication
                                        .length && (
                                        <ErrorLabel>
                                            This field is required
                                        </ErrorLabel>
                                    )}
                            </RiskModalFieldsIndent>
                            <RiskModalFieldsIndent>
                                <RiskModalLabelsIndent>
                                    Threat prevalence
                                </RiskModalLabelsIndent>
                                <Dropdown
                                    placeholder="Select threat prevalence"
                                    value={changedProps[id].threatPrevalence}
                                    handleChange={(
                                        e: React.ChangeEvent<any>
                                    ) => {
                                        changedProps[id].threatPrevalence =
                                            e.target.value;
                                        setChangedProps(changedProps);
                                        setFieldValue('changed', true);
                                    }}
                                    options={
                                        riskAttributes &&
                                        riskAttributes.inherentRiskAttributesMap
                                            .threatPrevalence
                                    }
                                    validation={
                                        isSubmit &&
                                        !changedProps[id].threatPrevalence
                                            .length
                                    }
                                />
                                {isSubmit &&
                                    !changedProps[id].threatPrevalence
                                        .length && (
                                        <ErrorLabel>
                                            This field is required
                                        </ErrorLabel>
                                    )}
                            </RiskModalFieldsIndent>
                        </Row>
                    </Column>
                    <Column>
                        <SubTitleWrapper>
                            <img
                                src={iconThreats}
                                style={{ marginRight: 5 }}
                                alt="THREAT SCENARIOS"
                            />
                            <SubTitle>Threat scenarios</SubTitle>
                        </SubTitleWrapper>
                        <RiskModalFieldsIndent>
                            <RiskModalLabelsIndent style={
                                    threatsRetrieved && threatsRetrieved.length
                                        ? {}
                                        : { opacity: 0.4 }
                                }>
                                Threat scenarios
                            </RiskModalLabelsIndent>
                            <DropdownMulti
                                anchorEl={anchorEl}
                                allCheckboxed={
                                    threatsRetrieved &&
                                    threats.length === threatsRetrieved.length
                                }
                                buttonDisabled={
                                    threatsRetrieved && !threatsRetrieved.length
                                }
                                disableClear={!threats.length}
                                dropdownsSelected={threats}
                                placeholder="Select threat scenarios"
                                values={threats}
                                options={threatsRetrieved}
                                handleDeleteChips={handleDelete}
                                onClear={() => {
                                    setThreats([]);
                                    changedProps[id].threats = [];
                                    setChangedProps(changedProps);
                                    setFieldValue('changed', true);
                                }}
                                onApply={() => {
                                    changedProps[id].threats = threats.map(
                                        el => {
                                            return { id: el.id };
                                        }
                                    );
                                    setChangedProps(changedProps);
                                    setFieldValue('changed', true);
                                }}
                                onButtonClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    setAnchorEl(e.currentTarget);
                                }}
                                onCheckAll={() => {
                                    if (
                                        threatsRetrieved &&
                                        threats.length ===
                                            threatsRetrieved.length
                                    ) {
                                        setThreats([]);
                                        changedProps[id].threats = [];
                                        setChangedProps(changedProps);
                                        setFieldValue('changed', true);
                                    } else if (threatsRetrieved) {
                                        setThreats(threatsRetrieved);
                                        changedProps[id].threats = threats.map(
                                            el => {
                                                return { id: el.id };
                                            }
                                        );
                                        setChangedProps(changedProps);
                                        setFieldValue('changed', true);
                                    }
                                }}
                                onDropdownClick={(
                                    e: React.MouseEvent<HTMLButtonElement>,
                                    value: string
                                ) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    filterCheckbox(value);
                                }}
                                disableApply={
                                    JSON.stringify(changedProps) ===
                                    JSON.stringify(threats)
                                }
                            />

                            {threatsRetrieved && !threatsRetrieved.length && (
                                <Alert
                                    severity="info"
                                    style={{ marginTop: '1rem' }}
                                >
                                    <Text color="#4588c3" size="17px">
                                        Threat scenarios have not been added to
                                        the “{selectedCampaignName}” yet. <br />
                                        You can do this in the Threat scenarios
                                        tab.
                                        <br />
                                        Adding threat scenarios provide a more
                                        in depth analyse of risks.
                                    </Text>
                                </Alert>
                            )}
                        </RiskModalFieldsIndent>
                    </Column>
                </Column>
            )}
        </OptionWrapper>
    );
};


const RiskModalFieldsIndent = styled(Column)({
    marginLeft: '35px',
    marginTop: '8px',
});

const RiskModalLabelsIndent = styled(Text)({
    color: 'grey',
    fontSize: '12px',
    marginLeft: '2px',
    marginTop: '8px',
});

const DeleteText = styled(Text)`
    font-size: 14px;
    color: #6E6E6E;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 22px;
`;

const ErrorLabel = styled(Text)`
    color: #da1e28;
    font-size: 16px;
`;

const ErrorText = styled('p')`
    color: rgb(218, 30, 40);
    position: relative;
    left: 45px;
    top: -33px;
    font-size: 13px;
    margin-bottom: -29px;
`;

const TitleTextField = styled(TextField)<{ isvalidation?: boolean }>`
    .MuiOutlinedInput-multiline {
        padding-bottom: ${props => (props.isvalidation ? '0px' : '18.5px')};
    }
    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
    .MuiFormLabel-root-MuiInputLabel-root {
        padding-top:10px;
    }
`;

const IconsWrapper = styled('div')`
    width: 55px;
    flex-basis: 5%;
`;

const Icon = styled('img')`
    && {
        margin-right: -13px;
        opacity: 0.5;
    }
`;

const DeleteIcon = styled('img')`
    width: 17px;
    opacity: 0.5;
`;

const SubTitleWrapper = styled(BoldText)`
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-top: 20px;
`;

const SubTitle = styled('div')`
    font-size: 14px;
    text-transform: uppercase;
`;

const Header = styled('div')<{ validation?: boolean }>`
    display: flex;
    width: 704px;
    height: 72px;
    border-color: ${props => (props.validation ? '#de3328' : '#ececec')}
    align-items: center;
    justify-content: space-evenly;
    background-color: #f4f4f4;
`;

const KeyboardArrowButton = styled(IconButton)`
    color: #2d2d2d !important;
    flex-basis: 8%;
`;

const OptionWrapper = styled('div')`
    margin-bottom: 3px;
    width: fit-content;
`;

export default RiskOption;
