export const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const campaignActions = [
    { id: 'edit', label: 'Edit campaign' },
    { id: 'add', label: 'Add assessment' }
];

export const campaignAnalysis = [
    { id: 'analysis', label: 'View Threat Analisis' },
    { id: 'benchmarking', label: 'View Benchmarking' },
    { id: 'comparison', label: 'View Comparison' },
    { id: 'risk', label: 'View Risk Dashboard' },
    { id: 'var', label: 'View VAR analyses' }
];

export const assessmentsActiveTypes = [
    { id: 'ACTIVE', label: 'Active assessments' },
    { id: 'ALL', label: 'All assessments' },
    // { id: 'CLOSED', label: 'Closed assessments' },
    { id: 'ARCHIVED', label: 'Archived assessments' }
];

export const assessmentsProcessTypes = [
    { id: 'ALL_ASSESSMENTS', label: 'All assessments' },
    { id: 'COMPLETED', label: 'Completed assessments' },
    { id: 'NOT_STARTED', label: 'Not started assessments' },
    { id: 'IN_PROGRESS', label: 'In progress assessments' }
];

export const maturityHeaderRows = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Entity'
    },
    // {
    //     id: 'assessmentLead',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Assessment Lead'
    // },
    {
        id: 'capScored',
        numeric: true,
        disablePadding: false,
        label: 'Scored'
    },
    {
        id: 'capCovered',
        numeric: true,
        disablePadding: false,
        label: 'Moderated'
    },
    {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'Last Updated'
    },
    {
        id: 'progress',
        numeric: true,
        disablePadding: false,
        label: 'Status'
    }
];

export const assessmentHeadersRows = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Entity'
    },
    {
        id: 'campaignTitle',
        numeric: false,
        disablePadding: false,
        label: 'Campaign Title'
    },
    {
        id: 'framework',
        numeric: false,
        disablePadding: false,
        label: 'Framework'
    },
    // {
    //     id: 'assessmentLead',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Assessment Lead'
    // },
    {
        id: 'capScored',
        numeric: true,
        disablePadding: false,
        label: 'Scored'
    },
    {
        id: 'capCovered',
        numeric: true,
        disablePadding: false,
        label: 'Moderated'
    },
    {
        id: 'lastUpdated',
        numeric: true,
        disablePadding: false,
        label: 'Last Updated'
    },
    {
        id: 'progress',
        numeric: true,
        disablePadding: false,
        label: 'Status'
    }
];

export const createCompaignStepsTitles = [
    'Enter campaign details',
    'Create entities',
    'Add members'
];

export const colorsList = [
    '#D04A02',
    '#EB8C00',
    '#FFB600',
    '#DB536A',
    '#E0301E',
    '#464646',
    '#9013FE',
    '#0089EB',
    '#4EB523',
    '#FD6412',
    '#FFA929',
    '#FFC83D',
    '#E27588',
    '#E86153',
    '#7D7D7D',
    '#B15AFE',
    '#4DACF1',
    '#86DB4F'
];

export const initDomain = {
    id: '',
    name: '',
    capabilities: [
        {
            id: '',
            name: '',
            inScope: true
        }
    ],
    feedback: [
        {
            id: '',
            observations: [
                {
                    text: ''
                }
            ],
            recommendations: [
                {
                    text: ''
                }
            ],
            title: ''
        }
    ]
};

export const privacyBannerMessage='Data privacy notice: Do not enter any sensitive personal information in free text fields, including, but not limited to, that revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health or sexual orientation.';

export enum GlobalSettingsStatus {
    READ_ONLY = 'Read Only',
    EXPORT_BUTTON = 'Export Button',
    TARGET_SCORE = 'Target Scoring',
    DECIMAL_POINT_ONE = 'Decimal .1',
    CHATBOT = 'ChatBot',
    NOTIFICATIONS = 'Notifications',
    IMPORT_BENCHMARKING = 'Import Benchmarking'
}

export enum FrameworkSettingsStatus {
    COMPENSATING_CONTROL = 'Compensating Control'
}

export const targetScoreDisableTitle='Switch Target scoring off?';
export const targetScoreDisableMessage='This action will hide the target scoring functionality and target scores for all campaign members. If target scoring is turned on again later, any existing target scores will be restored';
export const targetScoreEnableTitle='Overwrite existing target scores?';
export const targetScoreEnableMessage='This action will overwrite any existing target scores and cannot be undone.';

export const capabilityModeratedMessage='This capability has been moderated!';

export const autoSaveMessage='There are unsaved changes in the notes, do you want to proceed without saving?';

export const autoSaveFailedMessage='Auto save failed, please copy your notes and try saving it later';

export enum CampaignUserRoleEnum {
    MANAGER = 'MANAGER',
    MEMBER = 'MEMBER',
    READONLY = 'READONLY'
}

export enum CampaignStatusEnum {
    NOT_STARTED = 'NOT_STARTED',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    CLOSED_INCOMPLETE = 'CLOSED_INCOMPLETE',

}

export enum PermissionTypeEnum {
    FULL_MEMBER_ACCESS = 'FULL_MEMBER_ACCESS',
    READ_ONLY_ACCESS = 'READ_ONLY_ACCESS'
}

export enum NotificationTypeEnum {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export enum NotificationCategoryEnum {
    DEFAULT = 'DEFAULT',
    COMPANY = 'COMPANY',
    CAMPAIGN = 'CAMPAIGN',
    ASSESSMENT = 'ASSESSMENT',
}

export const weightingValidationMessage='Your weights must total 100%';

export const generalWeightFlagMessage='One or more peers are using a different weight distribution';

export const weightFlagDomainMessage='One or more peers are using a different weight distribution, impacting the Domain overview';

export const chartDraftWarning='This chart may display a DRAFT watermark if not all capabilities or assessments have been approved.';

export const weightBalanceSuccessMessages='Weights have been successfully balanced.';

export const weightBalanceFailureMessage='Weights failed to balance.';

export const minimunBenchmarkingPeers = 5;

export const NIST_FRAMEWORK_NAMES = ['NIST Cyber Security v1.1 - US use only', 'NIST CSF v1.1 (People, Process, Technology version)'];

export const INITIAL_LOGOUT_TIME = window?.__ENV__?.REACT_APP_INITIAL_LOGOUT_TIME ? Number(window?.__ENV__?.REACT_APP_INITIAL_LOGOUT_TIME) : 2; // time is in mins (default 2 mins)

export const EXTENDED_LOGOUT_TIME = window?.__ENV__?.REACT_APP_EXTENDED_LOGOUT_TIME ? Number(window?.__ENV__?.REACT_APP_EXTENDED_LOGOUT_TIME) : 2; // time is in mins (default 2 mins)

export const ASSESSMENT_FETCH_LIMIT = window?.__ENV__?.REACT_APP_ASSESSMENT_FETCH_LIMIT ? Number(window?.__ENV__?.REACT_APP_ASSESSMENT_FETCH_LIMIT) : 5; // items to be fetch at a time (default 5 items)

export const CLIENT_FETCH_LIMIT = window?.__ENV__?.REACT_APP_CLIENT_FETCH_LIMIT ? Number(window?.__ENV__?.REACT_APP_CLIENT_FETCH_LIMIT) : 5;

export const ASSESSMENT_SEARCH_DELAY = window?.__ENV__?.REACT_APP_ASSESSMENT_SEARCH_DELAY ? Number(window?.__ENV__?.REACT_APP_ASSESSMENT_SEARCH_DELAY) : 500; // pagination search delay(500 millisecond)

export const READONLY_MEMBER_ALERT_MESSAGE = 'The following users have full access to two or more entities that fall under the current selection. Granting them read access to this entity or campaign will remove all full access to entities that fall under it. Please review and uncheck the individual if you do now wish to include them in the changes being made.';
export const READONLY_MEMBER_REMOVE_MESSAGE = 'Read-only access removed for the following user';
export const READONLY_MEMBER_ADD_MESSAGE = 'The following users have been assigned read-only access';
export const FULL_MEMBER_ADD_MESSAGE = 'The following users have been assigned full access';
export const FUll_MEMBER_REMOVE_MESSAGE = 'Full access removed for the following user';

export const DUPLICATE_PERMISSION_ERROR_TOAST_MSG = 'Can not add duplicate permission.';
export const NO_USER_SELECTED_ERROR_TOAST_MSG = 'Please select a user to perform this action.';
export const NO_PERMISSION_TO_DELETE_ERROR_TOAST_MSG = 'You can not perform this action. Campaign managers or Relationship manager cannot be removed.';

export const MANAGE_MEMBERS_MODAL_MESSAGE1 = 'Full members can view and edit all parts of an assessment. Read-only members can only view an assessment section once approved.';
export const MANAGE_MEMBERS_MODAL_MESSAGE2 = 'Some members may have inherited access from the campaign and must be removed at the campaign level first.';

export const NOTIFICATION_FETCH_LIMIT = window?.__ENV__?.REACT_APP_NOTIFICATION_FETCH_LIMIT ? Number(window?.__ENV__?.REACT_APP_NOTIFICATION_FETCH_LIMIT) : 10; // items to be fetch at a time (default 10 items);
