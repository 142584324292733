import React from 'react';
import Text from '../../base/Text/Text';
import AlignItems from '../../base/Layout/AlignItems';
type MenuButtonProps = {
    leftIcon?: string;
    text: string;
    rightIcon: string;
    disabled?: boolean;
    backgroundColor?: string;
    textColor?: string;
};

const MenuButton: React.FC<MenuButtonProps> = ({
    leftIcon,
    text,
    rightIcon,
    disabled = false,
    backgroundColor,
    textColor
}) => (
    <AlignItems
        style={{
            backgroundColor: backgroundColor?backgroundColor:(disabled ? '#eb9690' : '#de3328'),
            height: 48,
            padding: '0 16px',
            borderRadius: 2
        }}
    >
        {leftIcon && <img src={leftIcon} aria-hidden={true} />}
        <Text
            style={{
                color: textColor?textColor:'#fff',
                margin: 10,
                fontSize: 16,
                fontWeight: 500
            }}
        >
            {text}
        </Text>
        {rightIcon && <img src={rightIcon} aria-hidden={true} />}
    </AlignItems>
);
export default MenuButton;
