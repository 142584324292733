import React from 'react';
import { Column, JustifyContentCenter } from '../../../../../components/base/Layout';
import { styled } from '@mui/material/styles';
import { BoldText, Text } from '../../../../../components';
import closeIcon from '../../../../../assets/images/Atmos_Icons_Close.svg';

export type ObjectiveGuidanceProps = {
    setGuidanceOpen: CallableFunction;
    guidance: string;
};

const ObjectiveGuidanceTooltip: React.FC<ObjectiveGuidanceProps> = (
    {
        setGuidanceOpen,
        guidance
    }
) => {
    return (
        <Column>
            <TooltipHeader>
                <div />
                <HeaderText>{'Objective Guidance'.toUpperCase()}</HeaderText>
                <ImageClose
                    src={closeIcon}
                    alt="close icon"
                    onClick={() => setGuidanceOpen(false)}
                />
            </TooltipHeader>
            <TextContainer>
                {guidance}
            </TextContainer>
        </Column>

    );
};

const TooltipHeader = styled(JustifyContentCenter)`
    padding: 10px 0;
    border-bottom: solid 1px #e0e0e0;
    justify-content: space-between;
`;

const TextContainer = styled(Text)`
    font-size: 16px;
    color: #333333;
    text-align: left;
    overflow: auto;
    padding: 9px 24px;
    max-height: 530px;
    white-space: pre-wrap;
`;

const HeaderText = styled(BoldText)`
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    height: 35px;
    line-height: 35px;
`;

const ImageClose = styled('img')`
    cursor: pointer;
    height: 16px;
    position: relative;
    padding: 0 16px;
`;

export default ObjectiveGuidanceTooltip;
