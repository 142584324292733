import React, { ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';

type Props = {
    isAutoFocus: boolean;
    onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    isDisabled?: boolean;
    name: string;
    handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    value: string;
    handleBlur?: () => void;
    overflow?: string;
    border?: string;
    placeholder?: string;
    minHeight?: string;
    margin?: string;
    padding?: string;
};

const TextArea = React.forwardRef(
    (props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
        return (
            <TextAreaContainer
                ref={ref || null}
                autoFocus={props.isAutoFocus}
                onFocus={props.onFocus ? props.onFocus : () => {}}
                disabled={props.isDisabled}
                name={props.name}
                onChange={props.handleChange}
                value={props.value}
                onBlur={props.handleBlur ? props.handleBlur : () => {}}
                overflow={props.overflow}
                border={props.border}
                placeholder={props.placeholder}
                minHeight={props.minHeight}
                margin={props.margin}
                padding={props.padding}
            />
        );
    }
);

TextArea.defaultProps = {
    isDisabled: false,
    overflow: '',
    border: '',
    placeholder: '',
    minHeight: '',
    margin: ''
};

const TextAreaContainer = styled('textarea')<{
    overflow?: string;
    border?: string;
    minHeight?: string;
    margin?: string;
    padding?: string;
}>`
    width: 100%;
    border-radius: 4px;
    font-size: 14px;
    font-family: inherit;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    width: 100%;
    padding: ${props => (props.padding ? props.padding : '20px')};
    box-sizing: border-box;
    font-size: 14px;
    color: #4f4f4f;
    overflow: ${props => (props.overflow ? props.overflow : 'none')};
    min-height: ${props => (props.minHeight ? props.minHeight : '85px')};
    border: ${props => (props.border ? props.border : 'solid 1px #cdcdcd')};
    margin: ${props => (props.margin ? props.margin : '0')};

    &:disabled {
        color: #4f4f4f;
        cursor: default;
        background-color: #fff;
    }
`;

export default TextArea;
