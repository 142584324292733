import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import TAREmptyState from '../../TAREmptyState';
import {
    ThreatScenariosComponentType,
    Threat,
    ThreatAssessment,
} from '../../../types';
import { Column } from '../../../../../../components';
import AssessmentRow from './AssessmentRow';
import threatIcon from '../../../../../../assets/images/illustration-threath-scenarios.jpg';
import iconThreatTabActive from '../../../../../../assets/images/icon-tab-threat-active.svg';
import verticalDots from '../../../../../../assets/images/icon-3-vertical-dots.svg';
import { Button, MenuItem, Tooltip, Typography } from '@mui/material';
import CustomMenu from '../../../../../../components/Menu';

const ThreatScenariosComponent: React.FC<ThreatScenariosComponentType> = ({
    isAddButtonDisabled,
    setAddTARModalOpen,
    assessments,
    threatDashboard,
    isOnlyKeyVisible,
    isGuidance,
    setOpenDeleteConfirmation,
    threatList,
    setOpenThreatId,
    setAddModal,
    listOfAssessmentIds,
}) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const [isOpen, setAllOpen] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    return (
        <div>
            {threatDashboard &&
            threatDashboard.length &&
            assessments &&
            assessments.length ? (
                <div id="ThreatsWithAssessment">
                    <HorizontalScrollDiv id="Threats">
                        <ThreatTable id="ThreatObjectivesTable" ref={tableRef}>
                            <thead>
                                <tr>
                                    {isOpen ? (
                                        <ExpandAll>
                                            <Button
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                onClick={() => {
                                                    setAllOpen(false);
                                                }}
                                            >
                                                Collapse all
                                            </Button>
                                        </ExpandAll>
                                    ) : (
                                        <ExpandAll>
                                            <Button
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                onClick={() => {
                                                    setAllOpen(true);
                                                }}
                                            >
                                                Expand all
                                            </Button>
                                        </ExpandAll>
                                    )}

                                    {threatDashboard &&
                                        threatDashboard.map(
                                            (threat: Threat, index: number) => {
                                                return (
                                                    <ThreatThTd key={threat.id}>
                                                        {!isAddButtonDisabled && (
                                                            <CustomMenu
                                                                setMenuStatus={
                                                                    setIsMenuOpen
                                                                }
                                                                isMenuOpen={
                                                                    isMenuOpen
                                                                }
                                                                button={
                                                                    <Tooltip
                                                                        title={
                                                                            <Typography>
                                                                                Edit
                                                                            </Typography>
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                verticalDots
                                                                            }
                                                                            alt="Risk Menu"
                                                                            style={{
                                                                                padding:
                                                                                    '3px',
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                                menuStyles={{
                                                                    position:
                                                                        'absolute',
                                                                    right: '13px',
                                                                    top: '5px',
                                                                    backgroundColor:
                                                                        '#c4c4c4',
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setIsMenuOpen(
                                                                            false
                                                                        );
                                                                        setAddModal(
                                                                            false
                                                                        );
                                                                        setOpenThreatId(
                                                                            threat.id
                                                                        );
                                                                        setAddTARModalOpen &&
                                                                            setAddTARModalOpen(
                                                                                true
                                                                            );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </MenuItem>
                                                                {threatList && (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setIsMenuOpen(
                                                                                false
                                                                            );
                                                                            const threatsToKeep = 
                                                                                threatList && _.cloneDeep(threatList).filter(( threatObject: any) => (
                                                                                    threat.id !== threatObject.id
                                                                                ));

                                                                            threatsToKeep &&
                                                                                threatsToKeep.forEach(
                                                                                    (
                                                                                        threatToKeep
                                                                                    ) => {
                                                                                        delete threatToKeep.createdAt;
                                                                                        delete threatToKeep.updatedAt;
                                                                                        delete threatToKeep.__typename;
                                                                                        threatToKeep.objectives &&
                                                                                            threatToKeep.objectives.forEach((objective) => {
                                                                                                    objective.__typename &&
                                                                                                        delete objective.__typename;
                                                                                                }
                                                                                            );
                                                                                    }
                                                                                );
                                                                            setOpenDeleteConfirmation(
                                                                                {
                                                                                    objectId:
                                                                                        threatsToKeep
                                                                                            ? threatsToKeep
                                                                                            : '',
                                                                                    objectName:
                                                                                        threat.title,
                                                                                    isOpen: true,
                                                                                    modalType:
                                                                                        'threat',
                                                                                    canDelete:
                                                                                        threatList &&
                                                                                        threatList.find(
                                                                                            (
                                                                                                threatObject: any
                                                                                            ) => {
                                                                                                return (
                                                                                                    threat.id ===
                                                                                                        threatObject.id &&
                                                                                                    threatObject
                                                                                                        .risks
                                                                                                        .length ===
                                                                                                        0
                                                                                                );
                                                                                            }
                                                                                        ),
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </MenuItem>
                                                                )}
                                                            </CustomMenu>
                                                        )}
                                                        <ThreatName>
                                                            <img
                                                                src={
                                                                    iconThreatTabActive
                                                                }
                                                                style={{
                                                                    alignSelf:
                                                                        'center',
                                                                }}
                                                                alt="iconThreatTabActive"
                                                            />

                                                            <ThreatText>
                                                                {threat.title}
                                                            </ThreatText>
                                                        </ThreatName>
                                                    </ThreatThTd>
                                                );
                                            }
                                        )}
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: 'baseline' }}>
                                <tr key={2} style={{ height: '65px' }}>
                                    <AssessmentRow
                                        tableRef={tableRef}
                                        rowId={2}
                                        isOpen={isOpen}
                                        assessments={assessments}
                                        threatDashboard={threatDashboard}
                                        isOnlyKeyVisible={isOnlyKeyVisible}
                                        isGuidance={isGuidance}
                                        listOfAssessmentIds={
                                            listOfAssessmentIds
                                        }
                                    />
                                </tr>
                                {threatDashboard &&
                                    threatDashboard[0].assessments &&
                                    threatDashboard[0].assessments.map(
                                        (
                                            assessment: ThreatAssessment,
                                            index: number
                                        ) => {
                                            return (
                                                <tr key={index + 2}>
                                                    <AssessmentRow
                                                        tableRef={tableRef}
                                                        rowId={index + 2}
                                                        isOpen={isOpen}
                                                        assessment={assessment}
                                                        assessments={
                                                            assessments
                                                        }
                                                        threatDashboard={
                                                            threatDashboard
                                                        }
                                                        isOnlyKeyVisible={
                                                            isOnlyKeyVisible
                                                        }
                                                        isGuidance={isGuidance}
                                                    />
                                                </tr>
                                            );
                                        }
                                    )}
                            </tbody>
                        </ThreatTable>
                    </HorizontalScrollDiv>
                </div>
            ) : (
                <TAREmptyState
                    headingText="Analyse threats to your organisation"
                    explanationText="Gain insight into your organisation's resilience against specific threats by leveraging your maturity scores."
                    buttonText="Add threat"
                    isAddButtonDisabled={isAddButtonDisabled}
                    imageSrc={threatIcon}
                    setAddTARModalOpen={setAddTARModalOpen}
                />
            )}
        </div>
    );
};

const ThreatText = styled('div')`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 8px;
`;

const ThreatTable = styled('table')`
    border-collapse: collapse;
    padding-left: 318px;
    thead td {
        height: 76px;
    }
`;

const ExpandAll = styled('td')`
    font-size: 15px;
    color: #2d2d2d;
    text-decoration: underline;
    padding: 38px 0 0 8px;
    position: absolute;
    left: calc(5% + 31px);
`;

const ThreatThTd = styled('td')`
    padding: 12px;
    font-size: 16px;
    min-height: 62px;
    flex-direction: column;
    color: #2d2d2d;
    text-align: center;
    border: solid 1px #d7d7d7;
    min-width: 276px;
    padding: 5px 13px;
    width: 276px;
    position: relative;
`;

const ThreatName = styled(Column)`
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    color: #2d2d2d;
    font-weight: bold;
    font-size: 16px;
    justify-content: space-evenly;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 276px;
`;

const HorizontalScrollDiv = styled('div')`
    overflow-x: scroll;
    margin-left: 230px;
    width: calc(100vw - 10% - 330px);
`;

export default ThreatScenariosComponent;
