import React, { useState, useEffect, useContext } from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { styled } from '@mui/material/styles';
import { UserStore } from '../../../context/user-store';
import {
    AssessmentsMaturityOverviewFirstTab,
    Chart,
    BarGraph
} from './AssessmentsMaturityOverviewTabs';
import {
    ImgContainer,
    Tab,
    VerticalLine,
    AlignItems,
    Column,
    Text,
    JustifyContentEnd,
    SpinnerWithOverlay
} from '../../../components';
import AssessmentMaturityOverviewHeader from './AssessmentMaturityOverviewHeader';
import { AssessmentsMaturityOverviewMeetingsTab } from './AssessmentsMaturityOverviewMeetingsTab';
import DetailsTab from './AssessmentsMaturityOverviewDetailsTab';
import { campaignOverviewMenuWithTargetScore } from '../MaturityOverview/MaturityOverviewHeader/MaturityOverviewHeaderHelpers';

import eventNoteIcon from '../../../assets/images/icon-event-note.svg';
import eventNoteIconWhite from '../../../assets/images/icon-event-note-white.svg';
import iconDetails from '../../../assets/images/icon-assessment-details.svg';
import iconDetailsActive from '../../../assets/images/icon-assessment-details-active.svg';
import iconAverage from '../../../assets/images/icon-assessments-domain-average.svg';
import iconAverageActive from '../../../assets/images/icon-assessments-domain-average-active.svg';
import iconGraph from '../../../assets/images/icon-assessment-maturity-graph.svg';
import iconGraphActive from '../../../assets/images/icon-assessment-maturity-graph-active.svg';
import iconMaturityOverviewActive from '../../../assets/images/icon-assessment-maturity-overview-active.svg';
import iconMaturityOverview from '../../../assets/images/icon-assessment-maturity-overview.svg';

import { AssessmentMaturityMatch } from '../MaturityOverview/types';
import { AssessmentData, CampaignProgresData, DomainWeight, FrameworkSetting } from './types';
import { isTargetScore, isPointOneDecimalEnable } from '../../../utils/global-settings';
import { PermissionTypeEnum, isReadOnlyEnabled } from '../../../utils';
import { isDomainCapWeightValid } from '../Assessments/AssessmentsHelpers';
import { isCompensatingControlEnable } from '../../../utils/framework-settings';
import { CAMPAIGN_FRAMEWORK } from './AssessmentsMaturityOverviewQueries';
import { useParams } from 'react-router';

const CAMPAIGN = gql`
    query campaign1($id: ID!) {
        campaign(id: $id) {
            id
            title
            progress
            assessmentsInProgressCnt
            assessmentsCompletedCnt
            assessmentsCnt
            status
            closedAt
            readOnly
            manager {
                id
            }
            members {
                id
            }
            readonlymembers {
                id
            }
            targetScoreEnabled
            pointOneDecimalEnable
            isCampaignEntityUser
        }
    }
`;



export const CAMPAIGN_FRAMEWORK_SETTINGS = gql`
    query frameworkSettingsByCampaign($campaignId: ID!) {
        frameworkSettingsByCampaign(campaignId: $campaignId) {
            id
            name
            status
        }
    }
`;

const UPDATE_DOMAIN_CAPABILITY_WEIGHT = gql`
    mutation updateAssessmentDomainsAndCapabilitiesWeighting(
        $data: UpdateAssessmentDomainCapabilityWeightingInput!
    ) {
        updateAssessmentDomainsAndCapabilitiesWeighting(data: $data) {
            status
        }
    }
`;

type CampaignData = {
    campaign: Campaign;
};

type Campaign = {
    id: string;
    title: string;
    status: string;
    progress: number;
    assessmentsInProgressCnt: number;
    assessmentsCompletedCnt: number;
    assessmentsCnt: number;
    targetScoreEnabled: boolean;
    pointOneDecimalEnable: boolean;
    readOnly: boolean;
    closedAt: null | number;
    manager: { id: number };
    members: { id: number }[];
    readonlymembers?: { id: number }[];
};

type TabType = {
    iconActive: string;
    iconDisable: string;
    id: number;
    label: string;
};

const renderContent = ({
    activeTab,
    data,
    campaignId,
    assessmentId,
    campaignTitle,
    campaignIsClosed,
    showTargetScore,
    showCompensationControl,
    isLegendVisible,
    setIsLegendVisible,
    view,
    targetScoreEnabled,
    isReadOnly,
    campaignComplete,
    pointOneDecimalEnable,
    isObjectiveComponentExist,
    handleOnSaveWeighting,
    isAssessmentWeightingError,
    isAssessmentWeightingSuccess,
    setbalanceWeightSuccessMessage,
    isbalanceWeightSuccessMessage,
    setIsAssessmentWeightingModal,
    isAssessmentWeightingModal,
    compensatingControlEnabled
}: {
    activeTab: number;
    data?: AssessmentData;
    campaignId: string;
    assessmentId: string;
    campaignTitle: string;
    campaignIsClosed: boolean;
    showTargetScore: boolean;
    showCompensationControl: boolean;
    isLegendVisible: boolean;
    setIsLegendVisible: any;
    view: string;
    targetScoreEnabled: boolean;
    isReadOnly: boolean;
    campaignComplete: boolean;
    pointOneDecimalEnable: boolean;
    isObjectiveComponentExist: boolean;
    handleOnSaveWeighting: any;
    isAssessmentWeightingError: boolean;
    isAssessmentWeightingSuccess: boolean;
    setbalanceWeightSuccessMessage: any;
    isbalanceWeightSuccessMessage: boolean;
    setIsAssessmentWeightingModal: any;
    isAssessmentWeightingModal: boolean;
    compensatingControlEnabled: boolean;
    }) => {
    const contentList = {
        [`${1}`]: (
            <AssessmentsMaturityOverviewFirstTab
                data={data}
                campaignId={campaignId}
                assessmentId={assessmentId}
                showTargetScore={showTargetScore}
                showCompensationControl={showCompensationControl}
                isLegendVisible={isLegendVisible}
                setIsLegendVisible={setIsLegendVisible}
                view={view}
                targetScoreEnabled={targetScoreEnabled}
                campaignIsClosed={campaignIsClosed}
                isReadOnly={isReadOnly}
                pointOneDecimalEnable={pointOneDecimalEnable}
                isObjectiveComponentExist={isObjectiveComponentExist}
                handleOnSaveWeighting={handleOnSaveWeighting}
                isAssessmentWeightingError={isAssessmentWeightingError}
                isAssessmentWeightingSuccess={isAssessmentWeightingSuccess}
                setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                isbalanceWeightSuccessMessage={isbalanceWeightSuccessMessage}
                setIsAssessmentWeightingModal={setIsAssessmentWeightingModal}
                isAssessmentWeightingModal={isAssessmentWeightingModal}
                compensatingControlEnabled={compensatingControlEnabled}
            />
        ),
        [`${2}`]: (
            <BarGraph assessmentsData={data} campaignTitle={campaignTitle} showTargetScore={showTargetScore} pointOneDecimalEnable={pointOneDecimalEnable} />
        ),
        [`${3}`]: <Chart data={data} campaignTitle={campaignTitle} showTargetScore={showTargetScore} pointOneDecimalEnable={pointOneDecimalEnable}/>,
        [`${4}`]: (
            <DetailsTab
                detailsTabData={data}
                assessmentId={assessmentId}
                showTargetScore={showTargetScore}
                isLegendVisible={isLegendVisible}
                setIsLegendVisible={setIsLegendVisible}
                isReadOnly={isReadOnly}
                campaignComplete={campaignComplete}
                pointOneDecimalEnable={pointOneDecimalEnable}
                isObjectiveComponentExist={isObjectiveComponentExist}
            />
        ),
        [`${5}`]: (
            <AssessmentsMaturityOverviewMeetingsTab
                assessmentId={assessmentId}
                campaignIsClosed={campaignIsClosed}
                isReadOnly={isReadOnly}
            />
        )
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return contentList[activeTab] || contentList[0];
};

const AssessmentsMaturityOverview: React.FC = () => {
    const params = useParams();
    const campaignId = params.campaignId ? params.campaignId : '';
    const assessmentId = params.assessmentId ? params.assessmentId : '';
    const [activeTab, setActiveTab] = useState<number>(1);
    const [assessmentName, setAssessmentName] = useState('');
    const [campaignProgresData, setCampaignProgressdata] = useState<
        CampaignProgresData
    >({
        progress: 0,
        assessmentsInProgressCnt: 0,
        assessmentsCompletedCnt: 0,
        assessmentsCnt: 0
    });

    const [showTargetScore, setShowTargetScore] = useState(false);
    const [showCompensationControl, setShowCompensationControl] = useState(false);
    const [isLegendVisible, setIsLegendVisible] = useState(() => {
        const defaultIsLegendVisible = localStorage.getItem('isLegendVisible') || 'true';
        return Boolean(defaultIsLegendVisible === 'true');
    });
    const [view, setView] = useState<string>('horizontal');

    const {
        state: { currentTheme, globalSettings }
    } = useContext(UserStore);
    const { dispatch } = useContext(UserStore);
    const setCurrentTheme = (theme: string) => {
        dispatch({ type: 'SET_THEME', value: theme });
    };

    const { loading, data } = useQuery<AssessmentData>(CAMPAIGN_FRAMEWORK, {
        variables: { campaignId, assessmentId },
        fetchPolicy: 'cache-and-network'
    });

    const { data: frameworkSettings } = useQuery(
        CAMPAIGN_FRAMEWORK_SETTINGS,
        {
            variables: {
                campaignId
            }
        }
    );

    const { data: campaignData } = useQuery<CampaignData>(CAMPAIGN, {
        variables: {
            id: campaignId
        }
    });
    const [campaignTitle, setCampaignTitle] = useState<string>('');
    const [targetScoreEnabled, setTargetScoreEnabled] = useState<boolean>(true);
    const [compensatingControlEnabled, setCompensatingControlEnabled] = useState<boolean>(false);
    const [isAssessmentWeightingError, setIsAssessmentWeightingError] = useState(false);
    const [isAssessmentWeightingSuccess, setIsAssessmentWeightingSuccess] = useState(false);
    const [viewPopoverAnchor, setViewPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [isbalanceWeightSuccessMessage, setbalanceWeightSuccessMessage] = useState<boolean>(false);
    const [isAssessmentWeightingModal, setIsAssessmentWeightingModal] = useState(false);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    useEffect(() => {
        if(campaignData){
            setCampaignTitle(campaignData.campaign.title);
            if(isTargetScore(globalSettings)){
                setTargetScoreEnabled(campaignData.campaign.targetScoreEnabled);
            } else {
                setTargetScoreEnabled(false);
            }
            const {
                progress,
                assessmentsInProgressCnt,
                assessmentsCompletedCnt,
                assessmentsCnt
            } = campaignData.campaign;
            setCampaignProgressdata({
                progress,
                assessmentsInProgressCnt,
                assessmentsCompletedCnt,
                assessmentsCnt
            });
        }
    }, [campaignData]);

    useEffect(() => {
        if (
            data &&
            data.assessment &&
            data.assessment.campaignEntity &&
            data.assessment.campaignEntity.name
        ) {
            setAssessmentName(data.assessment.campaignEntity.name);
        }
    }, [data]);

    useEffect(() => {
        if (frameworkSettings !== undefined &&
            frameworkSettings.frameworkSettingsByCampaign !== undefined &&
            frameworkSettings.frameworkSettingsByCampaign.length > 0) {
            setCompensatingControlEnabled(isCompensatingControlEnable(frameworkSettings.frameworkSettingsByCampaign));
        }
    }, [frameworkSettings]);

    const [updateDomainCapWeighting] = useMutation(UPDATE_DOMAIN_CAPABILITY_WEIGHT, {
        refetchQueries: [
            {
                query: CAMPAIGN_FRAMEWORK,
                variables: { campaignId, assessmentId }
            }
        ]
    });

    const handleOnSaveWeighting = (value: DomainWeight[],isPopUpOpen=false) => {
        const isValid = isDomainCapWeightValid(value);
        if (isValid) {
            setIsAssessmentWeightingError(false);
            setbalanceWeightSuccessMessage(false);
            updateDomainCapWeighting({
                variables: {
                    data: {
                        assessmentId,
                        domains: value.map(domain => {
                            return {
                                domainId: domain.id,
                                weighting: domain.value,
                                capabilities: domain.capability.map(cap => {
                                    return {
                                        capabilityId: cap.id,
                                        weighting: cap.value
                                    };
                                })
                            };
                        })
                    }
                }
            })
            .then(() => {
                if(isPopUpOpen)
                {
                    setbalanceWeightSuccessMessage(true);
                    return;
                }
                setIsAssessmentWeightingModal(false);
                setIsAssessmentWeightingSuccess(true);
            })
            .catch((error) => {
                console.log('error', error);
                setIsAssessmentWeightingModal(false);
                setIsAssessmentWeightingSuccess(false);
            });
        } else {
            setIsAssessmentWeightingError(true);
            setIsAssessmentWeightingSuccess(false);
        }
    };

    const campaignIsClosed = campaignData
        ? campaignData.campaign.closedAt !== null
        : false;

    // const campaignComplete = campaignData && campaignData.assessmentsCompletedCnt === campaignData.assessmentsCnt || false;
    const campaignComplete = data && data.assessment.state.status === 'COMPLETED' || false;

    const readOnlyAssessment = readOnlyEnabled && ( data && data.assessment && data.assessment.permission && data.assessment.permission === PermissionTypeEnum.READ_ONLY_ACCESS);
    const isReadOnly = campaignData ? campaignData.campaign.readOnly || readOnlyAssessment : false;

    const pointOneDecimalEnable = isPointOneDecimalEnable(globalSettings) && (campaignData && campaignData.campaign.pointOneDecimalEnable) || false;

    const isObjectiveComponentExist=data && data.frameworkByCampaign.definition.hasObjectiveComponents||false;

    const maturityTabsList = [
        {
            id: 1,
            label: 'Maturity overview',
            iconActive: iconMaturityOverviewActive,
            iconDisable: iconMaturityOverview
        },
    ];

    if (!(readOnlyEnabled && isReadOnly) || (readOnlyEnabled && isReadOnly && campaignComplete) || (readOnlyAssessment && campaignComplete)) {
        maturityTabsList.push(
            {
                id: 2,
                label: 'Maturity graph',
                iconActive: iconGraphActive,
                iconDisable: iconGraph
            },
            {
                id: 3,
                label: 'Domain average',
                iconActive: iconAverageActive,
                iconDisable: iconAverage
            }
        );
    }

    maturityTabsList.push(
                {
            id: 4,
            label: 'Details',
            iconActive: iconDetailsActive,
            iconDisable: iconDetails
        }
    );

    /*
    * Hide meetings from ReadOnly regardless of if completed
    */
    if (!readOnlyAssessment && (!(readOnlyEnabled && isReadOnly) || (readOnlyEnabled && !isReadOnly))) {
        maturityTabsList.push(
        {
            id: 5,
            label: 'Meetings',
            iconActive: eventNoteIcon,
            iconDisable: eventNoteIconWhite
        });
    }


    return loading ? (
        <SpinnerWithOverlay />

    ) : (
        <div>
            <AssessmentMaturityOverviewHeader
                assessmentName={assessmentName}
                campaignProgresData={campaignProgresData}
                campaignId={campaignId}
                campaignTitle={campaignTitle}
                campaignIsClosed={campaignIsClosed}
                isReadOnly={isReadOnly}
                readOnlyAssessment={readOnlyAssessment}
            />
            <Column>
                <Column>
                    <DropdownWrapper>
                        {campaignOverviewMenuWithTargetScore(
                            viewPopoverAnchor,
                            setViewPopoverAnchor,
                            showTargetScore,
                            setShowTargetScore,
                            showCompensationControl,
                            setShowCompensationControl,
                            targetScoreEnabled,
                            isLegendVisible,
                            setIsLegendVisible,
                            setCurrentTheme,
                            currentTheme,
                            compensatingControlEnabled,
                            view,
                            setView
                        )}
                    </DropdownWrapper>
                    <AlignItems>
                    {maturityTabsList.map(
                        (item: TabType, index: number, array: TabType[]) => {
                            return (
                                <Tab
                                    active={activeTab === item.id}
                                    handleClick={() => setActiveTab(item.id)}
                                    key={item.id}
                                    index={index}
                                    lastTab={
                                        maturityTabsList.length - 1 === index
                                    }
                                >
                                    <TabContentContainer
                                        isActive={activeTab === item.id}
                                    >
                                        <ImgContainer
                                            src={
                                                activeTab === item.id
                                                    ? item.iconActive
                                                    : item.iconDisable
                                            }
                                            alt={'icon'}
                                        />
                                        <Text
                                            style={{
                                                color: `${
                                                    activeTab === item.id
                                                        ? 'black'
                                                        : 'white'
                                                }`
                                            }}
                                        >
                                            {item.label}
                                        </Text>
                                    </TabContentContainer>
                                    {activeTab !== index + 1 &&
                                        index !== array.length - 1 &&
                                        index !== activeTab - 2 && (
                                            <VerticalLine />
                                        )}
                                </Tab>
                            );
                        }
                    )}
                    </AlignItems>

                </Column>

                <MainContentContainer>
                    {renderContent({
                        activeTab,
                        data,
                        campaignId,
                        assessmentId,
                        campaignTitle,
                        campaignIsClosed,
                        showTargetScore,
                        showCompensationControl,
                        isLegendVisible,
                        setIsLegendVisible,
                        view,
                        targetScoreEnabled,
                        isReadOnly: isReadOnly || (readOnlyAssessment || false),
                        campaignComplete,
                        pointOneDecimalEnable,
                        isObjectiveComponentExist,
                        handleOnSaveWeighting,
                        isAssessmentWeightingError,
                        isAssessmentWeightingSuccess,
                        setbalanceWeightSuccessMessage,
                        isbalanceWeightSuccessMessage,
                        setIsAssessmentWeightingModal,
                        isAssessmentWeightingModal,
                        compensatingControlEnabled
                    })}
                </MainContentContainer>
            </Column>
        </div>
    );
};

const TabContentContainer = styled('div')<{ isActive: boolean }>`
    padding: 3px;
    display: flex;
    align-items: center;
    height: 47px;
`;

const MainContentContainer = styled('div')`
    padding: 20px;
    border: solid 1px #d9d9d9;
    background: white;
`;

const DropdownWrapper = styled(JustifyContentEnd)`
    margin-bottom: -10px;
    button {
        margin-right: 0 !important;
    }
`;

export default AssessmentsMaturityOverview;
