import React, { useCallback, useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
    CustomTable,
    DialogBox,
    SpinnerWithOverlay
} from '../../../../components';

import EditMeetingModal from '../../Common/EditMeetingModal/EditMeetingModal';

import { UserStore } from '../../../../context/user-store';

import {
    // AssessmentsDropdown,
    AssessmentMeetings,
    CapabilityDropdown,
    // CapabilitiesWithDomain,
    Domain,
    Meeting,
    MeetingTableDataProps,
    Stakeholder,
    User,
    AssessmentsCapabilities,
    MeetingCreateProps,
    MeetingUpdateProps
} from './types';

import { fillAssessmentMeetings, headCells } from './MeetingNotesHelpers';

import { MeetingNotesHeader } from '../../Common';

const ASSESSMENT_MEETINGS = gql`
    query assessmentMeetings($assessmentId: ID!, $clientId: Int!) {
        assessmentMeetings(assessmentId: $assessmentId) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
        stakeholdersByClient(clientId: $clientId) {
            id
            firstName
            lastName
            status
        }
        usersByClient(clientId: $clientId) {
            id
            firstName
            lastName
        }
    }
`;

const ASSESSMENT_BY_ID = gql`
    query assessment6($id: ID!) {
        assessment(id: $id) {
            id
            state {
                domains {
                    id
                    name
                    capabilities {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const ARCHIVE_MEETING = gql`
    mutation archiveAssessmentMeeting($assessmentId: ID!, $meetingIds: [ID!]!) {
        archiveAssessmentMeeting(
            assessmentId: $assessmentId
            meetingIds: $meetingIds
        ) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

const UNARCHIVE_MEETING = gql`
    mutation unarchiveAssessmentMeeting($assessmentId: ID!, $meetingIds: [ID!]!) {
        unarchiveAssessmentMeeting(
            assessmentId: $assessmentId
            meetingIds: $meetingIds
        ) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

const UPDATE_ASSESSMENT_MEETINGS = gql`
    mutation updateAssessmentMeeting($data: UpdateAssessmentMeetingInput!) {
        updateAssessmentMeeting(data: $data) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

const CREATE_ASSESSMENT_MEETINGS = gql`
    mutation createAssessmentMeeting($data: CreateAssessmentMeetingInput!) {
        createAssessmentMeeting(data: $data) {
            id
            title
            creator
            archivedDate
            meetingDate
            capabilities {
                id
                name
            }
            notes {
                text
            }
            stakeholders {
                id
                firstName
                lastName
            }
            users {
                id
                firstName
                lastName
            }
        }
    }
`;

type MeetingNotesTypes = {
    assessmentId: string;
    currentCapabilityId: string;
    canEdit: boolean;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
};

const MeetingNotes: React.FC<MeetingNotesTypes> = ({
    assessmentId,
    currentCapabilityId,
    canEdit,
    readOnlyEnabled,
    isReadOnly
}) => {
    const [
        capabilitiesDropdownValues,
        setCapabilitiesDropdownValues
    ] = useState<CapabilityDropdown[]>([]);
    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [chosenField, setChosenField] = useState<Meeting | null>(null);
    const [meetingsTableData, setMeetingsTableData] = useState<
        MeetingTableDataProps[]
    >([]);
    const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [meetings, setMeetings] = useState<Meeting[]>([]);
    const [doArchive, setDoArchive] = useState<boolean>(true);
    const [notFilteredData, setNotFilteredData] = useState<Meeting[]>([]);
    const [editMeetingModalIsOpen, setEditMeetingModalIsOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>('1');
    const [disableAllSelect, setDisableAllSelect] = useState<boolean>(false);
    const {
        state: { client, user }
    } = useContext(UserStore);
    const {
        loading,
        data = {
            assessmentMeetings: [],
            stakeholdersByClient: [],
            usersByClient: []
        }
    } = useQuery<AssessmentMeetings>(ASSESSMENT_MEETINGS, {
        variables: {
            assessmentId,
            clientId: client && client.id
        }
    });
    const { data: capabilitiesData } = useQuery<AssessmentsCapabilities>(
        ASSESSMENT_BY_ID,
        {
            variables: {
                id: assessmentId
            }
        }
    );

    const [archiveAssessmentMeeting] = useMutation(ARCHIVE_MEETING, {
        // tslint:disable-next-line: no-shadowed-variable
        refetchQueries: [
            {
                query: ASSESSMENT_MEETINGS,
                variables: {
                    assessmentId,
                    clientId: client && client.id
                }
            }
        ]
    });

    const [unarchiveAssessmentMeeting] = useMutation(UNARCHIVE_MEETING, {
        // tslint:disable-next-line: no-shadowed-variable
        refetchQueries: [
            {
                query: ASSESSMENT_MEETINGS,
                variables: {
                    assessmentId,
                    clientId: client && client.id
                }
            }
        ]
    });

    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState<boolean>(false);

    const handleArchiveUnarchiveMeeting = () => {
        if (doArchive) {
            archiveAssessmentMeeting({
                    variables: {
                        assessmentId,
                        meetingIds: selectedRow
                    }
                })
                .then(() => {
                    setSelectedRow([]);
                    setIsDialogBoxOpen(false);
                    setEditMeetingModalIsOpen(false);
                })
                .catch(err => {
                    console.log('Archive assessment Error', err);
                });
        } else {
            unarchiveAssessmentMeeting({
                variables: {
                    assessmentId,
                    meetingIds: selectedRow
                }
            })
            // tslint:disable-next-line: no-identical-functions
            .then(() => {
                setSelectedRow([]);
                setIsDialogBoxOpen(false);
                setEditMeetingModalIsOpen(false);
            })
            .catch(err => {
                console.log('Archive assessment Error', err);
            });
        }
    };
    useEffect(() => {
        const capabilityDropdown: CapabilityDropdown[] = [];
        capabilitiesData &&
            capabilitiesData.assessment &&
            capabilitiesData.assessment.state &&
            capabilitiesData.assessment.state.domains &&
            capabilitiesData.assessment.state.domains.map((domain: Domain) => {
                capabilityDropdown.push({
                    id: 'title',
                    name: domain.name,
                    domain: ''
                });
                domain.capabilities.forEach((capability: CapabilityDropdown) =>
                    capabilityDropdown.push({
                        id: capability.id,
                        name: capability.name,
                        domain: domain.name
                    })
                );
            });
        setCapabilitiesDropdownValues(capabilityDropdown);
    }, [capabilitiesData]);

    useEffect(() => {
        if (data.usersByClient.length) {
            setUsers(data.usersByClient);
        }
    }, [data.usersByClient]);

    useEffect(() => {
        if (data.stakeholdersByClient.length) {
            const stakeholderData = data.stakeholdersByClient.filter((stakeholder: any) => stakeholder.status === 'ACTIVE');
            setStakeholders(stakeholderData);
        }
    }, [data.stakeholdersByClient]);

    const [assessmentMeeting, setAssessmentMeeting] = useState<Meeting[]>([]);

    useEffect(()=>{
        if(data && data.assessmentMeetings.length){
            setAssessmentMeeting(data.assessmentMeetings);
        }
    },[data]);

    useEffect(() => {
        if (assessmentMeeting.length > 0) {
            setNotFilteredData(assessmentMeeting);
            switch (Number(selectedValue)) {
                case 1:
                    setDoArchive(true);
                    setMeetings(assessmentMeeting.filter(
                        (item: Meeting) => item.archivedDate === null
                    ));
                    break;
                case 2:
                    setDoArchive(false);
                    setMeetings(assessmentMeeting.filter(
                        (item: Meeting) => item.archivedDate !== null
                    ));
                    break;
                default:
                    setMeetings(assessmentMeeting);
                    break;
            }
        }
    },[assessmentMeeting]);

    useEffect(() => {
        if (loading) {
            return undefined;
        }
        if (selectedValue) {
            switch (Number(selectedValue)) {
                case 1:
                    setDisableAllSelect(false);
                    break;
                case 2:
                    setDisableAllSelect(false);
                    break;
                default:
                    setDisableAllSelect(true);
                    break;
            }
        }
    }, [selectedValue]);

    const [isShowAllCapabilities, setIsShowAllCapabilities] = useState<string[]>([]);

    useEffect(() => {
        if (selectedRow.length === 0) {
            const updatedMeetings = meetings.map((meeting) => ({
               ...meeting, 
              ['disable']: false
            }));
                
            setMeetings(updatedMeetings);
        }
        if(meetings.length && selectedRow.length === 1 && selectedValue === '3') {
            const selectedMeeting = meetings.find((m) => m.id === selectedRow[selectedRow.length - 1]);
            // tslint:disable-next-line: no-redundant-boolean
            setDoArchive(selectedMeeting?.archivedDate ? false : true);
            const updatedMeetings = meetings.map((meeting) => ({
                // tslint:disable-next-line: no-redundant-boolean
                ...meeting, 
                ['disable']: selectedMeeting?.archivedDate 
                            ? meeting.archivedDate ? false : true : meeting.archivedDate ? true : false
            })); 
            setMeetings(updatedMeetings);
        }
    }, [selectedRow]);

    useEffect(() => {
        if (meetings) {
            const meetingsList: MeetingTableDataProps[] = fillAssessmentMeetings(
                meetings,
                stakeholders,
                setEditMeetingModalIsOpen,
                setChosenField,
                setDoArchive,
                false,
                setSelectedRow,
                setIsShowAllCapabilities,
                isShowAllCapabilities,
                canEdit
            );
            setMeetingsTableData(meetingsList);
        }
    }, [meetings, stakeholders, editMeetingModalIsOpen, isShowAllCapabilities]);

    const selectedHandler = (selected: string[]) => {
        setSelectedRow(selected);
    };

    const getTitle = () => {
        if (selectedRow.length > 1) {
            return `${selectedRow.length} meetings`;
        }
        if (selectedRow.length > 0) {
            const result = notFilteredData.find(
                (item: Meeting) => item.id === selectedRow[0]
            );
            return result ? result.title : '';
        }
        return '';
    };
    const [updateAssessmentMeeting] = useMutation<AssessmentMeetings>(
        UPDATE_ASSESSMENT_MEETINGS
    );
    const [createAssessmentMeeting] = useMutation<AssessmentMeetings>(
        CREATE_ASSESSMENT_MEETINGS
    );

    const [createAssessmentMeetingLoading, setCreateAssessmentMeetingLoading] = useState(false);

    useEffect(()=> {
        if(createAssessmentMeetingLoading) {
            setCreateAssessmentMeetingLoading(false);
        }

    }, [meetings.length]);

    const handleUpdateAssessmentMeeting = useCallback(
        (callbackData: MeetingUpdateProps) => {
            const {
                assessmentId,
                capabilities,
                id,
                meetingDate,
                notes,
                stakeholderIds,
                title,
                userIds
            } = callbackData;
            updateAssessmentMeeting({
                variables: {
                    data: {
                        assessmentId,
                        capabilities,
                        id,
                        meetingDate,
                        notes,
                        stakeholderIds,
                        title,
                        userIds
                    }
                }
            })
                .then(() => {
                    setChosenField(null);
                    setEditMeetingModalIsOpen(false);
                })
                .catch(error => console.error(error));
        },
        [updateAssessmentMeeting]
    );

    const handleCreateAssessmentMeeting = useCallback(
        (callbackData: MeetingCreateProps) => {
            setCreateAssessmentMeetingLoading(true);
            const {
                assessmentId,
                capabilities,
                meetingDate,
                notes,
                stakeholderIds,
                title,
                userIds
            } = callbackData;
            createAssessmentMeeting({
                variables: {
                    data:{
                        assessmentId,
                        capabilities,
                        meetingDate,
                        notes,
                        stakeholderIds,
                        title,
                        userIds
                    }
                },
                refetchQueries: [
                    {
                        query: ASSESSMENT_MEETINGS,
                        variables: {
                            assessmentId,
                            clientId: client && client.id
                        }
                    }
                ]
            })
                .then(thenData => {
                    setChosenField(null);
                    setEditMeetingModalIsOpen(false);
                    return thenData;
                })
                .catch(error => console.error(error));
        },
        [assessmentId, client, createAssessmentMeeting]
    );

    return loading || createAssessmentMeetingLoading ? (
        <SpinnerWithOverlay />
    ) : (
        <div>
            <CustomTable
                selectedHandler={selectedHandler}
                header={
                    <MeetingNotesHeader
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        setMeetingsTableData={setMeetings}
                        notFilteredData={notFilteredData}
                        selectedRow={selectedRow}
                        setEditMeetingModalIsOpen={setEditMeetingModalIsOpen}
                        setIsDialogBoxOpen={setIsDialogBoxOpen}
                        canEdit={canEdit}
                        setDoArchiveData={setDoArchive}
                        doArchiveData={doArchive}
                        setSelectedRow={setSelectedRow}
                    />
                }
                rows={meetingsTableData}
                headCells={headCells}
                selectedRows={selectedRow}
                selectAllDisabled={disableAllSelect}
            />

            <EditMeetingModal
                assessmentId={assessmentId}
                editMeetingModalIsOpen={editMeetingModalIsOpen && canEdit}
                setEditMeetingModalIsOpen={setEditMeetingModalIsOpen}
                setChosenField={setChosenField}
                meetingData={chosenField}
                stakeholders={stakeholders}
                domainsList={capabilitiesDropdownValues}
                doArchiveData={doArchive}
                users={users}
                user={user && `${user.firstName} ${user.lastName}`}
                handleCreateAssessmentMeeting={handleCreateAssessmentMeeting}
                handleUpdateAssessmentMeeting={handleUpdateAssessmentMeeting}
                handleArchiveUnarchiveMeeting={handleArchiveUnarchiveMeeting}
                currentCapabilityId={currentCapabilityId}
            />

            <DialogBox
                title={`Are you sure you want to ${doArchive ? 'archive' : 'unarchive'} “${getTitle()}” meeting?`}
                buttonCancelText="Cancel"
                buttonSaveText={doArchive ? 'Archive' : 'Unarchive'}
                content={`${doArchive ?
                    'Archived meetings can be accessed using the “Archived meetings” filter. These will not be included in any campaign reporting.' :
                    'Unarchive meetings can be accessed using the “Active meetings” filter.'}`}
                open={isDialogBoxOpen}
                handleClose={() => setIsDialogBoxOpen(false)}
                handleSubmit={handleArchiveUnarchiveMeeting}
            />
        </div>
    );
};

export default MeetingNotes;
