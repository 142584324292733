import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const TransparentButton = styled(Button)`
    && {
        display:flex;
        background-color:transparent;
        min-height: 48px;
        color: white;
        border: solid 1px white;
        min-width: 133px;
    }
`;

export default TransparentButton;
