import React, {
    useState,
    useEffect,
    useRef,
    ChangeEvent,
    MouseEvent
} from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, Collapse } from '@mui/material';

import ImgContainer from '../../base/ImgContainer';
import Menu from '../../Menu/index';
import Text from '../../base/Text/Text';
import AlignItems from '../../base/Layout/AlignItems';
import TextArea from '../../FormElements/TextArea';
import IconVerticalGrey from '../../../assets/images/icon-3-vertical-dots-grey.svg';
import { Action } from './types';
import { moveCaretAtEnd } from '../../../utils/UtilsHelpers';
import { wordBreak } from 'html2canvas/dist/types/css/property-descriptors/word-break';

type CardProps = {
    img: string;
    title: string;
    name: string;
    value: string;
    handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    isDisabled?: boolean;
    isMenuDisplay?: boolean;
    menuActions?: Action[];
    isEditable?: boolean;
    handleBlur?: any;
    handleClickProps?: (event: MouseEvent<HTMLDivElement>) => void;
};

const Card: React.FC<CardProps> = ({
    img,
    title,
    name,
    value,
    handleChange,
    isDisabled,
    isMenuDisplay,
    menuActions,
    isEditable,
    handleBlur,
    handleClickProps
}) => {
    const [isMenuOpen, setIsMenuOpenStatus] = useState<boolean>(false);
    const [isExpanded, setIsExpandedStatus] = useState<boolean>(false);
    const [contentFirstPart, setContent] = useState<string>('');
    const [isToggleShow, setIsToggleShowStatus] = useState<boolean>(false);
    const inputEl = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!(inputEl && inputEl.current && handleBlur)) {
            return undefined;
        }
        inputEl.current.style.height = '1px';
        inputEl.current.style.height = 25 + inputEl.current.scrollHeight + 'px';
    }, [inputEl, handleBlur]);

    useEffect(() => {
        handleUpdateCurrentContent();
    });

    const handleUpdateCurrentContent = () => {
        if (value.trim().length >= 250) {
            const trimmedString = value.trim().substr(0, 250);
            const currentString = trimmedString.substr(
                0,
                Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
            );
            setContent(currentString);
            setIsToggleShowStatus(true);
        } else {
            setIsToggleShowStatus(false);
            setContent(value.trim());
        }
    };

    return (
        <CardContainer>
            <CardHeader>
                <AlignItems>
                    <ImgContainer src={img} />
                    <Text color={'#828282'} size={'18px'} weight={'500'}>
                        {title}
                    </Text>
                </AlignItems>
                {isMenuDisplay ? (
                    <Menu
                        isMenuOpen={isMenuOpen}
                        isBoxShadowHide
                        setMenuStatus={setIsMenuOpenStatus}
                        top={'-2%'}
                        menuStyles={{
                            margin: '5px',
                            padding: '0'
                        }}
                        button={
                            <IconButtonContainer
                                onClick={() => {
                                    setIsMenuOpenStatus(true);
                                }}
                            >
                                <IconContainer
                                    opacity={isMenuOpen ? 1 : 0}
                                    src={IconVerticalGrey}
                                />
                            </IconButtonContainer>
                        }
                    >
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            style={{
                                paddingTop: 0,
                                paddingBottom: 0
                            }}>
                            {menuActions &&
                                menuActions.map(
                                    ({
                                        label,
                                        handleClick
                                    }: Action) => (
                                        <CapabilityListItem
                                            // button
                                            key={label}
                                            onClick={() => {
                                                setIsMenuOpenStatus(
                                                    false
                                                );
                                                handleClick();
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Text
                                                        color={'white'}
                                                        weight={'bold'}
                                                    >
                                                        {label}
                                                    </Text>
                                                }
                                            />
                                        </CapabilityListItem>
                                    )
                                )}
                        </List>
                    </Menu>
                ) : (
                    ''
                )}
            </CardHeader>
            <CardBody onClick={handleClickProps ? handleClickProps : () => {}}>
                {isEditable ? (
                    <TextArea
                        ref={inputEl}
                        isAutoFocus
                        border="none"
                        onFocus={moveCaretAtEnd}
                        isDisabled={isDisabled}
                        name={name}
                        handleChange={handleChange}
                        value={value}
                        handleBlur={() => {
                            handleBlur && handleBlur();
                            handleUpdateCurrentContent();
                        }}
                    />
                ) : (
                    <div>
                        <CardTextContent>
                            {isExpanded ? (
                                <Collapse
                                    in={isExpanded}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <TextContent color={'#4f4f4f'}>
                                        {value}
                                    </TextContent>
                                </Collapse>
                            ) : (
                                <TextContent color={'#4f4f4f'}>
                                    {contentFirstPart.trim()}
                                </TextContent>
                            )}
                        </CardTextContent>
                    </div>
                )}
            </CardBody>
            {!isEditable && isToggleShow ? (
                <CardToggleText
                    onClick={() => setIsExpandedStatus(state => !state)}
                    color={'#4588c3'}
                >
                    {isExpanded ? 'Hide' : 'Show more'}
                </CardToggleText>
            ) : (
                ''
            )}
        </CardContainer>
    );
};

Card.defaultProps = {
    isDisabled: false,
    isMenuDisplay: false,
    isEditable: true
};

const TextContent = styled(Text)`
    width: 100%;
    min-height: 85px;
    font-size: 14px;
    margin: 0;
    line-height: 22px;
    padding-bottom: 20px;
`;

const IconContainer = styled('img')<{ opacity?: number }>`
    opacity: ${props => (props.opacity ? props.opacity : '0')};
    width: 15px;
    padding: 6px;
`;

const IconButtonContainer = styled(AlignItems)`
    &:hover ${IconContainer} {
        opacity: 1;
    }
`;

const CapabilityListItem = styled(ListItem)`
    && {
        background-color: #2f2f2f;
        color: white;
        &:hover {
            background-color: #f8f8f8;
            > div span {
                color: #2f2f2f;
            }
        }
    }
`;

const CardHeader = styled('div')`
    border-bottom: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CardBody = styled('div')`
    display: flex;
    align-items: center;
    min-height: 85px;
`;

const CardTextContent = styled('div')`
    border: none;
    box-shadow: none;
    resize: none;
    width: auto;
    min-height: 85px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #4f4f4f;
    padding: 20px;
    margin: 0;
`;

const CardToggleText = styled(Text)`
    position: relative;
    padding: 10px;
    top: -10px;
    left: 20px;
    :hover {
        cursor: pointer;
    }
`;

const CardContainer = styled('div')`
    border: 1px solid #dadada;
    width: 100%;
    border-radius: 2px;
    margin-bottom: 20px;
    word-break: break-word;
`;

export default Card;
