import html2canvas from 'html2canvas';
import {
    CheckboxItemType,
    CampaignAssessments,
    Containers,
    FunctionType,
    FrameworkDomains,
    Pptxgen,
    StateCapabilities,
    StateDomains,
    Feedback,
    Observations,
    Recommendations,
    Pagination,
    SlideFunctions,
    Pages,
    tempData
} from './types';
import { barGraph } from '../../components/Charts/BarGraph/BarGraphHelpers';
import { logo, tempItems } from './ListData';
import {
    domainsAverage
} from '../Evaluate/MaturityOverview/MaturityOverviewTabs/MaturityOverviewTabsHelpers';
import PptxGenJS from 'pptxgenjs';
import { Dataset } from '../Analyse/Benchmarking/types';
import { Series } from '../../components/Charts/BarGraph/types';
import { NaLabels } from '../Analyse/Benchmarking/types';
import { colorForSeries } from '../../components/Charts/BarGraph/data';
import PieChart from '../../components/Charts/PieChart/PieChart';
import { roundFloor, colorsList, roundGraphScore, isHTML, htmlToString } from '../../utils';
import { CampaignGroupAssessment, MaturityOverviewDomain } from '../Evaluate/MaturityOverview/MaturityOverview/types';

let pptx = new PptxGenJS();
const CLIENT_ID = window.__ENV__.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const API_KEY = window.__ENV__.REACT_APP_GOOGLE_DRIVE_API_KEY;
const DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
];
const SCOPES = 'https://www.googleapis.com/auth/drive.file';
declare global {
    interface Window {
        gapi: any;
    }
}

export const handleClientLoad = (
    conts: Containers,
    checked: CheckboxItemType[],
    campaignAssessmentsData: CampaignGroupAssessment[],
    setLoading: any,
    isExport: boolean,
	pointOneDecimalEnable: boolean,
	clientName?: string
) => {
    if (!window.Blob) {
        alert(
            'Unsupported Browser\n\nSorry, but you will need a modern browser - (Chrome, Firefox, Edge, Opera) or IE11 - to use this feature.'
        );
        return;
    }
    // tslint:disable-next-line: no-floating-promises
    filters(conts, checked, campaignAssessmentsData, setLoading, isExport, pointOneDecimalEnable, clientName);
};

const signIn = () => {
    return window.gapi.auth2.getAuthInstance().signIn();
};

export const driveApi = async (setLoading: any) => {
    try {
        await new Promise<void>(resolve => {
            window &&
                window.gapi &&
                window.gapi.load &&
                window.gapi.load('client:auth2', () => {
                    window.gapi.client &&
                        window.gapi.client
                            .init({
                                apiKey: API_KEY,
                                clientId: CLIENT_ID,
                                scope: SCOPES,
                                discoveryDocs: DISCOVERY_DOCS,
                                plugin_name: 'Report'
                            })
                            .then(async () => {
                                const GoogleAuth =
                                    window.gapi.auth2 &&
                                    window.gapi.auth2.getAuthInstance &&
                                    window.gapi.auth2.getAuthInstance();
                                const issign = GoogleAuth.isSignedIn.get();
                                if (issign === false) {
                                    await signIn();
                                }
                                resolve();
                            })
                            .catch((x: string[]) => {
                                alert('Something Wrong');
                                setLoading(false);
                                console.log(x);
                            });
                });
        });
        return true;
    } catch (error) {
        return false;
    }
};

const filters = async (
    conts: Containers,
    checked: CheckboxItemType[],
    data: CampaignGroupAssessment[],
    setLoading: any,
    isExport: boolean,
	pointOneDecimalEnable: boolean,
	clientName?: string
) => {
    if (!isExport) {
        const connectionDrive = await driveApi(setLoading);
        if (!connectionDrive) {
            return;
        }
    }
    let pagination: Pagination = { pages: [], slide: [] };
    let contentSlide: SlideFunctions[] = [];
    const numberofSinglePage: any[] = [];
    checked.sort((a: CheckboxItemType, b: CheckboxItemType) => {
        return a.id - b.id;
    });
    await addMasterTitle(pptx);
    await addMasterDetail(pptx, data);
    await addMasterDetailSecond(pptx, data);
    await addMasterBottom(pptx, data);
    await addMasterTable(pptx, data);
    pptx.layout = 'LAYOUT_4x3';
    await initialSlide(data, clientName);
    const func: FunctionType = {
        0: () =>
            Promise.resolve(async () => {

                const content = await contentTableSlides(checked, data).then(x => (contentSlide = x));
                return content;
            }
            ),
        1: () =>
            Promise.resolve(
                slideTemplate(bsaSlideText).then(x =>
                    numberofSinglePage.push(x.slideNumber)
                )
            ),
        2: () =>
            Promise.resolve(
                slideTemplate(executiveSummaryText).then(x =>
                    numberofSinglePage.push((x.slideNumber))
                )
            ),
        3: () =>
            Promise.resolve(
                campaignOverview(conts, data, pointOneDecimalEnable).then(x => (pagination = x))
            )
    };
    await checked
        .reduce(async (accumulatorPromise: Promise<void>, x: CheckboxItemType) => {
            await accumulatorPromise;
            return func[x.id]();
        }, Promise.resolve())
        .then(() => {
            if (contentSlide.length) {
                contentsPagination(
                    contentSlide,
                    pagination,
                    numberofSinglePage.sort()
                );
                detailContentPagination(pagination);
            }
        });
    !isExport
        ? saveSlides(setLoading)
        : pptx.writeFile('Report.pptx').then(() => {
            setLoading(false);
            pptx = new PptxGenJS();
        });
};

const saveSlides = (setLoading: any) => {
    pptx.write('blob')
        .then(slide => {
            googleDriveSave(slide, setLoading);
        })
        .catch(x => {
            console.log(x);
        });
};

const GroupByEntity = (pag: Pagination) => {
    const mapData = new Map();
    pag.pages.forEach((item: Pages) => {
        const key = item.entityGroup;
        const collection = mapData.get(key);
        if (!collection) {
            mapData.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return mapData;
};

const contentsPagination = (
    slides: SlideFunctions[],
    pagination: Pagination,
    numberofSinglePage: number[]
) => {
    const mapData = GroupByEntity(pagination);
    let yaxis =
        (slides &&
            slides[0] &&
            slides[0].initialValue &&
            slides &&
            slides[0] &&
            slides[0].initialValue) ||
        8.6;
    yaxis = yaxis - (numberofSinglePage.length + 2);
    numberofSinglePage.forEach((x: number) => {
        ++yaxis;
        slides[0].addText(
            [
                {
                    text: x,
                    options: slideStyles.table_contentText
                }
            ],
            { x: 9.2, y: yaxis / 4 }
        );
    });
    ++yaxis;
    slides.forEach((sld: SlideFunctions, ind: number) => {
        if (ind !== 0) {
            yaxis = 0;
        }
        mapData.forEach((pages: Pages[], key: number) => {
            ++yaxis;
            if (yaxis > 24) {
                return;
            }
            sld.addText(
                [
                    {
                        text: pages[0].pageNumber,
                        options: slideStyles.table_contentText
                    }
                ],
                { x: 9.2, y: yaxis / 4 }
            );
            mapData.delete(key);
        });
    });
};

const detailContentPagination = (pagination: Pagination) => {
    const mapData = GroupByEntity(pagination);
    let yaxis = 12.0;
    let ind = 0;
    let sld = pagination.slide[ind];

    mapData.forEach((pages: Pages[], key: number) => {
        ++yaxis;
        pages.forEach((page: Pages, index: number) => {
            if (yaxis > 24) {
                index ? (yaxis = 0) : (yaxis = 2);
                ++ind;
                sld = pagination.slide[ind];
            }
            ++yaxis;
            sld.addText(
                [
                    {
                        text: page.pageNumber,
                        options: slideStyles.page_number
                    }
                ],
                { x: 9.2, y: yaxis / 4 }
            );
            mapData.delete(key);
        });
        ++yaxis;
    });
    ++yaxis;
};

export const allRenderItems = (
    data: StateDomains,
    i: number,
    objectiveScore: string[],
    pointOneDecimalEnable: boolean
) => {
    const dataSet: Dataset[] = [];
    let series: Series[] = [];
    const xData: string[] = [];
    let coorX = 0;
    const nalabels: NaLabels[] = [];

    data.capabilities.forEach((cp: StateCapabilities) => {
        dataSet.push({
            value: [
                coorX,
                roundGraphScore(
                    cp.inScope ? (cp.moderatedScore ? cp.moderatedScore : cp.averageScore) : null, // If descoped then mark as null
                    pointOneDecimalEnable
                ),
                cp.name
            ],
            itemStyle: {
                color: colorForSeries[i]
            }
        });
        xData.push(cp.name);
        coorX++;

        if (!cp.inScope) { // Bug 377 - Added a 'N/A' label for descoped capabilities.
            nalabels.push({
                value: 'N/A',
                xAxis: cp.name,
                yAxis: 0.15,
                label: {
                    color: colorsList[i],
                    backgroundColor: '#FFFFFF'
                }
            });
        }

    });
    series = [];
    series.push({
        name: '',
        type: 'bar',
        barWidth: 60,
        barCategoryGap: '5%',
        data: dataSet,
        symbolSize: 10,
        zlevel: 1,
        markPoint: {
            symbol: 'rect',
            symbolSize: 20,
            data: nalabels,
            itemStyle: {
                color: 'white',
                opacity: 1
            }
        },
    });
    const cont = document.createElement('div');
    cont.style.width = '1000px';
    cont.style.height = '500px';
    return barGraph(
        cont,
        [],
        false,
        objectiveScore,
        series,
        false,
        false,
        xData
    );
};

const screenshot = async (cont: HTMLElement, id: string, yaxis: number) => {
    let containerWidth;
    const container = document.getElementById('table');
    if (container) {
        containerWidth = container.offsetWidth;
    }
    const containerHeight = cont.offsetHeight;
    return html2canvas(cont, {
        onclone: documentClone => {
            const cloneMainElement = documentClone.getElementById(id);
            if (cloneMainElement && cloneMainElement.style) {
                cloneMainElement.style.display = 'grid';
                cloneMainElement.style.minWidth = 'fit-content';
            }
        },
        scale: 3,
        width: containerWidth,
        height: containerHeight,
        y: yaxis * 900
    })
        .then(canvas => {
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const height = (canvasHeight * 9) / canvasWidth;
            return {
                h: height,
                image: canvas.toDataURL('image/png')
            };
        })
        .catch((error: string) => {
            console.log(error);
            return null;
        });
};

const addMasterTitle = async (
    pptxMaster: Pptxgen,
) => {
    return pptxMaster.defineSlideMaster({
        title: 'COVER_SLIDE',
        bkgd: mastersStyles.cover_bkgd,
        objects: [
            { rect: mastersStyles.cover_recttop0 },
            { rect: mastersStyles.cover_recttop1 },
            { rect: mastersStyles.cover_recttop2 },
            { image: { x: 0.5, y: 6.0, w: 1.58, h: 1.2, data: logo } }
        ]
    });
};

const addMasterBottom = async (pptxMaster: Pptxgen, data: any[]) => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    return pptxMaster.defineSlideMaster({
        title: 'BOTTOM_COVER',
        objects: [
            {
                text: {
                    text: 'Strictly private and confidential',
                    options: mastersStyles.general_textAbovePWC
                }
            },
            {
                text: {
                    text: 'PwC',
                    options: mastersStyles.general_textOpt
                }
            },
            {
                line: {
                    x: 0.6,
                    y: 7.1,
                    w: 0.0,
                    h: 0.1,
                    line: 'D04A02',
                    lineSize: 1
                }
            },
            {
                text: {
                    text: data[0].assessments[0].campaign.title,
                    options: mastersStyles.general_campaignName
                }
            },
            {
                text: {
                    text: 'Draft',
                    options: mastersStyles.general_draft
                }
            },
            {
                text: {
                    text: (
                        date.getDate() +
                        '/' +
                        month +
                        '/' +
                        date.getFullYear()
                    ).toString(),
                    options: mastersStyles.general_dateOpt
                }
            }
        ],
        slideNumber: mastersStyles.slideNumber
    });
};

const addMasterDetail = async (pptxMaster: Pptxgen, data: any[]) => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    return pptxMaster.defineSlideMaster({
        title: 'DETAIL_COVER',
        bkgd: mastersStyles.detail_bkgd,
        objects: [
            { rect: mastersStyles.detail_rect },
            {
                text: {
                    text: 'Strictly private and confidential',
                    options: mastersStyles.general_textAbovePWC
                }
            },
            {
                text: {
                    text: 'PwC',
                    options: mastersStyles.general_textOpt
                }
            },
            {
                line: {
                    x: 0.6,
                    y: 7.1,
                    w: 0.0,
                    h: 0.1,
                    line: 'D04A02',
                    lineSize: 1
                }
            },
            {
                text: {
                    text: data[0].assessments[0].campaign.title,
                    options: mastersStyles.general_campaignName
                }
            },
            {
                text: {
                    text: 'Draft',
                    options: mastersStyles.general_draft
                }
            },
            {
                text: {
                    text: (
                        date.getDate() +
                        '/' +
                        month +
                        '/' +
                        date.getFullYear()
                    ).toString(),
                    options: mastersStyles.general_dateOpt
                }
            }
        ],
        slideNumber: mastersStyles.slideNumber
    });
};

const addMasterDetailSecond = async (pptxMaster: Pptxgen, data: any[]) => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });

    return pptxMaster.defineSlideMaster({
        title: 'DETAIL_COVER_SECOND',
        bkgd: mastersStyles.detail_bkgd,
        objects: [
            {
                text: {
                    text: 'Strictly private and confidential',
                    options: mastersStyles.general_textAbovePWC
                }
            },
            {
                text: {
                    text: 'PwC',
                    options: mastersStyles.general_textOpt
                }
            },
            {
                line: {
                    x: 0.6,
                    y: 7.1,
                    w: 0.0,
                    h: 0.1,
                    line: 'D04A02',
                    lineSize: 1
                }
            },
            {
                text: {
                    text: data[0].assessments[0].campaign.title,
                    options: mastersStyles.general_campaignName
                }
            },
            {
                text: {
                    text: 'Draft',
                    options: mastersStyles.general_draft
                }
            },
            {
                text: {
                    text: (
                        date.getDate() +
                        '/' +
                        month +
                        '/' +
                        date.getFullYear()
                    ).toString(),
                    options: mastersStyles.general_dateOpt
                }
            }
        ],
        slideNumber: mastersStyles.slideNumber
    });
};

const addMasterTable = async (pptxMaster: Pptxgen, data: any[]) => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    return pptxMaster.defineSlideMaster({
        title: 'TABLE_COVER',
        objects: [
            {
                text: {
                    text: 'Index',
                    options: {
                        x: 0.5,
                        y: 1.0,
                        fontSize: 11,
                        bold: true,
                        fontFace: 'Arial',
                        color: 'd04a02'
                    }
                }
            },
            {
                text: {
                    text: 'Observations',
                    options: {
                        x: 1.5,
                        y: 1.0,
                        fontSize: 11,
                        bold: true,
                        fontFace: 'Arial',
                        color: 'd04a02'
                    }
                }
            },
            {
                text: {
                    text: 'Recommendations',
                    options: {
                        x: 5.5,
                        y: 1.0,
                        fontSize: 11,
                        bold: true,
                        fontFace: 'Arial',
                        color: 'd04a02'
                    }
                }
            },
            {
                text: { text: 'PwC', options: mastersStyles.general_textOpt }
            },
            {
                line: {
                    x: 0.5,
                    y: 1.3,
                    w: 9,
                    h: 0.0,
                    line: 'D04A02',
                    lineSize: 3
                }
            },
            {
                text: {
                    text: 'Strictly private and confidential',
                    options: mastersStyles.general_textAbovePWC
                }
            },
            {
                line: {
                    x: 0.6,
                    y: 7.1,
                    w: 0.0,
                    h: 0.1,
                    line: 'D04A02',
                    lineSize: 1
                }
            },
            {
                text: {
                    text: data[0].assessments[0].campaign.title,
                    options: mastersStyles.general_campaignName
                }
            },
            {
                text: {
                    text: 'Draft',
                    options: mastersStyles.general_draft
                }
            },
            {
                text: {
                    text: (
                        date.getDate() +
                        '/' +
                        month +
                        '/' +
                        date.getFullYear()
                    ).toString(),
                    options: mastersStyles.general_dateOpt
                }
            }
        ],
        slideNumber: mastersStyles.slideNumber
    });
};

const initialSlide = async (
    data: any[],
    clientName?: string
) => {
    window.scrollTo(0, 0);
    const slide: SlideFunctions[] = [];
    slide[0] = pptx.addSlide({masterName:'COVER_SLIDE'});
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    let headerTitle = '';
    let headerDate = '';

    if (data[0] &&
        data[0].assessments[0] &&
        data[0].assessments[0].campaign &&
        clientName) {
        headerTitle = clientName + '\n' + data[0].assessments[0].campaign.title;
    }
    slide[0].addText([
        {
            text: headerTitle,
            options: slideStyles.cover_header
        }
    ],
        { x: 0.5, y: 0.5, w: 5.5, h: 2.25, }
    );

    headerDate = (
        date.getDate() +
        ' ' +
        month +
        ' ' +
        date.getFullYear()
    ).toString();

    slide[0].addText('Project description', slideStyles.cover_projectDesc);
    slide[0].addText(headerDate, slideStyles.cover_projectDate);
    slide[0].addText('Draft', slideStyles.cover_smallText);
    slide[0].addText('Strictly private and confidential', slideStyles.cover_smallText_2);
    return slide;
};

const contentTableSlides = async (
    checked: CheckboxItemType[],
    data: any[]
) => {
    let yCoordinate = 5.6;
    const slide: SlideFunctions[] = [];
    let slidecount = 0;
    slide[slidecount] = pptx.addSlide({masterName:'BOTTOM_COVER'});
    slide[slidecount].addText('Contents', slideStyles.title_text);
    const lng = checked.length;
    for (let idx = 1; idx < lng; idx++) {
        if (yCoordinate > 24) {
            ++slidecount;
            slide[slidecount] = pptx.addSlide({masterName:'BOTTOM_COVER'});
            yCoordinate = 1;
        }
        slide[slidecount].addText(
            [
                {
                    text: checked[idx].name,
                    options: slideStyles.table_contentText
                }
            ],
            { x: 0.5, y: yCoordinate / 4 }
        );
        if (checked[idx].id === 3) {
            data &&
                data.map((x: CampaignAssessments, index: number) => {
                    ++yCoordinate;
                    if (index === 0) {
                        slide[0].initialValue = yCoordinate;
                    }
                    if (yCoordinate > 24) {
                        ++slidecount;
                        slide[slidecount] = pptx.addSlide({masterName: 'BOTTOM_COVER'});
                        yCoordinate = 1;
                    }
                    slide[slidecount].addText(
                        [
                            {
                                text: index + 1 + '  ' + x.groupName,
                                options: slideStyles.table_subcontentText
                            }
                        ],
                        { x: 0.5, y: yCoordinate / 4 }
                    );
                });
            yCoordinate++;
        }
        yCoordinate++;
    }
    return slide;
};

const tempItemsIteration = (partY: number, slide: PptxGenJS.Slide) => {
    tempItems.forEach((x: tempData, index: number) => {
        slide.addText(x.text, {
            align: 'left',
            color: '000000',
            fontSize: 11,
            fontFace: 'Arial',
            x: x.xaxis,
            y: partY + (13 * index) / 40,
            bullet: { code: x.bullet }
        });
    });
};

const bsaSlideText = [
    'Background, Scope and Approach',
    'Background and Scope',
    'Assessment Framework'
];

const executiveSummaryText = [
    'Executive Summary',
    'Key Observations & Themes',
    'Key Improvement Opportunities'
];

const slideTemplate = async (textArray: string[]) => {
    const slide =pptx.addSlide({masterName:'BOTTOM_COVER'});
    slide.addText(textArray[0], slideStyles.title_text);
    slide.addText(textArray[1], slideStyles.subtitle_textLvl1);
    tempItemsIteration(1.4, slide);
    slide.addText(textArray[2], slideStyles.subtitle_textLvl2);
    tempItemsIteration(4.2, slide);

    return slide;
};

const campaignOverview = async (
    cont: Containers,
	data: CampaignGroupAssessment[],
	pointOneDecimalEnable: boolean
) => {
    const assessmentsCount = (
        await Promise.all(
            data.map((dt: CampaignGroupAssessment) => dt.assessments).flat()
        )
    ).length;
    const seperateCount = assessmentsCount / 12;
    for (let i = 0; i < seperateCount; i++) {
        const slide = pptx.addSlide({masterName:'BOTTOM_COVER'});
        const maturityScreen: any = await screenshot(
            cont.matCont,
            'MaturityCont',
            i
        );
        const height = maturityScreen && maturityScreen.h < 6.4 ? maturityScreen.h : 6.4;
        slide.addImage({
            data: maturityScreen && maturityScreen.image,
            x: 0.5,
            y: 0.5,
            w: 9.0,
            h: height
        });
    }
    await domainAverageGraph(data, pointOneDecimalEnable);
    /**
     * @todo  reports disabled for read-only, if restored, then we need to effect thesee
     */
    const domainAverageScore = domainsAverage(data, false, false);
    const tableRows: string[] = [];
    const tableTitleRows: any = [
        ['', 'Observations', 'Recommendations', 'Maturity Score']
    ];
    const table: any =
        data &&
        data[0].assessments[0] &&
        data[0].assessments[0].state &&
        data[0].assessments[0].state.domains.map((x: MaturityOverviewDomain) => {
            const dmnscore = domainAverageScore.find(a => a.Domain === x.name);
            const roundedDomainScore = roundGraphScore(
                dmnscore && dmnscore.Score && dmnscore.Score !== null
                    ? dmnscore.Score
                    : null,
                pointOneDecimalEnable
            );
            return [
                ...tableRows,
                x.name,
                'Lorem ipsum',
                'Lorem ipsum',
                (roundedDomainScore &&
                    parseFloat(roundedDomainScore.toFixed(2)).toString()) ||
                'No Score'
            ];
        });
    const slideTableExecutiveOverview = pptx.addSlide({masterName:'BOTTOM_COVER'});
    slideTableExecutiveOverview.addText(
        'Executive Overview',
        slideStyles.gen_title_options
    );
    slideTableExecutiveOverview.addTable(
        tableTitleRows,
        slideStyles.executiveOverviewTitle
    );
    slideTableExecutiveOverview.addShape(
        pptx.ShapeType.line,
        slideStyles.shapes_line_executive_table
    );
    slideTableExecutiveOverview.addTable([table[0]], {
        x: 0.5,
        y: 1.8,
        colW: [1.0, 3.3, 3.3, 1.4],
        margin: [5, 0, 10, 0],
        fontFace: 'Arial',
        fontSize: 9,
        border: [
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' }
        ]
    });
    const lng = table[0][0].length;
    const yaxis = Math.floor(lng / 12) * 0.2 + 0.4;
    table.shift();
    if (table.length > 0) {
        slideTableExecutiveOverview.addTable(table, {
            x: 0.5,
            y: 1.8 + yaxis,
            colW: [1.0, 3.3, 3.3, 1.4],
            margin: [5, 0, 10, 0],
            autoPage: true,
            fontFace: 'Arial',
            fontSize: 9,
            border: [
                { color: '000000', pt: 1 },
                { color: 'FFFFFF' },
                { color: '000000', pt: 1 },
                { color: 'FFFFFF' }
            ]
        });
    }
    return detailSectionCover(data, pointOneDecimalEnable);
};

const domainAverageGraph = async (data: CampaignGroupAssessment[], pointOneDecimalEnable = false) => {
    const dataSet: any[] = [];
    const domainsWithAverage = domainsAverage(data);
    const lng = domainsWithAverage.length;
    domainsWithAverage.forEach(dmn => {
        dataSet.push({
            score: dmn.Score !== 0 && dmn.Score && dmn.Score > 0.5
                ? roundFloor(dmn.Score, pointOneDecimalEnable)
                : 0,
            name: dmn.Domain
        });
    });
    const cont = document.createElement('div');
    cont.style.width = '1200px';
    cont.style.height = '600px';
    const graph = PieChart({
        scoresList: dataSet,
        maxScoreRange: 5,
        minScoreRange: 0,
        sectorSize: 100 / lng,
        bottomDomain: lng % 2 > 0 ? (lng / 2 - 0.5) : -1,
        colorsList,
        saveAsImageText: '',
        cont,
        showSaveImage: false
    }

    );
    const domainGraphSlide = pptx.addSlide({masterName:'BOTTOM_COVER'});
    domainGraphSlide.addText(
        'Maturity Overview by Domain',
        slideStyles.domain_average_graph_title
    );
    const snapshotImage = new Image();
    snapshotImage.src = graph.getDataURL();
    domainGraphSlide.addImage({
        data: snapshotImage.src,
        x: 1.0,
        y: 1.7,
        w: 8.0,
        h: 4
    });
    return true;
};

const detailSectionCover = async (data: any[], pointOneDecimalEnable: boolean) => {
    let yCoordinate = 12.0;
    const slideDetail: SlideFunctions[] = [];
    let slidecount = 0;
    slideDetail[slidecount] = pptx.addSlide({masterName:'DETAIL_COVER'});
    slideDetail[slidecount].addText(
        'Assessment maturity results',
        slideStyles.detail_text
    );
    data.forEach(ass => {
        if (yCoordinate > 24) {
            ++slidecount;
            slideDetail[slidecount] = pptx.addSlide({masterName:'DETAIL_COVER_SECOND'});
            yCoordinate = 1.0;
        }
        slideDetail[slidecount].addText(
            [
                {
                    text: '\n' + ass.groupName,
                    options: slideStyles.detail_subcontentText
                }
            ],
            { x: 1.5, y: yCoordinate / 4 }
        );
        ++yCoordinate;
        ass.assessments.forEach((x: { campaignEntity: { name: string; }; }, index: number) => {
            if (yCoordinate > 24) {
                ++slidecount;
                slideDetail[slidecount] = pptx.addSlide({masterName:'DETAIL_COVER_SECOND'});
                yCoordinate = 1.0;
            } else {
                ++yCoordinate;
            }

            slideDetail[slidecount].addText(
                [
                    {
                        text: index + 1 + ') ' + x.campaignEntity.name,
                        options: slideStyles.detail_subcontentText
                    }
                ],
                { x: 1.5, y: yCoordinate / 4 }
            );
        });
        yCoordinate++;
    });
    return detailedSection(data, slideDetail, pointOneDecimalEnable);
};

const detailedSection = async (
    data: CampaignAssessments[],
	slideDetail: SlideFunctions[],
	pointOneDecimalEnable: boolean
) => {
    const tableRows: string[] = [];
    const domainTableTitleRows: any = [
        ['Domain Name', 'Observations', 'Recommendations']
    ];
    const capabilitiesTableTitleRows: any = [
        ['Index', 'Observations', 'Recommendations']
    ];
    const parseText = (text: string): string => {
        if (isHTML(text)) {
            return htmlToString(text, true);
        }
        return text;
    };
    let entityname: string;
    const pagination: Pagination = { pages: [], slide: [] };
    pagination.slide = slideDetail;
    if((data[0].assessments.length === 1)){
        const table: any =  data && data[0].assessments[0] && data[0].assessments[0].state && data[0].assessments[0].state.domains.map((x: StateDomains) => {
            const domainObservation = x.feedback.map((fdb: any) => fdb.observations && fdb.observations.map((obs: any)=> parseText(obs.text))).flatMap(arr => arr).join(',');
            const domainRecommendation = x.feedback.map((fdb: any) => fdb.recommendations && fdb.recommendations.map((obs: any)=> parseText(obs.text))).flatMap(arr => arr).join(',');
            return [
                ...tableRows,
                x.name,
                domainObservation,
                domainRecommendation,
               
            ];
        });
        const firstRow = table[0];
        const lng = table[0][1].length > table[0][2].length ? table[0][1].length: table[0][2].length;
        const yaxis = Math.floor(lng / 35) * 0.2 + 0.35;
        table.shift();
        data &&
        data.forEach(x => {
            x.assessments.forEach(ass => {
                entityname = ass.campaignEntity.name;
        const slideTableEntity = pptx.addSlide({masterName: 'BOTTOM_COVER'});
        pagination.pages.push({
            entityGroup: x.groupName,
            pageNumber: slideTableEntity.slideNumber
        });
        slideTableEntity.addText(
            'Assessment maturity results - ' + entityname,
            slideStyles.gen_title_options
        );
        slideTableEntity.addTable(
            domainTableTitleRows,
            slideStyles.maturityOverviewTitle
        );
        slideTableEntity.addShape(
            pptx.ShapeType.line,
            slideStyles.shapes_line_executive_table
        );
        slideTableEntity.addTable([firstRow], {
            x: 0.5,
            y: 1.8,
            colW: [3.0, 3.0, 3.0],
            margin: 6,
            fontFace: 'Arial',
            fontSize: 9,
            border: [
                { color: 'FFFFFF' },
                { color: 'FFFFFF' },
                { color: 'FFFFFF' },
                { color: 'FFFFFF' }
            ]
        });
        if (table.length > 0) {
            slideTableEntity.addTable(table, {
                x: 0.5,
                y: 1.8 + yaxis,
                colW: [3.0, 3.0, 3.0],
                margin: 6,
                autoPage: true,
                fontFace: 'Arial',
                fontSize: 9,
                border: [
                    { color: '000000', pt: 1 },
                    { color: 'FFFFFF' },
                    { color: '000000', pt: 1 },
                    { color: 'FFFFFF' }
                ]
            });
        }
            });
        });
        
    }
    data &&
        data.forEach(x => {
            x.assessments.forEach(ass => {
                if((data[0].assessments.length > 1)){
                const table: any =  ass.state.domains.map((x: StateDomains) => {
                    const domainObservation = x.feedback.map((fdb: any) => fdb.observations && fdb.observations.map((obs: any)=> parseText(obs.text))).flatMap(arr => arr).join(',');
                    const domainRecommendation = x.feedback.map((fdb: any) => fdb.recommendations && fdb.recommendations.map((obs: any)=> parseText(obs.text))).flatMap(arr => arr).join(',');
                    return [
                        ...tableRows,
                        x.name,
                        domainObservation,
                        domainRecommendation,
                       
                    ];
                });
                const firstRow = table[0];
                const lng = table[0][1].length > table[0][2].length ? table[0][1].length: table[0][2].length;
                const yaxis = Math.floor(lng / 35) + 1.35 * 0.2 ;
                table.shift();
                entityname = ass.campaignEntity.name;
                const slideTableEntity = pptx.addSlide({masterName: 'BOTTOM_COVER'});
                pagination.pages.push({
                    entityGroup: x.groupName,
                    pageNumber: slideTableEntity.slideNumber
                });
                slideTableEntity.addText(
                    'Assessment maturity results - ' + entityname,
                    slideStyles.gen_title_options
                );
                slideTableEntity.addTable(
                    domainTableTitleRows,
                    slideStyles.maturityOverviewTitle
                );
                slideTableEntity.addShape(
                    pptx.ShapeType.line,
                    slideStyles.shapes_line_executive_table
                );
                slideTableEntity.addTable([firstRow], {
                    x: 0.5,
                    y: 1.8,
                    colW: [3.0, 3.0, 3.0],
                    margin: 6,
                    fontFace: 'Arial',
                    fontSize: 9,
                    border: [
                        { color: 'FFFFFF' },
                        { color: 'FFFFFF' },
                        { color: 'FFFFFF' },
                        { color: 'FFFFFF' }
                    ]
                });
                if (table.length > 0) {
                    slideTableEntity.addTable(table, {
                        x: 0.5,
                        y: 1.8 + yaxis ,
                        colW: [3.0, 3.0, 3.0],
                        margin: 6,
                        autoPage: true,
                        fontFace: 'Arial',
                        fontSize: 9,
                        border: [
                            { color: '000000', pt: 1 },
                            { color: 'FFFFFF' },
                            { color: '000000', pt: 1 },
                            { color: 'FFFFFF' }
                        ]
                    });
                }
            }
                ass.state.domains.forEach(
                    async (sd: StateDomains, ind: number) => {
                        const objectiveScore = ass.framework.definition.scores;
                        const myChart = allRenderItems(sd, ind, objectiveScore, pointOneDecimalEnable);
                        const domainSlide = pptx.addSlide({masterName:'BOTTOM_COVER'});
                        domainSlide.addText(
                            entityname.toUpperCase() + ' - ' + sd.name,
                            slideStyles.gen_title_options
                        );
                        domainSlide.addText(
                            'Maturity Score :' +
                            (sd.moderatedAverageScore === null
                                ? 'No Score'
                                : roundGraphScore(
                                    sd.moderatedAverageScore
                                )),
                            slideStyles.score_options
                        );
                        const snapshotImage = new Image();
                        snapshotImage.src = myChart.getDataURL();
                        domainSlide.addImage({
                            data: snapshotImage.src,
                            x: 1.0,
                            y: 1.2,
                            w: 7.0,
                            h: 4
                        });
                        domainSlide.addText(
                            'Observation',
                            slideStyles.domain_obs
                        );
                        domainSlide.addShape(
                            pptx.ShapeType.line,
                            slideStyles.shapes_line_obs
                        );
                        let observationsSizeY = slideStyles.text_body_obs.y ;
                        sd.feedback.map((fdb: any, i: number) => {
                            observationsSizeY = observationsSizeY+ 0.2;
                        fdb.observations.length > 0 && fdb.observations.map((obsData: Observations, index : number) =>{
                            const style =  {
                                x: 1 ,
                                y: ( i > 0 ? observationsSizeY  : slideStyles.text_body_obs.y ),
                                w: 3.5,
                                autoPage: true,
                                fontSize: 11,
                                fontFace: 'Arial'
                            };
                        domainSlide.addText(
                            parseText(obsData.text),
                            style,
                        );
                    });
                       });
                        
                        domainSlide.addText(
                            'Recommendation',
                            slideStyles.domain_rec
                        );
                        domainSlide.addShape(
                            pptx.ShapeType.line,
                                slideStyles.shapes_line_rec
                        );
                        let recommendationsSizeY = slideStyles.text_body_rec.y ;
                        sd.feedback.map((fdb: any, i: number) => {
                            recommendationsSizeY = recommendationsSizeY + 0.2;
                            fdb.recommendations.length > 0 && fdb.recommendations.map((recommData: Recommendations, index: number) =>{
                                const style =  {
                                    x: 5.5,
                                    y: ( i > 0 ? recommendationsSizeY  : slideStyles.text_body_rec.y ),
                                    w: 3.5,
                                    fontSize: 11,
                                    autoPage: true,
                                    fontFace: 'Arial'
                                };
                            domainSlide.addText(
                                parseText(recommData.text),
                               style
                            );
                        });
                           });
                   
                        sd.capabilities.forEach((sc: StateCapabilities) => {
                            const capabilityTable = pptx.addSlide({masterName:
                                'BOTTOM_COVER'
                        });
                            capabilityTable.addText(
                                entityname.toUpperCase() + ' - ' + sd.name + '\n' + sc.name,
                                slideStyles.domain_tableTitle
                            );
                            capabilityTable.addText(
                                'Maturity Score :' +
                                (sc.moderatedScore === null
                                    ? 'No Score'
                                    : ((sc.inScope) ? roundGraphScore(sc.moderatedScore, pointOneDecimalEnable) : 'N/A')),
                                slideStyles.score_options_2
                            );
                            capabilityTable.addTable(
                                capabilitiesTableTitleRows,
                                slideStyles.capabilities_table_TitleText
                            );
                            let feedbackRowsFirst: any[] = [];
                            let feedbackRows: any[] = [];
                            let firstPage = true;
                            let obs = '';
                            let rec = '';
                            let obsLength = 0;
                            let recLength = 0;
                            feedbackRows = [];
                            feedbackRowsFirst = [];
                            let obsSlice: string;
                            let recSlice: string;
                            sc.feedback &&
                                sc.feedback.map(
                                    (fdb: Feedback, index: number) => {
                                        obs = '';
                                        rec = '';
                                        fdb.observations &&
                                            fdb.observations.map(
                                                (o: Observations, i: number) =>
                                                    o.text !== ''
                                                        ? obs =
                                                        obs +
                                                        (
                                                            index + 1
                                                        ).toString() +
                                                        '.' +
                                                        (
                                                            i + 1
                                                        ).toString() +
                                                        ') ' +
                                                        ((sc.inScope) ? parseText(o.text) : 'This is a Descoped Observation.') +
                                                        ((i + 1 <
                                                            fdb.observations
                                                                .length)
                                                            ? '\n\n' : '')

                                                        : null
                                            );

                                        fdb.recommendations &&
                                            fdb.recommendations.map(
                                                (
                                                    r: Recommendations,
                                                    i: number
                                                ) =>
                                                    r.text !== ''
                                                        ? rec =
                                                        rec +
                                                        (
                                                            index + 1
                                                        ).toString() +
                                                        '.' +
                                                        (
                                                            i + 1
                                                        ).toString() +
                                                        ') ' +
                                                        ((sc.inScope) ? parseText(r.text) : 'This is a Descoped Recommendation.') +
                                                        ((i + 1 <
                                                            fdb.recommendations
                                                                .length) ? '\n\n' : '')

                                                        : null
                                            );
                                        obsLength = obsLength + obs.length;
                                        recLength = recLength + rec.length;
                                        if (firstPage) {
                                            if (
                                                obsLength <= 900 &&
                                                recLength <= 900
                                            ) {
                                                feedbackRowsFirst.push([
                                                    {
                                                        text: (
                                                            index + 1
                                                        ).toString(),
                                                        options: {
                                                            color: '000000',
                                                            bold: true
                                                        }
                                                    },
                                                    obs,
                                                    rec
                                                ]);
                                            } else {
                                                obsSlice = obs;
                                                recSlice = rec;
                                                if (obsLength > 900) {
                                                    const indexOfFirst = obs
                                                        .substring(900)
                                                        .indexOf(' ');
                                                    obsSlice = obs.substring(
                                                        0,
                                                        900 + indexOfFirst
                                                    );
                                                    obs = obs.substring(
                                                        900 + indexOfFirst + 1
                                                    );
                                                } else {
                                                    obs = '';
                                                }
                                                if (recLength > 900) {
                                                    const indexOfFirst = rec
                                                        .substring(900)
                                                        .indexOf(' ');
                                                    recSlice = rec.substring(
                                                        0,
                                                        900 + indexOfFirst
                                                    );
                                                    rec = rec.substring(
                                                        900 + indexOfFirst + 1
                                                    );
                                                } else {
                                                    rec = '';
                                                }
                                                feedbackRowsFirst.push([
                                                    {
                                                        text: (
                                                            index + 1
                                                        ).toString(),
                                                        options: {
                                                            color: '000000',
                                                            bold: true
                                                        }
                                                    },
                                                    obsSlice,
                                                    recSlice
                                                ]);
                                                feedbackRows.push([
                                                    {
                                                        text:
                                                            index === 0
                                                                ? ''
                                                                : (
                                                                    index + 1
                                                                ).toString(),
                                                        options: {
                                                            color: '000000',
                                                            bold: true
                                                        }
                                                    },
                                                    obs,
                                                    rec
                                                ]);
                                            }
                                            firstPage = false;
                                        } else {
                                            feedbackRows.push([
                                                {
                                                    text: (
                                                        index + 1
                                                    ).toString(),
                                                    options: {
                                                        color: '000000',
                                                        bold: true
                                                    }
                                                },
                                                obs,
                                                rec
                                            ]);
                                        }
                                    }
                                );
                            firstPage = true;
                            if (feedbackRowsFirst.length === 0) {
                                feedbackRowsFirst.push([
                                    {
                                        text: '',
                                        options: { color: '000000', bold: true }
                                    },
                                    '',
                                    ''
                                ]);
                            }
                            capabilityTable.addTable(feedbackRowsFirst, {
                                x: 0.5,
                                y: 2.4,
                                margin: [0, 5, 10, 5],
                                colW: [1.0, 4.0, 4.0],
                                fontSize: 9,
                                fontFace: 'Arial',
                                border: [
                                    { color: 'FFFFFF' },
                                    { color: 'FFFFFF' },
                                    { color: 'FFFFFF' },
                                    { color: 'FFFFFF' }
                                ]
                            });
                            capabilityTable.addShape(
                                pptx.ShapeType.line,
                                slideStyles.shapes_line_table
                            );
                            if (feedbackRows.length > 0) {
                                const capabilityTableSecond = pptx.addSlide({masterName:
                                    'TABLE_COVER'
                            });
                                capabilityTableSecond.addTable(feedbackRows, {
                                    newSlideStartY: 1.4,
                                    x: 0.5,
                                    y: 1.4,
                                    margin: [0, 5, 10, 5],
                                    colW: [1.0, 4.0, 4.0],
                                    autoPage: true,
                                    fontSize: 9,
                                    fontFace: 'Arial',
                                    border: [
                                        { color: 'FFFFFF' },
                                        { color: 'FFFFFF' },
                                        { color: 'FFFFFF' },
                                        { color: 'FFFFFF' }
                                    ]
                                });
                            }
                            feedbackRows = [];
                            feedbackRowsFirst = [];
                        });
                    }
                );
            });
        });
    return pagination;
};

export const googleDriveSave = async(
    slide: any,
    setLoading: any,
    ReportName?: string
) => {
    const file = slide;
    const metadata = {
        name: ReportName ? ReportName : 'Report',
        mimeType:
            'application/vnd.google-apps.presentation'
    };
    const accessToken =
        window.gapi.auth.getToken &&
        window.gapi.auth.getToken().access_token &&
        window.gapi.auth.getToken().access_token;
    const form = new FormData();
    form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], { type: 'application/json' })
    );

    form.append('file', new Blob([file], {type: 'application/vnd.ms-powerpoint'}));
    const xhr = new XMLHttpRequest();
    xhr.open(
        'post',
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id'
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.responseType = 'json';
    xhr.send(form);
    xhr.onload = () => {
        alert('Successful');
        setLoading(false);
        pptx = new PptxGenJS();
    };
    xhr.onerror = () => {
        alert('Request failed');
        setLoading(false);
    };
};
const slideStyles: any = {
    cover_header: {
        fontFace: 'Georgia',
        color: 'FFFFFF',
        fontSize: 40,
        margin: 0,
        align: 'left'
    },
    cover_projectDesc: {
        x: 0.5,
        y: 3.5,
        fontFace: 'Arial',
        color: 'FFFFFF',
        fontSize: 15,
        margin: 0,
        align: 'left'
    },
    cover_projectDate: {
        x: 0.5,
        y: 3.8,
        fontFace: 'Arial',
        color: 'FFFFFF',
        fontSize: 15,
        margin: 0,
        align: 'left'
    },
    cover_smallText: {
        x: 0.43,
        y: 4.1,
        fontSize: 10,
        fontFace: 'Arial',
        color: 'FFFFFF',
        align: 'left'
    },
    cover_smallText_2: {
        x: 0.43,
        y: 4.39,
        fontSize: 10,
        fontFace: 'Arial',
        color: 'FFFFFF',
        align: 'left'
    },
    title_text: {
        x: 0.5,
        y: 0.5,
        fontSize: 24,
        color: '000000',
        bold: false,
        margin: 5,
        fontFace: 'Georgia'
    },
    subtitle_textLvl1: {
        x: 0.5,
        y: 1.15,
        fontSize: 11,
        color: 'd04a02',
        bold: true,
        margin: 5,
        fontFace: 'Arial'
    },
    subtitle_textLvl2: {
        x: 0.5,
        y: 3.95,
        fontSize: 11,
        color: 'd04a02',
        bold: true,
        margin: 5,
        fontFace: 'Arial'
    },
    table_contentText: {
        align: 'left',
        fontSize: 11,
        bold: true,
        color: '000000'
    },
    capabilities_table_TitleText: {
        x: 0.5,
        y: 2.0,
        fontSize: 11,
        bold: true,
        fontFace: 'Arial',
        colW: [1.0, 4.0, 4.0],
        color: 'D04A02',
        border: [
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' }
        ]
    },
    shapes_line_rec: {
        x: 5.5,
        y: 5.3,
        w: 3.5,
        h: 0.0,
        line: 'D04A02',
        lineSize: 2
    },
    text_body_rec: {
        x: 5.5,
        y: 5.6,
        w: 3.5,
        fontSize: 11,
        fontFace: 'Arial'
    },
    shapes_line_obs: {
        x: 1,
        y: 5.3,
        w: 3.5,
        h: 0.0,
        line: 'D04A02',
        lineSize: 2
    },
    text_body_obs: {
        x: 1,
        y: 5.6,
        w: 3.5,
        fontSize: 11,
        fontFace: 'Arial'
    },
    shapes_line_table: {
        x: 0.5,
        y: 2.3,
        w: 9,
        h: 0.0,
        line: 'D04A02',
        lineSize: 3
    },
    shapes_line_executive_table: {
        x: 0.48,
        y: 1.78,
        w: 9.02,
        h: 0.0,
        line: 'D04A02',
        lineSize: 3
    },
    page_number: {
        align: 'left',
        fontSize: 11,
        bold: true,
        color: '000000'
    },
    table_subcontentText: {
        align: 'left',
        color: '000000',
        fontSize: 11,
        fontFace: 'Arial'
    },
    detail_subcontentText: {
        align: 'left',
        color: 'FFFFFF',
        fontSize: 11,
        fontFace: 'Arial'
    },
    gen_title_options: {
        x: 0.5,
        y: 0.5,
        w: 7.0,
        fontSize: 24,
        color: '000000',
        bold: false,
        fontFace: 'Georgia'
    },
    detail_text: {
        x: 0.5,
        y: 1.8,
        fontSize: 34,
        color: 'FFFFFF',
        bold: true,
        margin: 5
    },
    domain_tableTitle: {
        x: 0.5,
        y: 0.5,
        w: 9.0,
        h: 1.0,
        fontSize: 24,
        color: '000000',
        bold: false,
        fontFace: 'Georgia'
    },
    score_options: {
        x: 7.5,
        y: 0.5,
        w: 4,
        fontSize: 11,
        color: 'ce4a02',
        bold: true,
        fontFace: 'Arial'
    },
    score_options_2: {
        x: 7.5,
        y: 2.0,
        w: 4,
        fontSize: 11,
        color: 'ce4a02',
        bold: true,
        fontFace: 'Arial'
    },
    executiveOverviewTitle: {
        x: 0.4,
        y: 1.5,
        colW: [1.0, 3.3, 3.3, 1.4],
        color: 'D04A02',
        fontFace: 'Arial',
        fontSize: 11,
        bold: true,
        border: [
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' }
        ]
    },
    maturityOverviewTitle: {
        x: 0.5,
        y: 1.5,
        colW: [3.0, 3.0, 3.0],
        color: 'D04A02',
        fontFace: 'Arial',
        fontSize: 11,
        bold: true,
        border: [
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' },
            { color: 'FFFFFF' }
        ]
    },
    domain_obs: {
        x: 1.0,
        y: 5,
        w: 4,
        fontSize: 11,
        color: 'd04a02',
        bold: true,
        fontFace: 'Arial'
    },
    domain_average_graph_title: {
        x: 0.5,
        y: 0.5,
        w: 7.0,
        fontSize: 24,
        color: '000000',
        bold: false,
        fontFace: 'Georgia'
    },
    domain_rec: {
        x: 5.5,
        y: 5.0,
        w: 4,
        fontSize: 11,
        color: 'd04a02',
        bold: true,
        fontFace: 'Arial'
    }
};
const mastersStyles = {
    cover_bkgd: 'FFFFFF',
    cover_image: { x: 0, y: 6.55, w: 1.67, h: 0.55 },
    cover_recttop0: { x: 0, y: 0, w: 6.3, h: 4.5, fill: '4B4A4A' },
    cover_recttop1: { x: 6.3, y: 0, w: 3.7, h: 3.4, fill: 'F0A248' },
    cover_recttop2: { x: 0, y: 3.4, w: 6.3, h: 1.6, fill: 'd04a02' },
    cover_textOpt2: {
        x: 0.5,
        y: 3.6,
        w: 5.5,
        h: 0.75,
        fontFace: 'Arial',
        color: 'FFFFFF',
        fontSize: 20,
        valign: 'm',
        margin: 0
    },
    detail_bkgd: 'd04a02',
    detail_rect: { x: 0, y: 1.5, w: 10, h: 1.0, fill: '464646' },
    general_textOpt: {
        x: 0.2,
        y: 7.0,
        w: '100%',
        align: 'left',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    general_separationLine: {
        x: 0.5,
        y: 7.0,
        align: 'left',
        color: 'd04a02',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    general_campaignName: {
        x: 0.55,
        y: 7.0,
        w: '100%',
        align: 'left',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    general_textAbovePWC: {
        x: 0.2,
        y: 6.88,
        align: 'left',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    general_draft: {
        x: 3.0,
        y: 7.0,
        align: 'left',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    general_dateOpt: {
        x: 8.66,
        y: 6.8,
        w: '100%',
        align: 'left',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    slideNumber: {
        x: 9.55,
        y: 7.0,
        color: '000000',
        fontSize: 8.0
    }
};
