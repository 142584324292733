import React from 'react';
import { styled } from '@mui/material/styles';
import Slider  from '@mui/material/Slider';
import { SliderValueLabelProps } from '@mui/material/Slider';
import { Mark } from '../../modules/Evaluate/MaturityScore/types';

const TargetScoreSlider: React.FC<{
    defaultValue: number;
    isEnabled?: boolean;
    value?: number;
    setValue: any;
    marks?: Mark[];
    valueLabelDisplay?: 'on' | 'auto' | 'off';
    valueLabelFormat?: any;
    step?: number | null;
    min?: number;
    max?: number;
    color?: string;
    onBlur?: any;
    className?: string;
    ariaLabelledby?: string;
    ValueLabelComponent?: any;
}> = ({
    defaultValue,
    isEnabled = false,
    value = defaultValue,
    setValue,
    marks = true,
    valueLabelDisplay = 'off',
    valueLabelFormat,
    step = null,
    min = 0,
    max,
    color = '#DA566E',
    onBlur,
    className,
    ariaLabelledby,
    ValueLabelComponent
}) => {

    return (
        <CustomTargetScoreSlider
            style={{color: isEnabled ? color : '#818181'}}
            disabled={!isEnabled}
            defaultValue={defaultValue}
            value={value}
            aria-label="Input default target scoring"
            aria-valuetext={value.toString()}
            onChange={(event: object, value: number | number[]) => typeof value === 'number' ? setValue(value) : null}
            onChangeCommitted={(event: object, value: number | number[]) => onBlur && onBlur(event, value)}
            marks={marks}
            valueLabelDisplay={valueLabelDisplay}
            step={step}
            valueLabelFormat={((value: number, index: number) => valueLabelFormat ? valueLabelFormat(value, index) : value ? value : defaultValue)}
            min={min}
            max={max}
            className={className}
            components={ValueLabelComponent}
            aria-labelledby={ariaLabelledby}
        />
    );
};

const CustomTargetScoreSlider = styled(Slider)`
    .MuiSlider-mark{
        width: 4px;
        height: 4px;
        border-radius: 5px;
        margin-top: 1px;
        margin-left: 1px;
    }
    .MuiSlider-track{
        border-radius: 5px;
        height: 6px;
    }
    .MuiSlider-rail{
        width:102%;
        height: 6px;
        border-radius: 5px;
    }
    .MuiSlider-thumb{
        width: 16px;
        height: 16px;
    }
    .MuiSlider-thumb:hover, .MuiSlider-thumb.MuiSlider-active, .MuiSlider-thumb.Mui-focusVisible{
        box-shadow: 0px 0px 0px 11px #DA566E47;
    }
    .MuiSlider-valueLabel{
        background-color: #333;
        border-radius: 5px;
    }
    .MuiSlider-markLabel{
        top: -8px;
        transform: translateX(-10%);
    }
    .MuiSlider-thumb.Mui-disabled{
        width: 16px;
        height: 16px;
        margin-left: -6px;
        margin-top: 0;
    }
`;

export default TargetScoreSlider;
