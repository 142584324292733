import { styled } from '@mui/material/styles';

const ChartWarningTag = styled('p')`
    color: #fff;
    padding: 3px 3px;
    background: #2f2f2f;
    border-radius: 4px;
    margin: auto;
    display: block;
    font-size: 9px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.34);
    max-width: 400px;
`;

export default ChartWarningTag;
