import React from 'react';
import { styled } from '@mui/material/styles';

import { Column, AlignItems } from '../base/Layout';
import Text from '../base/Text';
import LinearProgress from './LinearProgress';

type Props = {
    progress: number;
};

const LinearProgressWithText: React.FC<Props> = ({ progress }) => {
    return (
        <Column>
            <AlignItems>
                <LinearProgress progress={progress} />
                <ProgressText>{`${progress}%`}</ProgressText>
            </AlignItems>
        </Column>
    );
};

const ProgressText = styled(Text)`
    size: 15px;
    color: #6E6E6E;
    font-weight: bold;
    margin-left: 8px;
`;

export default LinearProgressWithText;
