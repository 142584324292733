import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import RedButton from '../Buttons/RedButton';

type Props = {
    title: string;
    content: string;
    open: boolean;
    handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
    buttonCancelText?: string;
    buttonSaveText?: string;
};

const DialogBox: React.FC<Props> = ({
    title,
    content,
    open,
    handleClose,
    handleSubmit,
    buttonCancelText,
    buttonSaveText
}) => {
    const theme = createTheme({
        components: {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        padding: '16px'
                    }
                },
            },
        },
    });


    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttonCancelText && (
                        <ButtonCancel onClick={handleClose} variant="outlined">
                            {buttonCancelText || 'Cancel'}
                        </ButtonCancel>
                    )}
                    <ButtonWithMarginLeft
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        {buttonSaveText || 'Okay'}
                    </ButtonWithMarginLeft>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

const ButtonWithMarginLeft = styled(RedButton)`
    && {
        text-transform: capitalize;
        margin-left: 20px;
        min-height: 34px;
    }
`;

const ButtonCancel = styled(Button)`
    && {
        text-transform: capitalize;
    }
`;

export default DialogBox;
