import React, { useState } from 'react';
import { Flex } from '../../../../../../../components';
import { styled } from '@mui/material/styles';
import iconCreateKey from '../../../../../../../assets/images/icon-create-grey-circle.svg';
import iconDeleteKey from '../../../../../../../assets/images/icon-remove-key.svg';
import iconStarBlack from '../../../../../../../assets/images/icon-star-black.svg';
import iconDeleteObjective from '../../../../../../../assets/images/delete-bin-grey.svg';
import { ObjectiveInList, ObjectivesDropdownList } from '../../../../types';
import { findObjectiveType } from '../../../../ThreatAndRiskHelpers';
import { IconButton } from '@mui/material';

type ThreatOptionObjectiveType = {
    obj: ObjectiveInList;
    objWithKey: string[];
    setObjWithKey: any;
    allObjectives?: ObjectivesDropdownList[];
    finalObjectives: string[];
    setFinalObjectives: any;
    isInitialLoad: boolean;
    setInitialLoad: any;
    isLatestObjective: boolean;
    isGuidance: boolean;
};

const ThreatOptionObjective: React.FC<ThreatOptionObjectiveType> = ({
    obj,
    objWithKey,
    setObjWithKey,
    allObjectives,
    finalObjectives,
    setFinalObjectives,
    isInitialLoad,
    setInitialLoad,
    isLatestObjective,
    isGuidance
}) => {

    return (
        <Block isLatestObjective={isLatestObjective}>
            <Objective>
                <ObjectivesColumn>{obj.title}</ObjectivesColumn>
                <ObjectivesKey>
                    {objWithKey.includes(obj.reference) && (
                        <Icon src={iconStarBlack} alt="key objective"/>
                    )}
                    {objWithKey.includes(obj.reference) ? (
                        <IconButton
                            onClick={() => {
                                const newObjWithKey = objWithKey.filter(
                                    (object: string) => object !== obj.reference
                                );
                                setObjWithKey(newObjWithKey);
                                setInitialLoad(false);
                            }}
                        >
                            <Icon src={iconDeleteKey} alt="Remove key marker" />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => {
                                setObjWithKey([...objWithKey, obj.reference]);
                                setInitialLoad(false);
                            }}
                        >
                            <Icon src={iconCreateKey} alt="Add key marker" />
                        </IconButton>
                    )}
                </ObjectivesKey>
                <ObjectivesType>
                    <TypeStyle>
                        {findObjectiveType(obj.reference, allObjectives)}
                    </TypeStyle>
                </ObjectivesType>
                <IconButton
                    onClick={() => {
                        const newFinalObj = finalObjectives.filter(
                            (object: string) => object !== obj.reference
                        );
                        setFinalObjectives(newFinalObj);
                        const newObjWithKey = objWithKey.filter(
                            (object: string) => object !== obj.reference
                        );
                        setObjWithKey(newObjWithKey);
                        if (isInitialLoad) {
                            setInitialLoad(false);
                        }
                    }}
                >
                    <Icon src={iconDeleteObjective} alt="Delete objective" />
                </IconButton>
            </Objective>
            {isGuidance && <ObjectiveDesc>{obj.name}</ObjectiveDesc>}
        </Block>
    );
};

const ObjectiveDesc = styled('div')`
    font-size: 15px;
    color: #575757;
    margin-left: 30px;
    width: 73%;
    margin-bottom: 14px;
`;

const Block = styled('div')<{ isLatestObjective: boolean }>`
    border-bottom: ${props =>
        props.isLatestObjective ? '3px solid #eeeeee' : '1px solid #efeaea'};
`;

const TypeStyle = styled('div')`
    width: 25px;
    height: 25px;
    border-radius: 20px;
    background-color: #2d2d2d;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-left: 2px;
    padding-bottom: 2px;
`;

const ObjectivesType = styled('div')`
`;


const Icon = styled('img')`
    width: 25px;
    color: grey;
`;

const Objective = styled('div')`
    display: flex;
    height: 49px;
    align-items: center;
    padding-left: 18px;
    font-size: 16px;
    color: #2d2d2d;
    &:hover {
        background-color: #f8f8f8;
    }
    &:before {
        content: '• ';
        margin-right: 5px;
    }
`;

const ObjectivesColumn = styled('div')`
    flex-basis: 79%;
`;

const ObjectivesKey = styled('div')`
    display: flex;
`;

export default ThreatOptionObjective;
