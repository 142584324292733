import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { PieChart, Flex, H1Text } from '../../../../../components';
import {chartDraftWarning, colorsList, roundFloor} from '../../../../../utils';
import { AssessmentData, Score, AssessmentDomain } from '../../types';
import { getAverageScoreByDomain, getAverageScoreByCapability } from '../../../EvaluateHelpers';
import TooltipByClick from '../../../../../components/Tooltips/TooltipByClick';
import ChartWarningTag from '../../../../../components/base/ChartWarning/ChartWarningTag';
type Props = {
    data?: AssessmentData;
    campaignTitle: string;
    showTargetScore: boolean;
    pointOneDecimalEnable: boolean;
    showCapabilityStatus?: boolean;
};

const Chart: React.FC<Props> = ({ data, campaignTitle, showTargetScore, pointOneDecimalEnable }) => {
    const [domainsScoresList, setDomainsScoresList] = useState<Score[]>([]);
    const [bottomDomain, setBottomDomain] = useState(-1);
    const [sectionSize, setSectionSize] = useState(0);
    const assessmentName =
        data &&
        data.assessment &&
        data.assessment.campaignEntity &&
        data.assessment.campaignEntity.name;

    const capabilityStatus = data?.assessment.state.status !== 'COMPLETED' ? true : false;
    useEffect(() => {
        if (
            data &&
            data.assessment &&
            data.assessment.state &&
            data.assessment.state.domains
        ) {
            setSectionSize(100 / data.assessment.state.domains.length);
        }
        if (
            data &&
            data.assessment &&
            data.assessment.state &&
            data.assessment.state.domains.length &&
            data.assessment.state.domains.length % 2 > 0
        ) {
            setBottomDomain(data.assessment.state.domains.length / 2 - 0.5);
        }
    }, [data]);
    useEffect(() => {
        if (
            data &&
            data.assessment &&
            data.assessment.state &&
            data.assessment.state.domains
        ) {
            const scoreList = data.assessment.state.domains.map(
                (domain: AssessmentDomain) => {
                    return {
                        name: domain.name,
                        score: getAverageScoreByDomain(domain, pointOneDecimalEnable),
                        targetScore: domain.targetAverageScore ? roundFloor(domain.targetAverageScore, pointOneDecimalEnable) : 0,
                        capabilities: (domain.capabilities && domain.capabilities.length > 0) ? domain.capabilities.map(cap => ({
                            score: getAverageScoreByCapability(cap, pointOneDecimalEnable),
                            name: cap.name,
                            inScope:cap.inScope,
                            targetScore: cap.targetScore ? roundFloor(cap.targetScore) : 0
                        })) : []
                    };
                }
            );
            setDomainsScoresList(scoreList);
        }
    }, [data]);
    return (
        <ChartContainer>
            <H1Text color="#4f4f4f" style={{ textAlign: 'center',width:'100%' }}>
                {campaignTitle}: {assessmentName} Domain Average
            </H1Text>
            <ChartWarningTag> {chartDraftWarning}</ChartWarningTag>
            <PieChart
                saveAsImageText={'domain_average_pie_graph'}
                scoresList={domainsScoresList}
                maxScoreRange={5}
                minScoreRange={0}
                sectorSize={sectionSize}
                bottomDomain={bottomDomain}
                colorsList={colorsList}
                showTargetScore={showTargetScore}
                showCapabilityStatus={capabilityStatus}
                pointOneDecimalEnable={pointOneDecimalEnable}
            />
        </ChartContainer>
    );
};

const ChartContainer = styled(Flex)`
    padding: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export default Chart;
