import React from 'react';
import { styled } from '@mui/material/styles';
import BoldText from '../../base/Text/BoldText';
import SpaceBetween from '../../base/Layout/SpaceBetween';
import JustifyContentCenter from '../../base/Layout/JustifyContentCenter';

import closeIcon from '../../../assets/images/Atmos_Icons_Close.svg';
import { IconButton } from '@mui/material';

type Props = {
    handleClose?: () => void;
    title: string;
};

const PopUpHeader: React.FC<Props> = ({ handleClose, title }) => {
    return (
        <Container>
            <HeaderTextContainer>
                <BoldText>{title}</BoldText>
            </HeaderTextContainer>
            {handleClose ? (
                <IconButton
                    onClick={handleClose}
                    aria-label={'close button'}
                    style={{
                        right: '24px',
                        position: 'absolute'
                    }}
                >
                    <img src={closeIcon} alt="close" aria-hidden={true} />
                </IconButton>
            ) : null}
        </Container>
    );
};

const HeaderTextContainer = styled(JustifyContentCenter)`
    width: 100%;
`;

const Container = styled(SpaceBetween)`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #cdcdcd;
    padding: 24px;
`;

export default React.memo(PopUpHeader, (prevProps: Props, nextProps: Props) => {
    if (
        prevProps.title === nextProps.title &&
        prevProps.handleClose === nextProps.handleClose
    ) {
        return true;
    }
    return false;
});
