import React from 'react';
import { Column, Flex } from '../../components';
import { styled } from '@mui/material/styles';
import { alphabet, termOfUseData, termOfUseIntro, toRoman } from './constants';
import { Arabic, TermOfUseItem, termsIntro, TermsOfUseTextProps } from './types';
import MainText from './components/MainText';

const TermsOfUseText: React.FC<TermsOfUseTextProps> = ({ customStyles }) => {
    return (
        <Wrapper>
            {termOfUseIntro.map((item: termsIntro, index: number) => (
                <Intro style={customStyles} key={index}>
                    {
                        item &&
                        item.text
                    }
                </Intro>
            ))
            }
            {termOfUseData.map(
                (item: TermOfUseItem, index: number) => (
                    <ArabicBold key={index}>
                        {
                            item &&
                            item.arabic &&
                            <ArabicBold style={customStyles}>
                                {`${index + 1}.   ${item.arabic}`}
                            </ArabicBold>
                        }
                        {
                            item &&
                            item.alphabetList &&
                            item.alphabetList.map((alphabetItem: Arabic, ind: number) => (
                                <div key={ind}>
                                    <AlphabetItem key={ind}>
                                        <AlphabetNum style={customStyles}>
                                            {`${alphabet[ind]}.   `}
                                        </AlphabetNum>
                                        <JustifyText>
                                            <ItalicText style={customStyles}>
                                                {alphabetItem.italic}
                                            </ItalicText>
                                            <JustifyText style={customStyles}>
                                                {alphabetItem.content}
                                            </JustifyText>
                                        </JustifyText>
                                    </AlphabetItem>
                                    {
                                        alphabetItem &&
                                        alphabetItem.romanList &&
                                        alphabetItem.romanList.map((romanItem, i) => (
                                            <RomanItem key={i}>
                                                <RomanNum style={customStyles}>
                                                    {`${toRoman(i + 1)}.`}
                                                </RomanNum>
                                                <RomanText style={customStyles}>
                                                    {
                                                        romanItem &&
                                                        romanItem.bold &&
                                                            <BoldText style={customStyles}>
                                                                {romanItem.bold}
                                                            </BoldText>

                                                    }
                                                    {
                                                        romanItem &&
                                                        romanItem.normal &&
                                                        <JustifyText style={customStyles}>
                                                            {romanItem.normal}
                                                        </JustifyText>
                                                    }
                                                </RomanText>
                                            </RomanItem>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </ArabicBold>
                )
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Column)`
    margin: 24px 0 44px;
`;

const BoldText = styled(MainText)`
    font-weight: 800;
`;

const ItalicText = styled(MainText)`
    font-style: italic;
`;

const JustifyText = styled(MainText)`
    text-align: justify;
`;

const ArabicBold = styled(ItalicText)`
    font-weight: 800;
    margin-top: 46px;
`;

const Intro = styled(JustifyText)`
    margin-top: 16px;
`;

const RomanNum = styled(MainText)`
    width: 20px;
    text-align: right;
    margin: 0 15px 0 80px;
`;

const RomanItem = styled(Flex)`
    margin-top: 16px;
`;

const RomanText = styled(MainText)`
    width: 90%;
    text-align: justify;
`;

const AlphabetItem = styled(Flex)`
    margin-top: 16px;
`;

const AlphabetNum = styled(MainText)`
    text-align: right;
    margin: 0 15px 0 40px;
`;

export default TermsOfUseText;
