import React, { useContext } from 'react';
import Header from './Header';

import { UserStore } from '../../../context/user-store';

const HeaderContainer: React.FC<{setRefreshState: any}>  = ({ setRefreshState }) => {
    const { state: { user, client } } = useContext(UserStore);
    return (
        <Header setRefreshState={setRefreshState} user={user} client={client}/>
    );
};

export default HeaderContainer;
