import React from 'react';
import { TabProps } from './types';
import { styled } from '@mui/material/styles';

const Tab: React.FC<TabProps> = (props) => {
    return (
        <CustomTab active={props.active} index={props.index} onClick={() => props.handleClick && props.handleClick()} lastTab={props.lastTab}>
            {props.children}
        </CustomTab>
    );
};
const CustomTab = styled('button')<{active?: boolean; index? : number; lastTab?: boolean}>`
    display: flex;
    align-items: center;
    background-color: #4f4f4f;
    border: solid 1px #d9d9d9;
    border-bottom: #4f4f4f solid 1px;
    color: white;
    cursor: pointer;
    position: relative;
    transition: all .2s;
    top: 1.5px;
    border-left: none;
    border-right: none;

    ${props => props.active && `
        background-color: white;
        color: black;
        top: 1.5px;
        border-bottom: #fff solid 1px;

        & ::after {
            content: '';
            width: 90%;
            position: absolute;
            height: 1px;
            background: #f4f4f4;
            bottom: -2px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    `}

    ${props => props.index === 0  && `
        /* first tab */
        border-left: #d9d9d9 solid 1px;
        border-top-left-radius: 2px;
    `}

    ${props => props.lastTab && `
        /* last tab */
        border-right: #d9d9d9 solid 1px;
        border-top-right-radius: 2px;
    `}
`;

export default Tab;
