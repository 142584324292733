import React from 'react';
import { styled } from '@mui/material/styles';
import {
    SpaceBetween,
    JustifyContentCenter
} from '../../../../components/base/Layout';
import { textColorResolver } from '../../../../utils';

type RenderCompletedStegeType = {
    theme: string[];
    currentTheme: string;
    isTargetScoring?: boolean;
};

const RenderCompletedStege: React.FC<RenderCompletedStegeType> = ({
    theme,
    currentTheme,
    isTargetScoring
}) => {
    const completeStageValues = [
        '0 - 1',
        '1.1 - 2',
        '2.1 - 3',
        '3.1 - 4',
        '4.1 - 5',
    ];
    const targetScoringValues = [
        { value: '0 - 1', bgColour: '#92D050'},
        { value: '1.1 - 2.5', bgColour: '#FFB600'},
        { value: '2.6 - 5', bgColour: '#E0301E'}
    ];

    return (
        <SpaceBetween>
            {!isTargetScoring && completeStageValues.map((value: string, index: number)=>{
                return (
                    <NumberBlock
                        key={index}
                        style={{
                            backgroundColor: `${theme[index]}`,
                            minWidth: '4vw',
                            color: textColorResolver(index + 1, '', currentTheme)
                        }}
                    >
                        {value}
                    </NumberBlock>
                );
            })}
            {isTargetScoring && targetScoringValues.map((val: {value: string; bgColour: string}, index: number) => {
                return (
                    <div key={index}>
                    <NumberBlock
                        key={index}
                        style={{
                            backgroundColor: `${val.bgColour}`,
                            color: '#000'
                        }}
                    >
                    </NumberBlock>
                <NumberValue>
                        {val.value}
            </NumberValue>
                    </div>
                );
            })}
        </SpaceBetween>
    );
};

const NumberBlock = styled(JustifyContentCenter)`
    box-sizing: border-box;
    width: 4vw;
    height: 4.5vh;
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    margin-left: 1.7vw;
`;

const NumberValue = styled('div')`
    margin-left: 1.7vw;
`;

export default RenderCompletedStege;
