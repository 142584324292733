import React, { useState } from 'react';
import Modal from 'react-modal';
import { styled } from '@mui/material/styles';
import { termsOfUseDecline, termsOfUseStyles } from './constants';
import { AlignItems, Column, Flex } from '../../components/base/Layout';
import { H1Text as H1, RedButton } from '../../components';
import ColoredLogo from '../../assets/images/PwC_logo.png';
import TermsOfUseText from './TermsOfUseText';
import { Checkbox } from '@mui/material';
import MainText from './components/MainText';
import Router from '../../routes/router';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { removeJwtFromWhitelist } from '../../services/ApolloClient/client';
import {TermsOfUseProps} from './types';
import { useAuth } from 'react-oidc-context';

const TERMS_OF_USE = gql`
    mutation acceptTos {
        acceptTos {
            id
            tosAcceptedAt
        }
    }
`;

const TermOfUseSection: React.FC<TermsOfUseProps> = ({setIsAccepted}) => {
    const auth = useAuth();
    const [isChecked, setChecked] = useState<boolean>(false);
    const [isDecline, setDecline] = useState<boolean>(false);

    const [acceptToS, { loading }] = useMutation(TERMS_OF_USE);

    const handleAcceptToS = () => {
        acceptToS()
        .then(() => {
            setIsAccepted(true);
        })
        .catch(error => {
            console.log('error', error);
        });

    };

    return (
        <TermOfUse
            isOpen
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            style={termsOfUseStyles}
        >
            <ContentWrapper>
                <Header>
                    <img
                        src={ColoredLogo}
                        alt="colored pwc logo"
                        height="48 px"
                        style={{ margin: '8px' }}
                    />
                </Header>
                <TextWrapper>
                    <H1Text>
                        Terms of use
                        <br />
                        for the Connected Risk Engine
                    </H1Text><br/><br/><br/>
                    <TermsOfUseText />
                    <AgreementTextWrapper>
                        <Checkbox
                            checked={isChecked}
                            color="secondary"
                            name={'Agreement'}
                            onChange={() => {
                                setChecked(!isChecked);
                            }}
                            value={isChecked}
                            inputProps={{
                                'aria-label': 'secondary checkbox'
                            }}
                        />
                        <AgreementText>
                            I agree to the Terms of Use
                        </AgreementText>
                    </AgreementTextWrapper>
                    <TermOfUse
                        isOpen={isDecline}
                        shouldCloseOnOverlayClick={false}
                        ariaHideApp={false}
                        style={termsOfUseDecline}
                    >
                        <InnerTextWrapper>
                            <H2Text>Are you sure you wish to decline?</H2Text>
                            <SmallText>
                                If you wish to decline the Terms of Use, you
                                will not be able to enter the Connected Risk
                                Engine.
                            </SmallText>
                            <ButtonWrapper
                                style={{
                                    padding: '32px 14px 0'
                                }}
                            >
                                <DeclineButton>
                                    <Flex>
                                        <ButtonText
                                            onClick={async () => {
                                                auth.signoutSilent();
                                                auth.removeUser();
                                                await removeJwtFromWhitelist();
                                                Router.goToLogoutPage();
                                            }}
                                        >
                                            Yes, decline Terms of Use
                                        </ButtonText>
                                    </Flex>
                                </DeclineButton>
                                <CancelButton onClick={() => setDecline(false)}>
                                    <Flex>
                                        <RedButtonText>Cancel</RedButtonText>
                                    </Flex>
                                </CancelButton>
                            </ButtonWrapper>
                        </InnerTextWrapper>
                    </TermOfUse>
                </TextWrapper>
                <ButtonWrapper>
                    <DisagreeButton>
                        <Flex>
                            <ButtonText onClick={() => setDecline(true)}>
                                Decline
                            </ButtonText>
                        </Flex>
                    </DisagreeButton>
                    <AgreeButton
                        disabled={!isChecked || loading}
                        onClick={handleAcceptToS}
                    >
                        <Flex>
                            <RedButtonText>Agree</RedButtonText>
                        </Flex>
                    </AgreeButton>
                </ButtonWrapper>
            </ContentWrapper>
        </TermOfUse>
    );
};

const TermOfUse = styled(Modal)`
    position: absolute;
    border-width: 0;
    border-radius: 4px;
    box-shadow: 0 0 15px #3a3a3a;
    :focus {
        outline: 0;
    }
`;

const TextWrapper = styled('div')`
    margin: 16px 24px;
    padding: 24px 26px 45px;
    background: white;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    overflow: auto;
`;

const InnerTextWrapper = styled(TextWrapper)`
    width: 480px;
    padding: 24px 26px 40px;
    box-sizing: border-box;
    margin: auto;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
`;

const ButtonWrapper = styled(Flex)`
    padding: 0 24px 19px;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
`;

const ContentWrapper = styled(Column)`
    align-items: center;
    background-color: #f4f4f4;
    height: 100%;
    width: 100%;
    padding: 0;
`;

const Header = styled(AlignItems)`
    background-color: #fff;
    border-bottom: 1px solid #cdcdcd;
    justify-content: center;
    width: 100%;
    height: 64px;
`;

const H2Text = styled(H1)`
    font-size: 18px;
    color: #40403e;
    text-align: start;
    font-weight: bold;
`;

const H1Text = styled(H2Text)`
    margin: auto;
    color: #3a3a3a;
    text-align: center;
    line-height: 1.8;
    padding: 0px 16px 0px 80px;
`;

const CancelButton = styled(RedButton)`
    && {
        max-height: 44px;
        width: 86px;
        padding: 0 16px;
    }
`;

const DeclineButton = styled(CancelButton)`
    && {
        border: 1.5px solid #d9d9d9;
        border-radius: 2px;
        background-color: #fff;
        margin-right: 21px;
        width: 250px;
        :hover {
            background-color: #fff;
        }
    }
`;

const DisagreeButton = styled(CancelButton)`
    && {
        background-color: #f4f4f4;
        margin-right: 16px;
        :hover {
            background-color: #f4f4f4;
        }
    }
`;

const AgreeButton = styled(CancelButton)`
    && {
        width: 113px;
    }
`;

const ButtonText = styled(MainText)`
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    margin: 10px 0;
`;

const RedButtonText = styled(ButtonText)`
    color: #fff;
    margin: 10px 0;
`;

const AgreementText = styled(MainText)`
    position: relative;
    top: 2px;
    font-size: 17px;
    font-weight: 500;
`;

const AgreementTextWrapper = styled(MainText)`
    line-height: 24px;
    margin: 9px;
`;

const SmallText = styled(MainText)`
    font-size: 15px;
    width: 379px;
    padding: 4px 0 32px;
`;

export default TermOfUseSection;
