import React from 'react';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import AlignItems from '../../../base/Layout/AlignItems';
import Text from '../../../base/Text/Text';
import Column from '../../../base/Layout/Column';
import JustifyContentCenter from '../../../base/Layout/JustifyContentCenter';


type TooltipTitleContainerProps = {
    headerTitle: string;
    content: React.ReactNode | string;
    handleClose?: () => void;
};

const TooltipTitleContainer: React.FC<TooltipTitleContainerProps> = ({
    headerTitle,
    content,
    handleClose
}) => {

    return (
        <Root>
            <TooltipHeader>
                <TitleContainer>
                    <Text weight={'bold'}>{headerTitle}</Text>
                </TitleContainer>
                {handleClose && (
                    <CloseButton
                        aria-label="close"
                        onClick={handleClose}>
                        <Close />
                    </CloseButton>
                )}
            </TooltipHeader>
            <ContentWrapper>{content}</ContentWrapper>
        </Root>
    );
};

const TitleContainer = styled(JustifyContentCenter)`
    width: 100%;
`;

const ContentWrapper = styled(Column)`
    padding: 10px;
    background: #fff;
    color: #333;
    box-shadow: 0px 1px 3px 0px #333;
`;

const TooltipHeader = styled(AlignItems)`
    background: #fff;
    padding: 4px;
    color: #333;
`;

const CloseButton = styled(IconButton)`
    color: white;
    padding: 2px;
`;

const Root = styled('div')`
    display: flex;
    flex-direction: column;
`;

export default TooltipTitleContainer;
