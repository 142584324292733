import React from 'react';
import Modal from 'react-modal';
import { PopUpHeader } from '../../../../../components';
import { CapabilityObjectiveWithDescriptions } from '../../types';
import ContentModel, { Title } from './ContentModel';
import { customModalStyles } from '../../../../../components/PopUps/constants';

export type WeightedModalProps = {
    isOpen: boolean;
    setOpen: any;
    objectives: CapabilityObjectiveWithDescriptions[];
    titles: Title;
    handleOnSave: any;
    isError: boolean;
    setbalanceWeightSuccessMessage: any;
    balanceWeightSuccessMessage: boolean;
    balanceweightFailureMessage: boolean;
};

const ObjectiveWeightModal: React.FC<WeightedModalProps> = ({
    isOpen,
    setOpen,
    objectives,
    titles,
    handleOnSave,
    isError,
    setbalanceWeightSuccessMessage,
    balanceWeightSuccessMessage,
    balanceweightFailureMessage,
}) => {
    const content = objectives.map((objective) => {
        return {
            id: objective.id,
            inScope: objective.inScope,
            name: objective.displayRef || objective.reference,
            description: objective.name,
            value: objective.weighting || 0,
        };
    });

    return (
        <>
            <Modal
                isOpen={isOpen}
                contentLabel="Weighted Average"
                style={customModalStyles}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
            >
                {/* <PopUpHeader title="Adjust Sub-Category Weights" handleClose={() => setOpen(false)}/> */}
                <ContentModel
                    titles={titles}
                    content={content}
                    handleOnSave={handleOnSave}
                    isError={isError}
                    setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                    balanceWeightSuccessMessage={balanceWeightSuccessMessage}
                    balanceweightFailureMessage={balanceweightFailureMessage}
                />
            </Modal>
        </>
    );
};

export default ObjectiveWeightModal;
