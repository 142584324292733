import React, { useState, useEffect, useContext } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { JustifyContentCenter, SpinnerWithOverlay } from '../../../../components';
import { UserStore } from '../../../../context/user-store';
import DetailsTable from './DetailsTable';
import DetailsTableHeader from './DetailsTableHeader';
import {
    DetailsTabProps,
    Assessment,
    Capability,
    DetailsTableRows,
    Cell
} from './types';
import { detailsTableRows } from './detailsTableRows';
import Legend from '../../MaturityOverview/Legend';
import {
    useFilteredCapabilities,
    assessmentDetailHeadersRows,
    TableHeadTitle
} from './DetailsTabHelpers';
import { isReadOnlyEnabled } from '../../../../utils';

const ASSESSMENT = gql`
    query assessment2($id: ID!) {
        assessment(id: $id) {
            id
            state {
                domains {
                    capabilities {
                        id
                        name
                        averageScore
                        moderatedScore
                        workingNotes {
                            text
                        }
                        status
                        feedback {
                            id
                            title
                            observations {
                                text
                            }
                            recommendations {
                                text
                            }
                        }
                    }
                }
            }
            framework {
                version
                definition {
                    name
                    shortName
                    scores
                    domains {
                        name
                        shortName
                        capabilities {
                            name
                            shortName
                        }
                    }
                }
            }
        }
    }
`;

const DetailsTab: React.FC<DetailsTabProps> = ({
    assessmentId,
    detailsTabData,
    showTargetScore,
    isLegendVisible,
    setIsLegendVisible,
    isReadOnly,
    campaignComplete,
    pointOneDecimalEnable = false,
    isObjectiveComponentExist
}) => {
    const [capabilities, setCapabilities] = useState([]);
    const [openedFeedback, setOpenedFeedback] = useState<string[]>([]);
    const [assessmentDetailHeadersRowsState, setAssessmentDetailHeadersRowsState] = useState<Cell[]>([]);
    const [search, setSearch] = useState<string>('');

    const {
        state: { currentTheme, globalSettings }
    } = useContext(UserStore);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const { loading, data } = useQuery<Assessment>(ASSESSMENT, {
        variables: {
            id: assessmentId
        }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const capabilityShortNames = data && data.assessment.framework.definition.domains.reduce(
        (newArray: any, domain: { capabilities: any; shortName: string}): any => {
            const updatedObject = domain.capabilities.map((c: any) => ({
                ...c,
                ['domainShortName']: domain.shortName

            }));
            return [...newArray, ...updatedObject];
        },
        []
    );

    const [filterStatus, setFilterStatus] = useState('ALL');
    const [notFilteredData, setNotFilteredData] = useState<DetailsTableRows[]>(
        []
    );
    const [filteredData, setFilteredData] = useState<DetailsTableRows[]>([]);

    const feedbackToggler = (id: string) => {
        if (openedFeedback.some(feedbackId => feedbackId === id)) {
            const newOpenedFeedback = openedFeedback.filter(
                feedbackId => feedbackId !== id
            );
            setOpenedFeedback(newOpenedFeedback);
        } else {
            setOpenedFeedback([...openedFeedback, id]);
        }
    };

    const [filteredCapabilities] = useFilteredCapabilities(
        capabilities,
        openedFeedback,
        feedbackToggler,
        detailsTabData,
        filterStatus,
        currentTheme,
        showTargetScore,
        readOnlyEnabled,
        isReadOnly,
        pointOneDecimalEnable,
        isObjectiveComponentExist
    );

    useEffect(() => {
        if(showTargetScore){
            const targetScoreObj: Cell = {
                id: 'targetScore',
                disablePadding: false,
                align: 'center',
                label: (
                    <TableHeadTitle>
                        Target <br /> score
                    </TableHeadTitle>
                ),
                style: { padding: '5px 16px' }
            };
            setAssessmentDetailHeadersRowsState([...assessmentDetailHeadersRows(isObjectiveComponentExist), targetScoreObj]);
        } else {
            setAssessmentDetailHeadersRowsState(assessmentDetailHeadersRows(isObjectiveComponentExist));
        }
    }, [showTargetScore]);

    useEffect(() => {
        setFilteredData(filteredCapabilities);
        setNotFilteredData(filteredCapabilities);
    }, [filteredCapabilities, filterStatus]);

    useEffect(() => {
        if (!data) {
            return undefined;
        }
        setFilteredData(
            detailsTableRows(
                capabilities.map((c: any) => {
                    const d = capabilityShortNames.find((cs: any) => cs.name === c.name);
                    c.shortName = d.shortName;
                    c.domainShortName = d.domainShortName;
                    return c;
                }),
                openedFeedback,
                feedbackToggler,
                detailsTabData,
                filterStatus,
                currentTheme,
                showTargetScore,
                readOnlyEnabled,
                isReadOnly,
                pointOneDecimalEnable,
                isObjectiveComponentExist
            )
        );
    }, [currentTheme, openedFeedback, capabilities, showTargetScore]);

    useEffect(() => {
        if (loading || !data) {
            return undefined;
        }

        const newCapabilities = data.assessment.state.domains.reduce(
            (newArray, domain): any => {

                const capabilitiesArray= domain.capabilities.map((firstObject)=>{
                    const matchedObject = capabilityShortNames.find((cs: any) => cs.name === firstObject.name);
                    if(matchedObject){
                        return {...firstObject, ...matchedObject };
                    }
                });
                return [...newArray, ...capabilitiesArray];
            },
            []
        );
        setCapabilities(newCapabilities);
        setFilteredData(
            detailsTableRows(
                newCapabilities.map((c: any) => {
                    const d = capabilityShortNames.find((cs: any) => cs.name === c.name);
                    c.shortName = d.shortName;
                    c.domainShortName = d.domainShortName;
                    return c;
                }),
                openedFeedback,
                feedbackToggler,
                detailsTabData,
                filterStatus,
                currentTheme,
                showTargetScore,
                readOnlyEnabled,
                isReadOnly,
                pointOneDecimalEnable,
                isObjectiveComponentExist
            )
        );
    }, [data, loading]);

    const expandAll = () => {

        const allCapabilities: any[] = capabilities
            .filter(
                (capability: Capability) => capability.feedback.length !== 0
            ).filter((capability: Capability) => {
                if (readOnlyEnabled && isReadOnly && capability.status !== 'COMPLETED') {
                  return false; // skip
                }
                return true;
              })
            .map((capability: Capability) => {
                if(readOnlyEnabled && isReadOnly && capability.status !== 'COMPLETED') return false;
                return capability.id;
            });
        setOpenedFeedback(allCapabilities);
    };

    const collapseAll = () => { setOpenedFeedback([]); };

    const canCollapse: boolean =
        openedFeedback.length ===
        capabilities.filter(
            (capability: Capability) => capability.feedback.length !== 0
        ).length;

    return loading ? (
        <SpinnerWithOverlay />
    ) : (
        <div>
            <DetailsTable
                search={search}
                rows={filteredData}
                header={
                    <DetailsTableHeader
                        setSearch={setSearch}
                        setFilteredData={setFilteredData}
                        notFilteredData={notFilteredData}
                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                    />
                }
                expandAll={expandAll}
                collapseAll={collapseAll}
                canCollapse={canCollapse}
                headCells={assessmentDetailHeadersRowsState}
                isTargetScore={showTargetScore}
                readOnlyEnabled={readOnlyEnabled}
                isReadOnly={isReadOnly}
                campaignComplete={campaignComplete}
            />
            {isLegendVisible && (
                <Legend
                    setIsLegendVisible={setIsLegendVisible}
                    isTargetScoring={showTargetScore}
                    isReadOnly={isReadOnly}
                    isObjectiveComponentExist={isObjectiveComponentExist}
                />
            )}
        </div>
    );
};

export default DetailsTab;
