import React, { useEffect, useRef, useState } from 'react';
import { CardHeader, Card, CardContent, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Series, Legend, Chart } from './types';
import { barGraph } from './BarGraphHelpers';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import TooltipByClick from '../../Tooltips/TooltipByClick';
import {chartDraftWarning} from '../../../utils';
import ChartWarningTag from '../../base/ChartWarning/ChartWarningTag';
type Props = {
    seriesData: Series[];
    title?: string;
    subtitle?: string[];
    objectivesLabelsList: string[];
    legendData: Legend[];
    barToLine?: boolean;
    ScroolValue?: number;
    xAxisData: string[];
    isCapabilityByDomain: boolean;
    domainTitle?: string;
    isExpandAll?: boolean;
    setExpandAll?: any;
    colors?: string[];
    showCapabilityStatus?: boolean;
};
const Bargraph: React.FC<Props> = ({
    seriesData,
    title,
    subtitle,
    objectivesLabelsList,
    legendData,
    barToLine = false,
    ScroolValue = 100,
    xAxisData,
    isCapabilityByDomain,
    domainTitle,
    isExpandAll,
    showCapabilityStatus,
    setExpandAll,
    colors
}) => {
    const [isDomainGraphOpen, setDomainGraphOpen] = useState<boolean>(true);

    useEffect(() => {
        isExpandAll !== undefined && setDomainGraphOpen(isExpandAll);
    }, [isExpandAll]);

    const container = useRef<HTMLDivElement>(document.createElement('div'));
    useEffect(() => {
        barGraph(
            container.current,
            legendData,
            barToLine,
            objectivesLabelsList,
            seriesData,
            true,
            true,
            xAxisData,
            ScroolValue,
            colors,
            showCapabilityStatus
        );
        setDomainGraphOpen(false);
    }, [seriesData]);

    return (
        <GraphContainer>
            <Card>
                {title && (
                    <>


                        <CardHeader
                            style={{ display: 'grid', justifyItems: 'center' }}
                            title={title}
                        />
                      <ChartWarningTag> {chartDraftWarning}</ChartWarningTag>
                    </>
                )}
                {subtitle && (
                    <CardContent>
                        <Typography align="center" variant="h6" component="h6">
                            {subtitle[0]}
                        </Typography>
                        <Typography
                            align="center"
                            variant="body1"
                            component="p"
                        >
                            {subtitle[1]}
                        </Typography>
                    </CardContent>
                )}
                {setExpandAll && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                        <ExpandAll
                            onClick={() => {
                                setExpandAll(!isExpandAll);
                            }}
                        >
                            {isExpandAll ? <p>Collapse All</p> : <p>Expand All</p>}
                        </ExpandAll>
                    </div>
                )}
                {isCapabilityByDomain ? (
                    <DomainGraphWrapper>
                        <DomainTitleWrapper>
                            <DomainTitle>{domainTitle}</DomainTitle>
                            {isDomainGraphOpen ? (
                                <IconButton
                                    style={{ marginRight: 52 }}
                                    onClick={() => setDomainGraphOpen(false)}
                                    aria-label="collapse domain graph"
                                >
                                    <CollapseIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    style={{ marginRight: 52 }}
                                    onClick={() => setDomainGraphOpen(true)}
                                    aria-label="expand domain graph"
                                >
                                    <ExpandIcon />
                                </IconButton>
                            )}
                        </DomainTitleWrapper>
                        <GraphDiv
                            ref={container}
                            tabIndex={0}
                            style={{
                                display: isDomainGraphOpen ? 'block' : 'none'
                            }}
                        />
                    </DomainGraphWrapper>
                ) : (
                    <GraphDiv ref={container} tabIndex={0} />
                )}
            </Card>
        </GraphContainer>
    );
};

const GraphContainer = styled('div')`
    width: 1200px;
    @media (max-width: 1370px) {
        transform: scale(0.9);
    }
    @media (max-width: 1230px) {
        transform: scale(0.8);
    }
    @media (max-width: 1100px) {
        transform: scale(0.7);
    }
    @media (max-width: 960px) {
        transform: scale(0.6);
    }
    @media (max-width: 820px) {
        transform: scale(0.5);
    }
    @media (max-width: 700px) {
        transform: scale(0.4);
    }
`;
const DomainGraphWrapper = styled('div')`
    border-top: 1px solid #bfbfbf;
`;

const DomainTitle = styled('div')`
    font-size: 17px;
    margin-left: 47px;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #575757;
`;

const DomainTitleWrapper = styled('div')`
    display: flex;
    height: 65px;
    align-items: center;
    justify-content: space-between;
`;

const ExpandIcon = styled(KeyboardArrowDown)`
    border-radius: 2px;
    background-color: rgba(45, 45, 45, 0.15);
`;

const CollapseIcon = styled(KeyboardArrowUp)`
    border-radius: 2px;
    background-color: rgba(45, 45, 45, 0.15);
`;

const ExpandAll = styled('button')`
    margin-right: 50px;
    display: flex;
    justify-self: flex-end;
    font-size: 16px;
    text-decoration: underline;
    border: none;
    background: none;
`;

const GraphDiv = styled('div')`
    height: 600px;
    width: 100%;
`;
export let myChart: Chart;
export default Bargraph;
