import React from 'react';
import { Text } from '../../index';
type Props = {
    children: React.ReactNode;
};
export const ErrorText=(props: Props)=>{
    return <Text style={{color: '#de3328', marginBottom: '5px'}}>
        {props.children}
    </Text>;
};
