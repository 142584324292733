import { gql } from 'apollo-boost';

export const CAMPAIGNS = gql`
    query availableCampaignsByClient8($clientId: Int!) {
        availableCampaignsByClient(clientId: $clientId) {
            id
            title
            description
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCompletedCnt
            assessmentsCnt
            progress
            client {
                id
                name
            }
            stakeholder {
                id
                firstName
                lastName
            }
            manager {
                id
                firstName
                lastName
            }
            members {
                id
                firstName
                lastName
            }
            readonlymembers {
                id
                firstName
                lastName
            }
            framework {
                id
                definition {
                    name
                }
            }
            status
            isCampaignEntityUser
            updatedAt
            closedAt
        }
    }
`;

export const ASSESSMENT_LIST = gql`
    query assessmentsByCampaign1($campaignId: ID!, $id: ID!) {
        assessmentsByCampaignWithGroups(campaignId: $campaignId) {
            groupName
            assessments {
                id
                campaign {
                    id
                    title
                    status
                }
                campaignEntity {
                    id
                    name
                    archivedDate
                }
                state {
                    status
                    inScope
                    averageScore
                    meetings{
                        id
                        capabilities {
                            id
                        }
                    }
                    domains {
                        id
                        name
                        averageScore
                        moderatedAverageScore
                        targetAverageScore
                        capabilities {
                            status
                            id
                            name
                            averageScore
                            inScope
                            moderatedScore
                            targetScore
                            meetings {
                                id
                                title
                            }
                            objectives {
                                id
                                score
                                compensatingControlEnable
                                inScope
                                notes
                            }
                            objectivesScoredCnt
                            feedback {
                                id
                                observations {
                                    text
                                }
                                recommendations {
                                    text
                                }
                            }
                            workingNotes {
                                text
                            }
                        }
                    }
                }
                framework {
                    id
                    version
                    definition {
                        name
                        shortName
                        scores
                        domains {
                            name
                            shortName
                            capabilities {
                                name
                                shortName
                            }
                        }
                    }
                }
                archivedDate
            }
        }
        assessmentsByCampaign(campaignId: $campaignId) {
            id
            campaignEntity {
                id
                name
                archivedDate
            }
            campaign {
                id
                title
                progress
            }
            state {
                status
                capabilitiesCnt
                capabilitiesScoredCnt
                capabilitiesModeratedCnt
            }
            updatedAt
            archivedDate
        }
        campaign(id: $id) {
            id
            title
            progress
            readOnly
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCnt
            assessmentsNotStartedCnt
            assessmentsInProgressCnt
            assessmentsCompletedCnt
            manager {
                id
            }
            members {
                id
            }
            readonlymembers {
                id
            }
            status
            closedAt
            targetScoreEnabled
            pointOneDecimalEnable
            benchmarkingEnabled
            updatedAt
        }
    }
`;

export const ASSESSMENT_LIST_PAGINATE = gql`
    query assessmentsByCampaign3($campaignId: ID!, $id: ID!, $pagination: PaginationInput!) {
        assessmentsByCampaignWithGroupsWithPagination(campaignId: $campaignId, pagination: $pagination) {
            items {
                groupName
                assessments {
                    id
                    campaign {
                        id
                        title
                        status
                        progress
                    }
                    campaignEntity {
                        id
                        name
                        archivedDate
                    }
                    state {
                        status
                        inScope
                        averageScore
                        meetings{
                            id
                            capabilities {
                                id
                            }
                        }
                        domains {
                            id
                            name
                            averageScore
                            moderatedAverageScore
                            targetAverageScore
                            feedback {
                                id
                                observations {
                                    text
                                }
                                recommendations {
                                    text
                                }
                            }
                            capabilities {
                                status
                                id
                                name
                                averageScore
                                inScope
                                moderatedScore
                                targetScore
                                meetings {
                                    id
                                    title
                                }
                                objectives {
                                    id
                                    score
                                    compensatingControlEnable
                                    inScope
                                    notes
                                }
                                objectivesScoredCnt
                                feedback {
                                    id
                                    observations {
                                        text
                                    }
                                    recommendations {
                                        text
                                    }
                                }
                                workingNotes {
                                    text
                                }
                            }
                        }
                    }
                    framework {
                        id
                        version
                        definition {
                            name
                            shortName
                            scores
                            domains {
                                name
                                shortName
                                capabilities {
                                    name
                                    shortName
                                }
                            }
                        }
                    }
                    archivedDate
                    permission
                }
            }
            take
            skip
            total
            count
        }
        assessmentsByCampaign(campaignId: $campaignId) {
            id
            campaignEntity {
                id
                name
                archivedDate
            }
            campaign {
                id
                title
                progress
            }
            state {
                status
                capabilitiesCnt
                capabilitiesScoredCnt
                capabilitiesModeratedCnt
            }
            updatedAt
            archivedDate
            permission
        }
        campaign(id: $id) {
            id
            title
            progress
            readOnly
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCnt
            assessmentsNotStartedCnt
            assessmentsInProgressCnt
            assessmentsCompletedCnt
            manager {
                id
            }
            members {
                id
            }
            readonlymembers {
                id
            }
            status
            closedAt
            targetScoreEnabled
            pointOneDecimalEnable
            benchmarkingEnabled
            isCampaignEntityUser
            updatedAt
        }
    }
`;

export const CLOSE_CAMPAIGN = gql`
    mutation closeCampaign($data: CloseCampaignInput!) {
        closeCampaign(data: $data) {
            id
            status
            closedAt
        }
    }
`;
