export enum Routes {
    splash = '/',
    loginRedirect = '/login/redirect',
    logoutRedirect = '/logout/redirect',
    main = '/main',
    dashboard = '/main/dashboard',
    benchmarking = '/main/analyse/benchmarking',
    comparison = '/main/analyse/comparison',
    threatAndRisk = '/main/analyse/threatandrisk',
    evaluateCampaign = '/main/evaluate/campaigns',
    createCampaign = '/main/evaluate/campaigns/create',
    editCampaign = '/main/evaluate/campaigns/edit/:campaignId/:step',
    evaluateAssessments = '/main/evaluate/assessments',
    maturityOverview = '/main/evaluate/maturity/:campaignId',
    stakeholderManagement = '/main/stakeholder-management',
    assessmentMaturityScore = '/main/evaluate/maturity/score/campaing/:campaignId/assessment/:assessmentId/domain/:domainId/capability/:capabilityId',
    assessmentsMaturityOverview = '/main/evaluate/maturity/:campaignId/assessments/:assessmentId',
    companyStructure = '/main/company-structure',
    termsOfUse = '/main/terms-of-use'
}

export class DynamicRoutes {
    public static goToMaturityOverview(campaignId: string) {
        return `/main/evaluate/maturity/${campaignId}`;
    }
    public static goToCampaignCreation(campaignId: string, step?: number) {
        return `/main/evaluate/campaigns/edit/${campaignId}/${step}`;
    }
    public static goToMaturityScore({
        campaignId,
        assessmentId,
        domainId,
        capabilityId
    }: {
        campaignId: string;
        assessmentId: string;
        domainId: string | number;
        capabilityId: string | number;
    }) {
        return `/main/evaluate/maturity/score/campaing/${campaignId}/assessment/${assessmentId}/domain/${domainId}/capability/${capabilityId}`;
    }
    public static goToAssessmentsMaturityOverview(
        campaignId: string,
        assessmentId: string
    ) {
        return `/main/evaluate/maturity/${campaignId}/assessments/${assessmentId}`;
    }
}
