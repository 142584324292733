import { CurrentIdsData, Feedback, Recommendations, Values, CapabilityObjectiveScore, CurrentCapability, ObjectivesUI } from '../types';
import {roundFloor} from '../../../../utils';

export const handleMaturityScoreChange = ({
    moderatedValue,
    updateModeratedScore,
    currentAssessmentId,
    currentIdsData
}: {
    moderatedValue: number | null;
    updateModeratedScore: (data: object) => Promise<object>;
    currentAssessmentId: string;
    currentIdsData: CurrentIdsData;
}) => {
    updateModeratedScore({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                moderatedScore: moderatedValue
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const handleObservationCreate = ({
    currentAssessmentId,
    feedbackIndex,
    editCards,
    feedback,
    currentIdsData
}: {
    currentAssessmentId: string;
    editCards: (data: object) => Promise<object>;
    feedback: Feedback[];
    feedbackIndex: number;
    currentIdsData: CurrentIdsData;
}) => {
    editCards({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                feedback: feedback.map(
                    (item: Feedback, currentFeedbackIndex: number) => {
                        if (currentFeedbackIndex === feedbackIndex) {
                            return {
                                ...item,
                                observations: [{ text: '' }]
                            };
                        }
                        return item;
                    }
                )
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const handleUpdateCard = ({
    currentAssessmentId,
    editCards,
    feedback,
    currentIdsData
}: {
    currentAssessmentId: string;
    editCards: (data: object) => Promise<object>;
    feedback: Feedback[];
    currentIdsData: CurrentIdsData;
}) => {
    return editCards({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                feedback
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const handleUpdateDomainFeedback = ({
    currentAssessmentId,
    editDomainFeedback,
    feedback,
    currentIdsData
}: {
    currentAssessmentId: string;
    editDomainFeedback: (data: object) => Promise<object>;
    feedback: Feedback[];
    currentIdsData: CurrentIdsData;
}) => {
    return editDomainFeedback({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                feedback
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const handleRecommendationCreate = ({
    feedbackIndex,
    feedback,
    currentAssessmentId,
    editCards,
    currentIdsData
}: {
    feedbackIndex: number;
    feedback: Feedback[];
    editCards: (data: object) => Promise<object>;
    currentAssessmentId: string;
    currentIdsData: CurrentIdsData;
}) => {
    editCards({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                feedback: feedback.map(
                    (item: Feedback, currentFeedbackIndex: number) => {
                        if (currentFeedbackIndex === feedbackIndex) {
                            return {
                                ...item,
                                recommendations: [
                                    ...item.recommendations,
                                    { text: '' }
                                ]
                            };
                        }
                        return item;
                    }
                )
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const handleCreateCards = ({
    currentAssessmentId,
    values,
    editCards,
    currentIdsData
}: {
    currentAssessmentId: string;
    values: Values;
    editCards: (data: object) => Promise<object>;
    currentIdsData: CurrentIdsData;
}) => {
    editCards({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                feedback: [
                    ...values.feedback,
                    {
                        observations: values.newObservation
                            ? {
                                  text: values.newObservation
                              }
                            : [],
                        recommendations: values.newRecommendation
                            ? [
                                  {
                                      text: values.newRecommendation
                                  }
                              ]
                            : []
                    }
                ]
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

const handleDeleteCard = ({
    currentAssessmentId,
    currentIdsData,
    index,
    editCards,
    feedback,
    feedbackIndex
}: {
    index: number;
    editCards: (data: object) => Promise<object>;
    feedback: Feedback[];
    currentAssessmentId: string;
    currentIdsData: CurrentIdsData;
    feedbackIndex: number;
}) => {
    editCards({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                feedback: feedback.map(
                    (item: Feedback, currentItemIndex: number) => {
                        if (currentItemIndex === feedbackIndex) {
                            return {
                                ...item,
                                recommendations: item.recommendations.filter(
                                    (
                                        _recommendation: Recommendations,
                                        recommendationIndex: number
                                    ) => recommendationIndex !== index
                                )
                            };
                        }
                        return item;
                    }
                )
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const getRecommendationsMenuActions = ({
    currentAssessmentId,
    index,
    editCards,
    feedback,
    feedbackIndex,
    setFieldValue,
    currentIdsData
}: {
    index: number;
    editCards: (data: object) => Promise<object>;
    feedback: Feedback[];
    currentAssessmentId: string;
    feedbackIndex: number;
    setFieldValue: (field: string, value: any) => void;
    currentIdsData: CurrentIdsData;
}) => {
    return [
        {
            label: 'Edit',
            handleClick: () => {
                setFieldValue('editableCard', {
                    editableGroupIndex: feedbackIndex,
                    editableItemIndex: index,
                    name: 'recommendation'
                });
            }
        },
        {
            label: 'Delete',
            handleClick: () => {
                handleDeleteCard({
                    currentAssessmentId,
                    currentIdsData,
                    index,
                    editCards,
                    feedback,
                    feedbackIndex
                });
            }
        },
        {
            label: 'Add recommendation',
            handleClick: () =>
                handleRecommendationCreate({
                    feedbackIndex,
                    feedback,
                    currentAssessmentId,
                    currentIdsData,
                    editCards
                })
        }
    ];
};

export const deleteObservationCard = ({
    currentAssessmentId,
    editCards,
    feedback,
    feedbackIndex,
    currentIdsData
}: {
    feedback: Feedback[];
    editCards: (data: object) => Promise<object>;
    currentAssessmentId: string;
    currentIdsData: CurrentIdsData;
    feedbackIndex: number;
}) => {
    editCards({
        variables: {
            data: {
                assessmentId: currentAssessmentId,
                domainId: currentIdsData.domainId,
                capabilityId: currentIdsData.capabilityId,
                feedback: feedback.map(
                    (item: Feedback, currentItemIndex: number) => {
                        if (currentItemIndex === feedbackIndex) {
                            return {
                                ...item,
                                observations: []
                            };
                        }
                        return item;
                    }
                )
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const getObservationsMenuActions = ({
    currentAssessmentId,
    editCards,
    feedback,
    feedbackIndex,
    setFieldValue,
    currentIdsData
}: {
    feedback: Feedback[];
    editCards: (data: object) => Promise<object>;
    currentAssessmentId: string;
    feedbackIndex: number;
    setFieldValue: (field: string, value: any) => void;
    currentIdsData: CurrentIdsData;
}) => {
    return [
        {
            label: 'Edit',
            handleClick: () => {
                setFieldValue('editableCard', {
                    editableGroupIndex: feedbackIndex,
                    editableItemIndex: null,
                    name: 'observation'
                });
            }
        },
        {
            label: 'Delete',
            handleClick: () => {
                deleteObservationCard({
                    currentAssessmentId,
                    editCards,
                    feedback,
                    feedbackIndex,
                    currentIdsData
                });
            }
        },
        {
            label: 'Add recommendation',
            handleClick: () =>
                handleRecommendationCreate({
                    feedbackIndex,
                    feedback,
                    currentAssessmentId,
                    currentIdsData,
                    editCards
                })
        }
    ];
};

export const generateTitle = (name: string) => {
    return name !== undefined ? name
        .split(' ')
        .map((item: string) => item[0])
        .join('') : name;
};

export const fillModerationFeedback = (feedbacks?: Feedback[] | null) => {
    if (feedbacks && feedbacks.length) {
        return feedbacks.map((feedback: Feedback) => ({
            title: feedback.title,
            observations: feedback.observations.length
                ? feedback.observations.map((item: { text: string }) => ({
                      text: item.text
                  }))
                : feedback.observations,
            recommendations: feedback.recommendations.length
                ? feedback.recommendations.map((item: { text: string }) => ({
                      text: item.text
                  }))
                : feedback.recommendations
        }));
    }
    return [
        {
            title: '',
            observations: [
                {
                    text: ''
                }
            ],
            recommendations: [
                {
                    text: ''
                }
            ]
        }
    ];
};

export const getObjectivesRange = (currentCapability: CurrentCapability,onePointDecimal: boolean) => {
    const sortRules = (
        a: CapabilityObjectiveScore,
        b: CapabilityObjectiveScore
    ) => {
        if (a.score > b.score) {
            return 1;
        }
        if (b.score > a.score) {
            return -1;
        }

        return 0;
    };

    const scoreToPercentage: {score: number; percentage: number}[] = [
        {score: 0, percentage: 16},
        {score: 0.1, percentage: 17},
        {score: 0.2, percentage: 19},
        {score: 0.3, percentage: 12},
        {score: 0.4, percentage: 23},
        {score: 0.5, percentage: 25},
        {score: 0.6, percentage: 26},
        {score: 0.7, percentage: 28},
        {score: 0.8, percentage: 30},
        {score: 0.9, percentage: 32},
        {score: 1, percentage: 33},
        {score: 1.1, percentage: 34},
        {score: 1.2, percentage: 36},
        {score: 1.3, percentage: 38},
        {score: 1.4, percentage: 40},
        {score: 1.5, percentage: 41},
        {score: 1.6, percentage: 43},
        {score: 1.7, percentage: 45},
        {score: 1.8, percentage: 47},
        {score: 1.9, percentage: 49},
        {score: 2, percentage: 50},
        {score: 2.1, percentage: 51},
        {score: 2.2, percentage: 53},
        {score: 2.3, percentage: 55},
        {score: 2.4, percentage: 57},
        {score: 2.5, percentage: 58},
        {score: 2.6, percentage: 59},
        {score: 2.7, percentage: 61},
        {score: 2.8, percentage: 63},
        {score: 2.9, percentage: 65},
        {score: 3, percentage: 66},
        {score: 3.1, percentage: 67},
        {score: 3.2, percentage: 69},
        {score: 3.3, percentage: 71},
        {score: 3.4, percentage: 73},
        {score: 3.5, percentage: 75},
        {score: 3.6, percentage: 76},
        {score: 3.7, percentage: 78},
        {score: 3.8, percentage: 80},
        {score: 3.9, percentage: 82},
        {score: 4, percentage: 83},
        {score: 4.1, percentage: 84},
        {score: 4.2, percentage: 86},
        {score: 4.3, percentage: 88},
        {score: 4.4, percentage: 90},
        {score: 4.5, percentage: 91},
        {score: 4.6, percentage: 92},
        {score: 4.7, percentage: 94},
        {score: 4.8, percentage: 96},
        {score: 4.9, percentage: 98},
        {score: 5, percentage: 100}
    ];

    const obj=[...currentCapability.objectives].sort(sortRules);
    const currentCapability_Sort = obj.filter((item: CapabilityObjectiveScore) => item.score !== null && item.score !== undefined);
    let leftValue, rightValue;
    if(currentCapability_Sort !== null && currentCapability_Sort.length > 0){
        leftValue = scoreToPercentage.find((sTP: {score: number; percentage: number}) => sTP.score === roundFloor(currentCapability_Sort[0].score,onePointDecimal));
        rightValue = scoreToPercentage.find((sTP: {score: number; percentage: number}) => sTP.score === roundFloor(currentCapability_Sort[currentCapability_Sort.length - 1].score,onePointDecimal));
    }
    return {leftValue, rightValue};
};

export const displayObjectivesScores = (smallestObjective?: ObjectivesUI, biggestObective?: ObjectivesUI ) => {
    return `${smallestObjective ? smallestObjective.score : ''} ${smallestObjective && biggestObective ? '-' : ''} ${biggestObective ? biggestObective.score : ''}`;
};
