export const customModalStyles = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        maxHeight: 'calc(100% - 72px)',
        left: '50%',
        padding: 0,
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
};

export const obsAndConsStyles = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        maxHeight: 'calc(100% - 72px)',
        padding: 0,
        right: 0,
        top: '72px',
        width: '90vw',
        overflow: 'auto',
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
};

export const customModalStylesMangeMembers = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        left: '50%',
        padding: 0,
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        minWidth: 820,
        maxWidth: 1200,
        height: '80%',
        maxHeight: 1024,
        display: 'flex',
        flexDirection: 'column' as const,
        
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
};

export const customModalStylesMangeMembersDialog = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        left: '50%',
        padding: 0,
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        minWidth: 400,
        maxWidth: 750,
        minHeight: 'auto',
        
        display: 'flex',
        flexDirection: 'column' as const,
        
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
};


