import React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Flex } from '../Layout';
import iconMagnifier from '../../../assets/images/icon-magnifier-gray.svg';

type Props = {
    placeholder: string;
    callback: any;
    style?: React.CSSProperties;
};
const SearchInline: React.FC<Props> = ({ placeholder, callback, style }) => {
    return (
        <Wrapper style={style}>
            <IconWrapper src={iconMagnifier} alt="magnifier icon" />
            <Search
                type="search"
                placeholder={placeholder}
                fullWidth
                autoFocus
                onChange={(e: any) => {
                    callback(e.target.value);
                }}
            />
        </Wrapper>
    );
};

const Search = styled(InputBase)`
    && {
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        &::-webkit-input-placeholder {
            color: #818181;
        }
    }
`;

const Wrapper = styled(Flex)`
    height: 38px;
    border-radius: 2px;
    background-color: #f0f0f0;
    position: relative;
    box-sizing: border-box;
    margin: 0 8px 8px;
`;

const IconWrapper = styled('img')`
    padding: 0 12px 0 8px;
    cursor: pointer;
    width: 20px;
`;

export default SearchInline;
