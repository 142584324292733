import { ListItem, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import  ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ObjectivesDropdownList, ObjectiveInList } from '../../../../../types';

type Props = {
    capability: ObjectivesDropdownList;
    setSelectedObjectives: any;
    selectedObjectives: string[];
    isGuidance: boolean;
};

const ObjectiveItem: React.FC<Props> = ({
    capability,
    setSelectedObjectives,
    selectedObjectives,
    isGuidance
}) => {
    const OBJECTIVE_PREFIX = 'CustomObjectiveStyles';

    const classes = {
        main: `${OBJECTIVE_PREFIX}-main`,
        root: `${OBJECTIVE_PREFIX}-root`,
        popoverWrappper: `${OBJECTIVE_PREFIX}-popoverWrappper`,
        listWrappper: `${OBJECTIVE_PREFIX}-listWrappper`,
        section: `${OBJECTIVE_PREFIX}-section`,
        sectionTitle: `${OBJECTIVE_PREFIX}-sectionTitle`,
        sectionBody: `${OBJECTIVE_PREFIX}-sectionBody`,
        listItem: `${OBJECTIVE_PREFIX}-listItem`
    };

    const handleSelectAll = () => {
        const allCurrentObj = capability.objectives.map(
            (obj: ObjectiveInList) => obj.reference
        );
        if (
            !allCurrentObj.every((el: string) =>
                selectedObjectives.includes(el)
            )
        ) {
            const filteredArr = allCurrentObj.filter(
                (el: string) => !selectedObjectives.includes(el)
            );
            setSelectedObjectives([...selectedObjectives, ...filteredArr]);
        } else {
            const newObjectiveList = selectedObjectives.filter(
                (obj: string) => !allCurrentObj.includes(obj)
            );
            setSelectedObjectives(newObjectiveList);
        }
    };

    const checkAll = () => {
        const allCurrentObj = capability.objectives.map(
            (obj: ObjectiveInList) => obj.reference
        );
        return allCurrentObj.every((el: string) =>
            selectedObjectives.includes(el)
        );
    };

    const checkObjective = (objective: string) => {
        const contains = selectedObjectives.includes(objective);

        if (!contains) {
            setSelectedObjectives([...selectedObjectives, objective]);
        } else {
            const newObjectiveList = selectedObjectives.filter(
                (obj: string) => objective !== obj
            );
            setSelectedObjectives(newObjectiveList);
        }
    };

    const selectedCount = () => {
        const selectedValues = capability.objectives.filter(
            (objective: ObjectiveInList) =>
                selectedObjectives.includes(objective.reference)
        );
        return selectedValues.length + ' selected';
    };

    return (
        <ListItem style={{
            padding: 0,
            width: '100%'
        }}>
            <ExpansionPanelMain>
                <ExpansionPanelHeader
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="objective-header"
                >
                    <LabelWrapper>
                        <FormControlLabel
                            onClick={event => {
                                event.stopPropagation();
                                handleSelectAll();
                            }}
                            control={<Checkbox checked={checkAll()} />}
                            label={
                                <SectionTitle
                                    id={'label-' + capability.name}>
                                    {capability.name}
                                </SectionTitle>
                            }
                        />
                    </LabelWrapper>
                    <SelectedCountText>{selectedCount()}</SelectedCountText>
                </ExpansionPanelHeader>
                {capability.objectives.map(
                    (objective: ObjectiveInList, index: number) => {
                        return (
                            <ExpansionPanelBody
                                key={index}
                                style={{padding: '8px 24px 12px 24px'}}
                            >
                                <FormControlLabel
                                    onClick={() =>
                                        checkObjective(objective.reference)
                                    }
                                    control={
                                        <Checkbox
                                            checked={selectedObjectives.includes(
                                                objective.reference
                                            )}
                                        />
                                    }
                                    label={
                                        <SectionTitle>
                                            {objective.title}
                                        </SectionTitle>
                                    }
                                />
                                {isGuidance && (
                                    <Typography
                                        color="textSecondary"
                                        style={{
                                            paddingLeft: '26px',
                                            fontSize: '15px',
                                            color: '#575757'
                                        }}>
                                        {objective.name}
                                    </Typography>
                                )}
                            </ExpansionPanelBody>
                        );
                    }
                )}
            </ExpansionPanelMain>
        </ListItem>
    );
};

const SectionTitle = styled(Typography)({
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#2d2d2d',
    maxWidth: '270px',
    pointerEvents: 'none'
});

const ExpansionPanelMain = styled(ExpansionPanel)`
    border-radius: 0 !important;
    width: 100%;
`;

const ExpansionPanelHeader = styled(ExpansionPanelSummary)`
    padding-left: 8px !important;
    border-bottom: 1px solid #f0f0f0 !important;
    .MuiExpansionPanelSummary-expandIcon {
        margin-bottom: 2px;
    }
`;

const ExpansionPanelBody = styled(ExpansionPanelDetails)`
    display: flex;
    flex-direction: column;
    padding-left: 32px !important;
`;

const LabelWrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const SelectedCountText = styled('span')`
    margin-left: auto;
    margin-right: 8px;
    color: #6E6E6E;
    font-size: 12px;
    line-height: 45px;
`;

export default ObjectiveItem;
