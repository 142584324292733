import React, { RefObject } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRight from '@mui/icons-material/ArrowRight';

type Props = {
    label: string;
    mainMenuOpen: boolean;
    children: React.ReactNode;
    disabled: boolean | undefined;
};

// Modified from: https://github.com/mui/material-ui/issues/11723#issuecomment-632132246
class NestedMenuItem extends React.Component<Props> {
    state: {
        subMenuOpen: boolean;
    };
    subMenuRef: RefObject<HTMLDivElement>;
    nestedMenuRef: RefObject<HTMLLIElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            subMenuOpen: false
        };
        this.subMenuRef = React.createRef<HTMLDivElement>();
        this.nestedMenuRef = React.createRef<HTMLLIElement>();
    }

    isSubmenuFocused() {
        const active = this.nestedMenuRef.current?.ownerDocument?.activeElement;
        for (const child of Array.from(
            this.subMenuRef.current?.children ?? []
        )) {
            if (child === active) return true;
        }
        return false;
    }
    handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        this.setState({ subMenuOpen: true });
        if (this.nestedMenuRef.current)
            this.nestedMenuRef.current.style.backgroundColor = '#dddddd';
    };
    handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({ subMenuOpen: false });
        if (this.nestedMenuRef.current)
            this.nestedMenuRef.current.style.backgroundColor = 'white';
    };
    handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        this.setState({ subMenuOpen: this.state.subMenuOpen ? false : true });
    };
    handleFocus = (evt: React.FocusEvent<HTMLLIElement>) => {
        if (evt.target === this.nestedMenuRef.current) {
            this.setState({ subMenuOpen: true });
            this.nestedMenuRef.current.style.backgroundColor = '#dddddd';
        }
    };
    handleKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
        const arrowRight = 'ArrowRight';
        const arrowLeft = 'ArrowLeft';
        const length = this.subMenuRef.current?.children.length;
        if (length && length > 0) {
            // When keyboard nav goes out of bounds, wrap around the current menu
            // and prevent parent menu from receiving the key input
            if (
                evt.target === this.subMenuRef.current?.children[length - 1] &&
                evt.key === 'ArrowDown'
            ) {
                evt.stopPropagation();
                const itm = this.subMenuRef.current?.children[0] as HTMLElement;
                itm.focus();
            } else if (
                evt.target === this.subMenuRef.current?.children[0] &&
                evt.key === 'ArrowUp'
            ) {
                evt.stopPropagation();
                const itm = this.subMenuRef.current?.children[
                    length - 1
                ] as HTMLElement;
                itm.focus();
            } else if (this.isSubmenuFocused()) {
                evt.stopPropagation();
            }
        }
        // Handle arrow key directions behaviour
        if (evt.key === arrowRight && !this.isSubmenuFocused()) {
            if (!this.state.subMenuOpen) {
                this.setState({ subMenuOpen: true });
            }
            const itm = this.subMenuRef.current?.children[0] as HTMLElement;
            itm.focus();
            evt.stopPropagation();
        } else if (
            (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') &&
            evt.target === this.nestedMenuRef.current
        ) {
            this.setState({ subMenuOpen: false });
            this.nestedMenuRef.current.style.backgroundColor = 'white';
        } else if (evt.key === arrowLeft) {
            this.nestedMenuRef.current?.focus();
            this.setState({ subMenuOpen: false });
        }
    };
    handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        this.setState({ subMenuOpen: false });
    };

    render() {
        return (
            <MenuItem
                ref={this.nestedMenuRef}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
                onFocus={this.handleFocus}
                onKeyDown={this.handleKeyDown}
                style={{ outline: 'none', overflow: 'hidden' }}
                tabIndex={0}
                disabled={this.props.disabled}
            >
                {this.props.label}
                <ArrowRight />
                <Menu
                    // set to pointerEvents to none to prevent menu from capturing
                    // events meant for child elements
                    style={{ pointerEvents: 'none', overflow: 'none' }}
                    onMouseLeave={(evt) => {}}
                    anchorEl={this.nestedMenuRef.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    open={this.state.subMenuOpen && this.props.mainMenuOpen}
                    onClose={this.handleClose}
                    disableAutoFocus
                    disableEnforceFocus
                    disableRestoreFocus
                >
                    <div
                        ref={this.subMenuRef}
                        style={{ pointerEvents: 'auto' }}
                    >
                        {this.props.children}
                    </div>
                </Menu>
            </MenuItem>
        );
    }
}

export default NestedMenuItem;
