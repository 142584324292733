import React from 'react';
import { styled } from '@mui/material/styles';

const renderCapabilityOutOfScope = (callback: any) => {
    return (
        <Block
            aria-label='out of scope capability button'
            onClick={() => callback()}
            style={{
                background:
                    'repeating-linear-gradient(-45deg, transparent, transparent 4px, grey 7px, grey 7px)'
            }}
        />
    );
};

const Block = styled('button')`
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: none;
`;

export default renderCapabilityOutOfScope;
