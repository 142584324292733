import React, { useContext, useState, useEffect } from 'react';
import { CustomTable, SpinnerWithOverlay } from '../../components';
import EvaluateHeader from './EvaluateHeader';
import { UserStore } from '../../context/user-store';
import {
    headCells,
    useFilteredCampaigns,
    useCampaignStatus,
    isAnyDescopedDomain
} from './EvaluateHelpers';
import { Campaign, CampaignTableRows } from './types';
import { createBrowserHistory } from 'history';
import { SUBHEADER_DATA } from '../Layout/LayoutHelpers';
import { useLazyQuery } from '@apollo/react-hooks';
import { AvailableDataByClient } from '../Layout/types';
import Toast from '../../components/Toast';
import { isReadOnlyEnabled,privacyBannerMessage, isPointOneDecimalEnable } from '../../utils';
import { ASSESSMENT_LIST } from './query';
import { EvaluateDetailsData } from './MaturityOverview/types';

const Evaluate: React.FC = () => {
    const {
        state: { client = {}, user, globalSettings },
        dispatch
    } = useContext(UserStore);
    const history = createBrowserHistory();
    // const state: { availableCampaignsByClient: Campaign[] } = (history.location
    //     .state as any) || { availableCampaignsByClient: [] };
    const [search, setSearch] =useState<boolean>(false);
    const [availableData, setAvailableData] = useState<AvailableDataByClient>({
        availableCampaignsByClient: [],
        assessmentsByClient: []
    });
    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [filterStatus, setFilterStatus] = useState('ALL');
    const [notFilteredData, setNotFilteredData] = useState<CampaignTableRows[]>(
        []
    );
    const [filteredData, setFilteredData] = useState<CampaignTableRows[]>([]);
    const [openToast, setopenToast] = useState<boolean>(false);
    const [refetchNeeded, setRefetchNeeded] = useState<boolean>(false);
    const [domainDescoped, setDomainDescoped] = useState<boolean>(false);
    const [availableCampaignsByClient, {loading, refetch}] = useLazyQuery<AvailableDataByClient>(
        SUBHEADER_DATA,
        {
            //fetchPolicy: 'cache-first',
            onCompleted: res => {
                setAvailableData(res);
            },
            onError: err => {
                console.log(err);
            }
        }
    );

    useEffect(() => {
        if (refetchNeeded) {
            refetch();
            setRefetchNeeded(false);
        }
     }, [refetchNeeded]);


    useEffect(() => {
        availableCampaignsByClient({
            variables: { clientId: (client && client.id) || 0 }
        });
    }, [availableCampaignsByClient]);

    const [filteredCampaigns] = useFilteredCampaigns(
        availableData.availableCampaignsByClient || [],
        filterStatus
    );
    const [checkedCampaignsList] = useCampaignStatus(
        availableData.availableCampaignsByClient || [],
        selectedRow
    );

    useEffect(() => {
        setFilteredData(filteredCampaigns);
        setNotFilteredData(filteredCampaigns);
    }, [filteredCampaigns, filterStatus]);

    useEffect(() => {
        if(!user.isBannerDisplayedEvaulate){
            setopenToast(true);
            user.isBannerDisplayedEvaulate = true;
            dispatch({ type: 'SET_USER', value: user });
        }
    }, []);

    const [ getCampaign ] = useLazyQuery<EvaluateDetailsData>(ASSESSMENT_LIST, {
        onCompleted: res => setDomainDescoped(isAnyDescopedDomain(res.assessmentsByCampaignWithGroups)),
        onError: err => console.error(err)
    });

    const selectedHandler = (selected: any) => {
        setSelectedRow(selected);
        if (selected.length === 1)
            getCampaign({ variables: {campaignId: selected[0], id: selected[0]}});
    };

    const selectedActiveCampaigns =
        (availableData.availableCampaignsByClient &&
            availableData.availableCampaignsByClient.filter(
                campaign =>
                    campaign.status !== 'ARCHIVED' &&
                    campaign.closedAt === null &&
                    selectedRow.some(row => row === campaign.id) &&
                    campaign.manager.find(manager => user.id === manager.id)
            )) || [];

    const benchmarkingEnabled = selectedActiveCampaigns && selectedActiveCampaigns.length > 0 && selectedActiveCampaigns.every(campaign => campaign.benchmarkingEnabled === true);
    const pointOneDecimalEnable = isPointOneDecimalEnable(globalSettings) && (selectedActiveCampaigns && selectedActiveCampaigns.length > 0 && selectedActiveCampaigns.every(campaign => campaign.pointOneDecimalEnable === true));

    return loading ? (
        <SpinnerWithOverlay />
    ) : (
        <div>
            <Toast
                open={openToast}
                autoHideDuration={10000}
                title={privacyBannerMessage}
                closeToast={()=>{setopenToast(false);}}
                icon={'info'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            />
            <CustomTable
                search={search}
                selectedHandler={selectedHandler}
                header={
                    <EvaluateHeader
                        checkedCampaignsList={checkedCampaignsList}
                        setSelectedRow={setSelectedRow}
                        setFilteredData={setFilteredData}
                        setFilterStatus={setFilterStatus}
                        notFilteredData={notFilteredData}
                        selectedRow={selectedRow}
                        filterStatus={filterStatus}
                        user={user}
                        selectedActiveCampaigns={selectedActiveCampaigns}
                        client={client}
                        benchmarkingEnabled={benchmarkingEnabled}
                        domainDescoped={domainDescoped}
                        pointOneDecimalEnable={pointOneDecimalEnable}
                        setRefetchNeeded={setRefetchNeeded}
                        setSearch={setSearch}
                    />
                }
                selectedRows={selectedRow}
                rows={filteredData || []}
                headCells={headCells}
            />
        </div>
    );
};

export default Evaluate;
