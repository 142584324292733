import React, { useState, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
    Dialog,
    IconButton,
    Checkbox,
    List,
    ListItem,
    Card,
    CardHeader,
    Divider,
    ListItemIcon
} from '@mui/material';

import { CampaignAssessments } from './types';
import { H1Text, AlignItems, Text, RedButton, SpinnerWithOverlay } from '../../components';
import { Item } from './ListData';
import { handleClientLoad } from './ReportHelpers';
import { CheckboxItemType } from './types';
import legendClose from '../../assets/images/icon-legend-close.svg';
import { UserStore } from '../../context/user-store';

type Props = {
    isExport: boolean;
    modalToggle: boolean;
    setModalToggle: any;
    maturityCont: HTMLDivElement;
    campaignAssessmentsData: any[];
    pointOneDecimalEnable: boolean;
};
const ReportModal: React.FC<Props> = ({
    modalToggle,
    setModalToggle,
    maturityCont,
    campaignAssessmentsData,
    isExport,
    pointOneDecimalEnable
}) => {
    const { state: { client } } = useContext(UserStore);

    const [checked, setChecked] = useState<CheckboxItemType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const checkboxItem: Array<CheckboxItemType> = Item;
    const tempCont = useRef<HTMLDivElement>(document.createElement('div'));
    const handleToggle = (value: CheckboxItemType) => () => {
        const currentIndex = checked.findIndex(x => x.id === value.id);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    const not = (check: CheckboxItemType[], items: CheckboxItemType[]) => {
        return check.filter(
            value => items.findIndex(x => x.id === value.id) === -1
        );
    };
    const union = (check: CheckboxItemType[], items: CheckboxItemType[]) => {
        return [...check, ...not(items, check)];
    };
    const numberOfChecked = (items: CheckboxItemType[]) =>
        checked.filter(value => items.findIndex(x => x.id === value.id) !== -1)
            .length;

    const handleToggleAll = (items: CheckboxItemType[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const saveDriveNormalSize = () => {
        const containers: { temp: HTMLDivElement; matCont: HTMLDivElement } = {
            temp: tempCont && tempCont.current,
            matCont: maturityCont
        };
        setModalToggle(false);
        setLoading(true);
        handleClientLoad(
            containers,
            checked,
            campaignAssessmentsData,
            setLoading,
            isExport,
            pointOneDecimalEnable,
            client.name
        );
    };
    const customList = (items: CheckboxItemType[]) => {
        return (
            <Card>
                <CardHeader
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            color="default"
                            checked={
                                numberOfChecked(items) === items.length &&
                                items.length !== 0
                            }
                            indeterminate={
                                numberOfChecked(items) !== items.length &&
                                numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    }
                    title="Select all"
                    subheader={`${numberOfChecked(items)}/${
                        items.length
                    } selected`}
                />
                <List dense component="div" role="list">
                    {items.map((x: CheckboxItemType, key: number) => {
                        return (
                            <ListItem
                                key={key}
                                style={{ height: 60 }}
                                button
                                onClick={handleToggle(x)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={
                                            checked.findIndex(
                                                a => a.id === x.id
                                            ) !== -1
                                                ? true
                                                : false
                                        }
                                        tabIndex={-1}
                                        color="default"
                                    />
                                </ListItemIcon>
                                <Text>{x.name}</Text>
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
                <AlignItems
                    style={{ justifyContent: 'center', marginBottom: 24 }}
                >
                    <RedButton onClick={saveDriveNormalSize}>
                        Generate
                    </RedButton>
                    {/* <CustomMenu
                        top={'-2%'}
                        button={
                            <DropdownButton>
                                <Text color="#fff" style={{ margin: 10 }}>
                                    Save to Google Drive
                                </Text>
                                <img src={dropdownArrow} alt={dropdownArrow} />
                            </DropdownButton>
                        }
                        content={
                            <div style={{ display: modalToggle,paddingTop:-10 }}>
                                <List
                                    component="nav"
                                    aria-label="main mailbox folders"
                                >
                                    <ListItem button key={0}>
                                        <ListItemText
                                            primary={
                                                <Text
                                                    onClick={
                                                        saveDriveNormalSize
                                                    }
                                                >
                                                    Normal 4:3
                                                </Text>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem button key={1}>
                                        <ListItemText
                                            primary={
                                                <Text
                                                onClick={
                                                    saveDriveNormalSize
                                                }
                                                >Widescreen 16:9</Text>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </div>
                        }
                    /> */}
                </AlignItems>
            </Card>
        );
    };
    return loading ? (
        <SpinnerWithOverlay />
    ) : (
        <Dialog
            open={modalToggle}
            onClose={() => setModalToggle(false)}
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <H1Text>Report</H1Text>
                <IconButton
                    onClick={() => setModalToggle(false)}
                    style={{
                        right: '24px',
                        position: 'absolute'
                    }}
                >
                    <img src={legendClose} alt="close" />
                </IconButton>
            </div>
            <Divider style={{ marginBottom: 10 }} />
            <CustomText>
                Select the sections that you would like to include in your
                report.
            </CustomText>
            {customList(checkboxItem)}
        </Dialog>
    );
};

const Spinner = styled('div')`
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
`;
const DropdownButton = styled(AlignItems)`
    && {
        background-color: #de3328;
        padding-right: 10px;
        padding-left: 10px;
    }
`;
const CustomText = styled(Text)`
    margin: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
`;

export default ReportModal;
