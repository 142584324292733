import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {HTML5Backend as Backend} from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {
    AlignItems,
    Column,
    H1Text,
    JustifyContentCenter,
    ScoreSquare,
    Text,
    TargetScoreSlider,
    JustifyContentEnd
} from '../../../../components';
import ObsAndRecContainer from './components/ObsAndRecContainer';
import {isPointOneDecimalEnable, isTargetScore as isTargetScoreFunc} from '../../../../utils/global-settings';

import {
    Mark,
    CurrentCapability,
    CurrentIdsData,
    CampaignData,
    Member,
    MaturityMatch,
    ObjectivesUI,
    ResponseStatus
} from '../types';
import { UserStore } from '../../../../context/user-store';
import { getMarksList } from '../Objectives/ObjectivesHelpers';
import {roundScore} from '../../../../utils';
import { getObjectivesRange, displayObjectivesScores } from './ModerationHelpers';
import { Button } from '@mui/material';

const ASSESSMENT_CAPABILITY_WITH_FRAMEWORK = gql`
    query assessmentCapability2(
        $data: AssessmentCapabilityInput!
        $campaignId: ID!
    ) {
        frameworkByCampaign(campaignId: $campaignId) {
            id
            definition {
                name
                scores
                domains {
                    name
                    capabilities {
                        name
                        objectives {
                            name
                            reference
                            displayRef
                            results
                        }
                    }
                }
            }
        }
        assessmentCapability(data: $data) {
            id
            name
            averageScore
            targetScore
            inScope
            status
            meetings {
                archivedDate
                stakeholderIds
                capabilities {
                    id
                    name
                }
                meetingDate
                notes {
                    text
                }
            }
            moderatedScore
            objectives {
                id
                inScope
                score
                notes
            }
            workingNotes {
                text
            }
            feedback {
                title
                observations {
                    text
                }
                recommendations {
                    text
                }
            }
            objectivesScoredCnt
        }
    }
`;

const UPDATE_ASSESSMENT_CAPABILITY_MODERATED_SCORE = gql`
    mutation updateAssessmentCapabilityModeratedScore(
        $data: UpdateAssessmentCapabilityModeratedScoreInput!
    ) {
        updateAssessmentCapabilityModeratedScore(data: $data) {
            id
            moderatedScore
            status
        }
    }
`;

const UPDATE_ASSESSMENT_CAPABILITY_TARGET_SCORE = gql`
    mutation updateAssessmentCapabilityTargetScore(
        $data: UpdateAssessmentCapabilityTargetScoreInput!
    ) {
        updateAssessmentCapabilityTargetScore(data: $data) {
            id
            targetScore
            status
        }
    }
`;

export type ModerationProps = {
    frameworkCapabilities: any[];
    currentCapability: CurrentCapability;
    scoreMarks: string[];
    currentAssessmentId: string;
    currentIdsData: CurrentIdsData;
    setSavingStatus: (status: string) => void;
    campaignData?: CampaignData;
    canEdit: boolean;
    match: MaturityMatch;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    setResponseStatusData: any;
    isObjectiveComponentExist: boolean;
    pointOneDecimalEnable: boolean;
};

const Moderation: React.FC<ModerationProps> = ({
    frameworkCapabilities,
    currentCapability,
    currentAssessmentId,
    scoreMarks,
    currentIdsData,
    campaignData,
    setSavingStatus,
    canEdit,
    match,
    readOnlyEnabled,
    isReadOnly,
    setResponseStatusData,
    isObjectiveComponentExist,
    pointOneDecimalEnable
}) => {
    const [updateModeratedScore, { loading: mutationLoading }] = useMutation(
        UPDATE_ASSESSMENT_CAPABILITY_MODERATED_SCORE,
        {
            onCompleted: (data) => {
                const {status}=data.updateAssessmentCapabilityModeratedScore;
                setResponseStatusData((prev: ResponseStatus) => ({
                    ...prev,
                    status
                }));
                setSavingStatus('All changes saved in Connected Risk Engine');
            },
            onError: () => {
                setSavingStatus('Error saving. Please try again.');
            },
            refetchQueries: [
                {
                    query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                    variables: {
                        campaignId: match.params.campaignId,
                        data: {
                            assessmentId: match.params.assessmentId,
                            domainId: currentIdsData.domainId,
                            capabilityId: currentIdsData.capabilityId
                        }
                    }
                }
            ]
        }
    );
    const [updateTargetScore, { loading: targetMutationLoading }] = useMutation(
        UPDATE_ASSESSMENT_CAPABILITY_TARGET_SCORE,
        {
            onCompleted: () => {
                setSavingStatus('All changes saved in Connected Risk Engine');
            },
            onError: () => {
                setSavingStatus('Error saving. Please try again.');
            },
            refetchQueries: [
                {
                    query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                    variables: {
                        campaignId: match.params.campaignId,
                        data: {
                            assessmentId: match.params.assessmentId,
                            domainId: currentIdsData.domainId,
                            capabilityId: currentIdsData.capabilityId
                        }
                    }
                }
            ]
        }
    );
    const [marks, setMarks] = useState<Mark[]>([]);
    const [marksTargetScore, setMarksTargetScore] = useState<Mark[]>([]);
    const [moderatedScore, setModeratedScore] = useState<number>(-1);
    const [targetScore, setTargetScore] = useState<number>(-1);
    const [smallestObjective, setSmallestObjective] = useState<ObjectivesUI | undefined>(undefined);
    const [biggestObective, setBiggestObective] = useState<ObjectivesUI  | undefined>(undefined);
    const { state: { user, client, globalSettings }} = useContext(UserStore);

    useEffect(() => {
        if (mutationLoading || targetMutationLoading) {
            setSavingStatus('Saving...');
        }
    }, [mutationLoading, targetMutationLoading]);

    useEffect(() => {
        if (scoreMarks.length) {
            const moderatedScoreMarks: Mark[] = [];
            const marksWithValues = getMarksList(scoreMarks).reduce(
                (acc: Mark[], curr: Mark) => {
                    if (Number.isInteger(curr.value)) {
                        moderatedScoreMarks.push({value: curr.value, label: curr.value.toFixed(1).toString()});
                        if (!curr.value) {
                            return [
                                ...acc,
                                {
                                    value: curr.value,
                                    label: 'Not in place'
                                }
                            ];
                        }
                        return [
                            ...acc,
                            {
                                value: curr.value,
                                label: scoreMarks[curr.value - 1]
                            }
                        ];
                    } else {
                        moderatedScoreMarks.push({value: curr.value});
                        return [...acc, { value: curr.value }];
                    }
                },
                []
            );
            setMarks([{value: -1}, ...moderatedScoreMarks]);
            setMarksTargetScore([{value: -1, label:'N/A'}, ...marksWithValues]);
        }
    }, [scoreMarks]);
    useEffect(() => {
        const moderatedScoreObj = currentCapability.moderatedScore ? currentCapability.moderatedScore : currentCapability.moderatedScore === 0 ? 0 : -1;
        const targetScoreObj = currentCapability.targetScore ? currentCapability.targetScore : currentCapability.targetScore === 0 ? 0 : -1;
        setModeratedScore(moderatedScoreObj);
        setTargetScore(targetScoreObj);
        const {leftValue, rightValue} = getObjectivesRange(currentCapability,pointOneDecimalEnable);
        setSmallestObjective(leftValue);
        setBiggestObective(rightValue);
    }, [currentCapability]);

    const userMayEdit =
        (campaignData &&
            campaignData.campaign &&
            campaignData.campaign.members &&
            campaignData.campaign.members.some(
                (member: Member) => member.id === (user && user.id)
            )) ||
            (user && user.id && campaignData &&
                campaignData.campaign &&
                campaignData.campaign.manager &&
                campaignData.campaign.manager.some(manager => user && user.id === manager.id)) ||
        (user && user.id) === (client && client.owner && client.owner.id)
        || campaignData && campaignData.campaign.isCampaignEntityUser ;

    const isTargetScore = campaignData &&
                            campaignData.campaign &&
                            campaignData.campaign.targetScoreEnabled;

    const isTargetScoreSliderEnabled=!(readOnlyEnabled && isReadOnly)&&campaignData && campaignData.campaign.status !== 'CLOSED' && isTargetScore;

    function ValueLabelComponent(props: any) {
            const { children } = props;

            return (
                <span>
                    {children}
                    <AverageMark
                        left={smallestObjective ? `${smallestObjective.percentage}%` : '-5%'}
                        right={biggestObective ? `${100 - biggestObective.percentage - 1}%` : '-5%'}
                    >
                        <AverageTitle>Objective range</AverageTitle>
                    </AverageMark>
                </span>
            );
    }

    return (
        <DndProvider backend={Backend}>
            <JustifyContentEnd>
                <Block>
                    <ObjScoreBox>
                        {roundScore(currentCapability.averageScore,pointOneDecimalEnable)}
                    </ObjScoreBox>
                    <Text>Objective average</Text>
                </Block>
                {!isObjectiveComponentExist&&<Block>
                    <Column style={{ marginRight: '16px' }}>
                        <ModifiedScoreSquare
                            background={'#4588C3'}
                            color={'white'}
                        >
                            {moderatedScore === -1 ? null : moderatedScore}
                        </ModifiedScoreSquare>
                    </Column>
                    <Column>
                        <Text
                            style={{paddingBottom: 7}}
                        >
                            Moderated score
                        </Text>
                        {(currentCapability.status === 'REVIEW_REQUESTED' || currentCapability.status === 'COMPLETED') && (user && user.id && campaignData?.campaign.manager.some(manager => manager.id !== user.id)) ? '' :
                        <Link
                            onClick={() => {
                                if (!canEdit||(
                                    currentCapability.status ===
                                    'REVIEW_REQUESTED'
                                    )) return;
                                updateModeratedScore({
                                    variables: {
                                        data: {
                                            assessmentId: currentAssessmentId,
                                            domainId: currentIdsData.domainId,
                                            capabilityId: currentIdsData.capabilityId,
                                            moderatedScore: null
                                        }
                                    }
                                });
                            }}
                        >
                            Reset
                        </Link>
                         }
                    </Column>
                </Block>}
                { isTargetScoreFunc(globalSettings) && isTargetScore && (
                    <Block>
                        <Column style={{ marginRight: '16px' }}>
                            <ModifiedScoreSquare
                                background={'#DA566E'}
                                color={'white'}
                            >
                                {targetScore === -1 ? null : targetScore}
                            </ModifiedScoreSquare>
                        </Column>
                        <Column>
                            <Text
                                style={{paddingBottom: 7}}
                            >
                                Target score
                            </Text>
                            {(currentCapability.status === 'REVIEW_REQUESTED' || currentCapability.status === 'COMPLETED') && (user && user.id && campaignData?.campaign.manager.some(manager => manager.id !== user.id)) ? '' :
                            <Link
                                    onClick={() => {
                                        if (!canEdit) return;
                                        updateTargetScore({
                                            variables: {
                                                data: {
                                                    assessmentId: currentAssessmentId,
                                                    domainId: currentIdsData.domainId,
                                                    capabilityId: currentIdsData.capabilityId,
                                                    targetScore: null
                                                }
                                            }
                                        });
                                    }}
                                >
                                    Reset
                            </Link>
                            }
                        </Column>
                    </Block>
                )}
                {/* {!isObjectiveComponentExist&&<CheckModerationContainer>
                    <Checkbox
                        checked={
                            currentCapability.status === 'MODERATED' ||
                            currentCapability.moderatedScore !== null
                        }
                        // onChange={handleChangeModerationStatus('ModerationStatus')}
                        disabled
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Text>Moderation completed</Text>
                </CheckModerationContainer>} */}
            </JustifyContentEnd>
            {<SliderContainer>
                <WhapperWithPaddings
                isPadding={isTargetScoreFunc(globalSettings) && isTargetScore ||!isObjectiveComponentExist}
                >
                    {!isObjectiveComponentExist && <ModifiedModeratedScoreSlider
                        isEnabled={
                            !(!userMayEdit ||
                            !canEdit ||
                            (currentCapability.status !== 'SCORED' &&
                                currentCapability.status !==
                                    'AMENDS_REQUESTED' &&
                                currentCapability.status !== 'MODERATED'))
                        }
                        defaultValue={-1}
                        value={moderatedScore}
                        setValue={setModeratedScore}
                        marks={marks}
                        valueLabelDisplay={'auto'}
                        step={null}
                        valueLabelFormat={() => moderatedScore === -1 ? 'N/A' : moderatedScore.toFixed(1)}
                        min={-1}
                        max={5}
                        color={'#4588C3'}
                        onBlur={(event: React.ChangeEvent<unknown>, value: number) => {
                            updateModeratedScore({
                                variables: {
                                    data: {
                                        assessmentId: currentAssessmentId,
                                        domainId: currentIdsData.domainId,
                                        capabilityId: currentIdsData.capabilityId,
                                        moderatedScore: value === -1 ? null : value
                                    }
                                }
                            });
                        }}
                    />}
                    { isTargetScoreFunc(globalSettings) && isTargetScore && (
                        <>
                            <FakeSlider
                                isEnabled={false}
                                defaultValue={-1}
                                value={targetScore}
                                setValue={setTargetScore}
                                marks={marksTargetScore}
                                valueLabelDisplay={'off'}
                                step={null}
                                min={-1}
                                max={5}
                                ValueLabelComponent={ValueLabelComponent}
                                color={'transparent'}
                            />
                            <ModifiedTargetScoreSlider
                                isCenter={isObjectiveComponentExist}
                                isEnabled={isTargetScoreSliderEnabled}
                                defaultValue={2}
                                value={targetScore}
                                setValue={setTargetScore}
                                marks={marksTargetScore}
                                valueLabelDisplay={'auto'}
                                step={null}
                                onBlur={() => {
                                    updateTargetScore({
                                        variables: {
                                            data: {
                                                assessmentId: currentAssessmentId,
                                                domainId: currentIdsData.domainId,
                                                capabilityId: currentIdsData.capabilityId,
                                                targetScore: targetScore === -1 ? null : targetScore
                                            }
                                        }
                                    });
                                }}
                                valueLabelFormat={() => targetScore === -1 ? 'N/A' : targetScore.toFixed(1)}
                                min={-1}
                                max={5}
                            />
                        </>
                    )}
                </WhapperWithPaddings>
            </SliderContainer>}

            <JustifyContentCenter>
                <H1Text color={'#4f4f4f'}>
                    Add new recommendations and observations
                </H1Text>
            </JustifyContentCenter>

            <RecommendationsObservationsContainer>
                <ObsAndRecContainer
                    frameworkCapabilities={frameworkCapabilities}
                    currentCapability={currentCapability}
                    campaignData={campaignData}
                    currentAssessmentId={currentAssessmentId}
                    currentIdsData={currentIdsData}
                    canEdit={canEdit}
                    isReadOnly={isReadOnly}
                />
            </RecommendationsObservationsContainer>
        </DndProvider>
    );
};

const RecommendationsObservationsContainer = styled('div')`
    padding: 0 150px;
    min-width: 60vw;
`;

const SliderContainer = styled('div')`
    padding: 0 100px;
`;

const Block = styled(AlignItems)`
    margin-left: 30px;
`;

const WhapperWithPaddings = styled(AlignItems)<{isPadding?: boolean}>`
    padding: ${props => props.isPadding ? '110px 0px 50px 0' : '0px' };
    flex-direction: column;
`;

// const CheckModerationContainer = styled(AlignItems)`
//     justify-content: flex-end;
//     border: 1px solid #DEDEDE;
//     border-radius: 2px;
//     margin-left: 30px;
//     padding-right: 9px;
// `;

const ModifiedTargetScoreSlider = styled(TargetScoreSlider)<{isCenter?: boolean}>`
    padding: ${props => props.isCenter && '0px !important' };
    z-index: 2;
    .MuiSlider-markLabel{
        transform: translateY(-470%) translateX(-45%);
    }
    .MuiSlider-valueLabel{
        color: white;
    }
`;

const FakeSlider = styled(TargetScoreSlider)`
    z-index: 1;
    color: transparent !important;
    .MuiSlider-markLabel{
        display: none;
    }
    .MuiSlider-valueLabel{
        color: white;
    }
`;

const ModifiedModeratedScoreSlider = styled(TargetScoreSlider)`
    margin-bottom: -28px !important;
    z-index: 2;
    .MuiSlider-markLabel{
        transform: translateY(-323%) translateX(-45%);
    }
    .MuiSlider-valueLabel{
        color: white;
    }
    .MuiSlider-thumb:hover, .MuiSlider-thumb.MuiSlider-active, .MuiSlider-thumb.Mui-focusVisible{
        box-shadow: 0px 0px 0px 11px rgba(69, 136, 195, 0.3);
    }
`;

const AverageMark = styled('div')<{ left: string; right: string}>`
    position: absolute;
    background: #F2F2F2;
    border-radius: 6px;
    left: ${props => props.left};
    right: ${props => props.right};
    height: 90px;
    transform: translate(0, -15px);
`;

const AverageTitle = styled('span')`
    position: absolute;
    top: 90px;
    color: #575757;
    font-size: 14px;
    width: 100%;
    text-align: center;
`;

const ObjScoreBox = styled('div')`
    padding: 5px;
    background-color: #F2F2F2;
    margin-right: 8px;
    height: 38px;
    width: 38px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50px;
`;

const ModifiedScoreSquare = styled(ScoreSquare)`
    border-radius: 30px;
`;

const Link = styled(Button)`
    color: #4588c3 !important;
    text-decoration: underline !important;
    text-transform: none !important;
    line-height: normal !important;
    padding: unset !important;
`;

export default Moderation;
