import { styled } from '@mui/material/styles';

const BoldText = styled('div')<{
    color?: string;
    margin?: string;
    fontSize?: string;
    letterSpacing?: string;
}>`
    // font-family: HelveticaNeue;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : 'normal')};
    color: ${props => (props.color ? props.color : '#2d2d2d')};
    margin: ${props => (props.margin ? props.margin : '0')};
    font-size: ${props => (props.fontSize ? props.fontSize : '20px')};
    @media (max-width: 1366px) {
        font-size: 15px;
    }
`;

export default BoldText;
