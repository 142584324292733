import React from 'react';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { List, ListProps, Popover, ListItemButton, ListItemButtonProps } from '@mui/material';
import Text from '../../base/Text/Text';
import dropdownArrow from '../../../assets/images/icon-drop-down-dark.svg';
import ImgContainer from '../ImgContainer';
import ArrowTooltip from '../../Tooltips/ArrowTooltip';

type Option = {
    id: string;
    label: string;
    status?: string;
}

type Props = {
    listOfOptions?: Option[];
    selectedOptionId: string;
    setSelectedOptionId: any;
    isOpen: boolean;
    setOpen: CallableFunction;
    placeholder?: string;
    buttonDisabled?: boolean;
    width?: string;
    multiselect?: boolean;
    isButtons?: boolean;
    setGetFiltersData?: any;
};

const DropdownSingleSelection: React.FC<Props> = ({
    listOfOptions,
    selectedOptionId,
    setSelectedOptionId,
    isOpen,
    setOpen,
    placeholder,
    width,
    buttonDisabled = false,
    setGetFiltersData,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const anchorId = isOpen ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div style={{ marginTop: '8px' }}>
            <MenuButtonContainer
                background={buttonDisabled ? 'none' : '#ececec'}
                disabled={buttonDisabled}
                aria-describedby={anchorId}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(e);
                    setOpen(true);
                }}
                width={width ? width : '100%'}
            >
                <TextContainer
                id="skip-to-content"
                tabIndex={-1}
                    color={
                        placeholder === 'Select Campaign' ? '#575757' : '#000'
                    }
                >
                    {placeholder}
                </TextContainer>
                <ImgContainer src={dropdownArrow} alt="dropdown arrow" aria-hidden />
            </MenuButtonContainer>

            <Popover
                id={anchorId}
                open={isOpen}
                keepMounted={isOpen}
                onClose={() => setOpen(false)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                style={{ marginTop: '8px' }}
            >
                <div style={{ width: '312px' }}>
                    <CustomList
                        component="nav"
                        aria-label="main mailbox folders">
                        {listOfOptions ? (listOfOptions.map((item: Option, index: number) => (
                                        <CustomListItem
                                            key={index}
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();

                                                setSelectedOptionId(item.id);
                                                handleClose();
                                                if (setGetFiltersData) {
                                                    setGetFiltersData(true);
                                                }
                                            }}
                                            style={item.id === selectedOptionId? {
                                                          backgroundColor:  '#f2f2f2',
                                                          margin: 0
                                                      }: { margin: 0 }
                                            }
                                            disabled= {item.status && (item.status === 'ACTIVE' || item.status === 'CLOSED_INCOMPLETE'
                                                         || item.status === 'NOT_STARTED' || item.status === 'CAMPAIGN_ENTITY_USER')? true: false}
                                        >
                                            <ArrowTooltip
                                                title={item.label}
                                                placement="bottom"
                                                key={index}
                                                minWidth={'0px'}
                                            >
                                                <MenuOptionText>
                                                    {item.label}
                                                </MenuOptionText>
                                            </ArrowTooltip>
                                            {item.id === selectedOptionId && (<CheckIcon />)}
                                        </CustomListItem>
                                    )
                                )
                        ) : (
                            <div>Loading...</div>
                        )}
                    </CustomList>
                </div>
            </Popover>
        </div>
    );
};

const CustomList = styled((props: ListProps) => (
    <List
        {...props}
    />
))`
    width: 312px;
    margin: 0;
    padding: 0;
    background-color: white;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: 450;
    display: flex;
    flex-direction: column;

    &:focus {
        outline: 0;
    }
`;

const CustomListItem = styled((props: ListItemButtonProps) => (
    <ListItemButton
        {...props}
    />
))`
    display: flex;
    justify-content: space-between;
    background-color: inherit;
    margin: 0px 2px;
    padding: 18px;
    height: 42px;
`;

const MenuOptionText = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const MenuButtonContainer = styled('button')<{
    background?: string;
    width?: string;
}>`
    display: flex;
    width: ${props => props.width};
    align-items: center;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    text-transform: none;
    cursor: pointer;
    &:focus {
        border: 1px solid #2d2d2d;
        outline-color: #2d2d2d;
    }
    &:hover {
        background: ${props => (props.background ? props.background : 'none')};
    }
`;

const TextContainer = styled(Text)<{ color?: string }>`
    flex-direction: column;
    color: ${props => (props.color ? props.color : '#575757')};
`;

export default DropdownSingleSelection;
