import { useState, useEffect } from 'react';
import {
    CurrentCapability,
    CapabilityObjectiveScore,
    CapabilityObjectiveWithDescriptions,
    AssessmentDomain,
    AssessmentData,
    Domain,
    CampaignFrameworkPayload, PayloadObjective
} from './types';

export const useCapability = ({
    capabilityData,
    capabilityLoading,
    responseStatusData
}: {
    capabilityData: any;
    capabilityLoading: boolean;
    responseStatusData: { name: string; status: string } | null;
    }) => {
    const [capability, setCapability] = useState<CurrentCapability>({
        id: '',
        name: '',
        status: '',
        objectives: [],
        averageScore: null,
        targetScore: null,
        feedback: [],
        inScope: false,
        meetings: [],
        moderatedScore: 0,
        workingNotes: {
            text: ''
        },
        objectivesScoredCnt: 0
    });
    useEffect(() => {
        if (
            capabilityLoading ||
            !capabilityData ||
           // !capabilityData.objectives ||
            (capabilityData.id === '' && responseStatusData === null)
        ) {
            return undefined;
        }
        if (capabilityData && capabilityData.objectives) {
            capabilityData = {
                ...capabilityData,
                objectivesScoredCnt: capabilityData.objectives.filter(
                    (obj: PayloadObjective) => obj.inScope && !!obj.score
                ).length
            };
        }
        if (responseStatusData !== null && !capabilityLoading) {
            setCapability(() => ({
                ...capabilityData,
                name: responseStatusData.name && responseStatusData.name === '' ?
                    responseStatusData.name : capabilityData.name,
                status: responseStatusData.status && responseStatusData.status === '' ?
                    responseStatusData.status : capabilityData.status
            }));
        } else if (!capabilityLoading && capabilityData) {
            setCapability(capabilityData);
        }
    }, [capabilityData, capabilityLoading, responseStatusData]);
    return capability;
};

export const useCapabilityObjectives = ({
    objectives,
    frameworkData,
    currentDomainIndex,
    currentCapabilityIndex
}: {
    objectives: CapabilityObjectiveScore[];
    frameworkData: CampaignFrameworkPayload;
    currentDomainIndex: number;
    currentCapabilityIndex: number;
}) => {
    const [objectivesList, setObjectivesList] = useState<
        CapabilityObjectiveWithDescriptions[]
    >([]);
    useEffect(() => {
        if (frameworkData.id === null) {
            return undefined;
        }
        if (
            objectives.length &&
            frameworkData &&
            frameworkData.definition &&
            frameworkData.definition.domains.length &&
            frameworkData.definition.domains[currentDomainIndex] &&
            frameworkData.definition.domains[currentDomainIndex].capabilities
                .length &&
            frameworkData.definition.domains[currentDomainIndex].capabilities[
                currentCapabilityIndex
            ] &&
            frameworkData.definition.domains[currentDomainIndex].capabilities[
                currentCapabilityIndex
            ].objectives.length &&
            objectives.length ===
                frameworkData.definition.domains[currentDomainIndex]
                    .capabilities[currentCapabilityIndex].objectives.length
        ) {
            setObjectivesList(
                (state: CapabilityObjectiveWithDescriptions[]) => {
                    return objectives.map(
                        (item: CapabilityObjectiveScore, index: number) => {
                            const { id, inScope, score, notes, weighting, compensatingControlEnable } = item;
                            const {
                                name,
                                reference,
                                displayRef,
                                results,
                                guidance,
                                components
                            } = frameworkData.definition.domains[
                                currentDomainIndex
                            ].capabilities[currentCapabilityIndex].objectives[
                                index
                            ];

                            const componentsWithScore = item.components && item.components.map((que, index) => {
                                return {
                                    name: components&&components[index]&&components[index].name||'',
                                    reference: components&&components[index]&&components[index].reference||'',
                                    question: components&&components[index]&&components[index].question || '',
                                    results: components&&components[index]&&components[index].results || [''],
                                    score: que.score,
                                    id: que.id,
                                    weighting: que.weighting,
                                    inScope: que.inScope
                                };
                            });
                            if (state[index]) {
                                return {
                                    ...state[index],
                                    name,
                                    guidance,
                                    reference,
                                    displayRef,
                                    results,
                                    id,
                                    inScope,
                                    score,
                                    weighting,
                                    compensatingControlEnable,
                                    notes,
                                    components:componentsWithScore
                                };
                            }
                            return {
                                name,
                                reference,
                                displayRef,
                                guidance,
                                results,
                                id,
                                inScope,
                                score,
                                weighting,
                                compensatingControlEnable,
                                notes,
                                components:componentsWithScore
                            };
                        }
                    );
                }
            );
        }
    }, [objectives, frameworkData, currentDomainIndex, currentCapabilityIndex]);
    return objectivesList;
};

export const useAssessmentDomainsList = (
    assessmentLoading: boolean,
    assessmentData?: AssessmentData
) => {
    const [domainsList, setDomainsList] = useState<AssessmentDomain[]>([]);
    useEffect(() => {
        if (assessmentLoading || !assessmentData) {
            return undefined;
        }
        if (
            !assessmentLoading &&
            assessmentData &&
            assessmentData.assessment &&
            assessmentData.assessment.state &&
            assessmentData.assessment.state.domains.length
        ) {
            setDomainsList(assessmentData.assessment.state.domains);
        }
    }, [assessmentData, assessmentLoading]);
    return domainsList;
};

export const useDomainsList = (frameworkData: CampaignFrameworkPayload) => {
    const [domainsList, setDomainsList] = useState<Domain[]>([]);
    useEffect(() => {
        if (frameworkData.id === null) {
            return undefined;
        }
        if (
            frameworkData &&
            frameworkData.definition &&
            frameworkData.definition.domains &&
            frameworkData.definition.scores
        ) {
            setDomainsList(frameworkData.definition.domains);
        }
    }, [frameworkData]);

    return domainsList;
};

export const useScoreMarks = (frameworkData: CampaignFrameworkPayload) => {
    const [scoreMarks, setScoreMarks] = useState<string[]>([]);

    useEffect(() => {
        if (frameworkData.id === null) {
            return undefined;
        }
        if (
            frameworkData &&
            frameworkData.definition &&
            frameworkData.definition.domains &&
            frameworkData.definition.scores
        ) {
            setScoreMarks(frameworkData.definition.scores);
        }
    }, [frameworkData]);
    return scoreMarks;
};

export enum AssessmentCapabilityStatusEnum {
    NOT_STARTED = 'NOT_STARTED',
    SCORED = 'SCORED',
    MODERATED = 'MODERATED',
    IN_PROGRESS = 'IN_PROGRESS',
    REVIEW_REQUESTED = 'REVIEW_REQUESTED',
    COMPLETED = 'COMPLETED',
    AMENDS_REQUESTED = 'AMENDS_REQUESTED'
}
