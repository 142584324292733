import { gql } from 'apollo-boost';

export const STAKEHOLDERS = gql`
query stakeholdersByClient($clientId: Int!) {
    stakeholdersByClient(clientId: $clientId) {
        id
        firstName
        lastName
        jobTitle
        email
        status
        ownedCampaignEntities {
            id
            name
        }
        updatedAt
        createdAt
    }
}
`;
