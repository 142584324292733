import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { AlignItems } from '../../../components/base/Layout';
import { ClearButton } from '../../../components/base/Buttons';
import { gql } from 'apollo-boost';
import { Countries } from './types';
import { DialogBox, RedButton, Text, BoldText } from '../../../components';
import mdi_info_red from '../../../assets/images/mdi_info_red.svg';
import crossIcon from '../../../assets/images/cross_icon.svg';
import { driveApi, googleDriveSave } from '../../Report/ReportHelpers';
import PptxGenJS from 'pptxgenjs';
import { Pptxgen } from '../../Report/types';
import { logo } from '../../Report/ListData';
import {
    capabilitiesAverage,
    dataFormatter
} from '../../Evaluate/MaturityOverview/MaturityOverviewTabs/MaturityOverviewTabsHelpers';
import { CalculateClusteredScores, Dataset } from './types';
import { Series, Legend, PointLabel } from '../../../components/Charts/BarGraph/types';
import { MaturityTableData } from '../../Evaluate/MaturityOverview/MaturityOverview/types';
import { colorForSeries,colorForQuartiles } from '../../../components/Charts/BarGraph/data';
import { minimunBenchmarkingPeers, roundGraphScore } from '../../../utils';

let pptx = new PptxGenJS();

export const CAMPAIGNS = gql`
    query availableCampaignsByClient2($clientId: Int!, $supportsBenchmarking : Boolean) {
        availableCampaignsByClient(clientId: $clientId, supportsBenchmarking : $supportsBenchmarking) {
            id
            name: title
            readOnly
            framework {
                frameworkVersionId
            },
            pointOneDecimalEnable
            isCampaignEntityUser
            status
            manager {
                id
            }
        }
        industries {
            id
            name
        }
        annualRevenues {
            id
            name: value
        }
        homeCountries {
            country
            region
        }
        employeesNumbers {
            id
            name: value
        }
        clientTypes {
            id
            name
        }
        benchmarkingDatesRange {
            from
            to
        }
        campaignAssessmentTypes
    }
`;

export const SET_BENHCMARKING_FILTERS = gql`
    query saveBenchmarkingFilters(
        $filters: BenchmarkingFiltersInput!
        $campaignId: String!
    ) {
        saveBenchmarkingFilters(filters: $filters, campaignId: $campaignId) {
            region
            country
            industry
            clientType
            assessmentType
            revenue
            numberOfEmployees
            datesRange {
                from {
                    month
                    year
                }
                to {
                    month
                    year
                }
            }
        }
    }
`;

export const GET_BENHCMARKING_FILTERS = gql`
    query benchmarkingFilters($campaignId: String!) {
        benchmarkingFilters(campaignId: $campaignId) {
            region
            country
            industry
            clientType
            assessmentType
            revenue
            numberOfEmployees
            datesRange {
                from {
                    month
                    year
                }
                to {
                    month
                    year
                }
            }
        }
    }
`;

export const BENHCMARKING_DATA = gql`
    query benchmarkingData(
        $filters: BenchmarkingFiltersInput!
        $campaignId: String!
    ) {
        benchmarkingData(filters: $filters, campaignId: $campaignId) {
            rows {
                companyId
                frameworkId
                frameworkName
                frameworkVersion
                assessmentType
                campaignClosureDate
                domainsScore
                capabilitiesScore
                objectivesScore
                componentsScore
                industry
                sizeByRevenue
                sizeByEmployees
                companyType
                country
                region
                differentWeightDistribution{
                    domainLevel
                    capabilityLevel
                    objectiveLevel
                }
            }
            totalCount
            clusteredDomains {
                name
                score
                quartiles
            }
            clusteredCapabilities {
                name
                score
                quartiles
            }
        }
    }
`;

export const CAMPAIGN_DATA = gql`
    query assessmentsByCampaignWithGroups($campaignId: ID!) {
        assessmentsByCampaignWithGroups(campaignId: $campaignId) {
            groupName
            assessments {
                id
                campaign {
                    id
                    title
                    status
                }
                campaignEntity {
                    id
                    name
                    archivedDate
                }
                state {
                    status
                    inScope
                    averageScore
                    domains {
                        id
                        name
                        averageScore
                        moderatedAverageScore
                        capabilities {
                            status
                            id
                            name
                            averageScore
                            inScope
                            moderatedScore
                        }
                    }
                }
                framework {
                    id
                    version
                    definition {
                        name
                        shortName
                        scores
                        domains {
                            name
                            shortName
                            capabilities {
                                name
                                shortName
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const renderModal = ({
    modalId,
    handleClose,
    handleRetry,
    isOpen
}: {
    isOpen: boolean;
    modalId: string;
    handleClose: () => void;
    handleRetry: () => void;
}) => {
    const store = new Map();
    store.set(
        'peerGroupSmall',
        <DialogBox
            title={'Peer group too small'}
            content={`A minimum of ${minimunBenchmarkingPeers} peers is required.
            Please adjust the filters to increase the size of your peer group.
            `}
            open={isOpen}
            handleClose={() => {}}
            handleSubmit={() => {
                handleClose();
            }}
        />
    );
    store.set(
        'peerGroupSaveError',
        <div>
            <BenchDialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ width: '661px' }} id="alert-dialog-title">
                    <AlignItems style={{ justifyContent: 'space-between' }}>
                        <AlignItems style={{ marginTop: 8 }}>
                            <img src={mdi_info_red} alt="" />
                            <BenchBoldText>
                                There was a problem saving peer group.
                            </BenchBoldText>
                        </AlignItems>
                        <img
                            style={{ width: '16px', cursor: 'pointer' }}
                            src={crossIcon}
                            onClick={handleClose}
                            alt="crossIcon"
                        />
                    </AlignItems>
                </DialogTitle>

                <DialogActions>
                    <ButtonCancel onClick={handleClose} variant="outlined">
                        Dismiss
                    </ButtonCancel>

                    <ButtonWithMarginLeft
                        onClick={handleRetry}
                        variant="contained"
                    >
                        Retry
                    </ButtonWithMarginLeft>
                </DialogActions>
            </BenchDialog>
        </div>
    );

    return store.get(modalId) || null;
};

const BenchBoldText = styled(BoldText)`
    color: #40403e;
    font-size: 18px;
    margin-left: 8px;
`;

const BenchDialog = styled(Dialog)`
    .MuiDialog-paperWidthSm {
        max-width: none;
        padding-top: 8px;
        padding-bottom: 9px;
    }
    .MuiDialogActions-root {
        padding: 16px 24px 16px 16px;
    }
`;

export const createYearsFromToArray = (
    yearFrom: number | undefined,
    yearTo: number | undefined
) => {
    const dateArray = [];
    if (yearFrom && yearTo) {
        for (let start = yearFrom; start < yearTo + 1; start++) {
            dateArray.push({ label: start, value: start });
        }
    }
    return dateArray;
};

export const regionMap = (
    countriesRegionsDataSet: Countries[] = [],
    searchValue = '',
    viewSelected: boolean,
    selectedData: string[]
) => {
    const regionsMap = countriesRegionsDataSet
        .filter(data => {
            if (viewSelected && selectedData.length) {
                return (
                    selectedData.includes(data.country) &&
                    data.country.includes(searchValue)
                );
            }
            return data.country.includes(searchValue);
        })
        .reduce((acc: { [key in string]: string[] }, { country, region }) => {
            if (acc[region]) {
                acc[region].push(country);
            } else {
                acc[region] = [country];
            }
            return acc;
        }, {});
    return Object.entries(regionsMap).map(([key, name]) => ({
        region: key,
        countries: name
    }));
};

export const generateCapabilitiesByDomainGraph = (
    assessmentData: MaturityTableData,
    domainIndex: number,
    data: CalculateClusteredScores[],
    quartileNames: string[],
    readOnlyEnabled: boolean,
    isReadOnly: boolean,
    pointOneDecimalEnable: boolean
) => {
    const datasetLine: Dataset[] = [];
    const overviewLegend: Legend[] = [];
    const overviewSeries: Series[] = [];
    let xDataArray: string[] = [];
    let capabilityCoorX = 0;
    let currentDomainTitle='';

    const ass =
        assessmentData &&
        assessmentData.assessmentsByCampaignWithGroups &&
        assessmentData.assessmentsByCampaignWithGroups[0].assessments;

    const objectivesLabelsList =
        ass[0].framework &&
        ass[0].framework.definition &&
        ass[0].framework.definition.scores;

    const capabilitiesAverageScores = capabilitiesAverage(
        assessmentData && assessmentData.assessmentsByCampaignWithGroups, readOnlyEnabled, isReadOnly
    );
    const FormattedData = dataFormatter(capabilitiesAverageScores);
    const capabilitiesScoreArray = FormattedData[domainIndex];
    capabilitiesScoreArray &&
        capabilitiesScoreArray.capabilities.forEach((capabilityScore: any) => {
            datasetLine.push({
                value: [
                    capabilityCoorX,
                    roundGraphScore(capabilityScore.moderatedScore, pointOneDecimalEnable),
                    capabilityScore.name
                ],
                itemStyle: {
                    color: '#000000'
                }
            });
            capabilityCoorX++;
        });

    quartileNames.forEach((quartileName: string, index: number) => {
        xDataArray = [];
        let dataset: Dataset[] = [];
        const nullPoints: PointLabel[] = [];
        capabilityCoorX=0;
        overviewLegend.push({
            name: quartileName,
            textStyle: { color: colorForQuartiles[index][2] }
        });
        if (
            ass[0].framework &&
            ass[0].framework.definition &&
            ass[0].framework.definition.domains
        ) {
            const currentDomainEl = ass[0].framework.definition.domains[domainIndex];
            currentDomainTitle=currentDomainEl.name;
            currentDomainEl &&
                currentDomainEl.capabilities &&
                currentDomainEl.capabilities.forEach((capability: any) => {
                    const capabilityData = data[0].clusteredCapabilities.find(
                        el => el.name === capability.shortName
                    );
                    if (capabilityData && capabilityData.quartiles === null) {
                        nullPoints.push({
                            name: 'NullValue',
                            value: 'N/A',
                            xAxis: capability.name,
                            yAxis: 0.15,
                            label: { color: colorForSeries[index] }
                        });
                    }
                    capabilityData &&
                        dataset.push({
                            value: [
                                capabilityCoorX,
                                capabilityData.quartiles&&capabilityData.quartiles[index],
                                capability.name
                            ],
                            itemStyle: { color: colorForQuartiles[index][2] }
                        });
                        capabilityCoorX++;
                    xDataArray.push(capability.name);
                });
            overviewSeries.push({
                name: quartileName,
                type: 'bar',
                stack: 'stack',
                data: dataset,
                barWidth: 60,
                barCategoryGap: '5%',
                symbolSize: 10,
                zlevel: 0,
                markPoint: {
                    symbol: 'rect',
                    symbolSize: 20,
                    data: nullPoints,
                    itemStyle: {
                        color: 'white',
                        opacity: 0.8
                    }
                }
            });
            dataset = [];
        }
    });

    overviewLegend.push({
        name: currentDomainTitle,
        textStyle: { color: '#000000' }
    });

    overviewSeries.push({
        name: currentDomainTitle,
        type: 'line',
        data: datasetLine,
        lineStyle: {
            color: '#000000'
        },
        barGap: '-98%',
        symbolSize: 10,
        zlevel: 0
    });

    return {
        seriesData: overviewSeries,
        domainTitle: currentDomainTitle,
        objectivesLabelsList,
        legendData: overviewLegend,
        xAxisData: xDataArray
    };
};

export const mapData = {
    PWC_FULL: 'PwC full',
    PWC_LIGHT: 'PwC light',
    SELF_ASSESSMENT: 'Client self-assessment'
};

export const assessmentMap = (assessmentDataSet: string[] = []) => {
    return assessmentDataSet.map((type: string) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({ name: mapData[type], id: type })
    );
};

const addMasterTitle = async (pptxMaster: Pptxgen, title: string) => {
    const date = new Date();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
     pptxMaster.defineSlideMaster({
        title: 'COVER_SLIDE',
        bkgd: mastersStyles.cover_bkgd,
        objects: [
            { rect: mastersStyles.cover_recttop0 },
            { rect: mastersStyles.cover_recttop1 },
            { rect: mastersStyles.cover_recttop2 },
            {
                text: {
                    text: title,
                    options: mastersStyles.cover_textOpt0
                }
            },
            {
                text: {
                    text: 'Benchmarking results',
                    options: mastersStyles.cover_textOpt1
                }
            },
            {
                text: {
                    text: (
                        date.getDate() +
                        'th ' +
                        months[date.getMonth()] +
                        ' ' +
                        date.getFullYear()
                    ).toString(),
                    options: mastersStyles.cover_dateOpt
                }
            },
            { image: { x: 0.5, y: 4.5, w: 1.15, h: 0.9, data: logo } }
        ]
    });
};

const addMasterBottom = async (pptxMaster: Pptxgen) => {
    const date = new Date();
    return pptxMaster.defineSlideMaster({
        title: 'BOTTOM_COVER',
        objects: [
            {
                text: {
                    text: 'PwC',
                    options: mastersStyles.general_textOpt
                }
            },
            {
                text: {
                    text: (
                        date.getDate() +
                        '/' +
                        date.getMonth() +
                        '/' +
                        date.getFullYear()
                    ).toString(),
                    options: mastersStyles.general_dateOpt
                }
            }
        ],
        slideNumber: mastersStyles.slideNumber
    });
};

export const createReport = async (
    title: string,
    data: {
        title: string;
        image: string;
    }[],
    setLoading: any,
    isDrive: boolean
) => {
    await addMasterTitle(pptx, title);
    await addMasterBottom(pptx);
    pptx.layout = 'LAYOUT_16x9';
    pptx.addSlide({masterName: 'COVER_SLIDE'});

    data.forEach((d: {
        title: string;
        image: string;
    }) => {
        const slide = pptx.addSlide({masterName: 'BOTTOM_COVER'});
        slide.addText(d.title, slideStyles.title_text);
        slide.addImage({
            data: d.image,
            x: 0.5,
            y: 0.5,
            w: 9.0,
            h: 4.5
        });
    });

    // Set Export Filename
    const date = new Date();
    const currentDate = (
        date.getDate() +
        '/' +
        (date.getMonth() + 1) +
        '/' +
        date.getFullYear()
    ).toString();
    const exportTitle = title + ' - Benchmarking Results' + ' (' + currentDate + ')';

    if(isDrive){
        pptx.write('blob')
        .then(async (slide) => {
                const connectionDrive = await driveApi(setLoading);
                 if (!connectionDrive) {
                      return;
                  }
                  googleDriveSave(
                slide,
                setLoading,
                exportTitle
            );
            pptx = new PptxGenJS();
        })
        .catch(x => {
            setLoading(false);
            console.log(x);
            pptx = new PptxGenJS();
        });
    }
    else{
        pptx.writeFile(exportTitle + '.pptx').then(() => {
            setLoading(false);
            pptx = new PptxGenJS();
        }).catch(e=>{
            setLoading(false);
            console.log(e);
            pptx = new PptxGenJS();
        });
    }
};

export const MenuButtonContainer = styled(AlignItems)`
    min-width: 150px;
    background: transparent;
    padding: 9px;
    justify-content: space-between;
    border: 1px solid #dbdada;
    border-radius: 4px;
    &:focus {
        border: 1px solid #2d2d2d;
    }
`;

export const TextContainer = styled(Text)`
    flex-direction: column;
    color: #575757;
    font-size: 16px;
`;

export const ClearAllButton = styled(ClearButton)`
    width: 50%;
`;

const ButtonWithMarginLeft = styled(RedButton)`
    && {
        text-transform: capitalize;
        margin-left: 20px;
        min-height: 48px;
        font-size: 18px;
    }
`;

const ButtonCancel = styled(Button)`
    && {
        text-transform: capitalize;
        border: none;
        font-size: 18px;
        color: #575757;
        padding-right: 24px;
    }
`;

export const UppercaseText = styled(Text)`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: normal;
    color: #2d2d2d;
`;

export const Spinner = styled('div')`
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
`;

const slideStyles = {
    title_text: {
        x: 4.1,
        y: 0.3,
        fontSize: 14,
        color: '000000',
        bold: false,
        margin: 5,
        fontFace: 'Arial'
    }
};

const mastersStyles = {
    cover_bkgd: 'FFFFFF',
    cover_recttop0: { x: 0, y: 0, w: 6.3, h: 2.25, fill: '4B4A4A' },
    cover_recttop1: { x: 6.3, y: 0, w: 3.7, h: 2.25, fill: 'F0A248' },
    cover_recttop2: { x: 0, y: 2.25, w: 6.3, h: 1.05, fill: 'd04a02' },
    cover_textOpt1: {
        x: 0.4,
        y: 1.6,
        w: 5.5,
        fontFace: 'Georgia',
        color: 'FFFFFF',
        fontSize: 26,
        margin: 0
    },
    cover_textOpt0: {
        x: 0.4,
        y: 1.2,
        w: 5.5,
        fontFace: 'Georgia',
        color: 'FFFFFF',
        fontSize: 26,
        margin: 0
    },
    general_textOpt: {
        x: 0.2,
        y: 5.125,
        w: '100%',
        align: 'left',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    cover_dateOpt: {
        x: 0.4,
        y: 2.35,
        w: '100%',
        align: 'l',
        color: 'FFFFFF',
        fontSize: 12.0,
        fontFace: 'Arial'
    },
    general_dateOpt: {
        x: 9.17,
        y: 4.925,
        w: '100%',
        align: 'l',
        color: '000000',
        fontSize: 8.0,
        fontFace: 'Arial'
    },
    slideNumber: {
        x: 9.55,
        y: 5.125,
        color: '000000',
        fontSize: 8.0
    }
};
