import { PermissionTypeEnum } from '../../../../utils';
import { CapabilityList } from '../MaturityOverview/MaturityOverviewSharedTypes';
import {
    CampaignAssessment,
    CampaignGroupAssessment,
    MaturityOverviewCapability,
    MaturityOverviewDomain
} from '../MaturityOverview/types';


type DomainsData = {
    ScoreArray: number[];
    Score: number | null;
    Domain: string;
    targetScores: number[];
    averageTargetScore?: number;
    Capabilities?: CapabilityList[];
};

export type StateDomainsType = {
    capabilities: StateCapabilities[];
    name: string;
};

export type StateCapabilities = {
    moderatedScore: number | null;
    averageScore: number | null;
    name: string;
    inScope: boolean;
    targetScore?: number;
};

export const capabilitiesAverage = (data: CampaignGroupAssessment[], readOnlyEnabled: boolean, isReadOnly: boolean, userfilteredData?: any) => {
    const filteredData: CapabilityList[] = [];
    data && data.forEach(x => {
        x.assessments.forEach((a: CampaignAssessment) => {
            if(readOnlyEnabled && (isReadOnly || a.permission === PermissionTypeEnum.READ_ONLY_ACCESS) && (a.campaign.progress !== 100 && a.campaign.status !== 'COMPLETED')) {
                return;
            }
            a.state.domains.forEach((b: MaturityOverviewDomain) => {
                b.capabilities.forEach((y: MaturityOverviewCapability) => {

                    const isreadonlyCampaign = userfilteredData && userfilteredData.filter((campaign: any) => {
                        return (campaign.id === a.campaign.id)? campaign.readOnly : '';
                    });

                    if(readOnlyEnabled && isReadOnly && y.status !== 'COMPLETED') {
                        return;
                    }

                    const ind: number = filteredData.findIndex(
                        (c: CapabilityList) =>
                            c.Capabilities === y.name && b.name === c.Domain
                    );
                    if (ind === -1) {
                        filteredData.push({
                            Capabilities: y.name.toString(),
                            ScoreArray: ((isreadonlyCampaign && isreadonlyCampaign.length || a.permission === PermissionTypeEnum.READ_ONLY_ACCESS ) && y.status !== 'COMPLETED')? [] : y.inScope ? [
                                y.moderatedScore === null
                                    ? y.averageScore
                                    : y.moderatedScore
                            ]:[],
                            targetScores:y.targetScore?[y.targetScore]:[],
                            Score: null,
                            Domain: b.name,
                            inScope: y.inScope,
                        });
                    } else if (ind !== -1 && y.inScope) {
                        if(!((isreadonlyCampaign && isreadonlyCampaign.length || a.permission === PermissionTypeEnum.READ_ONLY_ACCESS) && y.status !== 'COMPLETED')){
                            filteredData[ind].ScoreArray.push(
                                    y.moderatedScore === null
                                        ? y.averageScore
                                        : y.moderatedScore
                                
                                );
                        }
                        if(y.targetScore) {
                            filteredData[ind].targetScores.push(
                                y.targetScore
                            );
                        }
                        filteredData[ind].inScope = y.inScope;
                    }
                    return;
                });
            });
        });
    });
    filteredData.forEach(x => {
        const len = x.ScoreArray.filter((a: number) => a !== null).length;
        x.Score =
            len !== 0
                ? x.ScoreArray.reduce((a: number, b: number) => a + b, 0) / len
                : null;
        x.averageTargetScore=x.targetScores.reduce((a: number, b: number) => a + b, 0) / x.targetScores.length;
    });
    return filteredData;
};

export const dataFormatter = (data: CapabilityList[]) => {
    const domains: StateDomainsType[] = [];
    data.forEach((x: CapabilityList) => {
        const ind: number = domains.findIndex(
            (c: StateDomainsType) => c.name === x.Domain
        );
        if (ind === -1) {
            domains.push({
                name: x.Domain,
                capabilities: [
                    {
                        name: x.Capabilities,
                        moderatedScore:
                            x.Score !== null
                                ? parseFloat(x.Score.toFixed(2))
                                : x.Score,
                        averageScore:
                            x.Score !== null
                                ? parseFloat(x.Score.toFixed(2))
                                : x.Score,
                        inScope: x.inScope,
                        targetScore:x.averageTargetScore

                    }
                ]
            });
        } else {
            domains[ind].capabilities.push({
                name: x.Capabilities,
                moderatedScore:
                    x.Score !== null ? parseFloat(x.Score.toFixed(2)) : x.Score,
                averageScore:
                    x.Score !== null ? parseFloat(x.Score.toFixed(2)) : x.Score,
                inScope: x.inScope,
                targetScore:x.averageTargetScore
            });
        }
    });
    return domains;
};

export const domainsAverage = (data: CampaignGroupAssessment[], readOnlyEnabled=false, isReadOnly=false, withCapability = false) => {
    const filteredData: DomainsData[] = [];
    data &&
        data.forEach(x => {
            x.assessments.forEach((a: CampaignAssessment) => {
                if(readOnlyEnabled && (isReadOnly || a.permission === PermissionTypeEnum.READ_ONLY_ACCESS) && (a.campaign.progress !== 100 && a.campaign.status !== 'COMPLETED')) {
                    return;
                }
                a.state.domains.forEach((b: MaturityOverviewDomain) => {
                    const ind: number = filteredData.findIndex(
                        (c: DomainsData) => c.Domain === b.name
                    );
                    if (ind === -1) {
                        filteredData.push({
                            ScoreArray: [
                                b.moderatedAverageScore
                                    ? b.moderatedAverageScore
                                    : b.averageScore
                            ],
                            targetScores: [b.targetAverageScore || 0],
                            Score: null,
                            Domain: b.name,
                            Capabilities: (withCapability && b.capabilities.length > 0) ? b.capabilities.map(c => ({
                                ScoreArray: [c.moderatedScore ?? c.averageScore],
                                Capabilities: c.name,
                                Score: c.moderatedScore ?? c.averageScore,
                                Domain: b.name,
                                inScope: c.inScope,
                                id: c.id,
                                targetScores: [c.targetScore || 0]
                            })) : []
                        });
                    } else {
                        filteredData[ind].ScoreArray.push(
                            b.moderatedAverageScore
                                ? b.moderatedAverageScore
                                : b.averageScore
                        );
                        filteredData[ind].targetScores.push(b.targetAverageScore||0);
                    }
                });
            });
        });

    filteredData.forEach(x => {
        const filtredScoreLength = x.ScoreArray.filter(item => item !== null)
            .length;
        x.Score =
            x.ScoreArray.reduce((a: number, b: number) => a + b, 0) /
            filtredScoreLength;
        x.averageTargetScore=(x.targetScores.reduce((a: number, b: number) => a + b, 0) /
            x.targetScores.length)||0;
    });

    return filteredData;
};

