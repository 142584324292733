import React, { useEffect, useState } from 'react';
import { Tooltip, ClickAwayListener, styled, createTheme, ThemeProvider } from '@mui/material';
import { arrowGenerator } from '../TooltipsHelpers';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type TooltipByClickProps = TooltipProps & {
    top?: number;
    isOpen: boolean;
    index?: number;
    width?: string;
    handleClose?: () => void;
    background?:string;
    marginTop?:string;
};

const TooltipByClick = ({
    title,
    top,
    width,
    isOpen,
    handleClose,
    background,
    marginTop,
    ...rest
}: TooltipByClickProps) => {

    const theme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        position: 'relative',
                        zIndex: 30,
                        maxWidth: 400,
                        padding: '0',
                        marginTop:`${marginTop ? marginTop : '14px'}`,
                        boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.34)',
                        background: `${background ? background : '#2f2f2f'}`,
                        minWidth: `${width ? width : 'auto'}`
                    },
                    arrow: {
                        position: 'absolute',
                        fontSize: 6,
                        '&::before': {
                            content: '""',
                            margin: 'auto',
                            display: 'block',
                            width: 0,
                            height: 0,
                            borderStyle: 'solid'
                        }
                    },
                    popper: arrowGenerator(background ? background :'#2f2f2f', top)
                },
            },
        },
    });

    const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(
        null
    );

    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);

    const handleToolTipClose = () => {
        setIsToolTipOpen(false);
        handleClose && handleClose();
    };

    useEffect(() => {
        if (isOpen) {
            setIsToolTipOpen(true);
        }
    }, [isOpen]);

    return (
        <ThemeProvider theme={theme}>
            <ClickAwayListener
                onClickAway={(e: any) => {
                    handleToolTipClose();
                }}>
                <div>
                <Tooltip
                    open={isToolTipOpen}
                    PopperProps={{
                        popperOptions: {
                            modifiers: [
                                {
                                    name: 'arrow',
                                    options: {
                                        enabled: Boolean(arrowRef),
                                        element: arrowRef,
                                    },
                                },
                            ],
                        }
                    }}
                    {...rest}
                    title={
                        <React.Fragment>
                            {title}
                            <span ref={setArrowRef} />
                        </React.Fragment>
                    }
                />
                </div>
            </ClickAwayListener>
        </ThemeProvider>
    );
};

export default TooltipByClick;
