import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Formik, FieldArray } from 'formik';
import gql from 'graphql-tag';
import Modal from 'react-modal';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '../../../../components';

import {
    AlignItems,
    HorizontalLine,
    ToggleSwitch,
    Text, Flex
} from '../../../../components';

import {
    CapabilityObjectiveWithDescriptions,
    CurrentCapability,
    Mark,
    ObjectiveWithScore,
    DescopedObjective, MaturityMatch, ObjectiveComponentWithDescriptions, CampaignData
} from '../types';

import { customModalStyles } from '../../../../components/PopUps/constants';
import { renderDescopePopup, getMarksList, validateWeighting, isComponentWeightingValid, isNISTFramework } from './ObjectivesHelpers';
import { ObjectiveTitle } from './components';
import { roundScore } from '../../../../utils/UtilsHelpers';
import {EventNote, KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import ComponentWeightedModal from './components/ComponentWeightedModal';
import ObjectiveWeightModal from './components/ObjectiveWeightModal';
import { Content } from './components/ContentModel';
import { DescopeIcon, RescopeIcon } from '../../Common';
import { IconButton } from '@mui/material';
import ObjectiveDescriptionCard from './components/ObjectiveDescriptionCard';
import { ASSESSMENT_CAPABILITY_WITH_FRAMEWORK } from '../MaturityScoreQueries';

const UPDATE_ASSESSMENT_OBJECTIVES_SCOPE = gql`
    mutation updateAssessmentObjectivesScope(
        $data: UpdateAssessmentObjectivesScopeInput!
    ) {
        updateAssessmentObjectivesScope(data: $data) {
            id
            averageScore
            inScope
            status
            objectives {
                id
                updateScopeReason
                inScope
                notes
                score
            }
        }
    }
`;

const UPDATE_ASSESSMENT_OBJECTIVES_WEIGHTING = gql`
    mutation updateAssessmentObjectivesWeighting(
        $data: UpdateAssessmentObjectiveWeightingInput!
    ) {
        updateAssessmentObjectivesWeighting(data: $data) {
            id
            averageScore
            inScope
            objectives {
                id
                updateScopeReason
                inScope
                notes
                score
                components {
                    score
                }
            }
        }
    }
`;

const UPDATE_ASSESSMENT_COMPONENTS_WEIGHTING = gql`
    mutation updateAssessmentComponentsWeighting(
        $data: UpdateAssessmentComponentsWeightingInput!
    ) {
        updateAssessmentComponentsWeighting(data: $data) {
            id
            averageScore
            inScope
            objectives {
                id
                updateScopeReason
                inScope
                notes
                score
                components {
                    score
                }
            }
        }
    }
`;

export type ObjectivesProps = {
    scoreMarks: string[];
    currentCapability: CurrentCapability;
    currentDomainIndex: number;
    currentCapabilityIndex: number;
    currentAssessmentId: string;
    setLoadingStatus: (status: boolean) => void;
    currentDomainId: string;
    currentCapabilityId: string;
    capabilityObjectives: CapabilityObjectiveWithDescriptions[];
    canEdit: boolean;
    currentIdsData: any;
    isDescopeModalOpen: boolean;
    setDirty: any;
    isDirty: boolean;
    match: MaturityMatch;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    pointOneDecimalEnable: boolean;
    isObjectiveComponentExist: boolean;
    campaignData?: CampaignData;
    frameworkName?: string;
    compensatingControlEnabled: boolean;
    assessmentReadOnly: boolean;
};

const Objectives: React.FC<ObjectivesProps> = ({
    scoreMarks,
    currentCapability,
    currentDomainIndex,
    currentCapabilityIndex,
    currentAssessmentId,
    setLoadingStatus,
    capabilityObjectives,
    canEdit,
    currentIdsData,
    isDescopeModalOpen,
    readOnlyEnabled,
    isReadOnly,
    pointOneDecimalEnable,
    match,
    setDirty,
    isDirty,
    isObjectiveComponentExist,
    campaignData,
    frameworkName,
    compensatingControlEnabled,
    assessmentReadOnly
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sameScope, setSameScope] = useState(false);
    const [modalId, setModalStatus] = useState('descope');
    const [notesStatus, setNotesStatus] = useState<string | any>(true);
    const [averageScore, setAverageScore] = useState<number | null>(null);
    const [marks, setMarks] = useState<Mark[]>([]);
    const [objectives, setObjectivesList] = useState<ObjectiveWithScore[]>([]);
    const [
        notFiltrationObjectivesList,
        setNotFiltationObjectivesList
    ] = useState<CapabilityObjectiveWithDescriptions[]>([]);
    const [descopedObjectivesList, setDescopedObjectivesList] = useState<
        DescopedObjective[]
    >([]);
    const [currentObjectiveId, setCurrentObjectiveId] = useState('');
    const [isObjectivesInScope, setObjectivesScope] = useState<boolean>(true);
    const [isReasonExist, setIsReasonExist] = useState<boolean>(false);

    const [isWeightedFormOpen, setIsWeightedFormOpen] = useState<boolean>(false);
    const [isObjectiveWeightModalOpen, setObjectiveWeightModalOpen] = useState(false);
    const [isWeightedHasError, setIsWeightedHasError] = useState(false);
    const [isComponentWeightedHasError, setIsComponentWeightedHasError] = useState(false);
    const [isComponentOpen, setIsComponentOpen] = useState<boolean[]>(new Array(currentCapability.objectives.length).fill(false));
    const [isbalanceWeightSuccessMessage, setbalanceWeightSuccessMessage] = useState<boolean>(false);
    const [isbalanceWeightFailureMessage, setbalanceWeightFailureMessage] = useState<boolean>(false);

    const [descopeObjectivesScope] = useMutation(
        UPDATE_ASSESSMENT_OBJECTIVES_SCOPE
        ,{
            refetchQueries: [
                {
                    query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                    variables: {
                        campaignId: match.params.campaignId,
                        data: {
                            assessmentId: match.params.assessmentId,
                            domainId: currentIdsData.domainId,
                            capabilityId: currentIdsData.capabilityId
                        }
                    }
                }
            ]
        }
    );

    const [updateObjectiveWeighting] = useMutation(UPDATE_ASSESSMENT_OBJECTIVES_WEIGHTING,{
        refetchQueries: [
            {
                query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                variables: {
                    campaignId: match.params.campaignId,
                    data: {
                        assessmentId: match.params.assessmentId,
                        domainId: currentIdsData.domainId,
                        capabilityId: currentIdsData.capabilityId
                    }
                }
            }
        ]
    });
    const [updateComponentsWeighting] = useMutation(UPDATE_ASSESSMENT_COMPONENTS_WEIGHTING, {
        refetchQueries: [
            {
                query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                variables: {
                    campaignId: match.params.campaignId,
                    data: {
                        assessmentId: match.params.assessmentId,
                        domainId: currentIdsData.domainId,
                        capabilityId: currentIdsData.capabilityId
                    }
                }
            }
        ]
    });
    useEffect(() => {
        if (capabilityObjectives) {
            setNotFiltationObjectivesList(capabilityObjectives);
        }
    }, [capabilityObjectives]);

    useEffect(() => {
        if (descopedObjectivesList.length === 0) {
            setSameScope(false);
            return;
        }
        setSameScope(!descopedObjectivesList.some((elem) => elem.inScope !== descopedObjectivesList[0].inScope));
        setModalStatus(descopedObjectivesList[0].inScope ? 'descope' : 'rescope');
    }, [descopedObjectivesList]);

    useEffect(() => {
        setDescopedObjectivesList([]);
    }, [currentCapability]);

    useEffect(() => {
        setAverageScore(currentCapability.averageScore);
    }, [currentCapability.averageScore]);

    useEffect(() => {
        const objectivesWithScores = capabilityObjectives
            .filter((item: CapabilityObjectiveWithDescriptions) =>
                isObjectivesInScope ? item.inScope : notFiltrationObjectivesList
            )
            .map((item: CapabilityObjectiveWithDescriptions) => {
                return {
                    ...item,
                    notes: item.notes || '',
                    isActive: false
                };
            });
             setObjectivesList(objectivesWithScores);
    }, [capabilityObjectives, isObjectivesInScope]);

    useEffect(() => {
        if (scoreMarks.length) {
            while (scoreMarks.length !== 5) {
                scoreMarks.shift();
            }

            const marksWithValues = getMarksList(scoreMarks).reduce(
                (acc: Mark[], curr: Mark) => {
                    if (Number.isInteger(curr.value)) {
                        return [...acc, { value: curr.value }];
                    }
                    return [...acc, { value: curr.value }];
                },
                []
            );
            setMarks(marksWithValues);
        }
    }, [scoreMarks]);
    const handleOnSave = (values: Content[],isPopUpOpen=false) => {
       const isValidated= validateWeighting(values.map(item=>item.value));
        if(isValidated) {
            setIsWeightedHasError(false);
            updateObjectiveWeighting({
                variables: {
                    data: {
                        assessmentId: currentAssessmentId,
                        domainId: currentIdsData.domainId,
                        capabilityId: currentIdsData.capabilityId,
                        objectives: values.map(item => {
                            return ({
                                objectiveId: item.id,
                                weighting: item.value
                            });

                        })
                    }
                }
            }).then((res) => {
                if(isPopUpOpen)
                {
                    setbalanceWeightSuccessMessage(true);
                    return;
                }
                setObjectiveWeightModalOpen(false);
                setIsWeightedHasError(false);
            }).catch(err => {
                    setObjectiveWeightModalOpen(false);
                    setbalanceWeightFailureMessage(true);
                    console.log('weighting err', err);
            });
        }
        else{
            setIsWeightedHasError(true);
        }
    };
    const handleChangeDescopeList = useCallback(
        ({
            isChecked,
            objective
        }: {
            isChecked: boolean;
            objective: DescopedObjective;
        }) => {
            setDescopedObjectivesList((state: DescopedObjective[]) => {
                if (
                    isChecked &&
                    !state.find((item: DescopedObjective) => {
                        return item.id === objective.id;
                    })
                ) {
                    return [...state, objective];
                }
                if (
                    !isChecked &&
                    state.find((item: DescopedObjective) => {
                        return item.id === objective.id;
                    })
                ) {
                    return state.filter(
                        (item: DescopedObjective) => item.id !== objective.id
                    );
                }
                return state;
            });
        },
        []
    );

    const handleOnSaveComponents = (values: ObjectiveWithScore,isPopUpOpen=false) => {
        const isValidated = isComponentWeightingValid(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            values.components && values.components.length > 0 ?
                values.components :
                []
        );
		if (isValidated) {
			setIsComponentWeightedHasError(false);
            const validatedComponents = values.components && values.components.map((c: ObjectiveComponentWithDescriptions) => {
                return {
                    componentId: c.id,
                    weighting: parseFloat(c.weighting.toString())
                };
            });

            updateComponentsWeighting({
                variables: {
                    data: {
                        assessmentId: currentAssessmentId,
                        domainId: currentIdsData.domainId,
                        capabilityId: currentIdsData.capabilityId,
                        objectiveId: currentObjectiveId,
                        components: validatedComponents
                    }
                }
            }).then(() => {
                if(isPopUpOpen)
                {
                    setbalanceWeightSuccessMessage(true);
                    return;
                }
                setIsWeightedFormOpen(false);
                setIsComponentWeightedHasError(false);
            })
            .catch(err => {
                setIsWeightedFormOpen(false);
                setIsComponentWeightedHasError(false);
                setbalanceWeightFailureMessage(true);
                console.log('weighting err', err);
            });
        } else {
            setIsComponentWeightedHasError(true);
		}
    };

    useEffect(() => {
        setDescopedObjectivesList([]);
    }, [isObjectivesInScope]);


    const titles = { title:currentCapability.name };
    const adjustButtonName = isNISTFramework(frameworkName) ? 'Adjust sub-category weights' : 'Adjust Weights';
    const manageButtonName = isNISTFramework(frameworkName) ? 'Manage Component Weights' : 'Manage Components';
    return (
        <div>
            <ContainerWithMargin>
                <ButtonText>In scope objectives only</ButtonText>
                <ToggleSwitch
                    turnedOn={isObjectivesInScope}
                    setTurnOn={(e: boolean) => {
                        if (!canEdit) {
                            return;
                        }
                        setObjectivesScope(e);
                    }}
                />
                <ButtonText>Collapse all notes</ButtonText>
                <ToggleSwitch
                    turnedOn={notesStatus}
                    setTurnOn={(e: boolean) => {
                        if (!canEdit) {
                            return;
                        }
                        setNotesStatus(e);
                    }}
                />
                {sameScope ? (
                    modalId === 'descope' ? (
                        <Block>
                            <Button
                                disabled={assessmentReadOnly ? true : !canEdit}
                                variant="outlined"
                                aria-label='descope button'
                                onClick={() => setIsModalOpen(true)}
                            >
                                <DescopeIcon />
                                Descope
                            </Button>
                        </Block>
                    ) : (
                        <Block>
                            <Button
                                disabled={!canEdit}
                                variant="outlined"
                                aria-label='rescope button'
                                onClick={() => setIsModalOpen(true)}
                            >
                                <RescopeIcon />
                                Rescope
                            </Button>
                        </Block>
                    )
                ) : null}
                {isObjectiveComponentExist && !(readOnlyEnabled && isReadOnly)&& <Button
                    style={{minHeight:'58px', marginLeft:'auto' }}
                    onClick={()=>{
                         setObjectiveWeightModalOpen(true);
                    }
                }
                    variant="outlined"
                >{adjustButtonName}</Button>}
            </ContainerWithMargin>

            <ObjectiveTitle
                averageScore={
                    averageScore === null ? null : roundScore(averageScore, pointOneDecimalEnable)
                }
                marks={marks}
                name={(currentCapability && currentCapability.name) || ''}
                canEdit={canEdit}
                scoreMarks={scoreMarks}
            />

            <Formik
                enableReinitialize
                initialValues={{
                    objectives
                }}
                onSubmit={(_values, { setSubmitting }) => {
                    if (!canEdit) {
                        return;
                    }
                    setSubmitting(false);
                }}>
                    {({ setFieldValue, values }) => (
                    <FieldArray
                        name="objectives"
                        render={arrayHelpers => (
                            <>
                                {values.objectives.map(
                                    (
                                        objective: ObjectiveWithScore,
                                        objectiveIndex: number
                                    ) => {
                                            return (
                                            <div key={objective.id}>
                                                <ObjectiveDescriptionCard
                                                    key={`objective-card-${objective.id}`}
                                                    CampaignData= {campaignData}
                                                    inScope={objective.inScope}
                                                    isDescopeModalOpen={
                                                        isDescopeModalOpen
                                                    }
                                                    canEdit={
                                                        canEdit &&
                                                        currentCapability.inScope
                                                    }
                                                    notesStatus={notesStatus}
                                                    descriptionsList={
                                                        objective.results || []
                                                    }
                                                    idTitle={
                                                        objective.displayRef || objective.reference
                                                    }
                                                    description={objective.name}
                                                    setLoadingStatus={
                                                        setLoadingStatus
                                                    }
                                                    notes={objective.notes}
                                                    guidance={
                                                        objective.guidance
                                                    }
                                                    handleChange={isChecked => {
                                                        if (!canEdit) {
                                                            return;
                                                        }
                                                        arrayHelpers.replace(
                                                            objectiveIndex,
                                                            {
                                                                ...objective,
                                                                isActive: isChecked
                                                            }
                                                        );
                                                        handleChangeDescopeList(
                                                            {
                                                                isChecked,
                                                                objective: {
                                                                    id:
                                                                        objective.id,
                                                                    name:
                                                                        objective.reference,
                                                                    inScope:
                                                                        objective.inScope
                                                                }
                                                            }
                                                        );
                                                    }}
                                                    marksList={marks}
                                                    checkboxName={`objectives[${objectiveIndex}].isActive`}
                                                    isActive={
                                                        objective.isActive
                                                    }
                                                    score={roundScore(objective.score, pointOneDecimalEnable)}
                                                    compensatingControlValue={objective.compensatingControlEnable}
                                                    scoreMarks={scoreMarks}
                                                    assessmentId={
                                                        currentAssessmentId
                                                    }
                                                    domainIndex={
                                                        currentDomainIndex
                                                    }
                                                    domainId={
                                                        currentIdsData.domainId
                                                    }
                                                    capabilityId={
                                                        currentIdsData.capabilityId
                                                    }
                                                    objectiveId={objective.id}
                                                    capabilityIndex={
                                                        currentCapabilityIndex
                                                    }
                                                    objectiveIndex={notFiltrationObjectivesList.findIndex(
                                                        (
                                                            item: CapabilityObjectiveWithDescriptions
                                                        ) => {
                                                            return (
                                                                item.id ===
                                                                objective.id
                                                            );
                                                        }
                                                    )}
                                                    handleScoreChange={(
                                                        maturityScore: number
                                                    ) => {
                                                        if (!canEdit) {
                                                            return;
                                                        }
                                                        setFieldValue(
                                                            `objectives[${objectiveIndex}].score`,
                                                            maturityScore
                                                        );
                                                    }}
                                                    readOnlyEnabled={readOnlyEnabled}
                                                    isReadOnly={isReadOnly}
                                                    setDirty={setDirty}
                                                    isDirty={isDirty}
                                                    capabilityStatus={currentCapability.status}
                                                    match={match}
                                                    isComponentCard={false}
                                                    isObjectiveComponentExist={isObjectiveComponentExist}
                                                    compensatingControlEnabled={compensatingControlEnabled}
                                                />
                                                {objective.components && <ManageComponents>
                                                    <EventNote/>
                                                    <IconButton onClick={() => {
                                                        !(readOnlyEnabled && isReadOnly)&& setIsWeightedFormOpen(true);
                                                        setCurrentObjectiveId(objective.id);
                                                        }}
                                                    >
                                                        <LinkText>{manageButtonName}</LinkText>
                                                    </IconButton>
                                                    {(currentObjectiveId===objective.id)&&
                                                    <ComponentWeightedModal
                                                        currentAssessmentId={currentAssessmentId}
                                                        currentCampaignId={match.params.campaignId}
                                                        currentDomainId={currentIdsData.domainId}
                                                        currentCapabilityId={currentIdsData.capabilityId}
                                                        handleOnSave={handleOnSaveComponents}
                                                        isWeightedFormOpen={isWeightedFormOpen}
                                                        setIsWeightedFormOpen={setIsWeightedFormOpen}
                                                        objective={objective}
                                                        isError={isComponentWeightedHasError}
                                                        setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                                                        balanceWeightSuccessMessage={isbalanceWeightSuccessMessage}
                                                        balanceweightFailureMessage={isbalanceWeightFailureMessage}
                                                    />}
                                                    {isComponentOpen[objectiveIndex] ? (
                                                        <IconButton onClick={()=>{
                                                            isComponentOpen[objectiveIndex]=false;
                                                            setIsComponentOpen({...isComponentOpen});
                                                        }}
                                                        aria-label="expand components"
                                                        >
                                                            <KeyboardArrowUpIcon/>
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton onClick={()=>{
                                                            isComponentOpen[objectiveIndex]=true;
                                                            setIsComponentOpen({...isComponentOpen});
                                                        }}
                                                        aria-label="collapse components"
                                                        >
                                                            <KeyboardArrowDownIcon/>
                                                        </IconButton>
                                                    )}
                                                </ManageComponents>}
                                                {isComponentOpen[objectiveIndex] && objective.components&&objective.components.map((component,componentIndex)=>{
                                                    return(
                                                       <>
                                                        <ComponentsContainer
                                                            key={`component-container-${componentIndex}`}>
                                                            <ObjectiveDescriptionCard
                                                                key={`component-objective-card-${componentIndex}`}
                                                                inScope={component.inScope}
                                                                isDescopeModalOpen={false}
                                                                canEdit={canEdit && component.inScope}
                                                                notesStatus={true}
                                                                descriptionsList={
                                                                    component.results || []
                                                                }
                                                                idTitle={component.reference}
                                                                description={component.name}
                                                                setLoadingStatus={
                                                                    setLoadingStatus
                                                                }
                                                                notes={''}
                                                                guidance={''}
                                                                handleChange={()=>{}}
                                                                marksList={marks}
                                                                checkboxName={''}
                                                                isActive={!component.inScope}
                                                                score={component.score}
                                                                scoreMarks={scoreMarks}
                                                                assessmentId={currentAssessmentId}
                                                                domainIndex={currentDomainIndex}
                                                                domainId={currentIdsData.domainId}
                                                                capabilityId={currentIdsData.capabilityId}
                                                                objectiveId={objective.id}
                                                                componentId={component.id}
                                                                capabilityIndex={
                                                                    currentCapabilityIndex
                                                                }
                                                                objectiveIndex={notFiltrationObjectivesList.findIndex(
                                                                    (
                                                                        item: CapabilityObjectiveWithDescriptions
                                                                    ) => {
                                                                        return (
                                                                            item.id ===
                                                                            objective.id
                                                                        );
                                                                    }
                                                                )}
                                                                handleScoreChange={(
                                                                    maturityScore: number
                                                                ) => {
                                                                    if (!canEdit) {
                                                                        return;
                                                                    }
                                                                    setFieldValue(
                                                                        `objectives[${objectiveIndex}].components[${componentIndex}].score`,
                                                                        maturityScore
                                                                    );
                                                                }}
                                                                readOnlyEnabled={readOnlyEnabled}
                                                                isReadOnly={isReadOnly}
                                                                setDirty={setDirty}
                                                                isDirty={isDirty}
                                                                capabilityStatus={currentCapability.status}
                                                                match={match}
                                                                isComponentCard={true}
                                                                isObjectiveComponentExist={isObjectiveComponentExist}
                                                                compensatingControlEnabled={compensatingControlEnabled}
                                                            />
                                                        </ComponentsContainer>
                                                       </>
                                                    );

                                                })}
                                                <HorizontalLineCustom />
                                            </div>
                                        );
                                    }
                                )}
                            </>
                        )}
                    />
                )}
            </Formik>
            {
                <ObjectiveWeightModal
                    handleOnSave={handleOnSave}
                    isOpen={isObjectiveWeightModalOpen}
                    setOpen={setObjectiveWeightModalOpen}
                    objectives={objectives}
                    titles={titles}
                    isError={isWeightedHasError}
                    setbalanceWeightSuccessMessage={setbalanceWeightSuccessMessage}
                    balanceWeightSuccessMessage={isbalanceWeightSuccessMessage}
                    balanceweightFailureMessage={isbalanceWeightFailureMessage}
                />
            }
            <Modal
                isOpen={isModalOpen}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
                style={customModalStyles}
                onRequestClose={() => setIsModalOpen(false)}
            >
                {renderDescopePopup({
                    match,
                    handleClose: () => setIsModalOpen(false),
                    descopedObjectivesList,
                    modalId,
                    currentAssessmentId,
                    currentDomainId: currentIdsData.domainId,
                    currentCapabilityId: currentIdsData.capabilityId,
                    descopeObjectivesScope,
                    isReasonExist,
                    setIsReasonExist
                })}
            </Modal>
        </div>
    );
};

const ContainerWithMargin = styled(AlignItems)`
    margin-bottom: 35px;
`;

const Block = styled(AlignItems)`
    margin-left: 20px;
`;

const HorizontalLineCustom = styled(HorizontalLine)`
    margin: 10px 0;
`;

const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px;
`;

const ComponentsContainer = styled('div')`
    margin: 10px 0px;
`;

const LinkText = styled(Flex)`
    font-size: 15px;
    color: #2d2d2d;
    text-decoration: underline;
`;

const ManageComponents = styled(AlignItems)`
    padding: 16px 50px 0 32px;
    cursor: pointer;
`;

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)`
    color: #2d2d2d;
`;

const KeyboardArrowUpIcon = styled(KeyboardArrowUp)`
    color: #2d2d2d;
`;

export default Objectives;
