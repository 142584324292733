import React from 'react';
import { styled } from '@mui/material/styles';
import { CapabilityWithStatus } from './types';
import {
    Column,
    JustifyContentCenter,
    Text,
    LinearProgress
} from '../../../../components';
import { detailsFilterOptions } from './constants';
import { DetailsFilterOption } from './types';
import { countProgress } from '../../../../utils';

const DetailsTableRowsTooltip: React.FC<{
    capability: CapabilityWithStatus;
}> = ({ capability }) => {
    const filterOption = detailsFilterOptions.find(
        (option: DetailsFilterOption) => option.id === capability.status
    );
    const filterStatus = filterOption ? filterOption.label : capability.status;

    return (
        <Column style={{ margin: -8 }}>
            <RowContainer>
                <Heading>{capability && capability.name}</Heading>
                <CapabilityStatus>
                    Capability status - {filterStatus.toLowerCase()}
                </CapabilityStatus>
                <LinearProgress
                    progress={
                        filterOption
                            ? countProgress({
                                  progressLength: 5,
                                  value:
                                      (filterOption &&
                                          filterOption.linearProgress) ||
                                      0
                              })
                            : 0
                    }
                    containerWidth="188px"
                />
            </RowContainer>
        </Column>
    );
};

const CapabilityStatus = styled(Text)`
    font-size: 13px;
    color: #ffffff;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
`;

const Heading = styled(Text)`
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 4px;
    text-align: center;
`;

const RowContainer = styled(JustifyContentCenter)`
    padding: 10px 16px;
    background-color: #2d2d2d;
    flex-direction: column;
    border-radius: 2px;
`;

export default DetailsTableRowsTooltip;
