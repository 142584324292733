import { styled } from '@mui/material/styles';

const CoverScreen = styled('div')`
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:998;
`;

export default CoverScreen;