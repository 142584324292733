/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Modal from 'react-modal';
import iconArrowDown from '../../../../../assets/images/arrow-down-solid-black.svg';
import {
    AlignItems,
    RedButton,
    Text,
    ImgContainer,
    PopUpHeader
} from '../../../../../components';
import { AssessmentDomain } from '../../types';
import { Formik, Form, FieldArray } from 'formik';
import { customModalStyles } from '../../../../../components/PopUps/constants';
import {oneDecimalNumber, TwelveDecimalNumber, weightBalanceSuccessMessages, weightingValidationMessage} from '../../../../../utils';

export type WeightedProps = {
    isOpen: boolean;
    setOpen: any;
    domains: AssessmentDomain[];
    handleOnSave: any;
    isError: boolean;
    isSuccess: boolean;
    title?: string;
    setbalanceWeightSuccessMessage: any;
    isbalanceWeightSuccessMessage: boolean;
};


const AssessmentWeightingModal: React.FC<WeightedProps> = ({
    isOpen,
    setOpen,
    domains,
    handleOnSave,
    isError,
    isSuccess,
    title,
    setbalanceWeightSuccessMessage,
    isbalanceWeightSuccessMessage,
}) => {
    const getContent =(data: AssessmentDomain[])=> data.map(domain => {
        return ({
            id: domain.id,
            name: domain.name,
            value: domain.weighting || 0,
            capability: domain.capabilities.map(cap => {
                return {
                    id: cap.id,
                    inScope: cap.inScope,
                    name: cap.name,
                    value: cap.weighting ?? 0
                };
            })
        });
    });

    const [state, setState] = useState<{
        settings: {
            id: string;
            open: boolean;
        }[];
    }>({ settings: domains.map(domain => {
        return ({
            id: domain.id,
            open: domains[0].id === domain.id
        });
    }) || []});
    const [content,setContent]=useState(getContent(domains));

    const handleCollapseToggle = (id: string) => {
        setState(() => ({
                ...state,
                settings: state.settings.map(item =>
                item.id === id ? { ...item, open: !item.open } : item
            )
        }));
    };

    useEffect(() => {
        if(isSuccess){
            setOpen(false);
        }
        setContent(getContent(domains));
    }, [domains]);

    let submitAction: string | undefined;
    return (
        <>
            <Modal
                isOpen={isOpen}
                contentLabel="Weighted Average"
                style={customModalStyles}
                ariaHideApp={false}
            >


                <Formik
                    enableReinitialize
                    initialValues={{ content }}
                    onSubmit={(values,actions) => {

                        if(submitAction==='balance') {
                            const domainLength=values.content.length||1;
                            values.content.forEach((dom: any)=> {
                                dom.value = TwelveDecimalNumber(100 / domainLength);
                                const capabilitiesLength= dom.capability.filter((cap: any)=>cap.inScope).length;
                                dom.capability.forEach((cap: any)=>cap.inScope?cap.value=TwelveDecimalNumber(100/capabilitiesLength):cap.value=0);
                                handleOnSave(values.content,true);
                                actions.setValues({...values});

                            });
                        }
                        else if(submitAction==='submit'){
                            handleOnSave(values.content);
                            setbalanceWeightSuccessMessage(false);
                        }

                    }}>
                        {({ setFieldValue, values,handleSubmit }) => {
                        return (
                            <>
                                <PopUpHeader handleClose={() => {
                                    submitAction='submit';
                                    handleSubmit();
                                }} title={title?title:'Adjust Weighting'}/>
                                <Form style={{padding: '25px'}}>
                                    <FieldArray
                                        name="content"
                                        render={(arrayHelpers) => (
                                            <>
                                                <ObjectiveHeader>
                                                    <TitleContainer>
                                                        <BoldText>Domains</BoldText>
                                                    </TitleContainer>
                                                    <HeaderTitle>Weight</HeaderTitle>
                                                </ObjectiveHeader>
                                                {values.content.map((item, index) => {
                                                    return (
                                                        <>
                                                            <QuestionsContainer
                                                                style={{
                                                                    borderBottom: values.content &&
                                                                        (values.content.length - 1) === index ? 'none' : '1px solid',
                                                                    marginBottom: 10,
                                                                    paddingBottom: 10
                                                                }}
                                                                key={item.id}>
                                                                <div style={{ display: 'block' }}>
                                                                    <QuestionTitle>
                                                                        <BoldText>{item && item.name}</BoldText>
                                                                    </QuestionTitle>
                                                                </div>
                                                                <div style={{
                                                                    display: 'block',
                                                                    alignSelf: 'center',
                                                                    marginLeft: '10px'
                                                                }}>
                                                                    <TextField
                                                                    defaultValue={item && item.value ? oneDecimalNumber(item.value) : 0}
                                                                    type="number"
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                '%'
                                                                            )
                                                                        }}
                                                                        inputProps={{
                                                                            maxLength: 3,
                                                                            disabled: false,
                                                                            min: 0,
                                                                            max: 100,
                                                                            step: '0.1',
                                                                            'aria-label': 'set domain weight'
                                                                        }}
                                                                        margin='dense'
                                                                        name={`weighting${index}`}
                                                                        style={{
                                                                            margin: 'auto',
                                                                            marginLeft: 15,
                                                                            minWidth: 100
                                                                        }}
                                                                        variant='outlined'
                                                                        onChange={(e: any) => {
                                                                            setFieldValue(
                                                                                `content[${index}].value`,
                                                                                parseFloat(e.target.value)
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </QuestionsContainer>
                                                        </>);
                                                })}
                                                <ObjectiveHeader>
                                                    <TitleContainer>
                                                        <BoldText>Capabilities</BoldText>
                                                    </TitleContainer>
                                                    <HeaderTitle>Weight</HeaderTitle>
                                                </ObjectiveHeader>
                                                {values.content.map((item, index) => {
                                                    return (
                                                        <>
                                                            <ObjectiveHeader>
                                                                <TitleContainer>
                                                                    <BoldText>{item.name}</BoldText>
                                                                </TitleContainer>
                                                                <button
                                                                    style={{
                                                                        border: 'none',
                                                                        background: 'none'
                                                                    }}
                                                                    onClick={(_e) => { handleCollapseToggle(item.id); }}>
                                                                    <ImgContainer
                                                                        style={{
                                                                            width: 15,
                                                                            verticalAlign: 'middle',
                                                                            padding: 5,
                                                                            // @ts-ignore
                                                                            transform: state.settings.find(s => s.id === item.id).open ?
                                                                                'rotate(180deg)' : 'rotate(0deg)'
                                                                        }}
                                                                        src={iconArrowDown}
                                                                        alt="collapse toggle"
                                                                    />
                                                                    {
                                                                        // @ts-ignore
                                                                        state.settings.find(s => s.id === item.id).open ? 'Hide' : 'Show'
                                                                    } capabilities
                                                                </button>
                                                            </ObjectiveHeader>
                                                            <CollapseSection
                                                                style={{
                                                                    // @ts-ignore
                                                                    display: state.settings.find(s => s.id === item.id).open ? 'block' : 'none'
                                                                }}
                                                            >
                                                                {item.capability.map((cap, capIndex) => {
                                                                    return (
                                                                        <>
                                                                            <QuestionsContainer
                                                                                style={{
                                                                                    borderBottom: item.capability &&
                                                                                        (item.capability.length - 1) === capIndex ? 'none' : '1px solid',
                                                                                    marginBottom: 10,
                                                                                    paddingBottom: 10
                                                                                }}
                                                                                key={cap.id}>
                                                                                <div style={{ display: 'block' }}>
                                                                                    <QuestionTitle>
                                                                                        <BoldText>{cap && cap.name}</BoldText>
                                                                                    </QuestionTitle>
                                                                                </div>
                                                                                <div style={{
                                                                                    display: 'block',
                                                                                    alignSelf: 'center',
                                                                                    marginLeft: '10px',
                                                                                    color: cap && cap.inScope === false ? '#6E6E6E' : '#000'
                                                                                }}>
                                                                                    <TextField
                                                                                        defaultValue={
                                                                                        cap && cap.inScope === false ? 0 : cap && (oneDecimalNumber(cap.value))
                                                                                        }
                                                                                        type="number"
                                                                                        InputProps={{
                                                                                            endAdornment: (
                                                                                                '%'
                                                                                            )
                                                                                        }}
                                                                                        inputProps={{
                                                                                            maxLength: 3,
                                                                                            disabled: false,
                                                                                            min: 0,
                                                                                            max: 100,
                                                                                            step: '0.1',
                                                                                            'aria-label': 'set capability weight'
                                                                                        }}
                                                                                        margin="dense"
                                                                                        name={`weighting${index}-${capIndex}`}
                                                                                        style={{
                                                                                            margin: 'auto',
                                                                                            marginLeft: 15,
                                                                                            minWidth: 100
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        onChange={(e: any) => {
                                                                                            setFieldValue(
                                                                                                `content[${index}].capability[${capIndex}].value`,
                                                                                                parseFloat(e.target.value)
                                                                                            );
                                                                                        }}
                                                                                        // tslint:disable-next-line: no-redundant-boolean
                                                                                        disabled={cap && cap.inScope === false ? true : false}
                                                                                    />
                                                                                </div>
                                                                            </QuestionsContainer>
                                                                        </>);
                                                                    })}
                                                            </CollapseSection>
                                                        </>);
                                                })}
                                                {isError &&
                                                    <TitleContainer style={{
                                                        textAlign: 'right',
                                                        color: '#de3328'
                                                    }}>
                                                        {weightingValidationMessage}
                                                    </TitleContainer>
                                                }
                                                {
                                                        isbalanceWeightSuccessMessage ?
                                                        <TitleContainer style={{textAlign: 'right',
                                                        color: '#4588c3'}}>{weightBalanceSuccessMessages}</TitleContainer> :''}
                                                <AlignItems>
                                                    <RedButton
                                                        style={{ marginRight: 16,marginLeft: 'auto' }}
                                                        type="reset"
                                                        onClick={() => {
                                                            submitAction='balance';
                                                            handleSubmit();
                                                        }}>
                                                        <TemplatesButtonText>
                                                            Balance weights
                                                        </TemplatesButtonText>
                                                    </RedButton>
                                                <RedButton
                                                    style={{ marginRight: 16, paddingLeft: 16 }}
                                                    type="submit"
                                                    onClick={() => {
                                                            submitAction='submit';
                                                            handleSubmit();
                                                        }}
                                                >
                                                        <TemplatesButtonText>
                                                            Save
                                                        </TemplatesButtonText>
                                                </RedButton>
                                                </AlignItems>
                                            </>
                                        )}
                                    />
                                </Form>
                            </>
                        );
                }}
               </Formik>
            </Modal>
        </>
    );
};

const ObjectiveHeader = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const QuestionsContainer = styled('div')`
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const BoldText = styled('span')`
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
`;

const TemplatesButtonText = styled(Text)`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    margin: 0 16px 0 8px;
`;

const CollapseSection = styled('section')`
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
`;

const TitleContainer = styled('p')``;
const HeaderTitle = styled('p')``;

const QuestionTitle = styled('p')``;

export default AssessmentWeightingModal;
