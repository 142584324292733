
import React from 'react';
import Modal from 'react-modal';
import { ThreatRiskModalType } from '../../types';
import { withStyles } from '@mui/material/styles';
import {
    AlignItems,
    Text,
    BoldText,
    JustifyContentCenter,
    PopUpHeader
 } from '../../../../../components';
 import { styled } from '@mui/material/styles';

 import iconThreatTabActive from '../../../../../assets/images/icon-tab-threat-active.svg';
 import iconRiskActive from '../../../../../assets/images/icon-tab-risk-active.svg';

 import { ScoreTable } from '../tables';
 import Tabs from '@mui/material/Tabs';
 import Tab, { TabProps } from '@mui/material/Tab';
 import { addRiskModalWrapper } from '../../constants';

 import {
    riskRating,
    riskRatingTwo,
    threatRating,
    likelihoodScoreVeryHigh,
    likelihoodScoreHigh,
    likelihoodScoreMedium,
    likelihoodScoreLow,
    likelihoodScoreVeryLow,
    likelihoodScoreCatastrophic,
    likelihoodScoreCritical,
    likelihoodScoreModerate,
    likelihoodScoreMinimal,
    likelihoodScoreInsignificant
} from '../../constants';

const CalculationInfoModal: React.FC<ThreatRiskModalType> = ({
    isModalOpen,
    setIsModalOpen,
    value,
    handleChange
}) => {
    return (
        <Modal
            isOpen={isModalOpen}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            style={addRiskModalWrapper}
            onRequestClose={() => setIsModalOpen(false)}
        >
            <PopUpHeader title='Calculation info' handleClose={() => setIsModalOpen(false)}/>
            <ModalHeader>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                    indicatorColor="primary"
                    TabIndicatorProps={{
                        style: {
                            height: 4,
                            backgroundColor: '#de3328'
                        }
                    }}
                >
                    <CustomTab
                        label={
                            <TabContainer>
                                <img
                                    src={iconThreatTabActive}
                                    alt="maturity overview"
                                    style={{ marginRight: 8 }}
                                />
                                <TabText>Threat Scenarios</TabText>
                            </TabContainer>
                        }
                    />
                    <CustomTab
                        label={
                            <TabContainer>
                                <img
                                    src={iconRiskActive}
                                    alt="assessment"
                                    style={{ marginRight: 8 }}
                                />
                                <TabText>
                                    Risk Dashboard
                                </TabText>
                            </TabContainer>
                        }
                    />
                </Tabs>
            </ModalHeader>

            {value ?
                <RiskThreatTab>
                    <RiskThreatTabHeader>
                        <BoldText fontSize="19px" color="#2d2d2d">Inherent Risk Calculation</BoldText>
                        <ul>
                            <li><BoldText fontSize="15px" color="#2d2d2d">Inherent risk (IR):</BoldText></li>
                        </ul>
                        <NoListStyle>
                            <li><Text>IR = Inherent Impact * ((Threat sophistication score * (threat proximity score + threat event prevalence score)) / 5)</Text></li>
                        </NoListStyle>
                        <BoldText fontSize="19px" color="#2d2d2d" style={{ paddingTop: '1.5rem' }}>Residual Risk Calculation</BoldText>
                        <ul>
                            <li><BoldText fontSize="15px" color="#2d2d2d">Residual risk (RR):</BoldText></li>
                        </ul>
                        <NoListStyle>
                            <li><Text>RR = Residual Impact * Residual Likelihood</Text></li>
                        </NoListStyle>
                    </RiskThreatTabHeader>
                    <RiskThreatTabBody>
                        <HeaderText size="16px" color="#2d2d2d">
                            The residual risk and residual likelihood are derived from these two matrices:
                        </HeaderText>
                        <HeaderText size="14px" color="#575757" margin="10px 0">
                            Qualitative risk reduction ruleset (from Inherent to Residual risk scores)
                        </HeaderText>
                        <ScoreTable
                            columnOneData={riskRating}
                            columnTwoData={[
                                threatRating,
                                threatRating,
                                threatRating,
                                threatRating,
                                threatRating
                            ]}
                            columnThreeData={[
                                likelihoodScoreVeryHigh,
                                likelihoodScoreHigh,
                                likelihoodScoreMedium,
                                likelihoodScoreLow,
                                likelihoodScoreVeryLow
                            ]}
                            columnOneTitle="Inherent likelihood score"
                            columnTwoTitle="Protective resiliense status"
                            columnThreeTitle="Residual likelihood score"
                        />

                        <ScoreTable
                            columnOneData={riskRatingTwo}
                            columnTwoData={[
                                threatRating,
                                threatRating,
                                threatRating,
                                threatRating,
                                threatRating
                            ]}
                            columnThreeData={[
                                likelihoodScoreCatastrophic,
                                likelihoodScoreCritical,
                                likelihoodScoreModerate,
                                likelihoodScoreMinimal,
                                likelihoodScoreInsignificant
                            ]}
                            columnOneTitle="Inherent impact score"
                            columnTwoTitle="Detective/corrective resilience status"
                            columnThreeTitle="Residual likelihood score"
                        />
                    </RiskThreatTabBody>
                </RiskThreatTab>
                :
                <RiskThreatTab>
                    <BoldText fontSize="19px" color="#2d2d2d">Threat Resilience Calculation</BoldText>
                    <TextSpacing>
                        <Text size="16px" color="#2d2d2d" margin="1em 0">The resilience score is a calculation that averages the preventative objective scores with a combined detective and corrective score.</Text>
                    </TextSpacing>
                    <TextSpacing>
                        <Text size="16px" color="#2d2d2d" margin="1em 0">For every objective it uses either of the following formulae to find the weighted percentage:</Text>
                    </TextSpacing>
                    <LeftBulletPoints>
                        <ul>
                            <li><BoldText fontSize="15px" color="#2d2d2d">Preventative percentage (PP):</BoldText></li>
                        </ul>
                        <NoListStyle>
                            <li><Text size="15px" color="#2d2d2d">PP = ((0.5 / sum of preventative weightings) * weighting) * (score / 5))</Text></li>
                        </NoListStyle>
                        <ul>
                            <li><BoldText fontSize="15px" color="#2d2d2d">Detective or corrective percentage (DP):</BoldText></li>
                        </ul>
                        <NoListStyle>
                            <li><Text size="15px" color="#2d2d2d">DP = ((0.5 / sum of preventative weightings) * weighting) * (score / 5)</Text></li>
                        </NoListStyle>
                    </LeftBulletPoints>
                    <MiddleBulletPoints>
                        <ul>
                            <li><BoldText fontSize="15px" color="#2d2d2d">Resilience score (RS)</BoldText></li>
                        </ul>
                        <NoListStyle>
                            <li><Text size="15px" color="#2d2d2d">RS = preventive % + detective or corrective %</Text></li>
                        </NoListStyle>
                    </MiddleBulletPoints>
                    <TextSpacing>
                        <BoldText fontSize="16px" color="#2d2d2d">Note: A key objective contributes twice as much to the score of 1000, doubling its influence on the resilience score.</BoldText>
                    </TextSpacing>
                </RiskThreatTab>
            }
        </Modal>
    );
};

const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
    padding: '0',
}));

const TextSpacing = styled('div')`
    padding: 1em 0;
`;

const LeftBulletPoints = styled('div')`
    padding-left: 7rem;
`;

const MiddleBulletPoints = styled('div')`
    padding-left: 12rem;
`;

const NoListStyle = styled('ul')`
    list-style: none;
`;

const HeaderText = styled(Text)`
    width: 100%;
    text-align: center;
    display: block;
`;

const RiskThreatTabHeader = styled('div')`
    border-bottom: solid 1px #eaeae9;
    padding-bottom: 1rem;
`;

const RiskThreatTabBody = styled('div')`
    padding: 1rem 0 0 0;
`;

const RiskThreatTab = styled('div')`
    padding: 3rem 3rem 2rem 3rem;
`;

const ModalHeader = styled(JustifyContentCenter)`
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.1);
    border: solid 1px #eaeae9;
    background-color: #f8f8f8;
    height: 56px;
`;

const TabText = styled(Text)`
    color: #575757;
    font-size: 16px;
    text-transform: none;
`;

const TabContainer = styled(AlignItems)`
    box-sizing: border-box;
    height: 56px;
`;

export default CalculationInfoModal;
