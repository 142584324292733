import { gql } from 'apollo-boost';

export const USER_DATA = gql`
    query USER_DATA {
        me {
            id
            firstName
            lastName
            jobTitle
            email
            role
            tosAcceptedAt
            countryUsers {
                countryId
                campaignCreateEnabled
                isCountryAdmin
            }
            clients {
                id
                name
                countryId
                isSynced
                country
                benchmarkingEnabled
                owner {
                    id
                }
            }
            campaigns {
                id
                title
                description
                benchmarkingEnabled
            }
        }
    }
`;

export const GLOBAL_SETTINGS = gql`
    query globalSettings {
        globalSettings {
        id
        name
        payload {
            name
        }
        status
        updatedAt
        createdAt
        }
    }
`;
