import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Chip, IconButton, LinearProgress, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { TextField as CustomText } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import Modal from 'react-modal';
import {v4 as uuid} from 'uuid';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField as MaterialUITextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import {
    CustomField,
    RedButton,
    Text,
    BoldText,
    Flex,
    Column,
    JustifyContentEnd,
    AlignItems,
    Button
} from '../../../../components';

import greyCloseIcon from '../../../../assets/images/Atmos_Icons_Close.svg';
import closeIcon from '../../../../assets/images/Atmos_Icons_Close.svg';
import {
    CampaignEntityGroup,
    SelectedStakeholder,
    DropDownEntityType
} from '../types';
import { ErrorText } from '../../../../components/base/Text/ErrorText';
import { dynamicSort } from '../../../../utils/UtilsHelpers';

const  state = {
    isSubmitting :true,
};

type Props = {
    dropdownValues: any;
    entityData: any;
    setSelectedEntityGroups: any;
    selectedEntityGroups: CampaignEntityGroup[];
    setSelectedEntityGroup: any;
    entityGroupOpen: boolean;
    setEntityGroupOpen: any;
    newStakeHolders: string[];
    setNewStakeholders: any;
    allStakeholdersInCampaign: string[];
    setAllStakeholdersInCampaign: any;
};



const EntityGroupSchema = Yup.object().shape({
    name: Yup.string().required('Required')
    .max(35, 'Too Long!(Should be Maximum 35 Character)'),
    entityTypeId: Yup.number().required('Required').min(1, 'Select Entity type'),
    ownerId: Yup.string().nullable()
});
const EntityGroupForm: React.FC<Props> = ({
    dropdownValues,
    entityData,
    setSelectedEntityGroups,
    selectedEntityGroups,
    setSelectedEntityGroup,
    entityGroupOpen,
    setEntityGroupOpen,
    newStakeHolders,
    setNewStakeholders,
    allStakeholdersInCampaign,
    setAllStakeholdersInCampaign
}) => {
    const stakeholdersByClient = dropdownValues.stakeholdersByClient
        ? dropdownValues.stakeholdersByClient.filter((sc: any) =>
        sc.status !== 'ARCHIVED') : [] as Array<SelectedStakeholder>;
        stakeholdersByClient.sort(dynamicSort('firstName'));
    const [stakeholderErrorMessage, setStakeholderErrorMessage] = useState<string>('');
    const [ownerErrorMessage, setOwnerErrorMessage] = useState<string>('');
    const [entityName, setEntityName] = useState<string | undefined>();
    const [entityNameError, setEntityNameError] = useState<boolean>(false);
    const [entityKeyStakeHolder, setKeyHandleBoolean] = useState<boolean>(false);
    const [entityOtherStakeHolder, setOtherStakeBoolean] = useState<boolean>(false);
    const [falseEntity, setFalseEntity] = useState<boolean>(false);

    const handleKeyPress = (e: any) => {
        setKeyHandleBoolean(true);
    };

    const handleKeyPressOtherStake = (e: any) => {
        setOtherStakeBoolean(true);
    };

    const onKeyfalse = (e: any) => {
        setFalseEntity(false);
    };

    const validateNewStakeholder = (value: any, who: string) => {
        let error = '';
        const lastName = value.split(/ (.+)/)[1];
         if (!lastName) {
          error = ' Please add last name using space';
        } else {
            const doesHeExist = stakeholdersByClient.filter((st: any) => {
                const fullName = `${st.firstName} ${st.lastName}`;
                return fullName === value;
            });
            if(doesHeExist && doesHeExist.length > 0){
                error = 'This stakeholder name already exists, please add a different name';
            }
        }
        if(who === 'owner'){
            setOwnerErrorMessage(error);
        }else if('stakeholder'){
            setStakeholderErrorMessage(error);
        }
        if(error){
            return false;
        }

        return true;
    };

    const handleClose = () => {
        setEntityName(undefined);
        setEntityNameError(false);
        setEntityGroupOpen(false);
        setSelectedEntityGroup(null);
    };

    const handleName = (name: string | undefined): void => {
        const entityNames = selectedEntityGroups.map(group => group.name.toLowerCase());

        setEntityNameError(false);
        if (name && entityNames.includes(name.toLowerCase())) {
            setEntityNameError(true);
        }
        setEntityName(name);
    };

    const [inputValue, setInputValue] = useState('');

    const initialValues = entityData
        ? {
              name: entityData.name,
              entityTypeId: entityData.entityTypeId,
              ownerId: entityData.ownerId,
              stakeholderIds: entityData.stakeholderIds
          }
        : { name: '', entityTypeId: 0, ownerId: null, stakeholderIds: [] };

    return (

        <Modal
            isOpen={entityGroupOpen}
            contentLabel="add parent entity"
            ariaHideApp={false}
            style={customStyles}
            onRequestClose={handleClose}>
            <Formik
                initialValues={initialValues}
                validationSchema={EntityGroupSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {

                    if (entityNameError) {
                        setEntityNameError(true);
                        return;
                    }
                    if (entityData) {
                        const result = {
                            ...entityData,
                            name: values.name,
                            ownerId: values.ownerId,
                            entityTypeId: values.entityTypeId,
                            stakeholderIds: values.stakeholderIds
                        };

                        setSelectedEntityGroups(
                            selectedEntityGroups.map((entityGroup: any) => {
                                if (
                                    entityGroup.editKey === entityData.editKey
                                ) {
                                    return result;
                                }
                                return entityGroup;
                            })
                        );
                        setSelectedEntityGroup(null);
                    } else if (!entityData) {
                        setSelectedEntityGroups(
                            selectedEntityGroups.concat([
                                // tslint:disable-next-line: no-object-literal-type-assertion
                                {
                                    ...values,
                                    editKey: uuid(),
                                    campaignEntities: []
                                } as CampaignEntityGroup
                            ])
                        );
                    }
                    setSelectedEntityGroup(null);
                    setSubmitting(false);
                    setEntityGroupOpen(false);
                    resetForm({values:{
                        name: '',
                        entityTypeId: 0,
                        ownerId: '',
                        stakeholderIds: []
                    }});
                }}>
                {({
                    submitForm,
                    isSubmitting,
                    values,
                    setFieldValue,
                    errors,
                    isValid,
                    dirty
                }) => (
                    <Form onLoad={() => {setEntityName(values.name);}}>
                        <HeadingWrapper>
                            <HeadingText>
                                {entityData ? 'Edit parent' : 'Add parent'}
                            </HeadingText>
                            <Button
                                style={{border: 'none'}}
                                onClick={() => handleClose()}
                            >
                                <img src={greyCloseIcon} alt="close" />
                            </Button>
                        </HeadingWrapper>
                        <Flex>
                            <ColumnWrapper>
                                <Label>Parent title</Label>
                                {errors.name &&
                                    <ErrorText>
                                       {errors.name.toString()}
                                    </ErrorText>
                                }
                                <CustomTextInput
                                    error={entityNameError}
                                    name="name"
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    autoComplete="off"
                                    type="text"
                                    placeholder={'Add title'}
                                    value={entityName}
                                    onChange={(e: React.ChangeEvent<unknown>) => {
                                        const { value } = e.target as HTMLTextAreaElement;
                                        setFieldValue('name', value);
                                        handleName(value);
                                    }}
                                />
                                {entityNameError && (
                                    <ErrorText>
                                        This entity name is already exists, or input field is empty. Please enter valid name
                                    </ErrorText>
                                )}
                                 <CustomDiv>
                                    <Label>
                                        Key stakeholder (Optional)
                                        <Tooltip
                                            title={<>
                                                <Typography color="inherit">To add a new key stakeholder:</Typography>
                                                <Typography color="inherit">1. Type their first and last name - e.g. Joe Bloggs</Typography>
                                                <Typography color="inherit">2. Press 'Enter' on your keyboard to add the stakeholder</Typography>
                                                </>}
                                            placement="top"
                                            >
                                            <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                <InfoIcon style={{fontSize: '12px'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Label>

                                    {entityKeyStakeHolder &&
                                        <div>
                                            <Text style={{color: '#de3328'}}> Press "Enter Key" to validate Key Stakeholder </Text>
                                        </div>
                                    }

                                    <Field
                                        variant="outlined"
                                        name="ownerId"
                                        style={{
                                            minHeight: 39
                                        }}
                                        as={Autocomplete}
                                        noOptionsText="No available owner"
                                        options={stakeholdersByClient}
                                        getOptionLabel={(option: SelectedStakeholder | string) => {
                                            if(typeof option ==='string'){
                                                const firstName = option.split(/ (.+)/)[0];
                                                const lastName = option.split(/ (.+)/)[1];
                                                if(lastName){
                                                    return `${firstName} ${lastName}`;
                                                }
                                                return `${firstName}`;
                                            }
                                            return `${option.firstName} ${option.lastName}`;
                                        }}
                                        value={values.ownerId}
                                        getOptionValue={(option: SelectedStakeholder) => {
                                            return option.value;
                                        }}
                                        onKeyPress={(e: any) => {
                                            if (e.charCode != 13) {
                                                handleKeyPress(e);
                                            }
                                            else{
                                                onKeyfalse(e);
                                            }
                                        }}
                                        freeSolo
                                        onChange={(
                                            _e: React.ChangeEvent,
                                            value: SelectedStakeholder
                                        ) => {

                                            // setKeyHandleBoolean(false);
                                            if(value){
                                                if(typeof value ==='string'){
                                                    if(validateNewStakeholder(value,'owner')){
                                                        if(!newStakeHolders.includes(value)){
                                                            setNewStakeholders([...newStakeHolders, value]);
                                                        }
                                                        setAllStakeholdersInCampaign([...allStakeholdersInCampaign,value]);
                                                        setFieldValue(
                                                            'ownerId',
                                                            value
                                                        );
                                                    }
                                                     setKeyHandleBoolean(false);
                                                } else {
                                                    if(!values.stakeholderIds.includes(value.value)){
                                                        setOwnerErrorMessage('');
                                                        setFieldValue(
                                                            'ownerId',
                                                            value.value
                                                        );
                                                    }
                                                }

                                            } else {
                                                setFieldValue(
                                                    'ownerId',
                                                    value
                                                );
                                            }
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event: any, newInputValue: string) => {
                                            const existingUser = stakeholdersByClient.find((sc: SelectedStakeholder) =>
                                                sc.value.toString() === newInputValue
                                            );
                                            if (existingUser) {
                                                setInputValue(`${existingUser.firstName} ${existingUser.lastName}`);
                                            } else {
                                                setInputValue(newInputValue);
                                            }
                                        }}
                                        renderInput={(params: object) => (
                                            <Field
                                                as={
                                                    MaterialUITextField
                                                }
                                                {...params}
                                                name="ownerId"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                label="Add key stakeholder"
                                                onChange={(val: any) => {
                                                    return val;
                                                }}
                                            />
                                        )}
                                    />
                                    <div style={{padding: '0px 2px 10px 10px'}}>

                                        {!!ownerErrorMessage &&(
                                            <Text style={{color: '#de3328'}}>
                                                {ownerErrorMessage}
                                            </Text>
                                        )}
                                    </div>
                                </CustomDiv>
                                <CustomDiv>
                                {(() => {
                                    const existingUser = stakeholdersByClient.find((sc: SelectedStakeholder) =>
                                        sc.value.toString() === values.ownerId
                                    );
                                    if(existingUser){
                                        return(
                                            <CustomChip key={existingUser.value}>
                                                <CustomChipContent>
                                                    {`${existingUser.firstName} ${existingUser.lastName}`}
                                                </CustomChipContent>
                                                <CloseIcon
                                                    src={closeIcon}
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'ownerId',
                                                            null
                                                        );
                                                    }}
                                                />
                                            </CustomChip>
                                        );
                                    } else if(values.ownerId) {
                                        const firstName = values.ownerId.split(/ (.+)/)[0];
                                        const lastName = values.ownerId.split(/ (.+)/)[1];
                                        if(firstName&&lastName) {
                                            return (
                                                <CustomChip>
                                                    <CustomChipContent>
                                                        {`${firstName} ${lastName}`}
                                                    </CustomChipContent>
                                                    <CloseIcon
                                                        src={closeIcon}
                                                        onClick={() => {
                                                            const position = allStakeholdersInCampaign.indexOf(values.ownerId);
                                                            const newArr = [...allStakeholdersInCampaign];
                                                            newArr.splice(position, 1);
                                                            setAllStakeholdersInCampaign(newArr);
                                                            setFieldValue(
                                                                'ownerId',
                                                                null
                                                            );
                                                        }}
                                                    />
                                                </CustomChip>
                                            );
                                        }
                                    }
                                })()}
                                </CustomDiv>
                                <CustomDiv>
                                    <Label>
                                        Other stakeholders (Optional)
                                        <Tooltip
                                            title={<>
                                                <Typography color="inherit">To add a new other stakeholder:</Typography>
                                                <Typography color="inherit">1. Type their first and last name - e.g. Joe Bloggs</Typography>
                                                <Typography color="inherit">2. Press 'Enter' on your keyboard to add the stakeholder</Typography>
                                                </>}
                                            placement="top"
                                        >
                                            <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                                <InfoIcon style={{fontSize: '12px'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Label>

                                    {entityOtherStakeHolder &&
                                        <div>
                                            <Text style={{color: '#de3328'}}> Press "Enter Key" to validate Key Stakeholder </Text>
                                        </div>
                                    }

                                    <Field
                                        variant="outlined"
                                        name="stakeholderIds"
                                        style={{
                                            marginBottom: 2,
                                            minHeight: 39
                                        }}
                                        as={Autocomplete}
                                        noOptionsText="No available stakeholders"
                                        multiple
                                        options={stakeholdersByClient}
                                        getOptionLabel={(option: SelectedStakeholder | string) => {
                                            if(typeof option === 'string'){
                                                const firstName = option.split(/ (.+)/)[0];
                                                const lastName = option.split(/ (.+)/)[1];
                                                if(lastName){
                                                    return `${firstName} ${lastName}`;
                                                }
                                                return `${firstName}`;
                                            }
                                            return `${option.firstName} ${option.lastName}`;
                                        }}
                                        getOptionValue={(option: SelectedStakeholder) => {
                                            return option.value;
                                        }}
                                        disableCloseOnSelect
                                        value={values.stakeholderIds}
                                        onKeyPress={(e: any) => {
                                            if (e.charCode != 13) {
                                                handleKeyPressOtherStake(e);
                                            }
                                            else{
                                                onKeyfalse(e);
                                            }
                                            }}
                                        freeSolo
                                        onChange={(
                                            _e: React.ChangeEvent,
                                            value: SelectedStakeholder[]
                                        ) => {
                                            setOtherStakeBoolean(false);
                                            const lastInput = value[value.length-1];
                                            if(lastInput){
                                                if(typeof lastInput ==='string'){
                                                    if(validateNewStakeholder(lastInput,'stakeholder')){
                                                        if(!newStakeHolders.includes(lastInput)){
                                                            setNewStakeholders([...newStakeHolders, lastInput]);
                                                        }
                                                        setAllStakeholdersInCampaign([...allStakeholdersInCampaign,lastInput]);
                                                        setFieldValue(
                                                            'stakeholderIds',
                                                            value
                                                        );
                                                    }
                                                } else {
                                                    if(!values.stakeholderIds.includes(lastInput.value)){
                                                        setStakeholderErrorMessage('');
                                                        value.pop();
                                                        const newValues = [...value, lastInput.value];
                                                        setFieldValue(
                                                            'stakeholderIds',
                                                            newValues
                                                        );
                                                    }
                                                }
                                            } else {
                                                setFieldValue(
                                                    'stakeholderIds',
                                                    value
                                                );
                                            }
                                        }}
                                        renderInput={(params: object) => (
                                            <Field
                                                as={
                                                    MaterialUITextField
                                                }
                                                {...params}
                                                name="stakeholderIds"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                label="Add other stakeholders"
                                                onChange={(val: any) => {
                                                    return val;
                                                }}
                                            />
                                        )}
                                        renderTags={(selected: any) => {
                                            return (
                                                selected.map((val: string, index: number) => {
                                                    const existingUser = stakeholdersByClient.find((s: SelectedStakeholder) => s.value.toString() === val);
                                                    return (existingUser ?
                                                        <Chip
                                                            key={`multi-seleted-chip-${index}`}
                                                            style={{
                                                                margin: '0px 2px'
                                                            }}
                                                            label={`${existingUser.firstName} ${existingUser.lastName}`}
                                                        /> :
                                                        <Chip
                                                            key={`multi-seleted-chip-${index}`}
                                                            style={{
                                                                margin: '2px'
                                                            }}
                                                            label={`${val}`}
                                                        />
                                                    );
                                                })
                                            );
                                        }}
                                    />
                                    <div style={{padding: '0px 2px 10px 10px'}}>
                                        {!!stakeholderErrorMessage &&(
                                            <Text style={{color: '#de3328'}}>
                                                {stakeholderErrorMessage}
                                            </Text>
                                        )}
                                    </div>
                                </CustomDiv>
                            </ColumnWrapper>
                            <ColumnWrapper>
                                <Label>Type</Label>
                                <Field
                                    variant="outlined"
                                    name="entityTypeId"
                                    type="text"
                                    select
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    as={TextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    validate={()=>{
                                        if(values.entityTypeId>0 && values.name!='') {
                                            state.isSubmitting = false;
                                        }
                                        else {
                                            state.isSubmitting = true;
                                        }
                                    }}
                                    aria-label='parent entity type'
                                    >
                                    <MenuItem value={0} disabled>
                                        Select type
                                    </MenuItem>
                                        {dropdownValues &&
                                            dropdownValues.entityTypes &&
                                            dropdownValues.entityTypes.slice().sort(dynamicSort('label')).map(
                                                (option: DropDownEntityType) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            )}
                                </Field>
                            </ColumnWrapper>
                        </Flex>
                        <CustomDiv style={{padding: '0px 50px 0 50px'}}>
                            {
                                values.stakeholderIds.map((sh: string, index: number) => {
                                    const existingUser = stakeholdersByClient.find((sc: SelectedStakeholder) =>
                                        sc.value.toString() === sh
                                    );
                                    if(existingUser){
                                        return(
                                            <CustomChip key={existingUser.value}>
                                                <CustomChipContent>
                                                    {`${existingUser.firstName} ${existingUser.lastName}`}
                                                </CustomChipContent>
                                                <CloseIcon
                                                    src={closeIcon}
                                                    onClick={() => {
                                                        const ids = values.stakeholderIds.filter((id: number) => id !== existingUser.value);
                                                        setFieldValue(
                                                            'stakeholderIds',
                                                            ids
                                                        );
                                                    }}
                                                />
                                            </CustomChip>
                                        );
                                    } else {
                                        const firstName = sh.split(/ (.+)/)[0];
                                        const lastName = sh.split(/ (.+)/)[1];
                                        if(firstName&&lastName) {
                                            return (
                                                <CustomChip
                                                    key={index}
                                                >
                                                    <CustomChipContent>
                                                        {`${firstName} ${lastName}`}
                                                    </CustomChipContent>
                                                    <CloseIcon
                                                        src={closeIcon}
                                                        onClick={() => {
                                                            const ids = values.stakeholderIds
                                                                .filter((id: number) => id.toString() !== sh);
                                                            const position = allStakeholdersInCampaign.indexOf(sh);
                                                            const newArr = [...allStakeholdersInCampaign];

                                                            newArr.splice(position, 1);
                                                            setAllStakeholdersInCampaign(newArr);
                                                            setFieldValue(
                                                                'stakeholderIds',
                                                                ids
                                                            );
                                                        }}
                                                    />
                                                </CustomChip>
                                            );
                                        }
                                    }
                                })
                            }
                        </CustomDiv>
                        <SubmitWrapper>

                            {isSubmitting && <LinearProgress />}
                            <RedButton
                                style={{ padding: '12px 60px' }}
                                disabled={(!(isValid && dirty) || isSubmitting)}
                            onMouseDown={() => setEntityNameError(!values.name || entityNameError)}
                                onClick={submitForm}
                            >
                                Save
                            </RedButton>
                        </SubmitWrapper>
                    </Form>
                )}
            </Formik>
        </Modal>

    );
};

const StakeholderCustomField = styled(CustomField)`
    .MuiAutocomplete-tag{
        display:none
    }
`;

const CustomTextInput = styled(CustomText)`
    && {
        width: 100%
        margin: 8px 0px 8px 0px;
        border-radius: 4px;
        outline: #575757;
    }
`;

const HeadingWrapper = styled(AlignItems)`
    justify-content: space-between;
    padding: 22px 50px;
    border-bottom: solid 1px #d9d9d9;
    margin-bottom: 40px;
`;

const HeadingText = styled(BoldText)`
    font-size: 20px;
`;

const ColumnWrapper = styled(Column)`
    width: 50%;
    padding: 0 50px;
`;

const SubmitWrapper = styled(JustifyContentEnd)`
    margin: 40px 24px 24px 0;
`;

const Label = styled(Text)`
    color: #575757;
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
`;

const CustomDiv = styled('div')`
    position: relative;
`;

const CustomChip = styled('span')`
    width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: #eeeeee;
    border: 1 solid black;
    border-radius: 50px;
    padding: 0 16px;
    margin-right: 4px;
    margin-bottom: 4px;

    &:hover {
        img {
            opacity: 1;
        }
    }
`;

const CustomChipContent = styled(Flex)`
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 8px 0;
    justify-content: space-between;
    align-items: center;
    color: #212121;
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 500;
`;

const CloseIcon = styled('img')`
    cursor: pointer;
    width: 16px;
    margin-left: 8px;
    opacity: 0;
    transition: 0.4s;
`;

const customStyles = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        bottom: 'auto',
        padding: 0,
        right: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        '&::after': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            background: 'black',
            left: 0,
            right: 0,
            top: 0,
            height: '100%',
            opacity: 0.5
        }
    },
    overlay: {
        backgroundColor: '#d9d9d9'
    }
};

export default EntityGroupForm;
