import React, { useContext, useEffect, useState, useRef } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Modal from 'react-modal';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from '@mui/material';
import {
    SpaceBetween,
    Text,
    BoldText,
    Column,
    JustifyContentCenter,
    RedButton,
    Flex,
    DateRangeSelect,
    Breadcrumb,
    AlignItems,
    Toast,
    Row,
    PopUpHeader,
    Button,
    SpinnerWithOverlay
} from '../../../components';
import { styled } from '@mui/material/styles';
import {
    ListItemText,
    MenuItem,
    Typography
} from '@mui/material';
import { createBrowserHistory } from 'history';
import iconDownload from '../../../assets/images/icon-download.svg';
import { CloudUpload } from '@mui/icons-material';
import emptyStateIcon from '../../../assets/images/image-benchamrking-empty-state.svg';
import icon_peer from '../../../assets/images/icon_peer.svg';
import icon_alert_outline from '../../../assets/images/icon_alert_outline_darkGrey.svg';
import { UserStore } from '../../../context/user-store';
import {
    renderModal,
    SET_BENHCMARKING_FILTERS,
    GET_BENHCMARKING_FILTERS,
    mapData,
    assessmentMap,
    CAMPAIGNS,
    BENHCMARKING_DATA,
    CAMPAIGN_DATA,
    createYearsFromToArray
} from './BenchmarkingHelpers';
import PopoverRegionMenu from './components/Menu/PopoverRegionMenu';
import {
    BenchmarkingData,
    CalculateClusteredScores,
    BenchmarkingGraphRef,
    MenuProps,
    CampaignDropdownOption,
    AssessmentDropdownOption, Weightedlevel, DifferentWeightDistribution, BenchmarkingDataExport
} from './types';
import BenchmarkingGraph from './BenchmarkingGraph';
import {
    DropdownSingleSelection,
    DropdownMultipleSelection
} from '../../../components';
import CustomMenu from '../../../components/Menu';
import {
    isReadOnlyEnabled,
    isPointOneDecimalEnable,
    generalWeightFlagMessage,
    weightFlagDomainMessage,
    isImportBenchmarkingEnable,
    minimunBenchmarkingPeers
} from '../../../utils';
import { customModalStyles } from '../../../components/PopUps/constants';
import { BenchmarkingInfoModal } from './BenchmarkingInfoModal';
import DropzoneUpload from '../../../utils/Dropzone';

const Benchmarking: React.FC = () => {
    const {
        state: { client, globalSettings, user }
    } = useContext(UserStore);
    const history = createBrowserHistory();
    const state = history.location.state as any;

    const campaignId = state? state.campaignId : '';

    const { data, loading, error } = useQuery<BenchmarkingData>(CAMPAIGNS, {
        variables: {
            clientId: (client && client.id) || 1,
            supportsBenchmarking:true
        },
        fetchPolicy: 'no-cache'
    });


    useEffect(() => {
        if(data && campaignId) {
            setGetFiltersData(true);
        }
    }, [data]);

    const [isOpenBenchmarkingInfo, setIsOpenBenchmarkingInfo] = useState(false);

    const handleClickOpenBenchmarkingInfoModal = () => {
        setIsOpenBenchmarkingInfo(true);
    };

    const [getBenchmarkingData] = useLazyQuery(BENHCMARKING_DATA, {
        onCompleted: res => {
            const {
                totalCount,
                clusteredCapabilities,
                clusteredDomains,
                rows
            } = res.benchmarkingData;
            setGraphData([{ clusteredCapabilities, clusteredDomains }]);
            setExportData(rows);
            setDifferentWightData(rows&&rows.filter((rw: DifferentWeightDistribution)=>{
                if(rw.differentWeightDistribution){
                    return rw.differentWeightDistribution;
                }
            }).map((dt: DifferentWeightDistribution)=>dt.differentWeightDistribution));
            setShowGraphSpin(false);
            setPeersAvailableCount(totalCount);
            if (totalCount < minimunBenchmarkingPeers) {
                setModalProps({
                    modalId: 'peerGroupSmall',
                    isOpen: true
                });
            }
        },
        onError: err => {
            setShowGraphSpin(false);
            alert('Something Went Wrong');
            console.log(err);
        }
    });

    const [sendFilters] = useLazyQuery(SET_BENHCMARKING_FILTERS, {
        onCompleted: () => {
            setSuccessToastOpen(true);
        },
        onError: () => {
            setModalProps({
                modalId: 'peerGroupSaveError',
                isOpen: true
            });
        }
    });

    const assessmentTypeData =
        data && assessmentMap(data.campaignAssessmentTypes);
    const [peersAvailableCount, setPeersAvailableCount] = useState(0);
    const [selectedCampaigns, setSelectedCampaigns] = useState<MenuProps>({
        id: '',
        name: ''
    });
    const [selectedCampaignId, setSelectedCampaignId] = useState<string>(campaignId);
    const [listOfCampaignOptions, setListOfCampaignOptions] = useState<
        CampaignDropdownOption[]
    >([]);
    const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
    const [selectedIndustrys, setSelectedIndustrys] = useState<
        AssessmentDropdownOption[]
    >([]);
    const [selectedCompanys, setSelectedCompanys] = useState<
        AssessmentDropdownOption[]
    >([]);
    const [selectedAssessments, setSelectedAssessments] = useState<
        AssessmentDropdownOption[]
    >([]);
    const [selectedRevenues, setSelectedRevenues] = useState<
        AssessmentDropdownOption[]
    >([]);
    const [selectedEmployees, setSelectedEmployees] = useState<
        AssessmentDropdownOption[]
    >([]);
    const [selectedDatesRange, setSelectedDatesRange] = useState(null);

    const [isCampaignMenuOpen, setCampaignMenuOpen] = useState<boolean>(false);
    const [isRegionMenuOpen, setRegionMenuOpen] = useState<boolean>(false);
    const [isIndustryMenuOpen, setIndustryMenuOpen] = useState<boolean>(false);
    const [isCompanyMenuOpen, setCompanyMenuOpen] = useState<boolean>(false);

    const [isAssessmentMenuOpen, setAssessmentMenuOpen] = useState<boolean>(
        false
    );
    const [isRevenueMenuOpen, setRevenueMenuOpen] = useState<boolean>(false);
    const [isEmployeesMenuOpen, setEmployeesMenuOpen] = useState<boolean>(
        false
    );
    const [dateRangeSelectOpen, setDateRangeSelectOpen] = useState(false);
    const [modalprops, setModalProps] = useState({
        modalId: '',
        isOpen: false
    });

    const [successToastOpen, setSuccessToastOpen] = useState(false);
    const [showGraphSpin, setShowGraphSpin] = useState(false);
    const [graphData, setGraphData] = useState<CalculateClusteredScores[]>([]);
    const [exportData, setExportData] = useState<BenchmarkingDataExport[]>([]);
    const [differentWeightData, setDifferentWightData] = useState<Weightedlevel[]|undefined>();
    const [getCampaignData, { data: currentCampaignData }] = useLazyQuery(
        CAMPAIGN_DATA,
        {
            variables: { campaignId: selectedCampaignId }
        }
    );
    const [getFiltersData, setGetFiltersData] = useState(() => {
        return campaignId? true: false;
    });
    const [getFilters] = useLazyQuery(GET_BENHCMARKING_FILTERS, {
        onCompleted: res => {
            if (res.benchmarkingFilters) {
                const {
                    country,
                    industry,
                    clientType,
                    revenue,
                    numberOfEmployees,
                    assessmentType,
                    datesRange
                } = res.benchmarkingFilters;

                if (
                    data &&
                    data.industries &&
                    data.clientTypes &&
                    data.annualRevenues &&
                    data.employeesNumbers
                ) {
                    setSelectedRegions(country ? country : []);
                    const industryVar = data.industries.filter(el => {
                        if (
                            industry &&
                            industry.some((ind: string) => ind === el.name)
                        ) {
                            return el;
                        }
                    });
                    setSelectedIndustrys(
                        industryVar
                            ? (industryVar as AssessmentDropdownOption[])
                            : []
                    );

                    const clientTypeVar = data.clientTypes.filter(el => {
                        if (
                            clientType &&
                            clientType.some((ind: string) => ind === el.name)
                        ) {
                            return el;
                        }
                    });
                    setSelectedCompanys(
                        clientTypeVar
                            ? (clientTypeVar as AssessmentDropdownOption[])
                            : []
                    );

                    const revenueVar = data.annualRevenues.filter(el => {
                        if (
                            revenue &&
                            revenue.some((ind: string) => ind === el.name)
                        ) {
                            return el;
                        }
                    });
                    setSelectedRevenues(
                        revenueVar
                            ? (revenueVar as AssessmentDropdownOption[])
                            : []
                    );

                    const numberOfEmployeesVar = data.employeesNumbers.filter(
                        el => {
                            if (
                                numberOfEmployees &&
                                numberOfEmployees.some(
                                    (ind: string) => ind === el.name
                                )
                            ) {
                                return el;
                            }
                        }
                    );
                    setSelectedEmployees(
                        numberOfEmployeesVar
                            ? (numberOfEmployeesVar as AssessmentDropdownOption[])
                            : []
                    );

                    setSelectedAssessments(
                        assessmentType
                            ? assessmentType.map((elMap: string) => {
                                  const entries = Object.entries(mapData).find(
                                      (elFind: string[]) => elFind[0] === elMap
                                  );
                                  return entries
                                      ? {
                                            id: entries[0],
                                            name: entries[1]
                                        }
                                      : {
                                            id: 'Client self-assessment',
                                            name: 'Client self-assessment'
                                        };
                              })
                            : []
                    );
                    if (datesRange) {
                        const newDatesRange= { ...datesRange};
                        delete newDatesRange.__typename;
                        if( newDatesRange.from){
                            const delObject ={...newDatesRange.from};
                            delete delObject.__typename;
                            newDatesRange.from = delObject;
                        }
                        if( newDatesRange.to){
                            const delObject ={...newDatesRange.to};
                            delete delObject.__typename;
                            newDatesRange.to = delObject;
                        }
                       setSelectedDatesRange(newDatesRange);
                    }

                }
            } else {
                setSelectedRegions([]);
                setSelectedIndustrys([]);
                setSelectedCompanys([]);
                setSelectedRevenues([]);
                setSelectedEmployees([]);
                setSelectedAssessments([]);
                setSelectedDatesRange(null);
                setGraphData([]);
                setPeersAvailableCount(0);
            }
        },
        onError: err => {
            setShowGraphSpin(false);
            alert('Something Went Wrong');
            console.log(err);
        }
    });

    useEffect(() => {
        if (getFiltersData) {
            getFilters({
                variables: { campaignId: selectedCampaignId }
            });
            setGetFiltersData(false);
        }
    }, [getFiltersData]);

    const [benchmarkingBreadcrumbs, setBenchmarkingBreadcrumbs] = useState([
        {
            name: 'Evaluate',
            path: '/main/evaluate/campaigns'
        },
        {
            name: `${client ? client.name : ''} - ${state &&
                state.campaignTitle}`,
            path: `/main/evaluate/maturity/${state && state.campaignId}`
        },
        {
            name: 'Benchmarking'
        }
    ]);

    useEffect(() => {
        if (state && state.assessmentId) {
            benchmarkingBreadcrumbs.splice(2, 0, {
                name: `${state.assessmentTitle}`,
                path: `/main/evaluate/maturity/${state.campaignId}/assessments/${state.assessmentId}`
            });
            setBenchmarkingBreadcrumbs(benchmarkingBreadcrumbs);
        }
    }, [state && state.assessmentId]);



    /**
     * @todo implement BenchMarking Readonly... needed this as ree-usable capabilitiesAverage now has two extra vars
     */
    const isReadOnly = false;

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    const globalPointOneDecimalEnable = isPointOneDecimalEnable(globalSettings);
    const importBenchmarkingEnable = isImportBenchmarkingEnable(globalSettings);
    const [pointOneDecimalEnable, setPointOneDecimalEnable] = useState<boolean>(false);


    const requestVar = () => {
        return {
            filters: {
                country: selectedRegions.length ? selectedRegions : null,
                industry: selectedIndustrys.length
                    ? selectedIndustrys.map(el => el.name)
                    : null,
                clientType: selectedCompanys.length
                    ? selectedCompanys.map(el => el.name)
                    : null,
                revenue: selectedRevenues.length
                    ? selectedRevenues.map(el => el.name)
                    : null,
                numberOfEmployees: selectedEmployees.length
                    ? selectedEmployees.map(el => el.name)
                    : null,
                assessmentType: selectedAssessments.length
                    ? selectedAssessments.map(
                          (elMap: AssessmentDropdownOption) => {
                              const entries = Object.entries(mapData).find(
                                  (elFind: string[]) => elFind[1] === elMap.name
                              );
                              return entries ? entries[0] : null;
                          }
                      )
                    : null,
                datesRange: selectedDatesRange
            },
            campaignId: selectedCampaignId
        };
    };

    useEffect(() => {
        if (
            !data ||
            !selectedCampaigns.name.length ||
            !selectedRegions.length ||
            !selectedIndustrys.length ||
            !selectedCompanys.length ||
            !selectedAssessments.length ||
            !selectedRevenues.length ||
            !selectedEmployees.length ||
            !selectedDatesRange
        ) {
            return undefined;
        }
        setShowGraphSpin(true);
        getCampaignData();
        getBenchmarkingData({
            variables: requestVar()
        });
    }, [
        selectedRegions,
        selectedIndustrys,
        selectedCompanys,
        selectedAssessments,
        selectedRevenues,
        selectedEmployees,
        selectedDatesRange,
        getBenchmarkingData,
        data
    ]);

    useEffect(() => {
        if (data && data.availableCampaignsByClient) {
            const currentListOfCampaignOptions = data.availableCampaignsByClient
                .filter(el => el.status !== 'CLOSED' && ((client.owner?.id === user.id)  || (el.manager.id == user.id) || (el.readOnly !== true || el.isCampaignEntityUser !== false))) //need to verify the logic for the filter
                .map(el => {
                    // const label = (globalPointOneDecimalEnable && globalPointOneDecimalEnable === true) &&
                    //     (el.pointOneDecimalEnable && el.pointOneDecimalEnable === true) ? ' | Decimal One Enabled' : '';
                    return {
                        id: el.id,
                        label: el.name,
                        status: el.isCampaignEntityUser? 'CAMPAIGN_ENTITY_USER' : el!.status
                        // label: el.name + label
                    };
                });
            setListOfCampaignOptions(currentListOfCampaignOptions);
        }
    }, [data && data.availableCampaignsByClient]);

    useEffect(() => {
        if (selectedCampaignId && data && data.availableCampaignsByClient) {
            const currSelectedCampaign = data.availableCampaignsByClient.find(
                el => el.id === selectedCampaignId
            );
            currSelectedCampaign && setSelectedCampaigns(currSelectedCampaign);
            setPointOneDecimalEnable(
                ((globalPointOneDecimalEnable && globalPointOneDecimalEnable === true) &&
                (currSelectedCampaign && currSelectedCampaign.pointOneDecimalEnable && currSelectedCampaign.pointOneDecimalEnable === true)) ||false
            );
        }
    }, [selectedCampaignId]);

    const disableSaveButton = () => {
        if (!selectedRegions.length &&
            !selectedIndustrys.length &&
            !selectedCompanys.length &&
            !selectedAssessments.length &&
            !selectedRevenues.length &&
            !selectedEmployees.length &&
            !selectedDatesRange
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (state && state.campaignId) {
            const campaignId: string = state.campaignTitle;
            setSelectedCampaigns({ id: '', name: campaignId });
        }
    }, [state]);
    const ref = useRef<BenchmarkingGraphRef>(null);
    const exportImage = (isDrive: boolean) => {
        if (graphData.length > 0) {
            ref && ref.current && ref.current.export(isDrive);
        }
    };

    const sortOnKeys = (dict: any) =>
        Object.fromEntries(
            Object.keys(dict)
                .map((key) => [key, dict[key]])
                .sort()
        );


    const exportCsv = () => {
        const headings = Object.keys(exportData[0]).slice(0,-1);
        const csvArr = [];
        csvArr.push(headings);
        exportData.forEach((campaign) => {
            campaign.componentsScore = campaign.componentsScore && JSON.stringify(sortOnKeys(JSON.parse(campaign.componentsScore)));
            csvArr.push(headings.map((heading) => {
                const item = campaign[heading as keyof BenchmarkingDataExport];
                if (heading.includes('Score')) return '"' + String(item).replace(/"/g, '""') + '"';
                if (heading === 'campaignClosureDate') return new Date(campaign.campaignClosureDate).toISOString();
                if (heading === 'differentWeightDistribution') return '"' + JSON.stringify(campaign.differentWeightDistribution).replace(/"/g, '""') + '"';
                return item;
            }).join(','));
        });

        const csvContent = 'data:text/csv;charset=utf-8,' + csvArr.join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'benchmarking_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [files, setFiles] = useState<any[]>([]);
    const [errorUpload ,setErrorUpload] = useState<string>('');

    const handleImportModalToggle = () => {
        if (importDialogOpen) {
            setImportDialogOpen(false);
            setFiles([]);
            setErrorUpload('');
        } else {
            setImportDialogOpen(true);
            setFiles([]);
            setErrorUpload('');
        }
    };

    const uploadFilesToBenchmarking = () => {
        if (files.length > 0) {
            const tkn = localStorage.getItem('pid-token');
            const clientLocal = localStorage.getItem('client');
            let clientUUID = null;
            const bodyFormData = new FormData();
            bodyFormData.append('benchmarkingData', files[0]);
            if (clientLocal) {
                const clientParsed = JSON.parse(clientLocal);
                clientUUID = clientParsed.id;

                // calling upload API
                axios.post(window.__ENV__.REACT_APP_APP_URL + '/campaign/benchmarking/upload', bodyFormData, {
                    headers: {
                        'pearl-client-uuid': clientUUID,
                        'Authorization': `Bearer ${tkn}`,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    setImportDialogOpen(false);
                    setFiles([]);
                    setErrorUpload('');
                })
                .catch((error) => {
                    setErrorUpload(error.message);
                });
            }
        } else {
            setErrorUpload('No Files Attached.');
        }
    };

    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

    const selectPlaceholder = (
        option: string[],
        currentData: any,
        label: string,
        dropdownOption?: AssessmentDropdownOption[]
    ) => {
        const getName = (currentId: string) => {
            if (label !== 'region/country') {
                const currentLabelLocal = currentData.find(
                    (elem: any) =>
                        elem.name === currentId || elem.id === currentId
                );
                return currentLabelLocal ? currentLabelLocal.name : '';
            }
            const currentLabel = currentData.find(
                (elem: any) => elem.country === currentId
            );
            return currentLabel ? currentLabel.country : '';
        };
        if (dropdownOption) {
            return dropdownOption.length
                ? dropdownOption.length > 1
                    ? `${dropdownOption.length} selected`
                    : getName(dropdownOption[0].name)
                : `Select ${label}`;
        }
        return option.length
            ? option.length > 1
                ? `${option.length} selected`
                : getName(option[0])
            : `Select ${label}`;
    };

    const yearFrom =
        data && new Date(data.benchmarkingDatesRange.from).getFullYear();
    const yearTo =
        data && new Date(data.benchmarkingDatesRange.to).getFullYear();

    const weightedLevelMessage=(differentWeight: Weightedlevel[]|undefined)=> {
        const isGeneralWarning = differentWeight && differentWeight.find((weight: Weightedlevel) => weight.capabilityLevel ||
            weight.objectiveLevel
        );
        const isDomainWarning = differentWeight && differentWeight.find((weight: Weightedlevel) => weight.domainLevel &&
        !weight.capabilityLevel && !weight.objectiveLevel
        );

        if(!(peersAvailableCount < minimunBenchmarkingPeers) &&
            (isDomainWarning && !isGeneralWarning)){
            return (
                <AlignItems>
                    <img style={{width:'15px', height:'15px'}} src={icon_alert_outline} alt="" />
                    <Text size="13px" color="#6E6E6E">
                        {weightFlagDomainMessage}
                    </Text>
                </AlignItems>
            );
        }
        if(!(peersAvailableCount < minimunBenchmarkingPeers) &&
        ((isGeneralWarning && !isDomainWarning ) || ( isGeneralWarning && isDomainWarning ) )){
            return (<AlignItems>
                <img style={{width:'15px', height:'15px'}} src={icon_alert_outline} alt="" />
                <Text size="13px" color="#6E6E6E">
                    {generalWeightFlagMessage}
                </Text>
            </AlignItems>);
    }
    };

    return loading ? (  <SpinnerWithOverlay />  ) : error ? (
        <Wrapper>Error loading data...</Wrapper>
    ) : (
        <div style={state && state.campaignId.length ? { marginTop: 16 } : {}}>
            {state && state.campaignId.length ? (
                <Breadcrumb crumbs={benchmarkingBreadcrumbs} />
            ) : (
                ''
            )}
            <Header
                style={
                    state && state.campaignId.length ? { paddingTop: 0 } : {}
                }>
                <Typography
                        variant='h1'
                        style={{
                            letterSpacing: 0.5,
                            marginTop: 22,
                            color:'#525252',
                            fontWeight:'bold',
                            fontSize:'24px'
                        }}
                    >
                        Benchmarking
                </Typography>
                <Row style={{'justifyContent': 'space-between'}}>
                    <Column>
                        <HeadingsFilter constantSize>
                            Campaign for benchmarking
                            <Tooltip
                                title={'You can only benchmark campaigns which have been completed and submitted to benchmarking.'}
                                        placement="right">
                                <IconButton style={{padding: 0, marginLeft: '4px', marginBottom: '2px'}} aria-label='info tooltip'>
                                <InfoIcon style={{fontSize: '12px'}}/>
                                </IconButton>
                                </Tooltip>
                        </HeadingsFilter>
                        <DropdownSingleSelection
                            listOfOptions={listOfCampaignOptions}
                            selectedOptionId={selectedCampaignId}
                            setSelectedOptionId={setSelectedCampaignId}
                            isOpen={isCampaignMenuOpen}
                            setOpen={setCampaignMenuOpen}
                            placeholder={selectPlaceholder(
                                selectedCampaigns.name.length
                                    ? [selectedCampaigns.name]
                                    : [],
                                data && data.availableCampaignsByClient,
                                'campaign'
                            )}
                            width="312px"
                            setGetFiltersData={setGetFiltersData}
                        />
                    </Column>
                    { (importBenchmarkingEnable && user && user.role === 'ADMIN') &&
                        <Column>
                            <HeadingsFilter constantSize>
                                Import benchmarking data
                            </HeadingsFilter>
                            <RedButton
                                style={{
                                    marginRight: 32,
                                    maxHeight: 44,
                                    padding: '0 16px',
                                }}
                                onClick={() => handleImportModalToggle()}>
                                <Flex style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <CloudUpload/>
                                    <Text
                                        style={{
                                            color: '#fff',
                                            margin: 10,
                                            fontSize: 16,
                                            fontWeight: 500,
                                        }}>
                                        Import
                                    </Text>
                                </Flex>
                            </RedButton>
                        </Column>
                    }
                </Row>
            </Header>
            <BenchmarkingBody>
                <BenchmarkingBodyHeader>
                    <BenchmarkingBodyHeaderHeading>
                        PEER GROUP
                    </BenchmarkingBodyHeaderHeading>
                    <Flex>
                    {exportData[0] && exportData[0].objectivesScore ? (
                        <RedButton
                            style={{
                                marginRight: 32,
                                maxHeight: 44,
                                padding: '0 16px',
                            }}
                            disabled={peersAvailableCount < minimunBenchmarkingPeers}
                            onClick={() => exportCsv()}
                        >
                            <Flex>
                                <img
                                    src={iconDownload}
                                    style={{ marginRight: 8 }}
                                    alt="download"
                                />
                                <Text
                                    style={{
                                        color: '#fff',
                                        margin: 10,
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    Benchmarking Data
                                </Text>
                            </Flex>
                        </RedButton>
                    ) : null}
                        <CustomMenu
                            top={'-2%'}
                            buttonDisabled={
                                peersAvailableCount < minimunBenchmarkingPeers ||
                                selectedCampaignId === undefined ||
                                selectedCampaignId === ''
                            }
                            button={
                                <ReportInnerButton
                                    disabled={
                                        peersAvailableCount < minimunBenchmarkingPeers ||
                                        selectedCampaignId === undefined ||
                                        selectedCampaignId === ''
                                    }
                                >
                                    <img
                                        src={iconDownload}
                                        style={{ marginRight: 8 }}
                                        alt="download"
                                    />
                                    <Text
                                        style={{
                                            color: '#fff',
                                            marginLeft: 8,
                                            fontSize: 16,
                                            fontWeight: 500
                                        }}
                                    >
                                        Report
                                    </Text>
                                </ReportInnerButton>
                            }
                        >
                            <MenuItem
                                // button
                                key={0}
                                onClick={() => {
                                    exportImage(true);
                                }}
                            >
                                <ListItemText
                                    primary={<Text>Save to Google Drive</Text>}
                                />
                            </MenuItem>
                            <MenuItem
                                // button
                                key={1}
                                onClick={() => {
                                    exportImage(false);
                                }}
                            >
                                <ListItemText
                                    primary={<Text>Export as PPT</Text>}
                                />
                            </MenuItem>
                        </CustomMenu>
                        <CustomRedButton
                            style={{
                                marginLeft: 32,
                                padding: '12px 16px',
                                minHeight: '46px'
                            }}
                            disabled={disableSaveButton()}
                            onClick={() => {
                                sendFilters({
                                    variables: requestVar()
                                });
                            }}
                        >
                            Save filters
                        </CustomRedButton>
                    </Flex>
                </BenchmarkingBodyHeader>
                <SpaceBetween style={{ width: '100%' }}>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>
                            Region/Country
                        </HeaderFilterHeading>
                        <PopoverRegionMenu
                            selected={selectedRegions}
                            setSelected={setSelectedRegions}
                            isOpen={isRegionMenuOpen}
                            setOpen={setRegionMenuOpen}
                            data={data && data.homeCountries}
                            buttonDisabled={!selectedCampaigns.name.length}
                            placeholder={selectPlaceholder(
                                selectedRegions,
                                data && data.homeCountries,
                                'region/country'
                            )}
                        />
                    </ColumnWithMargin>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>Industry</HeaderFilterHeading>
                        <DropdownMultipleSelection
                            buttonDisabled={!selectedCampaigns.name.length}
                            selected={selectedIndustrys}
                            setSelected={setSelectedIndustrys}
                            isOpen={isIndustryMenuOpen}
                            setOpen={setIndustryMenuOpen}
                            multiselect
                            listOfOptions={data && data.industries}
                            placeholder={selectPlaceholder(
                                [],
                                data && data.industries,
                                'industry',
                                selectedIndustrys
                            )}
                            selectAllText={'Select All'}
                        />
                    </ColumnWithMargin>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>Company type</HeaderFilterHeading>
                        <DropdownMultipleSelection
                            buttonDisabled={!selectedCampaigns.name.length}
                            selected={selectedCompanys}
                            setSelected={setSelectedCompanys}
                            isOpen={isCompanyMenuOpen}
                            setOpen={setCompanyMenuOpen}
                            multiselect
                            listOfOptions={data && data.clientTypes}
                            placeholder={selectPlaceholder(
                                [],
                                data && data.clientTypes,
                                'company type',
                                selectedCompanys
                            )}
                            selectAllText={'Select All'}
                        />
                    </ColumnWithMargin>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>
                            Assessment type
                        </HeaderFilterHeading>
                        <DropdownMultipleSelection
                            buttonDisabled={!selectedCampaigns.name.length}
                            selected={selectedAssessments}
                            setSelected={setSelectedAssessments}
                            isOpen={isAssessmentMenuOpen}
                            setOpen={setAssessmentMenuOpen}
                            multiselect
                            listOfOptions={data && assessmentTypeData}
                            placeholder={selectPlaceholder(
                                [],
                                data && assessmentTypeData,
                                'assessment type',
                                selectedAssessments
                            )}
                            selectAllText={'Select All'}
                        />
                    </ColumnWithMargin>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>Revenue</HeaderFilterHeading>
                        <DropdownMultipleSelection
                            buttonDisabled={!selectedCampaigns.name.length}
                            selected={selectedRevenues}
                            setSelected={setSelectedRevenues}
                            isOpen={isRevenueMenuOpen}
                            setOpen={setRevenueMenuOpen}
                            multiselect
                            listOfOptions={data && data.annualRevenues}
                            placeholder={selectPlaceholder(
                                [],
                                data && data.annualRevenues,
                                'revenue',
                                selectedRevenues
                            )}
                            selectAllText={'Select All'}
                        />
                    </ColumnWithMargin>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>
                            No. of employees
                        </HeaderFilterHeading>
                        <DropdownMultipleSelection
                            buttonDisabled={!selectedCampaigns.name.length}
                            selected={selectedEmployees}
                            setSelected={setSelectedEmployees}
                            isOpen={isEmployeesMenuOpen}
                            setOpen={setEmployeesMenuOpen}
                            multiselect
                            listOfOptions={data && data.employeesNumbers}
                            placeholder={selectPlaceholder(
                                [],
                                data && data.employeesNumbers,
                                'no. of employees',
                                selectedEmployees
                            )}
                            selectAllText={'Select All'}
                        />
                    </ColumnWithMargin>
                    <ColumnWithMargin>
                        <HeaderFilterHeading>Date range</HeaderFilterHeading>
                        <DateRangeSelect
                            buttonDisabled={!selectedCampaigns.name.length}
                            open={dateRangeSelectOpen}
                            setOpen={setDateRangeSelectOpen}
                            loading={loading}
                            yearsData={createYearsFromToArray(yearFrom, yearTo)}
                            selected={selectedDatesRange}
                            setSelected={setSelectedDatesRange}
                        />
                    </ColumnWithMargin>
                </SpaceBetween>
                <Flex style={{ width: '100%', marginTop: '16px', display:'block' }}>
                    <AlignItems>
                        <img src={icon_peer} alt="" />
                        <BoldText
                            margin="0 5px"
                            fontSize="23px"
                            color="#6E6E6E"
                        >
                            {peersAvailableCount}
                        </BoldText>
                        <Text size="15px" color="#6E6E6E">
                            Peers available
                        </Text>
                        <BenchmarkingInfo
                        onClick={handleClickOpenBenchmarkingInfoModal}
                        variant="outlined"
                        >

                          <InfoIcon style={{fontSize: '22px', color:''}}/>  Benchmarking info

                           </BenchmarkingInfo>
                    </AlignItems>
                    <BenchmarkingInfoModal
                    isOpenBenchmarkingInfo={isOpenBenchmarkingInfo}
                    setIsOpenBenchmarkingInfo={setIsOpenBenchmarkingInfo}
                    />

                    {weightedLevelMessage(differentWeightData)}
                </Flex>
                {showGraphSpin ? (
                    <SpinnerWithOverlay />

                ) : peersAvailableCount >= minimunBenchmarkingPeers ? (
                    <BenchmarkingGraph
                        data={graphData}
                        assessmentData={currentCampaignData}
                        title={'Benchmarking - ' + selectedCampaigns.name}
                        ref={ref}
                        readOnlyEnabled={readOnlyEnabled}
                        isReadOnly={isReadOnly}
                        pointOneDecimalEnable={pointOneDecimalEnable}
                    />
                ) : (
                    <EmptyState>
                        <img src={emptyStateIcon} alt="" />
                        <EmptyStateHeading>
                            Start benchmarking your data
                        </EmptyStateHeading>
                        <EmptyStateText>
                            Benchmark data according to location and industry as
                            well as other key factors that will allow you
                            compare year-on-year assessments of the same
                            framework.
                        </EmptyStateText>
                    </EmptyState>
                )}
            </BenchmarkingBody>
            <div style={{ height: '50px' }} />
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={successToastOpen}
                autoHideDuration={3000}
                icon="check"
                width="379px"
                title="The filters have been saved for this campaign."
                closeToast={() => setSuccessToastOpen(false)}
            />
            {renderModal({
                modalId: modalprops.modalId,
                handleClose: () =>
                    setModalProps({ modalId: '', isOpen: false }),
                handleRetry: () => {
                    setModalProps({ modalId: '', isOpen: false });
                    sendFilters({
                        variables: requestVar()
                    });
                },
                isOpen: modalprops.isOpen
            })}

            <Modal
                isOpen={importDialogOpen}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                style={customModalStyles}
                onRequestClose={handleImportModalToggle}>
                <PopUpHeader handleClose={handleImportModalToggle} title='Import Benchmarking Data'/>


                <Column>
                    <DropzoneUpload
                        files={files}
                        setFiles={setFiles}
                        title={'Drag `n` drop some files here, or click to select files'}
                        subTitle={'Only *.csv file will be accepted'}
                        extraText={'1 file is the maximum number of files you can drop here'}
                        errorUpload={errorUpload}
                    />
                    <RedButton
                        value="button"
                        onClick={() => {
                            uploadFilesToBenchmarking();
                        }}>
                        <Flex style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CloudUpload/>
                            <Text
                                style={{
                                    color: '#fff',
                                    margin: 10,
                                    fontSize: 16,
                                    fontWeight: 500,
                                }}>
                                Upload
                            </Text>
                        </Flex>
                    </RedButton>
                </Column>

            </Modal>
        </div>
    );
};

const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    left: 50%;
`;

const ReportInnerButton = styled(Flex)<{ disabled?: boolean }>`
    padding: 0 16px;
    background-color: ${props => props.disabled ? '#f5928b' : '#de3328'};
    min-height: 48px;
    box-sizing: border-box;
    border: none;
    color: white;
    align-items: center;
    &:hover {
        background-color: #f05046;
        color: white;
    }
`;

const EmptyState = styled(JustifyContentCenter)`
    flex-direction: column;
    padding-top: 35px;
`;

const EmptyStateText = styled(Text)`
    font-size: 16px;
    color: #666666;
    max-width: 400px;
    text-align: center;
`;

const EmptyStateHeading = styled(Text)`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #666666;
    margin-top: 46px;
    margin-bottom: 16px;
`;

const BenchmarkingBodyHeader = styled(SpaceBetween)`
    width: 100%;
    margin-bottom: 26px;
`;

const CustomRedButton = styled(RedButton)`
    .MuiButton-label {
        height: 18px;
        font-size: 18px;
    }
`;

const BenchmarkingBody = styled(JustifyContentCenter)`
    border: 1px solid #d9d9d9;
    padding: 32px 27px 35px 24px;
    flex-direction: column;
    background-color: #ffffff;
`;

const Header = styled(SpaceBetween)`
    padding-top: 32px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
`;

const BenchmarkingBodyHeaderHeading = styled(Text)`
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #575757;
`;

const HeaderFilterHeading = styled(Text)`
    letter-spacing: 0.5px;
    color: #575757;
    margin-bottom: 8px;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const HeadingsFilter = styled(Text)`
    letter-spacing: 0.5px;
    color: #575757;
    padding-top: 24px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ColumnWithMargin = styled(Column)`
    margin: 0 5px;
`;

const BenchmarkingInfo = styled(Button)`
    && {
        padding: 4px 7px;
        border: solid 1.5px #d9d9d9;
        background-color: #fff;
        margin-left: 20px;
        color: #6E6E6E;
    }
`;

export default Benchmarking;
