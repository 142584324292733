import React from 'react';
import { styled } from '@mui/material/styles';
import Text from '../base/Text/Text';
import {
    squireColorResolver,
    roundScore,
    textColorResolver
} from '../../utils/UtilsHelpers';
import Router from '../../routes/router';
import { statusResolver } from '../../modules/Evaluate/EvaluateHelpers';
import iconNotepad from '../../assets/images/icon-notepad.svg';

type InScopeBlockTypes = {
    averageScore: number;
    assessmentId: string;
    domainId: string;
    capabilityId: string;
    capabilityStatus: string;
    moderatedScore: number;
    workingNotes: {
        text: string;
    };
    currentTheme: string;
    campaignId: string;
    isObjectiveNotes: boolean;
    showTargetScore?: boolean;
    targetScore?: number;
    isReadOnly?: boolean;
    readOnlyEnabled?: boolean;
    pointOneDecimalEnable?: boolean;
};

type RedirectParams = {
    campaignId: string;
    assessmentId: string;
    domainId: string;
    capabilityId: string;
};

const RedirectToScore = (params: RedirectParams) => {
    Router.goToMaturityScore(params);
};

const renderCapabilityInScopeBlock = ({
    averageScore,
    assessmentId,
    domainId,
    capabilityId,
    capabilityStatus,
    moderatedScore,
    workingNotes,
    currentTheme,
    campaignId,
    isObjectiveNotes,
    showTargetScore,
    targetScore,
    isReadOnly,
    readOnlyEnabled,
    pointOneDecimalEnable
}: InScopeBlockTypes) => {
    const onClick = () => {
        (readOnlyEnabled && isReadOnly && capabilityStatus !== 'COMPLETED') ? console.log('Blocked!!!') :
        RedirectToScore({
            campaignId,
            assessmentId,
            domainId,
            capabilityId
        });
    };

    if (averageScore === null && capabilityStatus === 'NOT_STARTED' || ((readOnlyEnabled && isReadOnly) && (capabilityStatus !== 'COMPLETED'))) {
        if (workingNotes || isObjectiveNotes && !(readOnlyEnabled && isReadOnly)) {
            return (
                <Block
                    aria-label='capability button'
                    onClick={onClick}
                    style={{
                        background: '#fff',
                        border: 'solid 0.5px #d7d7d7'
                    }}
                >
                    <img src={iconNotepad} alt="notepad icon" aria-hidden={true}/>
                </Block>
            );
        }
        return (
            <Block
                aria-label='capability button'
                onClick={onClick}
                style={{
                    background: squireColorResolver(
                        averageScore ? roundScore(averageScore, pointOneDecimalEnable) : null,
                        capabilityStatus,
                        currentTheme,
                        showTargetScore,
                        targetScore,
                        readOnlyEnabled,
                        isReadOnly
                    ),
                    color: textColorResolver(
                        roundScore(averageScore, pointOneDecimalEnable),
                        capabilityStatus,
                        currentTheme,
                        showTargetScore,
                        readOnlyEnabled,
                        isReadOnly
                    ),
                    border: 'solid 0.5px #d7d7d7'
                }}
            >
                {capabilityStatus === 'NOT_STARTED'?null:'P'}
            </Block>
        );
    }
    const displayScore = moderatedScore|| moderatedScore === 0 ? roundScore(moderatedScore, pointOneDecimalEnable) : roundScore(averageScore, pointOneDecimalEnable); 
    return (
        <Block
            aria-label='capability button'
            onClick={onClick}
            style={{
                backgroundColor: squireColorResolver(
                    displayScore,
                    capabilityStatus,
                    currentTheme,
                    showTargetScore,
                    targetScore
                ),
                color: textColorResolver(
                    displayScore,
                    capabilityStatus,
                    currentTheme,
                    showTargetScore
                ),
                padding: 0,
                border: 'solid 0.5px #d7d7d7',
                flexDirection: 'column',
                justifyContent: statusResolver(capabilityStatus)
                    ? 'flex-start'
                    : 'center'
            }}
        >
            <ProgressText>{statusResolver(capabilityStatus)}</ProgressText>
            {displayScore}
        </Block>
    );
};
const Block = styled('button')`
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`;

const ProgressText = styled(Text)`
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    display: flex;
    width: 100%;
    justify-content: center;
`;

export default renderCapabilityInScopeBlock;
