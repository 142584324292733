/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import iconClose from '../assets/images/icon close.svg';
import iconArchive from '../assets/images/Atmos_Icons_Archive.svg';
import Text from './../components/base/Text/Text';
import Button from './../components/base/Buttons/Button';

import arrowRightBlack from '../assets/images/icon-arrow-right-black.svg';
import arrowRightWhite from '../assets/images/icon-arrow-right-white.svg';

export const getLastUpdatedDateDifference = ({
    currentDate
}: {
    currentDate: number;
}): string => {
    const date1 = new Date(currentDate).getTime();
    const date2 = new Date().getTime();
    const seconds: number = Math.floor((date2 - date1) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
        return `${interval} ${interval === 1 ? 'year' : 'years'} ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return `${interval} ${interval === 1 ? 'month' : 'months'} ago`;
    }
    interval = Math.floor(seconds / (60 * 60 * 24));
    if (interval >= 1) {
        return `${interval} ${interval === 1 ? 'day' : 'days'} ago`;
    }
    interval = Math.floor(seconds / (60 * 60));
    if (interval >= 1) {
        return `${interval} ${interval === 1 ? 'hour' : 'hours'} ago`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return `${interval} ${interval === 1 ? 'minute' : 'minutes'} ago`;
    }
    return 'recently';
};

export const withoutThemeColorResolver = (value: number | null) => {
    if (value === null) {
        return 'none';
    }
    if (!value && value !== 0) {
        return 'NS';
    }
    const averageScore = Number(value.toFixed(1));
    if (averageScore <= 1.4) {
        return '#eceff4';
    }
    if (averageScore >= 1.5 && averageScore <= 2.4) {
        return '#e2e6ec';
    }
    if (averageScore >= 2.5 && averageScore <= 3.4) {
        return '#c2d2df';
    }
    if (averageScore >= 3.5 && averageScore <= 4.4) {
        return '#94adc4';
    }
    if (averageScore >= 4.5 && averageScore <= 5) {
        return '#4588c3';
    }
};

export const squireColorResolver = (
    value: number | null,
    status: string,
    currentTheme: string,
    showTargetScore?: boolean,
    targetScore?: number | null,
    readOnlyEnabled?: boolean,
    isReadOnly?: boolean

) => {
    const averageScore = value === null ? 0 : Number(value.toFixed(1));

    if((readOnlyEnabled && isReadOnly)  && (status !== 'COMPLETED') ) {
        return '#ffffff';
    }

    if(showTargetScore){
        if(targetScore == null || value == null){
            return 'white';
        }else{

            const difference = targetScore - averageScore;
            if(difference >= 2.6){ return '#E0301E';}
            if(difference <= 2.5 && difference >= 1.1){ return '#FFB600';}
            if(difference <= 1){ return '#92D050';}
        }
    }

    switch (currentTheme) {
        case 'RAGtheme1':
            if (
                status === 'NOT_STARTED' ||
                status === 'IN_PROGRESS' ||
                status === 'NOT_SCORED' ||
                value === null
            ) {
                return '#ffffff';
            }
            if (averageScore <= 1) {
                return '#E0301E';
            }
            if (averageScore >= 1.1 && averageScore <= 2) {
                return '#FD9359';
            }
            if (averageScore >= 2.1 && averageScore <= 3) {
                return '#FFB600';
            }
            if (averageScore >= 3.1 && averageScore <= 4) {
                return '#5EB562';
            }
            if (averageScore >= 4.1 && averageScore <= 5) {
                return '#006652';
            }
            break;

        case 'RAGtheme2':
            if (
                status === 'NOT_STARTED' ||
                status === 'IN_PROGRESS' ||
                status === 'NOT_SCORED' ||
                value === null
            ) {
                return '#ffffff';
            }
            if (averageScore <= 1) {
                return '#E0301E';
            }
            if (averageScore >= 1.1 && averageScore <= 2) {
                return '#FFB600';
            }
            if (averageScore >= 2.1 && averageScore <= 3) {
                return '#92D050';
            }
            if (averageScore >= 3.1 && averageScore <= 4) {
                return '#38963C';
            }
            if (averageScore >= 4.1 && averageScore <= 5) {
                return '#006652';
            }
            break;

        default:
            if (
                status === 'NOT_STARTED' ||
                status === 'IN_PROGRESS' ||
                status === 'NOT_SCORED' ||
                value === null
            ) {
                return '#ffffff';
            }
            if (averageScore <= 1) {
                return '#eceff4';
            }
            if (averageScore >= 1.1 && averageScore <= 2) {
                return '#e2e6ec';
            }
            if (averageScore >= 2.1 && averageScore <= 3) {
                return '#c2d2df';
            }
            if (averageScore >= 3.1 && averageScore <= 4) {
                return '#94adc4';
            }
            if (averageScore >= 4.1 && averageScore <= 5) {
                return '#4588c3';
            }
            break;
    }
};

export const textColorResolver = (
    value: number | null,
    status: string,
    currentTheme: string,
    isTargetScore?: boolean,
    readOnlyEnabled?: boolean,
    isReadOnly?: boolean
) => {
    const averageScore = value === null ? 0 : Number(value.toFixed(1));

    if(isTargetScore){
        return '#000';
    }

    if((readOnlyEnabled && isReadOnly)  && (status !== 'COMPLETED') ) {
        return '#000';
    }

    if (
        status === 'NOT_STARTED' ||
        status === 'IN_PROGRESS' ||
        status === 'NOT_SCORED' ||
        value === null
    ) {
        return '#000';
    }
    if (currentTheme === 'RAGtheme1' || currentTheme === 'RAGtheme2') {
        return '#ffffff';
    }
    if (currentTheme === 'blue') {
        return '#000';
    }
    if (averageScore <= 3) {
        return '#000';
    }
    if (averageScore >= 3.1 && averageScore <= 5) {
        return '#fff';
    }
};

export const arrowColorResolver = (
    value: number | null,
    status: string,
    currentTheme: string
) => {
    const averageScore = value === null ? 0 : Number(value.toFixed(1));

    if (
        status === 'NOT_STARTED' ||
        status === 'IN_PROGRESS' ||
        status === 'NOT_SCORED' ||
        value === null
    ) {
        return arrowRightBlack;
    }
    if (currentTheme === 'RAGtheme1' || currentTheme === 'RAGtheme2') {
        return arrowRightWhite;
    }
    if (averageScore <= 3) {
        return arrowRightBlack;
    }
    if (averageScore >= 3.1 && averageScore <= 5) {
        return arrowRightWhite;
    }
};

export const isEven = (n: number) => !(n % 2);

export const moveCaretAtEnd = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
};

export const htmlToText = (format: string) => {
    const text = format.replace(/<\s*br[^>]?>/,'\n')
            .replace(/(<([^>]+)>)/g, '').replace(/&nbsp;/g, ' ');
    return text;

};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const countProgress = ({
    progressLength,
    value
}: {
    progressLength: number;
    value: number;
}) => (progressLength ? (value * 100) / progressLength : 0);

export const roundToTwoDecimailPlaces=(num?: number|string)=>{
    if(typeof(num)==='number'){
        return (Math.ceil(num * 100) / 100).toFixed(1);
    }
    return 'Scores pending';
};

export const mulitplyby100 =(value: number) =>{
    return(((value * 10000) / 100 ));
};
export const roundScore = (x: number | null, pointOneDecimal = false) => {
    if (x === null) {
        return null;
    }
    if (pointOneDecimal && x !== null) {
        const lastDigit = (mulitplyby100(x) %10);
        if(lastDigit >= 5) {
            return (Math.floor(x * 10) + 1) / 10;
        }
        return Math.floor(x * 10) / 10;
    }
    const integer = Math.floor(x);
    const decimal = (mulitplyby100(x)) % 100;
    if (decimal < 50) {
        return integer;
    }
    return integer + 0.5;
};

//sorts alphabets first followed by numerics
export const sortAlphaNum=(a: any, b: any) => {
    const reA = /[^a-zA-Z]/g;
    const reN = /[^0-9]/g;
    const AInt = parseInt(a, 10);
    const BInt = parseInt(b, 10);

    if(isNaN(AInt) && isNaN(BInt)){
        const aA = a.replace(reA, '');
        const bA = b.replace(reA, '');
        if(aA === bA) {
            const aN = parseInt(a.replace(reN, ''), 10);
            const bN = parseInt(b.replace(reN, ''), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    }else if(isNaN(AInt)){
        return -1;
    }else if(isNaN(BInt)){
        return 1;
    }else{
        return AInt > BInt ? 1 : -1;
    }
};

export const dynamicSort=(property: any) =>{
    let sortOrder = 1;
    if(property[0] === '-')  {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a: any,b: any) {
         if (a === b && a === 'number') {
            return a.Acr - b.Acr;
         }else

        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
    };
};

export const roundGraphScore = (x: number | null, pointOneDecimal = false) => {
    if (pointOneDecimal && x !== null) {
        const lastDigit = (mulitplyby100(x) %10);
        if (lastDigit >= 5) {
            return (Math.floor(x * 10) + 1) / 10;
        }
        return Math.floor(x * 10) / 10;
    }
    if (x !== null) {
        const integer = Math.floor(x);
        const decimal = (mulitplyby100(x)) % 100;
        if (decimal < 50) {
            return integer;
        }
        return integer + 0.5;
    }
    return null;
};

export const roundFloor = (num: number | null, pointOneDecimal = false) => {
    if (num === null) {
        return 0;
    }
    if (pointOneDecimal && num !== null) {
        const lastDigit = (mulitplyby100(num) %10);
        if (lastDigit >= 5) {
            return (Math.floor(num * 10) + 1) / 10;
        }
        return Math.floor(num * 10) / 10;
    }
    if (num > 0.5 && num < 1) {
        return 1;
    }
    return Math.floor(num * 2) / 2;
};

export const renderCloseButton = ({
    filterStatus,
    openModal,
    disabled = false
}: {
    filterStatus: string;
    openModal: () => void;
    disabled: boolean;
}) => {
    if (filterStatus === 'ALL' || filterStatus === 'CLOSED') {
        return null;
    }
    return (
        <HeaderButton disabled={disabled} onClick={openModal}>
            <img src={iconClose} alt="archive icon" />
            <ButtonText>Close</ButtonText>
        </HeaderButton>
    );
};

export const renderArchiveButton = ({
    filterStatus,
    doArchive,
    openModal,
    disabled = false
}: {
    filterStatus: string;
    doArchive?: any;
    openModal: () => void;
    disabled?: boolean;
}) => {

    if (filterStatus === 'ARCHIVED' || doArchive !== 'ACTIVE') {
        return null;
    }
    return (
        <HeaderButton disabled={disabled} onClick={openModal}>
            <img src={iconArchive} alt="disable icon" aria-hidden={true}/>
            <ButtonText>Archive</ButtonText>
        </HeaderButton>
    );
};

export const renderUnArchiveButton = ({
    filterStatus,
    doArchive,
    openModal
}: {
    filterStatus: string;
    doArchive?: any;
    openModal: () => void;
}) => {
    if (filterStatus === 'ACTIVE' || doArchive !== 'ARCHIVED') {
        return null;
    }
    return (
        <HeaderButton onClick={openModal}>
            <img src={iconArchive} alt="active icon" aria-hidden={true}/>
            <ButtonText>Unarchive</ButtonText>
        </HeaderButton>
    );
};

const HeaderButton = styled(Button)`
    && {
        margin-left: 20px;
        padding: 10px 14px;
    }
`;

const ButtonText = styled(Text)`
    color: #575757;
    font-size: 16px;
    margin-left: 8px;
    font-weight: 500;
`;

export const stringTruncator = (str: string, to: number) => {
    return str.length > to ? `${str.slice(0, to)}...` : str;
};

export const parseJwt = (token: string | null) => {
    if (!token) {
        return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const useDidMountEffect = (func: any, deps: Array<unknown>) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
};

export const TwelveDecimalNumber=(num: number) => {
    // @ts-ignore
    return parseFloat(num.toString().match(/^-?\d+(?:\.\d{0,12})?/)[0]);
};

export const oneDecimalNumber=(num: number) => {
    // @ts-ignore
    return parseFloat(num.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]);
};

export const isHTML =(str: string): boolean => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

export const htmlToString = (s: string, space: boolean): string => {
    const span= document.createElement('span');
    span.innerHTML= s;
    
    if(space) {
        const children: any = span.querySelectorAll('*');
        for(let i = 0 ; i < children.length ; i++) {
            if(children[i].textContent) {
                children[i].textContent += ' ';
            } else {
                children[i].innerText += ' ';
            }
        }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g,' ');
};