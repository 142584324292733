import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { AlignItems, Flex } from '../../../../components';
import { debounce } from 'lodash';

type Props = {
    placeholder?: string;
    callback?: any;
    setSearchValue: any;
    searchValue: string;
};
    const SearchEntitiesField: React.FC<Props> = ({ placeholder, callback, setSearchValue,searchValue }) => {

    const debounceFn = debounce(handleDebounceFn, 1300);

    function handleDebounceFn(event: any) {
        setValue(event.target.value);
        callback(event.target.value);
    }

    const handleChange = (event:any ) => {
        setSearchValue(event.target.value);
        debounceFn(event);
    };

    const [value, setValue] = useState<string>();
    return (
             <Wrapper>
            <Search
                type="search"
                value={searchValue}
                placeholder={placeholder}
                inputProps={{ 'aria-label':placeholder }}
                onChange={e => {
                    handleChange(e);
                }}
            />
            </Wrapper>
    );
};

const Search = styled(InputBase)`
    && {
        position: absolute;
        border: none;
        width: 100%;
        outline: none;
        border-radius: 50px;
        padding: 1% 10px 5px 24px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        background-color: #fff;
        &::-webkit-input-placeholder {
            color: #818181;
        }
    }
`;

const Wrapper = styled('div')`
    display: flex;
    height: 40px;
    width: 22%;
    border-radius: 30px;
    border: solid 1px #dcdcdc;
    top: 20px;
    left: 78%;
    position: relative;
    box-sizing: border-box;
`;

export default SearchEntitiesField;
