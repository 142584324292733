import { styled } from '@mui/material/styles';
import CustomButton from './Button';

const RedButton = styled(CustomButton)`
    && {
        background-color: #de3328;
        min-height: 48px;
        box-sizing: border-box;
        border: none;
        color: white;
        &:hover {
            background-color: #f05046;
            color: white;
        }
        &:disabled {
            background-color: #f5928b;
            color: white;
        }
    }
`;

export default RedButton;
