import React from 'react';
import { styled } from '@mui/material/styles';
import {
    RedButton,
    Text,
    PopUpHeader
} from '../../../../../components';
import { TextField } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import {weightBalanceSuccessMessages, oneDecimalNumber, weightingValidationMessage, weightBalanceFailureMessage, TwelveDecimalNumber} from '../../../../../utils';

export type ContentModelProps = {
    titles: Title;
    content: Content[];
    handleOnSave: any;
    isError?: any;
    setbalanceWeightSuccessMessage: any;
    balanceWeightSuccessMessage: boolean;
    balanceweightFailureMessage: boolean;
};
export type Title = {
    title: string;
    subTitle?: string;
};

export type Content = {
    id: string;
    inScope?: boolean;
    name: string;
    description: string;
    value: number;
};
let submitAction: string | undefined;
const ContentModel: React.FC<ContentModelProps> = ({
                                                       titles,
                                                       content,
                                                       handleOnSave,
                                                       isError,
                                                       setbalanceWeightSuccessMessage,
                                                       balanceWeightSuccessMessage,
                                                       balanceweightFailureMessage
                                                   }) => {

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{ content }}
                onSubmit={(values,actions) => {
                    if(submitAction==='balance') {
                        const length = values.content?.filter((com) => com.inScope).length || 1;
                        values.content?.forEach((com: any) => com.inScope ? com.value = TwelveDecimalNumber(100 / length) : com.value = 0);
                        handleOnSave(values.content, true);
                        actions.setValues({...values});
                    }
                    else if(submitAction==='submit'){
                        handleOnSave(values.content);
                        setbalanceWeightSuccessMessage(false);
                    }
                }}>
                    {({ setFieldValue, values,handleSubmit }) => {
                    return (
                        <>
                        <PopUpHeader title='Manage Component Weights' handleClose={() => {
                            submitAction='submit';
                            handleSubmit();
                        }} />
                        <Form style={{padding: '25px'}}>
                            <FieldArray
                                name='content'
                                render={(arrayHelpers) => (
                                    <>
                                        <ObjectiveHeader>
                                            <TitleContainer>
                                                <BoldText>{titles.title}</BoldText>
                                                {titles.subTitle ? ': ' + titles.subTitle : ''}
                                            </TitleContainer>
                                            <HeaderTitle>Weight</HeaderTitle>
                                        </ObjectiveHeader>
                                        {values.content.map((item, index) => {
                                            return (
                                                <>
                                                    <QuestionsContainer key={item.id}>

                                                    <div style={{ display: 'block' }}>
                                                        <QuestionTitle>
                                                            <BoldText>{item && item.name}</BoldText>
                                                        </QuestionTitle>
                                                        <QuestionDescription>
                                                            {item && item.description}
                                                        </QuestionDescription>
                                                    </div>
                                                    <div style={{
                                                        display: 'block',
                                                        alignSelf: 'center',
                                                        marginLeft: '10px',
                                                        color: item && item.inScope === false ? '#6E6E6E' : '#000'
                                                    }}>
                                                        <TextField
                                                            defaultValue={
                                                                item && item.inScope === false ? 0 : item && item.value
                                                            }
                                                            value={item && item.inScope === false ? 0:(oneDecimalNumber(item.value)||0)}
                                                            type="number"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    '%'
                                                                )
                                                            }}
                                                            inputProps={{
                                                                maxLength: 3,
                                                                disabled: false,
                                                                min: 0,
                                                                max: 100,
                                                                step: '0.1',
                                                                'aria-label':'set objective weight'
                                                            }}
                                                            margin="dense"
                                                            name={`weighting${index}`}
                                                            style={{
                                                                marginLeft: 15,
                                                                width: 100
                                                            }}
                                                            variant="outlined"
                                                            onChange={(e: any) => {
                                                                setFieldValue(
                                                                    `content[${index}].value`,
                                                                    parseFloat(e.target.value)
                                                                );
                                                            }}
                                                            // tslint:disable-next-line: no-redundant-boolean
                                                            disabled={item && item.inScope === false ? true : false}
                                                        />
                                                    </div>
                                                </QuestionsContainer>
                                            </>);
                                        })}
                                        {isError && <TitleContainer style={{
                                            textAlign: 'right',
                                            color: '#de3328'
                                        }}>
                                            {weightingValidationMessage}
                                        </TitleContainer>}
                                                 {
                                                    balanceWeightSuccessMessage ?
                                                    <TitleContainer style={{textAlign: 'right',
                                                    color: '#4588c3'}}>{weightBalanceSuccessMessages}</TitleContainer> :''}

                                        { balanceweightFailureMessage ? <TitleContainer style={{
                                                textAlign: 'right',
                                                color: '#de3328'
                                            }}>
                                                {weightBalanceFailureMessage}
                                            </TitleContainer> : ''}


                                        <ButtonContainer>
                                                <RedButton
                                                    style={{marginLeft: 'auto', marginRight: 16 }}
                                                    onClick={() => {
                                                        submitAction='balance';
                                                        handleSubmit();
                                                    }}>
                                                    <TemplatesButtonText>
                                                        Balance weights
                                                    </TemplatesButtonText>
                                                </RedButton>
                                            <RedButton
                                                style={{ paddingLeft: 16 }}
                                                onClick={() => {
                                                    submitAction='submit';
                                                    handleSubmit();
                                                }}
                                            >
                                                    <TemplatesButtonText>
                                                        Save
                                                    </TemplatesButtonText>
                                            </RedButton>
                                        </ButtonContainer>
                                    </>
                                )}
                            />
                        </Form>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

const ObjectiveHeader = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const QuestionsContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const BoldText = styled('span')`
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
`;

const TemplatesButtonText = styled(Text)`
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    margin: 0 16px 0 8px;
`;

const ButtonContainer = styled('div')`
    display: flex;
    justifyContent: space-between
`;

const TitleContainer = styled('p')``;
const HeaderTitle = styled('p')``;

const QuestionTitle = styled('p')``;
const QuestionDescription = styled('p')``;

export default ContentModel;
