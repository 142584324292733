import React, { MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import IconAddGrey from '../../../assets/images/icon-add-circle-grey-with-background.svg';
import ImgContainer from '../../base/ImgContainer';

const EmptyCard: React.FC<{
    handleClick: (event: MouseEvent) => void;
    title: string;
}> = ({ handleClick, title }) => {
    return (
        <CardContainer onClick={handleClick}>
            <Title>{title}</Title>
            <ImgContainer src={IconAddGrey} />
        </CardContainer>
    );
};

const Title = styled('div')`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #c4c4c4;
`;

const CardContainer = styled('div')`
    display: flex;
    padding: 35px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 2px;
    border: dashed 2px #dfdfdf;
    background-color: #fbfbfb;
`;

export default EmptyCard;
