import React from 'react';
import { styled } from '@mui/material/styles';
import { Slider, SliderProps } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import { withoutThemeColorResolver } from '../../utils';

const CustomSlider = styled(Slider)<SliderProps>(({ theme }) => ({
    color: 'transparent',
    height: '200px',
    padding: '0',
    zIndex: 10,

    thumb: {
        height: '200px',
        width: '4px',
        color: 'transparent',
        borderRadius: '25px',
        boxShadow: 'none !important',
        marginLeft: '0',
        marginTop: '4px',
        '&:focus:not(&:focus-visible)': {
            boxShadow: 'none !important',
            color: 'transparent',
        },
        '&:focus': {
            boxShadow: '0px 0px 0px 14px rgba(69, 136, 195, 0.16) !important',
            color: 'black'
        },
    },
    active: {},
    valueLabel: {
        display: 'none'
    },
    track: {
        display: 'none'
    },
    rail: {
        display: 'none'
    },
    mark: {
        display: 'none'
    },
    markActive: {
        display: 'none'
    },
    markLabel: {
        display: 'none'
    }
}));

const Custom = styled(CustomSlider)`
    .MuiSlider-thumb {
        display: none;
    }
`;

type MarkType = {
    label?: string | null;
    value: number;
};

type Props = {
    marks: MarkType[];
    setValue: any;
    value: number;
    step: number;
    min: number;
    max: number;
    backgroundHeight?: string;
    sliderMargin?: string;
    handleMaturityScoreChange?: (data: number) => void;
    renderDescriptions?: any;
    opacity?: string;
    isDisabled?: boolean;
};

const ObjectivesSlider: React.FC<Props> = ({
    marks,
    value,
    step,
    min,
    max,
    backgroundHeight,
    sliderMargin,
    opacity,
    isDisabled,
    setValue,
    renderDescriptions,
    handleMaturityScoreChange
}) => {
        return (
            <SliderContainer>

                <Custom
                    aria-label="objective score slider"
                    value={value ? value : 0}
                    onChangeCommitted={(_e: object, sliderValue: any) => {
                        if (isDisabled) {
                            return;
                        }
                        setValue(Number(sliderValue) ? Number(sliderValue) : 0);
                        handleMaturityScoreChange &&
                            handleMaturityScoreChange(sliderValue);
                    }}
                    onChange={(_e: object, sliderValue: any) => {
                        if (isDisabled) {
                            return;
                        }
                        setValue(Number(sliderValue) ? Number(sliderValue) : 0);
                    }}
                    step={step}
                    min={min}
                    max={max}
                    marks={marks}
                    valueLabelDisplay="on"
                />

                <SliderBackground
                    width={value * 2 * 10}
                    background={`linear-gradient(90deg, rgba(255,255,255,1) 0%, ${withoutThemeColorResolver(
                        value
                    )} 100%)`}
                    opacity={opacity}
                    height={backgroundHeight}
                    margin={sliderMargin}
                />

                {renderDescriptions ? renderDescriptions() : null}

            </SliderContainer>
        );
    };

ObjectivesSlider.defaultProps = {
    isDisabled: false
};

const SliderContainer = styled('div')`
    position: relative;
    min-width: 440px;
    width: 100%;
`;

const SliderBackground = styled('div') <{
    width: number;
    background?: string;
    height?: string;
    margin?: string;
    opacity?: string;
}>`
    width: ${props => props.width && props.width + '%'};
    background: ${props =>
        props.background
            ? props.background
            : 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(148,173,196,1) 100%)'};
    height: 200px;
    margin: -200px 0 0 4px;
    opacity: ${props => (props.opacity ? props.opacity : '1')};
`;

export default React.memo(ObjectivesSlider, (prevProps: Props, nextProps: Props) => {
    if (
        prevProps.marks === nextProps.marks &&
        prevProps.value === nextProps.value &&
        prevProps.step === nextProps.step &&
        prevProps.min === nextProps.min &&
        prevProps.max === nextProps.max &&
        prevProps.backgroundHeight === nextProps.backgroundHeight &&
        prevProps.sliderMargin === nextProps.sliderMargin &&
        prevProps.opacity === nextProps.opacity &&
        prevProps.isDisabled === nextProps.isDisabled &&
        prevProps.renderDescriptions === nextProps.renderDescriptions
    ) {
        return true;
    }
    return false;
});
