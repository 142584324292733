import React, { useState } from 'react';

type Props = {
    color: string;
    backgroundColor: string;
    borderColor: string;
    selected: boolean;
};

const IconMaturity1: React.FC<Props> = ({
    color,
    backgroundColor,
    borderColor,
    selected
}) => {
    const [hovered, isHovered] = useState(false);
    return (
        <svg
            onMouseEnter={() => isHovered(true)}
            onMouseLeave={() => isHovered(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill={selected ? backgroundColor : '#fff'}
                stroke={selected ? borderColor : '#D7D8DA'}
                strokeWidth="0.5"
                d="M.25 2C.25 1.034 1.034.25 2 .25h37.75v39.5H2A1.75 1.75 0 01.25 38V2z"
            />
            <path
                fill={hovered || selected ? color : '#D9D9D9'}
                d="M20.833 10H30v9.167h-9.167zM10 10h9.167v9.167H10zm10.833 10.834H30v9.167h-9.167zm-10.833 0h9.167v9.167H10z"
            />
        </svg>
    );
};

export default IconMaturity1;
