import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Cell, DetailsTableRows } from './types';
import { BoldText } from '../../../../components';
import { Capability } from './types';
import { AssessmentData } from '../types';
import { detailsTableRows } from './detailsTableRows';

export const TableHeadTitle = styled(BoldText)`
    font-size: 18px;
    color: #575757;
`;

export const assessmentDetailHeadersRows=(isComponentExist?: boolean): Cell[]=>{
    if(!isComponentExist){
    return ([
    {
        id: 'name',
        disablePadding: false,
        label: 'Capability area',
        align: 'left',
        style: { padding: '5px 16px' }
    },
    {
        id: 'averageScore',
        disablePadding: false,
        align: 'center',
        label: (
            <TableHeadTitle>
                Objective <br /> average
            </TableHeadTitle>
        ),
        style: { padding: '5px 16px' }
    },
        {
        id: 'moderatedScore',
        disablePadding: false,
        align: 'center',
        label: (
            <TableHeadTitle>
                Moderation <br /> score
            </TableHeadTitle>
        ),
        style: { padding: '5px 16px' }
    }
]);
    }
        return ([
            {
                id: 'name',
                disablePadding: false,
                label: 'Capability area',
                align: 'left',
                style: {padding: '5px 16px'}
            },
            {
                id: 'averageScore',
                disablePadding: false,
                align: 'center',
                label: (
                    <TableHeadTitle>
                        Objective <br/> average
                    </TableHeadTitle>
                ),
                style: {padding: '5px 16px'}
            }
        ]);
};

export const useFilteredCapabilities = (
    capabilities: Capability[],
    openedFeedback: string[],
    feedbackToggler: any,
    detailsTabData: AssessmentData | undefined,
    filterStatus: string,
    currentTheme: string,
    showTargetScore: boolean,
    readOnlyEnabled: boolean,
    isReadOnly: boolean,
    pointOneDecimalEnable: boolean,
    isObjectiveComponentExist: boolean
) => {
    const [filteredCapabilities, setFilteredCapabilities] = useState<
        DetailsTableRows[]
    >([]);

    useEffect(() => {
        if (capabilities.length) {
            const capabilitiesFilteredList = detailsTableRows(
                capabilities,
                openedFeedback,
                feedbackToggler,
                detailsTabData,
                filterStatus,
                currentTheme,
                showTargetScore,
                readOnlyEnabled,
                isReadOnly,
                pointOneDecimalEnable,
                isObjectiveComponentExist
            );
            setFilteredCapabilities(capabilitiesFilteredList);
        }
    }, [capabilities, filterStatus]);

    return [filteredCapabilities];
};
