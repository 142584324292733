import React, { useContext, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {
    Divider,
    ListItem,
    ListItemText,
    Select
} from '@mui/material';
import { UserStore } from '../../../../context/user-store';
import {
    AlignItems,
    Breadcrumb,
    Column,
    Flex,
    H1Text,
    LinearProgress,
    Menu,
    MenuButton,
    SpaceBetween,
    Text,
    BoldText,
    NotificationCloseCampaign
} from '../../../../components';
import iconCampaignProgress from '../../../../assets/images/icon-campaign-progress.svg';
import iconAssessmentsCovered from '../../../../assets/images/icon-assessments-covered-grey.svg';
import iconCapabilitiesCovered from '../../../../assets/images/icon-capabilities-covered-grey.svg';
import {
    ASSESSMENT_FETCH_LIMIT,
    assessmentsActiveTypes,
    assessmentsProcessTypes,
    campaignActions,
    countProgress
    // renderArchiveButton
} from '../../../../utils';
import dropdownArrow from '../../../../assets/images/icon-drop-down.svg';
import verticalDots from '../../../../assets/images/icon-3-vertical-dots.svg';
import { ASSESSMENT_LIST_PAGINATE } from '../../query';
import { CampaignData, AssessmentTableType } from '../MaturityOverview/types';
import { AssessmentsFilteredList } from '../types';
import { renderModal } from '../../Assessments/AssessmentsHelpers';
import { assessmentsActionsHandlers } from './MaturityOverviewAssessmentHeaderHelpers';
import EvaluateSearchFields from '../../Assessments/CompanyAssessmentHeader/EvaluateSearchFields';

const ARCHIVE_ASSESSMENTS = gql`
    mutation archiveAssessments2($ids: [ID!]!) {
        archiveAssessments(ids: $ids) {
            id
            archivedDate
            campaignEntity {
                id
                archivedDate
            }
        }
    }
`;

type Props = {
    setAssessmentsFilteredList: any;
    notFilteredData: AssessmentTableType[];
    selectedRow: string[];
    title: string;
    campaign?: CampaignData;
    filterProcessStatus: string;
    filterActiveStatus: string;
    setFilterProcessStatus: (data: string) => void;
    setFilterActiveStatus: (data: string) => void;
    assessmentsFilteredList: AssessmentTableType[];
    checkedAssessmentsList: AssessmentsFilteredList[];
    setSelectedRow: (data: string[]) => void;
    campaignIsClosed: boolean;
    assessmentsByCampaignWithGroupPageOffset: number;
    setSearch?: any;
};

const MaturityOverviewAssessmentHeader: React.FC<Props> = ({
    setAssessmentsFilteredList,
    notFilteredData,
    selectedRow,
    title,
    campaign,
    filterProcessStatus,
    filterActiveStatus,
    setFilterProcessStatus,
    setFilterActiveStatus,
    assessmentsFilteredList,
    checkedAssessmentsList,
    setSelectedRow,
    campaignIsClosed,
    assessmentsByCampaignWithGroupPageOffset,
    setSearch
}) => {

    const [archiveAssessments] = useMutation(ARCHIVE_ASSESSMENTS, {
        refetchQueries: [
            {
                query: ASSESSMENT_LIST_PAGINATE,
                variables: {
                    campaignId: (campaign && campaign.id) || 0,
                    id: (campaign && campaign.id) || 0,
                    pagination: {
                        skip: assessmentsByCampaignWithGroupPageOffset * ASSESSMENT_FETCH_LIMIT,
                        take: ASSESSMENT_FETCH_LIMIT
                    }
                }
            }
        ]
    });
    const [isOpen, setOpen] = React.useState(false);
    const [modalId, setModalId] = useState('');
    const [searchCount, setSearchCount] = useState(0);

    const handleProcessStatus = (event: any ) => {
        const target = event.target as HTMLInputElement;
        setFilterProcessStatus(target.value);
        setSelectedRow([]);
    };

    const handleActiveStatus = (event: any) => {
        const target = event.target as HTMLInputElement;
        setFilterActiveStatus(target.value);
        setSelectedRow([]);
    };

    const searchCallback = (params: string) => {
        if (params.length > searchCount) {
            setSearchCount(params.length);
        }
        if (!params || params.length < searchCount) {
            setAssessmentsFilteredList(notFilteredData);
            setSearch(false);
            setSearchCount(params.length);
            return;
        }
        setSearch(true);
        const filteredList = assessmentsFilteredList.filter((assessment: any) =>
            assessment.name?.props?.children?.toLowerCase().includes(params.toLowerCase())
        );
        setAssessmentsFilteredList(filteredList);
    };

    const handleToggleModal = useCallback(
        ({
            open,
            renderModalId
        }: {
            open: boolean;
            renderModalId: string;
        }) => {
            setOpen(open);
            setModalId(renderModalId);
        },
        []
    );

    const {
        state: { client = {} }
    } = useContext(UserStore);

    const assessmentsMaturityCrumbs = [
        {
            name: 'Evaluate (Campaigns)',
            path: '/main/evaluate/campaigns'
        },
        {
            name: `${client ? client.name : ''} | ${title}`
        }
    ];

    const message = campaignIsClosed && campaign && campaign.readOnly ? 'You have read-only access to this campaign.' : 'This campaign is closed - view only access.';

    return (
        <HeaderWrapper>
            {campaignIsClosed && <NotificationCloseCampaign message={message} />}
            <Breadcrumb crumbs={assessmentsMaturityCrumbs} />
            <SpaceBetween style={{ width: '100%' }}>
                <H1Text>{title}</H1Text>
                <SpaceBetween style={{ marginBottom: 20 }}>
                    <Column
                        style={{
                            paddingRight: 24,
                            borderRight: '1px solid #c5c5c5'
                        }}
                    >
                        <StatisticsHeading>CAMPAIGN</StatisticsHeading>
                        <AlignItems>
                            <img
                                src={iconCampaignProgress}
                                alt="campaign progress icon"
                                style={{ marginRight: 8 }}
                            />
                            <Column style={{ width: '100%' }}>
                                <Flex style={{ marginBottom: 4 }}>
                                    <Text style={{ marginRight: 5 }}>
                                        {campaign ? campaign.progress : 0}%
                                    </Text>
                                    <Text>Progress</Text>
                                </Flex>
                                <LinearProgress
                                    progress={campaign ? campaign.progress : 0}
                                    containerWidth="120px"
                                />
                            </Column>
                        </AlignItems>
                    </Column>
                    <Column>
                        <StatisticsHeading style={{ marginLeft: 30 }}>
                            ASSESSMENTS
                        </StatisticsHeading>
                        <AlignItems>

                            <AlignItems
                                style={{ marginLeft: 30, marginRight: 30 }}
                            >
                                <img
                                    src={iconAssessmentsCovered}
                                    alt="campaign progress icon"
                                    style={{ marginRight: 8 }}
                                />
                                <Column style={{ width: '100%' }}>
                                    <Flex style={{ marginBottom: 4 }}>
                                        <Text style={{ marginRight: 5 }}>
                                            {campaign
                                                ? campaign.assessmentsInProgressCnt
                                                : 0}
                                            /
                                            {campaign
                                                ? campaign.assessmentsCnt
                                                : 0}
                                        </Text>
                                        <Text> In progress</Text>
                                    </Flex>
                                    <LinearProgress
                                        progress={
                                            campaign
                                                ? countProgress({
                                                      progressLength:
                                                          campaign &&
                                                          campaign.assessmentsCnt,
                                                      value:
                                                          campaign &&
                                                          campaign.assessmentsInProgressCnt
                                                  })
                                                : 0
                                        }
                                        containerWidth="120px"
                                    />
                                </Column>
                            </AlignItems>
                            <AlignItems>
                                <img
                                    src={iconCapabilitiesCovered}
                                    alt="campaign progress icon"
                                    style={{ marginRight: 8 }}
                                />
                                <Column style={{ width: '100%' }}>
                                    <Flex style={{ marginBottom: 4 }}>
                                        <Text style={{ marginRight: 5 }}>
                                            {campaign
                                                ? campaign.assessmentsCompletedCnt
                                                : 0}
                                            /
                                            {campaign
                                                ? campaign.assessmentsCnt
                                                : 0}
                                        </Text>
                                        <Text>Completed</Text>
                                    </Flex>
                                    <LinearProgress
                                        progress={
                                            campaign
                                                ? countProgress({
                                                      progressLength:
                                                          campaign &&
                                                          campaign.assessmentsCnt,
                                                      value:
                                                          campaign &&
                                                          campaign.assessmentsCompletedCnt
                                                  })
                                                : 0
                                        }
                                        containerWidth="120px"
                                    />
                                </Column>
                            </AlignItems>
                        </AlignItems>
                    </Column>
                </SpaceBetween>
            </SpaceBetween>
            <SpaceBetween>
                <AlignItems>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={filterActiveStatus}
                        onChange={(e)=> handleActiveStatus(e)}
                        inputProps={{
                            'aria-label':'select assessment active status'
                        }}
                    >
                        {assessmentsActiveTypes.map(
                            ({ id, label }: { id: string; label: string }) => (
                                <option key={id} value={id}>
                                    {label}
                                </option>
                            )
                        )}
                    </CustomSelect>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={filterProcessStatus}
                        onChange={(e)=>handleProcessStatus(e)}
                        inputProps={{
                            'aria-label':'select assessment status'
                        }}
                    >
                        {assessmentsProcessTypes.map(
                            ({ id, label }: { id: string; label: string }) => (
                                <option key={id} value={id}>
                                    {label}
                                </option>
                            )
                        )}
                    </CustomSelect>
                    {selectedRow.length ? (
                        <AlignItems>
                            <Menu
                                top={'-2%'}
                                menuStyles={{
                                    padding: 0,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                                buttonDisabled={campaignIsClosed}
                                button={
                                    <MenuButton
                                        disabled={campaignIsClosed}
                                        leftIcon={verticalDots}
                                        text="Actions"
                                        rightIcon={dropdownArrow}
                                    />
                                }
                            >
                                {campaignActions.map(
                                    (
                                        el: {
                                            id: string;
                                            label: string;
                                        },
                                        i: number
                                    ) =>
                                        el.id !== 'Divider' ? (
                                            <ListItem
                                                button
                                                key={el.label}
                                                onClick={() =>
                                                    assessmentsActionsHandlers(
                                                        el.label,
                                                        i,
                                                        campaign &&
                                                            campaign.id
                                                    )
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Text>
                                                            {
                                                                el.label
                                                            }
                                                        </Text>
                                                    }
                                                />
                                            </ListItem>
                                        ) : (
                                            <Divider key={0}/>
                                        ) //TODO check this after resolving errors
                                )}
                            </Menu>


                        </AlignItems>
                    ) : null}
                </AlignItems>
                <AlignItems>
                    <EvaluateSearchFields
                        placeholder="Search campaign"
                        callback={searchCallback}
                        setSearchValue={setSearch}
                    />
                </AlignItems>
            </SpaceBetween>
            {renderModal({
                modalId,
                handleClose: () => setOpen(false),
                isOpen,
                checkedAssessmentsList,
                setSelectedRow,
                archiveAssessments
            })}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled(Column)`
    width: 100%;
    background-color: #f8f8f8;
    padding-bottom: 32px;
`;

const CustomSelect = styled(Select)`
    min-height: 48px;
    .MuiOutlinedInput-input {
        padding: 10px;
    }
    && {
        margin-right: 16px;
    }
`;

const StatisticsHeading = styled(BoldText)`
    color: #575757;
    letter-spacing: 0.5px;
    font-size: 15px;
    margin-bottom: 13px;
`;

export default MaturityOverviewAssessmentHeader;
