import React, { useState, useEffect, useContext } from 'react';
import { CustomTable } from '../../../components';
import CompanyAssessmentHeader from './CompanyAssessmentHeader';
import { assessmentHeadersRows } from '../../../utils';
import { useAssessmentsList, useAssessmentsStatus } from './AssessmentsHelpers';
import { AssessmentsTableRow } from './types';
import { createBrowserHistory } from 'history';
import { AssessmentsList, AvailableDataByClient } from '../../Layout/types';
import { useLazyQuery } from '@apollo/react-hooks';
import { SUBHEADER_DATA } from '../../Layout/LayoutHelpers';
import { UserStore } from '../../../context/user-store';

const CompanyAssessment: React.FC = () => {
    const {
        state: { client = {} }
    } = useContext(UserStore);
    const[search,setSearch] = useState<boolean>(false);
    const history = createBrowserHistory();
    const state: { assessmentsByClient: AssessmentsList[] } = (history.location
        .state as any) || { assessmentsByClient: [] };
    const [availableData, setAvailableData] = useState<AvailableDataByClient>({
        availableCampaignsByClient: [],
        assessmentsByClient: []
    });
    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [filteredAssessmentsList, setFilteredAssessmentsList] = useState<
        AssessmentsTableRow[]
    >([]);
    const [filterProcessStatus, setFilterProcessStatus] = useState('ALL');
    const [filterActiveStatus, setFilterActiveStatus] = useState('ALL');
    const [notFilteredData, setNotFilteredData] = useState<
        AssessmentsTableRow[]
    >([]);

    useEffect(() => {
        setAvailableData({
            availableCampaignsByClient: [],
            assessmentsByClient: state.assessmentsByClient
        });
    }, [state]);

    const [availableAssessmentsByClient] = useLazyQuery<AvailableDataByClient>(
        SUBHEADER_DATA,
        {
            onCompleted: res => {
                setAvailableData(res);
            }
        }
    );

    useEffect(() => {
        if (
            state.assessmentsByClient &&
            state.assessmentsByClient.length === 0
        ) {
            availableAssessmentsByClient({
                variables: { clientId: (client && client.id) || 0 }
            });
        }
    }, [state.assessmentsByClient]);

    const [assessmentsList] = useAssessmentsList({
        data: availableData.assessmentsByClient,
        filterActiveStatus,
        filterProcessStatus
    });
    const [checkedAssessmentsList] = useAssessmentsStatus(
        availableData.assessmentsByClient,
        selectedRow
    );

    useEffect(() => {
        setFilteredAssessmentsList(assessmentsList);
        setNotFilteredData(assessmentsList);
    }, [assessmentsList]);

    const selectedHandler = (selected: any) => {
        setSelectedRow(selected);
    };
    return (
        <div>
            <CustomTable
                search={search}
                selectedHandler={selectedHandler}
                header={
                    <CompanyAssessmentHeader
                        checkedAssessmentsList={checkedAssessmentsList}
                        filterProcessStatus={filterProcessStatus}
                        filterActiveStatus={filterActiveStatus}
                        setFilterProcessStatus={setFilterProcessStatus}
                        setFilterActiveStatus={setFilterActiveStatus}
                        setFilteredAssessmentsList={setFilteredAssessmentsList}
                        notFilteredData={notFilteredData}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        filteredAssessmentsList={filteredAssessmentsList}
                        setSearch={setSearch}
                    />
                }
                selectedRows={selectedRow}
                rows={filteredAssessmentsList || []}
                headCells={assessmentHeadersRows}
            />
        </div>
    );
};

export default CompanyAssessment;
