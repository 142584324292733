import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { ListItemText, Divider, IconButton,MenuItem  } from '@mui/material';

import { UserStore } from '../../../../../context/user-store';
import addIcon from '../../../../../assets/images/icon-add.svg';
import infoIcon from '../../../../../assets/images/info_icon_gray.svg';
import verticalDots from '../../../../../assets/images/icon-3-vertical-dots.svg';
import dropdownArrow from '../../../../../assets/images/icon-drop-down.svg';
import CustomButton from '../../../../../components/base/Buttons/Button';

import {
    Text,
    AlignItems,
    RedButton,
    ToggleSwitch,
    Menu,
    SpinnerWithOverlay
} from '../../../../../components';
import iconDownload from '../../../../../assets/images/download-btn.svg';

import {
    actionDropdownOptions,
    actionDropdownFunction
} from '../../ThreatAndRiskHelpers';

export type ToolbarTypes = {
    setAddTARModalOpen: any;
    activeTab?: number;
    isAddButtonDisabled: boolean;
    isLegendVisible: boolean;
    setIsLegendVisible: any;
    setIsModalOpen?: any;
    isGuidance: boolean;
    setGuidance: any;
    isOnlyKeyVisible: boolean;
    setOnlyKeyVisible: any;
    setAddModal: any;
    takeScreenshot: any;
    loading: boolean;
    campaignID?: string;
};

const Toolbar: React.FC<ToolbarTypes> = ({
    setAddTARModalOpen,
    activeTab,
    isLegendVisible,
    isAddButtonDisabled,
    setIsLegendVisible,
    setIsModalOpen,
    isGuidance,
    setGuidance,
    isOnlyKeyVisible,
    setOnlyKeyVisible,
    setAddModal,
    takeScreenshot,
    loading,
    campaignID
}) => {
    const { dispatch } = useContext(UserStore);

    const setCurrentTheme = (theme: string) => {
        dispatch({ type: 'SET_THEME', value: theme });
    };
    return (
        <RiskWrapper>
            <AlignItems>
                {setAddTARModalOpen && activeTab && (
                    <AddButton
                        disabled={isAddButtonDisabled}
                        onClick={() => {
                            setAddTARModalOpen(true);
                            setAddModal(true);
                        }}
                    >
                        <AlignItems>
                            <Icon src={addIcon} alt="+" aria-hidden />
                            <Text size="18px" color="#fff">
                                Add {activeTab === 2 ? 'risk' : 'threat'}
                            </Text>
                        </AlignItems>
                    </AddButton>
                )}

                {setAddTARModalOpen && activeTab && (
                    <Menu
                        top={'-2%'}
                        menuStyles={{
                            padding: 0,
                            marginLeft: 16,
                            marginRight: 16
                        }}
                        button={
                            <MenuButton
                                leftIcon={verticalDots}
                                text="Actions"
                                rightIcon={dropdownArrow}
                            />
                        }
                    >
                        <MenuItem
                            disabled
                            style={{
                                width: '196px',
                                height: '32.3px',
                                backgroundColor: '#ebebeb',
                                marginTop: '-16px'
                            }}>
                            <ListItemText
                                primary={<Text>Objective score theme</Text>}
                            />
                        </MenuItem>
                        {actionDropdownOptions(isAddButtonDisabled).map(
                            (el: string, index: number) => {
                                return el !== 'Divider' ? (
                                    <MenuItem
                                        // button
                                        key={index}
                                        onClick={() =>
                                            actionDropdownFunction(
                                                el,
                                                setCurrentTheme,
                                                campaignID
                                            )
                                        }
                                    >
                                        <ListItemText
                                            primary={<Text>{el}</Text>}
                                        />
                                    </MenuItem>
                                ) : (
                                    <Divider key={index} />
                                );
                            }
                        )}
                    </Menu>
                )}

                {setIsModalOpen && (
                    <InfoButton
                        variant="outlined"
                        onClick={() => setIsModalOpen(true)}
                    >
                        <AlignItems>
                            <Icon src={infoIcon} alt="info" />
                            <Text size="18px" color="#6E6E6E">
                                Calculation info
                            </Text>
                        </AlignItems>
                    </InfoButton>
                )}

                <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    {setGuidance && (
                        <RightSection>
                            <Text size="18px" margin="0 8px">
                                Guidance
                            </Text>
                            <ToggleSwitch
                                turnedOn={isGuidance}
                                setTurnOn={setGuidance}
                            />
                        </RightSection>
                    )}

                    {setOnlyKeyVisible && (
                        <RightSection>
                            <Text size="18px" margin="0 8px">
                                Only key objectives
                            </Text>
                            <ToggleSwitch
                                turnedOn={isOnlyKeyVisible}
                                setTurnOn={setOnlyKeyVisible}
                            />
                        </RightSection>
                    )}

                    {setIsLegendVisible && (
                        <RightSection>
                            <Text size="18px" margin="0 8px">
                                Legend
                            </Text>
                            <ToggleSwitch
                                turnedOn={isLegendVisible}
                                setTurnOn={setIsLegendVisible}
                            />
                        </RightSection>
                    )}

                    <RightSection>
                        {loading ? (
                           <SpinnerWithOverlay />
                        ) : (
                            <IconButton onClick={() => takeScreenshot()}>
                                <img src={iconDownload} alt="screenshot" />
                            </IconButton>
                        )}
                    </RightSection>
                </div>
            </AlignItems>
        </RiskWrapper>
    );
};

const RiskWrapper = styled('div')(({ theme }) => ({
    marginBottom: 24,
}));

const RightSection = styled(AlignItems)`
    display: inline-flex;
`;

const AddButton = styled(RedButton)`
    && {
        border-radius: 2px;
        padding: 6px 16px;
        min-height: 48px;
    }
`;

const InfoButton = styled(CustomButton)`
    && {
        border-radius: 2px;
        padding: 6px 16px;
        min-height: 48px;
        margin-right: 1rem;
    }
`;

const Icon = styled('img')`
    && {
        margin-right: 5px;
        height: 24px;
    }
`;

type MenuButtonProps = {
    leftIcon: string;
    text: string;
    rightIcon: string;
    disabled?: boolean;
};

const MenuButton: React.FC<MenuButtonProps> = ({
    leftIcon,
    text,
    rightIcon,
    disabled = false
}) => (
    <AlignItems
        style={{
            backgroundColor: disabled ? '#eb9690' : '#de3328',
            height: 48,
            padding: '0 16px',
            borderRadius: 2
        }}
    >
        <img src={leftIcon} alt={leftIcon} />
        <Text
            style={{
                color: '#fff',
                margin: 10,
                marginTop: 7,
                fontSize: 16,
                fontWeight: 500
            }}
        >
            {text}
        </Text>
        <img src={rightIcon} alt={rightIcon} />
    </AlignItems>
);

export default Toolbar;
