import React, { useContext } from 'react';
import Router from '../../../../routes/router';

import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import {
    AlignItems,
    BoldText,
    LinearProgress,
    Text
} from '../../../../components';
import greyArrowRight from '../../../../assets/images/icon-grey-arrow-right.svg';
import { UserStore } from '../../../../context/user-store';
import { Campaign as CampaignType } from '../../../../context/reducer';
import { Client as ClientType } from '../../../../context/reducer';

type CampaignProps = {
    companiesAreOpen: boolean;
    setCompaniesAreOpen: CallableFunction;
    campaignData: {
        title: string;
        progress: number;
    };
    campaignId: string;
    clientId: number;
};

export const Campaign: React.FC<CampaignProps> = ({
    campaignData,
    companiesAreOpen,
    setCompaniesAreOpen,
    campaignId,
    clientId
}) => {
    const { title, progress } = campaignData;
    const {
        state: { user, campaign, client },
        dispatch
    } = useContext(UserStore);
    const setCurrentCampaign = (currentCampaign: CampaignType) =>
        dispatch({ type: 'SET_CAMPAIGN', value: currentCampaign });
    const setCurrentClient = (currentClient: ClientType) =>
        dispatch({ type: 'SET_CLIENT', value: currentClient });
    const handleClick = () => {
        const choosenCampaign =
            (user &&
                user.campaigns &&
                user.campaigns.find(item => item.id === campaignId)) ||
            campaign;
        const choosenClient =
            (user &&
                user.clients &&
                user.clients.find(item => item.id === clientId)) ||
            client;
        Router.goToMaturityOverview(campaignId);
        setCurrentCampaign(choosenCampaign);
        setCurrentClient(choosenClient);
        setCompaniesAreOpen(!companiesAreOpen);
    };
    return (
        <CampaignWrapper>
            <CampaignTitleWrapper>
                <Text>{title}</Text>
            </CampaignTitleWrapper>

            <CampaignPercentWrapper>
                <LinearProgress progress={progress} />
            </CampaignPercentWrapper>

            <PercentText>{`${progress}%`}</PercentText>
            <IconButton
                aria-label='go to selected campaign'
                style={{
                    marginLeft: 10,
                    padding: '0px'
                }}
                onClick={() => handleClick()
            }>
                <CampaignImage
                    src={greyArrowRight}
                    alt="grey arrow right"
                    aria-hidden={true}
                />
            </IconButton>
        </CampaignWrapper>
    );
};

const CampaignImage = styled('img')``;

const CampaignWrapper = styled(AlignItems)`
    padding-left: 24px;
    padding-right: 12px;
    min-height: 40px;
    justify-content: space-between;
    &:hover {
        background-color: #d6d6d6;
    }
`;

const PercentText = styled(BoldText)`
    font-size: 12px;
    color: #535151;
`;

const CampaignTitleWrapper = styled('div')`
    flex-basis: 55%;
`;

const CampaignPercentWrapper = styled('div')`
    flex-basis: 25%;
`;
