import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';
import {
    H1Text,
    AlignItems,
    Column,
    Flex,
    SpaceBetween
} from '../../../../components';
import {
    assessmentsActiveTypes,
    assessmentsProcessTypes,
    renderArchiveButton
} from '../../../../utils';

import { AssessmentsTableRow, AssessmentsFilteredList } from '../types';
import { renderModal } from '../AssessmentsHelpers';
import EvaluateSearchFields from './EvaluateSearchFields';

const ARCHIVE_ASSESSMENT = gql`
    mutation archiveAssessments1($ids: [ID!]!) {
        archiveAssessments(ids: $ids) {
            id
            state {
                status
            }
            archivedDate
        }
    }
`;

type Props = {
    setFilteredAssessmentsList: any;
    notFilteredData: any;
    selectedRow: string[];
    setFilterProcessStatus: (data: string) => void;
    setFilterActiveStatus: (data: string) => void;
    filterProcessStatus: string;
    filterActiveStatus: string;
    filteredAssessmentsList: AssessmentsTableRow[];
    setSelectedRow: (data: string[]) => void;
    checkedAssessmentsList: AssessmentsFilteredList[];
    setSearch: any;
};

const CompanyAssessmentHeader: React.FC<Props> = ({
    setFilteredAssessmentsList,
    notFilteredData,
    selectedRow,
    setFilterProcessStatus,
    setFilterActiveStatus,
    filterProcessStatus,
    filterActiveStatus,
    setSelectedRow,
    checkedAssessmentsList,
    setSearch
}) => {
    // Dialog Box
    const [archiveAssessment] = useMutation(ARCHIVE_ASSESSMENT);
    const [isOpen, setOpen] = React.useState(false);
    const [modalId, setModalId] = useState('');

    const handleToggleModal = useCallback(
        ({ open, renderModalId }: { open: boolean; renderModalId: string }) => {
            setOpen(open);
            setModalId(renderModalId);
        },
        []
    );

    const handleProcessStatus = (event: any) => {
        const target = event.target as HTMLInputElement;
        setFilterProcessStatus(target.value);
        setSelectedRow([]);
    };

    const handleActiveStatus = (event: any) => {
        const target = event.target as HTMLInputElement;
        setFilterActiveStatus(target.value);
        setSelectedRow([]);
    };

    const searchCallback = (params: string) => {
        if (!params) {
            setFilteredAssessmentsList(notFilteredData);
            setSearch(false);
            return;
        }
        setSearch(true);
        const filteredList = notFilteredData.filter((assessment: any) => {
            return (
                assessment.name.props.children
                    .toLowerCase()
                    .indexOf(params.toLowerCase()) !== -1
            );
        });
        setFilteredAssessmentsList(filteredList);
    };
    return (
        <HeaderWrapper>
            <HeaderField style={{ width: '100%' }}>
                <H1Text>Assessments</H1Text>
            </HeaderField>
            <HeaderField>
                <AlignItems>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={filterActiveStatus}
                        onChange={(e) => handleActiveStatus(e)}
                        inputProps={{
                            'aria-label':'select assessment active status'
                        }}
                    >
                        {assessmentsActiveTypes.map(
                            ({ id, label }: { id: string; label: string }) => (
                                <option key={id} value={id}>
                                    {label}
                                </option>
                            )
                        )}
                    </CustomSelect>
                    <CustomSelect
                        native
                        variant="outlined"
                        value={filterProcessStatus}
                        onChange={(e) =>handleProcessStatus(e)}
                        inputProps={{
                            'aria-label':'select assessment status'
                        }}
                    >
                        {assessmentsProcessTypes.map(
                            ({ id, label }: { id: string; label: string }) => (
                                <option key={id} value={id}>
                                    {label}
                                </option>
                            )
                        )}
                    </CustomSelect>
                    {selectedRow.length ? (
                        <Flex>
                            {renderArchiveButton({
                                filterStatus: filterActiveStatus,
                                openModal: () =>
                                    handleToggleModal({
                                        open: true,
                                        renderModalId: 'archive'
                                    })
                            })}
                            {/*{renderCloseButton({*/}
                            {/*filterStatus: filterActiveStatus,*/}
                            {/*openModal: () =>*/}
                            {/*handleToggleModal({*/}
                            {/*open: true,*/}
                            {/*renderModalId: 'close'*/}
                            {/*})*/}
                            {/*})}*/}
                        </Flex>
                    ) : null}
                </AlignItems>
                <AlignItems>
                    <EvaluateSearchFields
                        placeholder="Search assessment"
                        callback={searchCallback}
                        setSearchValue={setSearch}
                    />
                </AlignItems>
            </HeaderField>
            {renderModal({
                modalId,
                handleClose: () => setOpen(false),
                isOpen,
                checkedAssessmentsList,
                setSelectedRow,
                archiveAssessments: archiveAssessment
            })}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled(Column)`
    width: 100%;
`;

const CustomSelect = styled(Select)`
    min-height: 48px;
    margin-right: 16px;
    .MuiOutlinedInput-input {
        padding: 10px;
        padding-right: 84px;
    }
`;

const HeaderField = styled(SpaceBetween)`
    margin: 0 20px;
`;

export default CompanyAssessmentHeader;
