import React from 'react';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';
import BoldText from '../../base/Text/BoldText';
import PopUpHeader from '../../PopUps/PopUpHeader/index';
import Text from '../../base/Text/Text';

type FormProps = {
    values: any;
    handleSubmit: (e: React.FormEvent<any>) => void;
    handleChange: (e: React.ChangeEvent) => void;
};

type Props = {
    handleClose: () => void;
    initialValues: any;
    render: (data: FormProps) => React.ReactNode;
    mainText: string;
    descriptionText: string;
    title: string;
};

const DescopePopup: React.FC<Props> = ({
    handleClose,
    initialValues,
    render,
    mainText,
    descriptionText,
    title
}) => {
    return (
        <>
            <PopUpHeader handleClose={handleClose} title={title} />
            <ModalBody>
                <BoldText margin={'0 0 20px 0'} fontSize={'16px'}>
                    {mainText}
                </BoldText>
                <Text>{descriptionText}</Text>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(_values, { setSubmitting }) => {
                        setSubmitting(false);
                    }}
                >
                    {({ handleChange, handleSubmit, values }) =>
                        render({ handleChange, handleSubmit, values })
                    }
                </Formik>
            </ModalBody>
        </>
    );
};

const ModalBody = styled('div')`
    padding: 25px;
`;

export default DescopePopup;
