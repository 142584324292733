import { FrameworkSettingsStatus } from './constants';

export interface FrameworkSettings {
    id?: number;
    name: string;
    status: boolean;
}

export function isCompensatingControlEnable (frameworkSettings: FrameworkSettings[]) {
    if(frameworkSettings !== undefined && frameworkSettings.length > 0) {
        const setting =  frameworkSettings.find(s => s.name === FrameworkSettingsStatus.COMPENSATING_CONTROL && s.status === true);
        if(setting === undefined) {
            return false;
        }
        return true;
    }
    return false;
}