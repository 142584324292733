import React, { useState, useEffect } from 'react';
import {
    capabilitiesAverage,
    dataFormatter,
    StateDomainsType,
    StateCapabilities
} from '../../../../../Evaluate/MaturityOverview/MaturityOverviewTabs/MaturityOverviewTabsHelpers';
import { ComparisonGraph, Dataset } from '../../../types';
import {
    Series,
    Legend
} from '../../../../../../components/Charts/BarGraph/types';
import Bargraph from '../../../../../../components/Charts/BarGraph/BarGraph';
import { colorForSeries } from '../../../../../../components/Charts/BarGraph/data';
import { roundGraphScore, roundScore,roundToTwoDecimailPlaces } from '../../../../../../utils';
type Props = {
    data: ComparisonGraph;
    campaignsName: string[];
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    pointOneDecimalEnable: boolean;
    userfilteredData?: any;

};
/**
 * @todo ReadOnly Implementation for ComparisonGrapgh using defaults so that we can use the re-usable capabilitiesAverage
 */
const ComparisonGrapgh: React.FC<Props> = ({ data, campaignsName, readOnlyEnabled = false, isReadOnly = false, pointOneDecimalEnable = false,userfilteredData }) => {
    const datasetLine1: Dataset[] = [];
    const datasetLine2: Dataset[] = [];
    let series: Series[] = [];
    let legend: Legend[] = [];
    const xData: string[] = [];
    const [objectivesLabelsList, setObjectivesLabelsList] = useState<string[]>([]);
    const [seriesData, setSeriesData] = useState<Series[]>([]);
    const [isColorChange, setColorChange] = useState<boolean>(false);
    const [domainNameForLegend, setDomainNameForLegend] = useState<Legend[]>([]);
    const subtitle = ['Comparison'];
    useEffect(() => {
        compareGraph();
    }, [data]);
    useEffect(() => {
        colorForSeries[0] = '#D04A02';
        colorForSeries[1] = '#EB8C00';
    }, [isColorChange]);
    const capabilitiesAverageScores1 = capabilitiesAverage(data.campaign1, readOnlyEnabled, isReadOnly,userfilteredData);
    const capabilitiesAverageScores2 = capabilitiesAverage(data.campaign2, readOnlyEnabled, isReadOnly,userfilteredData);
    const FormattedData1 = dataFormatter(capabilitiesAverageScores1);
    const FormattedData2 = dataFormatter(capabilitiesAverageScores2);
    let coorX = 0;
    FormattedData1.forEach((dmn: StateDomainsType) => {
        dmn.capabilities.forEach((cp: StateCapabilities) => {
            datasetLine1.push({
                value: [coorX,cp.moderatedScore? roundToTwoDecimailPlaces(cp.moderatedScore): cp.moderatedScore, cp.name],
                itemStyle: {
                    color: '#fdb52b'
                }
            });
            xData.push(cp.name);
            coorX++;
        });
    });
    coorX = 0;
    FormattedData2.forEach((dmn: StateDomainsType) => {
        dmn.capabilities.forEach((cp: StateCapabilities) => {
            datasetLine2.push({
                value: [coorX,cp.moderatedScore? roundToTwoDecimailPlaces(cp.moderatedScore): cp.moderatedScore , cp.name],
                itemStyle: {
                    color: '#818181'
                }
            });
            coorX++;
        });
    });
    colorForSeries[0] = '#fdb52b';
    colorForSeries[1] = '#818181';
    const compareGraph = () => {
        series = [];
        legend = [];
        const objectivesLabel =
            data &&
            data.campaign1[0] &&
            data.campaign1[0].assessments &&
            data.campaign1[0].assessments[0].framework.definition.scores;
        setObjectivesLabelsList(objectivesLabel);
        legend.push(
            {
                name: campaignsName[0],
                textStyle: { color: '#fdb52b' }
            },
            { name: campaignsName[1], textStyle: { color: '#818181' } }
        );
        setDomainNameForLegend(legend);
        series.push({
            name: campaignsName[0],
            type: 'bar',
            data: datasetLine1,
            symbolSize: 10,
            zlevel: 999
        });
        series.push({
            name: campaignsName[1],
            type: 'bar',
            data: datasetLine2,
            symbolSize: 10,
            zlevel: 999
        });
        setSeriesData(series);
        setColorChange(!isColorChange);
    };
    return (
        <div>
            <Bargraph
                seriesData={seriesData}
                title={''}
                subtitle={subtitle}
                objectivesLabelsList={objectivesLabelsList}
                legendData={domainNameForLegend}
                barToLine
                ScroolValue={100}
                xAxisData={xData}
                isCapabilityByDomain={false}
            />
        </div>
    );
};
export default ComparisonGrapgh;
