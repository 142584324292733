import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customModalStylesMangeMembersDialog } from '../../../components/PopUps/constants';
import tickIcon from '../../../assets/images/icons8-tick.svg';
import alertIcon from '../../../assets/images/alert-icon.svg';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import {
    BoldText,
    Text,
    RedButton
} from '../../../components';
import { styled } from '@mui/material/styles';
import { 
    READONLY_MEMBER_ALERT_MESSAGE,
    READONLY_MEMBER_REMOVE_MESSAGE,
    READONLY_MEMBER_ADD_MESSAGE,
    FULL_MEMBER_ADD_MESSAGE,
    FUll_MEMBER_REMOVE_MESSAGE
 } from '../../../utils';
import Button from '../../../components/base/Buttons/Button';
import {  ListItem,Checkbox,ListItemText } from '@mui/material';
import { PermissionTypeEnum } from '../../../utils';
import { PermissionObject } from '../types';

type Props = {
    isOpenDialog: boolean;
    setIsOpenDialog: CallableFunction;
    addSelectedMembersPermission: CallableFunction;
    removeSelectedMembersPermission: CallableFunction;
    actionType: 'Remove' | 'Add';
    removePermissionType: 'Full' | 'Read-only';
    userList: any[];
    modifiedSelectData: any[];
    permissionStructureData: PermissionObject[];
    selectedRadioValue: '' | 'Full' | 'Read-only';
    setCompanyMemberItems: CallableFunction
};

const CampaignPermissionModalDialog: React.FC<Props> = ({
    isOpenDialog,
    setIsOpenDialog,
    addSelectedMembersPermission,
    removeSelectedMembersPermission,
    actionType,
    removePermissionType,
    userList,
    modifiedSelectData,
    permissionStructureData,
    selectedRadioValue,
    setCompanyMemberItems
}) =>{
    const [filteredUserList, setFilteredUserList] = useState<any[]>([]);
    const [isAlertFlag, setIsAlertFlag] = useState<boolean>(false);
    
    useEffect(() => {
        const filteredItems = userList.filter((item: any) => {
            if (item.checked) {
                const fetchedPermissionStructureData = permissionStructureData.filter((permissionDataItem: any) =>
                    permissionDataItem.userId === item.value && permissionDataItem.permission === PermissionTypeEnum.FULL_MEMBER_ACCESS
                );
                // TODO: Not Needed the Popup
                // if(fetchedPermissionStructureData.length > 0 && selectedRadioValue === 'Read-only') {
                //     const fetchedCampaignEntityIds = fetchedPermissionStructureData.map((permissionDataItem: any) => permissionDataItem.campaignEntityId);

                //     const entityDetails = modifiedSelectData.filter((modifiedSelectDataItem: any) => {
                //         return fetchedCampaignEntityIds.find((campaignEntityId: any) => modifiedSelectDataItem.value === campaignEntityId);
                //     }).map((modifiedSelectDataItem: any) => modifiedSelectDataItem.name);

                //     item.access = entityDetails;
                //     setIsAlertFlag(true);
                // }
                return { ...item, checked: !item.checked };
            }
        });
        setFilteredUserList(filteredItems);
    }, [userList, selectedRadioValue]);

    const handleUserList = () => {
        const filteredItems = filteredUserList.filter((item: any) => {
            if (item.checked) {
                return { ...item, checked: !item.checked };
            }
        });
        setFilteredUserList(filteredItems);
    };

    const resetDialog = () => {
        setIsOpenDialog(false);
        setFilteredUserList([]);
        setIsAlertFlag(false);
    };

    const confirmChanges = (actionType?: 'Add' | 'Remove') => {
        if (actionType) {
            actionType === 'Add' ? addSelectedMembersPermission() : removeSelectedMembersPermission(removePermissionType);
            setIsAlertFlag(false);
            resetDialog();
            return;
        }
        setIsAlertFlag(!isAlertFlag);
        handleUserList();
    };

    const handleCheckboxChange = (item: number) =>{
        setCompanyMemberItems((prevs: any) => prevs.map((memberData: any) =>
            memberData.value === item ? { ...memberData, checked: !memberData.checked } : memberData));

        setFilteredUserList((prevs: any) => prevs.map((memberData: any) =>
            memberData.value === item ? { ...memberData, checked: !memberData.checked } : memberData));
    };

    return(
        <>
            <Modal
                isOpen={isOpenDialog}
                shouldCloseOnOverlayClick
                ariaHideApp={false}
                style={customModalStylesMangeMembersDialog}
                onRequestClose={() => {
                    setIsOpenDialog(false);
                }}>
                <img src={ actionType === 'Add' ? (selectedRadioValue ===  'Read-only' && isAlertFlag ? alertIcon : tickIcon ): deleteIcon } alt={actionType} aria-hidden={true} style={{ height:'50px', padding:'15px' }}/>
                <HeaderText >
                    { actionType === 'Add' ? (selectedRadioValue ===  'Read-only' && isAlertFlag ? 'Alert' : 'Access granted' ) : 'Access Removed'}
                </HeaderText>
                <HeaderMessage> 
                    { actionType === 'Add' ? (selectedRadioValue ===  'Read-only' ? (isAlertFlag ? READONLY_MEMBER_ALERT_MESSAGE : READONLY_MEMBER_ADD_MESSAGE) : FULL_MEMBER_ADD_MESSAGE ) : 
                    removePermissionType === 'Full' ?  FUll_MEMBER_REMOVE_MESSAGE : READONLY_MEMBER_REMOVE_MESSAGE }
                </HeaderMessage>
                <ModalBody >
                    <ContentBox>
                        {filteredUserList && filteredUserList.map((item: any, key: number) => (
                            selectedRadioValue === 'Read-only' && actionType === 'Add' && isAlertFlag ?
                                <ListTag key={`${item.value}-${key}`}>
                                    <Checkbox color="secondary"
                                    checked={item.checked}
                                    onChange={() =>  handleCheckboxChange(item.value)} 
                                    />
                                    <ListItemText primary={`${item.firstName} ${item.lastName} ${item.access ? ` - Full access to ${item.access} ` : ''}` } />
                                </ListTag>
                                :
                                <ListTag key={`${item.value}-${key}`}>
                                    <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                                </ListTag>
                        ))}
                    </ContentBox>
                    {isAlertFlag ?
                    <div><HeaderText> Do you wish to continue?</HeaderText>
                     <ButtonContainer> 
                            <RedButton onClick={() => {
                                confirmChanges();
                            }}>
                                <Text  style={{color: '#fff', margin: 10,fontSize: 16,fontWeight: 500}}>Confirm changes</Text>
                            </RedButton>
                            <CustomButton onClick={resetDialog}>
                                <ButtonText color="#de3328">Cancel all changes</ButtonText>
                            </CustomButton> 
                        </ButtonContainer> 
                        </div>
                        
                        :
                        <ButtonContainer> 
                            <RedButton onClick={() => {
                                confirmChanges(actionType);
                            }}>
                                <Text  style={{color: '#fff', margin: 10,fontSize: 16,fontWeight: 500}}> Continue</Text>
                            </RedButton>
                            <CustomButton onClick={resetDialog}>
                                <ButtonText color="#de3328">Undo</ButtonText>
                            </CustomButton>
                        </ButtonContainer> 
                    }
                </ModalBody>
            </Modal>
        </>
    );

};
const HeaderText = styled(BoldText)`
    text-align: center;
    padding: 5px 15px;
`;
const HeaderMessage = styled('p')`
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 14px;
    color: #2d2d2d;
    margin: 8px;
    padding: 5px 15px;
`;
const ModalBody = styled('div')`
    padding: 15px;
    flex-grow: 1;
`;
const ContentBox = styled('div')`
    padding: 5px;
    border: 1px solid #151515;
    min-height: 50px;
    border-radius: 4px;

`;
const CustomButton = styled(Button)`
    && {
        border: solid 1px #de3328;
        padding: 0 21px 0 38px;
        margin-left: 10px;
        min-height: 48px;
        &:disabled {
            opacity: 0.5;
        }
        &:hover {
            opacity: 0.9;
        }
    }
`;
const ButtonText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
`;
const ButtonContainer = styled('div')`
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
`;
const ListTag = styled(ListItem)`
    .MuiTypography-root {
        font-size: 14px;
        line-height: 1rem;
    }
    .MuiListItem-root {
        padding: 0px 0px 0px 5px;
    }
    padding: 0px 0px 0px 5px;
`;

export default CampaignPermissionModalDialog;