import React, {
    useEffect,
    useState,
    useRef,
    useContext
} from 'react';
import { useQuery } from '@apollo/react-hooks';

import Legend from '../Legend';
import MaturityTable from '../MaturityOverviewTabs/MaturityTable/MaturityTable';
import MaturityOverviewHeader from '../MaturityOverviewHeader';
import { Score, FrameworkSetting } from '../types';
import {
    CampaignGroupAssessment,
    DomainsData,
    MaturityOverviewFrameworkDomains,
    CampaignData
} from './types';
import { isReadOnlyEnabled, roundFloor } from '../../../../utils';
import { isTargetScore, isPointOneDecimalEnable } from '../../../../utils/global-settings';
import { Column, Toast } from '../../../../components';
import { MaturityOverviewPieChart, MaturityOverviewBarChart, MaturityOverviewBoxChart } from '../MaturityOverviewTabs';
import { domainsAverage } from '../MaturityOverviewTabs/MaturityOverviewTabsHelpers';
import { CampaignsData } from '../../../Analyse/ThreatAndRisk/types';
import { UserStore } from '../../../../context/user-store';
import { CAMPAIGNS } from '../../../Analyse/ThreatAndRisk/ThreatAndRiskHelpers';
import { campaignAnalysisValues } from '../MaturityOverviewHeader/MaturityOverviewHeaderHelpers';
import { gql } from 'apollo-boost';
import { isCompensatingControlEnable } from '../../../../utils/framework-settings';
import SearchEntitiesField from './SearchEntitiesField';
export const CAMPAIGN_FRAMEWORK = gql`
    query frameworkByCampaignComponentSupport($campaignId: ID!) {
        frameworkByCampaign(campaignId: $campaignId) {
            definition {
                hasObjectiveComponents
            }
        }
    }
`;
export const CAMPAIGN_FRAMEWORK_SETTINGS = gql`
    query frameworkSettingsByCampaign($campaignId: ID!) {
        frameworkSettingsByCampaign(campaignId: $campaignId) {
            name
            status
        }
    }
`;


const renderMaturityOverviewTabs = ({
    tab,
    selectedHandler,
    campaignAssessmentsData,
    domains,
    campaignId,
    assessmentsAverageScoresList,
    maturityContainer,
    selectedRow,
    campaignTitle,
    companyName,
    showTargetScore,
    showCompensationControl,
    targetScoreEnabled,
    compensatingControlEnabled,
    readOnlyEnabled,
    isReadOnly,
    campaignComplete,
    pointOneDecimalEnable,
    isObjectiveComponentExist,
    assessmentsByCampaignWithGroupPageOffset,
    assessmentsByCampaignWithGroupPageInfo,
    setAssessmentsByCampaignWithGroupPageOffset
}: {
    tab: number;
    selectedHandler: any;
    campaignId: string;
    domains: MaturityOverviewFrameworkDomains[];
    campaignAssessmentsData: CampaignGroupAssessment[];
    assessmentsAverageScoresList: Score[];
    maturityContainer: React.MutableRefObject<HTMLDivElement>;
    selectedRow: string[];
    campaignTitle: string;
    companyName?: string;
    showTargetScore: boolean;
    showCompensationControl: boolean;
    targetScoreEnabled: boolean;
    compensatingControlEnabled: boolean;
    readOnlyEnabled?: boolean;
    isReadOnly?: boolean;
    campaignComplete?: boolean;
    pointOneDecimalEnable: boolean;
    isObjectiveComponentExist: boolean;
    assessmentsByCampaignWithGroupPageOffset: number;
    assessmentsByCampaignWithGroupPageInfo: {
        count: number;
        skip: number;
        take: number;
        total: number;
    };
    setAssessmentsByCampaignWithGroupPageOffset: any;
    showCapabilityStatus?: boolean;
}) => {
    const store = new Map();
    store.set(
        1,
        <MaturityTable
            selectedHandler={selectedHandler}
            rows={campaignAssessmentsData || []}
            customHeadCells={domains}
            campaignId={campaignId || ''}
            maturityContainer={maturityContainer}
            selectedRow={selectedRow}
            showTargetScore={showTargetScore}
            showCompensationControl={showCompensationControl}
            compensatingControlEnabled={compensatingControlEnabled}
            targetScoreEnabled={targetScoreEnabled}
            isReadOnly={isReadOnly}
            pointOneDecimalEnable={pointOneDecimalEnable}
            isObjectiveComponentExist={isObjectiveComponentExist}
            assessmentsByCampaignWithGroupPageOffset={assessmentsByCampaignWithGroupPageOffset}
            assessmentsByCampaignWithGroupPageInfo={assessmentsByCampaignWithGroupPageInfo}
            setAssessmentsByCampaignWithGroupPageOffset={setAssessmentsByCampaignWithGroupPageOffset}
        />
    );
    store.set(
        2,
        <MaturityOverviewBarChart
            assessmentsData={campaignAssessmentsData}
            companyName={companyName || ''}
            showTargetScore={showTargetScore}
            readOnlyEnabled={readOnlyEnabled}
            isReadOnly={isReadOnly}
            pointOneDecimalEnable={pointOneDecimalEnable}
        />
    );
    store.set(
        3,
        <MaturityOverviewBoxChart
            assessmentsData={campaignAssessmentsData}
            companyName={companyName || ''}
            campaignTitle={campaignTitle}
            readOnlyEnabled={readOnlyEnabled}
            isReadOnly={isReadOnly}
            campaignComplete={campaignComplete}
            pointOneDecimalEnable={pointOneDecimalEnable}
        />
    );
    store.set(
        4,
        <MaturityOverviewPieChart
            sectionsList={assessmentsAverageScoresList}
            campaignTitle={campaignTitle}
            companyName={companyName || ''}
            showTargetScore={showTargetScore}
            assessmentsData={campaignAssessmentsData}
            pointOneDecimalEnable={pointOneDecimalEnable}
        />
    );

    return store.get(tab) || null;
};

const MaturityOverview: React.FC<{
    setSearch: any;
    maturityOverviewData: CampaignGroupAssessment[];
    campaign: CampaignData;
    assessmentsByCampaignWithGroupPageOffset: number;
    assessmentsByCampaignWithGroupPageInfo: {
        count: number;
        skip: number;
        take: number;
        total: number;
    };
    setAssessmentsByCampaignWithGroupPageOffset: any;
}> = ({
    setSearch,
    maturityOverviewData,
    campaign,
    assessmentsByCampaignWithGroupPageOffset,
    assessmentsByCampaignWithGroupPageInfo,
    setAssessmentsByCampaignWithGroupPageOffset
}) => {
    const campaignId = campaign.id;
    const campaignTitle = campaign.title;
    const {
        state: { client, globalSettings }
    } = useContext(UserStore);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);
    // tslint:disable-next-line: no-redundant-boolean
    const pointOneDecimalEnable = !isPointOneDecimalEnable(globalSettings) ? false : campaign.pointOneDecimalEnable || false;

    const { data } = useQuery<CampaignsData>(CAMPAIGNS, {
        variables: {
            clientId: (client && client.id) || 1,
            supportsThreatAndRisk: true
        }
    });

    const { loading, data: frameworkData } = useQuery(CAMPAIGN_FRAMEWORK, {
        variables: {
            campaignId
        }
    });

    const { data: frameworkSettings } = useQuery(
        CAMPAIGN_FRAMEWORK_SETTINGS,
        {
            variables: {
                campaignId
            }
        },
    );

    const isObjectiveComponentExist=
        frameworkData &&
        frameworkData.frameworkByCampaign &&
        frameworkData.frameworkByCampaign.definition&&
        frameworkData.frameworkByCampaign.definition.hasObjectiveComponents||false;
    const availableForThreatAndRisk =
        data &&
        data.availableCampaignsByClient &&
        data.availableCampaignsByClient.find(cmp => cmp.id === campaignId);

    const ind = campaignAnalysisValues.findIndex(
        cmp => cmp === 'View Threat and Risk Analysis'
    );
    if (
        !availableForThreatAndRisk &&
        campaignAnalysisValues.includes('View Threat and Risk Analysis')
    ) {
        campaignAnalysisValues.splice(ind, 1);
    } else if (!!availableForThreatAndRisk && ind === -1) {
        campaignAnalysisValues.unshift('View Threat and Risk Analysis');
    }
    const [
        assessmentsAverageScoresList,
        setAssessmentsAverageScoresList
    ] = useState<Score[]>([]);
    const [selectedRow, setSelectedRow] = useState<string[]>([]);
    const [tab, setTab] = useState(1);
    const [domains, setDomains] = useState<MaturityOverviewFrameworkDomains[]>(
        []
    );
    const [notFilteredData, setNotFilteredData] = useState<
        CampaignGroupAssessment[]
    >([]);
    const [campaignAssessmentsData, setCampaignAssessmentsData] = useState<
        CampaignGroupAssessment[]
    >([]);
    const [closeCampaignSnackbar, setCloseCampaignSnackbar] = useState(false);

    const [isLegendVisible, setIsLegendVisible] = useState(() => {
        const defaultIsLegendVisible = localStorage.getItem('isLegendVisible') || 'true';
        return Boolean(defaultIsLegendVisible === 'true');
    });
    const [showTargetScore, setShowTargetScore] = useState<boolean>(false);
    const [showCompensationControl, setShowCompensationControl] = useState<boolean>(false);
    const [compensatingControlEnabled, setCompensatingControlEnabled] = useState<boolean>(false);

    useEffect(() => {
        localStorage.setItem('isLegendVisible', new Boolean(isLegendVisible).toString());
    }, [isLegendVisible]);


    const maturityContainer = useRef<HTMLDivElement>(
        document.createElement('div')
    );
    const selectedHandler = (selected: string[]) => {
        setSelectedRow(selected);
    };
    const campaignIsClosed = campaign.closedAt !== null;
    const [searchValue, setSearchValue] = useState<string>('');

    const searchCallback = (params: string) => {
        if (!params) {
            setAssessmentsByCampaignWithGroupPageOffset(0);
            setSearch('');
        } else {
            setAssessmentsByCampaignWithGroupPageOffset(0);
            setSearch(params);
        }
    };
    useEffect(() => {
        setCampaignAssessmentsData(maturityOverviewData || []);
        setNotFilteredData(maturityOverviewData || []);
        setDomains(
            (maturityOverviewData &&
                maturityOverviewData[0] &&
                maturityOverviewData[0].assessments &&
                maturityOverviewData[0].assessments[0] &&
                maturityOverviewData[0].assessments[0].framework &&
                maturityOverviewData[0].assessments[0].framework.definition &&
                maturityOverviewData[0].assessments[0].framework.definition
                    .domains) ||
                []
        );
    }, [maturityOverviewData]);

    useEffect(() => {
        if (maturityOverviewData.length) {
            const domainsAverageScores = domainsAverage(maturityOverviewData, readOnlyEnabled, campaign.readOnly, true);
            const averageScores: Score[] = [];
            domainsAverageScores.forEach((dmn: DomainsData) => {
                averageScores.push({
                    name: dmn.Domain,
                    score:
                        dmn.Score !== 0 && dmn.Score ? roundFloor(dmn.Score, pointOneDecimalEnable)
                            : 0,
                    targetScore: dmn.averageTargetScore !==0 && dmn.averageTargetScore ? roundFloor(dmn.averageTargetScore, pointOneDecimalEnable): 0,
                    capabilities: (dmn.Capabilities && dmn.Capabilities.length > 0) ? dmn.Capabilities.map(c => ({
                        score: c.Score !== 0 && c.Score ? roundFloor(c.Score, pointOneDecimalEnable) : 0,
                        name: c.Capabilities,
                        inScope:c.inScope,
                        targetScore: c.targetScores[0] !== 0 && c.targetScores[0] ?
                            roundFloor(c.targetScores[0], pointOneDecimalEnable) : 0
                    })): []
                });
            });
            setAssessmentsAverageScoresList(averageScores);
        }
    }, [maturityOverviewData]);

    useEffect(() => {
        if (frameworkSettings !== undefined &&
            frameworkSettings.frameworkSettingsByCampaign !== undefined &&
            frameworkSettings.frameworkSettingsByCampaign.length > 0) {
            setCompensatingControlEnabled(isCompensatingControlEnable(frameworkSettings.frameworkSettingsByCampaign));
        }
    }, [frameworkSettings]);

    useEffect(() => {
        if(tab!==1){
            setSearchValue('');
            searchCallback('');
        }
    }, [tab]);

    return (
        <Column style={{ justifyContent: 'space-between' }}>
             {/*TODO targetScoreEnabled will get from campaign data*/}
            <MaturityOverviewHeader
                campaignData={campaign}
                notFilteredData={notFilteredData}
                setCampaignAssessmentsData={setCampaignAssessmentsData}
                selectedRow={selectedRow}
                setIsLegendVisible={setIsLegendVisible}
                isLegendVisible={isLegendVisible}
                campaignId={campaignId}
                setTab={setTab}
                container={maturityContainer && maturityContainer.current}
                campaignIsClosed={campaignIsClosed}
                setSelectedRow={setSelectedRow}
                targetScoreEnabled={!isTargetScore(globalSettings) ? false : campaign.targetScoreEnabled||false}
                setShowTargetScore={setShowTargetScore}
                showTargetScore={showTargetScore}
                showCompensationControl={showCompensationControl}
                setShowCompensationControl={setShowCompensationControl}
                compensatingControlEnabled={compensatingControlEnabled}
                assessmentsByCampaignWithGroupPageOffset={assessmentsByCampaignWithGroupPageOffset}
            />
            {tab === 1 ?
            <SearchEntitiesField placeholder="Search campaign" callback={searchCallback} setSearchValue={setSearchValue} searchValue={searchValue}/>
            : <></>}
            {renderMaturityOverviewTabs({
                tab,
                selectedHandler,
                selectedRow,
                campaignAssessmentsData,
                domains,
                campaignId,
                assessmentsAverageScoresList,
                maturityContainer,
                campaignTitle,
                companyName: client.name,
                showTargetScore,
                showCompensationControl,
                // tslint:disable-next-line: no-redundant-boolean
                targetScoreEnabled: !isTargetScore(globalSettings) ? false : campaign.targetScoreEnabled || false,
                compensatingControlEnabled,
                readOnlyEnabled,
                isReadOnly: campaign.readOnly,
                campaignComplete: campaign.assessmentsCompletedCnt === campaign.assessmentsCnt,
                pointOneDecimalEnable,
                isObjectiveComponentExist: isObjectiveComponentExist,
                assessmentsByCampaignWithGroupPageOffset,
                assessmentsByCampaignWithGroupPageInfo,
                setAssessmentsByCampaignWithGroupPageOffset
            })}
            {(isLegendVisible && !loading) && (
                <Legend
                    setIsLegendVisible={setIsLegendVisible}
                    isTargetScoring={showTargetScore}
                    isReadOnly={campaign.readOnly}
                    isObjectiveComponentExist={isObjectiveComponentExist}
                />
            )}
            <Toast
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={closeCampaignSnackbar}
                autoHideDuration={4000}
                icon="check"
                title="Campaign successfully closed"
                closeToast={setCloseCampaignSnackbar}
            />
        </Column>
    );
};
export default MaturityOverview;
