import React from 'react';
import { styled } from '@mui/material/styles';
import {
    RedButton,
    DescopePopup,
    JustifyContentEnd,
    TextArea,
    Text
} from '../../../../components';
import { DescopedObjective, DescopedComponent, ObjectiveComponentWithDescriptions, MaturityMatch } from '../types';
import { SavingState } from '../../../../utils/types';
import Router from '../../../../routes/router';
import { NIST_FRAMEWORK_NAMES } from '../../../../utils';

export const ValueLabelComponent = (props: any) => {
    const { children, value } = props;
    return (
        <span>
            <span>
                {children}
                <ModeratedScoreContainer left={children.props.style.left}>
                    <span
                        style={{
                            fontWeight: 'bold',
                            fontSize: '18px'
                        }}
                    >
                        {value}
                    </span>
                </ModeratedScoreContainer>
            </span>
        </span>
    );
};

export const tabsList = [
    { label: 'Score', id: 1 },
    { label: 'Notes', id: 2 }
];

const ModeratedScoreContainer = styled('span')<{ left: string }>`
    position: absolute;
    top: -30px;
    margin-left: -43px;
    width: 100px;
    color: #575757;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: ${props => (props.left ? props.left : 0)};
`;

export const objectiveScoreChange = ({
    updateObjectiveScore,
    assessmentId,
    domainId,
    capabilityId,
    objectiveId,
    componentId,
    compensatingControlEnable,
    score
}: {
    updateObjectiveScore: (data: object) => Promise<object>;
    assessmentId: string;
    domainId: string;
    capabilityId: string;
    objectiveId: string;
    componentId?: string;
    compensatingControlEnable?: boolean;
    score: number | null;
}) => {
    updateObjectiveScore({
        variables: {
            data: {
                assessmentId,
                domainId,
                capabilityId,
                objectiveId,
                componentId,
                compensatingControlEnable,
                score
            }
        }
    })
        .then(() => {})
        .catch(() => {});
};

export const objectiveNotesChange = ({
    updateObjectiveNotes,
    assessmentId,
    domainId,
    capabilityId,
    objectiveId,
    callbackNotes,
    setLocaleMarkdownValue
}: {
    updateObjectiveNotes: (data: object) => Promise<object>;
    assessmentId: string;
    domainId: string;
    capabilityId: string;
    objectiveId: string;
    callbackNotes: string;
    setLocaleMarkdownValue?: () => void;
}) => {
    const notes = callbackNotes;
   return updateObjectiveNotes({
        variables: {
            data: {
                assessmentId,
                domainId,
                capabilityId,
                objectiveId,
                notes
            }
        }
    })
        .then(() => {
            setLocaleMarkdownValue && setLocaleMarkdownValue();
            return SavingState.Saved;
        })
        .catch(() => {
            return SavingState.Failed;

        });
};

const handleDescopeObjectives = ({
        match,
        currentAssessmentId,
        currentDomainId,
        currentCapabilityId,
        currentObjectiveId,
        values,
        handleClose,
        descopeObjectivesScope,
        descopeComponentsScope,
        inScope,
        selectedComponents,
        setIsReasonExist,
        setIsWeightedFormOpen
    }: {
        descopeObjectivesScope?: (data: object) => Promise<object>;
        descopeComponentsScope?: (data: object) => Promise<object>;
        match: any;
        currentAssessmentId: string;
        currentDomainId: string;
        currentCapabilityId: string;
        currentObjectiveId?: string;
        values: {
            reason: string;
            listIds: DescopedObjective[];
        };
        handleClose: () => void;
        inScope: boolean;
        selectedComponents?: string;
        setIsReasonExist: any;
        setIsWeightedFormOpen?: any;
    }) => {
    setIsReasonExist(false);
    if (!values.reason && !inScope) {
        setIsReasonExist(true);
        return;
    }
    if (currentObjectiveId && descopeComponentsScope) {
        descopeComponentsScope({
            variables: {
                data: {
                    assessmentId: currentAssessmentId,
                    domainId: currentDomainId,
                    capabilityId: currentCapabilityId,
                    objectiveId: currentObjectiveId,
                    componentIds: values.listIds.map((item: DescopedObjective) => {
                        return item.id;
                    }),
                    updateScopeReason: values.reason,
                    inScope
                }
            }
        }).then((res: any) => {

        const componentsData = res.data.updateAssessmentComponentsScope.objectives
            .find((a: any) => a.id === currentObjectiveId)
            .components.every((component: any) => {
                return !component.inScope;
            });

        if(componentsData && setIsWeightedFormOpen){
            setIsWeightedFormOpen(false);
        }
            handleClose();
        })
        .catch(err => {
            console.log('descope err', err);
        });
    } else if (descopeObjectivesScope) {
        descopeObjectivesScope({
            variables: {
                data: {
                    assessmentId: currentAssessmentId,
                    domainId: currentDomainId,
                    capabilityId: currentCapabilityId,
                    objectiveIds: values.listIds.map((item: DescopedObjective) => {
                        return item.id;
                    }),
                    updateScopeReason: values.reason,
                    inScope
                }
            }
        }).then((response: any) => {
            if(!response.data.updateAssessmentObjectivesScope.inScope)
            {
                Router.goToMaturityOverview(
                    match.params.campaignId);
            }
            handleClose();
        })
        .catch(err => {
            console.log('descope err', err);
        });
    }
};

type FormData = {
    values: {
        reason: string;
        listIds: DescopedObjective[];
    };
    handleSubmit: (e: React.FormEvent) => void;
    handleChange: (e: React.ChangeEvent) => void;
};

export const renderDescopePopup = ({
    match,
    handleClose,
    descopedObjectivesList,
    descopedComponentsList,
    modalId,
    currentAssessmentId,
    currentDomainId,
    currentCapabilityId,
    currentObjectiveId,
	descopeObjectivesScope,
	descopeComponentsScope,
    selectedComponents,
    isReasonExist,
    setIsReasonExist,
    setIsWeightedFormOpen
}: {
    match?: MaturityMatch;
    handleClose: () => void;
    descopedObjectivesList?: DescopedObjective[];
    descopedComponentsList?: DescopedComponent[];
    modalId: string;
    descopeObjectivesScope?: (data: object) => Promise<object>;
    descopeComponentsScope?: (data: object) => Promise<object>;
    currentAssessmentId: string;
    currentDomainId: string;
    currentCapabilityId: string;
    currentObjectiveId?: string;
    selectedComponents?: string;
    isReasonExist: boolean;
    setIsReasonExist: any;
    setIsWeightedFormOpen?: any;
}) => {
    const mainTextDescope = descopedObjectivesList && descopedObjectivesList.length ? `In order to descope "${descopedObjectivesList.map(
        (item: DescopedObjective) => `${item.name}`
	)}", please provide a reason` :
		descopedComponentsList && descopedComponentsList.length ? 'In order to descope the questions, please provide a reason' : '';

	const mainTextRescope = descopedObjectivesList && descopedObjectivesList.length ? `Are you sure you want to rescope "${descopedObjectivesList.map(
		(item: DescopedObjective) => `${item.name}`
	)}"?` : descopedComponentsList && descopedComponentsList.length ? 'Are you sure you want to rescope the process component' : '';

    const popupsStore = new Map();
    popupsStore.set(
        'descope',
        <DescopePopup
            handleClose={handleClose}
            title="Descope objective"
            mainText={mainTextDescope}
            descriptionText={
                'Note: Score(s) will be eliminated and reset. Descoped objectives will be visible on the maturity score page. They can be reactivated from there as well.'
            }
            initialValues={{ reason: '', listIds: descopedObjectivesList ?? descopedComponentsList }}
            render={(data: FormData) => {
                return (
                    <>
                        <TextArea
                            margin={'20px 0 20px  0'}
                            isAutoFocus
                            name="reason"
                            handleChange={data.handleChange}
                            value={data.values.reason}
                            placeholder="Add a reason"
                            minHeight={'120px'}
                        />
                        <JustifyContentEnd>
                            {isReasonExist &&
                                <Text color="red"
                                    style={{
                                        display: 'block',
                                        verticalAlign: 'middle',
                                        lineHeight: 3.2,
                                        marginRight: 15
                                    }}>
                                    Reason is required
                                </Text>
                            }
                            <RedButton
                                onClick={() => {
                                    handleDescopeObjectives({
                                        match,
                                        currentAssessmentId,
                                        currentDomainId,
                                        currentCapabilityId,
                                        currentObjectiveId,
                                        values: data.values,
                                        handleClose,
                                        descopeObjectivesScope,
                                        descopeComponentsScope,
                                        inScope:(modalId !== 'descope'),
                                        selectedComponents,
                                        setIsReasonExist,
                                        setIsWeightedFormOpen
                                    });
                                }}
                            >
                                Descope
                            </RedButton>
                        </JustifyContentEnd>
                    </>
                );
            }}
        />
    );

    popupsStore.set(
        'rescope',
        <DescopePopup
            handleClose={handleClose}
            title="Rescope objective"
            mainText={mainTextRescope}
            descriptionText={''}
            initialValues={{ reason: '', listIds: descopedObjectivesList ?? descopedComponentsList }}
            render={(data: any) => {
                return (
                    <JustifyContentEnd>
                        <RedButton
                            onClick={() =>
                                handleDescopeObjectives({
                                    match,
                                    currentAssessmentId,
                                    currentDomainId,
                                    currentCapabilityId,
                                    currentObjectiveId,
                                    values: data.values,
                                    handleClose,
                                    descopeObjectivesScope,
                                    descopeComponentsScope,
                                    inScope:(modalId !== 'descope'),
                                    selectedComponents,
                                    setIsReasonExist
                                })
                            }
                        >
                            Rescope
                        </RedButton>
                    </JustifyContentEnd>
                );
            }}
        />
    );
    return popupsStore.get(modalId) || null;
};

export const getMarksList = (objectivesLabelsList: string[]) => {
    const marksList: { label: string | null; value: number }[] = [];

    const filteredObjectivesLabelsList = objectivesLabelsList.filter(
        objective => objective !== 'Not in Place'
    );
    for (
        let index = 0;
        index <= filteredObjectivesLabelsList.length;
        index = index + 0.5
    ) {
        if (!index) {
            marksList.push({ value: index, label: 'Not in place' });
        } else if (!Number.isInteger(index)) {
            marksList.push({ value: index, label: null });
        } else {
            marksList.push({
                value: index,
                label: filteredObjectivesLabelsList[index - 1]
            });
        }
    }

    return marksList;
};

export function validateWeighting(weightings: (number | undefined | null)[]) {
    const sumOfWeights = weightings.reduce((sum, value) => (sum || 0) + (value || 0));
    const summary = sumOfWeights ? (Math.round((sumOfWeights + Number.EPSILON) * 100) / 100) : 0;

    if (!(summary && (summary > 99 && summary <= 100))) {
        return false;
    }
    return true;
}

export const isComponentWeightingValid = (components: ObjectiveComponentWithDescriptions[]) => {
    if (components.length > 0) {
        const inScopeComponents = components.filter((component) => (component.inScope || false));
        if (components.length !== inScopeComponents.length) {
            components.forEach((c) => {
                if (c.inScope === false) {
                    c.weighting = 0;
                }
            });
        }

        const weightings = inScopeComponents.map(component => (component.weighting));

        const sumOfWeights = weightings.reduce((sum, value) => (sum || 0) + (value || 0));
        const summary = sumOfWeights ? (Math.round((sumOfWeights + Number.EPSILON) * 100) / 100) : 0;

        if (!(summary && (summary > 99 && summary <= 100))) {
            return false;
        }
        return true;
    }
    return false;
};

export const isNISTFramework = (frameworkName?: string): boolean => {
    if (frameworkName) {
        return NIST_FRAMEWORK_NAMES.filter((name: string) => {
            return frameworkName.toLowerCase() === name.toLowerCase();
        }).length > 0 ? true : false;
    }
    return false;
};
