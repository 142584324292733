import React, { useContext, useEffect, useState } from 'react';
import { FieldArray, Formik } from 'formik';
import { ObservationsAndRecommendationsSection } from './index';
import { Button } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { styled } from '@mui/material/styles';
import {
    fillModerationFeedback,
    handleUpdateCard,
    handleUpdateDomainFeedback
} from '../ModerationHelpers';
import { HorizontalLine, AlignItems, Flex } from '../../../../../components';
import { Feedback, Member, ObsAndRecProps, Values } from '../../types';
import { UserStore } from '../../../../../context/user-store';
import { ASSESSMENT_BY_ID } from '../../../AssessmentsMaturityOverview/AssessmentsMaturityOverviewTabs/Components/AssessmentQueries';
import { CAMPAIGN_FRAMEWORK } from '../../../AssessmentsMaturityOverview/AssessmentsMaturityOverviewQueries';
import { ASSESSMENT_CAPABILITY_WITH_FRAMEWORK } from '../../MaturityScoreQueries';

const EDIT_CAPABILITY = gql`
    mutation updateAssessmentCapabilityModerationFeedback(
        $data: UpdateAssessmentCapabilityModerationFeedbackInput!
    ) {
        updateAssessmentCapabilityModerationFeedback(data: $data) {
            id
            feedback {
                title
                observations {
                    text
                }
                recommendations {
                    text
                }
            }
        }
    }
`;

const EDIT_DOMAIN_FEEDBACK = gql`
    mutation updateAssessmentDomainModerationFeedback(
        $data: UpdateAssessmentDomainModerationFeedbackInput!
    ) {
        updateAssessmentDomainModerationFeedback(data: $data) {
            id
            feedback {
                id
                title
                observations {
                    text
                }
                recommendations {
                    text
                }
            }
        }
    }
`;

const ObsAndRecContainer: React.FC<ObsAndRecProps> = ({
    frameworkCapabilities,
    currentCapability,
    currentDomain,
    campaignData,
    currentAssessmentId,
    currentIdsData,
    level,
    canEdit = true,
    isReadOnly
}) => {

    const [editCapability, { loading: capsLoading }] = useMutation(
        EDIT_CAPABILITY,
        {
            refetchQueries: [
                {
                    query: ASSESSMENT_BY_ID,
                    variables: {
                        id: currentAssessmentId
                    }
                },
                {
                    query: ASSESSMENT_CAPABILITY_WITH_FRAMEWORK,
                    variables: {
                        campaignId: campaignData && campaignData.campaign.id,
                        data :{
                            assessmentId: currentAssessmentId,
                            domainId: currentIdsData.domainId,
                            capabilityId: currentIdsData.capabilityId
                        }
                    }
                }
            ]
        }
    );
    const [editDomainFeedback, { loading: domainLoading }] = useMutation(
        EDIT_DOMAIN_FEEDBACK,
        {
            refetchQueries: [
                {
                    query: ASSESSMENT_BY_ID,
                    variables: {
                        id: currentAssessmentId
                    }
                },
                {
                    query: CAMPAIGN_FRAMEWORK,
                    variables: {
                        campaignId: campaignData && campaignData.campaign.id,
                        assessmentId: currentAssessmentId
                    }
                }
            ]
        }
    );
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<Feedback[]>([]);
    const {
        state: { user, client }
    } = useContext(UserStore);
    const { domainId, capabilityId } = currentIdsData;

    useEffect(() => {
        handleCancel();
    }, [currentCapability, currentDomain, level]);

    useEffect(() => {
        if (domainLoading || capsLoading) {
            setLoadingStatus(true);
        } else {
            setLoadingStatus(false);
        }
    }, [domainLoading, capsLoading]);

    const handleCancel = () => {
        if (currentCapability && level !== 'domains' && currentCapability.id === capabilityId) {
            setFeedback(fillModerationFeedback(currentCapability.feedback));
        }
        if (currentDomain && level === 'domains') {
            setFeedback(fillModerationFeedback(currentDomain.feedback));
        }
    };

    const [referenceName, setReferenceName] = useState('');

    useEffect(() => {
        const filteredReferenceName = frameworkCapabilities ? frameworkCapabilities.filter((c: any) => c.name === currentCapability.name) : '';
        if (currentDomain && level === 'domains') {
            setReferenceName(filteredReferenceName[0] && filteredReferenceName[0].domainShortName);
        }
        if (currentCapability && level !== 'domains') {
            setReferenceName(filteredReferenceName[0] && filteredReferenceName[0].domainShortName +'-'+filteredReferenceName[0].shortName);
        }
    }, [
        currentCapability,
        currentDomain,
        currentAssessmentId,
        currentIdsData
    ]);



    const userMayEdit =
        (campaignData &&
            campaignData.campaign &&
            campaignData.campaign.members &&
            campaignData.campaign.members.some(
                (member: Member) => member.id === (user && user.id)
            )) ||
            ( campaignData &&
                campaignData.campaign &&
                campaignData.campaign.manager.some(manager => user && user.id === manager.id)) ||
        (user && user.id) === (client && client.owner && client.owner.id) ||
        (campaignData?.campaign.isCampaignEntityUser);

    const updateFeedback = (values: Values, updateFeedbackLevel?: string) => {
        if (!userMayEdit) {
            return;
        }

        if (updateFeedbackLevel === 'domains') {
            handleUpdateDomainFeedback({
                currentAssessmentId,
                currentIdsData: {
                    domainId,
                    capabilityId
                },
                editDomainFeedback,
                feedback: values.feedback
            });
        } else {
            handleUpdateCard({
                currentAssessmentId,
                currentIdsData: {
                    domainId,
                    capabilityId
                },
                editCards: editCapability,
                feedback: values.feedback
            });
        }
    };

    const initialValue={
        newObservation: '',
        newRecommendation: '',
        feedback:feedback,
        editableCard: {
            editableGroupIndex: null,
            editableItemIndex: null,
            name: ''
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={{}}
            onSubmit={(_values, { setSubmitting }) => {
                setSubmitting(false);
            }}>
                {({ setFieldValue, values }) => (
                <FieldArray
                    name="feedback"
                    render={arrayHelpers => (
                        <div>
                            {values.feedback &&
                                values.feedback.map(
                                    (feedbackMap: Feedback, index: number) => (
                                        <div key={index}>
                                            <ObservationsAndRecommendationsSection
                                                referenceName={referenceName}
                                                currentCapability={
                                                    currentCapability
                                                }
                                                feedbackIndex={index}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                canEdit={canEdit && !isReadOnly }
                                                dataLoading={loadingStatus}
                                                level={level}
                                            />

                                            <HorizontalLineCustom height="1px" />
                                        </div>
                                    )
                                )}

                            <Flex>
                                <Button onClick={handleCancel}>Cancel</Button>

                                <Button
                                    disabled={!canEdit || isReadOnly || loadingStatus}
                                    onClick={() =>
                                        updateFeedback(values, level)
                                    }
                                >
                                    Save
                                </Button>
                            </Flex>

                            <ButtonContainer>
                                <RedButton
                                    disabled={!canEdit || loadingStatus}
                                    onClick={() =>
                                        arrayHelpers.push({
                                            observations: [
                                                {
                                                    text: ''
                                                }
                                            ],
                                            recommendations: [
                                                {
                                                    text: ''
                                                }
                                            ]
                                        })
                                    }
                                >
                                    Add new section
                                </RedButton>
                            </ButtonContainer>
                        </div>
                    )}
                />
            )}
        </Formik>
    );
};

const HorizontalLineCustom = styled(HorizontalLine)`
    margin: 10px 0;
    background: #d9d9d9;
    height: 3px;
`;

const ButtonContainer = styled(AlignItems)`
    margin-bottom: 20px;
    justify-content: center;
`;

const RedButton = styled(Button)`
    && {
        border-radius: 2px;
        background-color: #de3328;
        color: #fff;
        text-transform: none;
        padding: 12px 16px;

        &:hover {
            background-color: #dd4b41;
        }
    }
`;
export default ObsAndRecContainer;
