import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Field } from 'formik';
import { IconButton, TextField } from '@mui/material';
import verticalDots from '../../../../../../../assets/images/icon-3-vertical-dots-grey.svg';
import riskDescription from '../../../../../../../assets/images/icon-risk-description.svg';
import iconTrash from '../../../../../../../assets/images/icon-trash.svg';
import {
    BoldText,
    Column,
    CustomField,
    Flex,
    Text,
    ToggleSwitch,
    AlignItems
} from '../../../../../../../components';
import {
    ThreatOptionType,
    ObjectiveInList,
    ObjectivesDropdownList
} from '../../../../types';
import PopoverObjectivesMenu from './PopoverObjectivesMenu/PopoverObjectivesMenu';
import ThreatOptionObjective from './ThreatOptionObjective';

const ThreatOption: React.FC<ThreatOptionType> = ({
    isNewThreat,
    placeholderText,
    id,
    setFieldValue,
    values,
    allObjectives,
    isSubmit,
    threat,
    setDeleteItem,
    isExpandAll,
    setThreatDeleted,
    isThreatDeleted,
    isAddModal,
    openThreatId,
    setGuidance,
    isGuidance,
    setInitialLoad,
    isInitialLoad
}) => {
    useEffect(() => {
        if (isNewThreat) {
            setThreatOpen(true);
        } else if (!isAddModal && threat && openThreatId === threat.id) {
            setThreatOpen(true);
        }
    }, []);
    const [isThreatOpen, setThreatOpen] = useState<boolean>(!!isNewThreat);
    const [isObjectivesOpen, setObjectivesOpen] = useState<boolean>(false);
    const [finalObjectives, setFinalObjectives] = useState<string[]>([]);
    const [objWithKey, setObjWithKey] = useState<string[]>([]);
    const [title, setTitle] = useState<string>(
        values && values[id] && values[id].title ? values[id].title : ''
    );
    const [description, setDescription] = useState<string>(
        values && values[id] && values[id].description
            ? values[id].description
            : ''
    );
    const [validationErrorText, setValidationErrorText] = useState<string>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [objWithCap, setObjWithCap] = useState<ObjectivesDropdownList[]>([]);

    useEffect(() => {
        if (threat) {
            setTitle(threat.title);
            setDescription(threat.description);
            const objectivesWithKey = threat.objectives
                .filter((obj: ObjectiveInList | string) => {
                    return (obj as ObjectiveInList).isKey;
                })
                .map((obj: ObjectiveInList | string) => {
                    return (obj as ObjectiveInList).reference;
                });
            const objectivesRetrieved = threat.objectives.map(
                (obj: ObjectiveInList | string) => {
                    return (obj as ObjectiveInList).reference;
                }
            );
            setFinalObjectives(objectivesRetrieved);
            objectivesWithKey && setObjWithKey(objectivesWithKey);
            setFieldValue(`[${id}].title`, threat.title);
            setFieldValue(`[${id}].description`, threat.description);
            setFieldValue(`[${id}].objectives`, threat.objectives);
            setFieldValue(`[${id}].id`, threat.id);
        }
    }, [threat]);

    useEffect(() => {
        if (values && values[id] && isThreatDeleted && isNewThreat) {
            setTitle(values[id].title);
            setDescription(values[id].description);
            if (values[id].objectives) {
                const objectivesWithKey = values[id].objectives
                    .filter((obj: ObjectiveInList) => {
                        return obj.isKey;
                    })
                    .map((obj: ObjectiveInList) => {
                        return obj.reference;
                    });
                const objectivesRetrieved = values[id].objectives.map(
                    (obj: ObjectiveInList) => {
                        return obj.reference;
                    }
                );
                setFinalObjectives(objectivesRetrieved);
                objectivesWithKey && setObjWithKey(objectivesWithKey);
            }
            setThreatDeleted && setThreatDeleted(false);
        } else if (isThreatDeleted && !isNewThreat) {
            setThreatDeleted && setThreatDeleted(false);
        }
    }, [isThreatDeleted]);

    useEffect(() => {
        if (isExpandAll !== undefined) {
            setThreatOpen(isExpandAll);
        }
    }, [isExpandAll]);

    useEffect(() => {
        if (title.length) {
            if (
                values.filter((value: any) => value.title === title).length > 1
            ) {
                setFieldValue(`[${id}].hasValidationErrors`, true);
                setValidationErrorText(
                    'There is already a threat scenario with this name in this campaign.'
                );
            } else {
                setFieldValue(`[${id}].hasValidationErrors`, false);
                setValidationErrorText('');
            }
        } else if (!title.length) {
            setFieldValue(`[${id}].hasValidationErrors`, true);
            setValidationErrorText('Complete required information');
        }
    }, [title.length]);

    useEffect(() => {
        const newObjectivesArray = finalObjectives.map((obj: string) => {
            if (objWithKey.includes(obj)) {
                return {
                    reference: obj,
                    isKey: true
                };
            }
            return {
                reference: obj,
                isKey: false
            };
        });
        setFieldValue(`[${id}].objectives`, newObjectivesArray);
    }, [objWithKey.length, finalObjectives.length]);

    useEffect(() => {
        if (finalObjectives) {
            const tempObjWithCap: ObjectivesDropdownList[] = [];

            finalObjectives.forEach((finalObjective: string) => {
                if (allObjectives) {
                    allObjectives.forEach((cap: ObjectivesDropdownList) => {
                        const objective = cap.objectives.find(
                            (obj: ObjectiveInList) =>
                                obj.reference === finalObjective
                        );
                        if (objective) {
                            const existCap = tempObjWithCap.findIndex(
                                (el: ObjectivesDropdownList) =>
                                    el.name === cap.name
                            );
                            if (existCap !== -1) {
                                if (
                                    tempObjWithCap[
                                        existCap
                                    ].objectives.findIndex(
                                        (obj: ObjectiveInList) =>
                                            obj.title === objective.title
                                    ) === -1
                                ) {
                                    tempObjWithCap[existCap].objectives.push(
                                        objective
                                    );
                                }
                            } else {
                                tempObjWithCap.push({
                                    name: cap.name,
                                    objectives: [objective]
                                });
                            }
                        }
                    });
                }
            });
            setObjWithCap([...tempObjWithCap]);
        }
    }, [finalObjectives, allObjectives && allObjectives.length]);

    return (
        <OptionWrapper>
            <Header
                validation={isSubmit && values[id].hasValidationErrors}
            >
                <IconsWrapper
                    style={{ marginRight: '4px' }}
                >
                    <Icon src={verticalDots} alt="Add risk" />
                    <Icon src={verticalDots} alt="Add risk" />
                </IconsWrapper>

                <Field
                    id={'title-' + id}
                    disabled={false}
                    margin="dense"
                    name={'title'}
                    value={title}
                    style={{
                        flexBasis: '87%',
                        top: isSubmit
                            && values[id].hasValidationErrors
                            && '-12px'
                    }}
                    type="name"
                    variant="outlined"
                    label="Threat name"
                    as={TitleTextField}
                    rows="1"
                    fullWidth
                    InputProps={{
                        style: {
                            notchedOutline: {
                                border: 'none'
                            }
                        }
                    }}
                    placeholder={placeholderText}
                    multiline
                    onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(`[${id}].title`, e.target.value);
                        setTitle(e.target.value);
                        if (isInitialLoad) {
                            setInitialLoad(false);
                        }
                    }}
                    validation={(!!(
                        isSubmit && values[id].hasValidationErrors
                    )).toString()}
                />
                <IconButton
                    onClick={() => {
                        if (isNewThreat) {
                            setDeleteItem({
                                objectId: id,
                                objectName: title,
                                isOpen: true,
                                modalType: 'threat'
                            });
                        } else if (threat) {
                            setDeleteItem({
                                objectId: threat.id,
                                objectName: title,
                                isOpen: true,
                                modalType: 'threat'
                            });
                        }
                    }}
                >
                    <DeleteThreatIcon src={iconTrash} alt="Delete risk" aria-hidden />
                    <DeleteText>Delete</DeleteText>
                </IconButton>
                <KeyboardArrowButton onClick={() => setThreatOpen(!isThreatOpen)}
                    aria-label="collapse or expand threat"
                >
                    { isThreatOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown /> }
                </KeyboardArrowButton>
            </Header>
            {isSubmit && values[id].hasValidationErrors && (
                <ErrorText>{validationErrorText}</ErrorText>
            )}
            {isThreatOpen && (
                <Column style={{ marginBottom: '15px' }}>
                    <Column>
                        <SubTitleWrapper>
                            <img
                                src={riskDescription}
                                style={{ marginRight: 5 }}
                                alt="DESCRIPTION"
                            />
                            <SubTitle>Description</SubTitle>
                        </SubTitleWrapper>
                        <Field
                            id={'description-' + id}
                            margin="dense"
                            name={'description'}
                            type="name"
                            variant="outlined"
                            placeholder="Add description"
                            align="left"
                            multiline
                            rows={3}
                            as={TextField}
                            value={description}
                            style={{
                                marginLeft: 45,
                                marginTop: 8,
                                marginBottom: 5
                            }}
                            onChange={(e: React.ChangeEvent<any>) => {
                                setFieldValue(
                                    `[${id}].description`,
                                    e.target.value
                                );
                                setDescription(e.target.value);
                                if (isInitialLoad) {
                                    setInitialLoad(false);
                                }
                            }}
                            />
                    </Column>
                    <Column>
                        <SubTitleWrapper>
                            <ListAltIcon />
                            <SubTitle>Objectives</SubTitle>
                        </SubTitleWrapper>
                        <ThreatModalFieldsIndent>
                            <Flex>
                                <Field
                                    margin="dense"
                                    name="threatScenarios"
                                    variant="outlined"
                                    multiple
                                    style={{ width: '473px' }}
                                    render={() => (
                                        <PopoverObjectivesMenu
                                            isOpen={isObjectivesOpen}
                                            setOpen={setObjectivesOpen}
                                            anchorEl={anchorEl}
                                            setAnchorEl={setAnchorEl}
                                            allObjectives={allObjectives}
                                            setFinalObjectives={
                                                setFinalObjectives
                                            }
                                            finalObjectives={finalObjectives}
                                            setFieldValue={setFieldValue}
                                            id={id}
                                            isGuidance={isGuidance}
                                            setInitialLoad={setInitialLoad}
                                            isInitialLoad={isInitialLoad}
                                        />
                                    )}
                                />
                                <ToggleWrapper>
                                    <Text
                                        size="15px"
                                        style={{ marginBottom: '5px' }}
                                    >
                                        Guidance
                                    </Text>
                                    <ToggleSwitch
                                        turnedOn={isGuidance}
                                        setTurnOn={setGuidance}
                                    />
                                </ToggleWrapper>
                            </Flex>
                            {objWithCap.length > 0 && (
                                <AllSelectedObjectives>
                                    <SelectedObjectivesHeader>
                                        <ObjectivesColumn>
                                            Assigned objectives
                                        </ObjectivesColumn>
                                        <ObjectivesKey>Key</ObjectivesKey>
                                        <ObjectivesType>Type</ObjectivesType>
                                    </SelectedObjectivesHeader>
                                    <Column>
                                        {objWithCap.map(
                                            (cap: ObjectivesDropdownList,ind: number) => {
                                                return (
                                                    // tslint:disable-next-line: jsx-key
                                                    <div key={ind}>
                                                        {isGuidance && (<CapName>
                                                            {cap.name}
                                                        </CapName>)}
                                                        {cap.objectives.map(
                                                            (
                                                                obj: ObjectiveInList,
                                                                objIndex: number
                                                            ) => {
                                                                return (
                                                                    <ThreatOptionObjective
                                                                        key={
                                                                            objIndex
                                                                        }
                                                                        obj={
                                                                            obj
                                                                        }
                                                                        objWithKey={
                                                                            objWithKey
                                                                        }
                                                                        setObjWithKey={
                                                                            setObjWithKey
                                                                        }
                                                                        allObjectives={
                                                                            allObjectives
                                                                        }
                                                                        finalObjectives={
                                                                            finalObjectives
                                                                        }
                                                                        setFinalObjectives={
                                                                            setFinalObjectives
                                                                        }
                                                                        setInitialLoad={
                                                                            setInitialLoad
                                                                        }
                                                                        isInitialLoad={
                                                                            isInitialLoad
                                                                        }
                                                                        isLatestObjective={
                                                                            cap
                                                                                .objectives
                                                                                .length - 1 ===
                                                                            objIndex && isGuidance
                                                                        }
                                                                        isGuidance={isGuidance}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Column>
                                </AllSelectedObjectives>
                            )}
                        </ThreatModalFieldsIndent>
                    </Column>
                </Column>
            )}
        </OptionWrapper>
    );
};

const ThreatModalFieldsIndent = styled(Column)({
    marginLeft: '45px',
    marginTop: '8px',
});

const CapName = styled(Text)`
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #575757;
    margin-top: 12px;
    margin-left: 11px;
`;

const ToggleWrapper = styled(AlignItems)`
    margin-top: -13px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const DeleteText = styled(Text)`
    font-size: 14px;
    color: #6E6E6E;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 22px;
`;

const DeleteThreatIcon = styled('img')`
    width: 17px;
    opacity: 0.5;
`;

const ErrorText = styled('p')`
    color: rgb(218, 30, 40);
    position: relative;
    left: 45px;
    top: -33px;
    font-size: 13px;
    margin-bottom: -29px;
`;

const AllSelectedObjectives = styled('div')`
    flex-direction: column;
    width: 649px;
    margin-top: 24px;
`;

const SelectedObjectivesHeader = styled('div')`
    display: flex;
    align-items: center;
    height: 37px;
    color: white;
    border-radius: 2px;
    background-color: #444444;
    font-size: 17px;
    padding-left: 18px;
`;

const TitleTextField = styled(TextField)<{ validation: string }>`
    .MuiOutlinedInput-multiline {
        padding-bottom: ${props =>
            props.validation === 'true' ? '0px' : '18.5px'};
    }
    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
    .MuiFormLabel-root-MuiInputLabel-root {
        padding-top:10px;
    }
`;

const ObjectivesColumn = styled('div')`
    flex-basis: 79%;
`;

const ObjectivesKey = styled('div')`
    flex-basis: 8%;
`;

const ObjectivesType = styled('div')`
    flex-basis: 8%;
`;

const IconsWrapper = styled('div')`
    width: 55px;
    flex-basis: 5%;
`;

const Icon = styled('img')`
    && {
        margin-right: -13px;
        opacity: 0.5;
    }
`;

const SubTitleWrapper = styled(BoldText)`
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-top: 20px;
`;

const SubTitle = styled('div')`
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 8px;
`;

const Header = styled('div')<{ validation: boolean }>`
    display: flex;
    width: 704px;
    height: 65px;
    border: solid 1px #ececec;
    border-color: ${props => (props.validation ? '#de3328' : '#ececec')};
    align-items: center;
    justify-content: space-evenly;
    background-color: #f4f4f4;
`;

const KeyboardArrowButton = styled(IconButton)`
    color: #2d2d2d !important;
    flex-basis: 8%;
`;

const OptionWrapper = styled('div')`
    margin-bottom: 3px;
    width: fit-content;
`;

export default ThreatOption;
