import React from 'react';
import { version } from '../../version';
import RouteConfig from '../../routes/RouteConfig';
import IdleTimeout from './IdleTimer/IdleTimeout';

const App: React.FC = () => {
    console.log('version', version);


    return (
        <>
            <IdleTimeout />
            <RouteConfig />
        </>
    );
};

export default App;
