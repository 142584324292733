import React from 'react';
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Text from '../../base/Text/Text';

import { BreadCrumb } from './types';

type Props = {
    crumbs: BreadCrumb[];
};

const Breadcrumb: React.FC<Props> = ({ crumbs = [] }) => {
    return (
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {crumbs.map((crumb: BreadCrumb, i: number) => {
                return crumb.path ? (
                    <LinkWithUnderline key={i} to={crumb.path}>
                        {crumb.name}
                    </LinkWithUnderline>
                ) : (
                    <CrumbText key={i}>{crumb.name}</CrumbText>
                );
            })}
        </Breadcrumbs>
    );
};

const CrumbText = styled(Text)`
    color: #525252;
    letter-spacing: 0.5px;
`;

const LinkWithUnderline = styled(Link)`
    && {
        border-bottom: 1px solid #525252;
        color: #525252;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
`;

export default Breadcrumb;
