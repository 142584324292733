import React from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Button, DateTimePickerField } from '../../../../components';
import iconClose from '../../../../assets/images/Atmos_Icons_Close.svg';

import {
    Divider,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    Checkbox,
    DialogContent,
    Popover,
    IconButton,
} from '@mui/material';

import {
    AlignItems,
    Text,
    ToggleSwitch,
    BoldText,
    Flex,
    JustifyContentCenter,
    CloseCampaignModal,
    LinkExternal,
} from '../../../../components';
import Router from '../../../../routes/router';

import dropdownArrow from '../../../../assets/images/icon-drop-down-dark.svg';
import infoIcon from '../../../../assets/images/info_icon_blue.svg';
import crossIcon from '../../../../assets/images/cross_icon.svg';
import PwC_full from '../../../../assets/images/PwC_full.svg';
import PwC_light from '../../../../assets/images/PwC_light.svg';
import client_sa from '../../../../assets/images/client_sa.svg';
import Dialog from '@mui/material/Dialog';
import TermsOfUse from '../../../TermsOfUse';
import CustomButton from '../../../../components/base/Buttons/Button';
import { base64ToBlob } from 'base64-blob';


export const campaignAnalysisValues = ['View Benchmarking', 'View Comparison'];

export const campaignMenuItems = [
    { id: 'edit', label: 'Edit campaign details' },
    { id: 'add', label: 'Add assessment' },
    { id: 'close', label: 'Close campaign' },
];

export const maturityTooltipTitle = [
    'Maturity overview',
    'Capability average',
    'Maturity box chart',
    'Domain average',
];

export const exportCSV = (id?: string) => {
    const tkn = localStorage.getItem('pid-token');
    const clientLocal = localStorage.getItem('client');
    let clientUUID = null;
    if (clientLocal) {
        const clientParsed = JSON.parse(clientLocal);
        clientUUID = clientParsed.id;
    }
    axios
        .get(window.__ENV__.REACT_APP_APP_URL + '/campaign/' + id + '/csv', {
            params: {},
            headers: {
                'pearl-client-uuid': clientUUID,
                Authorization: `Bearer ${tkn}`,
            },
        })
        .then(async (response) => {
            if (response.status === 200 && response.data) {
                const blob = await base64ToBlob('data:application/zip;base64,'+response.data.content);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = response.data.title;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

export const campaignOverviewMenuWithTargetScore = (
    viewPopoverAnchor: HTMLButtonElement | null,
    setViewPopoverAnchor: any,
    showTargetScore: boolean,
    setShowTargetScore: any,
    showCompensationControl: boolean,
    setShowCompensationControl: any,
    targetScoreEnabled: boolean,
    isLegendVisible: boolean,
    setIsLegendVisible: any,
    setCurrentTheme: any,
    currentTheme: string,
    compensatingControlEnabled: boolean,
    alignment?: string,
    setView?: any,
) => {

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setViewPopoverAnchor(event.currentTarget);
      };

    const handleClose = () => {
        setViewPopoverAnchor(null);
    };

    const open = Boolean(viewPopoverAnchor);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                style={{
                    padding: 0,
                    marginLeft: 16,
                    marginRight: 16,
                    border: '1px solid black',
                    borderRadius: 4,
                    boxShadow: 'none'
                }}
            >
                <AlignItems
                    style={{
                        height: 48,
                        padding: '0 16px'
                    }}
                >
                    <Text
                        style={{
                            color: '#000000',
                            margin: 10,
                            fontSize: 16,
                            fontWeight: 500
                        }}
                    >
                        View
                    </Text>
                    <img src={dropdownArrow} alt="dropdown arrow" aria-hidden />
                </AlignItems>
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={viewPopoverAnchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                style={{
                    padding: '8px 0'
                }}
            >
                {compensatingControlEnabled && <>
                    <div style={{ padding: '16px 16px 16px 16px' }}>
                        <AlignItems>
                            <Text style={{ marginBottom: 10 }}>
                                COMPENSATING CONTROLS
                            </Text>
                        </AlignItems>
                        <ToggleSwitch
                            turnedOn={showCompensationControl}
                            setTurnOn={() =>
                                setShowCompensationControl(!showCompensationControl)
                            }
                        />
                    </div>
                    <Divider />
                </>}
                {targetScoreEnabled && (
                    <>
                        <div style={{ padding: '16px 16px 16px 16px' }}>
                            <AlignItems>
                                <Text style={{ marginBottom: 10 }}>
                                    SHOW TARGET SCORE
                                </Text>
                            </AlignItems>
                            <ToggleSwitch
                                turnedOn={showTargetScore}
                                setTurnOn={() =>
                                    setShowTargetScore(!showTargetScore)
                                }
                            />
                        </div>
                        <Divider />
                    </>
                )}
                <div style={{ padding: '16px 0 16px 16px' }}>
                    <AlignItems>
                        <Text style={{ marginBottom: 10 }}>LEGEND</Text>
                    </AlignItems>
                    <ToggleSwitch
                        turnedOn={isLegendVisible}
                        setTurnOn={() =>
                            setIsLegendVisible(!isLegendVisible)
                        }
                    />
                </div>
                {!!alignment && !!setView && (
                    <>
                        <Divider />
                        <div style={{ padding: '8px 16px 0 16px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Layout
                                </FormLabel>
                                <RadioGroup
                                    aria-label="layout"
                                    name="layout"
                                    value={''}
                                    onChange={() => {}}
                                >
                                    <FormControlLabel
                                        checked={
                                            alignment === 'horizontal'
                                        }
                                        onChange={() =>
                                            setView('horizontal')
                                        }
                                        value="horizontal"
                                        control={<Radio />}
                                        label="Horizontal"
                                    />
                                    <FormControlLabel
                                        checked={
                                            alignment === 'vertical'
                                        }
                                        onChange={() =>
                                            setView('vertical')
                                        }
                                        value="vertical"
                                        control={<Radio />}
                                        label="Vertical"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </>
                )}
                {!showTargetScore && (
                    <>
                        <Divider />
                        <div style={{ padding: '8px 16px 0 16px' }}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Colour Themes
                                </FormLabel>
                                <RadioGroup
                                    aria-label="colour themes"
                                    name="theme"
                                    value={currentTheme}
                                >
                                    <FormControlLabel
                                        onClick={() =>
                                            setCurrentTheme('blue')
                                        }
                                        value="blue"
                                        control={<Radio />}
                                        label="Blue theme"
                                    />
                                    <FormControlLabel
                                        onClick={() =>
                                            setCurrentTheme('RAGtheme1')
                                        }
                                        value="RAGtheme1"
                                        control={<Radio />}
                                        label="RAG theme 1"
                                    />
                                    <FormControlLabel
                                        onClick={() =>
                                            setCurrentTheme('RAGtheme2')
                                        }
                                        value="RAGtheme2"
                                        control={<Radio />}
                                        label="RAG theme2"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </>
                )}
            </Popover>
        </>
    );
};

export const assessmentsActionsHandlers = (
    name: string,
    setOpen: any,
    step?: number,
    campaignId?: string
) => {
    switch (name) {
        case 'Edit campaign details':
            Router.goToEvaluateCreateCampaign(campaignId, step);
            break;
        case 'Add assessment':
            Router.goToEvaluateCreateCampaign(campaignId, step);
            break;
        case 'Close campaign':
            setOpen(true);
            break;
    }
};

export const renderModal = ({
    handleClose,
    isOpen,
    closeCampaign,
    campaignId,
    campaignTitle,
    closeCampaignValue,
    setCloseCampaignValue,
    isSelectOpen,
    setSelectOpen,
    allCapabilitiesCompleted,
    benchmarkingEnabled,
    domainDescoped,
    pointOneDecimalEnable,
    isCheckboxEnable,
    setCheckBoxes,
}: {
    isOpen: boolean;
    handleClose: () => void;
    campaignId: string;
    campaignTitle: string;
    closeCampaign: any;
    closeCampaignValue: {
        radioValue: string;
        assessmentType: string | number;
        closeDate: Date;
        closeCurrentDate: boolean;
    };
    setCloseCampaignValue: any;
    isSelectOpen: boolean;
    setSelectOpen: any;
    allCapabilitiesCompleted: boolean;
    benchmarkingEnabled?: boolean;
    domainDescoped: boolean;
    pointOneDecimalEnable?: boolean;
    isCheckboxEnable: boolean[][];
    setCheckBoxes: any;
}) => {
    const handleCloseTemplate = () => {
        setCloseCampaignValue({
            assessmentType: 0,
            radioValue: '',
            closeDate: new Date(0),
            closeCurrentDate: true
        });
        handleClose();
    };

    return (
        <CloseCampaignModal
            title={
                <TitleWrapper>
                    <TitleTextWrapper>
                        <BoldText
                            color="#575757"
                            fontSize="22px"
                            style={{ textAlign: 'center' }}
                        >
                            Close Campaign
                        </BoldText>
                    </TitleTextWrapper>
                    <IconButton onClick={() => handleCloseTemplate()} style={{margin: '0 24px'}}>
                        <img src={crossIcon} alt="close" />
                    </IconButton>
                </TitleWrapper>
            }
            buttonCancelText=""
            buttonSaveText={'Close campaign'}
            content={
                <div>
                    <InfoTitleContainer>
                        <div>
                            <img src={infoIcon} alt="" />
                        </div>
                        <InfoTitleTextWrapper>
                            <Text color="#4588c3" size="15px">
                                This action will close the "{campaignTitle}"
                                campaign. Closed campaigns cannot be reopened or
                                edited - all maturity scores, notes,
                                observations and recommendations will be view
                                only.
                            </Text>
                        </InfoTitleTextWrapper>
                    </InfoTitleContainer>
                    {benchmarkingEnabled ? (
                        !domainDescoped && allCapabilitiesCompleted ? (
                            <div style={{ padding: '32px 0' }}>
                                <BoldText fontSize="16px" color="#2d2d2d">
                                    Send this campaign data to the benchmarking
                                    database?
                                </BoldText>
                                <Text>
                                    Data sent to the benchmarking database will
                                    be anonymised and cannot be recovered.
                                </Text>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="position"
                                        name="position"
                                        value={closeCampaignValue.radioValue}
                                        onChange={(e) =>
                                            setCloseCampaignValue({
                                                ...closeCampaignValue,
                                                radioValue: e.target.value,
                                            })
                                        }
                                        row={false}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio color="default" />}
                                            color="default"
                                            label="Yes, send the campaign data to the benchmarking database."
                                            labelPlacement="end"
                                            onChange={(e: any) => {
                                                isCheckboxEnable[0][0] = true;
                                                isCheckboxEnable[1][0] = false;
                                                isCheckboxEnable[1][1] = false;
                                                setCheckBoxes({
                                                    ...isCheckboxEnable,
                                                });
                                            }}
                                        />
                                        {isCheckboxEnable[0][0] && (
                                            <CheckModerationContainer>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    id="check1"
                                                    onChange={(e: any) => {
                                                        isCheckboxEnable[0][1] =
                                                            !isCheckboxEnable[0][1];
                                                        setCheckBoxes({
                                                            ...isCheckboxEnable,
                                                        });
                                                    }}
                                                />
                                                <Text>
                                                    I confirm the data is true
                                                    and accurate to the best of
                                                    my knowledge,<br/> and
                                                    <b>
                                                    {' '}does not contain test data. {' '}
                                                    </b>
                                                    <FooterLinkExternal
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] = true;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Terms of Use
                                                    </FooterLinkExternal>
                                                </Text>
                                            </CheckModerationContainer>
                                        ) }
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio color="default" />}
                                            color="default"
                                            label="No, don’t share campaign data."
                                            labelPlacement="end"
                                            onChange={(e: any) => {
                                                isCheckboxEnable[0][0] = false;
                                                isCheckboxEnable[1][0] = true;
                                                isCheckboxEnable[0][1] = false;
                                                setCheckBoxes({
                                                    ...isCheckboxEnable,
                                                });
                                            }}
                                        />
                                        {isCheckboxEnable[1][0] && (
                                            <CheckModerationContainer>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    id="check2"
                                                    onChange={(e: any) => {
                                                        isCheckboxEnable[1][1] =
                                                            !isCheckboxEnable[1][1];
                                                        setCheckBoxes({
                                                            ...isCheckboxEnable,
                                                        });
                                                    }}
                                                />

                                                <Text>
                                                    I confirm the data is true
                                                    and accurate to the best of
                                                    my knowledge,<br/> and
                                                    <b>
                                                    {' '}does not contain test data. {' '}
                                                    </b>
                                                    <FooterLinkExternal
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                true;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Terms of Use
                                                    </FooterLinkExternal>
                                                </Text>
                                            </CheckModerationContainer>
                                        ) }

                                        {isCheckboxEnable[1][2] && (
                                            <Dialog
                                                open={true}
                                                maxWidth="md"
                                                scroll="body"
                                            >
                                                <DialogContent>
                                                    <Xmark
                                                        src={iconClose}
                                                        margin-right="99%"
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    />
                                                    <TermsOfUse />
                                                    <CloseButton
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Close
                                                    </CloseButton>
                                                </DialogContent>
                                            </Dialog>
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        ) : (
                            <div style={{ padding: '32px 0' }}>
                            {domainDescoped ? (
                                <BoldText fontSize="16px" color="#2d2d2d">
                                    The data for this campaign will not be sent
                                    to the benchmarking database because there
                                    are domains with all capabilities descoped.
                                </BoldText>
                            ) : (
                                <BoldText fontSize="16px" color="#2d2d2d">
                                    The data for this campaign will not be sent
                                    to the benchmarking database because there
                                    are capabilities that have not been
                                    completed.
                                </BoldText>
                            )}
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="position"
                                        name="position"
                                        value={closeCampaignValue.radioValue}
                                        onChange={(e) =>
                                            setCloseCampaignValue({
                                                ...closeCampaignValue,
                                                radioValue: e.target.value,
                                            })
                                        }
                                        row={false}
                                    >
                                        <FormControlLabel
                                            value="wontBeSent"
                                            control={<Radio color="default" />}
                                            color="default"
                                            label="I acknowledge that the data for this campaign won’t be sent to the benchmarking database."
                                            labelPlacement="end"
                                            onChange={(e: any) => {
                                                isCheckboxEnable[1][0] = true;
                                                setCheckBoxes({
                                                    ...isCheckboxEnable,
                                                });
                                            }}
                                        />
                                        {isCheckboxEnable[1][0] && (
                                            <CheckModerationContainer>
                                                <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    id="check2"
                                                    onChange={(e: any) => {
                                                        isCheckboxEnable[1][1] =
                                                            !isCheckboxEnable[1][1];
                                                        setCheckBoxes({
                                                            ...isCheckboxEnable,
                                                        });
                                                    }}
                                                />
                                                <Text>
                                                    I confirm the data is true
                                                    and accurate to the best of
                                                    my knowledge,<br/>and
                                                    <b>
                                                    {' '}does not contain test data. {' '}
                                                    </b>
                                                    <FooterLinkExternal
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                true;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Terms of Use
                                                    </FooterLinkExternal>
                                                </Text>
                                            </CheckModerationContainer>
                                        )}
                                        {isCheckboxEnable[1][2] && (
                                            <Dialog
                                                open={true}
                                                maxWidth="md"
                                                scroll="body"
                                            >
                                                <DialogContent>
                                                    <Xmark
                                                        src={iconClose}
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    />
                                                    <TermsOfUse />
                                                    <CloseButton
                                                        onClick={() => {
                                                            isCheckboxEnable[1][2] =
                                                                false;
                                                            setCheckBoxes({
                                                                ...isCheckboxEnable,
                                                            });
                                                        }}
                                                    >
                                                        Close
                                                    </CloseButton>
                                                </DialogContent>
                                            </Dialog>
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        )
                    ) : null}
                    <div style={{ paddingBottom: '32px' }}>
                        <BoldText fontSize="16px" color="#2d2d2d">When was the campaign completed?</BoldText>
                        <FormControlLabel
                            control={<Checkbox
                                size="small"
                                color="primary"
                                id="checkx123"
                                checked={closeCampaignValue.closeCurrentDate}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setCloseCampaignValue({...closeCampaignValue, closeCurrentDate: e.target.checked})
                                }
                            />}
                            label="Use current date"
                        />
                    {!closeCampaignValue.closeCurrentDate && ( <>
                        <Text style={{ display: 'block' }}>Defaults to last modified date</Text>
                        <DateTimePickerField
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            inputMargin="dense"
                            inputName="closeDate"
                            disableFuture
                            minDate={new Date('2020-04-01')}
                            onChange={(date: Date) => setCloseCampaignValue({...closeCampaignValue, closeDate: date})}
                            value={closeCampaignValue.closeDate}
                        /></>
                    )}
                    </div>
                    <div>
                        <BoldText fontSize="16px" color="#2d2d2d">
                            How was the assessment performed?
                        </BoldText>
                        <Text
                            size="14px"
                            color="#575757"
                            style={{ display: 'block', padding: '10px 0' }}
                        >
                            Assessment type
                        </Text>
                        <FormControl
                            variant="outlined"
                            style={{ minWidth: 300 }}
                        >
                            <CustomSelect
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                    transformOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    },
                                     anchorEl: null,
                                }}
                                value={closeCampaignValue.assessmentType}
                                onChange={(e: any) =>
                                    setCloseCampaignValue({
                                        ...closeCampaignValue,
                                        assessmentType: e.target.value,
                                    })
                                }
                                onOpen={() => setSelectOpen(true)}
                                onClose={() => setSelectOpen(false)}
                            >
                                <MenuItem value={0} disabled>
                                    <BoldText color="#6b6b6b" fontSize="16px">
                                        Select assessment type
                                    </BoldText>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <div>
                                        <BoldText fontSize="15px">
                                            <img
                                                src={PwC_full}
                                                alt="mdi_grade"
                                            />{' '}
                                            PwC full
                                        </BoldText>

                                        {isSelectOpen && (
                                            <Text color="#6b6b6b" size="14px">
                                                This applies to campaigns that
                                                have a full
                                                <br />
                                                set of scores, notes,
                                                observations and
                                                <br />
                                                recommendations.
                                            </Text>
                                        )}
                                    </div>
                                </MenuItem>
                                <MenuItem value={2}>
                                    <div>
                                        <BoldText fontSize="15px">
                                            <img
                                                src={PwC_light}
                                                alt="PwC_light"
                                            />{' '}
                                            PwC light
                                        </BoldText>
                                        {isSelectOpen && (
                                            <Text color="#6b6b6b" size="14px">
                                                This applies to campaigns that
                                                have limited
                                                <br />
                                                notes, observations or
                                                recommendations.
                                            </Text>
                                        )}
                                    </div>
                                </MenuItem>

                                <MenuItem value={3}>
                                    <div>
                                        <BoldText fontSize="15px">
                                            <img
                                                src={client_sa}
                                                alt="client_sa"
                                            />{' '}
                                            Client self-assessment
                                        </BoldText>
                                        {isSelectOpen && (
                                            <Text color="#6b6b6b" size="14px">
                                                This applies to campaigns that
                                                have been
                                                <br />
                                                completed by the client.
                                            </Text>
                                        )}
                                    </div>
                                </MenuItem>
                            </CustomSelect>
                        </FormControl>
                    </div>
                </div>
            }
            open={isOpen}
            handleClose={() => handleCloseTemplate()}
            disabledSave={
                benchmarkingEnabled
                    ? !closeCampaignValue.assessmentType ||
                      !(isCheckboxEnable[0][1] || isCheckboxEnable[1][1])
                    : !closeCampaignValue.assessmentType ||
                      isCheckboxEnable[1][0]
            }
            handleSubmit={() => {
                closeCampaign({
                    campaignId,
                    assessmentType: closeCampaignValue.assessmentType,
                    submitForBenchmarking: closeCampaignValue.radioValue,
                    pointOneDecimalEnable,
                });
            }}
        />
    );
};

const TitleTextWrapper = styled(JustifyContentCenter)`
    flex-grow: 1;
    padding-left: 72px;
`;

const TitleWrapper = styled(Flex)`
    border-bottom: 2px solid #e1e1e1;
    padding: 22px 0;
`;

const CheckModerationContainer = styled(AlignItems)`
    border-radius: 1px;
    margin-left: 30px;
    padding-right: 9px;
    height: 100%;
`;
const InfoTitleContainer = styled(Flex)`
    background-color: #edf4f8;
    padding: 12px;
`;

const InfoTitleTextWrapper = styled('div')`
    padding: 0 8px;
`;
const CloseButton = styled(CustomButton)`
    && {
        background-color: #de3328;
        min-height: 48px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        box-sizing: border-box;
        border: none;
        color: white;
        &:hover {
            background-color: #f05046;
            color: white;
        }
        &:disabled {
            background-color: #f5928b;
            color: white;
        }
    }
`;

const CustomSelect = styled(Select)`
    && .MuiFormLabel-root.Mui-focused {
        color: #575757;
    }
    &&.MuiFormLabel-root.Mui-focused {
        color: #575757;
    }
    &&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #575757;
    }
`;

const FooterLinkExternal = styled(LinkExternal)`
    color: #4169E1;
    font-size: 15px;
    text-decoration: underline;
`;
const Xmark = styled('img')`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    // margin-left: 98%;
`;
