const chart = [
    [ 'M', 1000],
    ['CM',  900],
    [ 'D',  500],
    ['CD',  400],
    [ 'C',  100],
    ['XC',   90],
    [ 'L',   50],
    ['XL',   40],
    [ 'X',   10],
    ['IX',    9],
    [ 'V',    5],
    ['IV',    4],
    [ 'I',    1]
];

export const alphabet = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
];

export const toRoman = (decimal: number) =>
    chart.reduce((acc: any, numeral: any) => {
        const [roman, remainder] = acc;
        const [letter, value] = numeral;
        return [roman.toLowerCase() + letter.toLowerCase().repeat(remainder / value),
            remainder % value];
    }, ['', decimal])[0];

export const termsOfUseStyles = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        height: '80vh',
        padding: 0,
        left: 0,
        right: 0,
        top: '10vh',
        margin: 'auto',
        maxWidth: '662px',
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: '#2B5576'
    }
};

export const termsOfUseDecline = {
    content: {
        backgroundColor: '#fff',
        border: 'none',
        height: '234 px',
        width: '479 px',
        padding: 0,
        left: 0,
        right: 0,
        top: 'calc(50% - 112px)',
        bottom: 'calc(50% + 112px)',
        margin: 'auto',
        maxWidth: '662px',
        '&::after': {
            background: 'black',
            content: '""',
            height: '100%',
            left: 0,
            opacity: 0.5,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: -1
        }
    },
    overlay: {
        backgroundColor: 'transparent'
    }
};

export const termOfUseIntro = [
    {
        text: 'Last updated: 13th April 2020'
    }, {
        text: 'You have been given access by a PwC firm (also referred to as “us”, or “we”) to this technology tool.  These terms form an agreement between you and that PwC firm; please read them carefully.'
    }, {
        text: 'By using this technology tool, you agree to these terms.  If you cannot comply with these terms, then you must cease using this technology tool.'
    }, {
        text: 'We may amend these terms from time to time.  The amended terms will either be notified to you, or be available for you to view on (or when accessing) the technology tool.'
    }
];

export const termOfUseData = [
    {
        arabic: 'Use',
        alphabetList: [
            {
                italic: 'Permitted use. ',
                content: 'You may only use this technology tool, the content on it, and the outputs from it, for their intended purpose and subject to these terms save as otherwise agreed in writing.'
            }, {
                italic: 'Restrictions on use. ',
                content: 'You may not:',
                romanList: [
                    {
                        normal: 'act or omit to act in any way that puts you or any entity you work for in breach of any agreement with any PwC firm, or any applicable law or regulation;'
                    }, {
                        normal: 'make this technology tool, the contents or the outputs available to any person, other than as permitted by us;'
                    }, {
                        normal: 'copy, modify, disassemble, work around, by-pass, reverse engineer or otherwise change this technology tool or its underlying data, systems or software, or attempt to do so;'
                    }, {
                        normal: 'copy or use the contents or outputs, other than as expressly permitted by us;'
                    }, {
                        normal: 'engage in or encourage unlawful, invasive, infringing, defamatory or fraudulent activity (including to violate, or encourage the violation of, the legal rights of others); impersonate another person or otherwise misrepresent your identity or other information about you;'
                    }, {
                        normal: 'remove or alter any identification, proprietary, copyright or other notices on the technology tool or the contents or outputs;'
                    }, {
                        normal: 'access or use the technology tool on any publicly available equipment; or'
                    }, {
                        normal: 'bypass or attempt to bypass any of the access or security controls or otherwise weaken or threaten the security of the technology tool.'
                    }
                ]
            }, {
                italic: 'Access. ',
                content: 'You agree to:',
                romanList: [
                    {
                        normal: 'maintain your access credentials in strict confidence and not share them with anyone else;'
                    }, {
                        normal: 'immediately notify us by email if anyone else obtains your access credentials so that we can disable them and provide you with new credentials; and'
                    }, {
                        normal: 'tell your PwC contact immediately if you lose your access credentials or know or suspect someone else may know or have access to them.'
                    }
                ]
            }, {
                italic: 'Incorrect access. ',
                content: 'You agree to notify us immediately, and then exit the technology tool, if you access content or outputs on it that are not intended for you.'
            }
        ]
    }, {
        arabic: 'No liability to you',
        alphabetList: [
            {
                italic: 'Use is at your own risk. ',
                content: 'As between you and any PwC firm:',
                romanList: [
                    {
                        normal: 'this technology tool, the content and outputs are provided on an ‘as-is’ basis;'
                    }, {
                        normal: 'to the extent permitted by law, no warranties or other provisions are implied into these terms.  We do not warrant that the operation of the technology tool will be uninterrupted or error-free, or that the technology tool, the content and outputs will be fit for any particular purpose or be free of errors or defects;'
                    }, {
                        normal: 'to the extent permitted by law, no PwC firm accepts any liability, responsibility or duty of care to you for the technology tool, the content or outputs, or for any consequences of you or anyone acting or refraining to act in reliance on them, or for any decision based on them; and'
                    }, {
                        normal: 'nothing in these terms excludes our liability for fraud, or for anything else that cannot be law be limited.'
                    }
                ]
            }
        ]
    }, {
        arabic: 'Suspension or termination',
        alphabetList: [
            {
                italic: 'Ending your access. ',
                content: 'We may immediately suspend or terminate access to the technology tool if you use it other than as permitted by us, or if we consider that your use of the product could cause a detriment to us or others, or for any other reason we require (e.g. maintenance, or to make any changes or improvements).'
            }
        ]
    }, {
        arabic: 'Your inputs',
        alphabetList: [{
            italic: 'Your inputs. ',
            content: 'To the extent that you make or upload inputs of any sort to the technology tool (which may include comments, information, materials, data, or anything else), you grant: i) us and our suppliers the right to host your inputs on the technology tool or any other system we deem appropriate, and use it to produce the outputs or for any other lawful business purpose (which may include technical support and maintenance); and ii) us and anyone we lawfully permit to access the technology tool the ability to view, access and download those inputs.  You agree that you have all necessary rights in your inputs to grant the rights set out in this section. No payment, royalty or other consideration whatsoever will be payable by us in connection with our use of such inputs.  You agree to reimburse us in full to the extent we incur any liability as a result of your non-compliance with this section.'
        }, {
            italic: 'Moderation - ',
            content: 'We may, in our discretion, moderate your inputs and other content (including making content unavailable), for example where the technology tool contains a discussion board or other open forum. However, we are under no obligation to oversee, monitor or moderate any content. The views expressed by users of the technology tool do not necessarily represent our views or values, nor are they supported or endorsed by us or any PwC firm.'
        }]
    }, {
        arabic: 'Ownership',
        alphabetList: [{
            italic: 'Intellectual property rights. ',
            content: 'We and our licensors own this technology tool, the content, and the outputs.  You have a limited, non-transferable, non-sublicensable licence to use them in accordance with these terms only.  (Our licensors may include an entity you work for).'
        }]
    }, {
        arabic: 'Open Source',
        alphabetList: [{
            italic: 'Open source software. ',
            content: 'Portions of the technology tool may use open source components, a list of which may be available on the technology tool or is otherwise available from us on request.  The source code and open source licensing terms applicable to each open source component can be obtained either by clicking on the related link, or from us on request.'
        }, {
            italic: 'Open source licence terms prevail. ',
            content: 'In the event of a conflict between the terms of any open source license and these terms, the open source license terms shall prevail.'
        }]
    }, {
        arabic: 'General',
        alphabetList: [{
            italic: 'Disputes. ',
            romanList: [
                {
                    normal: 'If a dispute arises, you and we will attempt to resolve it by discussion, negotiation and mediation before commencing legal proceedings.'
                }, {
                    normal: 'These terms and any dispute arising from them, whether contractual or non-contractual, will be governed by the law of the territory in which we operate, and be subject to the exclusive jurisdiction of the courts of that territory.'
                }, {
                    normal: 'Any claims must be brought no later than 2 years after the date the claimant should have been aware of the potential claim and, in any event, no later than 4 years after any alleged breach.'
                }
            ]
        }, {
            italic: 'Matters beyond reasonable control. ',
            content: 'We will not be liable to you if we fail to meet our obligations due to matters beyond our reasonable control.'
        }, {
            italic: 'Rights of third parties ',
            content: 'A person who is not a party to these terms has no rights to enforce them.'
        }, {
            italic: 'Entire agreement. ',
            content: 'These terms form the entire agreement between you and us in relation to the technology tool, the content and the outputs. These terms replace any earlier agreements, representations or discussions.'
        }]
    }, {
        arabic: 'Other',
        alphabetList: [{
            italic: 'Your personal data. ',
            content: 'All information, rights and obligations concerning personal data in relation to the technology tool is contained in its privacy statement.'
        }, {
            italic: 'System of record - ',
            content: 'The technology tool is not, and is not designed to operate as, a system of record.  You are solely responsible for keeping any inputs added to the technology tool, anything you wish to download from the technology tool, and any copies required and you must do so in accordance with these terms or as otherwise agreed in writing with  us.'
        }]
    }, {
        arabic: 'Definitions',
        alphabetList: [{
            content: 'The following words and expressions in these terms  have the following meanings:',
            romanList: [
                {
                    bold: 'access credentials',
                    normal: ' - the username and password or other access details that you may use to access the technology tool'
                }, {
                    bold: 'content',
                    normal: ' - the information, data, material and any other content on the technology tool, other than the outputs'
                }, {
                    bold: 'outputs',
                    normal: ' - any visualisation, graphic, calculation, result or other output produced by the functionality of the technology tool'
                }, {
                    bold: 'PwC firm',
                    normal: ' - a member firm in the PwC network, each of which is a separate legal entity. For further details, please see www.pwc.com/structure.'
                }, {
                    bold: 'these terms',
                    normal: ' - means the agreement formed by this “terms of use” document.'
                }
            ]
        }]
    }
];
