import { gql } from 'apollo-boost';

export const CAMPAIGN_BY_CLIENT = gql`
    query campaignsByClientSideMenu($clientId: Int!) {
        availableCampaignsByClient(clientId: $clientId) {
            id
                    title
                    description
                    progress
                    readOnly
                    client {
                        id
                        name
                    }
        }
        }
`;

export const SUBHEADER_DATA = gql`
    query campaignsByClient($clientId: Int!) {
        availableCampaignsByClient(clientId: $clientId) {
            id
            title
            description
            capabilitiesScoredCnt
            capabilitiesModeratedCnt
            capabilitiesCnt
            assessmentsCompletedCnt
            assessmentsCnt
            progress
            readOnly
            isCampaignEntityUser
            client {
                id
                name
            }
            readOnly
            stakeholder {
                id
                firstName
                lastName
            }
            manager {
                id
                firstName
                lastName
            }
            members {
                id
                firstName
                lastName
            }
            readonlymembers {
                id
                firstName
                lastName
            }
            framework {
                id
                definition {
                    name
                }
            }
            status
            updatedAt
            closedAt
            benchmarkingEnabled
            pointOneDecimalEnable
        }
        assessmentsByClient(clientId: $clientId) {
            id
            campaignEntity {
                id
                name
                archivedDate
            }
            campaign {
                id
                status
                title
                progress
                readOnly
            }
            framework {
                id
                version
                definition {
                    name
                }
            }
            stakeholder {
                firstName
                lastName
            }
            state {
                status
                capabilitiesCnt
                capabilitiesScoredCnt
                capabilitiesModeratedCnt
            }
            archivedDate
            permission
            updatedAt
        }
    }
`;
