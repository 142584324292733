import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { UserStore } from '../../../../context/user-store';
import RenderCompletedStege from './RenderCompletedStege';

import {
    AlignItems,
    JustifyContentCenter,
    Column,
    Text,
    SpaceBetween
} from '../../../../components';

import legendClose from '../../../../assets/images/icon-legend-close.svg';
import iconNotepad from '../../../../assets/images/icon-notepad.svg';
import { isReadOnlyEnabled } from '../../../../utils';

type Props = {
    setIsLegendVisible: any;
    isTargetScoring?: boolean;
    isReadOnly?: boolean;
    isObjectiveComponentExist?: boolean;
};

const Legend: React.FC<Props> = ({ setIsLegendVisible, isTargetScoring, isReadOnly = false,isObjectiveComponentExist=false }) => {
    const {
        state: { currentTheme, globalSettings }
    } = useContext(UserStore);

    useEffect(() => {
        setColotLegand(currentTheme);
    }, [currentTheme]);

    const readOnlyEnabled = isReadOnlyEnabled(globalSettings);

    const [theme, setTheme] = useState<string[]>([
        '#eceff4',
        '#e2e6ec',
        '#c2d2df',
        '#94adc4',
        '#4588c3',
        '#ffffff'
    ]);

    const setColotLegand = (currentThemeLegend: string) => {
        switch (currentThemeLegend) {
            case 'RAGtheme1':
                return setTheme([
                    '#E0301E',
                    '#FD9359',
                    '#FFB600',
                    '#5EB562',
                    '#006652',
                    '#ffffff'
                ]);

            case 'RAGtheme2':
                return setTheme([
                    '#E0301E',
                    '#FFB600',
                    '#92D050',
                    '#38963C',
                    '#006652',
                    '#ffffff'
                ]);

            default:
                return setTheme([
                    '#eceff4',
                    '#e2e6ec',
                    '#c2d2df',
                    '#94adc4',
                    '#4588c3',
                    '#ffffff'
                ]);
        }
    };

    return (
        <LegendWrapper style={{ width: '100%' }}>
            <AlignItems>
                <AlignItems style={{ marginTop: 11 }}>
                    <Block
                        style={{
                            background:
                                'repeating-linear-gradient(-45deg, transparent, transparent 4px, grey 7px, grey 7px)',
                            padding: 10
                        }}
                    />
                    <DescriptionText>Out of scope</DescriptionText>
                </AlignItems>
                <Column
                    style={{
                        borderLeft: 'solid 0.5px #d7d7d7',
                        margin: '0 20px'
                    }}
                >
                    {/* {!(readOnlyEnabled && isReadOnly) &&
                        <TopText>NOT STARTED</TopText>
                    } */}
                    <AlignItems>
                        <AlignItems>
                            {/* <Block
                                style={{
                                    backgroundColor: `${theme[5]}`,
                                    padding: 10
                                }}
                            >
                                <Text color="#fff"></Text>
                            </Block> */}
                            {/* <DescriptionText> {readOnlyEnabled && isReadOnly ? 'Pending approval' : 'Not scored'} </DescriptionText> */}
                        </AlignItems>
                        {!(readOnlyEnabled && isReadOnly) &&
                        <AlignItems>

                              <Block
                                style={{
                                    backgroundColor: `${theme[5]}`,
                                    padding: 10
                                }}
                            >
                                <Text color="#fff"></Text>
                            </Block>
                            <DescriptionText> {readOnlyEnabled && isReadOnly ? 'Pending approval' : 'Not scored'} </DescriptionText>

                            <Block
                                style={{ backgroundColor: '#fff', padding: 10 }}
                            >
                                <img
                                    style={{ width: '1.2vw', height: '1.2vw' }}
                                    src={iconNotepad}
                                    alt=""
                                />
                            </Block>
                            <DescriptionText>
                                Not scored with notes
                            </DescriptionText>
                        </AlignItems>
                        }
                    </AlignItems>



                </Column>
                {!(readOnlyEnabled && isReadOnly) &&
                <Column
                    style={{
                        borderLeft: 'solid 0.5px #d7d7d7',
                        borderRight: 'solid 0.5px #d7d7d7',
                        marginRight: 20
                    }}
                >
                    <TopText>IN PROGRESS</TopText>
                    <SpaceBetween>
                        <BlockWrapper style={{ marginLeft: 0 }}>
                            <Block>
                                <ProgressText>S</ProgressText>
                            </Block>
                            <DescriptionText>Scored</DescriptionText>
                        </BlockWrapper>
                        {!isObjectiveComponentExist &&<BlockWrapper>
                            <Block>
                                <ProgressText>M</ProgressText>
                            </Block>
                            <DescriptionText>Moderated</DescriptionText>
                        </BlockWrapper>}
                        <BlockWrapper>
                            <Block>
                                <ProgressText>R</ProgressText>
                            </Block>
                            <DescriptionText>Review</DescriptionText>
                        </BlockWrapper>
                        <BlockWrapper style={{ marginRight: 10 }}>
                            <Block>
                                <ProgressText>AR</ProgressText>
                            </Block>
                            <DescriptionText>Amendments requested</DescriptionText>
                        </BlockWrapper>
                    </SpaceBetween>
                </Column>
                }


{(readOnlyEnabled && isReadOnly) &&
                <Column>
                  <AlignItems>
                        <AlignItems>
                            <Block
                                style={{
                                    backgroundColor: `${theme[5]}`,
                                    padding: 10
                                }}
                            >
                                <Text>P</Text>
                            </Block>
                            <DescriptionText> {readOnlyEnabled && isReadOnly ? 'Pending approval' : 'Not scored'} </DescriptionText>
                            <Block
                                style={{
                                    backgroundColor: `${theme[5]}`,
                                    padding: 10
                                }}
                            >
                                <Text>&nbsp;</Text>
                            </Block>
                            <DescriptionText>Not Started</DescriptionText>
                        </AlignItems>
                        {!(readOnlyEnabled && isReadOnly) &&
                        <AlignItems>
                            <Block
                                style={{ backgroundColor: '#fff', padding: 10 }}
                            >
                                <img
                                    style={{ width: '1.2vw', height: '1.2vw' }}
                                    src={iconNotepad}
                                    alt=""
                                />
                            </Block>
                            <DescriptionText>
                                Not scored with notes
                            </DescriptionText>
                        </AlignItems>
                        }
                    </AlignItems>
                </Column>
                }

                <Column>
                    {!isTargetScoring ? (
                        <>
                            <TopText>COMPLETED</TopText>
                            <RenderCompletedStege
                                currentTheme={currentTheme}
                                theme={theme}
                                isTargetScoring={isTargetScoring}
                            />
                        </>
                    ) :
                    (
                        <>
                           <TopText>GAP</TopText>
                           <RenderCompletedStege
                                currentTheme={currentTheme}
                                theme={theme}
                                isTargetScoring={isTargetScoring}
                            />
                        </>
                    )}
                </Column>
            </AlignItems>
            <CloseItems onClick={() => setIsLegendVisible(false)}>
                <img
                    style={{ width: '1.2vw', height: '1.2vw' }}
                    src={legendClose}
                    alt="close"
                />
                <CloseText>CLOSE</CloseText>
            </CloseItems>
        </LegendWrapper>
    );
};

const LegendWrapper = styled(AlignItems)`
    max-width: 100vw;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 8px 0 8px 0;
    justify-content: space-between;
`;

const Block = styled(JustifyContentCenter)`
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 0.3vw;
    margin-left: 1.7vw;
    width: 1.8vw;
    height: 1.8vw;
    align-items: flex-start;
    padding: 0;
    border: 'solid 0.5px #d7d7d7';
`;

const CloseText = styled(Text)`
    font-size: 15px;
    font-weight: bold;
    color: #525252;
    margin-right: 3vw;
`;

const DescriptionText = styled(Text)`
    color: #5d5d5d;
    font-size: 14px;
`;

const TopText = styled(Text)`
    color: #575757;
    font-size: 11px;
    margin-left: 1.7vw;
    margin-bottom: 8px;
`;

const ProgressText = styled(Text)`
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    display: flex;
    width: 100%;
    justify-content: center;
`;

const BlockWrapper = styled(AlignItems)`
    margin-left: 0.6vw;
`;

const CloseItems = styled(AlignItems)`
    cursor: pointer;
    align-self: center;
    margin-top: 15px;
    margin-left: 15px;
`;

export default Legend;
