import React from 'react';
import { styled } from '@mui/material/styles';

import Router from '../../routes/router';
import JustifyContentCenter from '../base/Layout/JustifyContentCenter';

//TODO make this type global
type RedirectParams = {
    campaignId: string;
    assessmentId: string;
    domainId: string;
    capabilityId: string;
};

const RedirectToScore = (params: RedirectParams) => {
    Router.goToMaturityScore(params);
};

const renderCompensatingControlBlock = (
    campaignId: string,
    assessmentId: string,
    domainId: string,
    capabilityId: string,
    capabilityStatus: string,
    isReadOnly: boolean,
    readOnlyEnabled: boolean,
    CompensationControlCount?: number | string,
    inScope?: boolean
) => {
    const isBlockedByReadOnly=(readOnlyEnabled && isReadOnly && capabilityStatus !== 'COMPLETED');
    const onClick = () => {
        !isBlockedByReadOnly && inScope &&
        RedirectToScore({
            campaignId,
            assessmentId,
            domainId,
            capabilityId
        });
    };

    return (
        <Block
            onClick={onClick}
            cursor="pointer"
        >
            { CompensationControlCount }
        </Block>
    );
};

const Block = styled(JustifyContentCenter)<{ cursor?: string }>`
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: ${props => (props.cursor ? props.cursor : 'pointer')};
    width: 40px;
    height: 40px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    color: rgb(0, 0, 0);
    border: 0.5px solid rgb(215, 215, 215);
`;

export default renderCompensatingControlBlock;
