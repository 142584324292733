import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { AlignItems, Text } from '../../components';
import EntityStructure from './EntityStructure';
import Router from '../../routes/router';
import { UserStore } from '../../context/user-store';

import iconClose from '../../assets/images/Atmos_Icons_Close.svg';
import { IconButton } from '@mui/material';

const CompanyStructure = () => {
    const [clientId, setClientId] = useState<number | null>(null);

    const {
        state: { user, client }
    } = useContext(UserStore);

    useEffect(() => {
        if (client && client.id) {
            setClientId(client.id);
        }
    }, [client]);
    const userMayEdit =
        (user && user.id) === (client && client.owner && client.owner.id);
    return (
        <>
            <CompanyStructureHeader>
                <div />
                <TitleText>Company structure</TitleText>
                <IconButton
                    style={{marginRight: '5%'}}
                    onClick={Router.goBack}
                    aria-label={'close button'}
                >
                    <img src={iconClose} alt="close" aria-hidden={true} id="skip-to-content" tabIndex={-1}/>
                </IconButton>
            </CompanyStructureHeader>
            <CompanyStructureContent>
                {clientId !== null ? (
                    <EntityStructure
                        clientId={clientId}
                        userMayEdit={userMayEdit}
                    />
                ) : null}
            </CompanyStructureContent>
        </>
    );
};

const CompanyStructureHeader = styled(AlignItems)`
    justify-content: space-between;
    background-color: #fff;
    margin: 0 -8.8% 40px -6.5%;
    width: 100vw;
    min-height: 55px;
`;

const CompanyStructureContent = styled('div')`
    background-color: #fff;
    border: solid 1px #d9d9d9;
`;

const TitleText = styled(Text)`
    margin-left: 2vw;
    font-weight: 500;
    color: #575757;
    font-size: 18px;
`;

export default CompanyStructure;
