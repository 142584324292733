import React, { useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { Column, Flex, RedButton } from '../components';
import { styled } from '@mui/material/styles';
import csvIcon from '../assets/images/csv-pwc.svg';

interface fileUpload {
    files: any;
    setFiles: any;
    title: string;
    subTitle?: string;
    extraText?: string;
    errorUpload?: string;
}

const DropzoneUpload: React.FC<fileUpload> = ({
        files,
        setFiles,
        title,
        subTitle,
        extraText,
        errorUpload
    }) => {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        maxFiles: 1,
        accept: {
            'text/csv': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
        }
    });

    return (
        <ColumnWithMargin>
            <Dropzone {...getRootProps()}>
                <ThumbsContainer>
                    {files && files.map((file: any) => {
                        return (
                            <Thumb key={file.name}>
                                <ThumbInner>
                                    <CSVIcon src={csvIcon} alt={file.name} aria-hidden={true}></CSVIcon>
                                </ThumbInner>
                            </Thumb>
                        );
                    })}
                </ThumbsContainer>
                <input {...getInputProps()} />
                <p>{ title }</p>
                { subTitle && (<em>{ subTitle }</em>) }
                { extraText && (<em>{ extraText }</em>) }
                { errorUpload &&
                    (<ErrorText>{ errorUpload }</ErrorText>)
                }
            </Dropzone>
        </ColumnWithMargin>
    );


};

const ColumnWithMargin = styled(Column)`
    margin: 10px 5px;
`;

const Dropzone = styled(Flex)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
`;

const ThumbsContainer = styled('div')`
    display: flex;
    flexDirection: row;
    flexWrap: wrap;
    marginTop: 16;
`;

const Thumb = styled('div')`
    display: inline-flex;
    borderRadius: 2;
    border: 1px solid #eaeaea;
    marginBottom: 8;
    marginRight: 8;
    width: 100;
    height: 100;
    padding: 4;
    boxSizing: border-box;
    border-radius: 6px;
`;

const ThumbInner = styled('div')`
    display: flex;
    min-width: 0;
    overflow: hidden;
`;

const CSVIcon = styled('img')`
    width: 40px;
    margin: 5px;
    padding: 16px 12px;
    background: #eee;
    border: 1px dashed;
    border-radius: 6px;
`;

const ErrorText = styled('p')`
    color: #de3328
`;


export default DropzoneUpload;
