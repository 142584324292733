import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

type Props = {
    placeholder: string;
    callback: any;
    setSearch?: any;
    style?: React.CSSProperties;
    isStakeholderDropdownChanged?: any;
};
const SearchField: React.FC<Props> = ({ placeholder, callback, setSearch,style,isStakeholderDropdownChanged }) => {

    useEffect(() => {
        if (isStakeholderDropdownChanged) {
            setValue('');
            setSearch('');
        }
      }, [isStakeholderDropdownChanged]);

    const [value, setValue] = useState<string>();
        return (
            <Wrapper style={style}>
                <Search
                    type="search"
                    value={value}
                    placeholder={placeholder}
                    inputProps={{ 'aria-label':placeholder }}
                    onChange={e => {
                        if (setSearch) {
                            setSearch(e.target.value);
                        }
                        setValue(e.target.value);
                        callback(e.target.value);
                    }}
                />
            </Wrapper>
        );
};

const Search = styled(InputBase)`
    && {
        border: none;
        width: 100%;
        outline: none;
        border-radius: 50px;
        padding: 1% 10px 5px 24px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        background-color: #fff;
        &::-webkit-input-placeholder {
            color: #818181;
        }
    }
`;

const Wrapper = styled('div')`
    height: 40px;
    min-width: 350px;
    border-radius: 30px;
    border: solid 1px #dcdcdc;
    position: relative;
    box-sizing: border-box;
`;

export default SearchField;
